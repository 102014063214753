import {
  BridgeProvider,
  CrossChainBridgeProvider,
} from '@/types/crossChainBridge'
import swellL2Large from '@/assets/images/swell_l2_large.png'
import lifiLogo from '@/assets/images/lifi_logo.png'
import layerzeroLogoRound from '@/assets/images/layerzero_logo-round.png'
import bungeeLogo from '@/assets/images/bungee_logo.png'

export const BRIDGE_PROVIDER_NATIVE: BridgeProvider = {
  id: CrossChainBridgeProvider.native,
  name: 'Native Bridge',
  logoShape: 'square',
  logoURI: swellL2Large,
}
export const BRIDGE_PROVIDER_LIFI: BridgeProvider = {
  id: CrossChainBridgeProvider.lifi,
  name: 'Li.Fi',
  logoShape: 'square',
  logoURI: lifiLogo,
}
export const BRIDGE_PROVIDER_BUNGEE: BridgeProvider = {
  id: CrossChainBridgeProvider.bungee,
  name: 'Bungee',
  logoShape: 'circle',
  logoURI: bungeeLogo,
}
export const BRIDGE_PROVIDER_LAYER_ZERO: BridgeProvider = {
  id: CrossChainBridgeProvider.layerZero,
  name: 'Layer Zero',
  logoShape: 'circle',
  logoURI: layerzeroLogoRound,
}
