import { MouseEvent, useCallback, useEffect, useState } from 'react'
import { PortfolioDefiPositionSummary } from '../../types'
import styled from 'styled-components'
import { Portal, useMediaQuery } from '@mui/material'
import { keyframes } from 'styled-components'
import { FlexRow } from '@/swell-ui/FlexRow'
import { DividerDark } from '@/swell-ui/Divider'
import { PortfolioBreakpoints } from '../../consts'
import { usePageDimensions } from '@/hooks/usePageDimensions'

export function PortfolioApyCell({
  position,
}: {
  position: Pick<PortfolioDefiPositionSummary, 'apr' | 'apyComponents'>
}) {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)
  const open = Boolean(anchorEl)
  const onOpen = (e: MouseEvent<HTMLDivElement>) => setAnchorEl(e.currentTarget)
  const onClose = useCallback(() => setAnchorEl(null), [])
  const pageDimensions = usePageDimensions()

  const isMobile = useMediaQuery(
    `(max-width: ${PortfolioBreakpoints.mobile}px)`
  )

  // on scroll close tooltip
  useEffect(() => {
    function handleScroll() {
      onClose()
    }

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [onClose])

  let apyNode = <>{position.apr}</>
  let tooltipNode = null
  if (position.apyComponents.exists) {
    apyNode = (
      <span className="nobreak">
        {position.apr}{' '}
        <div
          onMouseEnter={onOpen}
          onMouseLeave={onClose}
          style={{ top: '2px', display: 'inline', position: 'relative' }}
        >
          <Stars />
        </div>
      </span>
    )

    if (open && anchorEl && pageDimensions.pageHeight) {
      const { top, left, width, height } = anchorEl.getBoundingClientRect()

      const isCloseToBottomOfPage =
        top + height + 8 + 200 > pageDimensions.pageHeight

      const tooltipBoxStyle = {} as React.CSSProperties
      if (isMobile) {
        tooltipBoxStyle.top = `${top + height + 8}px`
        tooltipBoxStyle.left = `${left + width / 2}px`
        tooltipBoxStyle.transform = 'translateX(-70%)'
      } else {
        tooltipBoxStyle.top = `${top + height + 8}px`
        tooltipBoxStyle.left = `${left + width / 2}px`
        tooltipBoxStyle.transform = 'translateX(-15%)'
      }

      if (isCloseToBottomOfPage) {
        tooltipBoxStyle.top = undefined
        tooltipBoxStyle.bottom = `${pageDimensions.pageHeight - top + 8}px`
      }

      tooltipNode = (
        <TooltipBox style={{ ...tooltipBoxStyle }}>
          {position.apyComponents.components.map((component, i) => (
            <FlexRow key={i} gap="12">
              <img src={component.logoURI} width="18" height="18" />
              <span>{component.name}</span>{' '}
              <span style={{ marginLeft: 'auto' }}>{component.apy}</span>
            </FlexRow>
          ))}
          <DividerDark />
          <FlexRow gap="12">
            <Stars />
            <span
              style={{
                whiteSpace: 'nowrap',
              }}
            >
              Total APR
            </span>
            <span style={{ marginLeft: 'auto' }}>{position.apr}</span>
          </FlexRow>
        </TooltipBox>
      )
    }
  }

  return (
    <>
      {apyNode}
      <Portal>{tooltipNode}</Portal>
    </>
  )
}

function Stars() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
    >
      <path
        d="M14.75 7.24976L15.6875 5.18726L17.75 4.24976L15.6875 3.31226L14.75 1.24976L13.8125 3.31226L11.75 4.24976L13.8125 5.18726L14.75 7.24976ZM9.125 7.62476L7.25 3.49976L5.375 7.62476L1.25 9.49975L5.375 11.3748L7.25 15.4998L9.125 11.3748L13.25 9.49975L9.125 7.62476ZM14.75 11.7498L13.8125 13.8123L11.75 14.7498L13.8125 15.6873L14.75 17.7498L15.6875 15.6873L17.75 14.7498L15.6875 13.8123L14.75 11.7498Z"
        fill="#6E79E9"
      />
    </svg>
  )
}

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`

const TooltipBox = styled.div`
  position: fixed;
  display: flex;
  min-width: 218px;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  z-index: 1000;

  border-radius: 12px;
  border: 1px solid var(--Gradient-Blue, #3068ef);

  background: var(--Swell-Oceanblue-125, #0b141b);

  opacity: 0;
  animation: ${fadeIn} 0.21s ease-out forwards;
  animation-delay: 0;

  hr {
    background: linear-gradient(180deg, #3068ef 0.29%, #1322ac 167.95%);
    border: none;
    margin: 0;
    height: 1px;
  }

  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
`
