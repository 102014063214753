import {
  NucleusApproveVaultTokenForAtomicQueue,
  NucleusRequestWithdraw,
} from '@/state/nucleusVault/hooks'
import {
  NucleusAllowances,
  NucleusBalances,
  NucleusSharesState,
  NucleusVaultState,
} from '@/state/nucleusVault/types'
import { useTransactionContext } from '@/state/transactions/context'
import { FlexRow } from '@/swell-ui/FlexRow'
import { useSwellWeb3 } from '@/swell-web3/core'
import { Token } from '@/types/tokens'
import { trimDecimalPlaces } from '@/util/number'
import { BigNumber } from 'ethers'
import { parseUnits } from 'ethers/lib/utils'
import React, { ReactNode, useEffect, useState } from 'react'
import styled from 'styled-components'
import {
  NucleusErrors,
  prepareNucleusApproveVaultTokenForAtomicQueue,
  prepareNucleusRequestWithdraw,
} from '../nucleusCalls'
import { INucleusActiveWithdrawal } from '../nucleusHooks'
import { useNowMs } from '@/hooks/useTimeCountdown'
import { TokenSelectMenu } from '@/components/TokenSelectMenu'
import { WithdrawAssetInput } from '../NucleusInputs'
import {
  NucleusApproveVaultTokenForAtomicQueueButton,
  NucleusRequestWithdrawButton,
} from '../NucleusButtons'
import { useMediaQuery } from '@mui/material'
import { ColoredHeadingTypography } from '@/swell-ui/Typography/typographyPresets'
import {
  NucleusRates,
  NucleusSupportedTokenMap,
  NucleusVault,
} from '@/types/nucleus'
import {
  makeNucleusVaultWithdrawalsSummary,
  nucleusWithdrawRequestSummary,
} from '../nucleusFormatting'
import { AvailableChipV2 } from '@/components/StakingWidget/AvailableChipV2'
import { ScrollableYArea } from '@/swell-ui/ScrollableArea'
import { SolverFeeValue } from './SolverFeeValue'
import { SolverFeeSummary } from '../types'
import { InfoIcon } from '@/swell-ui/icons/InfoIcon'
import { Tooltip } from '@/swell-ui/Tooltip'
import useChainDetection from '@/hooks/useChainDetection'

export function NucleusWithdrawView({
  requestWithdraw,
  approveVaultTokenForAtomicQueue,
  allowances,
  balances,
  activeWithdrawal,
  vault,
  rates,
  sharesState,
  vaultState,
  baseAssetRateUsd,
  goToClaim,
  supportedAssets,
}: {
  activeWithdrawal: INucleusActiveWithdrawal | undefined
  vault: NucleusVault

  goToClaim: () => void

  requestWithdraw: NucleusRequestWithdraw
  approveVaultTokenForAtomicQueue: NucleusApproveVaultTokenForAtomicQueue

  // user
  allowances: NucleusAllowances | undefined
  balances: NucleusBalances | undefined
  sharesState: NucleusSharesState | undefined

  // vault
  rates: NucleusRates | undefined
  vaultState: NucleusVaultState | undefined
  baseAssetRateUsd: number | undefined
  supportedAssets: NucleusSupportedTokenMap | undefined
}) {
  // switch to claim tab when withdrawal is successfully requested
  useEffect(() => {
    if (
      activeWithdrawal?.status === 'Requesting' &&
      requestWithdraw.status === requestWithdraw.STATUS.FULFILLED
    ) {
      goToClaim()
    }
  }, [
    activeWithdrawal?.status,
    goToClaim,
    requestWithdraw.STATUS.FULFILLED,
    requestWithdraw.status,
  ])

  const is900Up = useMediaQuery('(min-width:900px)')

  const { anyTransactionInProgress } = useTransactionContext()
  const { account } = useSwellWeb3()
  const [touched, setTouched] = useState(false)
  const [selectedToken, setSelectedToken] = useState<Token>(
    vault.withdrawAssets[0]
  )
  const [inputValue, setInputValue] = useState('')
  const nowMs = useNowMs()

  let fromAmount: BigNumber | undefined
  if (inputValue) {
    fromAmount = parseUnits(
      trimDecimalPlaces(inputValue, vault.vaultToken.decimals),
      vault.vaultToken.decimals
    )
  }

  const { isL2DeploymentChain, l2DeploymentChainId, deploymentChainId } =
    useChainDetection()

  let chainId = deploymentChainId
  if (isL2DeploymentChain) {
    chainId = l2DeploymentChainId
  }

  // clear input when tx is fulfilled
  useEffect(() => {
    if (requestWithdraw.status === requestWithdraw.STATUS.FULFILLED) {
      setInputValue('')
    }
  }, [requestWithdraw.status, requestWithdraw.STATUS.FULFILLED])

  let vaultTokenBalance: BigNumber | undefined
  let vaultToken: Token
  if (isL2DeploymentChain) {
    vaultToken = vault.vaultToken
    vaultTokenBalance = balances?.vaultToken?.[l2DeploymentChainId]
  } else {
    vaultTokenBalance = balances?.vaultToken?.[deploymentChainId]
    vaultToken = vault.vaultTokenL2
  }

  let vaultAllowanceForAtomicQueue: BigNumber | undefined
  if (isL2DeploymentChain) {
    vaultAllowanceForAtomicQueue =
      allowances?.vaultTokenForAtomicQueue?.[l2DeploymentChainId]
  } else {
    vaultAllowanceForAtomicQueue =
      allowances?.vaultTokenForAtomicQueue?.[deploymentChainId]
  }

  let vaultTokenRateInQuote: BigNumber | undefined
  if (isL2DeploymentChain) {
    vaultTokenRateInQuote =
      rates?.vaultTokenQuoteRates?.[l2DeploymentChainId]?.[
        selectedToken.address
      ]
  } else {
    vaultTokenRateInQuote =
      rates?.vaultTokenQuoteRates?.[deploymentChainId]?.[selectedToken.address]
  }

  const preparedApprove = prepareNucleusApproveVaultTokenForAtomicQueue({
    vaultTokenAmount: fromAmount,
    vaultTokenBalance,
  })
  const preparedRequestWithdrawal = prepareNucleusRequestWithdraw({
    nowMs,
    offerAmount: fromAmount,
    activeWithdrawal,
    sharesState,
    vaultAllowanceForAtomicQueue: vaultAllowanceForAtomicQueue,
    vaultTokenBalance,
    vaultTokenRateInQuote,
    wantAsset: selectedToken,
    vaultState,
    baseAsset: vault.baseAsset,
    chainId,
    supportedAssets,
    account,
    withdrawWhitelist: vault.withdrawWhitelist,
  })

  const accountAndNoBalances = Boolean(account && !balances)
  const noRates = !rates
  const preventInteraction =
    anyTransactionInProgress || accountAndNoBalances || noRates

  const mustApproveWithdrawal =
    preparedRequestWithdrawal.error === NucleusErrors.InsufficientAllowance

  let buttonNode: React.ReactNode
  if (mustApproveWithdrawal) {
    buttonNode = (
      <NucleusApproveVaultTokenForAtomicQueueButton
        approveVaultTokenForAtomicQueue={approveVaultTokenForAtomicQueue}
        prepared={preparedApprove}
        preventInteraction={preventInteraction}
        vault={vault}
        selectedToken={selectedToken}
      />
    )
  } else {
    buttonNode = (
      <NucleusRequestWithdrawButton
        prepared={preparedRequestWithdrawal}
        preventInteraction={preventInteraction}
        requestWithdraw={requestWithdraw}
        vault={vault}
        selectedToken={selectedToken}
      />
    )
  }

  let errorMessage: string | null = null
  if (touched) {
    if (mustApproveWithdrawal) {
      if (preparedApprove.error) {
        errorMessage = preparedApprove.error
      }
    } else if (preparedRequestWithdrawal.error) {
      errorMessage = preparedRequestWithdrawal.error
    }
  }

  const summary = nucleusWithdrawRequestSummary({
    balances,
    fromAmount,
    baseAsset: vault.baseAsset,
    baseAssetRateUsd,
    rates,
    vaultToken: vault.vaultToken,
    vaultTokenL2: vault.vaultTokenL2,
    selectedToken,
  })
  const withdrawalsSummary = makeNucleusVaultWithdrawalsSummary({
    vaultState,
    requestWithdrawArguments: preparedRequestWithdrawal.args,
    approveVaultTokenForAtomicQueueArguments: preparedApprove.args,
    vault,
    wantAsset: selectedToken,
    rates,
    vaultToken: vault.vaultToken,
    vaultTokenL2: vault.vaultTokenL2,
  })

  const tokenBalances: Token[] = []
  tokenBalances.push({ ...vaultToken, balance: vaultTokenBalance })

  let withdrawTokens = [...vault.withdrawAssets]
  withdrawTokens = withdrawTokens.filter(
    (t) => supportedAssets?.[t.chainId]?.[t.address]?.isSupported
  )

  const withdrawWidget = (
    <FlexRow direction="column" maxWidth="353px" height="188px">
      <FlexRow justify="space-between">
        <AvailableChipV2 available={summary.availableBalance} />
        <TokenSelectMenu
          title="Withdraw Asset"
          selectedToken={selectedToken}
          setSelectedToken={setSelectedToken}
          tokens={withdrawTokens}
        />
      </FlexRow>
      <div style={{ height: '32.5px' }} />
      <WithdrawAssetInput
        setTouched={setTouched}
        inputValue={inputValue}
        setInputValue={setInputValue}
        errorMessage={errorMessage}
        balances={balances}
        disabled={preventInteraction}
        vaultToken={vault.vaultToken}
        usdLabel={summary.withdrawUsd}
      />
      {buttonNode}
    </FlexRow>
  )

  const withdrawInfo = (
    <NucleusWithdrawInfo
      processingTime={withdrawalsSummary.processingTime}
      solverFee={withdrawalsSummary.solverFee}
    />
  )

  if (is900Up) {
    return (
      <Layout gap="62" height="208px">
        {withdrawWidget}
        {withdrawInfo}
      </Layout>
    )
  }

  return (
    <Layout gap="48" direction="column" height="100%">
      {withdrawWidget}
      {withdrawInfo}
    </Layout>
  )
}

const Layout = styled(FlexRow)`
  align-items: stretch;
  > div:first-child {
    flex-shrink: 0;
  }

  button[data-variant='tertiary'] {
    padding: 6px;
    height: auto;
  }
`

function NucleusWithdrawInfo({
  processingTime,
  solverFee,
}: {
  processingTime: string
  solverFee: SolverFeeSummary
}) {
  let solverFeeFrag: ReactNode = (
    <>
      <div>
        <ColoredHeadingTypography>
          Solver fee
          <Tooltip
            arrow
            title="Fee is dynamic based on the asset to be withdrawn and the amount."
          >
            <InfoIcon style={{ marginTop: '2px', marginLeft: '4px' }} />
          </Tooltip>
        </ColoredHeadingTypography>
      </div>
      <div>
        <SolverFeeValue solverFee={solverFee} />
      </div>
    </>
  )

  if (!solverFee.isRequestingWithdrawal) {
    solverFeeFrag = null
  }

  return (
    <ScrollableYArea containerType="flex">
      <Grid>
        <div>
          <ColoredHeadingTypography>Processing time</ColoredHeadingTypography>
        </div>
        <div>
          <span>{processingTime}</span>
        </div>
        {solverFeeFrag}
        <div>
          <ColoredHeadingTypography>Withdrawal notice</ColoredHeadingTypography>
        </div>
        <div />
        <div className="wide">
          <p>
            Only one withdrawal may be initiated at a time. All withdrawals are
            actioned by solvers and any fulfilled withdrawal requests will be
            sent to your withdrawal address within the processing time. While in
            the withdrawal queue you will not earn any yield or points. At times
            withdrawals will be unable to be fulfilled due to impermanent loss
            or market volatility. If this happens, please re-queue your
            withdrawal at the new rate.
          </p>
        </div>
      </Grid>
    </ScrollableYArea>
  )
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 32px;
  row-gap: 16px;
  font-size: 14px;
  color: #fff;

  .wide {
    grid-column: 1 / span 2;
  }

  p {
    margin: 0;
  }
  ${ColoredHeadingTypography} {
    font-size: 12px;

    svg {
      position: relative;
      top: 2px;

      path {
        stroke: currentColor;
      }
    }
  }
`
