import { Token } from '@/types/tokens'
import { useDeploymentSetConfig } from './useDeploymentSetConfig'
import { TOKEN_LIST_WSWELL_EULER_EVK } from '@/constants/tokens'
import { MerklDistributor } from '@/types/merkl'
import { MERKL_DISTRIBUTOR_SWELLCHAIN } from '@/constants/merkl'

export type WSwellMerklSettings = {
  merklBackendURL: string
  merklDistributor: MerklDistributor
  WSwellToken: Token
  maturityDurationUnix: number
}

const oneWeekUnix = 60 * 60 * 24 * 7
const tenWeeksUnix = oneWeekUnix * 10

export function useWSwellMerklSettings() {
  const { v3BackendLrtUrl } = useDeploymentSetConfig()
  // const v3BackendLrtUrl = 'http://localhost:8081'
  return {
    merklBackendURL: v3BackendLrtUrl,
    WSwellToken: TOKEN_LIST_WSWELL_EULER_EVK,
    maturityDurationUnix: tenWeeksUnix,
    merklDistributor: MERKL_DISTRIBUTOR_SWELLCHAIN,
  }
}
