import { L2EcosystemProject } from '@/state/l2ecosystem/types'
import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { L2Breakpoints } from './consts'
import { FlexRow } from '@/swell-ui/FlexRow'
import { ProjectListEl } from './ProjectListEl'
import { SearchInput } from '@/swell-ui/SearchInput'
import { TagsFilter } from './TagsFilter'
import { useMediaQuery } from '@mui/material'

export function ProjectsList({
  projects,
}: {
  projects: L2EcosystemProject[] | undefined
}) {
  const isMobile = useMediaQuery(`(max-width: ${L2Breakpoints.mobile}px)`)

  const allTags = new Set<string>()
  for (const project of projects || []) {
    for (const tag of project.tags) {
      allTags.add(tag)
    }
  }
  const [selectedTag, setSelectedTag] = useState('')
  const onClearTags = () => {
    setSelectedTag('')
  }

  const [searchTerm, setSearchTerm] = useState('')
  const onClearSearch = () => {
    setSearchTerm('')
  }

  const isSearching = searchTerm !== ''
  const isAllTags = selectedTag === ''

  let filteredProjects = projects?.filter((project) => {
    if (isAllTags) {
      return true
    }
    return project.tags.includes(selectedTag)
  })

  filteredProjects = filteredProjects?.filter((project) => {
    if (!isSearching) {
      return true
    }

    const isNameMatch = project.name
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
    const isDescriptionMatch = project.description
      .toLowerCase()
      .includes(searchTerm.toLowerCase())

    let isTagMatch = false
    for (const tag of project.tags) {
      if (tag.toLowerCase().includes(searchTerm.toLowerCase())) {
        isTagMatch = true
        break
      }
    }
    return isNameMatch || isDescriptionMatch || isTagMatch
  })

  const renderTags = () => {
    if (!projects) {
      return null
    }
    return (
      <TagsFilter
        projects={projects}
        onSelect={setSelectedTag}
        onClear={onClearTags}
        selected={selectedTag}
      />
    )
  }
  const renderSearch = () => {
    return (
      <SearchInput
        maxWidth={isMobile ? undefined : '320px'}
        searchTerm={searchTerm}
        onClear={onClearSearch}
        setSearchTerm={setSearchTerm}
      />
    )
  }
  const renderList = () => {
    if (!filteredProjects) {
      return <div></div> /*  loading ? */
    }
    return filteredProjects.map((project) => {
      return <ProjectListEl key={project.name} project={project} />
    })
  }

  const renderTop = () => {
    if (isMobile) {
      return (
        <FlexRow direction="column" gap={'12'} className="s-inner">
          {renderSearch()}
          {renderTags()}
        </FlexRow>
      )
    }

    return (
      <FlexRow justify="space-between" className="s-inner">
        <div style={{ marginRight: 'auto' }}>{renderTags()}</div>
        {renderSearch()}
      </FlexRow>
    )
  }

  return (
    <Layout>
      <StickyTop className="pl-sticky-top">{renderTop()}</StickyTop>
      <FlexRow
        direction="column"
        gap={'12'}
        className="pl-list"
        margin="24px 0 0 0"
      >
        {renderList()}
      </FlexRow>
    </Layout>
  )
}

const StickyTop = styled.div`
  position: sticky;
  top: 24px;
  z-index: 10;

  ::before {
    position: absolute;
    z-index: 9;
    content: '';
    width: 100%;
    height: calc(100% + 24px);
    bottom: 0;
    background: var(--Swell-Oceanblue-150, #010508);
  }

  > .s-inner {
    z-index: 11;
  }

  gap: 12px;
  padding-bottom: 12px;

  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
`

const layoutMobile = css`
  grid-template-areas:
    'sticky-top'
    'table';
`
const layoutDesktop = css``

const Layout = styled.div`
  ${layoutMobile};
  @media (min-width: ${L2Breakpoints.mobile}px) {
    ${layoutDesktop};
  }
`
