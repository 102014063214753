import { useWeb3CallOnFulfill } from '@/hooks/useWeb3CallEvents'
import { ClaimMerkl, UnlockWSwell } from '@/state/wswell/hooks'
import { MerklUser } from '@/types/merkl'
import { useEffect, useState } from 'react'
import { EagerEVKLock } from '@/state/wswell/types'

export function useWSwellPageLiveness({
  claimWSwell,
  unlockWSwell,
  mutateEvkUser,
  mutateMerklUser,
  refreshInterval,
}: {
  claimWSwell: ClaimMerkl
  unlockWSwell: UnlockWSwell
  mutateMerklUser: () => void
  mutateEvkUser: () => void
  refreshInterval?: number
}) {
  useWeb3CallOnFulfill(claimWSwell, () => {
    mutateMerklUser()
  })
  useWeb3CallOnFulfill(unlockWSwell, () => {
    mutateEvkUser()
  })

  useEffect(() => {
    const interval = setInterval(() => {
      mutateEvkUser()
      mutateMerklUser()
    }, refreshInterval || 45_000)

    return () => {
      clearInterval(interval)
    }
  }, [mutateEvkUser, mutateMerklUser, refreshInterval])
}

export function useWSwellEagerLock({
  claimWSwell,
  merklUser,
  numLocksMemo,
  account,
  unlockWSwell,
}: {
  claimWSwell: ClaimMerkl
  unlockWSwell: UnlockWSwell
  merklUser: MerklUser | undefined
  numLocksMemo: number | undefined
  account: string | undefined
}) {
  const [eagerLock, setEagerLock] = useState<EagerEVKLock>()

  useWeb3CallOnFulfill(claimWSwell, () => {
    if (!claimWSwell.args) return
    if (!merklUser) return
    if (numLocksMemo === undefined) return

    const now = Date.now()
    const unclaimed = merklUser.unclaimedBig

    setEagerLock((p) => {
      if (p) return p
      return {
        lockedAmount: unclaimed,
        lockTimestampUnix: Math.floor(now / 1000),
        numLocksAtTimeOfClaim: numLocksMemo,
      }
    })
  })

  useWeb3CallOnFulfill(unlockWSwell, () => {
    setEagerLock(undefined)
  })

  useEffect(() => {
    setEagerLock(undefined)
  }, [account])

  return eagerLock
}
