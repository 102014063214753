import { Token, TokenKey } from '@/types/tokens'
import {
  INucleusVaultApiRead,
  INucleusVaultApiWrite,
  NucleusAllowances,
  NucleusBalances,
  NucleusSharesState,
  NucleusVaultAuth,
  NucleusVaultPoints,
  NucleusVaultState,
  NucleusVaultStats,
} from './types'
import { NucleusVaultContext } from './context'
import { BigNumber, PayableOverrides } from 'ethers'
import {
  AtomicQueue,
  BoringVault,
  IERC20__factory,
  Multicall3,
  MultiChainLayerZeroTellerWithMultiAssetSupport,
  TellerWithMultiAssetSupport,
} from '@/abis/types'
import { useSwellWeb3 } from '@/swell-web3/core'
import { TOKEN_LIST_ETH } from '@/constants/tokens'
import {
  useAtomicQueue,
  useAtomicQueueL2,
  useBoringVault,
  useBoringVaultL2,
  useBoringVaultTeller,
  useBoringVaultTellerL2,
  useMulticallContract,
  useReadonlyMulticallL2,
} from '@/hooks/useContract'
import { SolmateRolesAuthorityService } from '@/services/SolmateRolesAuthority'
import { NucleusVaultService } from '@/services/NucleusVaultService'
import { useMemo } from 'react'
import { TokenMulticalls } from '@/services/Tokens'
import { useV3BackendClient } from '@/services/V3BackendService/hooks'
import {
  NucleusFulfilledRequestEvent,
  NucleusRates,
  NucleusShareUnlockResult,
  NucleusTokens,
  NucleusVault,
  NucleusWithdrawRequestResult,
} from '@/types/nucleus'
import { NucleusClient } from '@/services/V3BackendService/types'
import { latestNucleusWithdrawRequest } from '@/util/nucleus'
import { useNucleusVaultBackendSettings } from '../deployments/hooks/nucleusSettings'
import { getAddress } from 'ethers/lib/utils'
import useChainDetection from '@/hooks/useChainDetection'

const DEPOSIT_SIGNATURE = '0x0efe6a8b' // keccak256("deposit(address,uint256,uint256)")

// The service provides a batch of recent AtomicQueue AtomicRequestFulfilled events for the user.
export interface INucleusFulfilledRequestsFetcher {
  fulfilledRequests: (params: {
    account: string
    vaultToken: string
    wantAssetKeys: TokenKey[]
  }) => Promise<NucleusFulfilledRequestEvent[]>
}
export interface INucleusVaultStatsFetcher {
  vaultTokenStats: (params: {
    vaultToken: string
  }) => Promise<NucleusVaultStats>
}
export interface INucleusPointsFetcher {
  points: (params: { account: string }) => Promise<NucleusVaultPoints>
}

export function useNucleusVaultImpl({
  fulfilledRequestsFetcher,
  atomicQueueAddress,
  vault,
  vaultStatsFetcher,
  pointsFetcher,
  atomicQueueAddressL2,
}: {
  vault: NucleusVault
  fulfilledRequestsFetcher: INucleusFulfilledRequestsFetcher
  atomicQueueAddress: string
  vaultStatsFetcher: INucleusVaultStatsFetcher
  pointsFetcher: INucleusPointsFetcher
  atomicQueueAddressL2: string
}): NucleusVaultContext {
  return {
    read: useNucleusVaultReadImpl({
      fulfilledRequestsFetcher,
      vault,
      atomicQueueAddress,
      vaultStatsFetcher,
      pointsFetcher,
      atomicQueueAddressL2,
    }),
    write: useNucleusVaultWriteImpl({ vault, atomicQueueAddress }),
    atomicQueueAddress,
    ...vault,
  }
}

async function fetchAuth({
  account,
  rolesAuthorityAddress,
  multicall,
  tellerAddress,
}: {
  account: string
  multicall: Multicall3
  rolesAuthorityAddress: string
  tellerAddress: string
}): Promise<NucleusVaultAuth> {
  const rolesAuth = new SolmateRolesAuthorityService(
    multicall,
    rolesAuthorityAddress
  )

  const [{ canCall: canDeposit }] = await rolesAuth.fetchCanCall([
    {
      user: account,
      target: tellerAddress,
      sig: DEPOSIT_SIGNATURE,
    },
  ])

  return {
    isAuthorizedToDeposit: canDeposit,
    isAuthorizedToWithdraw: true, // permissionless
  }
}

async function fetchAssetBalancesForChain({
  account,
  multicall,
  vaultTokenAddress,
  depositAssetAddresses,
  withdrawAssetAddresses,
}: {
  account: string
  multicall: Multicall3
  vaultTokenAddress: string
  depositAssetAddresses: string[]
  withdrawAssetAddresses: string[]
}): Promise<NucleusBalances['assets'][number]> {
  const assets: Record<string, BigNumber> = {}

  const tc = new TokenMulticalls(multicall)
  const addressesSet = new Set<string>([vaultTokenAddress])
  for (const addr of [...depositAssetAddresses, ...withdrawAssetAddresses]) {
    addressesSet.add(addr)
  }

  const hadETH = addressesSet.delete(TOKEN_LIST_ETH.address)
  const results = await tc.fetchBalances(Array.from(addressesSet), account)

  for (const res of results) {
    assets[res.token] = res.balance
  }

  if (hadETH) {
    const ethBalance = await multicall.provider.getBalance(account)
    assets[TOKEN_LIST_ETH.address] = BigNumber.from(ethBalance)
  }

  return assets
}

async function fetchBalances({
  account,
  multicall,
  multicallL2,
  vault,
}: {
  account: string
  multicall: Multicall3
  multicallL2: Multicall3
  vault: NucleusVault
}): Promise<NucleusBalances> {
  const { depositAssets, withdrawAssets, vaultToken, vaultTokenL2 } = vault

  const l1ChainId = vaultToken.chainId
  const l2ChainId = vaultTokenL2.chainId

  const depositAssetsL1 = depositAssets.filter((t) => t.chainId === l1ChainId)
  const depositAssetsL2 = depositAssets.filter((t) => t.chainId === l2ChainId)
  const withdrawAssetsL1 = withdrawAssets.filter((t) => t.chainId === l1ChainId)
  const withdrawAssetsL2 = withdrawAssets.filter((t) => t.chainId === l2ChainId)

  const assetsL1P = fetchAssetBalancesForChain({
    account,
    multicall,
    vaultTokenAddress: vaultToken.address,
    depositAssetAddresses: depositAssetsL1.map((t) => t.address),
    withdrawAssetAddresses: withdrawAssetsL1.map((t) => t.address),
  })
  const assetsL2P = fetchAssetBalancesForChain({
    account,
    multicall: multicallL2,
    vaultTokenAddress: vaultTokenL2.address,
    depositAssetAddresses: depositAssetsL2.map((t) => t.address),
    withdrawAssetAddresses: withdrawAssetsL2.map((t) => t.address),
  })
  const [assetsL1, assetsL2] = await Promise.all([assetsL1P, assetsL2P])

  return {
    assets: {
      [l1ChainId]: assetsL1,
      [l2ChainId]: assetsL2,
    },
    vaultToken: {
      [l1ChainId]: assetsL1[vaultToken.address],
      [l2ChainId]: assetsL2[vaultTokenL2.address],
    },
  }
}

async function fetchAllowancesForChain({
  account,
  multicall,
  vaultToken,
  atomicQueueAddress,
  depositAssetAddresses,
}: {
  account: string
  multicall: Multicall3
  vaultToken: { address: string; chainId: number }
  atomicQueueAddress: string
  depositAssetAddresses: string[]
}): Promise<NucleusAllowances> {
  const vaultTokenAddress = vaultToken.address
  const chainId = vaultToken.chainId

  const assetsForDeposit: Record<string, BigNumber> = {}
  const ts = new TokenMulticalls(multicall)
  const [first, ...rest] = await ts.fetchAllowances(
    [
      {
        spender: atomicQueueAddress,
        token: vaultTokenAddress,
      },
      ...depositAssetAddresses.map((token) => ({
        spender: vaultTokenAddress,
        token,
      })),
    ],
    account
  )

  const vaultTokenForAtomicQueue = first.allowance

  for (const res of rest) {
    assetsForDeposit[res.token] = res.allowance
  }

  return {
    assetsForDeposit: {
      [chainId]: assetsForDeposit,
    },
    vaultTokenForAtomicQueue: {
      [chainId]: vaultTokenForAtomicQueue,
    },
  }
}

async function fetchAllowances({
  account,
  multicall,
  multicallL2,
  vault,
  atomicQueueAddress,
  atomicQueueAddressL2,
}: {
  account: string
  multicall: Multicall3
  multicallL2: Multicall3
  vault: NucleusVault
  atomicQueueAddress: string
  atomicQueueAddressL2: string
}): Promise<NucleusAllowances> {
  const depositAssetAddressesL1 = vault.depositAssets
    .filter((t) => t.chainId === vault.vaultToken.chainId)
    .map((t) => t.address)
  const depositAssetAddressesL2 = vault.depositAssets
    .filter((t) => t.chainId === vault.vaultTokenL2.chainId)
    .map((t) => t.address)

  const assetsForDepositL1P = fetchAllowancesForChain({
    account,
    multicall,
    vaultToken: vault.vaultToken,
    atomicQueueAddress,
    depositAssetAddresses: depositAssetAddressesL1,
  })
  const assetsForDepositL2P = fetchAllowancesForChain({
    account,
    multicall: multicallL2,
    vaultToken: vault.vaultTokenL2,
    atomicQueueAddress: atomicQueueAddressL2,
    depositAssetAddresses: depositAssetAddressesL2,
  })

  const [
    {
      assetsForDeposit: assetsForDepositL1,
      vaultTokenForAtomicQueue: vaultTokenForAtomicQueueL1,
    },
    {
      assetsForDeposit: assetsForDepositL2,
      vaultTokenForAtomicQueue: vaultTokenForAtomicQueueL2,
    },
  ] = await Promise.all([assetsForDepositL1P, assetsForDepositL2P])

  return {
    assetsForDeposit: {
      [vault.vaultToken.chainId]: assetsForDepositL1[vault.vaultToken.chainId],
      [vault.vaultTokenL2.chainId]:
        assetsForDepositL2[vault.vaultTokenL2.chainId],
    },
    vaultTokenForAtomicQueue: {
      [vault.vaultToken.chainId]:
        vaultTokenForAtomicQueueL1[vault.vaultToken.chainId],
      [vault.vaultTokenL2.chainId]:
        vaultTokenForAtomicQueueL2[vault.vaultTokenL2.chainId],
    },
  }
}

async function fetchRates({
  assetAddresses,
  nucleus,
}: {
  assetAddresses: string[]
  nucleus: NucleusVaultService
}): Promise<NucleusRates> {
  const addressSet = new Set(assetAddresses)
  return nucleus.rates({
    assetAddresses: Array.from(addressSet),
  })
}

async function fetchRecentFulfilledRequests({
  fulfilledRequestsFetcher,
  account,
  wantAssets,
  vaultToken,
}: {
  fulfilledRequestsFetcher: INucleusFulfilledRequestsFetcher
  account: string
  wantAssets: string[]
  vaultToken: Token
}): Promise<NucleusFulfilledRequestEvent[]> {
  const events = await fulfilledRequestsFetcher.fulfilledRequests({
    account,
    wantAssetKeys: wantAssets.map((a) => ({
      address: a,
      chainId: vaultToken.chainId,
    })),
    vaultToken: vaultToken.address,
  })

  return events
}
async function fetchSharesState(): Promise<NucleusSharesState> {
  const shareUnlock: NucleusShareUnlockResult = { isLocked: false }
  return {
    shareUnlock,
  }
}

async function fetchVaultStats({
  fetcher,
  vaultTokenAddress,
}: {
  fetcher: INucleusVaultStatsFetcher
  vaultTokenAddress: string
}): Promise<NucleusVaultStats> {
  return fetcher.vaultTokenStats({
    vaultToken: vaultTokenAddress,
  })
}
async function fetchSupportedTokens({
  nvs,
}: {
  nvs: NucleusVaultService
}): Promise<NucleusTokens> {
  return nvs.supportedTokens()
}
async function fetchVaultState({
  teller,
  nucleusClient,
  vault,
  l1ChainId,
}: {
  teller: TellerWithMultiAssetSupport
  nucleusClient: NucleusClient
  vaultTokenAddress: string
  vault: NucleusVault
  l1ChainId: number
}): Promise<NucleusVaultState> {
  const isDepositPaused = await teller.isPaused()

  const { withdrawAssets, vaultToken } = vault
  const {
    solverFees: solverFeesResp,
    withdrawalProcessingDurationUnix,
    performanceFeePercent,
    platformFeePercent,
  } = await nucleusClient.vaultState({ vaultToken: vaultToken.address })

  const solverFees: NucleusVaultState['solverFees'] = {}
  for (const s of solverFeesResp) {
    const {
      highFeePercent,
      lowFeePercent,
      wantTokenAddress: wantTokenAddressNoChecksum,
      lowFeeThresholdWei,
      wantTokenChainId,
    } = s

    const wantTokenAddress = getAddress(wantTokenAddressNoChecksum)
    const wantTokenChain = wantTokenChainId

    const lowFeeThreshold = BigNumber.from(lowFeeThresholdWei)
    if (!solverFees[wantTokenChain]) {
      solverFees[wantTokenChain] = {}
    }
    solverFees[wantTokenChain][wantTokenAddress] = {
      feeHighPercent: highFeePercent,
      feeLowPercent: lowFeePercent,
      lowFeeThreshold: lowFeeThreshold,
    }
  }
  for (const wantToken of withdrawAssets) {
    if (wantToken.chainId !== l1ChainId) {
      continue
    }

    const wantTokenAddress = wantToken.address
    const wantTokenChain = wantToken.chainId
    if (!solverFees?.[wantTokenChain]?.[wantTokenAddress]) {
      console.warn(
        `fetchVaultState: no solver fees for wantToken ${wantTokenAddress} ${wantTokenChain}`,
        {
          solverFees,
          wantTokenAddress,
        }
      )
      if (!solverFees[wantTokenChain]) {
        solverFees[wantTokenChain] = {}
      }

      solverFees[wantTokenChain][wantTokenAddress] = {
        feeHighPercent: 0,
        feeLowPercent: 0,
        lowFeeThreshold: BigNumber.from(0),
      }
    }
  }

  return {
    isDepositPaused,
    performanceFeePercent,
    solverFees,
    withdrawalProcessingDurationUnix,
    platformFeePercent,
  }
}
async function fetchWithdrawRequest({
  nvs,
  account,
  wantTokenKeys,
}: {
  nvs: NucleusVaultService
  account: string
  wantTokenKeys: TokenKey[]
}): Promise<NucleusWithdrawRequestResult> {
  const allReqsForAsset = await nvs.withdrawalRequestsByWantToken({
    wantTokenKeys,
    user: account,
  })
  const latest = latestNucleusWithdrawRequest(allReqsForAsset, wantTokenKeys)
  return latest
}
function useNucleusVaultReadImpl({
  fulfilledRequestsFetcher,
  vault,
  atomicQueueAddress,
  atomicQueueAddressL2,
  vaultStatsFetcher,
  pointsFetcher,
}: {
  fulfilledRequestsFetcher: INucleusFulfilledRequestsFetcher
  vault: NucleusVault
  atomicQueueAddressL2: string
  atomicQueueAddress: string
  vaultStatsFetcher: INucleusVaultStatsFetcher
  pointsFetcher: INucleusPointsFetcher
}): INucleusVaultApiRead {
  const { account: maybeAccount, provider } = useSwellWeb3()
  const multicall = useMulticallContract()
  const multicallL2 = useReadonlyMulticallL2()
  const teller = useBoringVaultTeller(vault.tellerAddress)

  const vaultTokenAddress = vault.vaultToken.address

  const { nucleusBackendURL } = useNucleusVaultBackendSettings()

  const nucleusClient =
    useV3BackendClient(nucleusBackendURL).v3BackendClient.nucleus
  const nucleusWalletClient =
    useV3BackendClient(nucleusBackendURL).v3BackendClient.nucleusWallet

  const {
    l2DeploymentChainId,
    deploymentChainId,
    chainId: currentChainId,
  } = useChainDetection()

  const l1ChainId = deploymentChainId
  const l2ChainId = l2DeploymentChainId

  const nvs = useMemo(
    () =>
      new NucleusVaultService({
        l1ChainId,
        l2ChainId,
        multicallL2,
        atomicQueueAddress,
        multicall,
        vault,
      }),
    [atomicQueueAddress, multicall, vault, l1ChainId, l2ChainId, multicallL2]
  )

  const account = maybeAccount!

  return {
    auth: async () => {
      return fetchAuth({
        account,
        tellerAddress: vault.tellerAddress,
        multicall,
        rolesAuthorityAddress: vault.rolesAuthorityAddress,
      })
    },
    balances: async () => {
      return fetchBalances({
        account,
        multicall,
        multicallL2,
        vault,
      })
    },
    allowances: async () => {
      return fetchAllowances({
        account,
        atomicQueueAddress,
        atomicQueueAddressL2,
        multicall,
        multicallL2,
        vault,
      })
    },
    rates: async () => {
      return fetchRates({
        assetAddresses: vault.depositAssets.map((t) => t.address),
        nucleus: nvs,
      })
    },
    recentFulfilledRequests: async () => {
      return fetchRecentFulfilledRequests({
        account,
        fulfilledRequestsFetcher,
        vaultToken: vault.vaultToken,
        wantAssets: vault.withdrawAssets.map((t) => t.address),
      })
    },
    sharesState: async () => {
      return fetchSharesState()
    },
    stats: async () => {
      return fetchVaultStats({
        fetcher: vaultStatsFetcher,
        vaultTokenAddress,
      })
    },
    supportedAssets: async () => {
      return fetchSupportedTokens({
        nvs,
      })
    },
    vaultState: async () => {
      return fetchVaultState({
        teller,
        nucleusClient,
        vaultTokenAddress,
        vault,
        l1ChainId,
      })
    },
    withdrawRequest: async () => {
      return fetchWithdrawRequest({
        account,
        nvs,
        wantTokenKeys: vault.withdrawAssets,
      })
    },
    points: async () => {
      return pointsFetcher.points({ account })
    },
    checkDeposit: async () => {
      return true
    },
    checkWithdrawalRequest: async ({ wantAssetAddress, withdrawRequest }) => {
      const res = await nucleusWalletClient.verifyWithdrawal({
        atomicPriceWei: withdrawRequest.atomicPrice.toString(),
        atomicQueueAddress,
        offerToken: vault.vaultToken.address,
        offerTokenAmount: withdrawRequest.offerAmount.toString(),
        user: account,
        wantToken: wantAssetAddress,
        chainId: currentChainId,
      })

      const { isValid, failMessage } = res
      if (!isValid) {
        return {
          valid: false,
          reason: failMessage,
        }
      }
      return {
        valid: true,
      }
    },
  }
}

function useNucleusVaultWriteImpl({
  atomicQueueAddress,
  vault,
}: {
  vault: NucleusVault
  atomicQueueAddress: string
}): INucleusVaultApiWrite {
  const { provider, chainId } = useSwellWeb3()

  const atomicQueueL1 = useAtomicQueue(atomicQueueAddress)
  const atomicQueueL2 = useAtomicQueueL2(atomicQueueAddress)
  const boringVaultL1 = useBoringVault(vault.vaultToken.address)
  const boringVaultL2 = useBoringVaultL2(vault.vaultToken.address)
  const tellerL1 = useBoringVaultTeller(vault.tellerAddress)
  const tellerL2 = useBoringVaultTellerL2(vault.tellerAddressL2)

  type GenericTeller =
    | TellerWithMultiAssetSupport
    | MultiChainLayerZeroTellerWithMultiAssetSupport

  let atomicQueue: AtomicQueue | undefined
  let boringVault: BoringVault | undefined
  let teller: GenericTeller | undefined
  if (chainId === vault.vaultToken.chainId) {
    atomicQueue = atomicQueueL1
    boringVault = boringVaultL1
    teller = tellerL1
  } else if (chainId === vault.vaultTokenL2.chainId) {
    atomicQueue = atomicQueueL2
    boringVault = boringVaultL2
    teller = tellerL2
  }

  return {
    approveAssetForDeposit: ({ assetAddress, amount }, opts) => {
      if (
        chainId !== vault.vaultToken.chainId &&
        chainId !== vault.vaultTokenL2.chainId
      ) {
        throw new Error('invalid chain')
      }
      const erc20 = IERC20__factory.connect(assetAddress, provider.getSigner())
      return erc20.approve(vault.vaultToken.address, amount, opts)
    },
    approveAssetForDepositEstimateGas: async ({ assetAddress, amount }) => {
      if (
        chainId !== vault.vaultToken.chainId &&
        chainId !== vault.vaultTokenL2.chainId
      ) {
        throw new Error('invalid chain')
      }
      const erc20 = IERC20__factory.connect(assetAddress, provider.getSigner())
      return erc20.estimateGas.approve(vault.vaultToken.address, amount)
    },
    approveVaultTokenForAtomicQueue: ({ amount }, opts) => {
      if (!boringVault) {
        throw new Error('invalid chain')
      }
      return boringVault.approve(atomicQueueAddress, amount, opts)
    },
    approveVaultTokenForAtomicQueueEstimateGas: ({ amount }) => {
      if (!boringVault) {
        throw new Error('invalid chain')
      }
      return boringVault.estimateGas.approve(atomicQueueAddress, amount)
    },
    deposit: ({ depositAsset, depositAmount, minimumMint }, userOpts) => {
      if (!teller) {
        throw new Error('invalid chain')
      }
      const opts: PayableOverrides = userOpts ?? {}
      if (depositAsset === TOKEN_LIST_ETH.address) {
        // native deposit requires value to be set
        opts.value = depositAmount
      }
      return teller.deposit(depositAsset, depositAmount, minimumMint, userOpts)
    },
    depositEstimateGas: ({ depositAsset, depositAmount, minimumMint }) => {
      if (!teller) {
        throw new Error('invalid chain')
      }
      return teller.estimateGas.deposit(
        depositAsset,
        depositAmount,
        minimumMint
      )
    },
    requestWithdrawal: ({ wantAssetAddress, withdrawRequest }, opts) => {
      if (!atomicQueue) {
        throw new Error('invalid chain')
      }
      return atomicQueue.updateAtomicRequest(
        vault.vaultToken.address,
        wantAssetAddress,
        {
          atomicPrice: withdrawRequest.atomicPrice,
          deadline: withdrawRequest.deadlineUnix,
          inSolve: false,
          offerAmount: withdrawRequest.offerAmount,
        },
        opts
      )
    },
    requestWithdrawalEstimateGas: ({ wantAssetAddress, withdrawRequest }) => {
      if (!atomicQueue) {
        throw new Error('invalid chain')
      }
      return atomicQueue.estimateGas.updateAtomicRequest(
        vault.vaultToken.address,
        wantAssetAddress,
        {
          atomicPrice: withdrawRequest.atomicPrice,
          deadline: withdrawRequest.deadlineUnix,
          inSolve: false,
          offerAmount: withdrawRequest.offerAmount,
        }
      )
    },
    cancelWithdrawal: ({ wantAssetAddress }, opts) => {
      if (!atomicQueue) {
        throw new Error('invalid chain')
      }
      return atomicQueue.updateAtomicRequest(
        vault.vaultToken.address,
        wantAssetAddress,
        {
          atomicPrice: BigNumber.from(0),
          deadline: BigNumber.from(0),
          inSolve: false,
          offerAmount: BigNumber.from(0),
        },
        opts
      )
    },
    cancelWithdrawalEstimateGas: ({ wantAssetAddress }) => {
      if (!atomicQueue) {
        throw new Error('invalid chain')
      }
      return atomicQueue.estimateGas.updateAtomicRequest(
        vault.vaultToken.address,
        wantAssetAddress,
        {
          atomicPrice: BigNumber.from(0),
          deadline: BigNumber.from(0),
          inSolve: false,
          offerAmount: BigNumber.from(0),
        }
      )
    },
  }
}
