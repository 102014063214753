import { useV3BackendClient } from '@/services/V3BackendService/hooks'
import { useSwellWeb3 } from '@/swell-web3/core'
import { BigNumber } from 'ethers'
import { WSwellMerklContext } from './context'
import { useWSwellMerklSettings } from '../deployments/hooks/useWSwellMerklSettings'
import { EVKLockBalanceMapResp, EVKLockResp, MerklUserResp } from './types'
import { ERC20WrapperLocked__factory, Multicall3 } from '@/abis/types'
import {
  useReadonlyMulticallL2,
  useWSwellContract,
  useWSwellDistributor,
} from '@/hooks/useContract'
import useChainDetection from '@/hooks/useChainDetection'
import { getChainName } from '@/constants/chainInfo'
import { useRef } from 'react'

const evkIface = ERC20WrapperLocked__factory.createInterface()

export function useWSwellMerklApiImpl(): WSwellMerklContext {
  const { account: maybeAccount } = useSwellWeb3()
  const { isL2DeploymentChain, l2DeploymentChainId } = useChainDetection()
  const { merklBackendURL, WSwellToken, maturityDurationUnix } =
    useWSwellMerklSettings()

  const account = maybeAccount!

  const merklWallet =
    useV3BackendClient(merklBackendURL).v3BackendClient.merklWallet

  const multicallL2 = useReadonlyMulticallL2()
  const wswellContract = useWSwellContract()
  const distributorContract = useWSwellDistributor()

  const balancesCache = useRef<EVKLockBalanceMapResp>({})

  return {
    maturityDurationUnix,
    WSwellToken,
    read: {
      wswellMerklUser: async () => {
        const { pendingWei, proofsHex, accumulatedWei } =
          await merklWallet.wSwell({ walletAddress: account })

        const { amount: cumulativeClaimed } = await distributorContract.claimed(
          account,
          wswellContract.address
        )

        const accumulatedWSwell = BigNumber.from(accumulatedWei)
        const trueClaimable = accumulatedWSwell.sub(cumulativeClaimed)

        const r = {
          accumulatedWSwell: BigNumber.from(accumulatedWei),
          pendingWSwell: BigNumber.from(pendingWei),
          proofsHex,
          unclaimedWSwell: trueClaimable,
        } as MerklUserResp

        if (r.unclaimedWSwell.lt(0)) {
          r.unclaimedWSwell = BigNumber.from(0)
        }
        return r
      },
      wswellEVKLocks: async () => {
        const [lockTimestamps, lockedAmount] =
          await wswellContract.getLockedAmounts(account)

        const locks: EVKLockResp[] = []
        for (let i = 0; i < lockTimestamps.length; i++) {
          locks.push({
            lockTimestampUnix: lockTimestamps[i].toNumber(),
            lockedAmount: BigNumber.from(lockedAmount[i]),
          })
        }
        return locks
      },
      wswellEVKLockBalances: (signal, lockTimestamps) => {
        const lockBalanceMessages: EVKLockBalanceMapResp = {}
        const uncachedLockTimestamps: number[] = []
        for (const lockTimestamp of lockTimestamps) {
          const cached = balancesCache.current[lockTimestamp]
          if (cached) {
            lockBalanceMessages[lockTimestamp] = cached
            continue
          }
          uncachedLockTimestamps.push(lockTimestamp)
        }

        return (async function* () {
          yield lockBalanceMessages

          const calls: Multicall3.CallStruct[] = []
          for (const lockTimestamp of uncachedLockTimestamps) {
            calls.push({
              target: WSwellToken.address,
              callData: evkIface.encodeFunctionData(
                'getWithdrawAmountsByLockTimestamp',
                [account, lockTimestamp]
              ),
            })
          }

          const results = await multicallL2.callStatic.tryAggregate(true, calls)
          if (signal.aborted) {
            return
          }

          for (let i = 0; i < uncachedLockTimestamps.length; i++) {
            const lockTimestamp = uncachedLockTimestamps[i]
            const [accountAmount, remainderAmount] =
              evkIface.decodeFunctionResult(
                'getWithdrawAmountsByLockTimestamp',
                results[i].returnData
              )
            lockBalanceMessages[lockTimestamp] = {
              accountAmount,
              remainderAmount,
            }
            yield lockBalanceMessages
            balancesCache.current[lockTimestamp] =
              lockBalanceMessages[lockTimestamp]
          }
        })()
      },
    },
    write: {
      claimMerkl: async ({ proofsHex, cumulativeAmount }, opts) => {
        if (!isL2DeploymentChain) {
          throw new Error(`Not on ${getChainName(l2DeploymentChainId) ?? 'L2'}`)
        }
        if (!proofsHex?.length) {
          throw new Error('No proofs provided')
        }

        return distributorContract.claim(
          [account],
          [WSwellToken.address],
          [cumulativeAmount],
          [proofsHex],
          opts
        )
      },
      claimMerklEstimateGas: async ({ proofsHex, cumulativeAmount }) => {
        if (!isL2DeploymentChain) {
          throw new Error(`Not on ${getChainName(l2DeploymentChainId) ?? 'L2'}`)
        }
        if (!proofsHex?.length) {
          throw new Error('No proofs provided')
        }

        return distributorContract.estimateGas.claim(
          [account],
          [WSwellToken.address],
          [cumulativeAmount],
          [proofsHex]
        )
      },
      unlockWSwell: async ({ lockTimestampUnix, mustUnlockAll }, opts) => {
        if (!isL2DeploymentChain) {
          throw new Error(`Not on ${getChainName(l2DeploymentChainId) ?? 'L2'}`)
        }
        const allowRemainderLoss = !mustUnlockAll
        return wswellContract.withdrawToByLockTimestamp(
          account,
          lockTimestampUnix,
          allowRemainderLoss,
          opts
        )
      },
      unlockWSwellEstimateGas: async ({ lockTimestampUnix, mustUnlockAll }) => {
        if (!isL2DeploymentChain) {
          throw new Error(`Not on ${getChainName(l2DeploymentChainId) ?? 'L2'}`)
        }
        const allowRemainderLoss = !mustUnlockAll
        return wswellContract.estimateGas.withdrawToByLockTimestamp(
          account,
          lockTimestampUnix,
          allowRemainderLoss
        )
      },
    },
  }
}
