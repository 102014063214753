import { useV3BackendClient } from '@/services/V3BackendService/hooks'
import { useDeploymentSetConfig } from '@/state/deployments/hooks'
import { useSwellWeb3 } from '@/swell-web3/core'
import { JsonRpcProvider } from '@ethersproject/providers'
import { useEffect } from 'react'
import { UAParser } from 'ua-parser-js'

function useTelemetryFor(provider: JsonRpcProvider, providerType: string) {
  const { rpcTelemetry } = useDeploymentSetConfig()
  const { v3BackendLrtUrl } = useDeploymentSetConfig()

  // const v3BackendLrtUrl = 'http://localhost:8081'
  const { account, chainId } = useSwellWeb3()

  const monitoring =
    useV3BackendClient(v3BackendLrtUrl).v3BackendClient.monitoring

  useEffect(() => {
    if (!rpcTelemetry || !monitoring) return

    const onDebug = (msg: any) => {
      const { action, request } = msg as { action: string; request: any }
      const parser = new UAParser(window.navigator.userAgent)
      const { type: deviceType } = parser.getDevice()
      monitoring.rpcMonitor({
        action,
        request: JSON.stringify(request),
        chainId,
        account: account ?? '',
        deviceType,
        providerType,
        durationMs: 0,
        url: provider.connection.url,
        windowLocation: window.location.href,
      })
    }

    provider.on('debug', onDebug)
    return () => {
      provider.off('debug', onDebug)
    }
  }, [account, chainId, monitoring, provider, providerType, rpcTelemetry])
}

export function useWeb3Telemetry() {
  const { provider, readOnlyProvider, readOnlyProviderL2 } = useSwellWeb3()
  useTelemetryFor(provider, 'main')
  useTelemetryFor(readOnlyProvider, 'read-only')
  useTelemetryFor(readOnlyProviderL2, 'read-only-l2')
}
