import React, { useMemo } from 'react'
import { Route, Routes } from 'react-router-dom'
import styled from 'styled-components/macro'
import { useSwellWeb3 } from '@swell-web3/core'
import { Stack } from '@swell-ui/Stack'
import { useSwellUiTheme } from '@swell-ui/theme'
import { useMediaQuery } from '@swell-ui/theme/useMediaQuery'
import { ConnectWalletButton } from '@/components/ConnectWalletButton'
import { useSwEthBalance } from '@/state/user/hooks'
import { OperatorAccountButton } from './OperatorAccountButton'
import { RestakerAccountButton } from './RestakerAccountButton'
import { StakerAccountButton } from './StakerAccountButton'
import { EthAccountInfoWidget } from './EthAccountInfoWidget'
import { RestakingOperatorAccountButton } from './RestakingOperatorAccountButton'
import { SymbioticAccountButton } from './SymbioticAccountButton'
import { useBtcLrtBalances, useRswellBalances } from '@/state/yearnVault/hooks'
import { ROUTES } from '@/constants/routes'
import { useRswEthBalance } from '@/state/rsweth/hooks'
import { RswellAccountButton } from './RswellAccountButton'

const StyledButton = styled(ConnectWalletButton)`
  padding-left: 32.5px;
  padding-right: 32.5px;
`

function Web3NavWidget() {
  const { account } = useSwellWeb3()

  const swEthBalance = useSwEthBalance()
  const rswEthBalance = useRswEthBalance()
  const btcLrtBalances = useBtcLrtBalances()

  const { theme } = useSwellUiTheme()
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'))

  const accountButton = useMemo(() => {
    if (!account) return null

    return (
      <Routes>
        <Route path="/operators/*" element={<OperatorAccountButton />} />
        <Route
          path="/restaking-operators/*"
          element={<RestakingOperatorAccountButton />}
        />
        <Route
          path={ROUTES.StakeSwETH}
          element={
            <StakerAccountButton
              swEthBalance={swEthBalance.data?.balance}
              loading={swEthBalance.isLoading}
            />
          }
        />
        <Route path={ROUTES.EarnETH} element={<></>} />
        <Route path={ROUTES.EarnBTC} element={<></>} />
        <Route
          path={ROUTES.StakeRswETH}
          element={
            <>
              <RestakerAccountButton
                rswEthBalance={rswEthBalance.data}
                loading={rswEthBalance.isLoading}
              />
            </>
          }
        />
        <Route path={ROUTES.EarnPortfolio} element={<></>} />
        <Route path={ROUTES.SwellchainLaunch} element={<></>} />
        <Route path={ROUTES.SwellchainEcosystem} element={<></>} />
        <Route path={ROUTES.EarnWSwell} element={<></>} />
        <Route path={ROUTES.DaoVoyage} element={<></>} />
        <Route path={ROUTES.DaoSwellCity} element={<></>} />
        <Route
          path={ROUTES.StakeSwBTC}
          element={
            <>
              <SymbioticAccountButton
                vaultTokenBalance={btcLrtBalances.data?.vaultToken}
              />
            </>
          }
        />
        <Route
          path={ROUTES.StakeSwell}
          element={
            <>
              <RswellAccountButton />
            </>
          }
        />
        <Route
          path="*"
          element={
            <>
              <StakerAccountButton
                swEthBalance={swEthBalance.data?.balance}
                loading={swEthBalance.isLoading}
              />
              <RestakerAccountButton
                rswEthBalance={rswEthBalance.data}
                loading={rswEthBalance.isLoading}
              />
            </>
          }
        />
      </Routes>
    )
  }, [
    account,
    swEthBalance.data?.balance,
    swEthBalance.isLoading,
    rswEthBalance.data,
    rswEthBalance.isLoading,
    btcLrtBalances.data?.vaultToken,
  ])

  return (
    <div>
      {!account && isSmUp && (
        <StyledButton size="small">Connect wallet</StyledButton>
      )}
      {account && (
        <Stack direction="row" alignItems="center">
          {accountButton}
          <EthAccountInfoWidget />
        </Stack>
      )}
    </div>
  )
}

export { Web3NavWidget }
