import { FlexRow } from '@/swell-ui/FlexRow'
import styled from 'styled-components'
import { NavigateExploreButton } from '../../SwellchainButtons'
import { L2PromoLaunchPartner } from '@/state/l2promotions/types'
import { ThemeData } from '@/swell-ui/theme/branding'
import { DividerDark } from '@/swell-ui/Divider'

export function LaunchPartnersSection({
  l2Promotions,
}: {
  l2Promotions: L2PromoLaunchPartner[] | undefined
}) {
  const majors: L2PromoLaunchPartner[] = []
  const dapps: L2PromoLaunchPartner[] = []
  const avs: L2PromoLaunchPartner[] = []
  for (const launchPartner of l2Promotions || []) {
    if (launchPartner.kind === 'major') {
      majors.push(launchPartner)
    } else if (launchPartner.kind === 'dapps') {
      dapps.push(launchPartner)
    } else if (launchPartner.kind === 'avs') {
      avs.push(launchPartner)
    }
  }

  return (
    <Layout>
      <FlexRow justify="space-between">
        <span className="headlines-h7">Launch Partners</span>
        <NavigateExploreButton />
      </FlexRow>

      <div id={'lpsbox'}>
        <div className={'lpsbox lpsbox--minor'}>
          <span
            className="uppercase-medium-white"
            style={{ textAlign: 'center' }}
          >
            Major launch partners
          </span>
          <div>
            {majors.map((launchPartner) => (
              <LaunchPartnerRestaking
                key={launchPartner.name}
                launchPartner={launchPartner}
              />
            ))}
          </div>
        </div>
        <DividerDark />
        <div className={'lpsbox lpsbox--minor'}>
          <span
            className="uppercase-medium-white"
            style={{ textAlign: 'center' }}
          >
            Dapps
          </span>
          <div>
            {dapps.map((launchPartner) => (
              <LaunchPartnerDapps
                key={launchPartner.name}
                launchPartner={launchPartner}
              />
            ))}
          </div>
        </div>
        <DividerDark />
        <div className={'lpsbox lpsbox--minor'}>
          <span
            className="uppercase-medium-white"
            style={{ textAlign: 'center' }}
          >
            AVS
          </span>
          <div>
            {avs.map((launchPartner) => (
              <LaunchPartnerAvs
                key={launchPartner.name}
                launchPartner={launchPartner}
              />
            ))}
          </div>
        </div>
      </div>
    </Layout>
  )
}

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  #lpsbox {
    border-radius: 16px;
    border: 1px solid rgba(164, 171, 241, 0.2);
    background: ${ThemeData.Swell.SwellOceanBlue150};
    backdrop-filter: blur(2px);
    padding: 42px;

    display: flex;
    flex-direction: column;
    gap: 42px;

    .lpsbox {
      max-width: 860px;
      margin: 0 auto;

      display: flex;
      flex-direction: column;
      gap: 24px;
      justify-content: center;
      align-items: center;

      > div {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;

        gap: 32px 16px;
        @media (max-width: 768px) {
          gap: 32px 8px;
        }
      }
    }

    hr {
      margin: 0;
    }
  }

  .launch-partner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 12px;
    width: 120px;

    img {
      width: 60px;
      height: 60px;
      border-radius: 100px;
    }

    span:first-of-type {
      color: var(--Swell-White-150, #818181);
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 160%; /* 19.2px */
      letter-spacing: 0.9px;
      text-transform: uppercase;
      white-space: nowrap;
    }

    span:nth-of-type(2) {
      color: #e6e6e6;
      text-align: center;
      /* Body/small */
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 160%; /* 22.4px */
      letter-spacing: -0.42px;
    }
  }
`

function LaunchPartnerMajorDapp({
  launchPartner,
}: {
  launchPartner: Omit<L2PromoLaunchPartner, 'kind'>
}) {
  return (
    <div className="launch-partner launch-partner--major-dapp">
      <img
        src={launchPartner.logoURI}
        width="32"
        height="32"
        style={{
          borderRadius: launchPartner.logoShape === 'square' ? '0' : '50%',
        }}
      />
      <span>{launchPartner.name}</span>
      <span style={{ height: '72px' }}>{launchPartner.description}</span>
    </div>
  )
}
function LaunchPartnerRestaking({
  launchPartner,
}: {
  launchPartner: Omit<L2PromoLaunchPartner, 'kind'>
}) {
  return (
    <div className="launch-partner launch-partner--restaking">
      <img
        src={launchPartner.logoURI}
        width="32"
        height="32"
        style={{
          borderRadius: launchPartner.logoShape === 'square' ? '0' : '50%',
        }}
      />
      <span>{launchPartner.name}</span>
    </div>
  )
}
function LaunchPartnerDapps({
  launchPartner,
}: {
  launchPartner: Omit<L2PromoLaunchPartner, 'kind'>
}) {
  return (
    <div className="launch-partner launch-partner--dapps">
      <img
        src={launchPartner.logoURI}
        width="32"
        height="32"
        style={{
          borderRadius: launchPartner.logoShape === 'square' ? '0' : '50%',
        }}
      />
      <span>{launchPartner.name}</span>
    </div>
  )
}
function LaunchPartnerAvs({
  launchPartner,
}: {
  launchPartner: Omit<L2PromoLaunchPartner, 'kind'>
}) {
  return (
    <div className="launch-partner launch-partner--avs">
      <img
        src={launchPartner.logoURI}
        width="32"
        height="32"
        style={{
          borderRadius: launchPartner.logoShape === 'square' ? '0' : '50%',
        }}
      />
      <span>{launchPartner.name}</span>
    </div>
  )
}
