import useSWRImmutable from 'swr/immutable'
import { useSwellWeb3 } from '@/swell-web3/core'
import { useWSwellMerklApi } from './context'
import { useRefreshRate } from '@/hooks/useMath'
import ms from 'ms.macro'
import useSWRSubscription from 'swr/subscription'
import { EagerEVKLock, EVKLockBalanceMapResp } from './types'
import { solidityKeccak256 } from 'ethers/lib/utils'
import { useMemo, useRef } from 'react'
import { buildEVKUser } from '@/util/eulerEVK'
import { EVKUser } from '@/types/eulerEVK'
import { useWeb3Call } from '@/hooks/useWeb3Call'
import { MerklUserWithProofAndAmount } from '@/types/merkl'
import { displayCryptoLocale } from '@/util/displayCrypto'
import { useNowMs } from '@/hooks/useTimeCountdown'

export function useWSwellEVKToken() {
  const { WSwellToken } = useWSwellMerklApi()
  return { WSwellToken }
}

export function useWSWellMerklUser() {
  const { read, WSwellToken } = useWSwellMerklApi()
  const { account } = useSwellWeb3()
  return useSWRImmutable(
    account ? ['wswell', 'merkl', account] : null,
    async (): Promise<MerklUserWithProofAndAmount> => {
      const a = await read.wswellMerklUser()

      return {
        unclaimedBig: a.unclaimedWSwell,
        cumulativeAmount: a.accumulatedWSwell,
        unclaimed: displayCryptoLocale(a.unclaimedWSwell, WSwellToken.decimals),
        proofsHex: a.proofsHex,
      }
    }
  )
}

export function useWSwellEVKUser({
  eagerLock,
  initialLockPercent,
}: {
  eagerLock: EagerEVKLock | undefined
  initialLockPercent: number
}) {
  const { account } = useSwellWeb3()
  const api = useWSwellMerklApi()
  const { WSwellToken: wswellToken, maturityDurationUnix } = api

  const ticker = useRefreshRate({ rate: ms`700ms` }) // dont trust swr
  const nowMs = useNowMs()

  const userLocksQuery = useSWRImmutable(
    account ? ['wswell', 'locks', account] : null,
    api.read.wswellEVKLocks
  )
  const userLocks = userLocksQuery.data
  const lockTimestamps = userLocks?.map((lock) => lock.lockTimestampUnix) ?? []

  const sub = useSWRSubscription(
    account
      ? ['wswell', 'locks', 'balances', account, ...lockTimestamps]
      : null,
    (__, { next }) => {
      const ac = new AbortController()
      if (lockTimestamps.length === 0) {
        return () => ac.abort()
      }

      const stream = api.read.wswellEVKLockBalances(ac.signal, lockTimestamps)
      ;(async () => {
        try {
          for await (const data of stream) {
            next(null, data)
          }
        } catch (e) {
          console.error('position balances', e)
          next(e, null)
        }
      })()
      return () => ac.abort()
    }
  )

  const balanceResp: EVKLockBalanceMapResp = sub.data || {}
  const persistedBalances = useRef<EVKLockBalanceMapResp>({})
  for (const [key, value] of Object.entries(balanceResp)) {
    persistedBalances.current[parseInt(key)] = value
  }

  // react doesn't work with deep objects, so we need to hash it manually
  const hash = solidityKeccak256(
    ['string'],
    [
      JSON.stringify({
        account,
        balances: persistedBalances,
        ticker,
        lockTimestamps,
        userLocks,
        eagerLock,
      }),
    ]
  )

  const data = useMemo<EVKUser | undefined>(() => {
    if (!userLocks) return undefined

    return buildEVKUser({
      balances: persistedBalances.current,
      evkToken: wswellToken,
      locks: userLocks,
      maturityDurationUnix,
      nowUnix: Math.floor(nowMs / 1000),
      eagerLock,
      initialLockPercent,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps -- manually computed
  }, [hash])

  return {
    error: userLocksQuery.error || sub.error,
    isLoading: userLocksQuery.isValidating,
    data,
    isValidating: userLocksQuery.isValidating,
    mutate: userLocksQuery.mutate,
  }
}

export function useClaimMerkl() {
  const { write } = useWSwellMerklApi()
  return useWeb3Call({
    estimateGas: write.claimMerklEstimateGas,
    fn: write.claimMerkl,
    validate: async (params) => {
      // todo check contract
      return null
    },
  })
}
export type ClaimMerkl = ReturnType<typeof useClaimMerkl>

export function useUnlockWSwell() {
  const { write } = useWSwellMerklApi()
  return useWeb3Call({
    estimateGas: write.unlockWSwellEstimateGas,
    fn: write.unlockWSwell,
    validate: async (params) => {
      // todo check contract
      return null
    },
  })
}
export type UnlockWSwell = ReturnType<typeof useUnlockWSwell>
