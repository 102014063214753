import { Token } from '@/types/tokens'
import { TokenLogoV2 } from '../TokenLogoV2'
import styled from 'styled-components'
import { MenuCaret } from '@/swell-ui/Menu'
import { FlexRow } from '@/swell-ui/FlexRow'

export function TokenTrigger({
  preventInteraction,
  onClick,
  selectedToken,
}: {
  preventInteraction?: boolean
  onClick?: () => void | undefined
  selectedToken: Token | undefined
}) {
  return (
    <TsPill
      onClick={() => {
        if (preventInteraction) return
        return onClick?.()
      }}
      style={{
        display: selectedToken ? 'flex' : 'none',
      }}
      aria-disabled={preventInteraction}
    >
      <FlexRow gap="6">
        <TokenLogoV2 token={selectedToken} />
        <span>{selectedToken?.symbol}</span>
      </FlexRow>
      <MenuCaret />
    </TsPill>
  )
}

const TsPill = styled.div`
  cursor: pointer;
  display: flex;
  height: 37.6px;
  padding: 6px;
  justify-content: center;
  align-items: center;

  gap: 12px;
  color: var(--white-50, #fff);
  /* Body/medium */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 25.6px */
  letter-spacing: -0.48px;

  [aria-disabled='true'] {
    cursor: default;
  }
  &:not([aria-disabled='true']):hover {
    color: var(--white-100, #fff);
    opacity: 0.7;
  }
`
