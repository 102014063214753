import { Box } from '@/swell-ui/Box'
import { TableV2 } from '@/swell-ui/TableV2/TableV2'
import { ReactNode } from 'react'
import { WSWELLUserSummary } from '../../types'
import styled from 'styled-components'
import {
  DisabledWSWELLUnlockButton,
  WSwellButton,
  WSwellConnectWallet,
  WSwellSwitchChain,
  WSWELLUnlockButton,
} from '../../WswellButtons'
import { PreparedUnlockWSwell } from '../../wswellCalls'
import {
  AmountToMatureCell,
  CurrentUnlockAmountCell,
  TimeTilMaturityCell,
} from '../common'
import { UnlockWSwell } from '@/state/wswell/hooks'
import { Token } from '@/types/tokens'
import { useSwellWeb3 } from '@/swell-web3/core'
import useChainDetection from '@/hooks/useChainDetection'
import { FlexRow } from '@/swell-ui/FlexRow'
import { css } from 'styled-components'
import { EagerEVKLock } from '@/state/wswell/types'

type Row = {
  amountToMature: ReactNode
  timeTilMaturity: ReactNode
  currentUnlockAmount: ReactNode
  unlockButton: ReactNode
}
type TableProps = React.ComponentProps<typeof TableV2<keyof Row, Row>>

export function MerklClaimTable({
  userSummary,
  allPreparedUnlockWSwell,
  unlockWSwell,
  WSwellToken,
  preventInteraction,
  eagerLock,
}: {
  WSwellToken: Token
  userSummary: WSWELLUserSummary
  allPreparedUnlockWSwell: Partial<Record<number, PreparedUnlockWSwell>>
  unlockWSwell: UnlockWSwell
  preventInteraction: boolean
  eagerLock: EagerEVKLock | undefined
}) {
  const { account } = useSwellWeb3()
  const { switchToL2DeploymentChain, isL2DeploymentChain } = useChainDetection()

  const tableProps: TableProps = {
    header: {
      amountToMature: {
        label: 'Amount To Mature',
      },
      timeTilMaturity: {
        label: 'Time Till Maturity',
      },
      currentUnlockAmount: {
        label: 'Current Unlock Amount',
      },
      unlockButton: {
        label: '',
        width: '195px',
        align: 'center',
      },
    },
    rows: [],
  }

  if (userSummary.locksLoaded) {
    for (const lock of userSummary.locks) {
      let unlockButton = <WSwellButton minWidth="77px">Unlock</WSwellButton>
      const preparedUnlockWSwell =
        allPreparedUnlockWSwell[lock.lockTimestampUnix]

      if (!preparedUnlockWSwell) {
        unlockButton = <DisabledWSWELLUnlockButton />
      } else {
        const isEagerLock =
          eagerLock?.lockTimestampUnix === lock.lockTimestampUnix
        unlockButton = (
          <WSWELLUnlockButton
            isEagerLock={isEagerLock}
            unlockWSwell={unlockWSwell}
            preventInteraction={preventInteraction}
            prepared={preparedUnlockWSwell}
            isMatured={lock.isMatured}
          />
        )
      }

      if (!isL2DeploymentChain) {
        unlockButton = (
          <WSwellButton onClick={switchToL2DeploymentChain}>
            Switch to Swellchain
          </WSwellButton>
        )
      }

      const r: (typeof tableProps.rows)[0] = {
        _key: `${account}-${lock.lockTimestampUnix}`,
        amountToMature: { loaded: false },
        timeTilMaturity: { loaded: false },
        currentUnlockAmount: { loaded: false },
        unlockButton: {
          loaded: true,
          result: <FlexRow direction="column">{unlockButton}</FlexRow>,
        },
      }

      if (lock.amountToMature) {
        r.amountToMature = {
          loaded: true,
          result: (
            <FlexRow
              className="mct-cell"
              justify="start"
              align="center"
              width="100%"
              height="100%"
            >
              <AmountToMatureCell lock={lock} />
            </FlexRow>
          ),
        }
      }
      if (lock.lockTimestampUnix && lock.percentUnlocked) {
        r.timeTilMaturity = {
          loaded: true,
          result: (
            <FlexRow
              className="mct-cell"
              justify="start"
              align="center"
              width="100%"
              height="100%"
            >
              <TimeTilMaturityCell lock={lock} />
            </FlexRow>
          ),
        }
      }
      if (lock.currentUnlockAmount) {
        r.currentUnlockAmount = {
          loaded: true,
          result: (
            <FlexRow
              className="mct-cell"
              justify="start"
              align="center"
              width="100%"
              height="100%"
            >
              <CurrentUnlockAmountCell lock={lock} evkToken={WSwellToken} />
            </FlexRow>
          ),
        }
      }

      tableProps.rows.push(r)
    }
  } else {
    for (let i = 0; i < 1; i++) {
      tableProps.rows.push({
        _key: `${i}`,
        amountToMature: { loaded: false },
        timeTilMaturity: { loaded: false },
        currentUnlockAmount: { loaded: false },
        unlockButton: {
          loaded: true,
          result: null,
        },
      })
    }
  }

  if (!isL2DeploymentChain) {
    tableProps.rows = []
  }
  if (!account) {
    tableProps.rows = []
  }

  return (
    <SBox empty={!tableProps.rows.length}>
      <TableV2 {...tableProps} maxHeight="100%" />
      {!account && (
        <div className="cw-positioner">
          <WSwellConnectWallet />
        </div>
      )}
      {account && !isL2DeploymentChain && (
        <div className="cw-positioner">
          <WSwellSwitchChain />
        </div>
      )}
    </SBox>
  )
}
const SBox = styled(Box)<{ empty?: boolean }>`
  /* height: 360px; */
  ${(p) =>
    p.empty &&
    css`
      height: 160px;
    `}

  border-radius: 16px;
  border: 1px solid rgba(164, 171, 241, 0.2);

  background: var(--Swell-Oceanblue-150, #010508);

  display: flex;
  padding: 32px 42px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;

  td.MuiTableCell-root {
    background: transparent;
  }

  .mct-cell {
    /* background: #ffc0cb30; */

    line-height: 2.6;
  }

  min-height: 270px;

  position: relative;
  .cw-positioner {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    display: flex;

    justify-content: center;
    align-items: center;

    button {
      position: relative;
      min-width: 182px;
      max-width: 182px;
      transform: translateY(24px);
    }
  }
`
