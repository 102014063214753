import { ExitClaim } from '@/types/claims'
import {
  SwETHClaimSummary,
  SwETHDepositInputs,
  SwETHDepositSummary,
  SwETHStatsSummary,
  SwETHWithdrawInputs,
  SwETHWithdrawRequestSummary,
  SwETHWithdrawSummary,
} from './types'
import { Prices, PrimaryRates } from '@/state/prices/types'
import { displayCryptoLocale } from '@/util/displayCrypto'
import { displayFiat } from '@/util/displayFiat'
import { formatUnits } from 'ethers/lib/utils'
import { formatWithConfig } from '@/util/number'
import {
  SwETHState,
  SwETHStats,
  SwETHUser,
  SwETHWithdrawalsUser,
} from '@/state/sweth/types'
import { isWithdrawRequestClaimable } from './util'
import { TransactionFeeResult } from '@/hooks/useTransactionFee'
import { BigNumber } from 'ethers'
import { displayPercentFromPercent } from '@/util/displayNumbers'
import { getChainInfo } from '@/constants/chainInfo'
import { getNativeCurrencyToExchangeForSweth } from '@/util/big'

export function makeSwETHDepositSummary({
  inputs: { nativeCurrencyAmount, swETHAmount },
  swETHToken,
  nativeCurrency,
  primaryRates,
  prices,
  fees,
  ethBalance,
  account,
}: {
  inputs: Pick<SwETHDepositInputs, 'nativeCurrencyAmount' | 'swETHAmount'>
  swETHToken: { decimals: number }
  nativeCurrency: { decimals: number }
  primaryRates: Pick<PrimaryRates, 'swETHPrimaryRate'> | undefined
  prices: Pick<Prices, 'ethUsd'> | undefined
  ethBalance: BigNumber | undefined
  fees: Pick<TransactionFeeResult, 'feeWei'> | undefined
  account: string | undefined
}): SwETHDepositSummary {
  const summary: SwETHDepositSummary = {
    nativeCurrencyAmount: '',
    swETHAmount: '',
    nativeCurrencyValueUSD: '',
    swETHValueUSD: '',
    availableETH: '',
    transactionFee: '',
  }

  if (nativeCurrencyAmount) {
    summary.nativeCurrencyAmount = displayCryptoLocale(
      nativeCurrencyAmount,
      nativeCurrency.decimals
    )
    if (prices) {
      summary.nativeCurrencyValueUSD = displayFiat(
        Number(formatUnits(nativeCurrencyAmount, nativeCurrency.decimals)) *
          prices.ethUsd.priceUsd
      )
    }
  }
  if (swETHAmount) {
    summary.swETHAmount = displayCryptoLocale(swETHAmount, swETHToken.decimals)
    if (primaryRates && prices) {
      const amountNative = getNativeCurrencyToExchangeForSweth({
        swethToEthRate: primaryRates.swETHPrimaryRate,
        toReceiveSweth: swETHAmount,
      })
      summary.swETHValueUSD = displayFiat(
        Number(formatUnits(amountNative, nativeCurrency.decimals)) *
          prices.ethUsd.priceUsd
      )
    }
  }
  if (fees && prices) {
    const feeETH = Number(
      formatUnits(fees.feeWei.toString(), nativeCurrency.decimals)
    )
    summary.transactionFee = displayFiat(feeETH * prices.ethUsd.priceUsd)
  }
  if (ethBalance) {
    summary.availableETH = displayCryptoLocale(
      ethBalance,
      nativeCurrency.decimals,
      { precision: 4 }
    )
  }

  if (!account) {
    summary.availableETH = '-'
  }

  return summary
}

export function makeSwETHWithdrawSummary({
  inputs,
  nativeCurrency,
  user,
  swETHToken,
  prices,
  primaryRates,
  fees,
  account,
}: {
  inputs: Pick<SwETHWithdrawInputs, 'nativeCurrencyAmount' | 'swETHAmount'>
  swETHToken: { decimals: number }
  nativeCurrency: { decimals: number }
  primaryRates: Pick<PrimaryRates, 'swETHPrimaryRate'> | undefined
  prices: Pick<Prices, 'ethUsd'> | undefined
  user: Pick<SwETHUser, 'swETHBalance'> | undefined
  fees: Pick<TransactionFeeResult, 'feeWei'> | undefined
  account: string | undefined
}): SwETHWithdrawSummary {
  const summary: SwETHWithdrawSummary = {
    nativeCurrencyAmount: '',
    nativeCurrencyValueUSD: '',
    swETHAmount: '',
    swETHValueUSD: '',
    availableSwETH: '',
    transactionFee: '',
  }

  if (inputs.nativeCurrencyAmount) {
    summary.nativeCurrencyAmount = displayCryptoLocale(
      inputs.nativeCurrencyAmount,
      nativeCurrency.decimals
    )
    if (prices) {
      const nativeCurrencyETH = Number(
        formatUnits(inputs.nativeCurrencyAmount, nativeCurrency.decimals)
      )
      summary.nativeCurrencyValueUSD = displayFiat(
        nativeCurrencyETH * prices.ethUsd.priceUsd
      )
    }
  }

  if (inputs.swETHAmount) {
    summary.swETHAmount = displayCryptoLocale(
      inputs.swETHAmount,
      swETHToken.decimals
    )

    if (primaryRates && prices) {
      const amountNative = getNativeCurrencyToExchangeForSweth({
        swethToEthRate: primaryRates.swETHPrimaryRate,
        toReceiveSweth: inputs.swETHAmount,
      })
      const nativeCurrencyETH = Number(
        formatUnits(amountNative, nativeCurrency.decimals)
      )

      summary.swETHValueUSD = displayFiat(
        nativeCurrencyETH * prices.ethUsd.priceUsd
      )
    }
  }

  if (user) {
    summary.availableSwETH = displayCryptoLocale(
      user.swETHBalance,
      swETHToken.decimals
    )
  }

  if (fees && prices) {
    const feeETH = Number(
      formatUnits(fees.feeWei.toString(), nativeCurrency.decimals)
    )
    summary.transactionFee = displayFiat(feeETH * prices.ethUsd.priceUsd)
  }

  if (!account) {
    summary.availableSwETH = '-'
  }

  return summary
}

export function makeSwETHWithdrawRequestSummary({
  claim,
  chainId,
  swEXITAddress,
}: {
  claim: ExitClaim
  chainId: number
  swEXITAddress: string
}): SwETHWithdrawRequestSummary {
  const { explorer } = getChainInfo(chainId)

  let nftTokenLink = ''
  if (explorer) {
    const u = new URL(explorer)
    u.pathname = `/nft/${swEXITAddress}/${claim.requestId}`
    nftTokenLink = u.toString()
  }

  // formatClaimDate formats a unix timestamp to the form "2023.12.25 12:12"
  function formatClaimDate(ts: number) {
    const d = new Date(ts * 1000)
    const year = d.getFullYear()
    const month = d.getMonth() + 1
    const day = d.getDate()
    const hours = String(d.getHours()).padStart(2, '0')
    const minutes = String(d.getMinutes()).padStart(2, '0')
    return `${year}.${month}.${day} ${hours}:${minutes}`
  }

  return {
    exchangeRate: formatWithConfig(claim.exchangeRate, {
      localize: true,
      precision: 4,
    }),
    queuePosition: formatWithConfig(claim.queuePosition, {
      localize: true,
      precision: 0,
    }),
    requestTime: formatClaimDate(claim.requestTimestamp),
    totalInQueue: formatWithConfig(claim.totalInQueue, {
      localize: true,
      precision: 0,
    }),
    nftTokenLink,
    tokenId: parseInt(claim.requestId),
    isClaimable: isWithdrawRequestClaimable(claim),
    swETHAmount: formatWithConfig(claim.tokenAmount, {
      localize: true,
      precision: 4,
    }),
    ethAmount: formatWithConfig(claim.assetAmount, {
      localize: true,
      precision: 4,
    }),
  }
}

export function makeSwETHClaimSummary({
  withdrawUser,
  chainId,
  swEXITAddress,
}: {
  withdrawUser: SwETHWithdrawalsUser | undefined
  swEXITAddress: string
  chainId: number
}): SwETHClaimSummary {
  const summary: SwETHClaimSummary = {
    isLoading: true,
    claimableETH: '',
    numClaimable: '',
    numClaimableNum: 0,
    numPending: '',
    numPendingNum: 0,
    numTotalNum: 0,
    pendingSwETH: '',
    requests: [],
  }
  if (!withdrawUser) return summary
  summary.isLoading = false

  const { exitClaims } = withdrawUser
  const claimable = exitClaims.filter((c) => isWithdrawRequestClaimable(c))
  const pending = exitClaims.filter((c) => !isWithdrawRequestClaimable(c))

  const numClaimableNum = claimable.length
  const numPendingNum = pending.length

  const numClaimable = formatWithConfig(numClaimableNum, {
    localize: true,
    precision: 0,
  })
  const numPending = formatWithConfig(numPendingNum, {
    localize: true,
    precision: 0,
  })

  const claimableETH = claimable.reduce((acc, c) => acc + c.assetAmount, 0)
  const pendingSwETH = pending.reduce((acc, c) => acc + c.tokenAmount, 0)

  summary.numTotalNum = numClaimableNum + numPendingNum
  summary.numClaimable = numClaimable
  summary.numClaimableNum = numClaimableNum
  summary.numPending = numPending
  summary.numPendingNum = numPendingNum
  summary.claimableETH = formatWithConfig(claimableETH, {
    localize: true,
    precision: 4,
  })
  summary.pendingSwETH = formatWithConfig(pendingSwETH, {
    localize: true,
    precision: 4,
  })
  summary.requests = exitClaims.map((claim) =>
    makeSwETHWithdrawRequestSummary({ claim: claim, chainId, swEXITAddress })
  )

  return summary
}

export function makeSwETHStatsSummary({
  stats,
  primaryRates,
  state,
  nativeCurrency,
}: {
  stats: SwETHStats | undefined
  state: SwETHState | undefined
  primaryRates: Pick<PrimaryRates, 'swETHPrimaryRate'> | undefined
  nativeCurrency: { decimals: number }
}): SwETHStatsSummary {
  const s: SwETHStatsSummary = {
    apr: '',
    commissionRate: '',
    exchangeRate: '',
    marketCap: '',
    numStakers: '',
    totalStaked: '',
  }

  if (stats) {
    const { aprPercent, numStakers, marketCapUsd } = stats
    s.apr = displayPercentFromPercent(aprPercent)
    s.numStakers = formatWithConfig(numStakers, {
      localize: true,
      precision: 0,
    })
    s.marketCap = displayFiat(marketCapUsd)
    s.totalStaked = `${displayCryptoLocale(
      stats.totalEthStaked,
      nativeCurrency.decimals
    )} ETH`
  }

  if (state) {
    const { nodeOperatorRewardPercentage, swellTreasuryRewardPercentage } =
      state
    const cmNum =
      Number(
        formatUnits(
          nodeOperatorRewardPercentage.add(swellTreasuryRewardPercentage),
          18
        )
      ) * 100
    s.commissionRate = displayPercentFromPercent(cmNum)
  }

  if (state && primaryRates) {
    const oneSwEthDisplay = '1 swETH'
    const equivalentEthDisplay = `${displayCryptoLocale(
      primaryRates.swETHPrimaryRate,
      18
    )} ETH`

    s.exchangeRate = `${oneSwEthDisplay} = ${equivalentEthDisplay}`
  }

  return s
}
