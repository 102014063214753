import styled, { css } from 'styled-components'
import { useSwellWeb3 } from '@/swell-web3/core'
import { useMediaQuery } from '@mui/material'
import { WSWELLBreakpoints } from './consts'
import { ReactNode, useEffect, useState } from 'react'
import { MerklClaimAccordion } from './components/MerklClaimAccordion'
import { MerklClaimTable } from './components/MerklClaimTable'
import {
  useWSwellEVKUser,
  useWSWellMerklUser,
  useClaimMerkl,
  useUnlockWSwell,
  useWSwellEVKToken,
} from '@/state/wswell/hooks'
import { AsyncDiv } from '@/swell-ui/AsyncDiv'
import { makeWSWELLUserSummary } from './wswellFormatting'
import { FlexRow } from '@/swell-ui/FlexRow'
import { Divider } from '@/swell-ui/Divider'
import useChainDetection from '@/hooks/useChainDetection'
import { WSwellButton, WSWELLClaimButton } from './WswellButtons'
import { prepareAllUnlockWSwell, prepareClaimWSwell } from './wswellCalls'
import { useTimeSinceMount } from '@/hooks/useMath'
import { useWeb3CallErrorNotify } from '@/hooks/useWeb3CallErrorNotify'
import { useWSwellEagerLock, useWSwellPageLiveness } from './hooks'
import { ClaimMerklToast, UnlockWSwellToast } from './WswellToasts'
import {
  useWeb3CallAutoClearError,
  useWeb3CallClearOnFulfill,
} from '@/hooks/useWeb3CallEvents'
import { useNowMs } from '@/hooks/useTimeCountdown'

const MERKL_LINK = 'https://merkl.angle.money/?search=swell'
const LOCK_DURATION_MS = 10 * 7 * 24 * 60 * 60 * 1000 // 10 weeks
const INITIAL_LOCK_PERCENT = 65

// https://www.figma.com/design/1trLPK52KCe6Nko8e2NCbU/Swell-Network-Design?node-id=12520-8728&p=f&t=3ZOlQdOuDw31feUg-0

const bodyMedium = css`
  /* Body/medium */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 25.6px */
  letter-spacing: -0.48px;
`
const bodyXSmall = css`
  /* Body/xsmall */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 19.2px */
  letter-spacing: -0.36px;
`
const bodyUppercaseSmall = css`
  /* Body/uppercase small */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 19.2px */
  letter-spacing: 0.9px;
  text-transform: uppercase;
`
const bodyXLargeBold = css`
  color: var(--Swell-White-50, #fff);
  /* Body/xlarge bold */
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
`

const headlinesH3 = css`
  color: var(--Swell-White-50, #fff);
  /* Headlines/H3 */
  font-family: Inter;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 57.6px */
  letter-spacing: -1.44px;
`
const headlinesH4 = css`
  color: var(--Swell-White-50, #fff);
  /* Headlines/H4 */
  font-family: Inter;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 48px */
  letter-spacing: -1.2px;
`

const headlinesH6 = css`
  color: var(--Swell-White-50, #fff);
  /* Headlines/H6 */
  font-family: Inter;
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 33.8px */
  letter-spacing: -0.78px;
`

const bodyUppercaseMedium = css`
  color: var(--Swell-Lightblue-75, #6e79e9);
  /* Body/uppercase medium */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 22.4px */
  letter-spacing: 1.05px;
  text-transform: uppercase;
`

const bodySmall = css`
  color: var(--Swell-White-50, #fff);
  /* Body/small */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 22.4px */
  letter-spacing: -0.42px;
`

export function ClaimAndUnlockWSWELLPage() {
  const timeSinceMount = useTimeSinceMount()
  const { WSwellToken } = useWSwellEVKToken()

  const claimWSwell = useClaimMerkl()
  const unlockWSwell = useUnlockWSwell()
  const anyTransactionInProgress = [unlockWSwell, claimWSwell].some(
    (call) =>
      call.status === call.STATUS.PENDING ||
      call.status === call.STATUS.PROMPTING
  )
  useWeb3CallErrorNotify(unlockWSwell)
  useWeb3CallErrorNotify(claimWSwell)
  useWeb3CallClearOnFulfill(unlockWSwell)
  useWeb3CallClearOnFulfill(claimWSwell)
  useWeb3CallAutoClearError(unlockWSwell)
  useWeb3CallAutoClearError(claimWSwell)

  const isMobile = useMediaQuery(`(max-width: ${WSWELLBreakpoints.mobile}px)`)
  const { account } = useSwellWeb3()
  const { switchToL2DeploymentChain, isL2DeploymentChain } = useChainDetection()
  const nowMs = useNowMs()

  const merklUserQuery = useWSWellMerklUser()
  const merklUser = merklUserQuery.data

  const [numLocksMemo, setNumLocksMemo] = useState<number>()
  const eagerLock = useWSwellEagerLock({
    claimWSwell,
    unlockWSwell,
    merklUser,
    account,
    numLocksMemo,
  })

  const evkUserQuery = useWSwellEVKUser({
    eagerLock,
    initialLockPercent: INITIAL_LOCK_PERCENT,
  })

  useWSwellPageLiveness({
    claimWSwell,
    mutateEvkUser: evkUserQuery.mutate,
    mutateMerklUser: merklUserQuery.mutate,
    unlockWSwell,
    refreshInterval: 24_000,
  })

  const evkUser = evkUserQuery.data
  const numLocks = evkUser?.locks.length
  useEffect(() => {
    setNumLocksMemo(numLocks)
  }, [numLocks])

  const userSummary = makeWSWELLUserSummary({
    account,
    evkUser,
    merklUser,
    lockDurationMs: LOCK_DURATION_MS,
    nowMs,
  })

  const preparedClaimWSwell = prepareClaimWSwell({ merklUser })
  const allPreparedUnlockWSwell = prepareAllUnlockWSwell({ evkUser })

  const preventInteraction = anyTransactionInProgress

  let table = null as ReactNode
  if (isMobile) {
    table = (
      <MerklClaimAccordion
        eagerLock={eagerLock}
        WSwellToken={WSwellToken}
        userSummary={userSummary}
        allPreparedUnlockWSwell={allPreparedUnlockWSwell}
        unlockWSwell={unlockWSwell}
        preventInteraction={preventInteraction}
      />
    )
  } else {
    table = (
      <MerklClaimTable
        eagerLock={eagerLock}
        WSwellToken={WSwellToken}
        userSummary={userSummary}
        allPreparedUnlockWSwell={allPreparedUnlockWSwell}
        unlockWSwell={unlockWSwell}
        preventInteraction={preventInteraction}
      />
    )
  }

  function renderUnclaimedSwell() {
    let step1Button = null
    if (!isL2DeploymentChain) {
      if (timeSinceMount > 1000) {
        if (account) {
          step1Button = (
            <WSwellButton onClick={switchToL2DeploymentChain}>
              Switch to Swellchain
            </WSwellButton>
          )
        }
      }
    } else if (
      userSummary.unclaimedWSwell &&
      userSummary.unclaimedWSwell !== '-' &&
      userSummary.unclaimedWSwell !== '0'
    ) {
      step1Button = (
        <WSWELLClaimButton
          claimWSwell={claimWSwell}
          prepared={preparedClaimWSwell}
          preventInteraction={preventInteraction}
        />
      )
    }

    if (isMobile) {
      return (
        <FlexRow
          direction={'column'}
          gap="8"
          maxWidth="100%"
          textAlign="left"
          align="start"
        >
          <span className="body-uppercase-medium gray-text ">
            Unclaimed wSWELL:
          </span>
          <FlexRow
            justify="start"
            align="center"
            className="inline-loader-sm"
            gap="24"
          >
            <AsyncDiv loading={!userSummary.unclaimedWSwell}>
              {() => (
                <span className="white-text body-xlarge-bold">
                  {userSummary.unclaimedWSwell}
                </span>
              )}
            </AsyncDiv>
            {step1Button}
          </FlexRow>
        </FlexRow>
      )
    }

    return (
      <FlexRow minWidth="320px" width="auto" gap="24" justify="space-between">
        <FlexRow className="inline-loader-lg">
          <span className="body-uppercase-medium gray-text ">
            Unclaimed wSWELL:{' '}
          </span>
          <AsyncDiv loading={!userSummary.unclaimedWSwell}>
            {() => (
              <span className="white-text body-xlarge-bold">
                {userSummary.unclaimedWSwell}
              </span>
            )}
          </AsyncDiv>
        </FlexRow>
        {step1Button}
      </FlexRow>
    )
  }

  function renderWSwellToUnlock() {
    if (isMobile) {
      return (
        <FlexRow
          direction={'column'}
          gap="8"
          maxWidth="100%"
          textAlign="left"
          align="start"
        >
          <span className="body-uppercase-medium gray-text ">
            Total wSWELL to unlock:
          </span>
          <FlexRow className="inline-loader-sm">
            <AsyncDiv loading={!userSummary.totalWSwellToUnlock}>
              {() => (
                <span className="white-text body-xlarge-bold">
                  {userSummary.totalWSwellToUnlock}
                </span>
              )}
            </AsyncDiv>
          </FlexRow>
        </FlexRow>
      )
    }

    return (
      <FlexRow className="inline-loader-lg">
        <span className="body-uppercase-medium gray-text ">
          Total wSWELL to unlock:{' '}
        </span>
        <AsyncDiv loading={!userSummary.totalWSwellToUnlock}>
          {() => (
            <span className="white-text body-xlarge-bold">
              {userSummary.totalWSwellToUnlock}
            </span>
          )}
        </AsyncDiv>
      </FlexRow>
    )
  }

  return (
    <PageStyles>
      <div className="a-title">
        <PageTitle />
      </div>
      <div className="a-description">
        <div className="body-medium">
          Claim and unlock wSWELL in two easy steps. Claiming will begin a 10
          week unlock period after which you are able to unlock 1:1 for SWELL,
          any early unlocking will incur a reduction in value.
        </div>
      </div>
      <div className="a-step-1">
        <FlexRow direction="column" align="flex-start" gap="24">
          <span className="body-uppercase-medium">Step 1</span>
          <span>
            Claim wSWELL below from{' '}
            <a href={MERKL_LINK} target="_blank" rel="noreferrer">
              Merkl
            </a>
            , a third-party distributor.
          </span>
          {renderUnclaimedSwell()}
        </FlexRow>
      </div>
      <div className="a-divider">
        <Divider />
      </div>
      <div className="a-step-2">
        <FlexRow
          direction="column"
          align="flex-start"
          justify="flex-start"
          gap="24"
        >
          <span className="body-uppercase-medium">Step 2</span>
          <span>
            Unlock wSWELL for SWELL on Swellchain. These are wrapped SWELL
            tokens currently maturing in your wallet. Wait till it is fully
            unlocked to redeem them in full.
          </span>
          {renderWSwellToUnlock()}
        </FlexRow>
      </div>
      <div className="a-table">{table}</div>
      <ClaimMerklToast
        anyTransactionInProgress={anyTransactionInProgress}
        claimWSwell={claimWSwell}
        merklUser={merklUser}
      />
      <UnlockWSwellToast
        evkUser={evkUser}
        unlockWSwell={unlockWSwell}
        anyTransactionInProgress={anyTransactionInProgress}
      />
    </PageStyles>
  )
}
function PageTitle() {
  const isMobile = useMediaQuery(`(max-width: ${WSWELLBreakpoints.mobile}px)`)

  return (
    <span className={isMobile ? 'headlines-h4' : 'headlines-h3'}>wSWELL</span>
  )
}

const pageStyles = css`
  .headlines-h3 {
    ${headlinesH3};
  }
  .headlines-h4 {
    ${headlinesH4};
  }
  .headlines-h6 {
    ${headlinesH6};
  }
  .body-uppercase-medium {
    ${bodyUppercaseMedium};
  }
  .headlines-h6-summary {
    ${headlinesH6}
    color: var(--Swell-White-75, #F1F1F1);
  }
  .body-medium {
    ${bodyMedium};
  }
  .body-small {
    ${bodySmall};
  }
  .body-xsmall {
    ${bodyXSmall};
  }
  .body-uppercase-small {
    color: var(--Swell-Lightblue-75, #6e79e9);
    ${bodyUppercaseSmall};
  }
  .body-xlarge-bold {
    ${bodyXLargeBold};
  }
  .nobreak {
    white-space: nowrap;
  }

  .inline-loader-lg {
    width: max-content;
    white-space: nowrap;
    display: flex;
    align-items: baseline;
    gap: 8px;
    row-gap: 24px;
    /* display: inline; */
    margin-top: 10px;
    [aria-busy='false'] {
      min-width: unset;
    }
    [aria-busy='true'] {
      height: 44.8px;
      display: flex;
      flex-direction: column;
      justify-content: baseline;
      align-items: stretch;
      .MuiSkeleton-root {
        margin-top: auto;
        margin-bottom: auto;
      }
    }
    > span:first-child {
      margin-top: auto;
      margin-bottom: 5.5px;
    }
  }

  .inline-loader-sm {
    white-space: nowrap;
    [aria-busy='false'] {
      min-width: unset;
    }
    [aria-busy='true'] {
      height: 44.8px;
      display: flex;
      flex-direction: column;
      justify-content: baseline;
      align-items: stretch;

      .MuiSkeleton-root {
        margin-top: auto;
        margin-bottom: auto;
      }
    }
  }

  .gray-text {
    color: var(--Swell-White-125, #b0b0b0);
  }
  .white-text {
    color: var(--Swell-White-50, #fff);
  }

  .MuiTableCell-head {
    color: var(--Swell-Lightblue-75, #6e79e9);
    ${bodyUppercaseSmall};
  }
`

const mobileLayout = css`
  margin: 0 auto;
  max-width: 958px;
  grid-template-columns: 1fr;
  grid-auto-rows: auto;
  grid-template-areas:
    'title'
    'description'
    'step-1'
    'divider'
    'step-2'
    'table';

  .a-title {
    margin-bottom: 69px;
    margin-bottom: 16px;
  }

  .a-description {
    margin-bottom: 32px;
  }

  .a-divider {
    margin-top: 42px;
    margin-bottom: 42px;
  }

  .a-table {
    margin-top: 56px;
  }
`
const tabletLayout = css``
const desktopLayout = css`
  margin: 0 auto;
  max-width: 958px;
  grid-template-columns: 1fr;
  grid-auto-rows: auto;
  grid-template-areas:
    'title'
    'description'
    'step-1'
    'divider'
    'step-2'
    'table';

  .a-title {
    margin-bottom: 69px;
    margin-bottom: 16px;
  }

  .a-description {
    margin-bottom: 75px;
  }

  .a-divider {
    margin-top: 42px;
    margin-bottom: 42px;
  }

  .a-table {
    margin-top: 56px;
  }
`
const layout = css`
  margin-top: 24px;
  overflow: visible;
  display: grid;

  .a-title {
    grid-area: title;
  }
  .a-description {
    grid-area: description;
    color: var(--Swell-White-125, #b0b0b0);
  }
  .a-step-1 {
    grid-area: step-1;
  }
  .a-divider {
    grid-area: divider;
  }
  .a-step-2 {
    grid-area: step-2;
  }
  .a-table {
    grid-area: table;
    margin-bottom: 75px;
  }

  ${mobileLayout}
  @media (min-width: ${WSWELLBreakpoints.mobile}px) {
    ${tabletLayout}
  }
  @media (min-width: ${WSWELLBreakpoints.tablet}px) {
    ${desktopLayout}
  }
`

const PageStyles = styled.div`
  ${layout};
  ${pageStyles};
`
