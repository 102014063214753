// @generated by protoc-gen-es v1.3.3 with parameter "target=ts"
// @generated from file swell/v3/layerzerobridge.proto (package swell.v3, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum swell.v3.LayerZeroStatus
 */
export enum LayerZeroStatus {
  /**
   * @generated from enum value: LAYER_ZERO_STATUS_UNKNOWN = 0;
   */
  UNKNOWN = 0,

  /**
   * @generated from enum value: LAYER_ZERO_STATUS_PENDING = 1;
   */
  PENDING = 1,

  /**
   * @generated from enum value: LAYER_ZERO_STATUS_CONFIRMED = 2;
   */
  CONFIRMED = 2,

  /**
   * @generated from enum value: LAYER_ZERO_STATUS_ERROR = 3;
   */
  ERROR = 3,

  /**
   * @generated from enum value: LAYER_ZERO_STATUS_REFUND = 4;
   */
  REFUND = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(LayerZeroStatus)
proto3.util.setEnumType(LayerZeroStatus, "swell.v3.LayerZeroStatus", [
  { no: 0, name: "LAYER_ZERO_STATUS_UNKNOWN" },
  { no: 1, name: "LAYER_ZERO_STATUS_PENDING" },
  { no: 2, name: "LAYER_ZERO_STATUS_CONFIRMED" },
  { no: 3, name: "LAYER_ZERO_STATUS_ERROR" },
  { no: 4, name: "LAYER_ZERO_STATUS_REFUND" },
]);

/**
 * @generated from message swell.v3.LayerZeroQuoteRequest
 */
export class LayerZeroQuoteRequest extends Message<LayerZeroQuoteRequest> {
  /**
   * @generated from field: int32 from_chain_id = 1;
   */
  fromChainId = 0;

  /**
   * @generated from field: int32 to_chain_id = 2;
   */
  toChainId = 0;

  /**
   * @generated from field: string from_token_address = 3;
   */
  fromTokenAddress = "";

  /**
   * @generated from field: string from_amount_wei = 4;
   */
  fromAmountWei = "";

  /**
   * @generated from field: int32 from_decimals = 5;
   */
  fromDecimals = 0;

  /**
   * @generated from field: string to_token_address = 6;
   */
  toTokenAddress = "";

  /**
   * @generated from field: int32 to_decimals = 7;
   */
  toDecimals = 0;

  /**
   * @generated from field: float slippage_percent = 8;
   */
  slippagePercent = 0;

  /**
   * @generated from field: string gas_on_destination_chain_wei = 9;
   */
  gasOnDestinationChainWei = "";

  /**
   * @generated from field: string from_account = 10;
   */
  fromAccount = "";

  /**
   * @generated from field: string to_account = 11;
   */
  toAccount = "";

  constructor(data?: PartialMessage<LayerZeroQuoteRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.LayerZeroQuoteRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "from_chain_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "to_chain_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "from_token_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "from_amount_wei", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "from_decimals", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "to_token_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "to_decimals", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 8, name: "slippage_percent", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 9, name: "gas_on_destination_chain_wei", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "from_account", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "to_account", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LayerZeroQuoteRequest {
    return new LayerZeroQuoteRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LayerZeroQuoteRequest {
    return new LayerZeroQuoteRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LayerZeroQuoteRequest {
    return new LayerZeroQuoteRequest().fromJsonString(jsonString, options);
  }

  static equals(a: LayerZeroQuoteRequest | PlainMessage<LayerZeroQuoteRequest> | undefined, b: LayerZeroQuoteRequest | PlainMessage<LayerZeroQuoteRequest> | undefined): boolean {
    return proto3.util.equals(LayerZeroQuoteRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.LayerZeroQuote
 */
export class LayerZeroQuote extends Message<LayerZeroQuote> {
  /**
   * @generated from field: int32 estTimeSeconds = 1;
   */
  estTimeSeconds = 0;

  /**
   * @generated from field: int32 fromChainId = 2;
   */
  fromChainId = 0;

  /**
   * @generated from field: string fromAmountWei = 3;
   */
  fromAmountWei = "";

  /**
   * @generated from field: int32 fromDecimals = 4;
   */
  fromDecimals = 0;

  /**
   * @generated from field: string fromTokenSymbol = 5;
   */
  fromTokenSymbol = "";

  /**
   * @generated from field: float fromAmountUsd = 6;
   */
  fromAmountUsd = 0;

  /**
   * @generated from field: float gasCostUsd = 7;
   */
  gasCostUsd = 0;

  /**
   * @generated from field: string rateWei = 8;
   */
  rateWei = "";

  /**
   * @generated from field: string toAmountWei = 9;
   */
  toAmountWei = "";

  /**
   * @generated from field: float toAmountUsd = 10;
   */
  toAmountUsd = 0;

  /**
   * @generated from field: int32 toChainId = 11;
   */
  toChainId = 0;

  /**
   * @generated from field: int32 toDecimals = 12;
   */
  toDecimals = 0;

  /**
   * @generated from field: string toTokenSymbol = 13;
   */
  toTokenSymbol = "";

  /**
   * @generated from field: string approveAddress = 14;
   */
  approveAddress = "";

  constructor(data?: PartialMessage<LayerZeroQuote>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.LayerZeroQuote";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "estTimeSeconds", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "fromChainId", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "fromAmountWei", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "fromDecimals", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "fromTokenSymbol", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "fromAmountUsd", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 7, name: "gasCostUsd", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 8, name: "rateWei", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "toAmountWei", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "toAmountUsd", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 11, name: "toChainId", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 12, name: "toDecimals", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 13, name: "toTokenSymbol", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "approveAddress", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LayerZeroQuote {
    return new LayerZeroQuote().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LayerZeroQuote {
    return new LayerZeroQuote().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LayerZeroQuote {
    return new LayerZeroQuote().fromJsonString(jsonString, options);
  }

  static equals(a: LayerZeroQuote | PlainMessage<LayerZeroQuote> | undefined, b: LayerZeroQuote | PlainMessage<LayerZeroQuote> | undefined): boolean {
    return proto3.util.equals(LayerZeroQuote, a, b);
  }
}

/**
 * @generated from message swell.v3.LayerZeroPreparedTransaction
 */
export class LayerZeroPreparedTransaction extends Message<LayerZeroPreparedTransaction> {
  /**
   * @generated from field: uint32 chainId = 1;
   */
  chainId = 0;

  /**
   * @generated from field: string to = 2;
   */
  to = "";

  /**
   * @generated from field: string from = 3;
   */
  from = "";

  /**
   * @generated from field: string gasLimitWei = 4;
   */
  gasLimitWei = "";

  /**
   * @generated from field: string data = 5;
   */
  data = "";

  /**
   * @generated from field: string value = 6;
   */
  value = "";

  constructor(data?: PartialMessage<LayerZeroPreparedTransaction>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.LayerZeroPreparedTransaction";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "chainId", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "to", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "gasLimitWei", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "data", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LayerZeroPreparedTransaction {
    return new LayerZeroPreparedTransaction().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LayerZeroPreparedTransaction {
    return new LayerZeroPreparedTransaction().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LayerZeroPreparedTransaction {
    return new LayerZeroPreparedTransaction().fromJsonString(jsonString, options);
  }

  static equals(a: LayerZeroPreparedTransaction | PlainMessage<LayerZeroPreparedTransaction> | undefined, b: LayerZeroPreparedTransaction | PlainMessage<LayerZeroPreparedTransaction> | undefined): boolean {
    return proto3.util.equals(LayerZeroPreparedTransaction, a, b);
  }
}

/**
 * @generated from message swell.v3.LayerZeroQuoteResponse
 */
export class LayerZeroQuoteResponse extends Message<LayerZeroQuoteResponse> {
  /**
   * @generated from field: bool found = 1;
   */
  found = false;

  /**
   * @generated from field: swell.v3.LayerZeroQuote quote = 2;
   */
  quote?: LayerZeroQuote;

  /**
   * @generated from field: swell.v3.LayerZeroPreparedTransaction preparedTransaction = 3;
   */
  preparedTransaction?: LayerZeroPreparedTransaction;

  constructor(data?: PartialMessage<LayerZeroQuoteResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.LayerZeroQuoteResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "found", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "quote", kind: "message", T: LayerZeroQuote },
    { no: 3, name: "preparedTransaction", kind: "message", T: LayerZeroPreparedTransaction },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LayerZeroQuoteResponse {
    return new LayerZeroQuoteResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LayerZeroQuoteResponse {
    return new LayerZeroQuoteResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LayerZeroQuoteResponse {
    return new LayerZeroQuoteResponse().fromJsonString(jsonString, options);
  }

  static equals(a: LayerZeroQuoteResponse | PlainMessage<LayerZeroQuoteResponse> | undefined, b: LayerZeroQuoteResponse | PlainMessage<LayerZeroQuoteResponse> | undefined): boolean {
    return proto3.util.equals(LayerZeroQuoteResponse, a, b);
  }
}

/**
 * @generated from message swell.v3.LayerZeroTxState
 */
export class LayerZeroTxState extends Message<LayerZeroTxState> {
  /**
   * @generated from field: string tx_hash = 1;
   */
  txHash = "";

  /**
   * @generated from field: string account = 2;
   */
  account = "";

  /**
   * @generated from field: int32 timestampUnix = 3;
   */
  timestampUnix = 0;

  /**
   * @generated from field: int32 fromChainId = 4;
   */
  fromChainId = 0;

  /**
   * @generated from field: int32 toChainId = 5;
   */
  toChainId = 0;

  /**
   * @generated from field: string error = 12;
   */
  error = "";

  /**
   * @generated from field: swell.v3.LayerZeroStatus status = 13;
   */
  status = LayerZeroStatus.UNKNOWN;

  /**
   * @generated from field: string url = 14;
   */
  url = "";

  constructor(data?: PartialMessage<LayerZeroTxState>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.LayerZeroTxState";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "tx_hash", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "account", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "timestampUnix", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "fromChainId", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "toChainId", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 12, name: "error", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "status", kind: "enum", T: proto3.getEnumType(LayerZeroStatus) },
    { no: 14, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LayerZeroTxState {
    return new LayerZeroTxState().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LayerZeroTxState {
    return new LayerZeroTxState().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LayerZeroTxState {
    return new LayerZeroTxState().fromJsonString(jsonString, options);
  }

  static equals(a: LayerZeroTxState | PlainMessage<LayerZeroTxState> | undefined, b: LayerZeroTxState | PlainMessage<LayerZeroTxState> | undefined): boolean {
    return proto3.util.equals(LayerZeroTxState, a, b);
  }
}

/**
 * @generated from message swell.v3.LayerZeroTxStatusRequest
 */
export class LayerZeroTxStatusRequest extends Message<LayerZeroTxStatusRequest> {
  /**
   * @generated from field: repeated string tx_hashes = 1;
   */
  txHashes: string[] = [];

  constructor(data?: PartialMessage<LayerZeroTxStatusRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.LayerZeroTxStatusRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "tx_hashes", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LayerZeroTxStatusRequest {
    return new LayerZeroTxStatusRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LayerZeroTxStatusRequest {
    return new LayerZeroTxStatusRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LayerZeroTxStatusRequest {
    return new LayerZeroTxStatusRequest().fromJsonString(jsonString, options);
  }

  static equals(a: LayerZeroTxStatusRequest | PlainMessage<LayerZeroTxStatusRequest> | undefined, b: LayerZeroTxStatusRequest | PlainMessage<LayerZeroTxStatusRequest> | undefined): boolean {
    return proto3.util.equals(LayerZeroTxStatusRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.LayerZeroTxStatusResponse
 */
export class LayerZeroTxStatusResponse extends Message<LayerZeroTxStatusResponse> {
  /**
   * @generated from field: repeated swell.v3.LayerZeroTxState states = 1;
   */
  states: LayerZeroTxState[] = [];

  constructor(data?: PartialMessage<LayerZeroTxStatusResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.LayerZeroTxStatusResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "states", kind: "message", T: LayerZeroTxState, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LayerZeroTxStatusResponse {
    return new LayerZeroTxStatusResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LayerZeroTxStatusResponse {
    return new LayerZeroTxStatusResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LayerZeroTxStatusResponse {
    return new LayerZeroTxStatusResponse().fromJsonString(jsonString, options);
  }

  static equals(a: LayerZeroTxStatusResponse | PlainMessage<LayerZeroTxStatusResponse> | undefined, b: LayerZeroTxStatusResponse | PlainMessage<LayerZeroTxStatusResponse> | undefined): boolean {
    return proto3.util.equals(LayerZeroTxStatusResponse, a, b);
  }
}

