import styled from 'styled-components'
import { css } from 'styled-components'
import { useEveryNthBlock } from '@/hooks/useBlockNumber'
import {
  useBtcLrtAllowances,
  useBtcLrtAuth,
  useBtcLrtBalances,
  useBtcLrtVault,
  useBtcLrtWithdrawRequest,
  useBtcLrtVaultStats,
  useBtcLrtVaultState,
  useBtcLrtVaultPoints,
  useBtcLrtVaultCalls,
  useBtcLrtLegacyVaultCalls,
  useBtcLrtLegacyWithdrawRequest,
  useBtcLrtLegacyVaultState,
} from '@/state/yearnVault/hooks'
import { useEffect } from 'react'
import { ThemeData } from '@/swell-ui/theme/branding'
import { YearnAeraPageTemplate } from '@/components/YearnAeraPage/YearnAeraPageTemplate'
import eigenLayerGreyLogo from '@/assets/images/eigen-grey-80x80.png'
import { VaultYieldSource, YearnWithdrawAssetV1 } from '@/types/yearnAera'
import { YearnAeraPointsCampaigns } from '@/components/YearnAeraPage/types'
import { usePrices } from '@/state/prices/hooks'
import { URI_SYMBIOTIC_LOGO } from '@/constants/logo'

const READ_MORE_HREF = 'https://www.swellnetwork.io/post/swbtc'

const titleCSS = css`
  .v-title {
    color: ${ThemeData.Symbiotic.symbioticTitleGrey};
    .btc {
      color: ${ThemeData.Symbiotic.symbioticBtcOrange};
    }
    ${({ theme }) => theme.breakpoints.up('sm')} {
      white-space: nowrap;
      br {
        display: none;
      }
    }
  }
`
const title = (
  <>
    Swell <span className="btc">BTC</span> LRT
  </>
)

const pageDescription = (
  <>
    An automated vault that restakes WBTC into restaking protocols like
    Symbiotic (Karak and Eigenlayer coming soon) to generate yield and secure
    BTCFi.{' '}
    <a href={READ_MORE_HREF} target="_blank" rel="noopener noreferrer">
      Read more here
    </a>
    .
  </>
)

const TERMS_OF_SERVICE_HREF =
  'https://www.swellnetwork.io/legal/terms-of-service'
const VAULT_AGREEMENT_HREF =
  'https://www.swellnetwork.io/legal/gauntlet-vault-agreement-and-disclaimer'

const vaultDescription = (
  <>
    <p>
      This is a multi-strategy vault that optimally restakes wBTC into a mix of
      BTC restaking protocols for maximum risk adjusted yields.
    </p>
    <br />
    <p>
      Disclaimer:
      <br />
      swBTC vault is not open to persons or citizens of the United States and
      other restricted countries - for more details please refer to the{' '}
      <a href={TERMS_OF_SERVICE_HREF} rel="noopener noreferrer" target="_blank">
        terms of service
      </a>{' '}
      and{' '}
      <a href={VAULT_AGREEMENT_HREF} rel="noopener noreferrer" target="_blank">
        vault agreement
      </a>
      .
    </p>
  </>
)
const pointsCampaigns: YearnAeraPointsCampaigns = {
  symbiotic: {
    logoURI: URI_SYMBIOTIC_LOGO,
    name: 'Symbiotic Points',
    multiplier: 1,
    comingSoon: false,
  },
  eigen: {
    logoURI: eigenLayerGreyLogo,
    name: 'Eigenlayer Points',
    multiplier: 1,
    comingSoon: true,
  },
}

const yieldSources: VaultYieldSource[] = [
  {
    name: 'Symbiotic',
    logoURI: URI_SYMBIOTIC_LOGO,
    comingSoon: false,
  },
  {
    name: 'Eigenlayer',
    logoURI: eigenLayerGreyLogo,
    comingSoon: true,
  },
]

export function BtcLrtVault() {
  const vault = useBtcLrtVault()

  const vaultStatsQuery = useBtcLrtVaultStats()
  const vaultStateQuery = useBtcLrtVaultState()
  const authQuery = useBtcLrtAuth()
  const balancesQuery = useBtcLrtBalances()
  const allowancesQuery = useBtcLrtAllowances()
  const withdrawRequestQuery = useBtcLrtWithdrawRequest()
  const vaultPointsQuery = useBtcLrtVaultPoints()
  const pricesQuery = usePrices()

  const legacyWithdrawRequestQuery = useBtcLrtLegacyWithdrawRequest()
  const legacyVaultStateQuery = useBtcLrtLegacyVaultState()

  useEveryNthBlock({
    blocksPerFetch: 1,
    fetch: () => {
      vaultStateQuery.mutate()
      allowancesQuery.mutate()
      balancesQuery.mutate()
      withdrawRequestQuery.mutate()
      legacyWithdrawRequestQuery.mutate()
    },
    skip: false,
  })

  useEveryNthBlock({
    blocksPerFetch: 4,
    fetch: () => {
      vaultStatsQuery.mutate()
      pricesQuery.mutate()
    },
    skip: false,
  })

  useEveryNthBlock({
    blocksPerFetch: 10,
    fetch: () => {
      vaultPointsQuery.mutate()
    },
    skip: false,
  })

  const calls = useBtcLrtVaultCalls()
  const {
    approveAssetForDeposit,
    approveVaultTokenForWithdraw,
    cancelWithdraw,
    deposit,
    requestWithdraw,
    completeWithdraw,
  } = calls

  useEffect(() => {
    if (
      approveAssetForDeposit.status === approveAssetForDeposit.STATUS.FULFILLED
    ) {
      allowancesQuery.mutate()
    }
  }, [
    allowancesQuery,
    approveAssetForDeposit.STATUS.FULFILLED,
    approveAssetForDeposit.status,
  ])
  useEffect(() => {
    if (
      approveVaultTokenForWithdraw.status ===
      approveVaultTokenForWithdraw.STATUS.FULFILLED
    ) {
      allowancesQuery.mutate()
    }
  }, [
    allowancesQuery,
    approveVaultTokenForWithdraw.STATUS.FULFILLED,
    approveVaultTokenForWithdraw.status,
  ])
  useEffect(() => {
    if (deposit.status === deposit.STATUS.FULFILLED) {
      balancesQuery.mutate()
    }
  }, [balancesQuery, deposit.STATUS.FULFILLED, deposit.status])
  useEffect(() => {
    if (requestWithdraw.status === requestWithdraw.STATUS.FULFILLED) {
      withdrawRequestQuery.mutate()
      balancesQuery.mutate()
    }
  }, [balancesQuery, requestWithdraw, withdrawRequestQuery])
  useEffect(() => {
    if (cancelWithdraw.status === cancelWithdraw.STATUS.FULFILLED) {
      withdrawRequestQuery.mutate()
      balancesQuery.mutate()
    }
  }, [balancesQuery, cancelWithdraw, withdrawRequestQuery])
  useEffect(() => {
    if (completeWithdraw.status === completeWithdraw.STATUS.FULFILLED) {
      balancesQuery.mutate()
      withdrawRequestQuery.mutate()
    }
  }, [
    balancesQuery,
    completeWithdraw,
    completeWithdraw.status,
    withdrawRequestQuery,
  ])

  const allowances = allowancesQuery.data
  const balances = balancesQuery.data
  const depositAssetUsdRate = pricesQuery.data?.btcUsd
  const withdrawRequest = withdrawRequestQuery.data
  const vaultStats = vaultStatsQuery.data
  const auth = authQuery.data
  const vaultPoints = vaultPointsQuery.data
  const vaultState = vaultStateQuery.data

  const legacyWithdrawRequest = legacyWithdrawRequestQuery.data
  const legacyVaultState = legacyVaultStateQuery.data

  const legacyCalls = useBtcLrtLegacyVaultCalls()
  const { cancelWithdraw: cancelWithdrawLegacy } = legacyCalls
  useEffect(() => {
    if (cancelWithdrawLegacy.status === cancelWithdrawLegacy.STATUS.FULFILLED) {
      legacyWithdrawRequestQuery.mutate()
    }
  }, [
    cancelWithdrawLegacy.STATUS.FULFILLED,
    cancelWithdrawLegacy.status,
    legacyWithdrawRequestQuery,
  ])

  let legacyWithdrawAsset: YearnWithdrawAssetV1 | undefined
  if (legacyVaultState?.withdrawAsset) {
    legacyWithdrawAsset = legacyVaultState.withdrawAsset as YearnWithdrawAssetV1
  }
  return (
    <PageStyles>
      <YearnAeraPageTemplate
        legacyWithdrawAsset={legacyWithdrawAsset}
        legacyCalls={legacyCalls}
        legacyWithdrawRequest={legacyWithdrawRequest}
        vault={vault}
        title={title}
        pageDescription={pageDescription}
        vaultDescription={vaultDescription}
        yieldSources={yieldSources}
        pointsCampaigns={pointsCampaigns}
        depositAssetUsdRate={depositAssetUsdRate}
        vaultStats={vaultStats}
        allowances={allowances}
        auth={auth}
        balances={balances}
        calls={calls}
        vaultPoints={vaultPoints}
        vaultState={vaultState}
        withdrawRequest={withdrawRequest}
      />
    </PageStyles>
  )
}

const PageStyles = styled.div`
  ${titleCSS}
`
