import { WavedropClaimAirdrop } from '@/state/wavedrops/hooks'
import { Wavedrop, WavedropUser } from '@/state/wavedrops/types'
import { MerkleContractsState } from '@/types/merkle'
import { BigNumber } from 'ethers'

export const WavedropErrors = {
  InvalidMerkleRoot: 'Invalid merkle root',
  InvalidMerkleProof: 'Invalid merkle proof',
  ClaimingNotOpen: 'Claiming is not open',
  AlreadyClaimed: 'Already claimed',
  AmountToLockNonZero: 'Amount to lock must be zero',
}

type ValidatedArgs<T> =
  | { args?: undefined; error: string | null }
  | { args: T; error?: undefined }

export function prepareWavedropClaim({
  wavedrops,
  merkleContractState,
  wavedropUser,
}: {
  wavedrops: Wavedrop[] | undefined
  merkleContractState: Pick<MerkleContractsState, 'merkleDrop'> | undefined
  wavedropUser: Pick<WavedropUser, 'cumulativeClaimed'> | undefined
}): ValidatedArgs<Parameters<WavedropClaimAirdrop['call']>> {
  if (!wavedrops || !merkleContractState || !wavedropUser) {
    return {
      error: null,
    }
  }

  let latestClaimableWave: Wavedrop | undefined
  let latestClaimableWaveNumber = 0
  for (const wave of wavedrops) {
    if (wave.phase === 'claims') {
      if (latestClaimableWave && wave.waveNumber <= latestClaimableWaveNumber) {
        continue
      }

      latestClaimableWave = wave
      latestClaimableWaveNumber = wave.waveNumber
    }
  }

  const claimIsOpen = merkleContractState.merkleDrop.claimIsOpen
  if (!claimIsOpen) {
    return {
      error: WavedropErrors.ClaimingNotOpen,
    }
  }
  if (!latestClaimableWave || latestClaimableWave.phase !== 'claims') {
    console.error('No claimable wave found', { wavedrops, merkleContractState })
    return {
      error: WavedropErrors.ClaimingNotOpen,
    }
  }

  const latestMerkleRoot = merkleContractState.merkleDrop.merkleRoot
  if (latestClaimableWave.merkleRoot !== latestMerkleRoot) {
    return {
      error: WavedropErrors.InvalidMerkleRoot,
    }
  }

  if (!latestClaimableWave.merkleProof.length) {
    return {
      error: WavedropErrors.InvalidMerkleProof,
    }
  }

  const cumulativeAmount = latestClaimableWave.cumulativeAmount
  const cumulativeClaimed = wavedropUser.cumulativeClaimed
  if (cumulativeClaimed.gte(cumulativeAmount)) {
    return {
      error: WavedropErrors.AlreadyClaimed,
    }
  }

  return {
    args: [
      {
        amountToLock: BigNumber.from(0),
        cumulativeAmount: latestClaimableWave.cumulativeAmount,
        merkleProof: latestClaimableWave.merkleProof,
      },
    ],
  }
}
export type PreparedWavedropClaim = ReturnType<typeof prepareWavedropClaim>
