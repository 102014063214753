import React from 'react'
import ms from 'ms.macro'
import styled from 'styled-components'
import { AsyncDiv } from '../AsyncDiv'
import { FlexRow } from '../FlexRow'

export const CountdownExpiryRule = {
  showZero: 'showZero' as const,
  scramble: 'scramble' as const,
}
export type CountdownExpiryRule =
  (typeof CountdownExpiryRule)[keyof typeof CountdownExpiryRule]
const DEFAULT_EXPIRY_RULE = CountdownExpiryRule.showZero

export function CountdownBoxClockV2({
  toMs,
  nowMs,
  onExpiry = DEFAULT_EXPIRY_RULE, // todo render differently
  loading,
}: {
  toMs: number
  nowMs: number
  onExpiry?: CountdownExpiryRule
  loading?: boolean
}) {
  function _renderBox(label: string, digits?: number) {
    let digitsStr = ''
    if (digits !== undefined) {
      digitsStr = digits.toString().padStart(2, '0')
    }

    return (
      <>
        <div className="clock-card">{digitsStr}</div>
        <div className="clock-label">
          <span>{label}</span>
        </div>
      </>
    )
  }
  function _renderSeparator() {
    return (
      <>
        <div className="clock-separator">:</div>
        <div />
      </>
    )
  }

  const durationMs = toMs - nowMs
  const isAlmostReady = durationMs < ms`0.5s`

  function renderBox(digits: number, label: string) {
    if (isAlmostReady) {
      if (onExpiry === CountdownExpiryRule.showZero) {
        return _renderBox(label, 0)
      }
      if (onExpiry === CountdownExpiryRule.scramble) {
        return _renderBox(label, 0)
      }
    }

    if (loading) {
      return _renderBox(label)
    }

    return _renderBox(label, digits)
  }

  function renderDays() {
    const days = Math.floor(durationMs / ms`1d`)
    return renderBox(days, 'D')
  }
  function renderHours() {
    const hours = Math.floor((durationMs % ms`1d`) / ms`1h`)
    return renderBox(hours, 'HR')
  }
  function renderMinutes() {
    const minutes = Math.floor((durationMs % ms`1h`) / ms`1m`)
    return renderBox(minutes, 'MIN')
  }
  function renderSeconds() {
    const seconds = Math.floor((durationMs % ms`1m`) / ms`1s`)
    return renderBox(seconds, 'SEC')
  }

  return (
    <Layout>
      {renderDays()}
      {_renderSeparator()}
      {renderHours()}
      {_renderSeparator()}
      {renderMinutes()}
      {_renderSeparator()}
      {renderSeconds()}
    </Layout>
  )
}

const Layout = styled.div`
  width: auto;

  display: grid;
  grid-column-gap: 4.15px;
  grid-row-gap: 4.67px;
  grid-template-columns: 50.473px auto 50.473px auto 50.473px auto 50.473px;
  grid-template-rows: auto auto;
  grid-auto-flow: column;
  justify-content: center;
  align-items: center;

  .clock-card {
    margin: auto;
    display: flex;
    width: 50.473px;
    height: 50.473px;
    padding: 5.32px 0px 5.319px 0px;
    justify-content: center;
    align-items: center;

    border-radius: 6.228px;
    background: #16113f;

    color: var(--white-100, #e7e7e7);
    text-align: center;
    font-family: Inter;
    font-size: 31.14px;
    font-style: normal;
    font-weight: 600;
    line-height: 85%; /* 26.469px */
    letter-spacing: -0.934px;
  }

  .clock-separator {
    align-self: center;
    margin-bottom: 2px;

    color: var(--white-100, #e7e7e7);
    text-align: center;
    font-family: Inter;
    font-size: 33.216px;
    font-style: normal;
    font-weight: 600;
    line-height: 85%; /* 28.234px */
    letter-spacing: -0.996px;
  }

  .clock-label {
    align-self: center;
    text-align: center;
    color: var(--Swell-White-150, #818181);
    font-family: Inter;
    font-size: 10.6px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%; /* 13.2px */
    letter-spacing: 1.2px;
    text-transform: uppercase;

    display: flex;
    justify-content: center;
  }
`
