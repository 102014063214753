// @generated by protoc-gen-es v1.3.3 with parameter "target=ts"
// @generated from file swell/v3/bridge.proto (package swell.v3, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum swell.v3.CrossChainTxStatus
 */
export enum CrossChainTxStatus {
  /**
   * @generated from enum value: CROSS_CHAIN_TX_STATUS_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: CROSS_CHAIN_TX_STATUS_PENDING = 1;
   */
  PENDING = 1,

  /**
   * @generated from enum value: CROSS_CHAIN_TX_STATUS_CONFIRMED = 2;
   */
  CONFIRMED = 2,

  /**
   * @generated from enum value: CROSS_CHAIN_TX_STATUS_FAILED = 3;
   */
  FAILED = 3,

  /**
   * @generated from enum value: CROSS_CHAIN_TX_STATUS_REFUNDED = 4;
   */
  REFUNDED = 4,

  /**
   * @generated from enum value: CROSS_CHAIN_TX_STATUS_NOT_FOUND = 5;
   */
  NOT_FOUND = 5,

  /**
   * @generated from enum value: CROSS_CHAIN_TX_STATUS_INVALID = 6;
   */
  INVALID = 6,
}
// Retrieve enum metadata with: proto3.getEnumType(CrossChainTxStatus)
proto3.util.setEnumType(CrossChainTxStatus, "swell.v3.CrossChainTxStatus", [
  { no: 0, name: "CROSS_CHAIN_TX_STATUS_UNSPECIFIED" },
  { no: 1, name: "CROSS_CHAIN_TX_STATUS_PENDING" },
  { no: 2, name: "CROSS_CHAIN_TX_STATUS_CONFIRMED" },
  { no: 3, name: "CROSS_CHAIN_TX_STATUS_FAILED" },
  { no: 4, name: "CROSS_CHAIN_TX_STATUS_REFUNDED" },
  { no: 5, name: "CROSS_CHAIN_TX_STATUS_NOT_FOUND" },
  { no: 6, name: "CROSS_CHAIN_TX_STATUS_INVALID" },
]);

/**
 * @generated from enum swell.v3.CrossChainTxFailedSubStatus
 */
export enum CrossChainTxFailedSubStatus {
  /**
   * @generated from enum value: CROSS_CHAIN_TX_FAILED_SUB_STATUS_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: CROSS_CHAIN_TX_FAILED_SUB_STATUS_NOT_PROCESSABLE_REFUND_NEEDED = 1;
   */
  NOT_PROCESSABLE_REFUND_NEEDED = 1,

  /**
   * @generated from enum value: CROSS_CHAIN_TX_FAILED_SUB_STATUS_OUT_OF_GAS = 2;
   */
  OUT_OF_GAS = 2,

  /**
   * @generated from enum value: CROSS_CHAIN_TX_FAILED_SUB_STATUS_SLIPPAGE_EXCEEDED = 3;
   */
  SLIPPAGE_EXCEEDED = 3,

  /**
   * @generated from enum value: CROSS_CHAIN_TX_FAILED_SUB_STATUS_INSUFFICIENT_ALLOWANCE = 4;
   */
  INSUFFICIENT_ALLOWANCE = 4,

  /**
   * @generated from enum value: CROSS_CHAIN_TX_FAILED_SUB_STATUS_INSUFFICIENT_BALANCE = 5;
   */
  INSUFFICIENT_BALANCE = 5,

  /**
   * @generated from enum value: CROSS_CHAIN_TX_FAILED_SUB_STATUS_UNKNOWN_ERROR = 6;
   */
  UNKNOWN_ERROR = 6,

  /**
   * @generated from enum value: CROSS_CHAIN_TX_FAILED_SUB_STATUS_EXPIRED = 7;
   */
  EXPIRED = 7,
}
// Retrieve enum metadata with: proto3.getEnumType(CrossChainTxFailedSubStatus)
proto3.util.setEnumType(CrossChainTxFailedSubStatus, "swell.v3.CrossChainTxFailedSubStatus", [
  { no: 0, name: "CROSS_CHAIN_TX_FAILED_SUB_STATUS_UNSPECIFIED" },
  { no: 1, name: "CROSS_CHAIN_TX_FAILED_SUB_STATUS_NOT_PROCESSABLE_REFUND_NEEDED" },
  { no: 2, name: "CROSS_CHAIN_TX_FAILED_SUB_STATUS_OUT_OF_GAS" },
  { no: 3, name: "CROSS_CHAIN_TX_FAILED_SUB_STATUS_SLIPPAGE_EXCEEDED" },
  { no: 4, name: "CROSS_CHAIN_TX_FAILED_SUB_STATUS_INSUFFICIENT_ALLOWANCE" },
  { no: 5, name: "CROSS_CHAIN_TX_FAILED_SUB_STATUS_INSUFFICIENT_BALANCE" },
  { no: 6, name: "CROSS_CHAIN_TX_FAILED_SUB_STATUS_UNKNOWN_ERROR" },
  { no: 7, name: "CROSS_CHAIN_TX_FAILED_SUB_STATUS_EXPIRED" },
]);

/**
 * @generated from enum swell.v3.CrossChainTxDoneSubStatus
 */
export enum CrossChainTxDoneSubStatus {
  /**
   * @generated from enum value: CROSS_CHAIN_TX_DONE_SUB_STATUS_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: CROSS_CHAIN_TX_DONE_SUB_STATUS_COMPLETED = 1;
   */
  COMPLETED = 1,

  /**
   * @generated from enum value: CROSS_CHAIN_TX_DONE_SUB_STATUS_PARTIAL = 2;
   */
  PARTIAL = 2,

  /**
   * @generated from enum value: CROSS_CHAIN_TX_DONE_SUB_STATUS_REFUNDED = 3;
   */
  REFUNDED = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(CrossChainTxDoneSubStatus)
proto3.util.setEnumType(CrossChainTxDoneSubStatus, "swell.v3.CrossChainTxDoneSubStatus", [
  { no: 0, name: "CROSS_CHAIN_TX_DONE_SUB_STATUS_UNSPECIFIED" },
  { no: 1, name: "CROSS_CHAIN_TX_DONE_SUB_STATUS_COMPLETED" },
  { no: 2, name: "CROSS_CHAIN_TX_DONE_SUB_STATUS_PARTIAL" },
  { no: 3, name: "CROSS_CHAIN_TX_DONE_SUB_STATUS_REFUNDED" },
]);

/**
 * @generated from message swell.v3.CrossChainRoutesRequest
 */
export class CrossChainRoutesRequest extends Message<CrossChainRoutesRequest> {
  /**
   * @generated from field: int32 from_chain_id = 1;
   */
  fromChainId = 0;

  /**
   * @generated from field: string from_amount_wei = 2;
   */
  fromAmountWei = "";

  /**
   * @generated from field: string from_token_address = 3;
   */
  fromTokenAddress = "";

  /**
   * @generated from field: int32 to_chain_id = 4;
   */
  toChainId = 0;

  /**
   * @generated from field: string to_token_address = 5;
   */
  toTokenAddress = "";

  /**
   * @generated from field: repeated string providers = 6;
   */
  providers: string[] = [];

  /**
   * @generated from field: float slippage_percent = 7;
   */
  slippagePercent = 0;

  /**
   * @generated from field: string gas_on_destination_chain_wei = 8;
   */
  gasOnDestinationChainWei = "";

  constructor(data?: PartialMessage<CrossChainRoutesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.CrossChainRoutesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "from_chain_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "from_amount_wei", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "from_token_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "to_chain_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "to_token_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "providers", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 7, name: "slippage_percent", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 8, name: "gas_on_destination_chain_wei", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CrossChainRoutesRequest {
    return new CrossChainRoutesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CrossChainRoutesRequest {
    return new CrossChainRoutesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CrossChainRoutesRequest {
    return new CrossChainRoutesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CrossChainRoutesRequest | PlainMessage<CrossChainRoutesRequest> | undefined, b: CrossChainRoutesRequest | PlainMessage<CrossChainRoutesRequest> | undefined): boolean {
    return proto3.util.equals(CrossChainRoutesRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.CrossChainRoute
 */
export class CrossChainRoute extends Message<CrossChainRoute> {
  /**
   * @generated from field: bytes raw = 1;
   */
  raw = new Uint8Array(0);

  /**
   * @generated from field: string provider = 2;
   */
  provider = "";

  /**
   * @generated from field: string from_amount_wei = 3;
   */
  fromAmountWei = "";

  /**
   * @generated from field: float from_amount_usd = 4;
   */
  fromAmountUsd = 0;

  /**
   * @generated from field: string from_token_address = 5;
   */
  fromTokenAddress = "";

  /**
   * @generated from field: string to_amount = 6;
   */
  toAmount = "";

  /**
   * @generated from field: float to_amount_usd = 7;
   */
  toAmountUsd = 0;

  /**
   * @generated from field: string to_token_address = 8;
   */
  toTokenAddress = "";

  /**
   * @generated from field: int32 est_time_seconds = 9;
   */
  estTimeSeconds = 0;

  /**
   * @generated from field: float gas_cost_usd = 10;
   */
  gasCostUsd = 0;

  /**
   * @generated from field: float fee_percent = 11;
   */
  feePercent = 0;

  /**
   * @generated from field: int32 from_chain_id = 12;
   */
  fromChainId = 0;

  /**
   * @generated from field: int32 to_chain_id = 13;
   */
  toChainId = 0;

  /**
   * @generated from field: int32 from_decimals = 14;
   */
  fromDecimals = 0;

  /**
   * @generated from field: int32 to_decimals = 15;
   */
  toDecimals = 0;

  constructor(data?: PartialMessage<CrossChainRoute>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.CrossChainRoute";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "raw", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 2, name: "provider", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "from_amount_wei", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "from_amount_usd", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 5, name: "from_token_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "to_amount", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "to_amount_usd", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 8, name: "to_token_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "est_time_seconds", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 10, name: "gas_cost_usd", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 11, name: "fee_percent", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 12, name: "from_chain_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 13, name: "to_chain_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 14, name: "from_decimals", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 15, name: "to_decimals", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CrossChainRoute {
    return new CrossChainRoute().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CrossChainRoute {
    return new CrossChainRoute().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CrossChainRoute {
    return new CrossChainRoute().fromJsonString(jsonString, options);
  }

  static equals(a: CrossChainRoute | PlainMessage<CrossChainRoute> | undefined, b: CrossChainRoute | PlainMessage<CrossChainRoute> | undefined): boolean {
    return proto3.util.equals(CrossChainRoute, a, b);
  }
}

/**
 * @generated from message swell.v3.CrossChainRoutesResponse
 */
export class CrossChainRoutesResponse extends Message<CrossChainRoutesResponse> {
  /**
   * @generated from field: repeated swell.v3.CrossChainRoute routes = 1;
   */
  routes: CrossChainRoute[] = [];

  constructor(data?: PartialMessage<CrossChainRoutesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.CrossChainRoutesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "routes", kind: "message", T: CrossChainRoute, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CrossChainRoutesResponse {
    return new CrossChainRoutesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CrossChainRoutesResponse {
    return new CrossChainRoutesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CrossChainRoutesResponse {
    return new CrossChainRoutesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CrossChainRoutesResponse | PlainMessage<CrossChainRoutesResponse> | undefined, b: CrossChainRoutesResponse | PlainMessage<CrossChainRoutesResponse> | undefined): boolean {
    return proto3.util.equals(CrossChainRoutesResponse, a, b);
  }
}

/**
 * @generated from message swell.v3.PopulateCrossChainTransactionRequest
 */
export class PopulateCrossChainTransactionRequest extends Message<PopulateCrossChainTransactionRequest> {
  /**
   * @generated from field: string provider = 1;
   */
  provider = "";

  /**
   * @generated from field: bytes raw = 2;
   */
  raw = new Uint8Array(0);

  /**
   * @generated from field: string for_address = 3;
   */
  forAddress = "";

  constructor(data?: PartialMessage<PopulateCrossChainTransactionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.PopulateCrossChainTransactionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "provider", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "raw", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 3, name: "for_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PopulateCrossChainTransactionRequest {
    return new PopulateCrossChainTransactionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PopulateCrossChainTransactionRequest {
    return new PopulateCrossChainTransactionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PopulateCrossChainTransactionRequest {
    return new PopulateCrossChainTransactionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: PopulateCrossChainTransactionRequest | PlainMessage<PopulateCrossChainTransactionRequest> | undefined, b: PopulateCrossChainTransactionRequest | PlainMessage<PopulateCrossChainTransactionRequest> | undefined): boolean {
    return proto3.util.equals(PopulateCrossChainTransactionRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.PopulateCrossChainTransactionResponse
 */
export class PopulateCrossChainTransactionResponse extends Message<PopulateCrossChainTransactionResponse> {
  /**
   * @generated from field: string data = 1;
   */
  data = "";

  /**
   * @generated from field: string to = 2;
   */
  to = "";

  /**
   * @generated from field: string value = 3;
   */
  value = "";

  /**
   * @generated from field: int32 chain_id = 4;
   */
  chainId = 0;

  /**
   * @generated from field: string gas_limit = 5;
   */
  gasLimit = "";

  constructor(data?: PartialMessage<PopulateCrossChainTransactionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.PopulateCrossChainTransactionResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "data", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "to", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "chain_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "gas_limit", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PopulateCrossChainTransactionResponse {
    return new PopulateCrossChainTransactionResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PopulateCrossChainTransactionResponse {
    return new PopulateCrossChainTransactionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PopulateCrossChainTransactionResponse {
    return new PopulateCrossChainTransactionResponse().fromJsonString(jsonString, options);
  }

  static equals(a: PopulateCrossChainTransactionResponse | PlainMessage<PopulateCrossChainTransactionResponse> | undefined, b: PopulateCrossChainTransactionResponse | PlainMessage<PopulateCrossChainTransactionResponse> | undefined): boolean {
    return proto3.util.equals(PopulateCrossChainTransactionResponse, a, b);
  }
}

/**
 * @generated from message swell.v3.BridgeTxKey
 */
export class BridgeTxKey extends Message<BridgeTxKey> {
  /**
   * @generated from field: string tx_hash = 1;
   */
  txHash = "";

  /**
   * @generated from field: string provider = 2;
   */
  provider = "";

  constructor(data?: PartialMessage<BridgeTxKey>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.BridgeTxKey";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "tx_hash", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "provider", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BridgeTxKey {
    return new BridgeTxKey().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BridgeTxKey {
    return new BridgeTxKey().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BridgeTxKey {
    return new BridgeTxKey().fromJsonString(jsonString, options);
  }

  static equals(a: BridgeTxKey | PlainMessage<BridgeTxKey> | undefined, b: BridgeTxKey | PlainMessage<BridgeTxKey> | undefined): boolean {
    return proto3.util.equals(BridgeTxKey, a, b);
  }
}

/**
 * @generated from message swell.v3.BridgeTxStatus
 */
export class BridgeTxStatus extends Message<BridgeTxStatus> {
  /**
   * @generated from field: string tx_hash = 1;
   */
  txHash = "";

  /**
   * @generated from field: swell.v3.CrossChainTxStatus status = 2;
   */
  status = CrossChainTxStatus.UNSPECIFIED;

  /**
   * @generated from field: swell.v3.CrossChainTxFailedSubStatus failed_sub_status = 3;
   */
  failedSubStatus = CrossChainTxFailedSubStatus.UNSPECIFIED;

  /**
   * @generated from field: swell.v3.CrossChainTxDoneSubStatus done_sub_status = 4;
   */
  doneSubStatus = CrossChainTxDoneSubStatus.UNSPECIFIED;

  /**
   * @generated from field: string message = 5;
   */
  message = "";

  constructor(data?: PartialMessage<BridgeTxStatus>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.BridgeTxStatus";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "tx_hash", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "status", kind: "enum", T: proto3.getEnumType(CrossChainTxStatus) },
    { no: 3, name: "failed_sub_status", kind: "enum", T: proto3.getEnumType(CrossChainTxFailedSubStatus) },
    { no: 4, name: "done_sub_status", kind: "enum", T: proto3.getEnumType(CrossChainTxDoneSubStatus) },
    { no: 5, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BridgeTxStatus {
    return new BridgeTxStatus().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BridgeTxStatus {
    return new BridgeTxStatus().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BridgeTxStatus {
    return new BridgeTxStatus().fromJsonString(jsonString, options);
  }

  static equals(a: BridgeTxStatus | PlainMessage<BridgeTxStatus> | undefined, b: BridgeTxStatus | PlainMessage<BridgeTxStatus> | undefined): boolean {
    return proto3.util.equals(BridgeTxStatus, a, b);
  }
}

/**
 * @generated from message swell.v3.BridgeTxStatusRequest
 */
export class BridgeTxStatusRequest extends Message<BridgeTxStatusRequest> {
  /**
   * @generated from field: repeated swell.v3.BridgeTxKey tx_keys = 1;
   */
  txKeys: BridgeTxKey[] = [];

  constructor(data?: PartialMessage<BridgeTxStatusRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.BridgeTxStatusRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "tx_keys", kind: "message", T: BridgeTxKey, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BridgeTxStatusRequest {
    return new BridgeTxStatusRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BridgeTxStatusRequest {
    return new BridgeTxStatusRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BridgeTxStatusRequest {
    return new BridgeTxStatusRequest().fromJsonString(jsonString, options);
  }

  static equals(a: BridgeTxStatusRequest | PlainMessage<BridgeTxStatusRequest> | undefined, b: BridgeTxStatusRequest | PlainMessage<BridgeTxStatusRequest> | undefined): boolean {
    return proto3.util.equals(BridgeTxStatusRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.BridgeTxStatusResponse
 */
export class BridgeTxStatusResponse extends Message<BridgeTxStatusResponse> {
  /**
   * @generated from field: repeated swell.v3.BridgeTxStatus statuses = 1;
   */
  statuses: BridgeTxStatus[] = [];

  constructor(data?: PartialMessage<BridgeTxStatusResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.BridgeTxStatusResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "statuses", kind: "message", T: BridgeTxStatus, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BridgeTxStatusResponse {
    return new BridgeTxStatusResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BridgeTxStatusResponse {
    return new BridgeTxStatusResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BridgeTxStatusResponse {
    return new BridgeTxStatusResponse().fromJsonString(jsonString, options);
  }

  static equals(a: BridgeTxStatusResponse | PlainMessage<BridgeTxStatusResponse> | undefined, b: BridgeTxStatusResponse | PlainMessage<BridgeTxStatusResponse> | undefined): boolean {
    return proto3.util.equals(BridgeTxStatusResponse, a, b);
  }
}

