import { getAddress } from 'ethers/lib/utils'
import axios, { AxiosError } from 'axios'
import {
  IStaticWavedropServiceV2,
  StaticWavedropV2,
  StaticWavedropV2Raw,
} from './types'
import { BigNumber } from 'ethers'

export class StaticWavedropServiceV2 implements IStaticWavedropServiceV2 {
  private baseURL: string
  constructor({ baseURL }: { baseURL: string }) {
    this.baseURL = baseURL.replace(/\/$/, '')
  }

  // https://staticdrop.svc.swellnetwork.io/api/v2/wavedrop?address=0x822c9782f35C4bc34216588e96fD6451e8DB80CF
  private userUrl = (address: string) =>
    `${this.baseURL}/api/v2/wavedrop?address=${address}?opn=1`

  wavedrops: IStaticWavedropServiceV2['wavedrops'] = async (address) => {
    address = getAddress(address)
    address = address.toLowerCase()

    const url = this.userUrl(address)
    let res: { data: StaticWavedropV2Raw[] }
    try {
      res = await axios.get(url)
    } catch (e) {
      if (e && e instanceof AxiosError && e.response?.status === 404) {
        return {
          wavedrops: [],
        }
      }
      throw e
    }
    const dataRaw = res.data
    if (!dataRaw || !dataRaw.length) {
      throw new Error('No wavedrops found')
    }

    const wavedrops: StaticWavedropV2[] = []
    for (const raw of dataRaw) {
      let wavedropNumber: number
      if (raw.wave_number === 'w1') {
        wavedropNumber = 1
      } else if (raw.wave_number === 'w2l2') {
        wavedropNumber = 2
      } else {
        continue
      }

      const wd: StaticWavedropV2 = {
        cumulativeAmount: BigNumber.from(raw.cumulative_amount),
        merkleRoot: raw.merkle_root,
        meta: {
          bonus: parseFloat(raw.meta.bonus),
          ecosystemAmount: BigNumber.from(raw.meta.ecosystem_amount),
          primaryAmount: BigNumber.from(raw.meta.primary_amount),
          swellAmount: BigNumber.from(raw.meta.swell_amount),
          weightedPearls: parseFloat(raw.meta.weighted_pearls),
        },
        proofsHex: raw.proofs,
        wavedropNumber,
      }
      wavedrops.push(wd)
    }
    return { wavedrops }
  }
}
