import { getAddress } from 'ethers/lib/utils'
import axios, { AxiosError } from 'axios'
import { BigNumber } from 'ethers'
import {
  IStaticEigenDropService,
  StaticEigenDropRaw,
  StaticEigenDrop,
} from './types'

export class StaticEigenDropService implements IStaticEigenDropService {
  private baseURL: string
  constructor({ baseURL }: { baseURL: string }) {
    this.baseURL = baseURL.replace(/\/$/, '')
  }

  // https://airdrop.svc.swellnetwork.io/api/eigendrop?address=0x822c9782f35C4bc34216588e96fD6451e8DB80CF
  private userUrl = (address: string) =>
    `${this.baseURL}/api/eigendrop?address=${address}&opn=2`

  eigendrop: IStaticEigenDropService['eigendrop'] = async (address) => {
    address = getAddress(address)
    address = address.toLowerCase()

    const url = this.userUrl(address)
    let res: { data: StaticEigenDropRaw[] }
    try {
      res = await axios.get(url)
    } catch (e) {
      if (e && e instanceof AxiosError && e.response?.status === 404) {
        return []
      }
      throw e
    }
    const dataRaw = res.data
    if (!dataRaw || !dataRaw?.length) {
      throw new Error('No eigendrop found')
    }

    const drops: StaticEigenDrop[] = []
    for (const raw of dataRaw) {
      const { cumulative_amount, merkle_root, proofs, wave_number } = raw
      const drop: StaticEigenDrop = {
        cumulativeAmount: BigNumber.from(cumulative_amount),
        merkleRoot: merkle_root,
        proofsHex: proofs,
        waveNumber: wave_number,
      }
      drops.push(drop)
    }

    return drops
  }
}
