import {
  SwETHClaimSummary,
  SwETHClaimInputs,
  SwETHWithdrawRequestSummary,
} from '../types'
import { PreparedFinalizeWithdrawalSwETH } from '../swETHCalls'
import { useRef, useState } from 'react'
import styled from 'styled-components'
import { CircularProgress } from '@swell-ui/CircularProgress'
import { Divider } from '@swell-ui/Divider'
import { EthIcon } from '@swell-ui/icons/EthIcon'
import { ExternalLinkIcon } from '@swell-ui/icons/ExternalLinkIcon'
import { FlexRow } from '@swell-ui/FlexRow'
import { Popper } from '@swell-ui/Popper'
import { SwethIcon } from '@swell-ui/icons/SwethIcon'
import { Typography } from '@swell-ui/Typography'
import { RadioButton } from '@/swell-ui/RadioButton'
import { FinalizeWithdrawalButton } from '../SwETHButtons'
import { FinalizeWithdrawalSwETH } from '@/state/sweth/hooks'

export function SwETHClaimView({
  claimSummary,
  claimInputs,
  preparedFinalizeWithdrawRequest,
  finalizeWithdrawal,
}: {
  claimSummary: SwETHClaimSummary
  claimInputs: SwETHClaimInputs
  preparedFinalizeWithdrawRequest: PreparedFinalizeWithdrawalSwETH
  finalizeWithdrawal: FinalizeWithdrawalSwETH
}) {
  const {
    isLoading,
    claimableETH,
    numClaimable,
    numPending,
    pendingSwETH,
    requests,
  } = claimSummary

  return (
    <ClaimViewContainer direction="column" align="flex-start" gap="16">
      <Typography variant="body" size="large">
        Claim unstaked swETH
      </Typography>
      {!isLoading && (
        <>
          <BorderedFlex justify="space-between" align="flex-start">
            <Grow>
              <FlexRow direction="column" align="flex-start" gap="12">
                <FlexRow gap="7">
                  <GreenDot />
                  <Typography
                    variant="body"
                    size="medium"
                    letterSpacing="small"
                  >
                    Claimable
                  </Typography>
                </FlexRow>
                <Typography variant="body" size="small" letterSpacing="small">
                  <FlexRow gap="6">
                    <div>{numClaimable}</div>
                    <div>|</div>
                    <FlexRow gap="3">
                      <StyledEthIcon />
                      <div>{claimableETH} ETH</div>
                    </FlexRow>
                  </FlexRow>
                </Typography>
              </FlexRow>
            </Grow>
            <div>
              <FlexRow direction="column" align="flex-start" gap="12">
                <FlexRow gap="7">
                  <YellowDot />
                  <Typography
                    variant="body"
                    size="medium"
                    letterSpacing="small"
                  >
                    Pending
                  </Typography>
                </FlexRow>
                <Typography variant="body" size="small" letterSpacing="small">
                  <FlexRow gap="6">
                    <div>{numPending}</div>
                    <div>|</div>
                    <FlexRow gap="3">
                      <StyledSwethIcon />
                      <div>{pendingSwETH} swETH</div>
                    </FlexRow>
                  </FlexRow>
                </Typography>
              </FlexRow>
            </div>
          </BorderedFlex>
          <Divider margin="0" />
          <ClaimRowsFlex direction="column" gap="12" numRows={requests.length}>
            {requests &&
              requests.map((claim, index) => {
                return (
                  <ClaimRow
                    key={index}
                    claim={claim}
                    onClaimSelect={() =>
                      claimInputs.setSelectedTokenId(claim.tokenId)
                    }
                    selected={claimInputs.selectedTokenId === claim.tokenId}
                  />
                )
              })}
          </ClaimRowsFlex>
        </>
      )}
      {isLoading && (
        <LoadingContainer>
          <CircularProgress />
        </LoadingContainer>
      )}
      <FinalizeWithdrawalButton
        finalizeWithdrawal={finalizeWithdrawal}
        prepared={preparedFinalizeWithdrawRequest}
        preventInteraction={isLoading}
      />
    </ClaimViewContainer>
  )
}

const ClaimViewContainer = styled(FlexRow)`
  padding-top: 16px;
`

const BorderedFlex = styled<any>(FlexRow)`
  position: relative;
  padding: 10px 12px;
  border: 1px solid ${({ theme }) => theme.colors.auxGrey};
  border-radius: 8px;
  align-self: stretch;
`

const ClaimRowsFlex = styled<any>(FlexRow)`
  height: 331px;

  ${({ numRows }) => numRows > 6 && `overflow-y: scroll;`}
`

const Grow = styled.div`
  flex-grow: 1;
`

const GreenDot = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background: rgba(26, 255, 75, 1);
`

const YellowDot = styled(GreenDot)`
  background: ${({ theme }) => theme.colors.eigenLayer['yellow']};
`

const StyledEthIcon = styled(EthIcon)`
  width: 16px;
  height: 16px;
`

const StyledSwethIcon = styled(SwethIcon)`
  width: 16px;
  height: 16px;
`

const ExternalA = styled.a`
  display: flex;
`

const StyledLinkIcon = styled(ExternalLinkIcon)`
  height: 16px;
  width: 19px;

  &:hover {
    opacity: 0.7;
  }
`

const Status = styled.div`
  text-transform: capitalize;
`

const StyledPopper = styled(Popper)`
  z-index: 50;
`

const ClaimTooltip = styled.div`
  position: relative;
  left: 30px;
  top: 8px;
`

const TooltipText = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  padding: 18px 0 0 11px;
  z-index: 2;
`

const TooltipLabel = styled.span`
  color: ${({ theme }) => theme.colors.white['125']};
`

const LoadingContainer = styled.div`
  height: 446px;
  margin: 0 auto;
`

function ClaimRow({
  claim,
  onClaimSelect,
  selected,
}: {
  claim: SwETHWithdrawRequestSummary
  onClaimSelect: () => void
  selected: boolean
}) {
  const [showTooltip, setShowTooltip] = useState<boolean>(false)
  const anchorRef = useRef<HTMLElement | null>(null)

  const {
    isClaimable,
    swETHAmount,
    tokenId,
    nftTokenLink,
    queuePosition,
    totalInQueue,
    requestTime,
    exchangeRate,
    ethAmount,
  } = claim
  const isPending = !isClaimable

  return (
    <>
      <BorderedFlex ref={anchorRef}>
        <Grow>
          <FlexRow gap="8">
            {isPending && (
              <>
                <StyledSwethIcon />
                <div>{swETHAmount} swETH</div>
              </>
            )}
            {isClaimable && (
              <>
                <StyledEthIcon />
                <div>{ethAmount} ETH</div>
              </>
            )}
          </FlexRow>
        </Grow>
        <div>
          <FlexRow gap="9">
            {isClaimable && (
              <RadioButton onClick={onClaimSelect} selected={selected} />
            )}
            <FlexRow gap="8">
              {isPending ? <YellowDot /> : <GreenDot />}
              <Status
                onMouseEnter={() => setShowTooltip(true)}
                onMouseOut={() => setShowTooltip(false)}
              >
                {isClaimable ? 'Claimable' : 'Pending'}
              </Status>
            </FlexRow>
            <ExternalA href={nftTokenLink} target="_blank" rel="noreferrer">
              <StyledLinkIcon />
            </ExternalA>
          </FlexRow>
        </div>
      </BorderedFlex>
      {isPending && showTooltip && (
        <StyledPopper
          id={`${tokenId}`}
          open={true}
          anchorEl={anchorRef.current}
        >
          <ClaimTooltip>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="240"
              height="117"
              viewBox="0 0 240 117"
              fill="none"
            >
              <path
                d="M1 112V11.4919C1 9.2828 2.79086 7.49194 4.99999 7.49194H195.869C196.93 7.49194 197.948 7.06994 198.699 6.3189L203.18 1.83281C204.742 0.268768 207.277 0.268769 208.84 1.83282L213.321 6.3189C214.071 7.06994 215.09 7.49194 216.151 7.49194H235C237.209 7.49194 239 9.2828 239 11.4919V112C239 114.209 237.209 116 235 116H5C2.79086 116 1 114.209 1 112Z"
                fill="#051A2B"
                stroke="url(#paint0_linear_6016_94618)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_6016_94618"
                  x1="120"
                  y1="-1"
                  x2="119.436"
                  y2="196.299"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#3068EF" />
                  <stop offset="1" stopColor="#1322AC" />
                </linearGradient>
              </defs>
            </svg>
            <TooltipText>
              <Typography variant="body" size="small" letterSpacing="small">
                <div>
                  <TooltipLabel>Queue position:</TooltipLabel>&nbsp;
                  {queuePosition} of {totalInQueue}
                </div>
                <div>
                  <TooltipLabel>Request timestamp:</TooltipLabel>&nbsp;
                  {requestTime}
                </div>
                <div>
                  <TooltipLabel>swETH-ETH rate:</TooltipLabel>&nbsp;
                  {exchangeRate}
                </div>
                <div>
                  <TooltipLabel>ETH:</TooltipLabel>&nbsp;
                  {ethAmount}
                </div>
              </Typography>
            </TooltipText>
          </ClaimTooltip>
        </StyledPopper>
      )}
    </>
  )
}
