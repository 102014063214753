import { BridgeEventsResponse } from '@/submodules/v3-shared/ts/connect/swell/v3/stats_pb'
import { L2BridgeTxEvent } from './types'
import { ChainInfo } from '@/constants/chainInfo'
import { BigNumber } from 'ethers'
import { Token } from '@/types/tokens'
import { formatUnits } from 'ethers/lib/utils'
import { TOKEN_LIST_ETH } from '@/constants/tokens'

export function parseL2BridgeTxEvent(
  event: BridgeEventsResponse,
  chainInfo: ChainInfo,
  tokens: Token[]
): L2BridgeTxEvent {
  let token = tokens.find((t) => t.address === event.tokenAddress)
  if (!token) {
    if (event.tokenAddress === TOKEN_LIST_ETH.address) {
      token = TOKEN_LIST_ETH
    } else {
      throw new Error(`Token not found for address ${event.tokenAddress}`)
    }
  }

  const u = new URL(chainInfo.explorer)
  u.pathname = `/tx/${event.transactionHash}`
  const url = u.toString()
  const timestampUnix = BigNumber.from(event.timestamp).toNumber()
  const account = event.walletAddress
  const amountBn = BigNumber.from(event.amountWei)
  const amount = Number(formatUnits(amountBn, token.decimals))
  const symbol = token.symbol

  return {
    account,
    amount,
    symbol,
    timestampUnix,
    url,
  }
}
