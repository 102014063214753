import { Token } from '@/types/tokens'
import { useEffect, useState } from 'react'
import { useTransactionContext } from '@/state/transactions/context'
import { PredepositInputs, PredepositTokensSummary } from '../../types'
import { PredepositTokens } from '@/state/predeposit/hooks'
import { BigNumber } from 'ethers'
import styled, { css } from 'styled-components'
import { FlexRow } from '@/swell-ui/FlexRow'
import { Button } from '@/swell-ui/Button'
import { AsyncDiv } from '@/swell-ui/AsyncDiv'
import { ScrollableYArea } from '@/swell-ui/ScrollableArea'
import { useSwellWeb3 } from '@/swell-web3/core'

export function PredepositTokenSelect({
  predepositInputs,
  predepositTokens,
  predepositTokensSummary,
}: {
  predepositTokens: PredepositTokens
  predepositInputs: PredepositInputs
  predepositTokensSummary: PredepositTokensSummary
}) {
  const { account } = useSwellWeb3()
  const { anyTransactionInProgress } = useTransactionContext()
  const [tokenSelectOpen, setTokenSelectOpen] = useState<boolean>(false)
  const [jumpUpProgress, setJumpUpProgress] = useState<number>(0)
  useEffect(() => {
    let done = false

    function animate() {
      if (done) return

      setJumpUpProgress((p) => {
        if (tokenSelectOpen && p < 1) {
          return Math.min(p + 0.2, 1)
        }

        if (!tokenSelectOpen && p > 0) {
          return Math.max(p - 0.2, 0)
        }

        return p
      })
      return requestAnimationFrame(animate)
    }

    requestAnimationFrame(animate)
    return () => {
      done = true
    }
  }, [tokenSelectOpen])

  return (
    <>
      <TsPill
        onClick={() => {
          if (anyTransactionInProgress) return
          return setTokenSelectOpen(true)
        }}
        style={{
          display: predepositInputs.withdrawToken ? 'flex' : 'none',
        }}
      >
        <img
          src={predepositInputs.withdrawToken?.logoURI ?? ''}
          width={24}
          height={24}
        />
        {predepositInputs.withdrawToken?.symbol}
        <CaretDown />
      </TsPill>
      <Backdrop
        style={{
          opacity: jumpUpProgress,
          pointerEvents: tokenSelectOpen ? 'auto' : 'none',
        }}
        onClick={() => {
          setTokenSelectOpen(false)
        }}
      />
      <TokenPaper
        style={{
          transform: `translateY(${(1 - jumpUpProgress) * 100}%)`,
        }}
      >
        <FlexRow justify="space-between">
          <span className="body-medium-bold">Withdraw Token</span>
          <Button
            onClick={() => setTokenSelectOpen(false)}
            variant="secondary"
            size="small"
            style={{
              padding: '0',
              width: '29px',
              height: '29px',
              borderRadius: '6px',
              border: '1px solid rgba(164, 171, 241, 0.30)',
            }}
          >
            <CloseButton />
          </Button>
        </FlexRow>
        <TsTrackLayout>
          <ScrollableYArea>
            {predepositTokensSummary.map((t) => {
              return (
                <TokenSelectRow
                  key={t.address}
                  token={t}
                  amount={t.staked}
                  onClick={() => {
                    predepositInputs.selectWithdrawToken(t.address)
                    setTokenSelectOpen(false)
                  }}
                />
              )
            })}
          </ScrollableYArea>
        </TsTrackLayout>
      </TokenPaper>
    </>
  )
}
const TsPill = styled.div`
  display: flex;
  height: 32px;
  padding: 6px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 100px;
  border: 1px solid rgba(164, 171, 241, 0.3);
  background: rgba(164, 171, 241, 0.05);
  color: var(--Swell-White-100, #e7e7e7);
  /* Body/xsmall bold */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%; /* 19.2px */
  cursor: pointer;
`
const TsTrackLayout = styled.div`
  display: flex;
  flex-flow: column;
  flex-grow: 1;

  .scroll-area-y {
    ${({ theme }) =>
      theme.scrollbar &&
      css`
        &::-webkit-scrollbar {
          width: ${theme.scrollbar.width};
        }

        &::-webkit-scrollbar-track {
          background: ${theme.scrollbar.trackBg};
          border-radius: ${theme.scrollbar.borderRadius};
        }

        &::-webkit-scrollbar-thumb {
          background: ${theme.scrollbar.thumbBg};
          border-radius: ${theme.scrollbar.borderRadius};
        }
      `}

    > div {
      padding-right: 9px;
    }
  }
`

const Backdrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  border-radius: 16px;
  backdrop-filter: blur(4px);
`

const TokenPaper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1001;
  border-radius: 16px;
  height: 80%;

  background: var(--Swell-Oceanblue-150, #010508);
  border: 0.8px solid rgba(164, 171, 241, 0.2);

  display: flex;
  flex-direction: column;
  gap: 24px;

  padding: 24px 32px;
  @media (max-width: 420px) {
    padding: 24px 8px;
  }
`
function CaretDown() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="5"
      viewBox="0 0 8 5"
      fill="none"
    >
      <path d="M0 0.5L4 4.5L8 0.5H0Z" fill="#2F43EC" />
    </svg>
  )
}

function TokenSelectRow({
  token,
  amount,
  onClick,
}: {
  token: Pick<Token, 'symbol' | 'name' | 'logoURI'>
  amount: string | undefined
  onClick?: () => void
}) {
  return (
    <TsRowLayout onClick={onClick}>
      <div className="ts-logo">
        <img src={token.logoURI} width={40} height={40} />
      </div>
      <div className="ts-name">
        <FlexRow direction="column" gap="10" align="start" justify="center">
          <span className="body-medium">{token.name}</span>
          <span className="body-xsmall">{token.symbol}</span>
        </FlexRow>
      </div>
      <div className="ts-amount">
        <div>
          <AsyncDiv loading={!amount}>
            {() => <span className="body-large-bold">{amount}</span>}
          </AsyncDiv>
        </div>
      </div>
    </TsRowLayout>
  )
}

const TsRowLayout = styled.div`
  padding: 12px;
  height: 69px;

  &:first-of-type {
    border-radius: 12px 12px 0 0;
  }
  &:last-of-type {
    border-radius: 0 0 12px 12px;
  }

  [aria-busy='false'] {
    min-width: 0;
  }

  .body-medium {
    color: white !important;
  }

  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-areas: 'logo name amount';

  .ts-logo {
    grid-area: logo;
    justify-self: center;
    align-self: center;
    margin-right: 12px;
  }
  .ts-name {
    grid-area: name;
    align-self: stretch;
    display: grid;
    position: relative;
    > div {
      width: 195px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      span {
        line-height: 100%;
      }
    }
  }
  .ts-amount {
    grid-area: amount;
    justify-self: end;
    align-self: center;
    /* text-align: right; */

    position: relative;
    > div {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      background-color: rgba(12, 8, 39, 0.9);
      transition: background-color 0.2s;

      max-width: 120px;
      overflow: hidden;
    }
  }

  &:not(:last-of-type) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }

  background-color: rgba(12, 8, 39, 0.9);
  &:hover {
    background-color: #16113f;

    .ts-amount {
      > div {
        background-color: #16113f;
      }
    }
  }

  transition: background-color 0.2s;
  cursor: pointer;
`

export function CloseButton() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <g clip-path="url(#clip0_12174_7761)">
        <path
          d="M17.1875 4.8125L4.8125 17.1875"
          stroke="white"
          stroke-width="1.375"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M17.1875 17.1875L4.8125 4.8125"
          stroke="white"
          stroke-width="1.375"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_12174_7761">
          <rect width="22" height="22" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
