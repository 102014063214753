import styled, { ThemeProvider } from 'styled-components'
import { css } from 'styled-components'
import { StyledButtonThin } from '@/components/AirdropCarousel/AirdropButtons'
import { DecoBox } from '@/components/DecoBox'
import { FlexRow } from '@/swell-ui/FlexRow'
import { Dialog } from '@mui/material'
import { darkTheme } from '@/swell-ui/theme/themes'
import { Token } from '@/types/tokens'
import { ColoredHeadingTypography } from '@/swell-ui/Typography/typographyPresets'
import { CloseIcon } from '@/swell-ui/icons/CloseIcon'

export type ConsiderDepositRswellInL2Summary = {
  amountTotal: string
  claimedSymbols: string
  claimedLogoURIs: string[]
}

const bodyCSS = css`
  color: var(--Swell-White-125, #fff);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 25.6px */
  letter-spacing: -0.48px;
`

const headlinesH7CSS = css`
  color: var(--Swell-White-50, #fff);
  /* Headlines/H7 */
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 26px */
  letter-spacing: -0.6px;
`

const StyledCloseIcon = styled(CloseIcon)`
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }

  path {
    stroke-width: 0.666667px;
    color: white;
  }
`

export function ConsiderDepositInL2Modal({
  onConfirm,
  summary,
  entrypoint,
  restakedDaoToken,
  onClose,
}: {
  onConfirm: () => void
  summary: ConsiderDepositRswellInL2Summary
  restakedDaoToken: Token
  onClose: () => void
  entrypoint: 'airdrop' | 'restake'
}) {
  return null
}

const MuiDialog = styled(Dialog)`
  .MuiPaper-root {
    /* border-radius: 12px; */
    border: none;
    background: none;
    padding: none;
    overflow: visible;
  }
`

const Content = styled.div`
  height: auto;
  width: 100%;
  padding: 12px 0;

  .headline-h7 {
    ${headlinesH7CSS}
  }
  p {
    ${bodyCSS}
  }

  .claimed {
    img:nth-child(1) {
      margin-right: 6px;
      &:has(+ img) {
        margin-right: -16px;
        z-index: 1;
      }
    }
    img:nth-child(2) {
      margin-right: 6px;
      &:has(+ img) {
        margin-right: -16px;
        z-index: 1;
      }
    }
  }
`

const Btns = styled(FlexRow)`
  button {
    flex: 1 1 auto;
  }
`

const Layout = styled(DecoBox)`
  h2 {
    margin: 0 auto;
    text-align: center;
    max-width: 80%;
    color: var(--Swell-White-50, #fff);
    /* Headlines/H7 */
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 26px */
    letter-spacing: -0.6px;
  }

  button {
    width: 100%;
  }

  ul {
    padding: 0;
    margin: 0;
    margin-left: 29px;
  }

  position: relative;
  ${StyledCloseIcon} {
    position: absolute;
    top: 16px;
    right: 16px;
  }
`
