import { BigNumber } from 'ethers'
import { EarnEthWithdrawAndDepositPhase, PredepositInputs } from './types'
import {
  NucleusErrors,
  PreparedNucleusDeposit,
} from '@/components/Nucleus/nucleusCalls'
import { parseUnits } from 'ethers/lib/utils'
import { PredepositUser } from '@/state/predeposit/types'
import { compareHex } from '@/util/hexStrings'

export function earnEthWithdrawAndDepositPhase({
  preparedDeposit,
  predepositUser,
  predepositInputs,
}: {
  preparedDeposit: PreparedNucleusDeposit
  predepositUser: PredepositUser | undefined
  predepositInputs: PredepositInputs | undefined
}) {
  let phase: EarnEthWithdrawAndDepositPhase = 'unknown'

  let stakedBalance: BigNumber | undefined
  if (predepositUser && predepositInputs?.withdrawToken) {
    stakedBalance =
      predepositUser.stakes[predepositInputs.withdrawToken.address]
  }

  if (!stakedBalance) return phase

  if (stakedBalance.gt(0)) {
    phase = 'withdraw'
    return phase
  }

  phase = 'deposit'
  if (preparedDeposit.error === NucleusErrors.InsufficientAllowance) {
    phase = 'approve'
  }

  return phase
}

export function minimumMintFromSlippage({
  mintNoSlippage,
  slippagePercent,
}: {
  mintNoSlippage: BigNumber
  slippagePercent: number
}) {
  const slippage = slippagePercent / 100

  const slippagePrecision = 12
  const slippageRate = parseUnits(slippage.toString(), slippagePrecision)
  const minMint = mintNoSlippage.sub(
    mintNoSlippage.mul(slippageRate).div(parseUnits('1', slippagePrecision))
  )

  return minMint
}

// let isEarnEthAsset = false
// if (earnEthTokens.find((t) => compareHex(t.address, token.address))) {
//   isEarnEthAsset = true
// }
// if (l1Tokens.find((t) => compareHex(t.address, token.address))) {
//   isEarnEthAsset = false
// }
// if (doNotVamp.find((t) => compareHex(t.address, token.address))) {
//   isEarnEthAsset = false
// }
export function determineIsEarnEthAsset({
  doNotVamp,
  earnEthTokens,
  l1Tokens,
  tokenAddress,
}: {
  tokenAddress: string
  earnEthTokens: { address: string }[]
  l1Tokens: { address: string }[]
  doNotVamp: { address: string }[]
}) {
  let isEarnEthAsset = false
  if (earnEthTokens.find((t) => compareHex(t.address, tokenAddress))) {
    isEarnEthAsset = true
  }
  // if (l1Tokens.find((t) => compareHex(t.address, tokenAddress))) {
  //   isEarnEthAsset = false
  // }
  // if (doNotVamp.find((t) => compareHex(t.address, tokenAddress))) {
  //   isEarnEthAsset = false
  // }

  return isEarnEthAsset
}
