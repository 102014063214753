import { AsyncDiv } from '@/swell-ui/AsyncDiv'
import { KeyValueList } from '@/swell-ui/KeyValueList'

export function LstExchangeInfo({
  unstake,
  apr,
  exchangeRate,
  transactionFee,
  processingTime,
}: {
  unstake: boolean
  exchangeRate: string
  apr: string
  transactionFee: string
  processingTime?: string
}) {
  const swethAprConfig = {
    label: 'swETH APR',
    value: <AsyncDiv loading={!apr}>{() => apr}</AsyncDiv>,
    infoText: (
      <span>
        <a
          href="https://dune.com/swell-network/swell-network"
          target="_blank"
          rel="noreferrer"
        >
          Total pool 7 day moving average
        </a>
      </span>
    ),
    hasInfo: true,
  }

  const exchangeRateConfig = {
    label: 'Exchange rate',
    value: <AsyncDiv loading={!exchangeRate}>{() => exchangeRate}</AsyncDiv>,
    hasInfo: true,
    infoText: 'Represents the accruing value of swETH vs ETH',
  }

  const exchangeInfoConfig = {
    label: 'Transaction fee',
    value: (
      <AsyncDiv loading={!transactionFee}>
        {() => transactionFee + ' USD'}
      </AsyncDiv>
    ),
  }

  const processingTimeConfig = {
    label: 'Processing time',
    value: <div>~12 days</div>,
    hasInfo: true,
    infoText: (
      <a
        href="https://docs.swellnetwork.io/swell/sweth-liquid-staking/sweth-v1.0-system-design/withdrawals"
        target="_blank"
        rel="noreferrer"
      >
        Visit docs for more info
      </a>
    ),
  }

  const list = [swethAprConfig, exchangeRateConfig, exchangeInfoConfig]

  if (unstake) {
    list.push(processingTimeConfig)
  }

  const exchangeInfoListConfig = {
    list,
  }

  return <KeyValueList config={exchangeInfoListConfig} />
}
