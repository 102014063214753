import { Stake } from '@/submodules/v3-shared/ts/connect/swell/v3/staker_pb'
import { ActivitySummary_Item } from '@/types/activity'
import { ExitClaim } from '@/types/claims'
import { displayCrypto, displayCryptoLocale } from '@/util/displayCrypto'
import { shortenAddress } from '@/util/hexStrings'
import axios from 'axios'
import { BigNumber } from 'ethers'

const NFTAttributes = [
  'ETH Amount',
  'swETH Amount',
  'Status',
  'Queue Position',
  'Total In Queue',
  'Request Timestamp',
  'Exchange Rate',
] as const
type NFTAttributes = (typeof NFTAttributes)[number]

const NFT_METADATA_ATTR_TO_CLAIM_KEY: Record<NFTAttributes, keyof ExitClaim> = {
  'ETH Amount': 'assetAmount',
  'swETH Amount': 'tokenAmount',
  Status: 'status',
  'Queue Position': 'queuePosition',
  'Total In Queue': 'totalInQueue',
  'Request Timestamp': 'requestTimestamp',
  'Exchange Rate': 'exchangeRate',
}

type TokenMetadata = {
  attributes: { trait_type: string; value: any }[]
}

export const swethClaimAdapter = (
  requestId: number,
  tokenMetadata: TokenMetadata
): ExitClaim => {
  const claim = {
    assetAmount: 0,
    exchangeRate: 0,
    queuePosition: 0,
    requestId: `${requestId}`,
    requestTimestamp: 0,
    status: '',
    tokenAmount: 0,
    totalInQueue: 0,
  } as ExitClaim
  tokenMetadata.attributes.forEach((attribute) => {
    const traitType = attribute.trait_type as NFTAttributes
    if (!(traitType in NFT_METADATA_ATTR_TO_CLAIM_KEY)) {
      return
    }
    const key = NFT_METADATA_ATTR_TO_CLAIM_KEY[traitType]
    ;(claim as any)[key] = attribute.value
  })
  claim.requestId = `${requestId}`
  return claim as ExitClaim
}

async function fetchClaim(v3BackendLrtUrl: string, tokenId: number) {
  const res = await axios.get(
    `${v3BackendLrtUrl}/api/swexit/sweth/metadata/${tokenId}`
  )
  return swethClaimAdapter(tokenId, res.data)
}

export class SwEXITClaimFetcher {
  private backendUrl: string

  constructor(backendUrl: string) {
    this.backendUrl = backendUrl
  }

  public fetchClaim = async (requestId: number): Promise<ExitClaim> => {
    return fetchClaim(this.backendUrl, requestId)
  }
}

export function stakeAdapter(
  { address, mintedSweth, stakedWei, timestamp, txHash }: Stake,
  {
    explorerBaseUrl,
    swETHToken,
    nativeCurrency,
  }: {
    explorerBaseUrl: string
    swETHToken: { decimals: number; symbol: string }
    nativeCurrency: { decimals: number; symbol: string }
  }
): ActivitySummary_Item {
  let link = ''
  if (txHash) {
    const url = new URL(explorerBaseUrl)
    url.pathname = `/tx/${txHash}`
    link = url.toString()
  }

  return {
    action: 'ETH staked',
    timestampMs: Number(timestamp) * 1000,
    deposit:
      displayCrypto(BigNumber.from(stakedWei), nativeCurrency.decimals) +
      ' ' +
      nativeCurrency.symbol,
    link,
    receive:
      displayCryptoLocale(BigNumber.from(mintedSweth), swETHToken.decimals) +
      ' ' +
      swETHToken.symbol,
    wallet: shortenAddress(address, 3, 4),
  }
}
