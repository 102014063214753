import { createContext, useContext } from 'react'
import { IL2PromotionsApi } from './types'

const MISSING_PROVIDER = Symbol()

export type L2PromotionsContext = IL2PromotionsApi
export const L2PromotionsContext = createContext<
  L2PromotionsContext | typeof MISSING_PROVIDER
>(MISSING_PROVIDER)

export function useL2PromotionsApi() {
  const connectors = useContext(L2PromotionsContext)
  if (connectors === MISSING_PROVIDER) {
    throw new Error(
      'L2PromotionsContext hooks must be wrapped in a <L2PromotionsProvider>'
    )
  }
  return connectors
}
