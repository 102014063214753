import { AsyncDiv } from '@/swell-ui/AsyncDiv'
import { PortfolioDefiPositionSummary } from '../../types'
import { FlexRow } from '@/swell-ui/FlexRow'
import styled from 'styled-components'

export function PortfolioRewardsCell({
  position,
}: {
  position: Pick<PortfolioDefiPositionSummary, 'rewardResult' | 'rewardsNA'>
}) {
  if (position.rewardsNA) return <span>-</span>

  if (position.rewardResult.rewards!.length <= 2) {
    return (
      <FlexRow gap="6" align="start" direction="column">
        {position.rewardResult.rewards!.map((reward, i) => {
          return (
            <FlexRow key={i} gap="6">
              <img
                src={reward.claimableAssetLogoURI}
                width={'18'}
                height={'18'}
                style={{
                  borderRadius: reward.claimableAssetSquareLogo ? '0' : '50%',
                }}
              />
              <AsyncDiv loading={!reward.claimable}>
                {() => <span>{reward.claimable}</span>}
              </AsyncDiv>
            </FlexRow>
          )
        })}
      </FlexRow>
    )
  }

  const firstThreeRewards = position.rewardResult.rewards!.slice(0, 3)

  return (
    <OversizeLayout>
      <div>
        <div>
          {firstThreeRewards.map((reward, i) => {
            return (
              <FlexRow key={i} gap="6">
                <img
                  src={reward.claimableAssetLogoURI}
                  width={'18'}
                  height={'18'}
                  style={{
                    borderRadius: reward.claimableAssetSquareLogo ? '0' : '50%',
                  }}
                />
                <AsyncDiv loading={!reward.claimable}>
                  {() => <span>{reward.claimable}</span>}
                </AsyncDiv>
              </FlexRow>
            )
          })}
        </div>
      </div>
    </OversizeLayout>
  )
}

const OversizeLayout = styled.div`
  position: relative;
  > div {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(0, -50%);
    > div {
      display: flex;
      flex-direction: column;
      gap: 3px;
    }
  }
`
