import { getAddress } from 'ethers/lib/utils'
import { StaticAirdropDataRaw, IStaticAirdropService } from './types'
import axios, { AxiosError } from 'axios'
import { parseRawStaticAirdropResult } from './util'

export class StaticAirdropService implements IStaticAirdropService {
  private baseURL: string
  constructor({ baseURL }: { baseURL: string }) {
    this.baseURL = baseURL?.replace(/\/$/, '')
  }

  private airdropUrl = (address: string) =>
    `${this.baseURL}/api/airdrop?address=${address}&opn=3`

  airdrop: IStaticAirdropService['airdrop'] = async (address: string) => {
    address = getAddress(address)
    address = address.toLowerCase()
    let data: StaticAirdropDataRaw
    try {
      const res = await axios.get(this.airdropUrl(address))
      data = res.data
    } catch (e: unknown) {
      if (e && e instanceof AxiosError && e.response?.status === 404) {
        return {
          exists: false,
          pearls: 0,
          isSybil: false,
        }
      }

      throw e
    }

    return parseRawStaticAirdropResult(data)
  }
}
