import { FlexRow } from '@/swell-ui/FlexRow'
import { useCallback } from 'react'
import styled, { css } from 'styled-components'
import {
  usePredepositCalls,
  usePredepositStats,
  usePredepositTokens,
  usePredepositUser,
} from '@/state/predeposit/hooks'
import { useSwellWeb3 } from '@/swell-web3/core'
import { L2PageView } from './types'
import { L2BridgeBreakpoints } from './styles'
import { TransactionContext } from '@/state/transactions/context'
import swellL2Large from '@/assets/images/swell_l2_large.png'
import { useWeb3CallErrorNotify } from '@/hooks/useWeb3CallErrorNotify'
import {
  useSwellchainLiveness,
  useL2PageView,
  usePredepositInputs,
  usePredepositTransactionFees,
  usePredepositWithdrawController,
} from './hooks'
import { SwellchainWidget } from './SwellchainWidget'
import { prepareWithdrawFromPredeposit } from './swellchainCalls'
import {
  makeGlobalBridgeEventsSummary,
  makeIncentivesSummary,
  makePredepositTokensSummary,
  makePredepositWithdrawalSummary,
  makeSwellchainStatsSummary,
} from './swellchainFormatting'
import { WithdrawFromPredepositToast } from './SwellchainToasts'
import {
  useL2BuiltWith,
  useL2LaunchPartners,
  useL2PromotionStats,
  useGlobalBridgeEvents,
} from '@/state/l2promotions/hooks'
import { useEthUsdRate } from '@/state/prices/hooks'
import { LaunchPartnersSection } from './components/LaunchPartners'
import { BuiltWithSection } from './components/BuiltWith'
import { BridgeFaqSection } from './components/BridgeFaqSection'
import { useNowMs } from '@/hooks/useTimeCountdown'
import { NewTxDrop } from './components/NewTxDrop'
import { SwellchainIncentives } from './components/SwellchainIncentives'
import { useSwellDaoToken } from '@/state/dao/hooks'
import {
  useNucleusEthAllowances,
  useNucleusEthAuth,
  useNucleusEthBalances,
  useNucleusEthRates,
  useNucleusEthSupportedAssets,
  useNucleusEthVault,
  useNucleusEthVaultCalls,
  useNucleusEthVaultState,
} from '@/state/nucleusVault/hooks'
import { ROUTES } from '@/constants/routes'
import { Link } from 'react-router-dom'
import { Button } from '@/swell-ui/Button'
import { useAddSwellchainToWallet } from '@/hooks/useAddChainToWallet'
import {
  prepareNucleusApproveAssetForDeposit,
  prepareNucleusDeposit,
} from '@/components/Nucleus/nucleusCalls'
import { BigNumber } from 'ethers'
import {
  NucleusApproveAssetForDepositToast,
  NucleusDepositToast,
} from '@/components/Nucleus/NucleusToasts'
import { useL2EcosystemStats } from '@/state/l2ecosystem/hooks'
import { SwellchainStats } from './components/SwellchainStats'
import { useCrossChainBridgeSettings } from '@/state/deployments/hooks/crossChainBridgeSettings'

const WIDGET_WIDTH_PX = 420
const EARN_ETH_DEPOSIT_SLIPPAGE = 0.01
const DO_NOT_VAMP = [] as any[]
const TWO_WEEKS_MS = 2 * 7 * 24 * 60 * 60 * 1000

export function SwellchainPage() {
  const earnETHVault = useNucleusEthVault()

  const daoToken = useSwellDaoToken()

  const predepositCalls = usePredepositCalls()
  const nucleusCalls = useNucleusEthVaultCalls()
  for (const call of Object.values({
    ...predepositCalls,
    ...nucleusCalls,
  })) {
    useWeb3CallErrorNotify(call)
  }
  const predepositTokens = usePredepositTokens()

  const viewInputs = useL2PageView({ defaultView: L2PageView.withdraw })
  const predepositInputs = usePredepositInputs({ predepositTokens })

  const { account, chainId } = useSwellWeb3()
  const { l1Tokens } = useCrossChainBridgeSettings()
  const l2StatsQuery = useL2EcosystemStats()

  const predepositUserQuery = usePredepositUser()
  const predepositStatsQuery = usePredepositStats()
  const l2PromotionStatsQuery = useL2PromotionStats()
  const l2PromotionsQuery = useL2LaunchPartners()
  const l2BuiltWithQuery = useL2BuiltWith()
  const globalBridgeEventsQuery = useGlobalBridgeEvents()
  const earnEthAuthQuery = useNucleusEthAuth()
  const earnEthAllowancesQuery = useNucleusEthAllowances()
  const earnEthBalancesQuery = useNucleusEthBalances()
  const earnEthRatesQuery = useNucleusEthRates()
  const earnEthSupportedTokensQuery = useNucleusEthSupportedAssets()
  const earnEthVaultStateQuery = useNucleusEthVaultState()

  const _mutatePredepositUser = predepositUserQuery.mutate
  const _mutateEarnEthAllowances = earnEthAllowancesQuery.mutate
  const _mutateEarnEthBalances = earnEthBalancesQuery.mutate

  const mutatePredepositUser = useCallback(() => {
    _mutatePredepositUser()
  }, [_mutatePredepositUser])
  const mutateNucleus = useCallback(() => {
    _mutateEarnEthAllowances()
    _mutateEarnEthBalances()
  }, [_mutateEarnEthAllowances, _mutateEarnEthBalances])

  useSwellchainLiveness({
    predepositCalls,
    mutatePredepositUser,
    mutateNucleus,
    nucleusCalls,
  })

  const l2Stats = l2StatsQuery.data
  const predepositUser = predepositUserQuery.data
  const predepositStats = predepositStatsQuery.data
  const l2PromoStats = l2PromotionStatsQuery.data
  const l2Promotions = l2PromotionsQuery.data
  const l2BuiltWith = l2BuiltWithQuery.data
  const globalBridgeEvents = globalBridgeEventsQuery.data
  const earnEthAuth = earnEthAuthQuery.data
  const earnEthBalances = earnEthBalancesQuery.data
  const earnEthRates = earnEthRatesQuery.data
  const earnEthSupportedTokens = earnEthSupportedTokensQuery.data
  const earnEthVaultState = earnEthVaultStateQuery.data
  const earnEthAllowances = earnEthAllowancesQuery.data

  const anyTransactionInProgress = [
    ...Object.values({
      ...predepositCalls,
    }),
  ].some(
    (call) =>
      call.status === call.STATUS.PENDING ||
      call.status === call.STATUS.PROMPTING
  )

  const predepositTokensSummary = makePredepositTokensSummary({
    predepositTokens,
    predepositUser,
    account,
    earnEthTokens: earnETHVault.depositAssets,
    l1Tokens,
    doNotVamp: DO_NOT_VAMP,
  })

  const preparedWithdrawFromPredeposit = prepareWithdrawFromPredeposit({
    predepositInputs,
    predepositUser,
  })
  const ethUsdRate = useEthUsdRate().data?.rate
  const withdrawalTxFeeResult = usePredepositTransactionFees()

  let earnEthDepositAssetAllowanceForVault: BigNumber | undefined
  if (
    earnEthAllowances &&
    earnEthAllowances.assetsForDeposit &&
    predepositInputs.withdrawToken
  ) {
    earnEthDepositAssetAllowanceForVault =
      earnEthAllowances.assetsForDeposit?.[earnETHVault.vaultToken.chainId]?.[
        predepositInputs.withdrawToken.address
      ]
  }
  let earnEthDepositAssetBalance: BigNumber | undefined
  if (
    earnEthBalances &&
    earnEthBalances.assets &&
    predepositInputs.withdrawToken
  ) {
    earnEthDepositAssetBalance =
      earnEthBalances.assets?.[earnETHVault.vaultToken.chainId]?.[
        predepositInputs.withdrawToken.address
      ]
  }

  let vaultTokenRateInQuote: BigNumber | undefined
  if (earnEthRates && predepositInputs.withdrawToken) {
    vaultTokenRateInQuote =
      earnEthRates.vaultTokenQuoteRates?.[earnETHVault.vaultToken.chainId]?.[
        predepositInputs.withdrawToken.address
      ]
  }

  const preparedEarnEthDeposit = prepareNucleusDeposit({
    auth: earnEthAuth,
    baseAsset: earnETHVault.baseAsset,
    depositAmount: predepositInputs.withdrawAmount,
    depositToken: predepositInputs.withdrawToken,
    depositAssetAllowanceForVault: earnEthDepositAssetAllowanceForVault,
    depositAssetBalance: earnEthDepositAssetBalance,
    requiresApproval: true,
    slippage: EARN_ETH_DEPOSIT_SLIPPAGE,
    supportedAssets: earnEthSupportedTokens?.deposit,
    vaultState: earnEthVaultState,
    vaultTokenRateInQuote: vaultTokenRateInQuote,
    chainId,
  })

  const preparedApproveAssetForDeposit = prepareNucleusApproveAssetForDeposit({
    assetAmount: predepositInputs.withdrawAmount,
    assetBalance: earnEthDepositAssetBalance,
    assetAddress: predepositInputs.withdrawToken?.address,
  })

  const controller = usePredepositWithdrawController({
    rates: earnEthRates,
    nucleusCalls,
    predepositCalls,
    preparedDeposit: preparedEarnEthDeposit,
    slippage: EARN_ETH_DEPOSIT_SLIPPAGE,
    predepositInputs,
    predepositUser,
    doNotVamp: DO_NOT_VAMP,
    earnEthTokens: earnETHVault.depositAssets,
    l1Tokens,
    vault: earnETHVault,
  })

  const predepositWithdrawalSummary = makePredepositWithdrawalSummary({
    predepositInputs,
    predepositUser,
    withdrawalTxFeeResult,
    ethUsdRate,
    predepositStats,
    account,
    controller,
    earnEthBalances,
    doNotVamp: DO_NOT_VAMP,
    earnEthTokens: earnETHVault.depositAssets,
    l1Tokens,
  })

  const nowMs = useNowMs()
  const globalBridgeEventsSummary = makeGlobalBridgeEventsSummary({
    globalBridgeEvents,
    nowMs,
    tokens: l1Tokens,
    maxSize: 7,
  })
  const incentivesSummary = makeIncentivesSummary({
    daoToken,
    currentIncentive: l2PromoStats?.currentIncentive,
    fallbackIntervalMs: TWO_WEEKS_MS,
    nowMs,
  })
  const swellchainStats = makeSwellchainStatsSummary({
    l2Stats,
  })

  const addChainToWallet = useAddSwellchainToWallet()

  return (
    <TransactionContext.Provider value={{ anyTransactionInProgress }}>
      <PageLayout>
        <div className="a-title">
          <PageTitle />
        </div>
        <div className="a-description">
          <p className="body-medium">
            Swellchain is here! The restaking chain, powered by Proof of
            Restake. Built on OP and part of the Superchain family. Join the
            early adopters and bridge your assets.
          </p>
          <div style={{ height: '24px' }} />
          <div id="tvl">
            <SwellchainStats swellchainStats={swellchainStats} />
          </div>
        </div>
        <div className="a-widget">
          <SwellchainWidget
            earnEthBalances={earnEthBalances}
            predepositTokens={predepositTokens}
            predepositTokensSummary={predepositTokensSummary}
            predepositWithdrawalSummary={predepositWithdrawalSummary}
            viewInputs={viewInputs}
            predepositUser={predepositUser}
            preparedWithdrawFromPredeposit={preparedWithdrawFromPredeposit}
            predepositInputs={predepositInputs}
            predepositCalls={predepositCalls}
            // bridgeCalls={bridgeCalls}
            // bridgeTokens={bridgeTokens}
            // bridgeProviders={bridgeProviders}
            // recentTxs={recentTxs}
            // quoteResult={quoteResult}
            // quoteSummaryResult={quoteSummaryResult}
            // bridgeUserSummary={bridgeUserSummary}
            // historySummary={historySummary}
            // otherProvidersSummary={otherProvidersSummary}
            preparedApproveAssetForDeposit={preparedApproveAssetForDeposit}
            preparedEarnEthDeposit={preparedEarnEthDeposit}
            controller={controller}
          />
        </div>
        <div className="a-prevtx">
          <div className="box">
            <span className="body-small colored uppercased">
              Recent bridge transactions
            </span>
            <NewTxDrop globalBridgeEventsSummary={globalBridgeEventsSummary} />
          </div>
        </div>
        <div className="a-info body-medium">
          <span className="headlines-h6 above">Calling all early adopters</span>
          <SwellchainIncentives incentivesSummary={incentivesSummary} />
          <div style={{ height: '24px' }} />
          <ol>
            <li style={{ marginBottom: '12px' }}>
              Check this Wave's yield opportunities on the{' '}
              <Link to={ROUTES.EarnPortfolio}>Portfolio page</Link>
            </li>
            <li style={{ marginBottom: '12px' }}>
              Deposit assets to accrue rewards including $SWELL and tokens from
              Swellchain dApps
            </li>
            <li style={{ marginBottom: '12px' }}>
              Claim your rewards at Wavedrops every 10 weeks, or earlier for a
              discounted amount.
            </li>
          </ol>
          <p>The next Wave will begin when the timer hits zero!</p>
          <FlexRow gap="12" wrap>
            <a
              href="https://www.swellnetwork.io/post/wave-1"
              rel="noopener noreferrer"
              target="_blank"
              style={{ width: 'max-content', display: 'block' }}
            >
              <Button
                variant="secondary"
                style={{ width: 'auto', height: '38px' }}
              >
                Learn more
              </Button>
            </a>
            <Button
              variant="secondary"
              style={{ width: 'auto', height: '38px', whiteSpace: 'nowrap' }}
              disabled={!account}
              onClick={addChainToWallet}
            >
              Add Swellchain to your wallet
            </Button>
          </FlexRow>
          <div style={{ height: '24px' }} />
          <div style={{ height: '24px' }} />
          <span className="headlines-h7">Deposited in the Pre-Launch?</span>
          <ol>
            <li style={{ marginBottom: '12px' }}>Withdraw your asset</li>
            <li style={{ marginBottom: '12px' }}>
              Bridge your asset to Swellchain
            </li>
            <li style={{ marginBottom: '12px' }}>
              Don't forget to bridge some ETH too, for gas
            </li>
          </ol>
          <p>
            Deposits will be halted and rewards will stop flowing to the
            Pre-launch on December 19th, and the rewards you have earned are
            broken down in the FAQ below.
          </p>
          <div style={{ height: '24px' }} />
          <span className="headlines-h7">Looking to build on Swellchain?</span>
          <p>
            Check out our developer docs at{' '}
            <a
              href="https://build.swellnetwork.io/"
              target="_blank"
              rel="noopener noreferrer"
            >
              build.swellnetwork.io
            </a>
            .
          </p>
        </div>
        <div className="a-partners">
          <LaunchPartnersSection l2Promotions={l2Promotions} />
        </div>
        <div className="a-builtwith">
          <BuiltWithSection l2BuiltWith={l2BuiltWith} />
        </div>
        <div className="a-faq">
          <BridgeFaqSection />
        </div>
        {/* <ApproveBridgeToast
          approveBridge={bridgeCalls.approveBridge}
          fromToken={fromToken}
        /> */}
        {/* <BridgeTxToast
          bridgeCall={bridgeCalls.bridgeCall}
          activeBridgeTx={activeBridgeTx}
          bridgeTxSummary={activeTxSummary}
        /> */}
        <WithdrawFromPredepositToast
          withdrawFromPredeposit={predepositCalls.withdrawFromPredeposit}
          predepositTokens={predepositTokens}
        />
        <NucleusApproveAssetForDepositToast
          approveAssetForDeposit={nucleusCalls.approveAssetForDeposit}
          anyTransactionInProgress={anyTransactionInProgress}
          depositAssets={earnETHVault.depositAssets}
        />
        <NucleusDepositToast
          baseAsset={earnETHVault.baseAsset}
          deposit={nucleusCalls.deposit}
          anyTransactionInProgress={anyTransactionInProgress}
          depositAssets={earnETHVault.depositAssets}
          rates={earnEthRates}
          vaultToken={earnETHVault.vaultToken}
          chainId={earnETHVault.vaultToken.chainId}
        />
      </PageLayout>
    </TransactionContext.Provider>
  )
}

function PageTitle() {
  return (
    <FlexRow gap="24">
      <img src={swellL2Large} alt="Swell" width="56" height="56" />
      <span className="headlines-h3 headlines-h5-mob nobreak">Swellchain</span>
    </FlexRow>
  )
}

const headlinesH3 = css`
  color: var(--Swell-White-50, #fff);
  /* Headlines/H3 */
  font-family: Inter;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 57.6px */
  letter-spacing: -1.44px;
`
const headlinesH4 = css`
  color: var(--Swell-White-50, #fff);
  /* Headlines/H4 */
  font-family: Inter;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 48px */
  letter-spacing: -1.2px;
`
const headlinesH5 = css`
  color: var(--Swell-White-50, #fff);
  /* Headlines/H5 */
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 38.4px */
  letter-spacing: -0.96px;
`

const headlinesH6 = css`
  color: var(--Swell-White-50, #fff);
  /* Headlines/H6 */
  font-family: Inter;
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 33.8px */
  letter-spacing: -0.78px;
`
const headlinesH7 = css`
  color: var(--Swell-White-50, #fff);
  /* Headlines/H7 */
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 26px */
  letter-spacing: -0.6px;
`

const bodyMedium = css`
  color: var(--Swell-White-125, #b0b0b0);
  /* Body/medium */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 25.6px */
  letter-spacing: -0.48px;
`
const bodyUppercaseMedium = css`
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 22.4px */
  letter-spacing: 1.05px;
  text-transform: uppercase;
  background: linear-gradient(90deg, #845aff 0%, #4943e0 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`
const uppercaseMediumWhite = css`
  color: var(--Swell-White-50, #fff);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 22.4px */
  letter-spacing: 1.05px;
  text-transform: uppercase;
`
const bodyUppercaseSmall = css`
  color: var(--Swell-White-100, #e7e7e7);
  /* Body/uppercase small */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 19.2px */
  letter-spacing: 0.9px;
  text-transform: uppercase;
`
const bodyXLargeBold = css`
  color: var(--Swell-White-100, #e7e7e7);
  /* Body/xlarge bold */
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 44.8px */
`
const bodyLargeBold = css`
  color: var(--Swell-White-50, #fff);

  /* Body/large bold */
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 28.8px */
`

const bodyMediumBold = css`
  color: var(--Swell-White-100, #e7e7e7);
  /* Body/medium bold */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%; /* 25.6px */
`

const bodySmall = css`
  /* Body/small */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 22.4px */
  letter-spacing: -0.42px;
`

const bodyXSmall = css`
  color: var(--Swell-White-125, #b0b0b0);
  /* Body/xsmall */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 19.2px */
  letter-spacing: -0.36px;
`

const pageStyles = css`
  .headlines-h3 {
    ${headlinesH3};
  }
  .headlines-h4 {
    ${headlinesH4};
  }
  .headlines-h5 {
    ${headlinesH5};
  }
  .headlines-h6 {
    ${headlinesH6};
  }
  .headlines-h7 {
    ${headlinesH7};
  }
  .body-uppercase-medium {
    ${bodyUppercaseMedium};
  }
  .uppercase-medium-white {
    ${uppercaseMediumWhite};
  }
  .body-uppercase-small {
    ${bodyUppercaseSmall};
  }
  .nobreak {
    white-space: nowrap;
  }
  .body-medium-bold {
    ${bodyMediumBold};
  }
  .body-medium {
    ${bodyMedium};
  }
  .body-xlarge-bold {
    ${bodyXLargeBold};
  }
  .body-large-bold {
    ${bodyLargeBold};
  }
  .body-small {
    ${bodySmall};
  }
  .body-xsmall {
    ${bodyXSmall};
  }
  .colored {
    color: var(--Swell-Lightblue-75, #6e79e9);
  }
  .uppercased {
    text-transform: uppercase;
  }

  @media (max-width: 768px) {
    .headlines-h5-mob {
      ${headlinesH5};
    }
    .headlines-h6-mob {
      ${headlinesH6};
    }
  }
`

const layoutMobile = css`
  padding: 24px 16px 0;
  grid-template-columns: repeat(1, 1fr);
  grid-auto-rows: auto;
  grid-template-areas:
    'title'
    'description'
    'widget'
    'prevtx'
    'info'
    'partners'
    'builtwith'
    'faq';

  .a-title {
    justify-self: start;
    text-align: left;
  }
  .a-description {
    justify-self: start;
    text-align: left;
    #tvl {
      margin-right: auto;
      width: max-content;
      margin-bottom: 32px;
    }
  }
  .a-widget {
    justify-self: stretch;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    max-width: 420px;
    margin: 0 auto;
  }
  .a-prevtx {
    margin-top: 12px;
    margin-bottom: 143px;
    .box {
      max-width: 420px;
      margin: 0 auto;
      display: flex;
      padding: 24px;
      flex-direction: column;
      align-items: stretch;
      gap: 6px;
      align-self: stretch;
      border-radius: 16px;
      border: 1px solid rgba(164, 171, 241, 0.2);
    }
  }
  .a-partners {
    margin-bottom: 75px;
  }
  .a-faq {
    margin-top: 75px;
  }

  @media (max-width: ${L2BridgeBreakpoints.mobile}px) {
    .a-widget {
      max-width: 420px;
      margin: 0 auto;
    }
  }
`
const layoutDesktop = css`
  padding: 24px 32px 0;
  max-width: 1100px;
  grid-column-gap: 93px;
  grid-template-columns: 1fr ${WIDGET_WIDTH_PX}px;
  grid-auto-rows: auto;
  grid-template-areas:
    'title        title'
    'description  description'
    'info         widget'
    'info         prevtx'
    'partners     partners'
    'builtwith    builtwith'
    'faq          faq';

  .a-title {
    justify-self: center;
    text-align: center;
  }
  .a-description {
    justify-self: center;
    text-align: center;
    max-width: 829px;
    margin-top: 16px;
    margin-bottom: 99px;
    p {
      margin: 0;
      padding: 0;
    }
    #tvl {
      margin: 0 auto;
    }
  }
`

const PageLayout = styled.div`
  ${pageStyles}

  ${layoutMobile}
  @media (min-width: ${L2BridgeBreakpoints.tablet}px) {
    ${layoutDesktop}
  }

  max-width: 1200px;
  margin: 0 auto;
  display: grid;

  .a-title {
    grid-area: title;
  }
  .a-description {
    grid-area: description;
  }
  .a-widget {
    grid-area: widget;
  }
  .a-prevtx {
    grid-area: prevtx;
  }
  .a-info {
    grid-area: info;

    ol {
      padding-left: 24px;
      margin-bottom: 24px;
    }

    position: relative;
    .above {
      position: absolute;
      bottom: 100%;
      left: 0;
      transform: translateY(-12px);
    }
  }
  .a-partners {
    grid-area: partners;
  }
  .a-builtwith {
    grid-area: builtwith;
  }
  .a-faq {
    grid-area: faq;
  }
`
