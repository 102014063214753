import { NucleusDepositInputs } from '../../nucleusHooks'
import { NucleusBalances } from '@/state/nucleusVault/types'
import { BigNumber } from 'ethers'
import { NucleusTokenModal } from '../NucleusTokenModal'
import useChainDetection from '@/hooks/useChainDetection'

function makeTokensWithBalances({
  depositInputs: { availableTokens },
  nucleusBalances,
  l1ChainId,
  l2ChainId,
}: {
  depositInputs: Pick<NucleusDepositInputs, 'availableTokens'>
  nucleusBalances: Pick<NucleusBalances, 'assets'> | undefined
  l1ChainId: number
  l2ChainId: number
}) {
  if (!nucleusBalances) {
    return availableTokens
  }

  const balanceMap = {} as Record<number, Record<string, BigNumber>>
  for (const token of availableTokens) {
    if (!balanceMap[token.chainId]) {
      balanceMap[token.chainId] = {}
    }
    balanceMap[token.chainId][token.address] = BigNumber.from(0)
  }

  if (nucleusBalances.assets?.[l1ChainId]) {
    const l1Balances = nucleusBalances.assets[l1ChainId]
    for (const [address, balance] of Object.entries(l1Balances)) {
      if (!balanceMap[l1ChainId]) {
        balanceMap[l1ChainId] = {}
      }
      balanceMap[l1ChainId][address] = balance
    }
  }
  if (nucleusBalances.assets?.[l2ChainId]) {
    const l2Balances = nucleusBalances.assets[l2ChainId]
    for (const [address, balance] of Object.entries(l2Balances)) {
      if (!balanceMap[l2ChainId]) {
        balanceMap[l2ChainId] = {}
      }
      balanceMap[l2ChainId][address] = balance
    }
  }

  const l = availableTokens.map((token) => ({
    ...token,
    balance: balanceMap?.[token.chainId]?.[token.address],
  }))

  l.sort((a, b) => {
    const aBalance = a.balance
    const bBalance = b.balance
    if (aBalance.lt(bBalance)) {
      return 1
    }
    if (aBalance.gt(bBalance)) {
      return -1
    }
    return 0
  })
  return l
}

export function NucleusDepositTokenSelect({
  depositInputs,
  nucleusBalances,
}: {
  depositInputs: NucleusDepositInputs
  nucleusBalances: NucleusBalances | undefined
}) {
  const { deploymentChainId, l2DeploymentChainId } = useChainDetection()

  const tokensWithBalances = makeTokensWithBalances({
    depositInputs,
    nucleusBalances,
    l1ChainId: deploymentChainId,
    l2ChainId: l2DeploymentChainId,
  })

  return (
    <NucleusTokenModal
      tokensWithBalances={tokensWithBalances}
      selectedToken={depositInputs.selectedToken}
      selectToken={depositInputs.selectToken}
    />
  )
}
