import { useState } from 'react'
import styled from 'styled-components/macro'
import { Accordion } from '@swell-ui/Accordion'
import { DownCaratIcon } from '@swell-ui/icons/DownCaratIcon'
import { UpCaratIcon } from '@swell-ui/icons/UpCaratIcon'
import { Typography } from '@swell-ui/Typography'
import { RestakingStatisticsAccordionDetails } from './RestakingStatisticsAccordionDetails'

const SummaryContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: -0.03em;

  svg {
    margin-left: 5px;
  }
`

function SwellStatisticAccordionSummary({ open }: { open: boolean }) {
  return (
    <SummaryContainer>
      <Typography variant="body" size="small">
        Swell statistics
      </Typography>
      {!open && <DownCaratIcon />}
      {open && <UpCaratIcon />}
    </SummaryContainer>
  )
}

function SwellStatisticAccordionDetails({ restake }: { restake?: boolean }) {
  if (restake) {
    return <RestakingStatisticsAccordionDetails />
  }

  return null
}

function SwellStatistics({ restake }: { restake?: boolean }) {
  const [open, setOpen] = useState<boolean>(false)

  const accordionConfig = {
    summary: <SwellStatisticAccordionSummary open={open} />,
    details: <SwellStatisticAccordionDetails restake={restake} />,
  }

  function handleChange() {
    if (!open) {
      setOpen(true)
    } else {
      setOpen(false)
    }
  }

  return (
    <Accordion config={accordionConfig} onChange={handleChange}></Accordion>
  )
}

export { SwellStatistics }
