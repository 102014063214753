import styled from 'styled-components'
import { SwellchainStatsSummary } from '../../types'
import { AsyncDiv } from '@/swell-ui/AsyncDiv'
import { useSin } from '@/hooks/useMath'

export function SwellchainStats({
  swellchainStats,
}: {
  swellchainStats: SwellchainStatsSummary
}) {
  const t = useSin()
  return (
    <Layout>
      <span className="body-small colored uppercased">Total value locked</span>
      <AsyncDiv loading={!swellchainStats?.tvl}>
        {() => <span className="body-xlarge-bold">{swellchainStats.tvl}</span>}
      </AsyncDiv>
    </Layout>
  )
}

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-width: 280px;
  [aria-busy='true'] {
    height: 44.8px;
    display: flex;
    flex-flow: column;
    justify-content: center;
  }
`
