import { withdrawalAssetReceivedForVaultToken } from '@/components/Nucleus/nucleusConversions'
import { NucleusDynamicSolverFee } from '@/state/nucleusVault/types'
import {
  NucleusVault,
  NucleusWithdrawRequest,
  NucleusWithdrawRequestResult,
  NucleusWithdrawRequestResultMap,
} from '@/types/nucleus'
import { TokenKey } from '@/types/tokens'
import { BigNumber } from 'ethers'
import { formatUnits } from 'ethers/lib/utils'

export type SolverFeeResult = {
  isHighFee: boolean
  feePercent: number
}

export function calculateSolverFee({
  solverFee,
  offerTokenAmount,
  vaultTokenRateInQuote,
  base,
}: {
  solverFee: NucleusDynamicSolverFee
  offerTokenAmount: BigNumber
  vaultTokenRateInQuote: BigNumber
  base: { decimals: number }
}): SolverFeeResult {
  const { feeHighPercent, feeLowPercent, lowFeeThreshold } = solverFee

  const wantAmountLowFee = withdrawalAssetReceivedForVaultToken({
    base,
    solverDiscount: feeLowPercent / 100,
    toSendVaultToken: offerTokenAmount,
    vaultTokenRateInQuote,
  })

  if (wantAmountLowFee.lte(lowFeeThreshold)) {
    return {
      isHighFee: false,
      feePercent: feeLowPercent,
    }
  }
  return {
    isHighFee: true,
    feePercent: feeHighPercent,
  }
}

// latestNucleusRequest returns the latest withdrawal request from a collection of withdrawal requests, keyed by asset address (wantToken).
// it attaches additional data (wantToken)
export function latestNucleusWithdrawRequest(
  withdrawalRequests: NucleusWithdrawRequestResultMap,
  withdrawAssets: TokenKey[]
): NucleusWithdrawRequestResult {
  let latestRequest: NucleusWithdrawRequest | undefined = undefined
  let wantTokenKey: TokenKey | undefined = undefined
  for (const asset of withdrawAssets) {
    const { address, chainId } = asset
    const withdrawalRequest = withdrawalRequests?.[chainId]?.[address]
    if (!withdrawalRequest?.request) continue
    if (!latestRequest) {
      latestRequest = withdrawalRequest.request
      wantTokenKey = asset
      continue
    }
    const latestDeadline = latestRequest.deadlineUnix
    const deadline = withdrawalRequest.request.deadlineUnix
    if (deadline > latestDeadline) {
      latestRequest = withdrawalRequest.request
      wantTokenKey = asset
    }
  }

  if (!latestRequest || !wantTokenKey) {
    return { exists: false }
  }

  return {
    exists: true,
    request: latestRequest,
    wantTokenKey,
  }
}

export type NucleusVaultTvl = {
  vaultToken: string
  baseTokenUsdCents: string
  totalAssetsWei: string
  baseToken: string
}
export function calculateNucleusGlobalTVL(
  vaults: NucleusVault[],
  data: NucleusVaultTvl[]
) {
  const baseTokenToDecimals: Record<string, number> = {}
  for (const vault of vaults) {
    baseTokenToDecimals[vault.baseAsset.address] = vault.baseAsset.decimals
  }

  let tvlCents = 0
  for (const vault of vaults) {
    const record = data.find((v) => v.vaultToken === vault.vaultToken.address)
    if (record) {
      const { baseTokenUsdCents, totalAssetsWei } = record

      const decimals = baseTokenToDecimals[vault.baseAsset.address]
      if (decimals === undefined) {
        console.error('calculateNucleusGlobalTVL: decimals not found')
        continue
      }

      let baseTokenCentsNum: number
      if (baseTokenUsdCents) {
        baseTokenCentsNum = parseInt(baseTokenUsdCents, 10)
      } else {
        console.error('calculateNucleusGlobalTVL: baseTokenUsdCents not found')
        continue
      }

      let totalAssets: BigNumber
      try {
        totalAssets = BigNumber.from(totalAssetsWei)
      } catch (e) {
        console.error('calculateNucleusGlobalTVL: BigNumber.from failed', e)
        continue
      }

      const totalAssetsNum = parseFloat(formatUnits(totalAssets, decimals))
      if (isNaN(totalAssetsNum)) {
        console.error('calculateNucleusGlobalTVL: totalAssetsNum is NaN')
        continue
      }

      const totalAssetsCents = totalAssetsNum * baseTokenCentsNum

      tvlCents += totalAssetsCents
    }
  }

  return tvlCents
}
