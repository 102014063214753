import { ConnectWalletButton } from '@/components/ConnectWalletButton'
import { SwitchChainButton } from '@/components/SwitchChainButton'
import { Button, ButtonProps } from '@/swell-ui/Button'
import styled from 'styled-components'
import { L2PageViewInputs } from './hooks'
import { PreparedWithdrawFromPredeposit } from './swellchainCalls'
import { useWeb3Button } from '@/hooks/useWeb3Button'
import { CircularProgress } from '@/swell-ui/CircularProgress'
import { WithdrawFromPredeposit } from '@/state/predeposit/hooks'
import { ROUTES } from '@/constants/routes'
import { Link } from 'react-router-dom'
import useChainDetection from '@/hooks/useChainDetection'
import { ThemeProvider } from 'styled-components'
import { earnTheme } from '@/swell-ui/theme/themes'
import {
  NucleusApproveAssetForDeposit,
  NucleusDeposit,
} from '@/state/nucleusVault/hooks'
import {
  PreparedNucleusApproveAssetForDeposit,
  PreparedNucleusDeposit,
} from '@/components/Nucleus/nucleusCalls'
import { useTransactionContext } from '@/state/transactions/context'
import { PredepositWithdrawController } from './types'

const BUILD_DOCS_URL = 'https://build.swellnetwork.io/'

const StyledButton = styled(Button)`
  position: relative;
  width: 100%;
`
const StyledConnectButton = styled(ConnectWalletButton)`
  width: 100%;
`
const StyledSwitchChainButton = styled(SwitchChainButton)`
  width: 100%;
`

export function SwellchainButton(props: ButtonProps) {
  return (
    <StyledButton
      variant="primary"
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.children}
    </StyledButton>
  )
}

const TabActionButton = styled(Button)`
  display: flex;
  height: 40px;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 2px;

  backdrop-filter: blur(1.5px);
  border: 1px solid rgba(164, 171, 241, 0.3);
  &[aria-selected='true'] {
    &,
    &:hover {
      color: var(--Swell-White-50, #fff);
      background: rgba(164, 171, 241, 0.2);
      opacity: 1;
    }
  }
  &[aria-selected='false'] {
    color: var(--Swell-White-150, #818181);
    background: rgba(164, 171, 241, 0.05);

    &:hover {
      background: rgba(164, 171, 241, 0.12);
    }
  }

  &:first-of-type {
    border-radius: 100px 0px 0px 100px;
  }
  &:last-of-type {
    border-radius: 0px 100px 100px 0px;
  }

  /* Body/xsmall bold */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%; /* 19.2px */

  transition: all 0.2s;
`

export function L2BridgeActionSelect({
  viewInputs,
}: {
  viewInputs: L2PageViewInputs
}) {
  const {
    isCustomBridgeView,
    isSuperbridgeView,
    isWithdrawView,
    switchToCustomBridge,
    switchToSuperbridge,
    switchToWithdraw,
  } = viewInputs

  return (
    <ActionSelectLayout>
      <TabActionButton
        onClick={switchToWithdraw}
        aria-selected={isWithdrawView}
      >
        Pre-Launch Withdraw
      </TabActionButton>
      <TabActionButton
        onClick={switchToSuperbridge}
        aria-selected={isSuperbridgeView}
      >
        Bridge
      </TabActionButton>
    </ActionSelectLayout>
  )
}
const ActionSelectLayout = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  > * {
    white-space: nowrap;
    justify-self: stretch;
  }
`

export function WithdrawFromPredepositButton({
  prepared,
  withdrawFromPredeposit,
  preventInteraction,
  idleLabel = 'Withdraw',
  controller,
  isEarnEth,
}: {
  withdrawFromPredeposit: WithdrawFromPredeposit
  prepared: PreparedWithdrawFromPredeposit
  preventInteraction?: boolean
  idleLabel?: string
  controller: PredepositWithdrawController
  isEarnEth?: boolean
}) {
  // StyledSwitchChainButton
  const { anyTransactionInProgress } = useTransactionContext()
  const { isDeploymentChain } = useChainDetection()

  const btn = useWeb3Button(
    withdrawFromPredeposit,
    withdrawFromPredeposit.call,
    prepared.args,
    {
      idleLabel,
      pendingLabel: (
        <ButtonInner>
          <div>
            <ButtonCircularProgress size={24} />
          </div>
          <span>Confirming...</span>
        </ButtonInner>
      ),
      fulfilledLabel: idleLabel,
    }
  )

  if (
    !isEarnEth &&
    anyTransactionInProgress &&
    controller.initiator !== 'withdraw'
  ) {
    return <SwellchainButton disabled>{idleLabel}</SwellchainButton>
  }

  if (!isDeploymentChain) {
    return <StyledSwitchChainButton />
  }

  return (
    <SwellchainButton
      disabled={preventInteraction || btn.disabled}
      onClick={btn.onClick}
    >
      {btn.label}
    </SwellchainButton>
  )
}

export function SwellchainNucleusApproveAssetForDepositButton({
  approveAssetForDeposit,
  prepared,
  preventInteraction,
  idleLabel = 'Approve',
}: {
  approveAssetForDeposit: NucleusApproveAssetForDeposit
  prepared: PreparedNucleusApproveAssetForDeposit
  preventInteraction?: boolean
  idleLabel?: string
}) {
  const btn = useWeb3Button(
    approveAssetForDeposit,
    approveAssetForDeposit.call,
    prepared.args,
    {
      idleLabel: idleLabel,
      pendingLabel: (
        <ButtonInner>
          <div>
            <ButtonCircularProgress size={24} />
          </div>
          <span>Confirming...</span>
        </ButtonInner>
      ),
      fulfilledLabel: idleLabel,
    }
  )

  return (
    <SwellchainButton
      disabled={preventInteraction || btn.disabled}
      onClick={btn.onClick}
    >
      {btn.label}
    </SwellchainButton>
  )
}

export function SwellchainNucleusDepositButton({
  deposit,
  prepared,
  preventInteraction,
  idleLabel = 'Deposit',
}: {
  deposit: NucleusDeposit
  prepared: PreparedNucleusDeposit
  preventInteraction: boolean
  idleLabel?: string
}) {
  const btn = useWeb3Button(deposit, deposit.call, prepared.args, {
    idleLabel: idleLabel,
    pendingLabel: (
      <ButtonInner>
        <div>
          <ButtonCircularProgress size={24} />
        </div>
        <span>Confirming...</span>
      </ButtonInner>
    ),
    fulfilledLabel: idleLabel,
  })

  return (
    <SwellchainButton
      disabled={preventInteraction || btn.disabled}
      onClick={btn.onClick}
    >
      {btn.label}
    </SwellchainButton>
  )
}

export function MintEarnETHMultiStepButton({
  withdrawFromPredeposit,
  preparedWithdrawFromPredeposit,
  approveAssetForDeposit,
  deposit,
  preparedApproveAssetForDeposit,
  preparedDeposit,
  controller,
}: {
  withdrawFromPredeposit: WithdrawFromPredeposit
  preparedWithdrawFromPredeposit: PreparedWithdrawFromPredeposit
  approveAssetForDeposit: NucleusApproveAssetForDeposit
  preparedApproveAssetForDeposit: PreparedNucleusApproveAssetForDeposit
  deposit: NucleusDeposit
  preparedDeposit: PreparedNucleusDeposit
  controller: PredepositWithdrawController
}) {
  const { anyTransactionInProgress } = useTransactionContext()

  let preventInteraction = false
  if (anyTransactionInProgress) {
    preventInteraction = true
  }

  const idleLabel = 'Withdraw and Mint earnETH'

  const phase = controller.phase
  let btn = null
  if (phase === 'unknown') {
    btn = <SwellchainButton disabled>{idleLabel}</SwellchainButton>
  } else if (phase === 'approve') {
    btn = (
      <SwellchainNucleusApproveAssetForDepositButton
        approveAssetForDeposit={approveAssetForDeposit}
        prepared={preparedApproveAssetForDeposit}
        preventInteraction={preventInteraction}
        idleLabel={idleLabel}
      />
    )
  } else if (phase === 'deposit') {
    btn = (
      <SwellchainNucleusDepositButton
        deposit={deposit}
        prepared={preparedDeposit}
        preventInteraction={preventInteraction}
        idleLabel={idleLabel}
      />
    )
  } else if (phase === 'withdraw') {
    btn = (
      <WithdrawFromPredepositButton
        withdrawFromPredeposit={withdrawFromPredeposit}
        prepared={preparedWithdrawFromPredeposit}
        preventInteraction={preventInteraction}
        idleLabel={idleLabel}
        controller={controller}
        isEarnEth={true}
      />
    )
  }

  return <ThemeProvider theme={earnTheme}>{btn}</ThemeProvider>
}

const MultiStepLayout = styled.div`
  display: flex;

  width: 100%;

  position: relative;
  .stepper {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%) translateY(8px);
  }
`

const ButtonCircularProgress = styled(CircularProgress)`
  &.MuiCircularProgress-colorPrimary {
    color: currentColor;
  }
`
const ButtonInner = styled.span`
  position: relative;
  > div {
    position: absolute;
    top: -7px;
    left: -32px;
  }
`

const StyledButtonSecondary = styled(Button)`
  display: flex;
  width: 182px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  height: 42px;
  color: var(--Swell-White-50, #fff);
  text-align: center;
  /* Body/medium bold */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%; /* 25.6px */
`

export function NavigateExploreButton() {
  return (
    <Link to={ROUTES.SwellchainEcosystem}>
      <StyledButtonSecondary variant={'secondary'}>
        Explore
      </StyledButtonSecondary>
    </Link>
  )
}
export function NavigateBuildButton() {
  return (
    <a href={BUILD_DOCS_URL} target="_blank" rel="noopener noreferrer">
      <StyledButtonSecondary variant={'secondary'}>Build</StyledButtonSecondary>
    </a>
  )
}
