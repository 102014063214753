import {
  TOKEN_LIST_ETH,
  TOKEN_LIST_ETH_ARBITRUM,
  TOKEN_LIST_ETH_OPTIMISM,
  TOKEN_LIST_ETH_SEPOLIA,
  TOKEN_LIST_ETH_SWELL_L2,
  TOKEN_LIST_ETH_SWELL_L2_TESTNET,
  TOKEN_LIST_ETHENA_ENA,
  TOKEN_LIST_ETHENA_ENA_ARBITRUM,
  TOKEN_LIST_ETHENA_ENA_OPTIMISM,
  TOKEN_LIST_ETHENA_ENA_SEPOLIA,
  TOKEN_LIST_ETHENA_ENA_SWELL_L2,
  TOKEN_LIST_ETHENA_ENA_SWELL_L2_TESTNET,
  TOKEN_LIST_ETHENA_SUSDE,
  TOKEN_LIST_ETHENA_SUSDE_ARBITRUM,
  TOKEN_LIST_ETHENA_SUSDE_OPTIMISM,
  TOKEN_LIST_ETHENA_SUSDE_SEPOLIA,
  TOKEN_LIST_ETHENA_SUSDE_SWELL_L2,
  TOKEN_LIST_ETHENA_SUSDE_SWELL_L2_TESTNET,
  TOKEN_LIST_ETHENA_USDE,
  TOKEN_LIST_ETHENA_USDE_ARBITRUM,
  TOKEN_LIST_ETHENA_USDE_OPTIMISM,
  TOKEN_LIST_ETHENA_USDE_SEPOLIA,
  TOKEN_LIST_ETHENA_USDE_SWELL_L2,
  TOKEN_LIST_ETHENA_USDE_SWELL_L2_TESTNET,
  TOKEN_LIST_KELP_RSETH,
  TOKEN_LIST_KELP_RSETH_ARBITRUM,
  TOKEN_LIST_KELP_RSETH_OPTIMISM,
  TOKEN_LIST_KELP_RSETH_SEPOLIA,
  TOKEN_LIST_KELP_RSETH_SWELL_L2,
  TOKEN_LIST_KELP_RSETH_SWELL_L2_TESTNET,
  TOKEN_LIST_LORENZO_STBTC,
  TOKEN_LIST_LORENZO_STBTC_SWELL_L2,
  TOKEN_LIST_LORENZO_STBTC_SWELL_L2_TESTNET,
  TOKEN_LIST_RENZO_EZETH,
  TOKEN_LIST_RENZO_EZETH_ARBITRUM,
  TOKEN_LIST_RENZO_EZETH_OPTIMISM,
  TOKEN_LIST_RENZO_EZETH_SEPOLIA,
  TOKEN_LIST_RENZO_EZETH_SWELL_L2,
  TOKEN_LIST_RENZO_EZETH_SWELL_L2_TESTNET,
  TOKEN_LIST_RENZO_PZETH,
  TOKEN_LIST_RENZO_PZETH_SWELL_L2,
  TOKEN_LIST_RENZO_PZETH_SWELL_L2_TESTNET,
  TOKEN_LIST_RSWELL,
  TOKEN_LIST_RSWELL_ARBITRUM,
  TOKEN_LIST_RSWELL_OPTIMISM,
  TOKEN_LIST_RSWELL_SEPOLIA,
  TOKEN_LIST_RSWELL_SWELL_L2,
  TOKEN_LIST_RSWELL_SWELL_L2_TESTNET,
  TOKEN_LIST_RSWETH,
  TOKEN_LIST_RSWETH_SWELL_L2,
  TOKEN_LIST_RSWETH_SWELL_L2_TESTNET,
  TOKEN_LIST_SWBTC,
  TOKEN_LIST_SWBTC_ARBITRUM,
  TOKEN_LIST_SWBTC_OPTIMISM,
  TOKEN_LIST_SWBTC_SEPOLIA,
  TOKEN_LIST_SWBTC_SWELL_L2,
  TOKEN_LIST_SWBTC_SWELL_L2_TESTNET,
  TOKEN_LIST_SWELL,
  TOKEN_LIST_SWELL_ARBITRUM,
  TOKEN_LIST_SWELL_OPTIMISM,
  TOKEN_LIST_SWELL_SEPOLIA,
  TOKEN_LIST_SWELL_SWELL_L2,
  TOKEN_LIST_SWELL_SWELL_L2_TESTNET,
  TOKEN_LIST_SWETH,
  TOKEN_LIST_SWETH_SWELL_L2,
  TOKEN_LIST_SWETH_SWELL_L2_TESTNET,
  TOKEN_LIST_USDC,
  TOKEN_LIST_USDC_ARBITRUM,
  TOKEN_LIST_USDC_OPTIMISM,
  TOKEN_LIST_USDC_SEPOLIA,
  TOKEN_LIST_USDC_SWELL_L2,
  TOKEN_LIST_USDC_SWELL_L2_TESTNET,
  TOKEN_LIST_USDT,
  TOKEN_LIST_USDT_ARBITRUM,
  TOKEN_LIST_USDT_OPTIMISM,
  TOKEN_LIST_USDT_SEPOLIA,
  TOKEN_LIST_USDT_SWELL_L2,
  TOKEN_LIST_USDT_SWELL_L2_TESTNET,
  TOKEN_LIST_WEETH,
  TOKEN_LIST_WEETH_ARBITRUM,
  TOKEN_LIST_WEETH_OPTIMISM,
  TOKEN_LIST_WEETH_SEPOLIA,
  TOKEN_LIST_WEETH_SWELL_L2,
  TOKEN_LIST_WEETH_SWELL_L2_TESTNET,
  TOKEN_LIST_WSTETH,
  TOKEN_LIST_WSTETH_ARBITRUM,
  TOKEN_LIST_WSTETH_OPTIMISM,
  TOKEN_LIST_WSTETH_SEPOLIA,
  TOKEN_LIST_WSTETH_SWELL_L2,
  TOKEN_LIST_WSTETH_SWELL_L2_TESTNET,
} from '@/constants/tokens'
import { Token } from '@/types/tokens'
import useChainDetection from '@/hooks/useChainDetection'
import { useDeploymentSetConfig } from './useDeploymentSetConfig'
import { BridgeProvider } from '@/types/crossChainBridge'
import {
  BRIDGE_PROVIDER_BUNGEE,
  BRIDGE_PROVIDER_LAYER_ZERO,
  BRIDGE_PROVIDER_LIFI,
  BRIDGE_PROVIDER_NATIVE,
} from '@/constants/bridgeProvider'

type CrossChainTokens = {
  [symbol: string]: Token[]
}
function getCrossChainTokens(): CrossChainTokens {
  return {
    [TOKEN_LIST_ETH.symbol]: [
      TOKEN_LIST_ETH,
      TOKEN_LIST_ETH_SEPOLIA,
      TOKEN_LIST_ETH_SWELL_L2,
      TOKEN_LIST_ETH_SWELL_L2_TESTNET,
      TOKEN_LIST_ETH_OPTIMISM,
      TOKEN_LIST_ETH_ARBITRUM,
    ],
    [TOKEN_LIST_SWETH.symbol]: [
      TOKEN_LIST_SWETH,
      TOKEN_LIST_SWETH_SWELL_L2,
      TOKEN_LIST_SWETH_SWELL_L2_TESTNET,
    ],
    [TOKEN_LIST_RSWETH.symbol]: [
      TOKEN_LIST_RSWETH,
      TOKEN_LIST_RSWETH_SWELL_L2,
      TOKEN_LIST_RSWETH_SWELL_L2_TESTNET,
    ],
    [TOKEN_LIST_SWBTC.symbol]: [
      TOKEN_LIST_SWBTC,
      TOKEN_LIST_SWBTC_SWELL_L2,
      TOKEN_LIST_SWBTC_SWELL_L2_TESTNET,
    ],
    [TOKEN_LIST_WEETH.symbol]: [
      TOKEN_LIST_WEETH,
      TOKEN_LIST_WEETH_SEPOLIA,
      TOKEN_LIST_WEETH_SWELL_L2,
      TOKEN_LIST_WEETH_SWELL_L2_TESTNET,
      TOKEN_LIST_WEETH_OPTIMISM,
      TOKEN_LIST_WEETH_ARBITRUM,
    ],
    [TOKEN_LIST_KELP_RSETH.symbol]: [
      TOKEN_LIST_KELP_RSETH,
      TOKEN_LIST_KELP_RSETH_SEPOLIA,
      TOKEN_LIST_KELP_RSETH_SWELL_L2,
      TOKEN_LIST_KELP_RSETH_SWELL_L2_TESTNET,
      TOKEN_LIST_KELP_RSETH_OPTIMISM,
      TOKEN_LIST_KELP_RSETH_ARBITRUM,
    ],
    [TOKEN_LIST_RENZO_EZETH.symbol]: [
      TOKEN_LIST_RENZO_EZETH,
      TOKEN_LIST_RENZO_EZETH_SEPOLIA,
      TOKEN_LIST_RENZO_EZETH_SWELL_L2,
      TOKEN_LIST_RENZO_EZETH_SWELL_L2_TESTNET,
      TOKEN_LIST_RENZO_EZETH_OPTIMISM,
      TOKEN_LIST_RENZO_EZETH_ARBITRUM,
    ],
    [TOKEN_LIST_RENZO_PZETH.symbol]: [
      TOKEN_LIST_RENZO_PZETH,
      TOKEN_LIST_RENZO_PZETH_SWELL_L2,
      TOKEN_LIST_RENZO_PZETH_SWELL_L2_TESTNET,
    ],
    [TOKEN_LIST_ETHENA_USDE.symbol]: [
      TOKEN_LIST_ETHENA_USDE,
      TOKEN_LIST_ETHENA_USDE_SEPOLIA,
      TOKEN_LIST_ETHENA_USDE_SWELL_L2,
      TOKEN_LIST_ETHENA_USDE_SWELL_L2_TESTNET,
      TOKEN_LIST_ETHENA_USDE_OPTIMISM,
      TOKEN_LIST_ETHENA_USDE_ARBITRUM,
    ],
    [TOKEN_LIST_ETHENA_SUSDE.symbol]: [
      TOKEN_LIST_ETHENA_SUSDE,
      TOKEN_LIST_ETHENA_SUSDE_SEPOLIA,
      TOKEN_LIST_ETHENA_SUSDE_SWELL_L2,
      TOKEN_LIST_ETHENA_SUSDE_SWELL_L2_TESTNET,
      TOKEN_LIST_ETHENA_SUSDE_OPTIMISM,
      TOKEN_LIST_ETHENA_SUSDE_ARBITRUM,
    ],
    [TOKEN_LIST_ETHENA_ENA.symbol]: [
      TOKEN_LIST_ETHENA_ENA,
      TOKEN_LIST_ETHENA_ENA_SEPOLIA,
      TOKEN_LIST_ETHENA_ENA_SWELL_L2,
      TOKEN_LIST_ETHENA_ENA_SWELL_L2_TESTNET,
      TOKEN_LIST_ETHENA_ENA_OPTIMISM,
      TOKEN_LIST_ETHENA_ENA_ARBITRUM,
    ],
    [TOKEN_LIST_USDT.symbol]: [
      TOKEN_LIST_USDT,
      TOKEN_LIST_USDT_SEPOLIA,
      TOKEN_LIST_USDT_SWELL_L2,
      TOKEN_LIST_USDT_SWELL_L2_TESTNET,
      TOKEN_LIST_USDT_OPTIMISM,
      TOKEN_LIST_USDT_ARBITRUM,
    ],
    [TOKEN_LIST_USDC.symbol]: [
      TOKEN_LIST_USDC,
      TOKEN_LIST_USDC_SEPOLIA,
      TOKEN_LIST_USDC_SWELL_L2,
      TOKEN_LIST_USDC_SWELL_L2_TESTNET,
      TOKEN_LIST_USDC_OPTIMISM,
      TOKEN_LIST_USDC_ARBITRUM,
    ],
    [TOKEN_LIST_WSTETH.symbol]: [
      TOKEN_LIST_WSTETH,
      TOKEN_LIST_WSTETH_SEPOLIA,
      TOKEN_LIST_WSTETH_SWELL_L2,
      TOKEN_LIST_WSTETH_SWELL_L2_TESTNET,
      TOKEN_LIST_WSTETH_OPTIMISM,
      TOKEN_LIST_WSTETH_ARBITRUM,
    ],
    [TOKEN_LIST_SWELL.symbol]: [
      TOKEN_LIST_SWELL,
      TOKEN_LIST_SWELL_SEPOLIA,
      TOKEN_LIST_SWELL_SWELL_L2,
      TOKEN_LIST_SWELL_SWELL_L2_TESTNET,
      TOKEN_LIST_SWELL_OPTIMISM,
      TOKEN_LIST_SWELL_ARBITRUM,
    ],
    [TOKEN_LIST_RSWELL.symbol]: [
      TOKEN_LIST_RSWELL,
      TOKEN_LIST_RSWELL_SEPOLIA,
      TOKEN_LIST_RSWELL_SWELL_L2,
      TOKEN_LIST_RSWELL_SWELL_L2_TESTNET,
      TOKEN_LIST_RSWELL_OPTIMISM,
      TOKEN_LIST_RSWELL_ARBITRUM,
    ],
    [TOKEN_LIST_SWBTC.symbol]: [
      TOKEN_LIST_SWBTC,
      TOKEN_LIST_SWBTC_SEPOLIA,
      TOKEN_LIST_SWBTC_SWELL_L2,
      TOKEN_LIST_SWBTC_SWELL_L2_TESTNET,
      TOKEN_LIST_SWBTC_OPTIMISM,
      TOKEN_LIST_SWBTC_ARBITRUM,
    ],
    [TOKEN_LIST_LORENZO_STBTC.symbol]: [
      TOKEN_LIST_LORENZO_STBTC,
      TOKEN_LIST_LORENZO_STBTC_SWELL_L2,
      TOKEN_LIST_LORENZO_STBTC_SWELL_L2_TESTNET,
    ],
  }
}
const crossChainTokens = getCrossChainTokens()
function lookupCrossChainToken(
  symbol: string,
  chainId: number
): Token | undefined {
  return crossChainTokens[symbol]?.find((t) => t.chainId === chainId)
}

export type CrossChainBridgeSettings = {
  txUpdateFrequencyMs: number
  bridgeBackendURL: string
  tokenSymbolToSupportedBridgeProviders: Record<string, string[]>
  tokenSymbolToTokens: Record<string, Token[]>
  l1Tokens: Token[]
  l2Tokens: Token[]
  bridgeProviders: BridgeProvider[]
  alchemyApiKey: string
  defaultBridgeProvider: BridgeProvider
}
const bridgeProviders = [
  BRIDGE_PROVIDER_NATIVE,
  BRIDGE_PROVIDER_BUNGEE,
  BRIDGE_PROVIDER_LIFI,
  BRIDGE_PROVIDER_LAYER_ZERO,
]
export function useCrossChainBridgeSettings(): CrossChainBridgeSettings {
  const { v3BackendLrtUrl } = useDeploymentSetConfig()
  // const v3BackendLrtUrl = 'http://localhost:8081'

  const { bridge: bridgeConfig } = useDeploymentSetConfig()

  const { assets } = bridgeConfig
  const tokenSymbolToSupportedBridgeProviders =
    {} as CrossChainBridgeSettings['tokenSymbolToSupportedBridgeProviders']
  for (const asset of assets) {
    tokenSymbolToSupportedBridgeProviders[asset.symbol] = asset.providers
  }

  const { deploymentChainId, l2DeploymentChainId } = useChainDetection()

  const symbolToTokens: Record<string, Token[]> = {}

  for (const asset of assets) {
    symbolToTokens[asset.symbol] = []

    const token = lookupCrossChainToken(asset.symbol, deploymentChainId)
    if (token) {
      symbolToTokens[asset.symbol].push(token)
    }
    const tokenL2 = lookupCrossChainToken(asset.symbol, l2DeploymentChainId)
    if (tokenL2) {
      symbolToTokens[asset.symbol].push(tokenL2)
    }
  }

  for (const symbol of Object.keys(symbolToTokens)) {
    if (symbolToTokens[symbol].length !== 2) {
      console.error(
        `Expected 2 tokens for symbol ${symbol}, got ${symbolToTokens[symbol].length}`
      )
      symbolToTokens[symbol] = []
    }
  }

  const l1Tokens: Token[] = []
  for (const tokens of Object.values(symbolToTokens)) {
    for (const token of tokens) {
      if (token.chainId === deploymentChainId) {
        l1Tokens.push(token)
      }
    }
  }

  const l2Tokens: Token[] = []
  for (const tokens of Object.values(symbolToTokens)) {
    for (const token of tokens) {
      if (token.chainId === l2DeploymentChainId) {
        l2Tokens.push(token)
      }
    }
  }

  return {
    alchemyApiKey: 'k65F1pnFsC2MLjVrBuJK28WqsyOVioJY', // todo cfg
    bridgeProviders,
    l1Tokens,
    l2Tokens,
    txUpdateFrequencyMs: 6000,
    tokenSymbolToTokens: symbolToTokens,
    bridgeBackendURL: v3BackendLrtUrl,
    tokenSymbolToSupportedBridgeProviders,
    defaultBridgeProvider: BRIDGE_PROVIDER_LIFI,
  }
}
