import { SwETHCalls } from '@/state/sweth/hooks'
import { BigNumber } from 'ethers'
import { formatUnits, parseUnits } from 'ethers/lib/utils'
import { useCallback, useEffect, useRef, useState } from 'react'
import {
  SwETHClaimSummary,
  SwETHWithdrawInputs,
  SwETHClaimInputs,
  SwETHDepositInputs,
  StakingPoolActivityInputs,
} from './types'
import {
  getNativeCurrencyToExchangeForSweth,
  getSwethReceivedForNativeCurrency,
} from '@/util/big'
import { PrimaryRates } from '@/state/prices/types'
import { SwETHUser } from '@/state/sweth/types'
import { useWeb3CallOnFulfill } from '@/hooks/useWeb3Events'
import { isWithdrawRequestClaimable } from './util'
import { trimDecimalPlaces } from '@/util/number'

export function useSwETHDepositInputs({
  nativeCurrency,
  swETHToken,
  ethBalance,
  primaryRates,
}: {
  nativeCurrency: { decimals: number }
  swETHToken: { decimals: number }
  ethBalance: BigNumber | undefined
  primaryRates: Pick<PrimaryRates, 'swETHPrimaryRate'> | undefined
}): SwETHDepositInputs {
  const [nativeCurrencyInput, setNativeCurrencyInput] = useState('')
  const [swETHInput, setSwETHInput] = useState('')

  let nativeCurrencyAmount: BigNumber | undefined
  try {
    nativeCurrencyAmount = parseUnits(
      nativeCurrencyInput,
      nativeCurrency.decimals
    )
  } catch {
    nativeCurrencyAmount = undefined
  }

  let swETHAmount: BigNumber | undefined
  try {
    swETHAmount = parseUnits(swETHInput, swETHToken.decimals)
  } catch {
    swETHAmount = undefined
  }

  const onEditNativeCurrency = useCallback(
    (v: string) => {
      if (!v) {
        setNativeCurrencyInput('')
        setSwETHInput('')
        return
      }
      if (!primaryRates) {
        return
      }
      v = trimDecimalPlaces(v, nativeCurrency.decimals)
      setNativeCurrencyInput(v)

      let nativeCurrencyAmount: BigNumber
      try {
        nativeCurrencyAmount = parseUnits(v, nativeCurrency.decimals)
      } catch {
        return
      }

      const swETHAmount = getSwethReceivedForNativeCurrency({
        swethToEthRate: primaryRates.swETHPrimaryRate,
        toSendNativeCurrency: nativeCurrencyAmount,
      })
      const swETHStr = formatUnits(swETHAmount, swETHToken.decimals)
      setSwETHInput(swETHStr)
    },
    [nativeCurrency.decimals, primaryRates, swETHToken.decimals]
  )

  const onEditSwETH = useCallback(
    (v: string) => {
      if (!v) {
        setSwETHInput('')
        setNativeCurrencyInput('')
        return
      }
      if (!primaryRates) {
        return
      }
      v = trimDecimalPlaces(v, swETHToken.decimals)
      setSwETHInput(v)

      let swETHAmount: BigNumber
      try {
        swETHAmount = parseUnits(v, swETHToken.decimals)
      } catch {
        return
      }

      const nativeCurrencyAmount = getNativeCurrencyToExchangeForSweth({
        swethToEthRate: primaryRates.swETHPrimaryRate,
        toReceiveSweth: swETHAmount,
      })

      const nativeCurrencyStr = formatUnits(
        nativeCurrencyAmount,
        nativeCurrency.decimals
      )

      setNativeCurrencyInput(nativeCurrencyStr)
    },
    [nativeCurrency.decimals, primaryRates, swETHToken.decimals]
  )

  let nativeCurrencyMaxInputValue: string | undefined
  if (ethBalance) {
    nativeCurrencyMaxInputValue = formatUnits(
      ethBalance,
      nativeCurrency.decimals
    )
  }

  return {
    nativeCurrencyInput,
    onEditNativeCurrency,
    nativeCurrencyAmount,
    swETHInput,
    onEditSwETH,
    swETHAmount,
    nativeCurrencyMaxInputValue,
  }
}

export function useSwETHWithdrawInputs({
  nativeCurrency,
  swETHToken,
  primaryRates,
  user: swETHUser,
  maxUnstakeAmount,
  minUnstakeAmount,
}: {
  nativeCurrency: { decimals: number }
  swETHToken: { decimals: number }
  primaryRates: Pick<PrimaryRates, 'swETHPrimaryRate'> | undefined
  user: Pick<SwETHUser, 'swETHBalance'> | undefined
  minUnstakeAmount: BigNumber
  maxUnstakeAmount: BigNumber
}): SwETHWithdrawInputs {
  const [swETHInput, setSwETHInput] = useState('')
  const [nativeCurrencyInput, setNativeCurrencyInput] = useState('')
  let swETHAmount: BigNumber | undefined
  try {
    swETHAmount = parseUnits(swETHInput, swETHToken.decimals)
  } catch {
    swETHAmount = undefined
  }

  let nativeCurrencyAmount: BigNumber | undefined
  try {
    nativeCurrencyAmount = parseUnits(
      nativeCurrencyInput,
      nativeCurrency.decimals
    )
  } catch {
    nativeCurrencyAmount = undefined
  }

  const onEditSwETH = useCallback(
    (v: string) => {
      if (!v) {
        setSwETHInput('')
        setNativeCurrencyInput('')
        return
      }
      if (!primaryRates) {
        return
      }
      v = trimDecimalPlaces(v, swETHToken.decimals)
      setSwETHInput(v)

      let swETHAmount: BigNumber
      try {
        swETHAmount = parseUnits(v, swETHToken.decimals)
      } catch {
        return
      }

      const nativeCurrencyAmount = getNativeCurrencyToExchangeForSweth({
        swethToEthRate: primaryRates.swETHPrimaryRate,
        toReceiveSweth: swETHAmount,
      })
      const nativeCurrencyStr = formatUnits(
        nativeCurrencyAmount,
        nativeCurrency.decimals
      )

      setNativeCurrencyInput(nativeCurrencyStr)
    },
    [nativeCurrency.decimals, primaryRates, swETHToken.decimals]
  )

  const onEditNativeCurrency = useCallback(
    (v: string) => {
      if (!v) {
        setNativeCurrencyInput('')
        setSwETHInput('')
        return
      }
      if (!primaryRates) {
        return
      }
      v = trimDecimalPlaces(v, nativeCurrency.decimals)
      setNativeCurrencyInput(v)

      let nativeCurrencyAmount: BigNumber
      try {
        nativeCurrencyAmount = parseUnits(v, nativeCurrency.decimals)
      } catch {
        return
      }

      const swETHAmount = getSwethReceivedForNativeCurrency({
        swethToEthRate: primaryRates.swETHPrimaryRate,
        toSendNativeCurrency: nativeCurrencyAmount,
      })
      const swETHStr = formatUnits(swETHAmount, swETHToken.decimals)

      setSwETHInput(swETHStr)
    },
    [nativeCurrency.decimals, primaryRates, swETHToken.decimals]
  )

  let swETHMaxInputValue: string | undefined
  if (swETHUser) {
    swETHMaxInputValue = formatUnits(
      swETHUser.swETHBalance,
      swETHToken.decimals
    )
  }

  const maxUnstakeAmountStr = formatUnits(
    maxUnstakeAmount,
    swETHToken.decimals
  ).replace(/\.0$/, '')
  const minUnstakeAmountStr = formatUnits(
    minUnstakeAmount,
    swETHToken.decimals
  ).replace(/\.0$/, '')

  return {
    nativeCurrencyAmount,
    nativeCurrencyInput,
    onEditNativeCurrency,
    swETHAmount,
    swETHInput,
    onEditSwETH,
    swETHMaxInputValue,
    maxUnstakeAmountSwETH: maxUnstakeAmountStr,
    minUnstakeAmountSwETH: minUnstakeAmountStr,
  }
}

export function useSwETHClaimInputs({
  claimSummary,
}: {
  claimSummary: Pick<SwETHClaimSummary, 'isLoading'> & {
    requests: Pick<
      SwETHClaimSummary['requests'][number],
      'tokenId' | 'isClaimable'
    >[]
  }
}): SwETHClaimInputs {
  const claimableRequests = claimSummary.requests.filter((r) => r.isClaimable)

  const getDefaultTokenId = useCallback(() => {
    if (!claimSummary.isLoading) {
      return 0
    }

    if (claimableRequests.length > 0) {
      return claimableRequests[0].tokenId
    }

    return 0
  }, [claimSummary.isLoading, claimableRequests])

  const [selectedTokenId, setSelectedTokenId] = useState(getDefaultTokenId)
  useEffect(() => {
    // case: selected token is no longer claimable
    const isCurrentRequestStillClaimable = claimableRequests.some(
      (req) => req.tokenId === selectedTokenId
    )

    if (!isCurrentRequestStillClaimable) {
      const nextClaimableToken = claimableRequests[0]?.tokenId ?? 0
      setSelectedTokenId(nextClaimableToken)
    }
  }, [selectedTokenId, claimableRequests])

  useEffect(() => {
    // case: no token is selected but one appears
    if (selectedTokenId === 0 && claimableRequests.length > 0) {
      setSelectedTokenId(claimableRequests[0].tokenId)
    }
  }, [claimableRequests, selectedTokenId])

  return {
    selectedTokenId,
    setSelectedTokenId,
  }
}

export function useStakingPoolActivityInputs(): StakingPoolActivityInputs {
  const [selectedPage, setSelectedPage] = useState(1)
  return {
    selectedPage,
    setSelectedPage,
  }
}

export function useSwETHPageLiveness({
  calls,
  mutateSwethState,
  mutateSwethStats,
  mutateEthBalance,
  mutateSwethUser,
  mutateWithdrawUser,
  mutatePrices,
  mutatePrimaryRates,
  mutateActivity,
  globalDataRefreshInterval = 40_000,
  userDataRefreshInterval = 120_000,
}: {
  calls: SwETHCalls
  mutateEthBalance: () => void
  mutateSwethState: () => void
  mutateSwethUser: () => void
  mutateSwethStats: () => void
  mutateWithdrawUser: () => void
  mutatePrimaryRates: () => void
  mutatePrices: () => void
  mutateActivity: () => void
  globalDataRefreshInterval?: number
  userDataRefreshInterval?: number
}) {
  const {
    deposit,
    approveSwETHForWithdrawal,
    createWithdrawRequest,
    finalizeWithdrawal,
  } = calls
  const approveSwETHForWithdrawalStatus = approveSwETHForWithdrawal.status
  const createWithdrawRequestStatus = createWithdrawRequest.status
  const depositStatus = deposit.status
  const finalizeWithdrawalStatus = finalizeWithdrawal.status

  useEffect(() => {
    if (depositStatus === 'fulfilled') {
      mutateSwethUser()
      mutateEthBalance()
    }
  }, [depositStatus, mutateEthBalance, mutateSwethUser])
  useEffect(() => {
    if (approveSwETHForWithdrawalStatus === 'fulfilled') {
      mutateSwethUser()
    }
  }, [approveSwETHForWithdrawalStatus, mutateSwethUser])
  useEffect(() => {
    if (createWithdrawRequestStatus === 'fulfilled') {
      mutateSwethUser()
      mutateWithdrawUser()
    }
  }, [createWithdrawRequestStatus, mutateSwethUser, mutateWithdrawUser])
  useEffect(() => {
    if (finalizeWithdrawalStatus === 'fulfilled') {
      mutateWithdrawUser()
      mutateWithdrawUser()
      mutateSwethUser()
      mutateEthBalance()
    }
  }, [
    finalizeWithdrawalStatus,
    mutateEthBalance,
    mutateSwethUser,
    mutateWithdrawUser,
  ])

  useEffect(() => {
    const t = setInterval(() => {
      mutateSwethState()
      mutateSwethStats()
      mutatePrices()
      mutatePrimaryRates()
      mutateActivity()
    }, globalDataRefreshInterval)

    return () => {
      clearInterval(t)
    }
  }, [
    mutateSwethState,
    mutateSwethStats,
    globalDataRefreshInterval,
    mutatePrices,
    mutatePrimaryRates,
    mutateActivity,
  ])

  useEffect(() => {
    const t = setInterval(() => {
      mutateSwethUser()
      mutateWithdrawUser()
      mutateEthBalance()
    }, userDataRefreshInterval)

    return () => {
      clearInterval(t)
    }
  }, [
    mutateSwethUser,
    userDataRefreshInterval,
    mutateWithdrawUser,
    mutateEthBalance,
  ])
}

export function useSwETHResetInputs({
  depositInputs,
  withdrawInputs,
  account,
  calls: { createWithdrawRequest, deposit },
}: {
  depositInputs: SwETHDepositInputs
  withdrawInputs: SwETHWithdrawInputs
  account: string | undefined
  calls: SwETHCalls
}) {
  useEffect(() => {
    depositInputs.onEditNativeCurrency('')
    depositInputs.onEditSwETH('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account])

  const depositOnEdit = depositInputs.onEditNativeCurrency
  const withdrawOnEdit = withdrawInputs.onEditSwETH
  const clearAll = useCallback(() => {
    depositOnEdit('')
    withdrawOnEdit('')
  }, [depositOnEdit, withdrawOnEdit])

  useWeb3CallOnFulfill(createWithdrawRequest, clearAll, 0)
  useWeb3CallOnFulfill(deposit, clearAll, 0)
}
