import { Token } from '@/types/tokens'
import {
  TOKEN_LIST_EARN_BTC,
  TOKEN_LIST_EARN_ETH,
  TOKEN_LIST_RSWELL,
  TOKEN_LIST_RSWETH,
  TOKEN_LIST_SWBTC,
  TOKEN_LIST_SWELL,
  TOKEN_LIST_SWETH,
} from '@/constants/tokens'
import { ChainId } from '@/constants/chains'
import { useDeploymentSetConfig } from './useDeploymentSetConfig'
import {
  EarnPointsCampaign,
  EarnRewardAsset,
  GlobalEarnCampaign,
} from '@/types/earn'
import {
  EARN_CAMPAIGN_L2_LAUNCH,
  EARN_CAMPAIGN_SYMBIOTIC,
  EARN_CAMPAIGN_VOYAGE,
  EARN_CAMPAIGN_WAVEDROP_1,
  EARN_CAMPAIGN_WAVEDROP_2,
  EARN_CAMPAIGN_WAVEDROP_3,
  REWARD_ASSET_AMBIENT,
  REWARD_ASSET_EULER,
  REWARD_ASSET_ION,
  REWARD_ASSET_NEPTUNE,
  REWARD_ASSET_SWELL,
  REWARD_ASSET_TEMPEST,
} from '@/constants/earn'

export type EarnSettings = {
  backendURL: string
  filterTokenOptions: Token[]
  filterChainOptions: number[]
  rewardAssets: EarnRewardAsset[]
  pointsCampaigns: EarnPointsCampaign[]
  preconfiguredCampaigns: GlobalEarnCampaign[]
  latestSwellCampaign: GlobalEarnCampaign
  merklBaseUrl: string
}

export function useEarnSettings(): EarnSettings {
  const { v3BackendLrtUrl } = useDeploymentSetConfig()
  // const v3BackendLrtUrl = 'http://localhost:8081'
  return {
    backendURL: v3BackendLrtUrl,
    filterTokenOptions: [
      TOKEN_LIST_RSWETH,
      TOKEN_LIST_SWBTC,
      TOKEN_LIST_SWETH,
      TOKEN_LIST_SWELL,
      TOKEN_LIST_RSWELL,
      TOKEN_LIST_EARN_ETH,
      TOKEN_LIST_EARN_BTC,
    ],
    filterChainOptions: [ChainId.MAINNET, ChainId.SWELLCHAIN],
    rewardAssets: [
      REWARD_ASSET_SWELL,
      REWARD_ASSET_ION,
      REWARD_ASSET_NEPTUNE,
      REWARD_ASSET_AMBIENT,
      REWARD_ASSET_EULER,
      REWARD_ASSET_TEMPEST,
    ],
    pointsCampaigns: [],
    preconfiguredCampaigns: [
      EARN_CAMPAIGN_VOYAGE,
      EARN_CAMPAIGN_WAVEDROP_1,
      EARN_CAMPAIGN_WAVEDROP_2,
      // EARN_CAMPAIGN_WAVEDROP_3,
      EARN_CAMPAIGN_L2_LAUNCH,
      EARN_CAMPAIGN_SYMBIOTIC,
    ],
    latestSwellCampaign: EARN_CAMPAIGN_WAVEDROP_3,
    merklBaseUrl: 'https://merkl.angle.money',
  }
}
