import { Web3Call } from './useWeb3Call'
import useChainDetection from './useChainDetection'
import { ReactNode, useEffect, useRef, useState } from 'react'
import { useSwellWeb3 } from '@/swell-web3/core'

export function useWeb3Button<
  W extends Web3Call,
  Call extends Web3Call['call']
>(
  callState: Omit<W, 'call'>,
  callFn: Call,
  args: Parameters<Call> | undefined,
  opts: {
    idleLabel: ReactNode
    pendingLabel: ReactNode
    fulfilledLabel: ReactNode
  }
) {
  const { account, connect } = useSwellWeb3()
  const { isWrongChainId } = useChainDetection()

  let onClick: () => void
  let disabled: boolean
  let label: ReactNode

  const noArgs = !Array.isArray(args)

  const lastStatus = useRef(callState.status)
  const [active, setActive] = useState(false)
  useEffect(() => {
    const prevStatus = lastStatus.current
    lastStatus.current = callState.status
    const prevWasNotIdle = prevStatus !== 'idle'
    const isIdle = callState.status === 'idle'
    if (prevWasNotIdle && isIdle) {
      setActive(false)
    }
  }, [callState.status])

  const onClickActive = () => {
    if (noArgs) return
    callFn(...args)
    setActive(true)
  }
  const onClickNoop = () => {}

  if (!account) {
    onClick = () => connect()
    disabled = false
    label = 'Connect wallet'
  } else if (isWrongChainId) {
    onClick = onClickNoop
    disabled = true
    label = opts.idleLabel
  } else if (callState.status === callState.STATUS.IDLE) {
    onClick = onClickActive
    disabled = noArgs
    label = opts.idleLabel
  } else if (active && callState.status === callState.STATUS.PROMPTING) {
    onClick = onClickNoop
    disabled = true
    label = 'Pending...'
  } else if (active && callState.status === callState.STATUS.PENDING) {
    onClick = onClickNoop
    disabled = true
    label = opts.pendingLabel
  } else if (active && callState.status === callState.STATUS.FULFILLED) {
    onClick = onClickNoop
    disabled = true
    label = opts.fulfilledLabel
  } else {
    onClick = onClickNoop
    disabled = true
    label = opts.idleLabel
  }

  return { onClick, disabled, label }
}
