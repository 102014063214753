import { NucleusVault } from '@/types/nucleus'
import {
  TOKEN_LIST_APXETH,
  TOKEN_LIST_EARN_BTC,
  TOKEN_LIST_EARN_ETH,
  TOKEN_LIST_ETH,
  TOKEN_LIST_PXETH,
  TOKEN_LIST_RSWETH,
  TOKEN_LIST_SWETH,
  TOKEN_LIST_WBTC,
  TOKEN_LIST_WETH,
  TOKEN_LIST_WSTETH,
  TOKEN_LIST_SWBTC,
  TOKEN_LIST_CBBTC,
  TOKEN_LIST_FBTC,
  TOKEN_LIST_PUMPBTC,
  TOKEN_LIST_TBTC,
  TOKEN_LIST_SOLV_BTC,
  TOKEN_LIST_LBTC,
  TOKEN_LIST_LORENZO_STBTC,
  TOKEN_LIST_BINANCE_BTCB,
  TOKEN_LIST_RENZO_EZETH,
  TOKEN_LIST_WEETH,
  TOKEN_LIST_PT_WEETH_26DEC2024,
  TOKEN_LIST_PT_EZETH_26DEC2024,
  TOKEN_LIST_PT_RSWETH_27JUN2024,
  TOKEN_LIST_PT_WEETH_27JUN2024,
  TOKEN_LIST_DC_COLLATERAL_SWETH,
  TOKEN_LIST_DC_COLLATERAL_WSTETH,
  TOKEN_LIST_DC_COLLATERAL_CBETH,
  TOKEN_LIST_EIGENPIE_MMETH,
  TOKEN_LIST_EIGENPIE_MSWETH,
  TOKEN_LIST_EIGENPIE_MSTETH,
  TOKEN_LIST_MELLOW_FINANCE_AMPHRETH,
  TOKEN_LIST_AMPHOR_AMPHRETH,
  TOKEN_LIST_LORENZO_STBTC_SWELL_L2,
  TOKEN_LIST_UBTC_SWELLCHAIN,
  TOKEN_LIST_EARN_BTC_SWELLCHAIN,
  TOKEN_LIST_EARN_ETH_SWELLCHAIN,
} from './tokens'
import { StrategyProvider } from '@/types/vaults'
import nucleusLogo from '@/assets/images/nucleus_logo_64x64.png'

export const NUCLEUS_ATOMIC_QUEUE_ADDRESS =
  '0xc7287780bfa0c5d2dd74e3e51e238b1cd9b221ee'

export const NUCLEUS_ATOMIC_QUEUE_ADDRESS_SWELLCHAIN =
  '0xc7287780bfa0c5d2dd74e3e51e238b1cd9b221ee'

export const NUCLEUS_VAULT_EARN_BTC: NucleusVault = {
  name: 'Earn BTC Vault',
  vaultToken: TOKEN_LIST_EARN_BTC,
  vaultTokenL2: TOKEN_LIST_EARN_BTC_SWELLCHAIN,
  baseAsset: TOKEN_LIST_WBTC,
  depositAssets: [
    TOKEN_LIST_WBTC,
    TOKEN_LIST_SWBTC,
    TOKEN_LIST_LORENZO_STBTC_SWELL_L2,
    TOKEN_LIST_UBTC_SWELLCHAIN,
    TOKEN_LIST_CBBTC,
    TOKEN_LIST_FBTC,
    TOKEN_LIST_PUMPBTC,
    TOKEN_LIST_TBTC,
    TOKEN_LIST_LBTC,
    TOKEN_LIST_SOLV_BTC,
    TOKEN_LIST_LORENZO_STBTC,
    TOKEN_LIST_BINANCE_BTCB,
  ],
  withdrawAssets: [
    TOKEN_LIST_WBTC,
    TOKEN_LIST_SWBTC,
    TOKEN_LIST_LORENZO_STBTC_SWELL_L2,
    TOKEN_LIST_UBTC_SWELLCHAIN,
    TOKEN_LIST_CBBTC,
    TOKEN_LIST_FBTC,
    TOKEN_LIST_PUMPBTC,
    TOKEN_LIST_TBTC,
    TOKEN_LIST_LBTC,
    TOKEN_LIST_SOLV_BTC,
    // TOKEN_LIST_LORENZO_STBTC,
    // TOKEN_LIST_BINANCE_BTCB,
  ],
  accountantAddress: '0x6bBf58f1A95D22f497fD2e7f640fAE94481b1A08',
  rolesAuthorityAddress: '0x05FAE28773ab9dAfD5C8997AcEe5099fa0D1f219',
  tellerAddress: '0x1DA9ceFA0F46b2087b2cF7dbF9E62Dd94076Ece1',
  accountantAddressL2: '0x6bBf58f1A95D22f497fD2e7f640fAE94481b1A08',
  rolesAuthorityAddressL2: '',
  tellerAddressL2: '0x651F908702F23D794dC54FA36D77DFB6E35F0924',
  withdrawWhitelist: {
    [TOKEN_LIST_EARN_BTC.chainId]: {},
    [TOKEN_LIST_EARN_BTC_SWELLCHAIN.chainId]: {
      [TOKEN_LIST_LORENZO_STBTC_SWELL_L2.address]: [
        '0x3F14eBD532F0015c673836eBd7a5d4d9DE3A197a',
      ],
      [TOKEN_LIST_UBTC_SWELLCHAIN.address]: [
        '0x83c2e4F0B43d4Dd9fad91eDf2c1Cd54c2D2feBAd',
        '0xD0eC0DCCcbe38A5ABFD166d67e30D0880039Aa29',
        '0x34e270122e0E341DfaD3b5745457b1279Ec9bA77',
      ],
    },
  },
}

export const NUCLEUS_VAULT_EARN_ETH: NucleusVault = {
  name: 'Earn ETH Vault',
  vaultToken: TOKEN_LIST_EARN_ETH,
  vaultTokenL2: TOKEN_LIST_EARN_ETH_SWELLCHAIN,
  baseAsset: TOKEN_LIST_ETH,
  depositAssets: [
    TOKEN_LIST_WETH,
    TOKEN_LIST_SWETH,
    TOKEN_LIST_RSWETH,
    TOKEN_LIST_WSTETH,
    TOKEN_LIST_PXETH,
    TOKEN_LIST_APXETH,
    TOKEN_LIST_RENZO_EZETH,
    TOKEN_LIST_WEETH,
    TOKEN_LIST_PT_WEETH_26DEC2024,
    TOKEN_LIST_PT_EZETH_26DEC2024,
    TOKEN_LIST_PT_RSWETH_27JUN2024,
    TOKEN_LIST_PT_WEETH_27JUN2024,
    TOKEN_LIST_DC_COLLATERAL_SWETH,
    TOKEN_LIST_DC_COLLATERAL_WSTETH,
    TOKEN_LIST_DC_COLLATERAL_CBETH,
    TOKEN_LIST_EIGENPIE_MMETH,
    TOKEN_LIST_EIGENPIE_MSWETH,
    TOKEN_LIST_EIGENPIE_MSTETH,
    TOKEN_LIST_MELLOW_FINANCE_AMPHRETH,
    TOKEN_LIST_AMPHOR_AMPHRETH,
  ],
  withdrawAssets: [
    TOKEN_LIST_WETH,
    TOKEN_LIST_SWETH,
    TOKEN_LIST_RSWETH,
    TOKEN_LIST_WSTETH,
    TOKEN_LIST_PXETH,
    TOKEN_LIST_APXETH,
    TOKEN_LIST_RENZO_EZETH,
    TOKEN_LIST_WEETH,
  ],
  accountantAddress: '0x411c78BC8c36c3c66784514f28c56209e1DF2755',
  rolesAuthorityAddress: '0x1F5dddF627C3796a589c6271b36A570f18d3a016',
  tellerAddress: '0x6D207874DDc8B1C3954a0BB2b21c6Fce2Aa18Dba',
  accountantAddressL2: '0x411c78BC8c36c3c66784514f28c56209e1DF2755',
  rolesAuthorityAddressL2: '',
  tellerAddressL2: '0x6D207874DDc8B1C3954a0BB2b21c6Fce2Aa18Dba',
  withdrawWhitelist: {
    [TOKEN_LIST_EARN_ETH.chainId]: {},
    [TOKEN_LIST_EARN_ETH_SWELLCHAIN.chainId]: {},
  },
}

export const NUCLEUS_STRATEGY_PROVIDER_NUCLEUS_EARN: StrategyProvider = {
  name: 'Nucleus Earn',
  link: 'https://www.nucleusearn.io/',
  logoURI: nucleusLogo,
}
