import { YearnVaultCalls } from '@/state/yearnVault/hooks'
import {
  YearnAllowances,
  YearnAuthResult,
  YearnBalances,
  YearnVaultState,
  YearnVaultStats,
  YearnWithdrawRequestResult,
} from '@/state/yearnVault/types'
import {
  YearnErrors,
  prepareYearnApproveAssetForDeposit,
  prepareYearnApproveVaultTokenForWithdraw,
  prepareYearnCancelWithdraw,
  prepareYearnCompleteWithdraw,
  prepareYearnDeposit,
  prepareYearnRequestWithdraw,
} from './yearnCalls'
import { useEffect, useState } from 'react'
import { Token } from '@/types/tokens'
import { ACTIONS } from '../StakingWidget/constants'
import { useYearnActiveWithdrawal, useYearnMaxLossSettings } from './yearnHooks'
import { parseUnits } from 'ethers/lib/utils'
import { BigNumber } from 'ethers'
import { trimDecimalPlaces } from '@/util/number'
import { useSwellWeb3 } from '@/swell-web3/core'
import {
  YearnApproveAssetForDepositButton,
  YearnApproveVaultTokenForWithdrawButton,
  YearnCancelWithdrawButton,
  YearnCompleteWithdrawButton,
  YearnConnectButton,
  YearnDepositButton,
  YearnRequestWithdrawButton,
} from './YearnButtons'
import { YearnWithdrawalStatus } from './yearnWithdraw'
import { AvailableChip } from '../StakingWidget/AvailableChip'
import { FlexRow } from '@/swell-ui/FlexRow'
import { Typography } from '@/swell-ui/Typography'
import { TokenLogo } from '../TokenLogo'
import styled from 'styled-components'
import { Box } from '@/swell-ui/Box'
import { css } from 'styled-components'
import {
  DepositAssetInput,
  DepositReceiveVaultTokenInput,
  WithdrawReceiveAssetInput,
  WithdrawVaultTokenInput,
} from './YearnInputs'
import { DividerDark } from '@/swell-ui/Divider'
import { YearnDepositExchangeInfo } from './ExchangeInfo/YearnDepositExchangeInfo'
import { YearnWithdrawPending } from './YearnWithdraw/YearnWithdrawPending'
import { YearnWithdrawExpired } from './YearnWithdraw/YearnWithdrawExpired'
import { YearnWithdrawClaimable } from './YearnWithdraw/YearnWithdrawClaimable'
import { YearnRequestWithdrawExchangeInfo } from './ExchangeInfo/YearnRequestWithdrawExchangeInfo'
import { ActionChooser } from '../StakingWidget/ActionChooser'
import {
  YearnApproveAssetForDepositToast,
  YearnApproveVaultTokenForWithdrawToast,
  YearnCancelWithdrawToast,
  YearnCompleteWithdrawToast,
  YearnDepositToast,
  YearnRequestWithdrawToast,
} from './YearnToasts'
import { useMediaQuery } from '@mui/material'
import { YearnAeraVault, YearnWithdrawAssetV1 } from '@/types/yearnAera'
import {
  makeYearnDepositSummary,
  makeYearnWithdrawClaimSummary,
  makeYearnWithdrawPendingSummary,
  makeYearnWithdrawRequestSummary as makeYearnWithdrawRequestSummary,
} from './yearnFormatting'
import { useYearnVaultTransactionFees } from '../YearnAeraPage/hooks'
import { PriceReading } from '@/state/prices/types'

export function YearnVaultWidget({
  allowances,
  auth,
  balances,
  depositAssetUsdRate,
  vaultStats,
  vault,
  vaultState,
  calls,
  withdrawRequest,
  isBtc,
  legacyCalls,
  legacyWithdrawRequest,
  legacyWithdrawAsset,
}: {
  vault: YearnAeraVault
  calls: YearnVaultCalls
  isBtc?: boolean

  // vault data
  vaultStats: YearnVaultStats | undefined
  vaultState: YearnVaultState | undefined
  depositAssetUsdRate: PriceReading | undefined

  // user data
  auth: YearnAuthResult | undefined
  balances: YearnBalances | undefined
  allowances: YearnAllowances | undefined
  withdrawRequest: YearnWithdrawRequestResult | undefined

  legacyWithdrawRequest: YearnWithdrawRequestResult | undefined
  legacyCalls: Pick<YearnVaultCalls, 'cancelWithdraw'> | undefined
  legacyWithdrawAsset: YearnWithdrawAssetV1 | undefined
}) {
  const transactionFees = useYearnVaultTransactionFees()

  const isDesktop = useMediaQuery('(min-width: 1391px)')

  const defaultDepositAsset = vault.depositAsset
  const vaultToken = vault.vaultToken
  const defaultWithdrawAsset = vault.withdrawAssets[0]

  const {
    deposit,
    approveAssetForDeposit,
    requestWithdraw,
    approveVaultTokenForWithdraw,
    cancelWithdraw,
    completeWithdraw,
  } = calls
  const { cancelWithdraw: legacyCancelWithdraw } = legacyCalls || {}
  const hasLegacyV1Support = !!legacyCancelWithdraw && !!legacyWithdrawAsset

  const [touched, setTouched] = useState<boolean>(false)
  const [srcTokenInputValue, setSrcTokenInputValue] = useState('')
  const [destTokenInputValue, setDestTokenInputValue] = useState('')
  const [srcToken, setSrcToken] = useState<Token>(defaultDepositAsset)
  const [destToken, setDestToken] = useState<Token>(vaultToken)
  const [action, setAction] = useState(ACTIONS.VAULT)
  const isDeposit = () => action === ACTIONS.VAULT
  const isWithdraw = () => action === ACTIONS.WITHDRAW

  const { maxLossBasisPoints, overrideMaxLoss } = useYearnMaxLossSettings()
  const activeWithdrawal = useYearnActiveWithdrawal({
    withdrawAsset: vaultState?.withdrawAsset,
    withdrawRequest,
  })

  const switchToDeposit = () => {
    setTouched(false)
    setSrcTokenInputValue('')
    setDestTokenInputValue('')
    setSrcToken(defaultDepositAsset)
    setDestToken(vaultToken)
    setAction(ACTIONS.VAULT)
  }
  const switchToWithdraw = () => {
    setTouched(false)
    setSrcTokenInputValue('')
    setDestTokenInputValue('')
    setSrcToken(vaultToken)
    setDestToken(defaultWithdrawAsset)
    setAction(ACTIONS.WITHDRAW)
  }

  useEffect(() => {
    if (requestWithdraw.status === requestWithdraw.STATUS.FULFILLED) {
      setTouched(false)
      setSrcTokenInputValue('')
      setDestTokenInputValue('')
    }
  }, [requestWithdraw])
  useEffect(() => {
    if (deposit.status === deposit.STATUS.FULFILLED) {
      setTouched(false)
      setSrcTokenInputValue('')
      setDestTokenInputValue('')
    }
  }, [deposit])

  let fromAmount: BigNumber | undefined
  if (srcTokenInputValue) {
    fromAmount = parseUnits(
      trimDecimalPlaces(srcTokenInputValue, srcToken.decimals),
      srcToken.decimals
    )
  }
  let toAmount: BigNumber | undefined
  if (destTokenInputValue) {
    toAmount = parseUnits(
      trimDecimalPlaces(destTokenInputValue, destToken.decimals),
      destToken.decimals
    )
  }

  const tokenBalances = [
    { ...defaultDepositAsset, balance: balances?.depositAsset },
    { ...vaultToken, balance: balances?.vaultToken },
  ]

  const preparedDeposit = prepareYearnDeposit({
    assetBalance: balances?.depositAsset,
    assetAllowance: allowances?.depositAssetForVault,
    assetAmount: fromAmount,
    auth,
    paused: vaultState,
  })
  const preparedApproveAssetForDeposit = prepareYearnApproveAssetForDeposit({
    assetAmount: fromAmount,
    assetBalance: balances?.depositAsset,
  })
  const preparedRequestWithdraw = prepareYearnRequestWithdraw({
    activeWithdrawal,
    maxLossBasisPoints,
    overrideMaxLoss,
    shares: fromAmount,
    vaultTokenAllowanceForWithdraw: allowances?.vaultTokenForWithdrawals,
    auth,
    paused: vaultState,
    vaultTokenBalance: balances?.vaultToken,
    withdrawAsset: vaultState?.withdrawAsset,
  })
  const preparedApproveVaultTokenForWithdraw =
    prepareYearnApproveVaultTokenForWithdraw({
      vaultTokenAmount: fromAmount,
      vaultTokenBalance: balances?.vaultToken,
    })
  const preparedCancelWithdraw = prepareYearnCancelWithdraw({
    activeWithdrawal,
    auth,
    paused: vaultState,
    withdrawAsset: vaultState?.withdrawAsset,
  })
  const preparedCompleteWithdraw = prepareYearnCompleteWithdraw({
    activeWithdrawal,
    auth,
    paused: vaultState,
    withdrawAsset: vaultState?.withdrawAsset,
  })

  const legacyActiveWithdrawal = useYearnActiveWithdrawal({
    withdrawAsset: legacyWithdrawAsset,
    withdrawRequest: legacyWithdrawRequest,
  })

  const legacyPreparedCancelWithdraw = prepareYearnCancelWithdraw({
    activeWithdrawal: legacyActiveWithdrawal,
    auth: {
      canCancelWithdraw: true,
      canCompleteWithdraw: true,
      canRequestWithdraw: true,
      canDeposit: true,
    },
    paused: { depositPaused: false, withdrawPaused: false },
    withdrawAsset: legacyWithdrawAsset,
  })

  const { account } = useSwellWeb3()

  const depositSummary = makeYearnDepositSummary({
    depositAssetAmount: fromAmount,
    depositAssetUsdMarketRate: depositAssetUsdRate?.priceUsd,
    vault,
    apr: vaultStats?.apr,
    primaryRates: vaultState,
  })
  const withdrawRequestSummary = makeYearnWithdrawRequestSummary({
    withdrawAsset: vaultState?.withdrawAsset,
    withdrawAssetAmount: toAmount,
    withdrawAssetUsdMarketRate: depositAssetUsdRate?.priceUsd,
  })
  const withdrawPendingSummary = makeYearnWithdrawPendingSummary({
    primaryRates: vaultState,
    withdrawAsset: vaultState?.withdrawAsset,
    vaultToken,
    withdrawRequest: activeWithdrawal?.request,
    isBtc,
  })
  const withdrawClaimSummary = makeYearnWithdrawClaimSummary({
    primaryRates: vaultState,
    withdrawAsset: vaultState?.withdrawAsset,
    withdrawRequest: withdrawRequest?.request,
    isBtc,
    vaultToken: vault.vaultToken,
  })
  const legacyWithdrawPendingSummary = makeYearnWithdrawPendingSummary({
    withdrawAsset: legacyWithdrawAsset,
    primaryRates: vaultState,
    vaultToken,
    withdrawRequest: legacyActiveWithdrawal?.request,
    isBtc,
  })

  const mustApproveDeposit = () => {
    if (!isDeposit()) return false
    return preparedDeposit.error === YearnErrors.InsufficientAllowance
  }
  const mustApproveWithdraw = () => {
    if (!isWithdraw()) return false
    return preparedRequestWithdraw.error === YearnErrors.InsufficientAllowance
  }

  function depositErrorMessage() {
    if (!touched) return null
    if (mustApproveDeposit()) {
      return preparedApproveAssetForDeposit.error ?? null
    }
    return preparedDeposit.error ?? null
  }

  function withdrawErrorMessage() {
    if (!touched) return null
    if (mustApproveWithdraw()) {
      return preparedApproveVaultTokenForWithdraw.error ?? null
    }
    return preparedRequestWithdraw.error ?? null
  }

  const handleActionClick = (a: string) => {
    setSrcTokenInputValue('')
    setDestTokenInputValue('')
    if (a === ACTIONS.VAULT) {
      switchToDeposit()
    }
    if (a === ACTIONS.WITHDRAW) {
      switchToWithdraw()
    }
  }

  const preventInteraction = () => {
    if (account) {
      if (isDeposit()) {
        if (!balances) return true
      }
      if (isWithdraw()) {
        if (!balances) return true
        if (!activeWithdrawal) return true
      }
    }
    if (!depositAssetUsdRate) return true
    return false
  }

  const renderButton = () => {
    if (!account) {
      return <YearnConnectButton>Connect Wallet</YearnConnectButton>
    }

    if (isDeposit()) {
      if (mustApproveDeposit()) {
        return (
          <YearnApproveAssetForDepositButton
            approveAssetForDeposit={approveAssetForDeposit}
            prepared={preparedApproveAssetForDeposit}
          />
        )
      }
      return (
        <YearnDepositButton
          deposit={deposit}
          prepared={preparedDeposit}
          preventInteraction={preventInteraction()}
        />
      )
    }

    if (isWithdraw()) {
      if (hasLegacyV1Support) {
        if (
          legacyActiveWithdrawal?.status === YearnWithdrawalStatus.Requesting ||
          legacyActiveWithdrawal?.status === YearnWithdrawalStatus.Expired ||
          legacyActiveWithdrawal?.status === YearnWithdrawalStatus.Claimable
        ) {
          return (
            <YearnCancelWithdrawButton
              cancelWithdraw={legacyCancelWithdraw}
              prepared={legacyPreparedCancelWithdraw}
              preventInteraction={preventInteraction()}
            />
          )
        }
      }

      if (activeWithdrawal?.status === YearnWithdrawalStatus.Requesting) {
        if (vault.withdrawType === 'v2') {
          return null // v2 withdrawals can't be cancelled
        }

        return (
          <YearnCancelWithdrawButton
            cancelWithdraw={cancelWithdraw}
            prepared={preparedCancelWithdraw}
            preventInteraction={preventInteraction()}
          />
        )
      }
      if (activeWithdrawal?.status === YearnWithdrawalStatus.Claimable) {
        return (
          <YearnCompleteWithdrawButton
            completeWithdraw={completeWithdraw}
            prepared={preparedCompleteWithdraw}
            preventInteraction={preventInteraction()}
          />
        )
      }

      if (activeWithdrawal?.status === YearnWithdrawalStatus.Expired) {
        return (
          <YearnCancelWithdrawButton
            cancelWithdraw={cancelWithdraw}
            prepared={preparedCancelWithdraw}
            preventInteraction={preventInteraction()}
          />
        )
      }

      if (mustApproveWithdraw()) {
        return (
          <YearnApproveVaultTokenForWithdrawButton
            approveVaultTokenForWithdraw={approveVaultTokenForWithdraw}
            prepared={preparedApproveVaultTokenForWithdraw}
            preventInteraction={preventInteraction()}
          />
        )
      }
      return (
        <YearnRequestWithdrawButton
          requestWithdraw={requestWithdraw}
          prepared={preparedRequestWithdraw}
          preventInteraction={preventInteraction()}
        />
      )
    }

    console.error('button', { action })
    return null
  }

  const renderDepositView = () => {
    const topUsdLabel = depositSummary.depositValueUsd
    const bottomUsdLabel = depositSummary.depositValueUsd

    return (
      <>
        <AvailableChip token={srcToken} tokenBalances={tokenBalances} />
        <div>
          <FlexRow justify="space-between" align="center">
            <Typography
              variant="body"
              size="xlarge"
              fstyle="bold"
              className="widget-xlarge"
            >
              Deposit
            </Typography>
            <SymbolWrapper>
              <StyledTokenLogo size={35} src={defaultDepositAsset.logoURI} />
              <Typography variant="body" size="large" fstyle="bold">
                {defaultDepositAsset.symbol}
              </Typography>
            </SymbolWrapper>
          </FlexRow>
          <EthInputWrapper>
            <DepositAssetInput
              setTouched={setTouched}
              srcTokenInputValue={srcTokenInputValue}
              setSrcTokenInputValue={setSrcTokenInputValue}
              setDestTokenInputValue={setDestTokenInputValue}
              errorMessage={depositErrorMessage()}
              disabled={preventInteraction()}
              pricePerShare={vaultState?.pricePerShare}
              balances={balances}
              depositAsset={defaultDepositAsset}
              vaultToken={vaultToken}
              account={account}
            />
          </EthInputWrapper>
          {!depositErrorMessage() && topUsdLabel && (
            <UsdTypography variant="body" size="xsmall">
              {topUsdLabel}
            </UsdTypography>
          )}
          {!depositErrorMessage() && !topUsdLabel && (
            <HiddenTypography variant="body" size="xsmall">
              $0.00
            </HiddenTypography>
          )}
          <FlexRow justify="space-between" align="center">
            <Typography
              variant="body"
              size="xlarge"
              fstyle="bold"
              className="widget-xlarge"
            >
              Receive
            </Typography>
            <SymbolWrapper>
              <StyledTokenLogo size={35} src={vaultToken.logoURI} />
              <Typography
                variant="headline"
                size="h5"
                fstyle="bold"
                letterSpacing="small"
              >
                {vaultToken.symbol}
              </Typography>
            </SymbolWrapper>
          </FlexRow>
          <EthInputWrapper>
            <DepositReceiveVaultTokenInput
              setTouched={setTouched}
              destTokenInputValue={destTokenInputValue}
              setSrcTokenInputValue={setSrcTokenInputValue}
              setDestTokenInputValue={setDestTokenInputValue}
              disabled={preventInteraction()}
              pricePerShare={vaultState?.pricePerShare}
              depositAsset={defaultDepositAsset}
              vaultToken={vaultToken}
              balances={balances}
              account={account}
            />
          </EthInputWrapper>
          {bottomUsdLabel && (
            <UsdTypography variant="body" size="xsmall">
              {bottomUsdLabel}
            </UsdTypography>
          )}
          {!bottomUsdLabel && (
            <HiddenTypography variant="body" size="xsmall">
              $0.00
            </HiddenTypography>
          )}
        </div>
        <DividerDark />
        <YearnDepositExchangeInfo
          vault={vault}
          summary={depositSummary}
          transactionFees={transactionFees}
        />
      </>
    )
  }

  const renderWithdrawView = () => {
    if (legacyActiveWithdrawal) {
      if (
        legacyActiveWithdrawal?.status === YearnWithdrawalStatus.Requesting ||
        legacyActiveWithdrawal?.status === YearnWithdrawalStatus.Expired ||
        legacyActiveWithdrawal?.status === YearnWithdrawalStatus.Claimable
      ) {
        return (
          <YearnWithdrawPending
            vaultToken={vaultToken}
            withdrawAsset={legacyWithdrawAsset}
            withdrawRequest={legacyActiveWithdrawal.request}
            pricePerShare={vaultState?.pricePerShare}
            pendingSummary={legacyWithdrawPendingSummary}
            transactionFees={transactionFees}
            withdrawAssetToken={legacyWithdrawAsset!}
          />
        )
      }
    }

    if (activeWithdrawal?.status === YearnWithdrawalStatus.Requesting) {
      return (
        <YearnWithdrawPending
          vaultToken={vaultToken}
          withdrawAsset={vaultState?.withdrawAsset}
          withdrawAssetToken={defaultWithdrawAsset}
          withdrawRequest={activeWithdrawal.request}
          pricePerShare={vaultState?.pricePerShare}
          pendingSummary={withdrawPendingSummary}
          transactionFees={transactionFees}
        />
      )
    }
    if (activeWithdrawal?.status === YearnWithdrawalStatus.Expired) {
      return <YearnWithdrawExpired withdrawAsset={vaultState?.withdrawAsset} />
    }
    if (activeWithdrawal?.status === YearnWithdrawalStatus.Claimable) {
      return (
        <YearnWithdrawClaimable
          depositAsset={defaultDepositAsset}
          withdrawRequest={activeWithdrawal.request}
          pricePerShare={vaultState?.pricePerShare}
          vaultToken={vaultToken}
          withdrawAsset={vaultState?.withdrawAsset}
          claimSummary={withdrawClaimSummary}
        />
      )
    }

    const topUsdLabel = withdrawRequestSummary.withdrawValueUsd
    const bottomUsdLabel = withdrawRequestSummary.withdrawValueUsdAfterFee

    const errorMessage = withdrawErrorMessage()
    return (
      <>
        <AvailableChip token={srcToken} tokenBalances={tokenBalances} />
        <div>
          <FlexRow justify="space-between" align="center">
            <Typography
              variant="body"
              size="xlarge"
              fstyle="bold"
              className="widget-xlarge"
            >
              Withdraw
            </Typography>
            <SymbolWrapper>
              <StyledTokenLogo size={35} src={vaultToken.logoURI} />
              <Typography variant="body" size="large" fstyle="bold">
                {vaultToken.symbol}
              </Typography>
            </SymbolWrapper>
          </FlexRow>
          <EthInputWrapper>
            <WithdrawVaultTokenInput
              setTouched={setTouched}
              srcTokenInputValue={srcTokenInputValue}
              setSrcTokenInputValue={setSrcTokenInputValue}
              setDestTokenInputValue={setDestTokenInputValue}
              errorMessage={errorMessage}
              disabled={preventInteraction()}
              balances={balances}
              maxLossBasisPoints={maxLossBasisPoints}
              pricePerShare={vaultState?.pricePerShare}
              vaultToken={vaultToken}
              withdrawAsset={vaultState?.withdrawAsset}
              account={account}
            />
          </EthInputWrapper>
          {!errorMessage && topUsdLabel && (
            <UsdTypography variant="body" size="xsmall">
              {topUsdLabel}
            </UsdTypography>
          )}
          {!errorMessage && !topUsdLabel && (
            <HiddenTypography variant="body" size="xsmall">
              $0.00
            </HiddenTypography>
          )}
          <FlexRow justify="space-between" align="center">
            <Typography
              variant="body"
              size="xlarge"
              fstyle="bold"
              className="widget-xlarge"
            >
              Receive
            </Typography>
            <SymbolWrapper>
              <StyledTokenLogo size={35} src={defaultWithdrawAsset.logoURI} />
              <Typography variant="body" size="large" fstyle="bold">
                {defaultWithdrawAsset.symbol}
              </Typography>
            </SymbolWrapper>
          </FlexRow>
          <EthInputWrapper>
            <WithdrawReceiveAssetInput
              setTouched={setTouched}
              destTokenInputValue={destTokenInputValue}
              setSrcTokenInputValue={setSrcTokenInputValue}
              setDestTokenInputValue={setDestTokenInputValue}
              disabled={preventInteraction()}
              pricePerShare={vaultState?.pricePerShare}
              vaultToken={vaultToken}
              withdrawAsset={vaultState?.withdrawAsset}
              balances={balances}
              account={account}
            />
          </EthInputWrapper>
          {bottomUsdLabel && (
            <UsdTypography variant="body" size="xsmall">
              {bottomUsdLabel}
            </UsdTypography>
          )}
          {!bottomUsdLabel && (
            <HiddenTypography variant="body" size="xsmall">
              $0.00
            </HiddenTypography>
          )}
        </div>
        <DividerDark />
        <YearnRequestWithdrawExchangeInfo
          transactionFees={transactionFees}
          withdrawRequestSummary={withdrawRequestSummary}
        />
      </>
    )
  }

  const allCalls = [
    deposit,
    approveAssetForDeposit,
    requestWithdraw,
    approveVaultTokenForWithdraw,
    cancelWithdraw,
    completeWithdraw,
  ]
  if (legacyCancelWithdraw) {
    allCalls.push(legacyCancelWithdraw)
  }

  const anyTransactionInProgress = allCalls.some(
    (x) => x.status === x.STATUS.PENDING || x.status === x.STATUS.PROMPTING
  )

  return (
    <StakingWidgetBox isTall={isDesktop}>
      <ActionChooser
        actions={[ACTIONS.VAULT, ACTIONS.WITHDRAW]}
        onActionClick={handleActionClick}
        defaultAction={ACTIONS.VAULT}
      />
      {isDeposit() && renderDepositView()}
      {isWithdraw() && renderWithdrawView()}
      <ButtonWrapper>{renderButton()}</ButtonWrapper>

      <YearnApproveAssetForDepositToast
        approveAssetForDeposit={approveAssetForDeposit}
        anyTransactionInProgress={anyTransactionInProgress}
        depositAsset={defaultDepositAsset}
      />
      <YearnApproveVaultTokenForWithdrawToast
        approveVaultTokenForWithdraw={approveVaultTokenForWithdraw}
        anyTransactionInProgress={anyTransactionInProgress}
        vaultToken={vaultToken}
      />
      <YearnDepositToast
        deposit={deposit}
        anyTransactionInProgress={anyTransactionInProgress}
        depositAsset={defaultDepositAsset}
        pricePerShare={vaultState?.pricePerShare}
        vaultToken={vaultToken}
      />
      <YearnRequestWithdrawToast
        requestWithdraw={requestWithdraw}
        anyTransactionInProgress={anyTransactionInProgress}
        vaultToken={vaultToken}
        pricePerShare={vaultState?.pricePerShare}
        withdrawAsset={vaultState?.withdrawAsset}
      />
      <YearnCancelWithdrawToast
        cancelWithdraw={cancelWithdraw}
        anyTransactionInProgress={anyTransactionInProgress}
        withdrawAsset={defaultWithdrawAsset}
      />
      {legacyCancelWithdraw && legacyWithdrawAsset && (
        <YearnCancelWithdrawToast
          cancelWithdraw={legacyCancelWithdraw}
          anyTransactionInProgress={anyTransactionInProgress}
          withdrawAsset={legacyWithdrawAsset}
        />
      )}
      <YearnCompleteWithdrawToast
        completeWithdraw={completeWithdraw}
        anyTransactionInProgress={anyTransactionInProgress}
        withdrawAsset={defaultWithdrawAsset}
      />
    </StakingWidgetBox>
  )
}

const SymbolWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${({ theme }) => theme.colors.white['50']};
`
const EthInputWrapper = styled.div`
  position: relative;
  display: flex;
  gap: 8px;
  justify-content: space-between;
  align-items: center;

  .MuiFormControl-root {
    width: 100%;
  }

  input,
  div > div > p {
    font-size: ${({ theme }) => theme.typography.body.large.fontSize};
    font-weight: 600;
  }
`
const StyledTokenLogo = styled.img<{ size: number }>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border-radius: 50%;
`
const UsdTypography = styled(Typography)`
  height: 19.2px;
  color: ${({ theme }) => theme.fiatColor};
  /* opacity: 0.6; */
  margin-bottom: 16px;
  margin-top: 2px;
  letter-spacing: -0.03em;
`
const HiddenTypography = styled(UsdTypography)`
  visibility: hidden;
`

const ButtonWrapper = styled(FlexRow)`
  margin-top: auto;
`

const StakingWidgetBox = styled(Box)<{ isTall: boolean }>`
  min-height: ${({ isTall }) => (isTall ? '698px' : '')};
  display: flex;
  flex-flow: column nowrap;
  ${({ theme }) => css`
    position: relative;
    width: 100%;
    max-width: 460px;
    margin: 0 auto;
    padding: 24px 24px 12px;

    > div {
      margin-bottom: 12px;
    }

    ${theme.breakpoints.up('sm')} {
      width: 460px;
      padding: 24px 32px 12px;


      > div {
        margin-bottom: 24px;

        &:last-child {
          margin-bottom: 12px;
        }
      }
    }}
  `}

  .Mui-disabled.MuiInputBase-root:before {
    border-bottom-style: solid;
    opacity: 0.6;
  }

  border: 1px solid transparent;

  .widget-xlarge {
    color: var(--Swell-White-50, #fff);

    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 44.8px */
  }
`
