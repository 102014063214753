import React from 'react'
import styled from 'styled-components'
import { Chip } from '@swell-ui/Chip'
import { AsyncDiv } from '@/swell-ui/AsyncDiv'
import { FlexRow } from '@/swell-ui/FlexRow'

const AvailableChipContainer = styled.div<any>`
  height: 32px;
  ${({ disabled }) => disabled && 'opacity: 0.3;'}

  [aria-busy='true'] {
    min-width: 32px;
  }
  [aria-busy='false'] {
    min-width: unset;
  }
`

const StyledChip = styled(Chip)`
  border-color: ${({ theme }) => theme.chip.color};
`

export function AvailableChipV2({
  available = '-',
  symbol,
}: {
  available: string
  symbol?: string
}) {
  return (
    <AvailableChipContainer disabled={available.startsWith('-')}>
      <StyledChip
        variant="outlined"
        label={
          <FlexRow gap="6">
            <AsyncDiv loading={!available}>
              {() => (symbol ? `${available} ${symbol}` : available)}
            </AsyncDiv>{' '}
            Available
          </FlexRow>
        }
      />
    </AvailableChipContainer>
  )
}
