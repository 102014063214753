export const EarnPositionsOrderBy = {
  unspecified: 'unspecified' as const,
  protocol: 'protocol' as const,
  asset: 'asset' as const,
  category: 'category' as const,
  tvl: 'tvl' as const,
  apr: 'apr' as const,
  multiplier: 'multiplier' as const,
  rewards: 'rewards' as const,
  balance: 'balance' as const,
}
export type EarnPositionsOrderBy =
  (typeof EarnPositionsOrderBy)[keyof typeof EarnPositionsOrderBy]

export type EarnPositionFilters = {
  searchTerm?: string
  chainIds?: number[]
  includeTokenSymbols?: string[] // if not included, fetches all tokens
  hideZeroBalances?: boolean
}

export const EarnCampaignsOrderBy = {
  unspecified: 'unspecified' as const,
  campaignName: 'campaignName' as const,
  points: 'points' as const,
  claimable: 'claimable' as const,
}
export type EarnCampaignsOrderBy =
  (typeof EarnCampaignsOrderBy)[keyof typeof EarnCampaignsOrderBy]

// eslint-disable-next-line @typescript-eslint/ban-types
export type EarnCampaignsFilters = {}
