import React from 'react'
import styled from 'styled-components'
import { useSwellWeb3 } from '@swell-web3/core'
import { CreditCardIcon } from '@swell-ui/icons/CreditCardIcon'
import { Tooltip } from '@swell-ui/Tooltip'
import { TransakOnRampWidget } from '@/components/TransakOnRampWidget'

const StyledCreditCardIcon = styled(CreditCardIcon)`
  path {
    fill: ${({ theme }) => theme.mainColor};
  }

  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }

  &:active {
    opacity: 0.5;
  }
`

const DisabledCreditCardIcon = styled(CreditCardIcon)`
  position: relative;
  top: 3px;
  opacity: 0.3;
`

export function Transak() {
  const { account } = useSwellWeb3()
  if (!account) return <DisabledCreditCardIcon />
  return (
    <TransakOnRampWidget
      label={
        <Tooltip title={'Buy ETH with fiat'} placement="top" arrow>
          <StyledCreditCardIcon />
        </Tooltip>
      }
      account={account}
    />
  )
}
