import { useSwellWeb3 } from '@/swell-web3/core'
import { usePredepositApi } from './context'
import useSWRImmutable from 'swr/immutable'
import { useWeb3Call } from '@/hooks/useWeb3Call'
import { getPreDepositWithdrawGasEstimate } from '@/constants/gasEstimates'

export function usePredepositEcosystemPoints() {
  const { account } = useSwellWeb3()
  const api = usePredepositApi()
  return useSWRImmutable(
    account ? ['predeposit-ecosystem-points', account] : null,
    () => api.read.ecosystemPoints()
  )
}

export function usePredepositTokens() {
  const api = usePredepositApi()
  const { tokenList, tokenSupportedMap } = api
  return { tokenList, tokenSupportedMap }
}
export type PredepositTokens = ReturnType<typeof usePredepositTokens>

export function usePredepositUser() {
  const { account } = useSwellWeb3()
  const api = usePredepositApi()
  return useSWRImmutable(
    account ? ['predeposit-user', account] : null,
    api.read.user,
    { refreshInterval: 12_000 }
  )
}

export function usePredepositStats() {
  const api = usePredepositApi()
  return useSWRImmutable('predeposit-stats', api.read.stats)
}

export function useWithdrawFromPredeposit() {
  const api = usePredepositApi()
  return useWeb3Call({
    estimateGas: api.write.withdrawEstimateGas,
    fn: api.write.withdraw,
    staticGasEstimate: () => getPreDepositWithdrawGasEstimate(),
    validate: async ({ amount, assetAddress }) => {
      if (!amount) return 'Amount is required'
      if (!assetAddress) return 'Asset is required'
      return null
    },
  })
}
export type WithdrawFromPredeposit = ReturnType<
  typeof useWithdrawFromPredeposit
>
export type PredepositCalls = {
  withdrawFromPredeposit: WithdrawFromPredeposit
}
export function usePredepositCalls(): PredepositCalls {
  return {
    withdrawFromPredeposit: useWithdrawFromPredeposit(),
  }
}
