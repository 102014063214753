import { L2EcosystemContext } from './context'
import bubblyFinanceLogo from '@/assets/images/bubbly_finance_80x80.png'
import ambientLogo from '@/assets/images/predeposit/ambient.png'
import { ChainId } from '@/constants/chains'
import { L2EcosystemProject } from './types'
import ionLogo from '@/assets/images/predeposit/ion.png'
import eulerLogo from '@/assets/images/euler_160x160.png'
import neptuneLogo from '@/assets/images/neptune_finance_160x160.png'
import metronomeLogo from '@/assets/images/metronome_logo_120x120.png'
import axisLogo from '@/assets/images/axis_logo_120x120.png'
import tempestLogo from '@/assets/images/tempest_logo_120x120.png'
import merklLogo from '@/assets/images/merkl_logo_120x120.png'
import venusLogo from '@/assets/images/venus_160x160.png'
import { useV3BackendClient } from '@/services/V3BackendService/hooks'
import { useDeploymentSetConfig } from '../deployments/hooks'
import { BigNumber } from 'ethers'
import dittoLogo from '@/assets/images/ditto-logo.png'

export function useL2EcosystemApiImpl(): L2EcosystemContext {
  const { v3BackendLrtUrl } = useDeploymentSetConfig()
  const stats = useV3BackendClient(v3BackendLrtUrl).v3BackendClient.stats

  return {
    stats: async () => {
      const { tvlCents } = await stats.l2Stats({})
      return {
        tvlUsd: BigNumber.from(tvlCents).div(100).toNumber(),
      }
    },
    projects: async () => {
      const projects: L2EcosystemProject[] = [
        {
          name: 'Merkl (Angle)',
          description: 'The #1 incentive distribution platform',
          logoURI: merklLogo,
          tags: ['Yield'],
          twitterUrl: 'https://twitter.com/merkl_xyz',
          url: 'https://app.merkl.xyz/',
          discordUrl: 'https://discord.com/invite/Gs8MUrUVP3',
          docsUrl: 'https://docs.merkl.xyz/',
          deployedChainIds: [
            ChainId.SWELLCHAIN,
            ChainId.OPTIMISM,
            ChainId.BASE,
            ChainId.MANTLE,
            ChainId.BLAST,
            ChainId.MODE,
            ChainId.FRAXTAL,
            ChainId.LISK,
          ],
          parentChain: 'Superchain',
        } as L2EcosystemProject,
        {
          name: 'Axis (prev. Bond)',
          description: "DeFi's first modular auction protocol.",
          logoURI: axisLogo,
          tags: ['DeFi'],
          twitterUrl: 'https://twitter.com/axis_fi',
          url: 'https://app.axis.finance/',
          discordUrl: 'https://discord.gg/tx9CrvPZWF',
          docsUrl: 'https://axis.finance/docs/overview',
          deployedChainIds: [
            ChainId.SWELLCHAIN,
            ChainId.BASE,
            ChainId.MODE,
            ChainId.MANTLE,
            ChainId.BLAST,
          ],
          parentChain: 'Superchain',
        } as L2EcosystemProject,
        {
          name: 'Venus',
          description:
            'Earn, Borrow & Lend on the #1 Multichain Decentralized Money Market.',
          logoURI: venusLogo,
          tags: ['Lending'],
          twitterUrl: 'https://twitter.com/VenusProtocol',
          url: 'https://app.venus.io/',
          discordUrl: 'https://discord.gg/venus-protocol-912811548651708448',
          docsUrl: 'https://docs-v4.venus.io/',
          deployedChainIds: [ChainId.SWELLCHAIN, ChainId.OPTIMISM],
          parentChain: 'Superchain',
        } as L2EcosystemProject,
        {
          name: 'Tempest',
          description:
            'A Quantitative Research Firm building Decentralized Liquidity Management on-top of @ambient_finance',
          logoURI: tempestLogo,
          tags: ['Liquidity manager'],
          twitterUrl: 'https://x.com/tempest_fi',
          url: 'https://app.tempestfinance.xyz/',
          discordUrl: 'https://discord.com/invite/fZGQUQgFfP',
          docsUrl: 'https://tempestfinance.gitbook.io/',
          deployedChainIds: [ChainId.SWELLCHAIN],
          parentChain: 'Superchain',
        } as L2EcosystemProject,
        {
          name: 'Ditto',
          description:
            "Ditto seamlessly extends Ethereum's capabilities by providing a secure keeper network for running intents based on predefined logic",
          logoURI: dittoLogo,
          tags: ['AVS'],
          twitterUrl: 'https://twitter.com/Ditto_Network',
          url: 'https://dittonetwork.io/',
          discordUrl: 'https://discord.gg/BTaZrZ9w5q',
          docsUrl: 'https://docs.dittonetwork.io/',
          deployedChainIds: [ChainId.SWELLCHAIN],
          parentChain: 'Superchain',
        } as L2EcosystemProject,
        {
          name: 'Bubbly Finance',
          description:
            'Bubbly Finance is an AMM-based DEX, aiming to become the Uniswap for pre-market assets',
          logoURI: bubblyFinanceLogo,
          tags: ['DEX'],
          twitterUrl: 'https://x.com/BubblyFinance',
          url: 'https://app.bubbly.finance/',
          discordUrl: 'https://discord.com/invite/BubblyFinance',
          docsUrl: 'https://docs.bubbly.finance/',
          deployedChainIds: [ChainId.SWELLCHAIN, ChainId.MANTLE],
          parentChain: 'Superchain',
        } as L2EcosystemProject,
        {
          name: 'Metronome',
          description:
            'A complete synthetic protocol that enables users to generate a variety of synthetics using crypto assets as collateral.',
          logoURI: metronomeLogo,
          tags: ['Synthetics'],
          twitterUrl: 'https://twitter.com/MetronomeDAO',
          url: 'https://app.metronome.io/eth',
          discordUrl: 'https://discord.gg/metronome',
          docsUrl: 'https://docs.metronome.io/metronome-2.0/master',
          deployedChainIds: [
            ChainId.SWELLCHAIN,
            ChainId.OPTIMISM,
            ChainId.BASE,
          ],
          parentChain: 'Superchain',
        } as L2EcosystemProject,
        {
          name: 'ION Protocol',
          description:
            'The lending protocol for staked and restaked assets. Earn more and stay liquid with zero price based liquidations.',
          logoURI: ionLogo,
          tags: ['Lending'],
          twitterUrl: 'https://twitter.com/ionprotocol',
          url: 'https://www.app.ionprotocol.io/',
          discordUrl: 'https://discord.com/invite/CjQqUgPA6Y',
          docsUrl: 'https://docs.ionprotocol.io/',
          deployedChainIds: [ChainId.SWELLCHAIN, ChainId.BASE],
          parentChain: 'Superchain',
        } as L2EcosystemProject,
        {
          name: 'Euler',
          description: 'Lend, borrow and build without limits.',
          logoURI: eulerLogo,
          tags: ['Lending'],
          twitterUrl: 'https://x.com/eulerfinance',
          url: 'https://app.euler.finance',
          discordUrl: 'https://discord.com/invite/pTTnr7b4mT',
          docsUrl: 'https://docs.euler.finance/',
          deployedChainIds: [ChainId.SWELLCHAIN],
          parentChain: 'Superchain',
          isFeatured: true,
        } as L2EcosystemProject,
        {
          name: 'Neptune',
          description: 'The first all-in-one DEX built on Swell Chain',
          logoURI: neptuneLogo,
          tags: ['DEX'],
          twitterUrl: 'https://x.com/NepFinance',
          url: 'https://nep.finance/',
          discordUrl: 'http://discord.gg/kBaTsdtmsZ',
          docsUrl: 'https://docs.nep.finance/neptune-docs',
          deployedChainIds: [ChainId.SWELLCHAIN],
          parentChain: 'Superchain',
        } as L2EcosystemProject,
        {
          name: 'Ambient',
          description: 'Zero-to-One Decentralized Trading Protocol.',
          logoURI: ambientLogo,
          tags: ['DEX'],
          twitterUrl: 'https://x.com/ambient_finance',
          url: 'https://ambient.finance/',
          discordUrl: 'https://discord.com/invite/ambient-finance',
          docsUrl: 'https://docs.ambient.finance/',
          deployedChainIds: [ChainId.SWELLCHAIN, ChainId.BLAST],
          parentChain: 'Superchain',
          isFeatured: true,
        } as L2EcosystemProject,
      ]

      return projects
    },
  }
}
