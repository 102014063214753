import { useDeploymentSetConfig } from '@/state/deployments/hooks'
import { useMemo } from 'react'
import { defaultTokenList } from '../defaultTokenList'

function useDefaultPreDepositTokenList() {
  const { addresses } = useDeploymentSetConfig()
  return useMemo(() => {
    const tl = defaultTokenList(addresses)
    // check validity and warn
    const addrsSet = new Set<string>()
    for (const token of tl.tokens) {
      if (addrsSet.has(token.address)) {
        console.error(
          'duplicate token address in default token list',
          token.address
        )
      }
      addrsSet.add(token.address)
    }
    return tl
  }, [addresses])
}
export function usePreDepositTokenList() {
  const data = useDefaultPreDepositTokenList()
  return { data, isFetching: false }
}
