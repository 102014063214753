import { Button } from '@/swell-ui/Button'
import styled from 'styled-components'

function makeCalendarLink({
  title,
  description,
  startDate,
}: {
  title: string
  description: string
  startDate: string
}) {
  return `https://calendar.google.com/calendar/u/0/r/eventedit?text=${encodeURIComponent(
    title
  )}&details=${encodeURIComponent(description)}&dates=${startDate}/${startDate}`
}
const SButton = styled(Button)`` as any

export function RemindMe({
  dateMs,
  title,
  description,
}: {
  dateMs: number
  title: string
  description: string
}) {
  function formatDate(date: Date) {
    const year = date.getFullYear()
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const day = date.getDate().toString().padStart(2, '0')
    return `${year}${month}${day}`
  }

  const link = makeCalendarLink({
    title,
    description,
    startDate: formatDate(new Date(dateMs)),
  })

  return (
    <SButton
      variant="tertiary"
      as={'a'}
      href={link}
      target="_blank"
      rel="noopener noreferrer"
    >
      <CalendarIcon />
    </SButton>
  )
}

export function CalendarIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <g clip-path="url(#clip0_12520_33469)">
        <path
          d="M16.25 3.125H3.75C3.40482 3.125 3.125 3.40482 3.125 3.75V16.25C3.125 16.5952 3.40482 16.875 3.75 16.875H16.25C16.5952 16.875 16.875 16.5952 16.875 16.25V3.75C16.875 3.40482 16.5952 3.125 16.25 3.125Z"
          stroke="white"
          stroke-width="1.25"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M13.75 1.875V4.375"
          stroke="white"
          stroke-width="1.25"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M6.25 1.875V4.375"
          stroke="white"
          stroke-width="1.25"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M3.125 6.875H16.875"
          stroke="white"
          stroke-width="1.25"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_12520_33469">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
