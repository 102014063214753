import { FlexRow } from '@/swell-ui/FlexRow'
import { WSWELLLockSummary } from '../../types'
import { RemindMe } from '@/components/RemindMe'
import { Tooltip } from '@/swell-ui/Tooltip'
import styled from 'styled-components'

export function TimeTilMaturityCell({ lock }: { lock: WSWELLLockSummary }) {
  const { unlockDateMs, unlockDateFormatted, percentUnlocked } = lock

  const description = `Go to https://app.swellnetwork.io/earn/wswell to unlock your wSWELL`

  let checkIcon = (
    <Tooltip title={`${percentUnlocked} unlocked`}>
      <CheckPending />
    </Tooltip>
  )
  if (percentUnlocked === '100%') {
    checkIcon = <CheckCompleted />
  }

  return (
    <TTMLayout align="center" gap="6">
      <Tooltip title={'Remind me'}>
        <RemindMe
          dateMs={unlockDateMs}
          title="wSWELL fully unlocked"
          description={description}
        />
      </Tooltip>
      <span>{unlockDateFormatted}</span>
      {checkIcon}
    </TTMLayout>
  )
}
const TTMLayout = styled(FlexRow)`
  white-space: nowrap;
  position: relative;
  > :first-child,
  > :last-child:not(.green) {
    position: relative;
    top: 6px;
  }

  :last-child.green {
    top: 1px;
  }
`

function CheckCompleted() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      className="green"
    >
      <g clip-path="url(#clip0_12520_33512)">
        <path
          d="M11 2.0625C9.23233 2.0625 7.50436 2.58668 6.0346 3.56874C4.56483 4.55081 3.41929 5.94665 2.74283 7.57977C2.06637 9.21288 1.88938 11.0099 2.23424 12.7436C2.57909 14.4773 3.43031 16.0698 4.68024 17.3198C5.93017 18.5697 7.52268 19.4209 9.25638 19.7658C10.9901 20.1106 12.7871 19.9336 14.4202 19.2572C16.0534 18.5807 17.4492 17.4352 18.4313 15.9654C19.4133 14.4956 19.9375 12.7677 19.9375 11C19.935 8.6304 18.9926 6.35856 17.317 4.683C15.6414 3.00743 13.3696 2.065 11 2.0625ZM14.9239 9.42391L10.1114 14.2364C10.0476 14.3003 9.97174 14.351 9.88828 14.3856C9.80481 14.4202 9.71535 14.438 9.625 14.438C9.53466 14.438 9.44519 14.4202 9.36173 14.3856C9.27827 14.351 9.20245 14.3003 9.1386 14.2364L7.0761 12.1739C6.94709 12.0449 6.87462 11.8699 6.87462 11.6875C6.87462 11.5051 6.94709 11.3301 7.0761 11.2011C7.2051 11.0721 7.38007 10.9996 7.5625 10.9996C7.74494 10.9996 7.91991 11.0721 8.04891 11.2011L9.625 12.778L13.9511 8.45109C14.015 8.38722 14.0908 8.33655 14.1743 8.30198C14.2577 8.26741 14.3472 8.24962 14.4375 8.24962C14.5278 8.24962 14.6173 8.26741 14.7007 8.30198C14.7842 8.33655 14.86 8.38722 14.9239 8.45109C14.9878 8.51497 15.0385 8.5908 15.073 8.67426C15.1076 8.75772 15.1254 8.84717 15.1254 8.9375C15.1254 9.02783 15.1076 9.11728 15.073 9.20074C15.0385 9.2842 14.9878 9.36003 14.9239 9.42391Z"
          fill="#009E49"
        />
      </g>
      <defs>
        <clipPath id="clip0_12520_33512">
          <rect width="22" height="22" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

function CheckPending() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <g clip-path="url(#clip0_12520_33518)">
        <path
          d="M11 2.0625C9.23233 2.0625 7.50436 2.58668 6.0346 3.56874C4.56483 4.55081 3.41929 5.94665 2.74283 7.57977C2.06637 9.21288 1.88938 11.0099 2.23424 12.7436C2.57909 14.4773 3.43031 16.0698 4.68024 17.3198C5.93017 18.5697 7.52268 19.4209 9.25638 19.7658C10.9901 20.1106 12.7871 19.9336 14.4202 19.2572C16.0534 18.5807 17.4492 17.4352 18.4313 15.9654C19.4133 14.4956 19.9375 12.7677 19.9375 11C19.935 8.6304 18.9926 6.35856 17.317 4.683C15.6414 3.00743 13.3696 2.065 11 2.0625ZM14.9239 9.42391L10.1114 14.2364C10.0476 14.3003 9.97174 14.351 9.88828 14.3856C9.80481 14.4202 9.71535 14.438 9.625 14.438C9.53466 14.438 9.44519 14.4202 9.36173 14.3856C9.27827 14.351 9.20245 14.3003 9.1386 14.2364L7.0761 12.1739C6.94709 12.0449 6.87462 11.8699 6.87462 11.6875C6.87462 11.5051 6.94709 11.3301 7.0761 11.2011C7.2051 11.0721 7.38007 10.9996 7.5625 10.9996C7.74494 10.9996 7.91991 11.0721 8.04891 11.2011L9.625 12.778L13.9511 8.45109C14.015 8.38722 14.0908 8.33655 14.1743 8.30198C14.2577 8.26741 14.3472 8.24962 14.4375 8.24962C14.5278 8.24962 14.6173 8.26741 14.7007 8.30198C14.7842 8.33655 14.86 8.38722 14.9239 8.45109C14.9878 8.51497 15.0385 8.5908 15.073 8.67426C15.1076 8.75772 15.1254 8.84717 15.1254 8.9375C15.1254 9.02783 15.1076 9.11728 15.073 9.20074C15.0385 9.2842 14.9878 9.36003 14.9239 9.42391Z"
          fill="#FFA608"
        />
      </g>
      <defs>
        <clipPath id="clip0_12520_33518">
          <rect width="22" height="22" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export function CurrentUnlockAmountCell({
  lock,
  evkToken,
}: {
  lock: WSWELLLockSummary
  evkToken: {
    logoURI: string
  }
}) {
  const { currentUnlockAmount, currentForfeitAmount } = lock

  const showForfeit = false

  return (
    <FlexRow direction="column">
      <FlexRow gap="6">
        <img src={evkToken.logoURI} width="22" height="22" />
        <span>{currentUnlockAmount} SWELL</span>
      </FlexRow>
      {showForfeit && (
        <FlexRow gap="6" style={{ whiteSpace: 'nowrap', marginTop: '-4px' }}>
          <img
            src={evkToken.logoURI}
            width="22"
            height="22"
            style={{ filter: 'grayscale(1)', opacity: 0.3 }}
          />
          <span style={{ filter: 'grayscale(0.5)', color: 'red' }}>
            {currentForfeitAmount} SWELL forfeit ({lock.percentForfeit})
          </span>
        </FlexRow>
      )}
    </FlexRow>
  )
}

export function AmountToMatureCell({ lock }: { lock: WSWELLLockSummary }) {
  const { amountToMature } = lock
  return <span>{amountToMature} wSWELL</span>
}
