import { useEffect, useRef } from 'react'
import { registerGui } from './RegisterGui'
import { BigNumber, ContractTransaction } from 'ethers'
import { formatUnits, parseUnits } from 'ethers/lib/utils'

export function useDevGuiLabel({
  name,
  showGui,
}: {
  name: string
  showGui?: boolean
}) {
  // todo...
  useDevGuiCallback({
    name,
    callback: () => {},
    showGui,
  })
}

export function useDevGuiCallback({
  callback,
  name,
  showGui,
}: {
  name: string
  callback: () => void
  showGui?: boolean
}) {
  // store callback in ref to avoid re-rendering on change
  const callbackRef = useRef(callback)

  useEffect(() => {
    if (!showGui) return

    return registerGui((gui) => {
      gui.add({ [name]: () => callbackRef.current?.() }, name)?.name(name)
    })
  }, [name, showGui])

  useEffect(() => {
    callbackRef.current = callback
  }, [callback])
}

export function useDevGuiString({
  name,
  value,
  showGui,
  onChange,
}: {
  name: string
  value: string
  showGui?: boolean
  onChange: (value: string) => void
}) {
  const valueRef = useRef(value ?? '')
  const onChangeRef = useRef(onChange)

  useEffect(() => {
    if (!showGui) return

    return registerGui((gui) => {
      gui
        .add({ [name]: valueRef.current }, name)
        ?.name(name)
        .onChange((value: string) => {
          valueRef.current = value
          onChangeRef.current(value)
        })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, showGui])

  useEffect(() => {
    onChangeRef.current = onChange
  }, [onChange])
}

export function useDevGuiTransaction({
  callback,
  name,
  showGui,
}: {
  name: string
  callback: () => Promise<ContractTransaction>
  showGui?: boolean
}) {
  // store callback in ref to avoid re-rendering on change
  const callbackRef = useRef(callback)

  useEffect(() => {
    if (!showGui) return

    return registerGui((gui) => {
      gui
        .add(
          {
            [name]: async () => {
              try {
                await callbackRef.current()
              } catch (e) {
                console.error('Error in transaction', e)
              }
            },
          },
          name
        )
        ?.name(name)
    })
  }, [name, showGui])

  useEffect(() => {
    callbackRef.current = callback
  }, [callback])
}

export function useDevGuiNumber({
  name,
  value,
  showGui,
  onChange,
  range = [-Infinity, Infinity],
}: {
  name: string
  value: number
  showGui?: boolean
  onChange: (value: number) => void
  range?: [number | null, number | null]
}) {
  const valueRef = useRef(value)
  const onChangeRef = useRef(onChange)

  useEffect(() => {
    if (!showGui) return

    return registerGui((gui) => {
      gui
        .add({ [name]: valueRef.current }, name)
        ?.name(name)
        .onChange((value: number) => {
          if (range[0] !== null && value < range[0]) {
            value = range[0]
          }
          if (range[1] !== null && value > range[1]) {
            value = range[1]
          }

          valueRef.current = value
          onChangeRef.current(value)
        })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, showGui])

  useEffect(() => {
    onChangeRef.current = onChange
  }, [onChange])
}

export function useDevGuiBigNumber({
  name,
  value,
  showGui,
  onChange,
  decimals,
  range,
}: {
  name: string
  value: BigNumber
  decimals: number
  showGui?: boolean
  onChange: (value: BigNumber) => void
  range?: [number | null, number | null]
}) {
  return useDevGuiNumber({
    name,
    value: Number(formatUnits(value, decimals)),
    showGui,
    onChange: (value) => onChange(parseUnits(value.toString(), decimals)),
    range,
  })
}

export function useDevGuiOptions({
  name,
  value,
  showGui,
  options,
  onChange,
}: {
  name: string
  value: string
  showGui?: boolean
  options: string[]
  onChange: (value: string) => void
}) {
  const valueRef = useRef(value)
  const onChangeRef = useRef(onChange)

  useEffect(() => {
    if (!showGui) return

    return registerGui((gui) => {
      gui
        .add({ [name]: valueRef.current }, name)
        ?.name(name)
        .options(options)
        .onChange((value: string) => {
          valueRef.current = value
          onChangeRef.current(value)
        })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, showGui])

  useEffect(() => {
    onChangeRef.current = onChange
  }, [onChange])
}

export function useDevGuiCheckbox({
  name,
  value,
  showGui,
  onChange,
}: {
  name: string
  value: boolean
  showGui?: boolean
  onChange: (value: boolean) => void
}) {
  const valueRef = useRef(value)
  const onChangeRef = useRef(onChange)

  useEffect(() => {
    if (!showGui) return

    return registerGui((gui) => {
      gui
        .add({ [name]: valueRef.current }, name)
        ?.name(name)
        .onChange((value: boolean) => {
          valueRef.current = value
          onChangeRef.current(value)
        })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, showGui])

  useEffect(() => {
    onChangeRef.current = onChange
  }, [onChange])
}
