import React from 'react'
import styled, { css } from 'styled-components/macro'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'

const bodyMedium = css`
  /* Body/medium */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 25.6px */
  letter-spacing: -0.48px;
`

export function AccordionV2({
  details,
  summary,
}: {
  summary: React.ReactNode
  details: React.ReactNode
}) {
  return (
    <StyledMuiAccordion>
      <MuiAccordionSummary expandIcon={<ExpandMoreIcon />}>
        {summary}
      </MuiAccordionSummary>
      <MuiAccordionDetails>{details}</MuiAccordionDetails>
    </StyledMuiAccordion>
  )
}

// todo themes
const StyledMuiAccordion = styled(MuiAccordion)`
  width: 100%;
  border: 1px solid;

  background: ${({ theme }) => theme.box.bgColor};
  border-color: ${({ theme }) => theme.box.borderColor};
  padding: 16px;
  color: #fff;

  .MuiAccordionSummary-root {
    padding: 0;
    ${bodyMedium};
  }
  .MuiAccordionDetails-root {
    padding: 0;
  }

  &.Mui-expanded {
    margin: 0;
  }

  border-radius: ${({ theme }) => theme.box.borderRadius};
  &.MuiAccordion-root {
    &:first-of-type {
      border-top-left-radius: ${({ theme }) => theme.box.borderRadius};
      border-top-right-radius: ${({ theme }) => theme.box.borderRadius};
    }
    &:last-of-type {
      border-bottom-left-radius: ${({ theme }) => theme.box.borderRadius};
      border-bottom-right-radius: ${({ theme }) => theme.box.borderRadius};
    }
  }
`

function ExpandMoreIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
    >
      <path
        d="M4 6.5L8 10.5L12 6.5"
        stroke="#6E79E9"
        stroke-width="1.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}
