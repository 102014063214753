import { Web3Toast } from '@/components/StakingConfirmationProgressWidget/Web3Toast'
import { PrimaryRates } from '@/state/prices/types'
import {
  ApproveSwETHForWithdrawal,
  CreateWithdrawRequestSwETH,
  FinalizeWithdrawalSwETH,
  SwETHDeposit,
} from '@/state/sweth/hooks'
import { SwETHWithdrawalsUser } from '@/state/sweth/types'
import {
  getNativeCurrencyToExchangeForSweth,
  getSwethReceivedForNativeCurrency,
} from '@/util/big'
import { displayCryptoLocale } from '@/util/displayCrypto'
import { ReactNode } from 'react'
import { SwETHClaimInputs } from './types'
import { BigNumber } from 'ethers'
import { formatWithConfig } from '@/util/number'
import styled from 'styled-components'

export const TRANSACTION_TOAST_TITLE = {
  COMPLETED: 'Transaction completed!',
  APPROVE_PROMPTING: 'Approve pending',
  APPROVE_PENDING: 'Approve confirming',
  APPROVE_COMPLETED: 'Approve completed',

  STAKE_PROMPTING: 'Stake pending',
  STAKE_PENDING: 'Stake confirming',
  STAKE_COMPLETED: 'Transaction completed!',

  UNSTAKE_PROMPTING: 'Unstake request pending',
  UNSTAKE_PENDING: 'Unstake request confirming',
  UNSTAKE_COMPLETED: 'Unstake request completed!',

  FINALIZE_WITHDRAWAL_PROMPTING: 'Claim pending',
  FINALIZE_WITHDRAWAL_PENDING: 'Claim confirming',
  FINALIZE_WITHDRAWAL_COMPLETED: 'Claim completed',
}

export function SwETHDepositToast({
  swETHDeposit,
  nativeCurrency,
  swETHToken,
  primaryRates,
  anyTransactionInProgress,
}: {
  swETHDeposit: SwETHDeposit
  primaryRates: Pick<PrimaryRates, 'swETHPrimaryRate'> | undefined
  nativeCurrency: { decimals: number; symbol: string }
  swETHToken: { decimals: number; symbol: string }
  anyTransactionInProgress: boolean
}) {
  const complete = swETHDeposit.status === swETHDeposit.STATUS.FULFILLED
  const confirming = swETHDeposit.status === swETHDeposit.STATUS.PROMPTING
  const pending = swETHDeposit.status === swETHDeposit.STATUS.PENDING

  let title = ''
  if (complete) {
    title = TRANSACTION_TOAST_TITLE.COMPLETED
  } else if (confirming) {
    title = TRANSACTION_TOAST_TITLE.STAKE_PROMPTING
  } else if (pending) {
    title = TRANSACTION_TOAST_TITLE.STAKE_PENDING
  }

  let message = ''
  if (swETHDeposit.args && primaryRates) {
    const [{ nativeCurrencyAmount }] = swETHDeposit.args
    const swETHAmount = getSwethReceivedForNativeCurrency({
      swethToEthRate: primaryRates.swETHPrimaryRate,
      toSendNativeCurrency: nativeCurrencyAmount,
    })
    const amountNativeStr = displayCryptoLocale(
      nativeCurrencyAmount,
      nativeCurrency.decimals
    )
    const amountSwETHStr = displayCryptoLocale(swETHAmount, swETHToken.decimals)
    message = `Stake ${amountNativeStr} ${nativeCurrency.symbol} for ${amountSwETHStr} ${swETHToken.symbol}`
    if (complete) {
      message = `Staked ${amountNativeStr} ${nativeCurrency.symbol} for ${amountSwETHStr} ${swETHToken.symbol}`
    }
  }

  return (
    <Web3Toast
      call={swETHDeposit}
      title={title}
      message={message}
      anyTransactionInProgress={anyTransactionInProgress}
    />
  )
}

const ToastActionLink = styled.a`
  color: ${({ theme }) => theme.colors.white['50']};
  text-decoration: underline !important;

  &[aria-busy='true'] {
    cursor: default;
    pointer-events: none;
    opacity: 0.6;
  }
  &:not([aria-busy='true']) {
    cursor: pointer;
    &:hover {
      color: ${({ theme }) => theme.colors.white['150']};
    }
  }
`

export function SwETHApproveUnstakeToast({
  approveSwETHForWithdrawal,
  anyTransactionInProgress,
  swETHToken,
}: {
  approveSwETHForWithdrawal: ApproveSwETHForWithdrawal
  anyTransactionInProgress: boolean
  swETHToken: { symbol: string; decimals: number }
}) {
  const complete =
    approveSwETHForWithdrawal.status ===
    approveSwETHForWithdrawal.STATUS.FULFILLED
  const confirming =
    approveSwETHForWithdrawal.status ===
    approveSwETHForWithdrawal.STATUS.PROMPTING
  const pending =
    approveSwETHForWithdrawal.status ===
    approveSwETHForWithdrawal.STATUS.PENDING

  let title = ''
  if (complete) {
    title = TRANSACTION_TOAST_TITLE.APPROVE_COMPLETED
  } else if (confirming) {
    title = TRANSACTION_TOAST_TITLE.APPROVE_PROMPTING
  } else if (pending) {
    title = TRANSACTION_TOAST_TITLE.APPROVE_PENDING
  }

  let message = ''
  if (approveSwETHForWithdrawal.args) {
    const [{ amount }] = approveSwETHForWithdrawal.args
    const amountStr = displayCryptoLocale(amount, swETHToken.decimals, {
      precision: 4,
    })
    message = `Approve ${amountStr} ${swETHToken.symbol}`
    if (complete) {
      message = `Approved ${amountStr} ${swETHToken.symbol}`
    }
  }

  return (
    <Web3Toast
      call={approveSwETHForWithdrawal}
      title={title}
      message={message}
      anyTransactionInProgress={anyTransactionInProgress}
    />
  )
}

export function SwETHUnstakeToast({
  createWithdrawRequest,
  anyTransactionInProgress,
  primaryRates,
  switchToClaim: switchToClaimProp,
  nativeCurrency,
  swETHToken,
}: {
  createWithdrawRequest: CreateWithdrawRequestSwETH
  anyTransactionInProgress: boolean
  primaryRates: Pick<PrimaryRates, 'swETHPrimaryRate'> | undefined
  switchToClaim: (() => void) | undefined
  swETHToken: { decimals: number; symbol: string }
  nativeCurrency: { decimals: number; symbol: string }
}) {
  const onClose = createWithdrawRequest.clear

  const complete =
    createWithdrawRequest.status === createWithdrawRequest.STATUS.FULFILLED
  const confirming =
    createWithdrawRequest.status === createWithdrawRequest.STATUS.PROMPTING
  const pending =
    createWithdrawRequest.status === createWithdrawRequest.STATUS.PENDING

  let title = ''
  if (complete) {
    title = TRANSACTION_TOAST_TITLE.UNSTAKE_COMPLETED
  } else if (confirming) {
    title = TRANSACTION_TOAST_TITLE.UNSTAKE_PROMPTING
  } else if (pending) {
    title = TRANSACTION_TOAST_TITLE.UNSTAKE_PENDING
  }

  function onClickCheckClaimTab() {
    if (!switchToClaimProp) {
      return
    }
    switchToClaimProp()
    onClose()
  }

  const canSwitchToClaim = !!switchToClaimProp

  let message: ReactNode = ''
  if (createWithdrawRequest.args && primaryRates) {
    const [{ swETHAmount }] = createWithdrawRequest.args
    const nativeAmount = getNativeCurrencyToExchangeForSweth({
      swethToEthRate: primaryRates.swETHPrimaryRate,
      toReceiveSweth: swETHAmount,
    })

    const swethStr = displayCryptoLocale(swETHAmount, swETHToken.decimals, {
      precision: 4,
    })
    const nativeStr = displayCryptoLocale(
      nativeAmount,
      nativeCurrency.decimals,
      { precision: 4 }
    )

    if (complete) {
      message = (
        <span>
          Check{' '}
          <ToastActionLink
            onClick={onClickCheckClaimTab}
            aria-busy={canSwitchToClaim ? 'false' : 'true'}
          >
            claim tab
          </ToastActionLink>
          .
        </span>
      )
    } else {
      message = `Unstaking ${swethStr} ${swETHToken.symbol} for ${nativeStr} ${nativeCurrency.symbol}`
    }
  }

  return (
    <Web3Toast
      call={createWithdrawRequest}
      title={title}
      message={message}
      anyTransactionInProgress={anyTransactionInProgress}
    />
  )
}

export function SwETHFinalizeWithdrawalToast({
  finalizeWithdrawal,
  withdrawUser,
  claimInputs,
  anyTransactionInProgress,
}: {
  finalizeWithdrawal: FinalizeWithdrawalSwETH
  withdrawUser: SwETHWithdrawalsUser | undefined
  claimInputs: SwETHClaimInputs
  anyTransactionInProgress: boolean
}) {
  const complete =
    finalizeWithdrawal.status === finalizeWithdrawal.STATUS.FULFILLED
  const confirming =
    finalizeWithdrawal.status === finalizeWithdrawal.STATUS.PROMPTING
  const pending =
    finalizeWithdrawal.status === finalizeWithdrawal.STATUS.PENDING

  let title = ''
  if (complete) {
    title = TRANSACTION_TOAST_TITLE.FINALIZE_WITHDRAWAL_COMPLETED
  } else if (confirming) {
    title = TRANSACTION_TOAST_TITLE.FINALIZE_WITHDRAWAL_PROMPTING
  } else if (pending) {
    title = TRANSACTION_TOAST_TITLE.FINALIZE_WITHDRAWAL_PENDING
  }

  let message = ''
  if (finalizeWithdrawal.args && withdrawUser) {
    const [{ requestId: requestIdBN }] = finalizeWithdrawal.args
    const requestId = requestIdBN.toNumber()
    const { exitClaims } = withdrawUser
    const { selectedTokenId } = claimInputs
    if (selectedTokenId !== requestId) {
      return null
    }
    let ethAmount: string | undefined = undefined
    for (const claim of exitClaims) {
      const claimRequestId = BigNumber.from(claim.requestId).toNumber()
      if (claimRequestId === requestId) {
        ethAmount = formatWithConfig(claim.assetAmount, {
          localize: true,
          precision: 3,
        })
        break
      }
    }
    if (!ethAmount) {
      return null
    }
    message = `Claiming ${ethAmount} ETH`
    if (complete) {
      message = `Claimed ${ethAmount} ETH`
    }
  }

  return (
    <Web3Toast
      call={finalizeWithdrawal}
      title={title}
      message={message}
      anyTransactionInProgress={anyTransactionInProgress}
    />
  )
}
