import { SwellchainIncentive, SwellchainIncentiveId } from '@/types/incentives'
import { parseEther } from 'ethers/lib/utils'

export const SWELLCHAIN_INCENTIVE_2024_LAUNCH: SwellchainIncentive = {
  id: SwellchainIncentiveId.SwellchainIncentive2024Launch,
  name: '2024 Launch', // AKA wavedrop 3
  targetDateMs: 1735603200000, // Tuesday, 31 December 2024 00:00:00 UTC
  swellToDistribute: parseEther('32500000'),
}

export const SWELLCHAIN_INCENTIVE_WAVEDROP3_PART2: SwellchainIncentive = {
  id: SwellchainIncentiveId.Wavedrop3Part2,
  name: 'Wavedrop 3 Part 2',
  targetDateMs: 1736812800000, // Tuesday, 14 January 2025 00:00:00 UTC
  swellToDistribute: parseEther('25000000'),
}

export const SWELLCHAIN_INCENTIVE_WAVEDROP3_PART3: SwellchainIncentive = {
  id: SwellchainIncentiveId.Wavedrop3Part3,
  name: 'Wavedrop 3 Part 3',
  targetDateMs: 1738022400000, // Tuesday, 28 January 2025 00:00:00 UTC
  swellToDistribute: parseEther('25000000'), // TBD
}
