import { EthInput, SwEthInput } from '@/swell-ui/inputs'
import styled from 'styled-components'
import { css } from 'styled-components'
import {
  SwETHDepositInputs,
  SwETHDepositSummary,
  SwETHWithdrawInputs,
  SwETHWithdrawSummary,
} from './types'
import { ReactNode } from 'react'
import { FlexRow } from '@/swell-ui/FlexRow'
import { Typography } from '@/swell-ui/Typography'

/* border-color: ${ThemeData.Swell.SwellVaultTurquoise}; */
const StyledEthInput = styled(EthInput)`
  ${({ theme }) => css`
    .MuiFormHelperText-root {
      margin-top: 2px;
      margin-bottom: 16px;
    }

    ${theme.breakpoints.up('sm')} {
      max-width: unset;
    }
  `}
  .MuiInputBase-root:after {
  }
  .Mui-disabled.MuiInputBase-root:before {
    border-bottom-style: solid;
    opacity: 0.6;
  }
`
const EthInputWrapper = styled.div`
  position: relative;
  display: flex;
  gap: 8px;
  justify-content: space-between;
  align-items: center;

  .MuiFormControl-root {
    width: 100%;
  }

  input,
  div > div > p {
    font-size: ${({ theme }) => theme.typography.body.large.fontSize};
    font-weight: 600;
  }
`

export function SwETHTopInputDepositNativeCurrency({
  depositSummary,
  errorMessage,
  setTouched,
  preventInteraction,
  depositInputs,
}: {
  depositSummary: Pick<SwETHDepositSummary, 'nativeCurrencyValueUSD'>
  preventInteraction: boolean
  depositInputs: Pick<
    SwETHDepositInputs,
    | 'nativeCurrencyMaxInputValue'
    | 'nativeCurrencyInput'
    | 'onEditNativeCurrency'
  >
  errorMessage: string
  setTouched: (touched: boolean) => void
}) {
  const disabled = preventInteraction

  const onChange = (event: any) => {
    setTouched(true)
    const value = event.target.value
    depositInputs.onEditNativeCurrency(value)
  }
  const onClickMax = () => {
    if (!depositInputs.nativeCurrencyMaxInputValue) {
      return
    }
    onChange({
      target: { value: depositInputs.nativeCurrencyMaxInputValue },
    })
  }

  return (
    <InputLayout
      usdLabel={depositSummary.nativeCurrencyValueUSD}
      hasError={!!errorMessage}
      showUsd
    >
      <EthInputWrapper>
        <StyledEthInput
          variant="standard"
          value={depositInputs.nativeCurrencyInput}
          onChange={onChange}
          error={!!errorMessage}
          helperText={errorMessage}
          disabled={disabled}
          onMaxClick={onClickMax}
        />
      </EthInputWrapper>
    </InputLayout>
  )
}
export function SwETHBottomInputDepositForSwETH({
  depositSummary,
  preventInteraction,
  depositInputs,
  setTouched,
}: {
  depositSummary: Pick<SwETHDepositSummary, 'swETHValueUSD'>
  preventInteraction: boolean
  depositInputs: Pick<SwETHDepositInputs, 'swETHInput' | 'onEditSwETH'>
  setTouched: (touched: boolean) => void
}) {
  const disabled = preventInteraction

  const onChange = (event: any) => {
    setTouched(true)
    const value = event.target.value
    depositInputs.onEditSwETH(value)
  }

  return (
    <InputLayout usdLabel={depositSummary.swETHValueUSD} showUsd>
      <EthInputWrapper>
        <SwEthInput
          variant="standard"
          value={depositInputs.swETHInput}
          onChange={onChange}
          error={false}
          helperText={null}
          disabled={disabled}
        />
      </EthInputWrapper>
    </InputLayout>
  )
}

export function SwETHTopInputWithdrawSwETH({
  withdrawSummary,
  errorMessage,
  preventInteraction,
  withdrawInputs,
  setTouched,
}: {
  withdrawSummary: Pick<SwETHWithdrawSummary, 'swETHValueUSD'>
  preventInteraction: boolean
  withdrawInputs: Pick<
    SwETHWithdrawInputs,
    'swETHInput' | 'onEditSwETH' | 'swETHMaxInputValue'
  >
  errorMessage: string
  setTouched: (touched: boolean) => void
}) {
  const onClickMax = () => {
    if (!withdrawInputs.swETHMaxInputValue) {
      return
    }
    onChange({
      target: { value: withdrawInputs.swETHMaxInputValue },
    })
  }

  const disabled = preventInteraction
  const onChange = (event: any) => {
    setTouched(true)
    const value = event.target.value
    withdrawInputs.onEditSwETH(value)
  }
  return (
    <InputLayout
      usdLabel={withdrawSummary.swETHValueUSD}
      hasError={!!errorMessage}
      showUsd
    >
      <EthInputWrapper>
        <StyledEthInput
          variant="standard"
          value={withdrawInputs.swETHInput}
          onChange={onChange}
          error={!!errorMessage}
          helperText={errorMessage}
          disabled={disabled}
          onMaxClick={onClickMax}
        />
      </EthInputWrapper>
    </InputLayout>
  )
}

export function SwETHBottomInputWithdrawForNativeCurrency({
  withdrawSummary,
  preventInteraction,
  withdrawInputs,
  setTouched,
}: {
  withdrawSummary: Pick<SwETHWithdrawSummary, 'nativeCurrencyValueUSD'>
  preventInteraction: boolean
  withdrawInputs: Pick<
    SwETHWithdrawInputs,
    'nativeCurrencyInput' | 'onEditNativeCurrency'
  >
  setTouched: (touched: boolean) => void
}) {
  const disabled = preventInteraction

  const onChange = (event: any) => {
    setTouched(true)
    const value = event.target.value
    withdrawInputs.onEditNativeCurrency(value)
  }

  return (
    <InputLayout usdLabel={withdrawSummary.nativeCurrencyValueUSD} showUsd>
      <EthInputWrapper>
        <SwEthInput
          variant="standard"
          value={withdrawInputs.nativeCurrencyInput}
          onChange={onChange}
          error={false}
          helperText={null}
          disabled={disabled}
        />
      </EthInputWrapper>
    </InputLayout>
  )
}

const InfoTypography = styled(Typography)`
  height: 19.2px;
  color: rgb(164, 171, 241);
  letter-spacing: -0.03em;
`
function InputLayout({
  children,
  usdLabel,
  hasError,
  showUsd,
}: {
  children: React.ReactNode
  hasError?: boolean
  usdLabel?: string | undefined
  showUsd?: boolean
}) {
  let usdNode: ReactNode = <div style={{ height: '0px' }} />
  if (!hasError) {
    usdNode = usdLabel ? (
      <InfoTypography variant="body" size="xsmall" className="il-usd-node">
        {usdLabel}
      </InfoTypography>
    ) : (
      <InfoTypography variant="body" size="xsmall" className="il-usd-node">
        $0.00
      </InfoTypography>
    )
  }

  return (
    <SInputLayout direction="column" gap="8" align="start">
      {children}
      {!hasError && (
        <FlexRow justify="space-between" gap="8" height="19.2px">
          {showUsd && <div style={{ height: '19.2px' }}>{usdNode}</div>}
        </FlexRow>
      )}
    </SInputLayout>
  )
}

const SInputLayout = styled(FlexRow)`
  position: relative;

  [aria-busy='false'] {
    min-width: unset;
    height: unset;
  }
  [aria-busy='true'] {
    min-width: 32px;
  }
  .il-usd-node {
    margin-right: auto;
  }

  .MuiFormHelperText-root {
    height: 27.2px;
    margin: 0;
    padding-top: 2px;
  }
`
