import { AsyncDiv } from '@/swell-ui/AsyncDiv'
import { FlexRow } from '@/swell-ui/FlexRow'
import { useMediaQuery } from '@mui/material'
import styled from 'styled-components'
import { makeNucleusVaultStatsSummary } from './nucleusFormatting'
import { NucleusVaultStats } from '@/state/nucleusVault/types'
import { NucleusHoldingsSummary, NucleusPointsCampaigns } from './types'
import { ReactNode } from 'react'
import { Tooltip } from '@/swell-ui/Tooltip'
import { InfoIcon } from '@/swell-ui/icons/InfoIcon'
import { ColoredHeadingTypography } from '@/swell-ui/Typography/typographyPresets'
import { css } from 'styled-components'

const ICON_SIZE = 24

export function NucleusVaultStatsRow({
  vaultStats,
  holdingsSummary,
  pointsCampaigns,
}: {
  vaultStats: NucleusVaultStats | undefined
  pointsCampaigns: NucleusPointsCampaigns
  holdingsSummary: NucleusHoldingsSummary
}) {
  const { nucleusPtsCampaign } = pointsCampaigns

  const summary = makeNucleusVaultStatsSummary({ vaultStats })

  return (
    <Layout>
      <div className="ns-apy metric small-loading">
        <span className="s-label">Est. APY</span>
        <AsyncDiv loading={!summary.apy}>
          {() => <span className="s-value">{summary.apy}</span>}
        </AsyncDiv>
      </div>
      <div className="ns-points metric">
        <span className="s-label">Nucleus Pts (3x)</span>
        <AsyncDiv loading={false}>
          {() => (
            <FlexRow gap="12">
              <img
                src={nucleusPtsCampaign.logoURI}
                width={ICON_SIZE}
                height={ICON_SIZE}
              />
              <span className="s-value">TBD</span>
            </FlexRow>
          )}
        </AsyncDiv>
      </div>
      <div className="ns-tvl metric">
        <span className="s-label">TVL</span>
        <AsyncDiv loading={!summary.tvl}>
          {() => <span className="s-value">{summary.tvl}</span>}
        </AsyncDiv>
      </div>
    </Layout>
  )
}

const layoutMobile = css`
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto;
  grid-template-areas:
    'apy'
    'points'
    'tvl';
  gap: 12px;
  justify-content: start;
  align-items: start;
`
const layoutDesktop = css`
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto;
  grid-template-areas: 'apy points tvl';
`

const Layout = styled.div`
  width: 100%;

  display: grid;

  ${layoutMobile}
  @media (min-width: 942px) {
    ${layoutDesktop}
  }

  .ns-apy {
    grid-area: apy;
  }
  .ns-points {
    grid-area: points;
  }
  .ns-tvl {
    grid-area: tvl;
  }
  .s-label {
    ${(props) => props.theme.typographyCSS.coloredHeading}
    white-space: nowrap;
  }

  .metric {
    [aria-busy='true'] {
      height: 33.8px;
      display: flex;
      flex-flow: column;
      justify-content: center;
    }

    @media (min-width: 942px) {
      display: flex;
      flex-flow: row;
      align-items: center;
      gap: 12px;
    }
  }

  .metric.small-loading {
    [aria-busy='true'] {
      min-width: 80px;
      max-width: 80px;
    }
  }

  .metric:not(.small-loading) {
    [aria-busy='true'] {
      min-width: 160px;
      max-width: 160px;
    }
  }

  .s-value {
    color: var(--Swell-White-50, #fff);
    /* Headlines/H6 */
    font-family: Inter;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 33.8px */
    letter-spacing: -0.78px;
  }
`

function Holding({
  icon,
  title,
  value,
  tooltip,
}: {
  title: string
  value: ReactNode | undefined
  icon?: string
  tooltip?: ReactNode
}) {
  const is942Up = useMediaQuery('(min-width:942px)')

  const titleContent = (
    <>
      {title}
      {tooltip && (
        <>
          {' '}
          <Tooltip title={tooltip} arrow>
            <InfoIcon />
          </Tooltip>
        </>
      )}
    </>
  )

  if (is942Up) {
    return (
      <HoldingLayout gap="12">
        <ColoredHeadingTypography nobreak={is942Up}>
          {titleContent}
        </ColoredHeadingTypography>
        <FlexRow gap="12">
          <AsyncDiv loading={!value}>
            {() => (
              <FlexRow gap="12" justify={is942Up ? 'center' : 'start'}>
                {icon && (
                  <img src={icon} width={ICON_SIZE} height={ICON_SIZE} />
                )}
                <span className="value">{value}</span>
              </FlexRow>
            )}
          </AsyncDiv>
        </FlexRow>
      </HoldingLayout>
    )
  }

  return (
    <HoldingLayout gap="6">
      <ColoredHeadingTypography>{titleContent}</ColoredHeadingTypography>
      <FlexRow gap="12">
        <AsyncDiv loading={!value}>
          {() => (
            <FlexRow gap="12">
              {icon && <img src={icon} width={ICON_SIZE} height={ICON_SIZE} />}
              <span className="value">{value}</span>
            </FlexRow>
          )}
        </AsyncDiv>
      </FlexRow>
    </HoldingLayout>
  )
}

const HoldingLayout = styled(FlexRow)`
  flex-wrap: wrap;
  @media (min-width: 470px) {
    flex-wrap: nowrap;
    ${ColoredHeadingTypography} {
      flex: 1 0 auto;
    }
  }

  ${ColoredHeadingTypography} {
    svg {
      position: relative;
      top: 1px;

      path {
        stroke: currentColor;
      }
    }
  }

  span.value {
    color: var(--Swell-White-50, #fff);
    /* Headlines/H6 */
    font-family: Inter;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 33.8px */
    letter-spacing: -0.78px;
  }

  [aria-busy='false'] {
    min-width: unset;
    width: min-content;
  }

  [aria-busy='true'] {
    height: 33.8px;
    display: flex;
    flex-flow: column;
    justify-content: center;
  }
`
