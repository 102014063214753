/**
 * Copied: https://github.com/Uniswap/interface/blob/ad2472eac638b389316ba1f3c3f1ed08fbbb12cd/src/hooks/useContract.ts
 * Changed:
 * - Similar structure, but different domain -> different contracts
 */
import { Contract } from '@ethersproject/contracts'
import { useMemo } from 'react'
import { useSwellWeb3 } from '@swell-web3/core'
import { getContract } from '../util/contract'
import SWETH_ABI from '../abis/SwETH.json'
import SWEXIT_ABI from '../abis/SWExit.json'
import DEPOSIT_MANAGER_ABI from '../abis/DepositManager.json'
import NODE_OPERATOR_REGISTRY_ABI from '../abis/NodeOperatorRegistry.json'
import AGGREGATOR_V3_INTERFACE_ABI from '../abis/AggregatorV3Interface.json'
import RSWETH_ABI from '../abis/RswETH.json'
import RESTAKING_DEPOSIT_MANAGER_ABI from '../abis/RestakingDepositManager.json'
import RESTAKING_NODE_OPERATOR_REGISTRY_ABI from '../abis/RestakingNodeOperatorRegistry.json'
import SIMPLE_STAKING_ERC20_ABI from '../abis/SimpleStakingERC20.json'
import IERC20_ABI from '../abis/IERC20.json'
import ZAP_ABI from '../abis/Zap.json'
import MULTICALL3_ABI from '../abis/Multicall3.json'
import PRE_DEPOSIT_ZAP_ABI from '../abis/PreDepositZap.json'
import TELLER_WITH_MULTI_ASSET_SUPPORT_ABI from '../abis/TellerWithMultiAssetSupport.json'
import MOCK_TOKEN_ABI from '../abis/MockToken.json'
import YEARN_V3_VAULT_ABI from '../abis/YearnV3Vault.json'
import YEARN_DELAYED_WITHDRAW_ABI from '../abis/YearnDelayedWithdraw.json'
import YEARN_DELAYED_WITHDRAW_V2_ABI from '../abis/YearnDelayedWithdrawV2.json'
import MULTI_CHAIN_LAYER_ZERO_TELLER_WITH_MULTI_ASSET_SUPPORT_ABI from '../abis/MultiChainLayerZeroTellerWithMultiAssetSupport.json'
import ERC20_WRAPPER_LOCKED from '../abis/ERC20WrapperLocked.json'
import MERKL_DISTRIBUTOR_CONTRACT from '../abis/MerklDistributorContract.json'
import {
  SwETH,
  SWExit,
  DepositManager,
  NodeOperatorRegistry,
  AggregatorV3Interface,
  RswETH,
  RestakingDepositManager,
  RestakingNodeOperatorRegistry,
  SimpleStakingERC20,
  IERC20,
  Zap,
  Multicall3,
  PreDepositZap,
  TellerWithMultiAssetSupport,
  YearnDelayedWithdraw,
  YearnV3Vault,
  MockToken,
  AtomicQueue,
  BoringVault,
  YearnDelayedWithdrawV2,
  MultiChainLayerZeroTellerWithMultiAssetSupport,
  ERC20WrapperLocked,
  MerklDistributorContract,
} from '@/abis/types'
import {
  useContractAddresses,
  useL2ContractAddresses,
} from '@/state/deployments/hooks'
import {
  CHAINLINK_ETH_USD_FEED_ADDRESSES,
  CHAINLINK_WBTC_ETH_ADDRESSES,
} from '@/constants/addresses'
import BORING_VAULT_ABI from '../abis/BoringVault.json'
import ACCOUNTANT_WITH_RATE_PROVIDERS_ABI from '../abis/AccountantWithRateProviders.json'
import ATOMIC_QUEUE_ABI from '../abis/AtomicQueue.json'
import ROLES_AUTHORITY_ABI from '../abis/RolesAuthority.json'
import ARCTIC_ARCHITECTURE_LENS_ABI from '../abis/ArcticArchitectureLens.json'
import { CumulativeMerkleDrop } from '@/abis/types/CumulativeMerkleDrop'
import CUMULATIVE_MERKLE_DROP_ABI from '../abis/CumulativeMerkleDrop.json'
import useChainDetection from './useChainDetection'
import { useWSwellMerklSettings } from '@/state/deployments/hooks/useWSwellMerklSettings'

function useContract<T extends Contract = Contract>(
  addressOrAddressMap: string | { [chainId: number]: string } | undefined,
  ABI: any,
  withSignerIfPossible = true
): T | null {
  const { account, chainId, provider } = useSwellWeb3()

  return useMemo(() => {
    if (!addressOrAddressMap || !ABI || !provider || !chainId) return null
    let address: string | undefined
    if (typeof addressOrAddressMap === 'string') address = addressOrAddressMap
    else address = addressOrAddressMap[chainId]
    if (!address) return null
    try {
      return getContract(
        address,
        ABI,
        provider,
        withSignerIfPossible && account ? account : undefined
      )
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [
    addressOrAddressMap,
    ABI,
    provider,
    chainId,
    withSignerIfPossible,
    account,
  ]) as T | null
}
function useReadonlyContract<T extends Contract = Contract>(
  address: string | undefined,
  ABI: any
): T | null {
  const { readOnlyProvider } = useSwellWeb3()

  return useMemo(() => {
    if (!address || !ABI || !readOnlyProvider) return null
    try {
      return getContract(address, ABI, readOnlyProvider)
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [address, ABI, readOnlyProvider]) as T | null
}
function useReadonlyContractL2<T extends Contract = Contract>(
  address: string | undefined,
  ABI: any
): T | null {
  const { userProviderIfAvailableL2 } = useChainDetection()

  return useMemo(() => {
    if (!address || !ABI || !userProviderIfAvailableL2) return null
    try {
      return getContract(address, ABI, userProviderIfAvailableL2)
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [address, ABI, userProviderIfAvailableL2]) as T | null
}

function useMainnetContract<T extends Contract = Contract>(
  addressOrAddressMap: string | { [chainId: number]: string } | undefined,
  ABI: any,
  withSignerIfPossible = true
): T | null {
  const { chainId } = useSwellWeb3()
  const { deploymentChainId } = useChainDetection()

  const executableContract = useContract<T>(
    addressOrAddressMap,
    ABI,
    withSignerIfPossible
  )

  let addr: string | undefined
  if (typeof addressOrAddressMap === 'string') addr = addressOrAddressMap
  else addr = addressOrAddressMap?.[deploymentChainId]

  const readonlyContract = useReadonlyContract<T>(addr, ABI)

  return chainId === deploymentChainId ? executableContract : readonlyContract
}

function useL2Contract<T extends Contract = Contract>(
  addressOrAddressMap: string | { [chainId: number]: string } | undefined,
  ABI: any,
  withSignerIfPossible = true
): T | null {
  const { isL2DeploymentChain, l2DeploymentChainId } = useChainDetection()

  const executableContract = useContract<T>(
    addressOrAddressMap,
    ABI,
    withSignerIfPossible
  )

  let addr: string | undefined
  if (typeof addressOrAddressMap === 'string') addr = addressOrAddressMap
  else addr = addressOrAddressMap?.[l2DeploymentChainId]

  const readonlyContract = useReadonlyContractL2<T>(addr, ABI)

  return isL2DeploymentChain ? executableContract : readonlyContract
}

/* Staking contracts */
export function useSwETHContract() {
  const { swETH } = useContractAddresses()
  return useMainnetContract<SwETH>(swETH, SWETH_ABI, true)
}

export function useSwETHContractView() {
  const { swETH } = useContractAddresses()
  return useMainnetContract<SwETH>(swETH, SWETH_ABI, true)!
}

export function useSwExitContract() {
  const { swExit } = useContractAddresses()
  return useMainnetContract<SWExit>(swExit, SWEXIT_ABI, true)
}

export function useSwExitContractView() {
  const { swExit } = useContractAddresses()
  return useMainnetContract<SWExit>(swExit, SWEXIT_ABI, true)!
}

export function useDepositManagerContract() {
  const { depositManager } = useContractAddresses()
  return useMainnetContract<DepositManager>(
    depositManager,
    DEPOSIT_MANAGER_ABI,
    true
  )
}

export function useDepositManagerContractView() {
  const { depositManager } = useContractAddresses()
  return useMainnetContract<DepositManager>(
    depositManager,
    DEPOSIT_MANAGER_ABI,
    true
  )!
}

export function useNodeOperatorRegistryContract() {
  const { nodeOperatorRegistry } = useContractAddresses()
  return useMainnetContract<NodeOperatorRegistry>(
    nodeOperatorRegistry,
    NODE_OPERATOR_REGISTRY_ABI,
    true
  )
}

export function useNodeOperatorRegistryContractView() {
  const { nodeOperatorRegistry } = useContractAddresses()
  return useMainnetContract<NodeOperatorRegistry>(
    nodeOperatorRegistry,
    NODE_OPERATOR_REGISTRY_ABI,
    true
  )!
}

/* Restaking Contracts */

export function useRswETHContract() {
  const { rswETH } = useContractAddresses()
  return useMainnetContract<RswETH>(rswETH, RSWETH_ABI, true)
}

export function useRswETHContractView() {
  const { rswETH } = useContractAddresses()
  return useMainnetContract<RswETH>(rswETH, RSWETH_ABI, true)!
}

export function useRestakingNodeOperatorRegistryContract() {
  const { restakingNodeOperatorRegistry } = useContractAddresses()
  return useMainnetContract<RestakingNodeOperatorRegistry>(
    restakingNodeOperatorRegistry,
    RESTAKING_NODE_OPERATOR_REGISTRY_ABI,
    true
  )
}

export function useRestakingNodeOperatorRegistryContractView() {
  const { restakingNodeOperatorRegistry } = useContractAddresses()
  return useMainnetContract<RestakingNodeOperatorRegistry>(
    restakingNodeOperatorRegistry,
    RESTAKING_NODE_OPERATOR_REGISTRY_ABI,
    true
  )!
}

export function useRestakingDepositManagerContract() {
  const { restakingDepositManager } = useContractAddresses()
  return useMainnetContract<RestakingDepositManager>(
    restakingDepositManager,
    RESTAKING_DEPOSIT_MANAGER_ABI,
    true
  )
}

export function useRestakingDepositManagerContractView() {
  const { restakingDepositManager } = useContractAddresses()
  return useMainnetContract<DepositManager>(
    restakingDepositManager,
    RESTAKING_DEPOSIT_MANAGER_ABI,
    true
  )!
}

export function useEthUsdFeedChainlinkContract() {
  return useMainnetContract<AggregatorV3Interface>(
    CHAINLINK_ETH_USD_FEED_ADDRESSES,
    AGGREGATOR_V3_INTERFACE_ABI,
    true
  )!
}
export function useWbtcEthChainlinkContract() {
  return useMainnetContract<AggregatorV3Interface>(
    CHAINLINK_WBTC_ETH_ADDRESSES,
    AGGREGATOR_V3_INTERFACE_ABI,
    true
  )!
}

export function useZapContract() {
  const { zap } = useContractAddresses()
  return useMainnetContract<Zap>(zap, ZAP_ABI)
}

export function useMulticallContract() {
  const { multicall } = useContractAddresses()
  return useMainnetContract<Multicall3>(multicall, MULTICALL3_ABI, true)!
}

export function useIERC20Contract(address: string) {
  return useMainnetContract<IERC20>(address, IERC20_ABI, true)
}

export function usePreDepositStakingContract() {
  const { preDepositStaking } = useContractAddresses()
  return useMainnetContract<SimpleStakingERC20>(
    preDepositStaking,
    SIMPLE_STAKING_ERC20_ABI,
    true
  )
}

export function usePreDepositStakingContractView() {
  const { preDepositStaking } = useContractAddresses()
  return useMainnetContract<SimpleStakingERC20>(
    preDepositStaking,
    SIMPLE_STAKING_ERC20_ABI,
    true
  )!
}

export function usePreDepositZapContract() {
  const { preDepositZap } = useContractAddresses()
  return useMainnetContract<PreDepositZap>(
    preDepositZap,
    PRE_DEPOSIT_ZAP_ABI,
    true
  )!
}

export function useLiquidUSDVaultTellerContract() {
  const { liquidUSDVaultTeller } = useContractAddresses()
  return useMainnetContract<TellerWithMultiAssetSupport>(
    liquidUSDVaultTeller,
    TELLER_WITH_MULTI_ASSET_SUPPORT_ABI,
    true
  )!
}
export function useMockTokenContract(address: string) {
  return useMainnetContract<MockToken>(address, MOCK_TOKEN_ABI, true)!
}
export function useYearnV3VaultContract(address: string) {
  return useMainnetContract<YearnV3Vault>(address, YEARN_V3_VAULT_ABI, true)!
}
export function useYearnDelayedWithdrawContract(address: string) {
  return useMainnetContract<YearnDelayedWithdraw>(
    address,
    YEARN_DELAYED_WITHDRAW_ABI,
    true
  )!
}
export function useYearnDelayedWithdrawV2Contract(address: string) {
  return useMainnetContract<YearnDelayedWithdrawV2>(
    address,
    YEARN_DELAYED_WITHDRAW_V2_ABI,
    true
  )!
}

export function useAtomicQueue(address: string) {
  return useMainnetContract<AtomicQueue>(address, ATOMIC_QUEUE_ABI, true)!
}
export function useAtomicQueueL2(address: string) {
  return useL2Contract<AtomicQueue>(address, ATOMIC_QUEUE_ABI, true)!
}
export function useBoringVault(address: string) {
  return useMainnetContract<BoringVault>(address, BORING_VAULT_ABI, true)!
}
export function useBoringVaultL2(address: string) {
  return useL2Contract<BoringVault>(address, BORING_VAULT_ABI, true)!
}
export function useBoringVaultTeller(address: string) {
  return useMainnetContract<TellerWithMultiAssetSupport>(
    address,
    TELLER_WITH_MULTI_ASSET_SUPPORT_ABI,
    true
  )!
}

export function useBoringVaultTellerL2(address: string) {
  return useL2Contract<MultiChainLayerZeroTellerWithMultiAssetSupport>(
    address,
    MULTI_CHAIN_LAYER_ZERO_TELLER_WITH_MULTI_ASSET_SUPPORT_ABI,
    true
  )!
}

export function useMerkleDropContract(address: string | undefined) {
  return useMainnetContract<CumulativeMerkleDrop>(
    address,
    CUMULATIVE_MERKLE_DROP_ABI,
    true
  )
}
export function useMerkleDropContractL2(address: string | undefined) {
  return useL2Contract<CumulativeMerkleDrop>(
    address,
    CUMULATIVE_MERKLE_DROP_ABI,
    true
  )
}

export function useReadonlyMulticall() {
  const { multicall } = useContractAddresses()
  return useReadonlyContract<Multicall3>(multicall, MULTICALL3_ABI)!
}
export function useReadonlyMulticallL2() {
  const { multicall } = useL2ContractAddresses()
  return useReadonlyContractL2<Multicall3>(multicall, MULTICALL3_ABI)!
}

export function useWSwellContract() {
  const { WSwellToken } = useWSwellMerklSettings()
  return useL2Contract<ERC20WrapperLocked>(
    WSwellToken.address,
    ERC20_WRAPPER_LOCKED,
    true
  )!
}
export function useMerklDistributorContract(addr: string) {
  return useMainnetContract<MerklDistributorContract>(
    addr,
    MERKL_DISTRIBUTOR_CONTRACT,
    true
  )
}
export function useMerklDistributorContractL2(addr: string) {
  return useL2Contract<MerklDistributorContract>(
    addr,
    MERKL_DISTRIBUTOR_CONTRACT,
    true
  )
}
export function useWSwellDistributor() {
  const { merklDistributor } = useWSwellMerklSettings()
  return useMerklDistributorContractL2(merklDistributor.address)!
}
