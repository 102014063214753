import {
  EagerEVKLock,
  EVKLockBalanceMapResp,
  EVKLockResp,
} from '@/state/wswell/types'
import { EVKUser, UserEVKLockWithBalances } from '@/types/eulerEVK'
import { displayCryptoLocale } from './displayCrypto'
import { formatEther, formatUnits, parseEther } from 'ethers/lib/utils'
import { displayPercentFromPercent } from './displayNumbers'
import { formatWithDynamicPrecision } from './number'

function formatDateEVK(date: Date) {
  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
}
function formatDurationEVK(durationMs: number) {
  if (durationMs <= 0) {
    return '0w 0d'
  }
  const weeks = Math.floor(durationMs / 1000 / 60 / 60 / 24 / 7)
  const days = Math.floor((durationMs / 1000 / 60 / 60 / 24) % 7)

  return `${weeks}w ${days}d`
}
export function formatEVKDateString(date: Date, durationMs: number) {
  return `${formatDateEVK(date)} (${formatDurationEVK(durationMs)})`
}
export function enrichEagerLock(
  lock: EagerEVKLock,
  {
    initialLockPercent,
    lockDurationMs,
    evkToken,
  }: {
    initialLockPercent: number
    lockDurationMs: number
    evkToken: { decimals: number }
  }
): UserEVKLockWithBalances {
  const { lockTimestampUnix, lockedAmount } = lock

  const formattedMaturityDate = formatEVKDateString(
    new Date(lockTimestampUnix * 1000 + lockDurationMs),
    lockDurationMs
  )

  // const remainderAmountBig = lockedAmount.mul(initialLockPercent).div(100)
  const remainderBig = lockedAmount
    .mul(parseEther(`${initialLockPercent / 100}`))
    .div(parseEther('1'))
  const accountAmountBig = lockedAmount.sub(remainderBig)
  const remainderAmount = displayCryptoLocale(remainderBig, evkToken.decimals, {
    precision: 2,
  })
  const accountAmount = displayCryptoLocale(
    accountAmountBig,
    evkToken.decimals,
    {
      precision: 2,
    }
  )

  return {
    lockTimestampUnix,
    lockedAmount: displayCryptoLocale(lockedAmount, evkToken.decimals, {
      precision: 2,
    }),
    remainderAmount,
    unlockablePercentNum: 100 - initialLockPercent,
    accountAmount,
    unlockablePercent: `${100 - initialLockPercent}%`,
    formattedMaturityDate: formattedMaturityDate,
    forfeitPercent: `${initialLockPercent}%`,
    forfeitPercentNum: initialLockPercent,
  }
}

export function buildEVKUser({
  balances,
  locks: locksProp,
  evkToken,
  maturityDurationUnix,
  nowUnix,
  eagerLock,
  initialLockPercent,
}: {
  balances: Partial<EVKLockBalanceMapResp>
  locks: EVKLockResp[]
  evkToken: { decimals: number }
  maturityDurationUnix: number
  nowUnix: number
  eagerLock: EagerEVKLock | undefined
  initialLockPercent: number
}): EVKUser {
  const nowMs = nowUnix * 1000

  let locks: typeof locksProp
  if (eagerLock) {
    const { numLocksAtTimeOfClaim } = eagerLock

    if (locksProp.length > numLocksAtTimeOfClaim) {
      let latestLockTimestamp = 0
      let latestLockIndex = -1
      for (let i = 0; i < locksProp.length; i++) {
        const lock = locksProp[i]
        if (lock.lockTimestampUnix > latestLockTimestamp) {
          latestLockTimestamp = lock.lockTimestampUnix
          latestLockIndex = i
        }
      }

      if (balances[latestLockTimestamp]) {
        locks = locksProp
        eagerLock = undefined
      } else {
        locks = locksProp.filter((l, i) => i !== latestLockIndex)
      }
    } else {
      locks = locksProp
    }
  } else {
    locks = locksProp
  }

  let totalToUnlock = 0
  const userLocks: UserEVKLockWithBalances[] = []
  for (const lock of locks) {
    const balance = balances[lock.lockTimestampUnix]

    const maturityDate = new Date(
      lock.lockTimestampUnix * 1000 + maturityDurationUnix * 1000
    )
    const maturityDurationMs = maturityDurationUnix * 1000
    let durationIntoWaitMs = 0
    let durationRemainingMs = 0
    if (nowMs < maturityDate.getTime()) {
      durationIntoWaitMs = nowMs - lock.lockTimestampUnix * 1000
      durationRemainingMs = maturityDurationMs - durationIntoWaitMs
    } else {
      durationIntoWaitMs = maturityDurationMs
      durationRemainingMs = 0
    }

    const formattedMaturityDate = formatEVKDateString(
      maturityDate,
      durationRemainingMs
    )

    const l: UserEVKLockWithBalances = {
      accountAmount: '',
      lockedAmount: displayCryptoLocale(lock.lockedAmount, evkToken.decimals, {
        precision: 2,
      }),
      lockTimestampUnix: lock.lockTimestampUnix,
      formattedMaturityDate,
      remainderAmount: '',
      unlockablePercent: '',
      unlockablePercentNum: 0,
      forfeitPercent: '',
      forfeitPercentNum: 0,
    }

    if (balance) {
      l.accountAmount = displayCryptoLocale(
        balance.accountAmount,
        evkToken.decimals,
        { precision: 2 }
      )

      l.remainderAmount = displayCryptoLocale(
        balance.remainderAmount,
        evkToken.decimals,
        { precision: 2 }
      )
      if (lock.lockedAmount.gt(0)) {
        const unlockablePercentBig = balance.accountAmount
          .mul(100)
          .mul(parseEther('1'))
          .div(lock.lockedAmount)
        l.unlockablePercentNum = parseFloat(formatEther(unlockablePercentBig))
        l.unlockablePercent = displayPercentFromPercent(l.unlockablePercentNum)
        l.forfeitPercentNum = 100 - l.unlockablePercentNum
        l.forfeitPercent = displayPercentFromPercent(l.forfeitPercentNum)
      }
    }

    totalToUnlock =
      totalToUnlock + Number(formatUnits(lock.lockedAmount, evkToken.decimals))

    userLocks.push(l)
  }

  if (eagerLock) {
    const lockDurationMs = maturityDurationUnix * 1000
    userLocks.push(
      enrichEagerLock(eagerLock, {
        initialLockPercent,
        lockDurationMs,
        evkToken,
      })
    )
    totalToUnlock += Number(
      formatUnits(eagerLock.lockedAmount, evkToken.decimals)
    )
  }

  const user: EVKUser = {
    locks: userLocks,
    totalToUnlock: formatWithDynamicPrecision(totalToUnlock, {
      localize: true,
    }),
  }

  const allLocksLoaded =
    user.locks.filter((l) => l.accountAmount === '').length === 0
  if (!allLocksLoaded) {
    user.totalToUnlock = ''
  }

  return user
}
