import { useEffect, useRef, useState } from 'react'
import { Web3Call } from './useWeb3Call'
import { useNowMsAnimation } from './useTimeCountdown'

export function useWeb3CallOnFulfill(
  call: Web3Call,
  fn: () => void,
  delayMs = 0
) {
  const statusRef = useRef(call.status)
  const [lastFulfilled, setLastFulfilled] = useState(0)
  const nowMs = useNowMsAnimation()

  useEffect(() => {
    const prevStatus = statusRef.current
    statusRef.current = call.status
    const wasNotFulfilled = prevStatus !== call.STATUS.FULFILLED
    const isFulfilled = call.status === call.STATUS.FULFILLED
    if (wasNotFulfilled && isFulfilled) {
      setLastFulfilled(Date.now())
    }
  }, [call, delayMs])

  useEffect(() => {
    if (lastFulfilled === 0) return
    if (nowMs - lastFulfilled > delayMs) {
      setLastFulfilled(0)
      fn()
    }
  }, [delayMs, fn, lastFulfilled, nowMs])
}

export function useWeb3CallClearOnFulfill(call: Web3Call, delayMs = 5000) {
  return useWeb3CallOnFulfill(call, call.clear, delayMs)
}

export function useWeb3CallAutoClearError(call: Web3Call, delayMs = 5000) {
  const errorRef = useRef(call.error)
  const [lastErrorMs, setLastErrorMs] = useState(0)
  const nowMs = useNowMsAnimation()

  useEffect(() => {
    const prevErr = errorRef.current
    errorRef.current = call.error
    const wasNotError = !prevErr
    const isError = !!call.error
    if (wasNotError && isError) {
      setLastErrorMs(Date.now())
    }
  }, [call, delayMs])

  const clear = call.clear

  useEffect(() => {
    if (lastErrorMs === 0) return
    if (nowMs - lastErrorMs > delayMs) {
      setLastErrorMs(0)
      clear()
    }
  }, [clear, delayMs, lastErrorMs, nowMs])
}
