// @generated by protoc-gen-es v1.3.3 with parameter "target=ts"
// @generated from file swell/v3/bungeebridge.proto (package swell.v3, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum swell.v3.BungeeStatus
 */
export enum BungeeStatus {
  /**
   * @generated from enum value: BUNGEE_STATUS_UNKNOWN = 0;
   */
  UNKNOWN = 0,

  /**
   * @generated from enum value: BUNGEE_STATUS_PENDING = 1;
   */
  PENDING = 1,

  /**
   * @generated from enum value: BUNGEE_STATUS_CONFIRMED = 2;
   */
  CONFIRMED = 2,

  /**
   * @generated from enum value: BUNGEE_STATUS_ERROR = 3;
   */
  ERROR = 3,

  /**
   * @generated from enum value: BUNGEE_STATUS_REFUND = 4;
   */
  REFUND = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(BungeeStatus)
proto3.util.setEnumType(BungeeStatus, "swell.v3.BungeeStatus", [
  { no: 0, name: "BUNGEE_STATUS_UNKNOWN" },
  { no: 1, name: "BUNGEE_STATUS_PENDING" },
  { no: 2, name: "BUNGEE_STATUS_CONFIRMED" },
  { no: 3, name: "BUNGEE_STATUS_ERROR" },
  { no: 4, name: "BUNGEE_STATUS_REFUND" },
]);

/**
 * @generated from message swell.v3.BungeeBridgeQuoteRequest
 */
export class BungeeBridgeQuoteRequest extends Message<BungeeBridgeQuoteRequest> {
  /**
   * @generated from field: int32 from_chain_id = 1;
   */
  fromChainId = 0;

  /**
   * @generated from field: int32 to_chain_id = 2;
   */
  toChainId = 0;

  /**
   * @generated from field: string from_token_address = 3;
   */
  fromTokenAddress = "";

  /**
   * @generated from field: string from_amount_wei = 4;
   */
  fromAmountWei = "";

  /**
   * @generated from field: int32 from_decimals = 5;
   */
  fromDecimals = 0;

  /**
   * @generated from field: string to_token_address = 6;
   */
  toTokenAddress = "";

  /**
   * @generated from field: int32 to_decimals = 7;
   */
  toDecimals = 0;

  /**
   * @generated from field: float slippage_percent = 8;
   */
  slippagePercent = 0;

  /**
   * @generated from field: string gas_on_destination_chain_wei = 9;
   */
  gasOnDestinationChainWei = "";

  /**
   * @generated from field: string from_account = 10;
   */
  fromAccount = "";

  /**
   * @generated from field: string to_account = 11;
   */
  toAccount = "";

  constructor(data?: PartialMessage<BungeeBridgeQuoteRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.BungeeBridgeQuoteRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "from_chain_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "to_chain_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "from_token_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "from_amount_wei", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "from_decimals", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "to_token_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "to_decimals", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 8, name: "slippage_percent", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 9, name: "gas_on_destination_chain_wei", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "from_account", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "to_account", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BungeeBridgeQuoteRequest {
    return new BungeeBridgeQuoteRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BungeeBridgeQuoteRequest {
    return new BungeeBridgeQuoteRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BungeeBridgeQuoteRequest {
    return new BungeeBridgeQuoteRequest().fromJsonString(jsonString, options);
  }

  static equals(a: BungeeBridgeQuoteRequest | PlainMessage<BungeeBridgeQuoteRequest> | undefined, b: BungeeBridgeQuoteRequest | PlainMessage<BungeeBridgeQuoteRequest> | undefined): boolean {
    return proto3.util.equals(BungeeBridgeQuoteRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.BungeeBridgeQuote
 */
export class BungeeBridgeQuote extends Message<BungeeBridgeQuote> {
  /**
   * @generated from field: int32 estTimeSeconds = 1;
   */
  estTimeSeconds = 0;

  /**
   * @generated from field: int32 fromChainId = 2;
   */
  fromChainId = 0;

  /**
   * @generated from field: string fromAmountWei = 3;
   */
  fromAmountWei = "";

  /**
   * @generated from field: int32 fromDecimals = 4;
   */
  fromDecimals = 0;

  /**
   * @generated from field: string fromTokenSymbol = 5;
   */
  fromTokenSymbol = "";

  /**
   * @generated from field: float fromAmountUsd = 6;
   */
  fromAmountUsd = 0;

  /**
   * @generated from field: float gasCostUsd = 7;
   */
  gasCostUsd = 0;

  /**
   * @generated from field: string rateWei = 8;
   */
  rateWei = "";

  /**
   * @generated from field: string toAmountWei = 9;
   */
  toAmountWei = "";

  /**
   * @generated from field: float toAmountUsd = 10;
   */
  toAmountUsd = 0;

  /**
   * @generated from field: int32 toChainId = 11;
   */
  toChainId = 0;

  /**
   * @generated from field: int32 toDecimals = 12;
   */
  toDecimals = 0;

  /**
   * @generated from field: string toTokenSymbol = 13;
   */
  toTokenSymbol = "";

  /**
   * @generated from field: string approveAddress = 14;
   */
  approveAddress = "";

  constructor(data?: PartialMessage<BungeeBridgeQuote>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.BungeeBridgeQuote";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "estTimeSeconds", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "fromChainId", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "fromAmountWei", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "fromDecimals", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "fromTokenSymbol", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "fromAmountUsd", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 7, name: "gasCostUsd", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 8, name: "rateWei", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "toAmountWei", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "toAmountUsd", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 11, name: "toChainId", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 12, name: "toDecimals", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 13, name: "toTokenSymbol", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "approveAddress", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BungeeBridgeQuote {
    return new BungeeBridgeQuote().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BungeeBridgeQuote {
    return new BungeeBridgeQuote().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BungeeBridgeQuote {
    return new BungeeBridgeQuote().fromJsonString(jsonString, options);
  }

  static equals(a: BungeeBridgeQuote | PlainMessage<BungeeBridgeQuote> | undefined, b: BungeeBridgeQuote | PlainMessage<BungeeBridgeQuote> | undefined): boolean {
    return proto3.util.equals(BungeeBridgeQuote, a, b);
  }
}

/**
 * @generated from message swell.v3.BungeeBridgePreparedTransaction
 */
export class BungeeBridgePreparedTransaction extends Message<BungeeBridgePreparedTransaction> {
  /**
   * @generated from field: uint32 chainId = 1;
   */
  chainId = 0;

  /**
   * @generated from field: string to = 2;
   */
  to = "";

  /**
   * @generated from field: string from = 3;
   */
  from = "";

  /**
   * @generated from field: string gasLimitWei = 4;
   */
  gasLimitWei = "";

  /**
   * @generated from field: string data = 5;
   */
  data = "";

  /**
   * @generated from field: string value = 6;
   */
  value = "";

  constructor(data?: PartialMessage<BungeeBridgePreparedTransaction>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.BungeeBridgePreparedTransaction";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "chainId", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "to", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "gasLimitWei", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "data", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BungeeBridgePreparedTransaction {
    return new BungeeBridgePreparedTransaction().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BungeeBridgePreparedTransaction {
    return new BungeeBridgePreparedTransaction().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BungeeBridgePreparedTransaction {
    return new BungeeBridgePreparedTransaction().fromJsonString(jsonString, options);
  }

  static equals(a: BungeeBridgePreparedTransaction | PlainMessage<BungeeBridgePreparedTransaction> | undefined, b: BungeeBridgePreparedTransaction | PlainMessage<BungeeBridgePreparedTransaction> | undefined): boolean {
    return proto3.util.equals(BungeeBridgePreparedTransaction, a, b);
  }
}

/**
 * @generated from message swell.v3.BungeeBridgeQuoteResponse
 */
export class BungeeBridgeQuoteResponse extends Message<BungeeBridgeQuoteResponse> {
  /**
   * @generated from field: bool found = 1;
   */
  found = false;

  /**
   * @generated from field: swell.v3.BungeeBridgeQuote quote = 2;
   */
  quote?: BungeeBridgeQuote;

  /**
   * @generated from field: swell.v3.BungeeBridgePreparedTransaction preparedTransaction = 3;
   */
  preparedTransaction?: BungeeBridgePreparedTransaction;

  constructor(data?: PartialMessage<BungeeBridgeQuoteResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.BungeeBridgeQuoteResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "found", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "quote", kind: "message", T: BungeeBridgeQuote },
    { no: 3, name: "preparedTransaction", kind: "message", T: BungeeBridgePreparedTransaction },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BungeeBridgeQuoteResponse {
    return new BungeeBridgeQuoteResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BungeeBridgeQuoteResponse {
    return new BungeeBridgeQuoteResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BungeeBridgeQuoteResponse {
    return new BungeeBridgeQuoteResponse().fromJsonString(jsonString, options);
  }

  static equals(a: BungeeBridgeQuoteResponse | PlainMessage<BungeeBridgeQuoteResponse> | undefined, b: BungeeBridgeQuoteResponse | PlainMessage<BungeeBridgeQuoteResponse> | undefined): boolean {
    return proto3.util.equals(BungeeBridgeQuoteResponse, a, b);
  }
}

/**
 * @generated from message swell.v3.BungeeBridgeTxState
 */
export class BungeeBridgeTxState extends Message<BungeeBridgeTxState> {
  /**
   * @generated from field: string tx_hash = 1;
   */
  txHash = "";

  /**
   * @generated from field: string account = 2;
   */
  account = "";

  /**
   * @generated from field: int32 timestampUnix = 3;
   */
  timestampUnix = 0;

  /**
   * @generated from field: int32 fromChainId = 4;
   */
  fromChainId = 0;

  /**
   * @generated from field: int32 toChainId = 5;
   */
  toChainId = 0;

  /**
   * @generated from field: string fromTokenSymbol = 6;
   */
  fromTokenSymbol = "";

  /**
   * @generated from field: string toTokenSymbol = 7;
   */
  toTokenSymbol = "";

  /**
   * @generated from field: string fromAmountWei = 8;
   */
  fromAmountWei = "";

  /**
   * @generated from field: string toAmountWei = 9;
   */
  toAmountWei = "";

  /**
   * @generated from field: int32 fromDecimals = 10;
   */
  fromDecimals = 0;

  /**
   * @generated from field: int32 toDecimals = 11;
   */
  toDecimals = 0;

  /**
   * @generated from field: string error = 12;
   */
  error = "";

  /**
   * @generated from field: swell.v3.BungeeStatus status = 13;
   */
  status = BungeeStatus.UNKNOWN;

  /**
   * @generated from field: string url = 14;
   */
  url = "";

  constructor(data?: PartialMessage<BungeeBridgeTxState>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.BungeeBridgeTxState";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "tx_hash", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "account", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "timestampUnix", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "fromChainId", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "toChainId", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "fromTokenSymbol", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "toTokenSymbol", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "fromAmountWei", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "toAmountWei", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "fromDecimals", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 11, name: "toDecimals", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 12, name: "error", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "status", kind: "enum", T: proto3.getEnumType(BungeeStatus) },
    { no: 14, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BungeeBridgeTxState {
    return new BungeeBridgeTxState().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BungeeBridgeTxState {
    return new BungeeBridgeTxState().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BungeeBridgeTxState {
    return new BungeeBridgeTxState().fromJsonString(jsonString, options);
  }

  static equals(a: BungeeBridgeTxState | PlainMessage<BungeeBridgeTxState> | undefined, b: BungeeBridgeTxState | PlainMessage<BungeeBridgeTxState> | undefined): boolean {
    return proto3.util.equals(BungeeBridgeTxState, a, b);
  }
}

/**
 * @generated from message swell.v3.BungeeTxKey
 */
export class BungeeTxKey extends Message<BungeeTxKey> {
  /**
   * @generated from field: string tx_hash = 1;
   */
  txHash = "";

  /**
   * @generated from field: int32 from_chain_id = 2;
   */
  fromChainId = 0;

  constructor(data?: PartialMessage<BungeeTxKey>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.BungeeTxKey";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "tx_hash", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "from_chain_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BungeeTxKey {
    return new BungeeTxKey().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BungeeTxKey {
    return new BungeeTxKey().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BungeeTxKey {
    return new BungeeTxKey().fromJsonString(jsonString, options);
  }

  static equals(a: BungeeTxKey | PlainMessage<BungeeTxKey> | undefined, b: BungeeTxKey | PlainMessage<BungeeTxKey> | undefined): boolean {
    return proto3.util.equals(BungeeTxKey, a, b);
  }
}

/**
 * @generated from message swell.v3.BungeeBridgeTxStatusRequest
 */
export class BungeeBridgeTxStatusRequest extends Message<BungeeBridgeTxStatusRequest> {
  /**
   * @generated from field: repeated swell.v3.BungeeTxKey tx_keys = 1;
   */
  txKeys: BungeeTxKey[] = [];

  constructor(data?: PartialMessage<BungeeBridgeTxStatusRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.BungeeBridgeTxStatusRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "tx_keys", kind: "message", T: BungeeTxKey, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BungeeBridgeTxStatusRequest {
    return new BungeeBridgeTxStatusRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BungeeBridgeTxStatusRequest {
    return new BungeeBridgeTxStatusRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BungeeBridgeTxStatusRequest {
    return new BungeeBridgeTxStatusRequest().fromJsonString(jsonString, options);
  }

  static equals(a: BungeeBridgeTxStatusRequest | PlainMessage<BungeeBridgeTxStatusRequest> | undefined, b: BungeeBridgeTxStatusRequest | PlainMessage<BungeeBridgeTxStatusRequest> | undefined): boolean {
    return proto3.util.equals(BungeeBridgeTxStatusRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.BungeeBridgeTxStatusResponse
 */
export class BungeeBridgeTxStatusResponse extends Message<BungeeBridgeTxStatusResponse> {
  /**
   * @generated from field: repeated swell.v3.BungeeBridgeTxState states = 1;
   */
  states: BungeeBridgeTxState[] = [];

  constructor(data?: PartialMessage<BungeeBridgeTxStatusResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.BungeeBridgeTxStatusResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "states", kind: "message", T: BungeeBridgeTxState, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BungeeBridgeTxStatusResponse {
    return new BungeeBridgeTxStatusResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BungeeBridgeTxStatusResponse {
    return new BungeeBridgeTxStatusResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BungeeBridgeTxStatusResponse {
    return new BungeeBridgeTxStatusResponse().fromJsonString(jsonString, options);
  }

  static equals(a: BungeeBridgeTxStatusResponse | PlainMessage<BungeeBridgeTxStatusResponse> | undefined, b: BungeeBridgeTxStatusResponse | PlainMessage<BungeeBridgeTxStatusResponse> | undefined): boolean {
    return proto3.util.equals(BungeeBridgeTxStatusResponse, a, b);
  }
}

