// @generated by protoc-gen-es v1.3.3 with parameter "target=ts"
// @generated from file swell/v3/monitoring.proto (package swell.v3, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message swell.v3.RpcMonitorRequest
 */
export class RpcMonitorRequest extends Message<RpcMonitorRequest> {
  /**
   * @generated from field: string action = 1;
   */
  action = "";

  /**
   * @generated from field: string request = 2;
   */
  request = "";

  /**
   * @generated from field: float duration_ms = 3;
   */
  durationMs = 0;

  /**
   * @generated from field: string url = 4;
   */
  url = "";

  /**
   * @generated from field: string window_location = 5;
   */
  windowLocation = "";

  /**
   * @generated from field: string device_type = 6;
   */
  deviceType = "";

  /**
   * @generated from field: string account = 7;
   */
  account = "";

  /**
   * @generated from field: string provider_type = 8;
   */
  providerType = "";

  /**
   * @generated from field: int32 chain_id = 9;
   */
  chainId = 0;

  constructor(data?: PartialMessage<RpcMonitorRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.RpcMonitorRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "action", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "request", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "duration_ms", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 4, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "window_location", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "device_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "account", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "provider_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "chain_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RpcMonitorRequest {
    return new RpcMonitorRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RpcMonitorRequest {
    return new RpcMonitorRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RpcMonitorRequest {
    return new RpcMonitorRequest().fromJsonString(jsonString, options);
  }

  static equals(a: RpcMonitorRequest | PlainMessage<RpcMonitorRequest> | undefined, b: RpcMonitorRequest | PlainMessage<RpcMonitorRequest> | undefined): boolean {
    return proto3.util.equals(RpcMonitorRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.RpcMonitorResponse
 */
export class RpcMonitorResponse extends Message<RpcMonitorResponse> {
  constructor(data?: PartialMessage<RpcMonitorResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.RpcMonitorResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RpcMonitorResponse {
    return new RpcMonitorResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RpcMonitorResponse {
    return new RpcMonitorResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RpcMonitorResponse {
    return new RpcMonitorResponse().fromJsonString(jsonString, options);
  }

  static equals(a: RpcMonitorResponse | PlainMessage<RpcMonitorResponse> | undefined, b: RpcMonitorResponse | PlainMessage<RpcMonitorResponse> | undefined): boolean {
    return proto3.util.equals(RpcMonitorResponse, a, b);
  }
}

