import ms from 'ms.macro'
import { useNowMs, useNowMsAnimation } from './useTimeCountdown'
import { useRef } from 'react'

export function useSin({
  amplitude,
  frequency,
}: {
  amplitude?: number
  frequency?: number
} = {}) {
  const nowMs = useNowMsAnimation()

  const t = nowMs / 1000
  const a = amplitude || 1
  const f = frequency || 1

  return a * Math.sin(2 * Math.PI * f * t)
}

export function useRefreshRate({
  rate = ms`1s`,
}: {
  rate?: number
} = {}) {
  const nowMs = useNowMsAnimation()
  return Math.floor(nowMs / rate)
}

export function useTimeSinceMount() {
  const mountedAt = useRef(Date.now())
  const nowMs = useNowMs()
  return nowMs - mountedAt.current
}

//   const nowMs = useNowMsAnimation()
//   return nowMs
// }
