import { NucleusVaultCalls } from '@/state/nucleusVault/hooks'
import { PredepositCalls } from '@/state/predeposit/hooks'
import {
  BridgeProvider,
  CrossChainBridgeProvider,
} from '@/types/crossChainBridge'
import { Token } from '@/types/tokens'
import { BigNumber } from 'ethers'

export type SwellchainStatsSummary = {
  tvl: string
}

export const L2PageView = {
  withdraw: 'withdraw' as const,
  superbridge: 'superbridge' as const,
  custombridge: 'custombridge' as const,
}
export type L2PageView = (typeof L2PageView)[keyof typeof L2PageView]

export const CustomBridgeSubView = {
  bridge: 'bridge' as const,
  gas: 'gas' as const,
  history: 'history' as const,
}
export type CustomBridgeSubView =
  (typeof CustomBridgeSubView)[keyof typeof CustomBridgeSubView]

export type PredepositInputs = {
  setWithdrawAmountInput: (amount: string) => void
  withdrawAmountInput: string
  withdrawAmount: BigNumber | undefined

  selectWithdrawToken: (symbol: string) => void
  withdrawTokenAddress: string
  withdrawToken: Token | undefined
}

export type EarnEthWithdrawAndDepositPhase =
  | 'unknown'
  | 'withdraw'
  | 'approve'
  | 'deposit'
export type EarnEthWithdrawAndDepositProgress = {
  numSteps: number
  currentStepIndex: number
}

export type PredepositWithdrawInitiator = 'withdraw' | 'withdraw-and-deposit'

export type PredepositWithdrawController = {
  phase: EarnEthWithdrawAndDepositPhase
  progress: EarnEthWithdrawAndDepositProgress | undefined
  initiator: PredepositWithdrawInitiator | undefined
  isEarnEthAsset: boolean
  predepositCalls: Pick<PredepositCalls, 'withdrawFromPredeposit'>
  predepositCallsWithNucleus: Pick<PredepositCalls, 'withdrawFromPredeposit'>
  nucleusCalls: Pick<NucleusVaultCalls, 'approveAssetForDeposit' | 'deposit'>
}

export type BridgeInputs = {
  setFromAmountInput: (amount: string) => void
  fromAmountInput: string
  fromAmount: BigNumber | undefined

  setSlippagePercentInput: (percent: number) => void
  slippagePercent: number | undefined

  setGasOnDestinationChainInput: (amount: string) => void
  gasOnDestinationChain: BigNumber | undefined
  gasOnDestinationChainInput: string

  selectFromToken: (symbol: string) => void
  selectToToken: (symbol: string) => void
  fromTokenSymbol: string
  toTokenSymbol: string

  setForAccount: (account: string) => void
  forAccount: string | undefined
  forAccountInput: string

  selectedBridgeProvider: BridgeProvider | undefined
  selectBridgeProvider: (provider: BridgeProvider) => void
}

export type PredepositTokenSummary = Omit<Token, 'decimals' | 'balance'> & {
  staked: string | undefined
  isEarnEthAsset: boolean
}
export type PredepositTokensSummary = PredepositTokenSummary[]

export type QuoteSummary = {
  usdFrom: string
  usdTo: string
  rate: string
  gasFee: string
  estTime: string
  send: string
  receive: string
}

export type QuoteSummaryResult =
  | {
      status: 'thinking' | 'found' | 'idle'
      summary: QuoteSummary
    }
  | {
      status: 'no-route'
      summary?: undefined
    }

export type BridgeUserSummary = {
  available: string
  availableSymbol: string
  fromTokenSymbolToBalance: Record<string, string>
}

export type BridgeTxSummary = {
  key: string
  providerName: string
  providerLogoURI: string
  providerLogoShape: 'circle' | 'square'
  date: string
  timestampMs: number // for sorting
  status: 'in-progress' | 'completed' | 'failed' | 'unknown'
  url: string
  fromAmount: string
  fromTokenSymbol: string
  toAmount: string
  toTokenSymbol: string
}

export type BridgeHistorySummary = {
  txs: BridgeTxSummary[]
}

export type OtherProviderSummary = {
  rate: string
  gasCost: string
  estTime: string
}
export type BridgeProviderSummaryResult = {
  provider: CrossChainBridgeProvider
  providerName: string
  providerLogoURI: string
  status: 'thinking' | 'found' | 'no-route'
  summary: OtherProviderSummary
}

export type BridgeProvidersSummary = {
  providerResults: BridgeProviderSummaryResult[]
}

export type PredepositWithdrawalSummary = {
  valueUsd: string
  gasCost: string
  available: string
}

export type GlobalBridgeEventSummary = {
  url: string
  timeAgo: string
  timestampMs: number
  amount: string
  symbol: string
  account: string
}
export type GlobalBridgeEventsSummary = {
  events: GlobalBridgeEventSummary[]
}

export type SwellchainIncentivesSummary = {
  amount: string
  targetDateMs: number
  scrambleClock: boolean
}
