import { ClaimMerkl, UnlockWSwell } from '@/state/wswell/hooks'
import { EVKUser, UserEVKLockWithBalances } from '@/types/eulerEVK'
import { MerklUserWithProofAndAmount } from '@/types/merkl'

export const WSwellErrors = {
  LockNotFound: 'Lock not found',
  InvalidProofs: 'Invalid proofs',
  NoUnclaimedAmount: 'No unclaimed amount',
}

type ValidatedArgs<T> =
  | {
      args?: undefined
      error: string | null
    }
  | {
      args: T
      error?: undefined
    }

export function prepareClaimWSwell({
  merklUser,
}: {
  merklUser:
    | Pick<MerklUserWithProofAndAmount, 'proofsHex' | 'cumulativeAmount'>
    | undefined
}): ValidatedArgs<Parameters<ClaimMerkl['call']>> {
  if (!merklUser) {
    return {
      error: null,
    }
  }
  const { proofsHex, cumulativeAmount } = merklUser
  if (proofsHex?.length === 0) {
    return { error: WSwellErrors.InvalidProofs }
  }
  if (cumulativeAmount.isZero()) {
    return { error: WSwellErrors.NoUnclaimedAmount }
  }

  return { args: [{ proofsHex, cumulativeAmount }] }
}
export type PreparedClaimWSwell = ReturnType<typeof prepareClaimWSwell>

export function prepareUnlockWSwell({
  lockTimestampUnix,
  userLocks,
}: {
  lockTimestampUnix: number
  userLocks:
    | Pick<
        UserEVKLockWithBalances,
        'lockTimestampUnix' | 'unlockablePercentNum'
      >[]
    | undefined
}): ValidatedArgs<Parameters<UnlockWSwell['call']>> {
  if (!userLocks) {
    return {
      error: null,
    }
  }
  const lock = userLocks.find(
    (lock) => lock.lockTimestampUnix === lockTimestampUnix
  )
  if (!lock) {
    return { error: WSwellErrors.LockNotFound }
  }
  if (lock.unlockablePercentNum < 0 || lock.unlockablePercentNum > 100) {
    return { error: 'Invalid unlockablePercentNum' }
  }
  const mustUnlockAll = lock.unlockablePercentNum === 100

  return { args: [{ lockTimestampUnix, mustUnlockAll }] }
}
export type PreparedUnlockWSwell = ReturnType<typeof prepareUnlockWSwell>

export function prepareAllUnlockWSwell({
  evkUser,
}: {
  evkUser: EVKUser | undefined
}): Record<number, PreparedUnlockWSwell> {
  if (!evkUser) {
    return {}
  }
  const mapping: Record<number, PreparedUnlockWSwell> = {}
  for (const lock of evkUser.locks) {
    mapping[lock.lockTimestampUnix] = prepareUnlockWSwell({
      lockTimestampUnix: lock.lockTimestampUnix,
      userLocks: evkUser.locks,
    })
  }
  return mapping
}
