// @generated by protoc-gen-es v1.3.3 with parameter "target=ts"
// @generated from file swell/v3/stats.proto (package swell.v3, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";

/**
 * @generated from message swell.v3.StatsServiceAllRequest
 */
export class StatsServiceAllRequest extends Message<StatsServiceAllRequest> {
  constructor(data?: PartialMessage<StatsServiceAllRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.StatsServiceAllRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StatsServiceAllRequest {
    return new StatsServiceAllRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StatsServiceAllRequest {
    return new StatsServiceAllRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StatsServiceAllRequest {
    return new StatsServiceAllRequest().fromJsonString(jsonString, options);
  }

  static equals(a: StatsServiceAllRequest | PlainMessage<StatsServiceAllRequest> | undefined, b: StatsServiceAllRequest | PlainMessage<StatsServiceAllRequest> | undefined): boolean {
    return proto3.util.equals(StatsServiceAllRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.StatsServiceAllResponse
 */
export class StatsServiceAllResponse extends Message<StatsServiceAllResponse> {
  /**
   * @generated from field: string staking_apr_percent = 1;
   */
  stakingAprPercent = "";

  /**
   * @generated from field: string staker_count_users = 2;
   */
  stakerCountUsers = "";

  /**
   * @generated from field: string total_eth_staked_wei = 3;
   */
  totalEthStakedWei = "";

  /**
   * @generated from field: string sw_eth_market_cap_cents = 4;
   */
  swEthMarketCapCents = "";

  /**
   * @generated from field: string suggested_gas_price_wei = 5;
   */
  suggestedGasPriceWei = "";

  /**
   * @generated from field: string node_operator_reward_percentage = 6;
   */
  nodeOperatorRewardPercentage = "";

  /**
   * @generated from field: string swell_treasury_reward_percentage = 7;
   */
  swellTreasuryRewardPercentage = "";

  constructor(data?: PartialMessage<StatsServiceAllResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.StatsServiceAllResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "staking_apr_percent", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "staker_count_users", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "total_eth_staked_wei", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "sw_eth_market_cap_cents", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "suggested_gas_price_wei", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "node_operator_reward_percentage", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "swell_treasury_reward_percentage", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StatsServiceAllResponse {
    return new StatsServiceAllResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StatsServiceAllResponse {
    return new StatsServiceAllResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StatsServiceAllResponse {
    return new StatsServiceAllResponse().fromJsonString(jsonString, options);
  }

  static equals(a: StatsServiceAllResponse | PlainMessage<StatsServiceAllResponse> | undefined, b: StatsServiceAllResponse | PlainMessage<StatsServiceAllResponse> | undefined): boolean {
    return proto3.util.equals(StatsServiceAllResponse, a, b);
  }
}

/**
 * @generated from message swell.v3.RemainingEigenlayerSwethRequest
 */
export class RemainingEigenlayerSwethRequest extends Message<RemainingEigenlayerSwethRequest> {
  constructor(data?: PartialMessage<RemainingEigenlayerSwethRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.RemainingEigenlayerSwethRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RemainingEigenlayerSwethRequest {
    return new RemainingEigenlayerSwethRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RemainingEigenlayerSwethRequest {
    return new RemainingEigenlayerSwethRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RemainingEigenlayerSwethRequest {
    return new RemainingEigenlayerSwethRequest().fromJsonString(jsonString, options);
  }

  static equals(a: RemainingEigenlayerSwethRequest | PlainMessage<RemainingEigenlayerSwethRequest> | undefined, b: RemainingEigenlayerSwethRequest | PlainMessage<RemainingEigenlayerSwethRequest> | undefined): boolean {
    return proto3.util.equals(RemainingEigenlayerSwethRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.RemainingEigenlayerSwethResponse
 */
export class RemainingEigenlayerSwethResponse extends Message<RemainingEigenlayerSwethResponse> {
  /**
   * @generated from field: string remaining_eigenlayer_sweth = 1;
   */
  remainingEigenlayerSweth = "";

  constructor(data?: PartialMessage<RemainingEigenlayerSwethResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.RemainingEigenlayerSwethResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "remaining_eigenlayer_sweth", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RemainingEigenlayerSwethResponse {
    return new RemainingEigenlayerSwethResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RemainingEigenlayerSwethResponse {
    return new RemainingEigenlayerSwethResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RemainingEigenlayerSwethResponse {
    return new RemainingEigenlayerSwethResponse().fromJsonString(jsonString, options);
  }

  static equals(a: RemainingEigenlayerSwethResponse | PlainMessage<RemainingEigenlayerSwethResponse> | undefined, b: RemainingEigenlayerSwethResponse | PlainMessage<RemainingEigenlayerSwethResponse> | undefined): boolean {
    return proto3.util.equals(RemainingEigenlayerSwethResponse, a, b);
  }
}

/**
 * @generated from message swell.v3.EarnAPYRewardAsset
 */
export class EarnAPYRewardAsset extends Message<EarnAPYRewardAsset> {
  /**
   * @generated from field: string asset_id = 1;
   */
  assetId = "";

  /**
   * @generated from field: string asset_name = 2;
   */
  assetName = "";

  /**
   * @generated from field: string logo_uri = 3;
   */
  logoUri = "";

  /**
   * @generated from field: bool square_logo = 4;
   */
  squareLogo = false;

  constructor(data?: PartialMessage<EarnAPYRewardAsset>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.EarnAPYRewardAsset";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "asset_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "asset_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "logo_uri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "square_logo", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EarnAPYRewardAsset {
    return new EarnAPYRewardAsset().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EarnAPYRewardAsset {
    return new EarnAPYRewardAsset().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EarnAPYRewardAsset {
    return new EarnAPYRewardAsset().fromJsonString(jsonString, options);
  }

  static equals(a: EarnAPYRewardAsset | PlainMessage<EarnAPYRewardAsset> | undefined, b: EarnAPYRewardAsset | PlainMessage<EarnAPYRewardAsset> | undefined): boolean {
    return proto3.util.equals(EarnAPYRewardAsset, a, b);
  }
}

/**
 * @generated from message swell.v3.EarnAPY
 */
export class EarnAPY extends Message<EarnAPY> {
  /**
   * @generated from field: int32 id = 1;
   */
  id = 0;

  /**
   * @generated from field: string protocol = 2;
   */
  protocol = "";

  /**
   * @generated from field: string logo = 3;
   */
  logo = "";

  /**
   * @generated from field: int32 chain_id = 4;
   */
  chainId = 0;

  /**
   * @generated from field: string chain_logo = 5;
   */
  chainLogo = "";

  /**
   * @generated from field: string chain_name = 6;
   */
  chainName = "";

  /**
   * @generated from field: string position_name = 7;
   */
  positionName = "";

  /**
   * @generated from field: repeated string position_tokens = 8;
   */
  positionTokens: string[] = [];

  /**
   * @generated from field: repeated string token_icon_list = 9;
   */
  tokenIconList: string[] = [];

  /**
   * @generated from field: string category = 10;
   */
  category = "";

  /**
   * @generated from field: float tvl = 11;
   */
  tvl = 0;

  /**
   * @generated from field: repeated float apy = 12;
   */
  apy: number[] = [];

  /**
   * @generated from field: string link = 13;
   */
  link = "";

  /**
   * @generated from field: bool is_visible = 14;
   */
  isVisible = false;

  /**
   * @generated from field: bool include_tvl = 15;
   */
  includeTvl = false;

  /**
   * deprecated
   *
   * @generated from field: float boost = 16;
   */
  boost = 0;

  /**
   * @generated from field: repeated string reward_asset_ids = 17;
   */
  rewardAssetIds: string[] = [];

  /**
   * @generated from field: repeated float swell_apr_percent = 18;
   */
  swellAprPercent: number[] = [];

  constructor(data?: PartialMessage<EarnAPY>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.EarnAPY";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "protocol", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "logo", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "chain_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "chain_logo", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "chain_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "position_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "position_tokens", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 9, name: "token_icon_list", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 10, name: "category", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "tvl", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 12, name: "apy", kind: "scalar", T: 2 /* ScalarType.FLOAT */, repeated: true },
    { no: 13, name: "link", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "is_visible", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 15, name: "include_tvl", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 16, name: "boost", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 17, name: "reward_asset_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 18, name: "swell_apr_percent", kind: "scalar", T: 2 /* ScalarType.FLOAT */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EarnAPY {
    return new EarnAPY().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EarnAPY {
    return new EarnAPY().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EarnAPY {
    return new EarnAPY().fromJsonString(jsonString, options);
  }

  static equals(a: EarnAPY | PlainMessage<EarnAPY> | undefined, b: EarnAPY | PlainMessage<EarnAPY> | undefined): boolean {
    return proto3.util.equals(EarnAPY, a, b);
  }
}

/**
 * @generated from message swell.v3.EarnAPYsRequest
 */
export class EarnAPYsRequest extends Message<EarnAPYsRequest> {
  constructor(data?: PartialMessage<EarnAPYsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.EarnAPYsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EarnAPYsRequest {
    return new EarnAPYsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EarnAPYsRequest {
    return new EarnAPYsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EarnAPYsRequest {
    return new EarnAPYsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: EarnAPYsRequest | PlainMessage<EarnAPYsRequest> | undefined, b: EarnAPYsRequest | PlainMessage<EarnAPYsRequest> | undefined): boolean {
    return proto3.util.equals(EarnAPYsRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.EarnAPYsResponse
 */
export class EarnAPYsResponse extends Message<EarnAPYsResponse> {
  /**
   * @generated from field: repeated swell.v3.EarnAPY featured = 1;
   */
  featured: EarnAPY[] = [];

  /**
   * @generated from field: repeated swell.v3.EarnAPY top_apr = 2;
   */
  topApr: EarnAPY[] = [];

  /**
   * @generated from field: repeated swell.v3.EarnAPY available = 3;
   */
  available: EarnAPY[] = [];

  constructor(data?: PartialMessage<EarnAPYsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.EarnAPYsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "featured", kind: "message", T: EarnAPY, repeated: true },
    { no: 2, name: "top_apr", kind: "message", T: EarnAPY, repeated: true },
    { no: 3, name: "available", kind: "message", T: EarnAPY, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EarnAPYsResponse {
    return new EarnAPYsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EarnAPYsResponse {
    return new EarnAPYsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EarnAPYsResponse {
    return new EarnAPYsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: EarnAPYsResponse | PlainMessage<EarnAPYsResponse> | undefined, b: EarnAPYsResponse | PlainMessage<EarnAPYsResponse> | undefined): boolean {
    return proto3.util.equals(EarnAPYsResponse, a, b);
  }
}

/**
 * @generated from message swell.v3.StatsServiceBtcLrtRequest
 */
export class StatsServiceBtcLrtRequest extends Message<StatsServiceBtcLrtRequest> {
  constructor(data?: PartialMessage<StatsServiceBtcLrtRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.StatsServiceBtcLrtRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StatsServiceBtcLrtRequest {
    return new StatsServiceBtcLrtRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StatsServiceBtcLrtRequest {
    return new StatsServiceBtcLrtRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StatsServiceBtcLrtRequest {
    return new StatsServiceBtcLrtRequest().fromJsonString(jsonString, options);
  }

  static equals(a: StatsServiceBtcLrtRequest | PlainMessage<StatsServiceBtcLrtRequest> | undefined, b: StatsServiceBtcLrtRequest | PlainMessage<StatsServiceBtcLrtRequest> | undefined): boolean {
    return proto3.util.equals(StatsServiceBtcLrtRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.StatsServiceBtcLrtResponse
 */
export class StatsServiceBtcLrtResponse extends Message<StatsServiceBtcLrtResponse> {
  /**
   * @generated from field: string vault_apr_percent = 1;
   */
  vaultAprPercent = "";

  /**
   * @generated from field: string depositor_count_users = 2;
   */
  depositorCountUsers = "";

  /**
   * @generated from field: string tvl_usd = 3;
   */
  tvlUsd = "";

  /**
   * @generated from field: string swbtc_market_cap_cents = 4;
   */
  swbtcMarketCapCents = "";

  /**
   * @generated from field: string fees_percentage = 5;
   */
  feesPercentage = "";

  /**
   * @generated from field: float symbiotic_points_vault = 6;
   */
  symbioticPointsVault = 0;

  constructor(data?: PartialMessage<StatsServiceBtcLrtResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.StatsServiceBtcLrtResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "vault_apr_percent", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "depositor_count_users", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "tvl_usd", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "swbtc_market_cap_cents", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "fees_percentage", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "symbiotic_points_vault", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StatsServiceBtcLrtResponse {
    return new StatsServiceBtcLrtResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StatsServiceBtcLrtResponse {
    return new StatsServiceBtcLrtResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StatsServiceBtcLrtResponse {
    return new StatsServiceBtcLrtResponse().fromJsonString(jsonString, options);
  }

  static equals(a: StatsServiceBtcLrtResponse | PlainMessage<StatsServiceBtcLrtResponse> | undefined, b: StatsServiceBtcLrtResponse | PlainMessage<StatsServiceBtcLrtResponse> | undefined): boolean {
    return proto3.util.equals(StatsServiceBtcLrtResponse, a, b);
  }
}

/**
 * @generated from message swell.v3.StatsServiceRswellRequest
 */
export class StatsServiceRswellRequest extends Message<StatsServiceRswellRequest> {
  constructor(data?: PartialMessage<StatsServiceRswellRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.StatsServiceRswellRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StatsServiceRswellRequest {
    return new StatsServiceRswellRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StatsServiceRswellRequest {
    return new StatsServiceRswellRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StatsServiceRswellRequest {
    return new StatsServiceRswellRequest().fromJsonString(jsonString, options);
  }

  static equals(a: StatsServiceRswellRequest | PlainMessage<StatsServiceRswellRequest> | undefined, b: StatsServiceRswellRequest | PlainMessage<StatsServiceRswellRequest> | undefined): boolean {
    return proto3.util.equals(StatsServiceRswellRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.StatsServiceRswellResponse
 */
export class StatsServiceRswellResponse extends Message<StatsServiceRswellResponse> {
  /**
   * @generated from field: string vault_apr_percent = 1;
   */
  vaultAprPercent = "";

  /**
   * @generated from field: string depositor_count_users = 2;
   */
  depositorCountUsers = "";

  /**
   * @generated from field: string tvl_usd = 3;
   */
  tvlUsd = "";

  /**
   * @generated from field: string rswell_market_cap_cents = 4;
   */
  rswellMarketCapCents = "";

  /**
   * @generated from field: string fees_percentage = 5;
   */
  feesPercentage = "";

  /**
   * @generated from field: float symbiotic_points_vault = 6;
   */
  symbioticPointsVault = 0;

  constructor(data?: PartialMessage<StatsServiceRswellResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.StatsServiceRswellResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "vault_apr_percent", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "depositor_count_users", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "tvl_usd", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "rswell_market_cap_cents", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "fees_percentage", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "symbiotic_points_vault", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StatsServiceRswellResponse {
    return new StatsServiceRswellResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StatsServiceRswellResponse {
    return new StatsServiceRswellResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StatsServiceRswellResponse {
    return new StatsServiceRswellResponse().fromJsonString(jsonString, options);
  }

  static equals(a: StatsServiceRswellResponse | PlainMessage<StatsServiceRswellResponse> | undefined, b: StatsServiceRswellResponse | PlainMessage<StatsServiceRswellResponse> | undefined): boolean {
    return proto3.util.equals(StatsServiceRswellResponse, a, b);
  }
}

/**
 * @generated from message swell.v3.EarnVaultStatsRequest
 */
export class EarnVaultStatsRequest extends Message<EarnVaultStatsRequest> {
  constructor(data?: PartialMessage<EarnVaultStatsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.EarnVaultStatsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EarnVaultStatsRequest {
    return new EarnVaultStatsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EarnVaultStatsRequest {
    return new EarnVaultStatsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EarnVaultStatsRequest {
    return new EarnVaultStatsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: EarnVaultStatsRequest | PlainMessage<EarnVaultStatsRequest> | undefined, b: EarnVaultStatsRequest | PlainMessage<EarnVaultStatsRequest> | undefined): boolean {
    return proto3.util.equals(EarnVaultStatsRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.EarnVaultStatsResponse
 */
export class EarnVaultStatsResponse extends Message<EarnVaultStatsResponse> {
  /**
   * @generated from field: string vault_apr_percent = 1;
   */
  vaultAprPercent = "";

  /**
   * @generated from field: string depositor_count_users = 2;
   */
  depositorCountUsers = "";

  /**
   * @generated from field: string tvl_usd = 3;
   */
  tvlUsd = "";

  constructor(data?: PartialMessage<EarnVaultStatsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.EarnVaultStatsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "vault_apr_percent", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "depositor_count_users", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "tvl_usd", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EarnVaultStatsResponse {
    return new EarnVaultStatsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EarnVaultStatsResponse {
    return new EarnVaultStatsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EarnVaultStatsResponse {
    return new EarnVaultStatsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: EarnVaultStatsResponse | PlainMessage<EarnVaultStatsResponse> | undefined, b: EarnVaultStatsResponse | PlainMessage<EarnVaultStatsResponse> | undefined): boolean {
    return proto3.util.equals(EarnVaultStatsResponse, a, b);
  }
}

/**
 * @generated from message swell.v3.WavedropStatsRequest
 */
export class WavedropStatsRequest extends Message<WavedropStatsRequest> {
  constructor(data?: PartialMessage<WavedropStatsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.WavedropStatsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): WavedropStatsRequest {
    return new WavedropStatsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): WavedropStatsRequest {
    return new WavedropStatsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): WavedropStatsRequest {
    return new WavedropStatsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: WavedropStatsRequest | PlainMessage<WavedropStatsRequest> | undefined, b: WavedropStatsRequest | PlainMessage<WavedropStatsRequest> | undefined): boolean {
    return proto3.util.equals(WavedropStatsRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.WavedropStatsResponse
 */
export class WavedropStatsResponse extends Message<WavedropStatsResponse> {
  /**
   * @generated from field: int32 nextWavedropEndUnix = 1;
   */
  nextWavedropEndUnix = 0;

  /**
   * @generated from field: int32 currentWaveNumber = 2;
   */
  currentWaveNumber = 0;

  constructor(data?: PartialMessage<WavedropStatsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.WavedropStatsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "nextWavedropEndUnix", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "currentWaveNumber", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): WavedropStatsResponse {
    return new WavedropStatsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): WavedropStatsResponse {
    return new WavedropStatsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): WavedropStatsResponse {
    return new WavedropStatsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: WavedropStatsResponse | PlainMessage<WavedropStatsResponse> | undefined, b: WavedropStatsResponse | PlainMessage<WavedropStatsResponse> | undefined): boolean {
    return proto3.util.equals(WavedropStatsResponse, a, b);
  }
}

/**
 * @generated from message swell.v3.EarnStatsRequest
 */
export class EarnStatsRequest extends Message<EarnStatsRequest> {
  constructor(data?: PartialMessage<EarnStatsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.EarnStatsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EarnStatsRequest {
    return new EarnStatsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EarnStatsRequest {
    return new EarnStatsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EarnStatsRequest {
    return new EarnStatsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: EarnStatsRequest | PlainMessage<EarnStatsRequest> | undefined, b: EarnStatsRequest | PlainMessage<EarnStatsRequest> | undefined): boolean {
    return proto3.util.equals(EarnStatsRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.EarnStatsResponse
 */
export class EarnStatsResponse extends Message<EarnStatsResponse> {
  /**
   * @generated from field: int32 total_tvl_cents = 1;
   */
  totalTvlCents = 0;

  constructor(data?: PartialMessage<EarnStatsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.EarnStatsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "total_tvl_cents", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EarnStatsResponse {
    return new EarnStatsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EarnStatsResponse {
    return new EarnStatsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EarnStatsResponse {
    return new EarnStatsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: EarnStatsResponse | PlainMessage<EarnStatsResponse> | undefined, b: EarnStatsResponse | PlainMessage<EarnStatsResponse> | undefined): boolean {
    return proto3.util.equals(EarnStatsResponse, a, b);
  }
}

/**
 * @generated from message swell.v3.SuggestedGasPriceRequest
 */
export class SuggestedGasPriceRequest extends Message<SuggestedGasPriceRequest> {
  constructor(data?: PartialMessage<SuggestedGasPriceRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.SuggestedGasPriceRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SuggestedGasPriceRequest {
    return new SuggestedGasPriceRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SuggestedGasPriceRequest {
    return new SuggestedGasPriceRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SuggestedGasPriceRequest {
    return new SuggestedGasPriceRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SuggestedGasPriceRequest | PlainMessage<SuggestedGasPriceRequest> | undefined, b: SuggestedGasPriceRequest | PlainMessage<SuggestedGasPriceRequest> | undefined): boolean {
    return proto3.util.equals(SuggestedGasPriceRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.SuggestedGasPriceResponse
 */
export class SuggestedGasPriceResponse extends Message<SuggestedGasPriceResponse> {
  /**
   * @generated from field: string suggested_gas_price_wei = 1;
   */
  suggestedGasPriceWei = "";

  constructor(data?: PartialMessage<SuggestedGasPriceResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.SuggestedGasPriceResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "suggested_gas_price_wei", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SuggestedGasPriceResponse {
    return new SuggestedGasPriceResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SuggestedGasPriceResponse {
    return new SuggestedGasPriceResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SuggestedGasPriceResponse {
    return new SuggestedGasPriceResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SuggestedGasPriceResponse | PlainMessage<SuggestedGasPriceResponse> | undefined, b: SuggestedGasPriceResponse | PlainMessage<SuggestedGasPriceResponse> | undefined): boolean {
    return proto3.util.equals(SuggestedGasPriceResponse, a, b);
  }
}

/**
 * @generated from message swell.v3.PriceRequest
 */
export class PriceRequest extends Message<PriceRequest> {
  constructor(data?: PartialMessage<PriceRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.PriceRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PriceRequest {
    return new PriceRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PriceRequest {
    return new PriceRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PriceRequest {
    return new PriceRequest().fromJsonString(jsonString, options);
  }

  static equals(a: PriceRequest | PlainMessage<PriceRequest> | undefined, b: PriceRequest | PlainMessage<PriceRequest> | undefined): boolean {
    return proto3.util.equals(PriceRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.PriceResponse
 */
export class PriceResponse extends Message<PriceResponse> {
  /**
   * @generated from field: string eth_usd_price = 1;
   */
  ethUsdPrice = "";

  /**
   * @generated from field: string wbtc_usd_price = 2;
   */
  wbtcUsdPrice = "";

  /**
   * @generated from field: string swell_usd_price = 3;
   */
  swellUsdPrice = "";

  /**
   * @generated from field: string rswell_usd_price = 4;
   */
  rswellUsdPrice = "";

  constructor(data?: PartialMessage<PriceResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.PriceResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "eth_usd_price", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "wbtc_usd_price", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "swell_usd_price", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "rswell_usd_price", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PriceResponse {
    return new PriceResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PriceResponse {
    return new PriceResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PriceResponse {
    return new PriceResponse().fromJsonString(jsonString, options);
  }

  static equals(a: PriceResponse | PlainMessage<PriceResponse> | undefined, b: PriceResponse | PlainMessage<PriceResponse> | undefined): boolean {
    return proto3.util.equals(PriceResponse, a, b);
  }
}

/**
 * @generated from message swell.v3.BridgeEventsRequest
 */
export class BridgeEventsRequest extends Message<BridgeEventsRequest> {
  constructor(data?: PartialMessage<BridgeEventsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.BridgeEventsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BridgeEventsRequest {
    return new BridgeEventsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BridgeEventsRequest {
    return new BridgeEventsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BridgeEventsRequest {
    return new BridgeEventsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: BridgeEventsRequest | PlainMessage<BridgeEventsRequest> | undefined, b: BridgeEventsRequest | PlainMessage<BridgeEventsRequest> | undefined): boolean {
    return proto3.util.equals(BridgeEventsRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.BridgeEventsResponse
 */
export class BridgeEventsResponse extends Message<BridgeEventsResponse> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string wallet_address = 2;
   */
  walletAddress = "";

  /**
   * @generated from field: string amount_wei = 3;
   */
  amountWei = "";

  /**
   * @generated from field: string token_address = 4;
   */
  tokenAddress = "";

  /**
   * @generated from field: string transaction_hash = 5;
   */
  transactionHash = "";

  /**
   * @generated from field: int32 log_index = 6;
   */
  logIndex = 0;

  /**
   * @generated from field: int64 timestamp = 7;
   */
  timestamp = protoInt64.zero;

  constructor(data?: PartialMessage<BridgeEventsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.BridgeEventsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "wallet_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "amount_wei", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "token_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "transaction_hash", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "log_index", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "timestamp", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BridgeEventsResponse {
    return new BridgeEventsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BridgeEventsResponse {
    return new BridgeEventsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BridgeEventsResponse {
    return new BridgeEventsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: BridgeEventsResponse | PlainMessage<BridgeEventsResponse> | undefined, b: BridgeEventsResponse | PlainMessage<BridgeEventsResponse> | undefined): boolean {
    return proto3.util.equals(BridgeEventsResponse, a, b);
  }
}

/**
 * @generated from message swell.v3.PollingBridgeEventsRequest
 */
export class PollingBridgeEventsRequest extends Message<PollingBridgeEventsRequest> {
  /**
   * @generated from field: int32 num_events = 1;
   */
  numEvents = 0;

  constructor(data?: PartialMessage<PollingBridgeEventsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.PollingBridgeEventsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "num_events", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PollingBridgeEventsRequest {
    return new PollingBridgeEventsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PollingBridgeEventsRequest {
    return new PollingBridgeEventsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PollingBridgeEventsRequest {
    return new PollingBridgeEventsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: PollingBridgeEventsRequest | PlainMessage<PollingBridgeEventsRequest> | undefined, b: PollingBridgeEventsRequest | PlainMessage<PollingBridgeEventsRequest> | undefined): boolean {
    return proto3.util.equals(PollingBridgeEventsRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.PollingBridgeEventsResponse
 */
export class PollingBridgeEventsResponse extends Message<PollingBridgeEventsResponse> {
  /**
   * @generated from field: repeated swell.v3.PollingBridgeEvent events = 1;
   */
  events: PollingBridgeEvent[] = [];

  constructor(data?: PartialMessage<PollingBridgeEventsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.PollingBridgeEventsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "events", kind: "message", T: PollingBridgeEvent, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PollingBridgeEventsResponse {
    return new PollingBridgeEventsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PollingBridgeEventsResponse {
    return new PollingBridgeEventsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PollingBridgeEventsResponse {
    return new PollingBridgeEventsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: PollingBridgeEventsResponse | PlainMessage<PollingBridgeEventsResponse> | undefined, b: PollingBridgeEventsResponse | PlainMessage<PollingBridgeEventsResponse> | undefined): boolean {
    return proto3.util.equals(PollingBridgeEventsResponse, a, b);
  }
}

/**
 * @generated from message swell.v3.PollingBridgeEvent
 */
export class PollingBridgeEvent extends Message<PollingBridgeEvent> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string wallet_address = 2;
   */
  walletAddress = "";

  /**
   * @generated from field: string amount_wei = 3;
   */
  amountWei = "";

  /**
   * @generated from field: string token_address = 4;
   */
  tokenAddress = "";

  /**
   * @generated from field: string transaction_hash = 5;
   */
  transactionHash = "";

  /**
   * @generated from field: int32 log_index = 6;
   */
  logIndex = 0;

  /**
   * @generated from field: int64 timestamp = 7;
   */
  timestamp = protoInt64.zero;

  constructor(data?: PartialMessage<PollingBridgeEvent>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.PollingBridgeEvent";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "wallet_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "amount_wei", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "token_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "transaction_hash", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "log_index", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "timestamp", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PollingBridgeEvent {
    return new PollingBridgeEvent().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PollingBridgeEvent {
    return new PollingBridgeEvent().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PollingBridgeEvent {
    return new PollingBridgeEvent().fromJsonString(jsonString, options);
  }

  static equals(a: PollingBridgeEvent | PlainMessage<PollingBridgeEvent> | undefined, b: PollingBridgeEvent | PlainMessage<PollingBridgeEvent> | undefined): boolean {
    return proto3.util.equals(PollingBridgeEvent, a, b);
  }
}

/**
 * @generated from message swell.v3.L2StatsRequest
 */
export class L2StatsRequest extends Message<L2StatsRequest> {
  constructor(data?: PartialMessage<L2StatsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.L2StatsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): L2StatsRequest {
    return new L2StatsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): L2StatsRequest {
    return new L2StatsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): L2StatsRequest {
    return new L2StatsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: L2StatsRequest | PlainMessage<L2StatsRequest> | undefined, b: L2StatsRequest | PlainMessage<L2StatsRequest> | undefined): boolean {
    return proto3.util.equals(L2StatsRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.L2StatsResponse
 */
export class L2StatsResponse extends Message<L2StatsResponse> {
  /**
   * @generated from field: string tvl_cents = 1;
   */
  tvlCents = "";

  /**
   * @generated from field: int32 user_count = 2;
   */
  userCount = 0;

  constructor(data?: PartialMessage<L2StatsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.L2StatsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "tvl_cents", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "user_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): L2StatsResponse {
    return new L2StatsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): L2StatsResponse {
    return new L2StatsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): L2StatsResponse {
    return new L2StatsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: L2StatsResponse | PlainMessage<L2StatsResponse> | undefined, b: L2StatsResponse | PlainMessage<L2StatsResponse> | undefined): boolean {
    return proto3.util.equals(L2StatsResponse, a, b);
  }
}

