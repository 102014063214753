import useSWR from 'swr'
import { useL2EcosystemApi } from './context'

export function useL2EcosystemStats() {
  const api = useL2EcosystemApi()
  return useSWR('ecosystem-stats', api.stats)
}

export function useL2EcosystemProjects() {
  const api = useL2EcosystemApi()
  return useSWR('ecosystem-projects', api.projects)
}
