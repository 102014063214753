import { useDevGuiCallback, useDevGuiLabel } from '@/components/DevGui/hooks'
import { ROUTES } from '@/constants/routes'
import { useDeploymentSetConfig } from '@/state/deployments/hooks'
import { shortenHash } from '@/util/hexStrings'
import axios from 'axios'
import { useMemo } from 'react'
import { useLocation } from 'react-router'
import useSWR from 'swr'

type CacheStateResp = {
  meta: {
    ready: boolean
    hash: string
    last_updated_seconds_ago: number
    last_flushed_seconds_ago: number
  }
}

type CacheMeta = {
  ready: boolean
  hash: string
  lastUpdatedSecondsAgo: number
  lastFlushedSecondsAgo: number
}

class Fetcher {
  private baseUrl: string
  constructor(baseUrl: string) {
    this.baseUrl = baseUrl
  }
  private stateUrl = () => `${this.baseUrl}/api/earn`
  private clearCacheUrl = () => `${this.baseUrl}/api/earn`

  async getState() {
    const url = this.stateUrl()
    const res = await axios.get(url)
    const resp = res.data as CacheStateResp
    return {
      meta: {
        ready: resp.meta.ready,
        hash: resp.meta.hash,
        lastUpdatedSecondsAgo: resp.meta.last_updated_seconds_ago,
        lastFlushedSecondsAgo: resp.meta.last_flushed_seconds_ago,
      } as CacheMeta,
    }
  }

  async clearCache() {
    const url = this.clearCacheUrl()
    const res = await axios.delete(url)
    return res.data
  }
}

export function useDevGuiEarnCacheBuster() {
  const pathname = useLocation().pathname
  let showGui = false
  if (pathname.toLowerCase().includes(ROUTES.EarnBTC.toLowerCase())) {
    showGui = true
  } else if (pathname.toLowerCase().includes(ROUTES.EarnETH.toLowerCase())) {
    showGui = true
  } else if (
    pathname.toLowerCase().includes(ROUTES.EarnPortfolio.toLowerCase())
  ) {
    showGui = true
  }

  const { v3BackendLrtUrl } = useDeploymentSetConfig()
  const fetcher = useMemo(() => new Fetcher(v3BackendLrtUrl), [v3BackendLrtUrl])

  const cacheStateQuery = useSWR('earnCacheState', () => fetcher.getState(), {
    refreshInterval: 8_000,
    isPaused: () => !showGui,
  })
  const cacheState = cacheStateQuery.data

  let checksum = 'loading...'
  if (cacheState) {
    checksum = shortenHash(cacheState.meta.hash, 3, 4)
  }
  useDevGuiLabel({
    name: `Earn cache checksum: ${checksum}`,
    showGui,
  })

  let readyState = 'loading...'
  if (cacheState) {
    readyState = cacheState.meta.ready ? 'ready' : 'not ready'
  }

  useDevGuiLabel({
    name: `Earn cache: ${readyState}`,
    showGui,
  })

  let lastUpdatedStr = 'loading...'
  if (cacheState) {
    lastUpdatedStr = `${cacheState.meta.lastUpdatedSecondsAgo}s ago`
  }
  useDevGuiLabel({
    name: `Earn last updated: ${lastUpdatedStr}`,
    showGui,
  })

  let lastFlushedStr = 'loading...'
  if (cacheState) {
    lastFlushedStr = `${cacheState.meta.lastFlushedSecondsAgo}s ago`
  }
  useDevGuiLabel({
    name: `Earn last flushed: ${lastFlushedStr}`,
    showGui,
  })

  useDevGuiCallback({
    name: 'Clear earn cache (reload)',
    callback: () => {
      fetcher.clearCache().then(() => {
        cacheStateQuery.mutate()
        setTimeout(() => {
          window.location.reload()
        }, 1000)
      })
    },
    showGui,
  })
}
