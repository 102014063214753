import { UserCampaignsSummary } from './types'
import { ClaimLinkButton, EigenStakeDropClaimButton } from './PortfolioButtons'
import { FlexRow } from '@/swell-ui/FlexRow'
import { AccordionV2 } from '@/swell-ui/AccordionV2'
import { AsyncDiv } from '@/swell-ui/AsyncDiv'
import { PreparedClaimEigenStakedrop } from '@/components/ClaimEigenWidget/eigenStakedropCalls'
import { ClaimEigenStakedrop } from '@/state/rsweth/hooks'
import { EARN_CAMPAIGN_EIGEN } from '@/constants/earn'

export function PortfolioCampaignsCards({
  campaignsSummary,
  claimEigenStakedrop,
  preparedClaimEigenStakedrop,
}: {
  campaignsSummary: UserCampaignsSummary
  preparedClaimEigenStakedrop: PreparedClaimEigenStakedrop
  claimEigenStakedrop: ClaimEigenStakedrop
}) {
  return (
    <FlexRow direction="column" gap="12">
      {campaignsSummary.campaigns.map((campaign) => {
        return (
          <PointsCampaignAccordion
            key={campaign._campaignId}
            title={campaign.campaignName}
            pointsName={campaign.pointsName}
            logoURI={campaign.campaignLogoURI}
            points={campaign.points}
            claimable={campaign.claimable}
            link={campaign.claimURL}
            logoSquare={campaign.campaignSquareLogo}
            linkDisabled={campaign.showDisabledClaimLink}
            linkExternal={campaign.externalClaimLink}
            preparedClaimEigenStakedrop={preparedClaimEigenStakedrop}
            claimEigenStakedrop={claimEigenStakedrop}
            campaignName={campaign.campaignName}
          />
        )
      })}
    </FlexRow>
  )
}

function PointsCampaignAccordion({
  claimable,
  link,
  logoURI,
  points,
  pointsName,
  title,
  logoSquare,
  linkDisabled,
  linkExternal,
  campaignName,
  claimEigenStakedrop,
  preparedClaimEigenStakedrop,
}: {
  title: string
  pointsName: string
  logoURI: string
  logoSquare?: boolean
  points: string
  claimable: string
  link: string
  linkDisabled: boolean
  linkExternal: boolean
  campaignName: string
  preparedClaimEigenStakedrop: PreparedClaimEigenStakedrop
  claimEigenStakedrop: ClaimEigenStakedrop
}) {
  let claim = <ClaimLinkButton internal to={'#'} disabled label="N/A" />
  if (link) {
    if (linkExternal) {
      claim = (
        <ClaimLinkButton href={link} disabled={linkDisabled} internal={false} />
      )
    } else {
      claim = <ClaimLinkButton to={link} disabled={linkDisabled} internal />
    }
  } else {
    if (linkDisabled && linkExternal) {
      claim = <ClaimLinkButton internal={false} href={''} disabled />
    }
  }
  if (campaignName === EARN_CAMPAIGN_EIGEN.campaignName) {
    claim = (
      <EigenStakeDropClaimButton
        prepared={preparedClaimEigenStakedrop}
        claimEigenStakedrop={claimEigenStakedrop}
      />
    )
  }

  return (
    <AccordionV2
      summary={
        <FlexRow gap="12">
          <img
            src={logoURI}
            width="32"
            height="32"
            style={{ borderRadius: logoSquare ? '0' : '50%' }}
          />
          <span>{title}</span>
        </FlexRow>
      }
      details={
        <FlexRow direction="column" gap="16">
          <FlexRow direction="column" align="start">
            <span className="body-uppercase-small">Points</span>
            <span className="body-small">
              {points} {points === '-' ? null : pointsName}
            </span>
          </FlexRow>
          <FlexRow direction="column" align="start">
            <span className="body-uppercase-small">Claimable</span>
            {/* TODO: icon */}
            <AsyncDiv loading={!claimable}>
              {() => <span className="body-small">{claimable}</span>}
            </AsyncDiv>
          </FlexRow>
          {claim}
        </FlexRow>
      }
    />
  )
}
