import { FlexRow } from '@/swell-ui/FlexRow'
import styled from 'styled-components'
import { DecoBox } from '@/components/DecoBox'
import { Typography } from '@/swell-ui/Typography'
import { useSwellUiTheme } from '@/swell-ui/theme'
import { useMediaQuery } from '@mui/material'
import { WavedropCard } from '@/components/WavedropCard'
import {
  useClaimWavedrop,
  useWavedropMerkleContractState,
  useWavedrops,
  useWavedropUser,
} from '@/state/wavedrops/hooks'
import { prepareWavedropClaim } from '@/components/WavedropCard/wavedropCalls'
import {
  ClaimedWavedropButton,
  ClaimWavedropButton,
  ConnectWalletButton,
  PreviewClaimWavedropButton,
} from './buttons'
import { useWeb3CallErrorNotify } from '@/hooks/useWeb3CallErrorNotify'
import { useSwellWeb3 } from '@/swell-web3/core'
import { ClaimWavedropToast } from './toasts'
import { useSwellDaoToken } from '@/state/dao/hooks'
import { useEffect, useRef } from 'react'

const WAVEDROP_1_END = 1728352800 // Tuesday, 8 October 2024 02:00:00 UTC
const WAVEDROP_1_END_FORMATTED = '8th Oct. 2024'
const WAVEDROP_2_END = 1734400800 // Tuesday, 17 December 2024 02:00:00 UTC
const WAVEDROP_2_END_FORMATTED = '17th Dec. 2024'

export function SwellCity() {
  const { account } = useSwellWeb3()
  const { theme } = useSwellUiTheme()
  const daoToken = useSwellDaoToken()
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))
  const wavedropsQuery = useWavedrops()
  const merkleContractStateQuery = useWavedropMerkleContractState()
  const wavedropUserQuery = useWavedropUser()

  const wavedrops = wavedropsQuery.data?.wavedrops
  const merkleContractState = merkleContractStateQuery.data
  const wavedropUser = wavedropUserQuery.data

  const wavedrop1 = wavedrops?.find((w) => w.waveNumber === 1)
  const wavedrop2 = wavedrops?.find((w) => w.waveNumber === 2)

  const preparedWavedropClaim = prepareWavedropClaim({
    merkleContractState,
    wavedropUser,
    wavedrops,
  })

  const claimWavedrop = useClaimWavedrop()
  useWeb3CallErrorNotify(claimWavedrop)

  const noAccountButton = <ConnectWalletButton />
  const noClaimButton = <PreviewClaimWavedropButton />
  const claimWavedropButton = (
    <ClaimWavedropButton
      prepared={preparedWavedropClaim}
      claimWavedrop={claimWavedrop}
    />
  )
  const claimedWavedropButton = <ClaimedWavedropButton />
  const wavedropUserQueryMutate = wavedropUserQuery.mutate

  const claimedRef = useRef(false)
  useEffect(() => {
    if (claimedRef.current) {
      return
    }

    if (claimWavedrop.status === claimWavedrop.STATUS.FULFILLED) {
      claimedRef.current = true
      // explicitly no cleanup
      setTimeout(() => {
        wavedropUserQueryMutate()
      }, 5000)
    }
  }, [
    claimWavedrop.STATUS.FULFILLED,
    claimWavedrop.status,
    wavedropUserQueryMutate,
  ])

  const buttonIfNoAccount = !account ? noAccountButton : null

  let w1Button = buttonIfNoAccount ?? claimWavedropButton
  if (wavedropUser?.cumulativeClaimedW1?.gt(0)) {
    w1Button = claimedWavedropButton
  }
  if (wavedrop1?.daoTokenAmount?.eq(0)) {
    w1Button = noClaimButton
  }

  const w2Button = buttonIfNoAccount ?? claimWavedropButton

  return (
    <Layout
      align="center"
      direction="column"
      maxWidth={isMdUp ? '912px' : '433px'}
      gap="24"
    >
      <Typography
        variant="headline"
        xs="h3"
        md="h2"
        size="h2"
        fstyle="bold"
        letterSpacing="small"
      >
        Swell City
      </Typography>
      <span className="sub">🏄🏼 Ride the waves to boost your Wavedrops</span>
      <DecoBox>
        <p style={{ textAlign: 'center', margin: 0 }}>
          Wavedrop claims are now on Swellchain!
          <br />
          Please submit a support ticket before Feb 5 if you wish to claim on
          mainnet instead.
        </p>
      </DecoBox>
      <WavedropCard
        endTimeUnix={WAVEDROP_1_END}
        wavedrop={wavedrop1}
        ended={true}
        endTimeFormatted={WAVEDROP_1_END_FORMATTED}
        waveNumber={1}
        showMultiplier
        preparedWavedropClaim={preparedWavedropClaim}
        button={w1Button}
        isEcosystemDrop={false}
      />
      <WavedropCard
        endTimeUnix={WAVEDROP_2_END}
        wavedrop={wavedrop2}
        ended={true}
        endTimeFormatted={WAVEDROP_2_END_FORMATTED}
        waveNumber={2}
        showMultiplier
        isWip
        preparedWavedropClaim={preparedWavedropClaim}
        button={w2Button}
        isEcosystemDrop
      />
      <ClaimWavedropToast
        claimWavedrop={claimWavedrop}
        daoToken={daoToken}
        latestClaimableWavedrop={wavedrop2}
        wavedropUser={wavedropUser}
      />
    </Layout>
  )
}

const Layout = styled(FlexRow)`
  /* display: flex; */
  margin: 0 auto;
  padding-top: 60px;

  > .sub {
    color: var(--white-125, #b0b0b0);
    text-align: center;
    /* Body/large */
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 38.4px */
    letter-spacing: -0.72px;
  }

  .swc-label-1 {
    color: var(--white-50, #fff);
    text-align: center;
    /* Body/large */
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 38.4px */
    letter-spacing: -0.72px;
  }

  .swc-label-colored {
    color: var(--lightblue-50, #a4abf1);
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%; /* 13.2px */
    letter-spacing: 1.2px;
    text-transform: uppercase;
  }

  .info-icon {
    svg {
      position: relative;
      top: 2px;

      path {
        stroke: currentColor;
      }
    }
  }

  .swc-med-value {
    color: var(--white-50, #fff);
    text-align: center;
    /* Headlines/H6 */
    font-family: Inter;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 33.8px */
    letter-spacing: -0.78px;
  }

  // Make loader container shrink to fit content when not loading
  *[aria-busy='false'] {
    min-width: unset;
  }
`
