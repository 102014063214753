import { PredepositInputs } from './types'
import { WithdrawFromPredeposit } from '@/state/predeposit/hooks'
import { PredepositUser } from '@/state/predeposit/types'

export const PredepositErrors = {
  AmountMustBeGreaterThanZero: 'Amount must be greater than 0',
  InsufficientStakedBalance: 'Insufficient staked balance',
  WithdrawNotSupported: 'Withdraw not supported',
}
export const BridgeErrors = {
  AmountMustBeGreaterThanZero: 'Amount must be greater than 0',
  InsufficientBalance: 'Insufficient balance',
  InsufficientAllowance: 'Insufficient allowance',
  RouteNotFound: 'Route not found',
  StaleRoute: 'Route is stale',
  InvalidChain: 'Invalid chain',
}

type ValidatedArgs<T> =
  | {
      args?: never
      error: string | null
    }
  | {
      args: T
      error?: never
    }

export function prepareWithdrawFromPredeposit({
  predepositInputs,
  predepositUser,
}: {
  predepositUser: Pick<PredepositUser, 'stakes'> | undefined
  predepositInputs: Pick<PredepositInputs, 'withdrawAmount' | 'withdrawToken'>
}): ValidatedArgs<Parameters<WithdrawFromPredeposit['call']>> {
  if (!predepositUser) {
    return { error: null }
  }

  const { withdrawAmount, withdrawToken } = predepositInputs
  if (!withdrawAmount || !withdrawToken) {
    return { error: null }
  }

  const stakedBalance = predepositUser.stakes[withdrawToken.address]
  if (!stakedBalance) {
    return { error: 'Error fetching staked balance' }
  }

  if (withdrawAmount.gt(stakedBalance)) {
    return { error: PredepositErrors.InsufficientStakedBalance }
  }

  if (withdrawAmount.lte(0)) {
    return { error: PredepositErrors.AmountMustBeGreaterThanZero }
  }

  return {
    args: [{ amount: withdrawAmount, assetAddress: withdrawToken.address }],
  }
}
export type PreparedWithdrawFromPredeposit = ReturnType<
  typeof prepareWithdrawFromPredeposit
>
