import React, { useState } from 'react'
import styled from 'styled-components'
import { useSwellWeb3 } from '@swell-web3/core'
import { Button } from '@swell-ui/Button'
import { DownCaratIcon } from '@swell-ui/icons/DownCaratIcon'
import { FlexRow } from '@swell-ui/FlexRow'
import { Typography } from '@swell-ui/Typography'
import { useImportableTokens } from '@/hooks/tokens/useImportableTokens'
import { useVisibleTokens } from '@/hooks/tokens/useVisibleTokens'
import { Token } from '@/types/tokens'
import { TokenSelectModal } from './TokenSelectModal'
import { TokenSelectField } from './constants'
import { TokenLogoV2 } from '../TokenLogoV2'

const defaultFields = [TokenSelectField.Filter]

const TokenSelectButton = styled(Button)`
  padding: 0;
  height: auto;

  &:disabled {
    color: #fff;
  }
`

const TokenSelectCarat = styled(DownCaratIcon)`
  height: 8px;
  width: 16px;
  path {
    fill: ${({ theme }) => theme.mainColor};
  }
`

function TokenSelectWidget({
  isOpen,
  onClose,
  onTokenSelect,
  tokenBalances,
  defaultToken,
  disabled: propDisabled,
  exactTokens,
  allowNoAccount,
  fields: propFields,
}: {
  isOpen: boolean
  onClose: () => void
  onTokenSelect: (token: any) => void
  tokenBalances?: Token[]
  defaultToken?: Token
  disabled?: boolean
  exactTokens?: Token[] // if provided, the displayed tokens will be limited to this list (including 0 balances)
  allowNoAccount?: boolean
  fields?: TokenSelectField[]
}) {
  const fields = propFields || defaultFields

  // get data for widget
  const { account } = useSwellWeb3()

  // widget state
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [selectedToken, setSelectedToken] = useState<any>(
    defaultToken || undefined
  )

  // visible tokens
  const tokens = useVisibleTokens({ tokenBalances, exactTokens })
  // importable tokens
  const importableTokens = useImportableTokens({ paused: !!exactTokens })

  const handleButtonClick = () => {
    setModalOpen(true)
  }

  const handleModalClose = () => {
    onClose()
    setModalOpen(false)
  }

  const handleTokenSelect = (token: Token) => {
    setSelectedToken(token)
    onTokenSelect(token)
  }

  let disabled = propDisabled
  if (!allowNoAccount && !account) {
    disabled = true
  }

  return (
    <>
      <TokenSelectButton
        variant="tertiary"
        disabled={disabled}
        onClick={handleButtonClick}
      >
        <FlexRow align="center" gap="8">
          {!selectedToken && (
            <Typography
              variant="headline"
              size="h5"
              fstyle="bold"
              letterSpacing="small"
            >
              Select token
            </Typography>
          )}
          {selectedToken && (
            <>
              <TokenLogoV2 token={selectedToken} size={35} />
              <Typography
                variant="headline"
                size="h5"
                fstyle="bold"
                letterSpacing="small"
              >
                {selectedToken.symbol}
              </Typography>
            </>
          )}
          <TokenSelectCarat />
        </FlexRow>
      </TokenSelectButton>
      <TokenSelectModal
        open={modalOpen || isOpen}
        onClose={handleModalClose}
        tokens={tokens}
        importableTokens={exactTokens ? [] : importableTokens}
        onTokenSelect={handleTokenSelect}
        fields={fields}
      />
    </>
  )
}

export { TokenSelectWidget }
