import { SupportedChainId } from './chains'

export const MAX_DEPOSIT_KEYS = 1000

const MAX_DEPOSIT_KEY_CHUNK_SIZE = {
  [SupportedChainId.MAINNET]: 50,
  [SupportedChainId.GOERLI]: 50,
  [SupportedChainId.HARDHAT]: 50,
  [SupportedChainId.SEPOLIA]: 50,
  [SupportedChainId.HOLESKY]: 50,
  [SupportedChainId.SWELL]: 50,
  [SupportedChainId.SWELL_TESTNET]: 50,
  [SupportedChainId.OPTIMISM]: 50,
  [SupportedChainId.ARBITRUM]: 50,
  [SupportedChainId.OPTIMISM_SEPOLIA]: 50,
}

const GENESIS_FORK_VERSION = {
  [SupportedChainId.MAINNET]: Buffer.from('00000000', 'hex'),
  [SupportedChainId.GOERLI]: Buffer.from('00001020', 'hex'),
  [SupportedChainId.HARDHAT]: Buffer.from('00000000', 'hex'),
  [SupportedChainId.SEPOLIA]: Buffer.from('00000000', 'hex'),
  [SupportedChainId.HOLESKY]: Buffer.from('00000000', 'hex'),
  [SupportedChainId.SWELL]: Buffer.from('00000000', 'hex'),
  [SupportedChainId.SWELL_TESTNET]: Buffer.from('00000000', 'hex'),
  [SupportedChainId.OPTIMISM]: Buffer.from('00000000', 'hex'),
  [SupportedChainId.ARBITRUM]: Buffer.from('00000000', 'hex'),
  [SupportedChainId.OPTIMISM_SEPOLIA]: Buffer.from('00000000', 'hex'),
}

export const DEPOSIT_AMOUNT_GWEI = 32 * 10 ** 9

export const getGenesisForkVersion = (chainId: number) => {
  return GENESIS_FORK_VERSION[chainId as SupportedChainId]
}

export const getMaxDepositKeyChunkSize = (chainId: number) => {
  return MAX_DEPOSIT_KEY_CHUNK_SIZE[chainId as SupportedChainId]
}
