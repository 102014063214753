import { ClaimMerkleDropParams, MerkleContractsState } from '@/types/merkle'
import { BigNumber, BigNumberish, ContractTransaction } from 'ethers'

export const WavedropPhase = {
  ongoing: 'ongoing' as const,
  preChecker: 'preChecker' as const,
  checker: 'checker' as const,
  claims: 'claims' as const,
}
export type WavedropPhase = (typeof WavedropPhase)[keyof typeof WavedropPhase]

type WavedropBase = {
  waveNumber: number
  blackPearls: number
  multiplier: number
}

export type Wavedrop = WavedropBase &
  (
    | {
        phase: typeof WavedropPhase.ongoing | typeof WavedropPhase.preChecker
        daoTokenAmount?: undefined
      }
    | {
        phase: typeof WavedropPhase.checker
        daoTokenAmount: BigNumber
      }
    | {
        phase: typeof WavedropPhase.claims
        daoTokenAmount: BigNumber
        cumulativeAmount: BigNumber
        merkleProof: string[]
        merkleRoot: string
        swellAmount: BigNumber
        primaryAmount?: BigNumber // special: wavedrop 2 (base)
        ecosystemAmount?: BigNumber // special: wavedrop 2 (ecosytem drop merged in)
      }
  )

export type WavedropsResult = {
  wavedrops: Wavedrop[]
}

export type WavedropUser = {
  cumulativeClaimed: BigNumber
  cumulativeClaimedW1: BigNumber
}

export interface IWavedropsApiRead {
  wavedrops: () => Promise<WavedropsResult>
  wavedropUser: () => Promise<WavedropUser>
  wavedropContractState: () => Promise<MerkleContractsState>
  checkClaimWavedrop: (params: ClaimMerkleDropParams) => Promise<boolean>
}
export interface IWavedropsApiWrite {
  claimWavedrop: (
    params: ClaimMerkleDropParams,
    opts?: { gasLimit?: BigNumberish }
  ) => Promise<ContractTransaction>
  claimWavedropEstimateGas: (
    params: ClaimMerkleDropParams
  ) => Promise<BigNumber>
}

export type WavedropsApi = {
  read: IWavedropsApiRead
  write: IWavedropsApiWrite
}
