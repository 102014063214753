import { TextField as MuiTextField } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import styled, { useTheme } from 'styled-components'

export function SearchInput({
  searchTerm,
  onClear,
  setSearchTerm,
  maxWidth,
}: {
  searchTerm: string
  onClear: () => void
  setSearchTerm: (term: string) => void
  maxWidth?: string
}) {
  const [value, setValue] = useState(searchTerm)
  const [thinking, setThinking] = useState(false)

  const timeout = useRef<NodeJS.Timeout | null>(null)
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
    if (timeout.current) {
      clearTimeout(timeout.current)
    }
    setThinking(true)
    const t = setTimeout(() => {
      setSearchTerm(e.target.value)
      setThinking(false)
    }, 300)
    timeout.current = t
    return () => {
      clearTimeout(t)
    }
  }

  useEffect(() => {
    if (searchTerm === '') {
      setValue(searchTerm)
    }
  }, [searchTerm])

  const clearButton = value ? (
    <X
      onClick={() => {
        setValue('')
        onClear()
      }}
    />
  ) : null

  return (
    <Input
      variant="outlined"
      value={value}
      onChange={handleChange}
      placeholder="Search"
      InputProps={{
        endAdornment: thinking ? '' : clearButton,
        startAdornment: <MagnifyingGlass />,
      }}
      autoComplete="off"
      fullWidth
      sx={{ maxWidth }}
    />
  )
}

const Input = styled(MuiTextField)<{ thinking?: boolean; w?: string }>`
  border: ${(props) => props.theme.searchInput.border};
  background: ${(props) => props.theme.searchInput.bgColor};

  &,
  .MuiInputBase-formControl {
    border-radius: ${(props) => props.theme.searchInput.borderRadius};
    height: ${(props) => props.theme.searchInput.height};

    color: white;
    input {
      padding-left: 8px;
    }

    svg {
      path {
        stroke: currentColor;
      }
    }
  }

  color: ${(props) => props.theme.searchInput.color};
  box-shadow: none;
`

function MagnifyingGlass() {
  const {
    searchInput: { magnifyingGlassStyle },
  } = useTheme()

  return magnifyingGlassStyle === 1 ? (
    <MagnifyingGlassStyle1 />
  ) : (
    <MagnifyingGlassStyle2 />
  )
}

function MagnifyingGlassStyle1() {
  const {
    searchInput: { magnifyingGlassColor },
  } = useTheme()
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
    >
      <g clip-path="url(#clip0_11149_1794)">
        <path
          d="M7.5 12C10.2614 12 12.5 9.76142 12.5 7C12.5 4.23858 10.2614 2 7.5 2C4.73858 2 2.5 4.23858 2.5 7C2.5 9.76142 4.73858 12 7.5 12Z"
          stroke={magnifyingGlassColor}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M11.0356 10.5356L14.5 14"
          stroke={magnifyingGlassColor}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_11149_1794">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

function MagnifyingGlassStyle2() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M21.5304 20.4693L16.8363 15.7762C18.1968 14.1428 18.8752 12.0478 18.7304 9.92691C18.5856 7.80604 17.6288 5.82265 16.0589 4.38932C14.489 2.95599 12.4269 2.18308 10.3016 2.23138C8.17638 2.27968 6.15157 3.14547 4.6484 4.64864C3.14523 6.15181 2.27944 8.17663 2.23114 10.3019C2.18284 12.4271 2.95575 14.4892 4.38907 16.0591C5.8224 17.629 7.8058 18.5859 9.92666 18.7307C12.0475 18.8755 14.1426 18.1971 15.776 16.8365L20.4691 21.5306C20.5388 21.6003 20.6215 21.6556 20.7126 21.6933C20.8036 21.731 20.9012 21.7504 20.9997 21.7504C21.0983 21.7504 21.1959 21.731 21.2869 21.6933C21.3779 21.6556 21.4607 21.6003 21.5304 21.5306C21.6 21.4609 21.6553 21.3782 21.693 21.2871C21.7307 21.1961 21.7501 21.0985 21.7501 21C21.7501 20.9014 21.7307 20.8038 21.693 20.7128C21.6553 20.6218 21.6 20.539 21.5304 20.4693ZM3.74973 10.5C3.74973 9.16495 4.14561 7.8599 4.88731 6.74987C5.62901 5.63984 6.68321 4.77467 7.91661 4.26378C9.15001 3.75289 10.5072 3.61922 11.8166 3.87967C13.126 4.14012 14.3287 4.78299 15.2727 5.727C16.2167 6.671 16.8596 7.87374 17.12 9.18311C17.3805 10.4925 17.2468 11.8497 16.7359 13.0831C16.225 14.3165 15.3599 15.3707 14.2498 16.1124C13.1398 16.8541 11.8348 17.25 10.4997 17.25C8.71012 17.248 6.99439 16.5362 5.72895 15.2708C4.46351 14.0053 3.75171 12.2896 3.74973 10.5Z"
        fill="#818181"
      />
    </svg>
  )
}

function X({ onClick }: { onClick: () => void }) {
  const {
    searchInput: { magnifyingGlassColor },
  } = useTheme()
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      role="button"
      onClick={onClick}
      style={{ cursor: 'pointer' }}
    >
      <g clip-path="url(#clip0_11221_608)">
        <path
          d="M13 3.5L4 12.5"
          stroke={magnifyingGlassColor}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M13 12.5L4 3.5"
          stroke={magnifyingGlassColor}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_11221_608">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
