import { Web3Toast } from '../../components/StakingConfirmationProgressWidget/Web3Toast'
import { useEffect, useRef, useState } from 'react'
import { ClaimMerkl, UnlockWSwell } from '@/state/wswell/hooks'
import { MerklUser } from '@/types/merkl'
import { EVKUser } from '@/types/eulerEVK'

export const TRANSACTION_TOAST_TITLE = {
  COMPLETED: 'Transaction completed!',

  CLAIM_PROMPTING: 'Claim pending',
  CLAIM_PENDING: 'Claim confirming',
  CLAIM_COMPLETED: 'Claim started',

  UNLOCK_PROMPTING: 'Unlock pending',
  UNLOCK_PENDING: 'Unlock confirming',
  UNLOCK_COMPLETED: 'Unlock started',
}

export function ClaimMerklToast({
  claimWSwell,
  merklUser,
  anyTransactionInProgress,
}: {
  anyTransactionInProgress: boolean
  claimWSwell: ClaimMerkl
  merklUser: MerklUser | undefined
}) {
  const onClose = claimWSwell.clear

  const complete = claimWSwell.status === claimWSwell.STATUS.FULFILLED
  const confirming = claimWSwell.status === claimWSwell.STATUS.PROMPTING
  const pending = claimWSwell.status === claimWSwell.STATUS.PENDING

  const [unclaimed, setUnclaimed] = useState('')
  const statusRef = useRef(claimWSwell.status)
  useEffect(() => {
    const prevStatus = statusRef.current
    statusRef.current = claimWSwell.status
    const prevWasIdle = prevStatus === claimWSwell.STATUS.IDLE
    const nowIsNotIdle = claimWSwell.status !== claimWSwell.STATUS.IDLE
    if (prevWasIdle && nowIsNotIdle) {
      if (merklUser?.unclaimed) {
        setUnclaimed(merklUser.unclaimed || '')
      }
    }
    const prevWasNotIdle = prevStatus !== claimWSwell.STATUS.IDLE
    const nowIsIdle = claimWSwell.status === claimWSwell.STATUS.IDLE
    if (prevWasNotIdle && nowIsIdle) {
      setUnclaimed('')
    }
  }, [claimWSwell.STATUS.IDLE, claimWSwell.status, merklUser?.unclaimed])

  useEffect(() => {
    if (complete && anyTransactionInProgress) {
      onClose()
    }
  }, [complete, anyTransactionInProgress, onClose])

  let title = ''
  if (complete) {
    title = TRANSACTION_TOAST_TITLE.COMPLETED
  } else if (confirming) {
    title = TRANSACTION_TOAST_TITLE.CLAIM_PROMPTING
  } else if (pending) {
    title = TRANSACTION_TOAST_TITLE.CLAIM_PENDING
  }

  let message = ''
  if (claimWSwell.args) {
    if (unclaimed) {
      if (!complete) {
        message = `Claiming ${unclaimed} wSWELL`
      } else {
        message = `Claimed ${unclaimed} wSWELL`
      }
    }
  }

  return (
    <Web3Toast
      call={claimWSwell}
      title={title}
      message={message}
      anyTransactionInProgress={anyTransactionInProgress}
    />
  )
}

export function UnlockWSwellToast({
  unlockWSwell,
  anyTransactionInProgress,
  evkUser,
}: {
  evkUser: EVKUser | undefined
  unlockWSwell: UnlockWSwell
  anyTransactionInProgress: boolean
}) {
  const onClose = unlockWSwell.clear

  const complete = unlockWSwell.status === unlockWSwell.STATUS.FULFILLED
  const confirming = unlockWSwell.status === unlockWSwell.STATUS.PROMPTING
  const pending = unlockWSwell.status === unlockWSwell.STATUS.PENDING

  type ToastState = {
    unlockingWSwell: string
    receivingSwell: string
  }
  const [toastState, setToastState] = useState<ToastState>()
  const statusRef = useRef(unlockWSwell.status)
  useEffect(() => {
    const prevStatus = statusRef.current
    statusRef.current = unlockWSwell.status
    const prevWasIdle = prevStatus === unlockWSwell.STATUS.IDLE
    const nowIsNotIdle = unlockWSwell.status !== unlockWSwell.STATUS.IDLE
    if (prevWasIdle && nowIsNotIdle) {
      const [{ lockTimestampUnix }] = unlockWSwell.args!
      const lockWithSameTimestamp = evkUser?.locks.find(
        ({ lockTimestampUnix: lockTimestamp }) =>
          lockTimestamp === lockTimestampUnix
      )
      if (lockWithSameTimestamp) {
        setToastState({
          unlockingWSwell: lockWithSameTimestamp.lockedAmount,
          receivingSwell: lockWithSameTimestamp.accountAmount,
        })
      }
    }
    const prevWasNotIdle = prevStatus !== unlockWSwell.STATUS.IDLE
    const nowIsIdle = unlockWSwell.status === unlockWSwell.STATUS.IDLE
    if (prevWasNotIdle && nowIsIdle) {
      setToastState(undefined)
    }
  }, [
    unlockWSwell.STATUS.IDLE,
    unlockWSwell.status,
    unlockWSwell.args,
    evkUser?.locks,
  ])

  useEffect(() => {
    if (complete && anyTransactionInProgress) {
      onClose()
    }
  }, [complete, anyTransactionInProgress, onClose])

  let title = ''
  if (complete) {
    title = TRANSACTION_TOAST_TITLE.COMPLETED
  } else if (confirming) {
    title = TRANSACTION_TOAST_TITLE.UNLOCK_PROMPTING
  } else if (pending) {
    title = TRANSACTION_TOAST_TITLE.UNLOCK_PENDING
  }

  let message = ''
  if (unlockWSwell.args) {
    if (toastState) {
      if (!complete) {
        message = `Unlocking ${toastState.unlockingWSwell} wSWELL to receive ${toastState.receivingSwell} SWELL`
      } else {
        message = `Unlocked ${toastState.unlockingWSwell} wSWELL to receive ${toastState.receivingSwell} SWELL`
      }
    }
  }

  return (
    <Web3Toast
      call={unlockWSwell}
      title={title}
      message={message}
      anyTransactionInProgress={anyTransactionInProgress}
    />
  )
}
