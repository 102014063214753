import useSWR from 'swr'
import { useL2PromotionsApi } from './context'
import { L2BridgeTxEvent } from './types'
import useSWRSubscription from 'swr/subscription'
import uniqBy from 'lodash/uniqBy'
import { useRefreshRate } from '@/hooks/useMath'
import ms from 'ms.macro'
import { solidityKeccak256 } from 'ethers/lib/utils'
import { useMemo } from 'react'
import { cloneDeep } from 'lodash'

export function useL2PromotionStats() {
  const api = useL2PromotionsApi()
  return useSWR('l2-promotion-stats', api.stats)
}
export function useL2LaunchPartners() {
  const api = useL2PromotionsApi()
  return useSWR('l2-launch-partners', api.launchPartners)
}
export function useL2BuiltWith() {
  const api = useL2PromotionsApi()
  return useSWR('l2-launch-built-with', api.builtWith)
}
export function useGlobalBridgeEvents() {
  const api = useL2PromotionsApi()

  const sub = useSWRSubscription('global-bridge-events', (__, { next }) => {
    const ac = new AbortController()
    const stream = api.recentGlobalEvents(ac.signal)
    ;(async () => {
      try {
        for await (const m of stream) {
          next(null, (prev: any) => {
            if (!prev) {
              return [m]
            }
            return [...prev, m]
          })
        }
      } catch (e) {
        console.error('l2 bridge events', e)
        next(e, null)
      }
    })()
    return () => ac.abort()
  })

  // hack... don't trust swr
  const r = useRefreshRate({ rate: ms`4s` })

  const error = sub.error
  let data = cloneDeep(sub.data) as L2BridgeTxEvent[] | undefined
  // in strict mode above hook runs twice. this is a hack to dedupe.
  // the data should be keyed by url anyway
  data = uniqBy(data, (e) => e.url)
  const hash = solidityKeccak256(['string'], [JSON.stringify(data) + `${r}`])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  data = useMemo(() => data, [hash])

  return { data, error }
}
