import { DeploymentAddresses } from '@/types/deployments'
import { useSwellWeb3 } from '@/swell-web3/core'
import {
  CumulativeMerkleDrop__factory,
  Multicall3,
  RswETH,
  SWExit__factory,
} from '@/abis/types'
import { JsonRpcProvider } from '@ethersproject/providers'
import { ExitAssets, ExitClaim } from '@/types/claims'
import {
  IRswETHApiRead,
  IRswETHApiWrite,
  ExitAllowanceMap,
  ExitClaimMap,
  EigenLayerStakedropResult,
} from './types'
import { ExitMulticaller } from '@/services/Exit'
import { TokenMulticalls } from '@/services/Tokens'
import {
  useMerkleDropContract,
  useMulticallContract,
  useRswETHContract,
} from '@/hooks/useContract'
import { ExitToWithdrawAsset, RswETHContext, useRswETHApi } from './context'
import { Token } from '@/types/tokens'
import { RswEXITClaimFetcher } from '@/services/V3BackendService/rswexitNft'
import { useDeploymentSetConfig } from '../deployments/hooks'
import { MerkleDropService } from '@/services/MerkleDropService'
import { MerkleContractsState, MerkleDrop } from '@/types/merkle'
import {
  useV3BackendClient,
  WalletClient,
} from '@/services/V3BackendService/hooks'
import { prefix0x } from '@/util/hexStrings'
import { BigNumber } from 'ethers'
import { formatBytes32String } from 'ethers/lib/utils'
import { useMemo } from 'react'
import { StaticEigenDropService } from '@/services/StaticEigenDrop'

export interface IExitClaimFetcher {
  fetchClaim: (
    lstAddress: string | null,
    requestId: number
  ) => Promise<ExitClaim>
}

export function useRswETHApiPortfolioImpl(): RswETHContext {
  const api = useRswETHApi()
  const { staticAirdropUrl } = useDeploymentSetConfig()

  const dropSvc = useMemo(
    () => new StaticEigenDropService({ baseURL: staticAirdropUrl }),
    [staticAirdropUrl]
  )

  const { eigenStakedropMerkleDrop } = api
  const merkleDropEigenStakedropContract = useMerkleDropContract(
    eigenStakedropMerkleDrop.address
  )!

  const { account: maybeAccount } = useSwellWeb3()
  const account = maybeAccount!

  return {
    ...api,
    read: {
      checkClaimEigenStakedrop: async () => {
        throw new Error('not implemented')
      },
      eigenStakedrop: async () => {
        const drops = await dropSvc.eigendrop(account)
        if (drops.length < 1) {
          return {
            exists: false,
          }
        }
        const drop2 = drops.find((d) => d.waveNumber === 2)
        if (!drop2) {
          return {
            exists: false,
          }
        }

        const cumulativeClaimed =
          await merkleDropEigenStakedropContract.cumulativeClaimed(account)

        return {
          exists: true,
          cumulativeClaimed,
          data: {
            address: account,
            cumulativeAmount: drop2.cumulativeAmount,
            merkleRoot: drop2.merkleRoot,
            merkleProof: drop2.proofsHex,
            totalAmount: drop2.cumulativeAmount,
          },
        }
      },
      eigenStakedropContractsState: async () => {
        return {
          merkleDrop: { claimIsOpen: false, merkleRoot: '0x' },
          staking: { exists: false },
        }
      },
      rswETHBalance: async () => {
        throw new Error('not implemented')
      },
      rswEXITAllowances: async () => {
        throw new Error('not implemented')
      },
      rswEXITClaims: async () => {
        throw new Error('not implemented')
      },
    },
    write: {
      approveRswETHForWithdrawal: async () => {
        throw new Error('not implemented')
      },
      approveRswETHForWithdrawalEstimateGas: async () => {
        throw new Error('not implemented')
      },
      claimEigenStakedrop: async () => {
        throw new Error('not implemented')
      },
      claimEigenStakedropEstimateGas: async () => {
        throw new Error('not implemented')
      },
      createWithdrawRequest: async () => {
        throw new Error('not implemented')
      },
      createWithdrawRequestEstimateGas: async () => {
        throw new Error('not implemented')
      },
      finalizeWithdrawal: async () => {
        throw new Error('not implemented')
      },
      finalizeWithdrawalEstimateGas: async () => {
        throw new Error('not implemented')
      },
    },
  }
}

async function fetchClaimsForTokens(
  lrtClaimFetcher: IExitClaimFetcher,
  tokenIds: number[],
  assetAddress: string
) {
  const claims: ExitClaim[] = []
  const promises = tokenIds.map(async (tokenId: number) => {
    claims.push(await lrtClaimFetcher.fetchClaim(assetAddress, tokenId))
  })
  await Promise.all(promises)
  return claims
}

export type UserApiReadConfig = {
  provider: JsonRpcProvider
  addresses: DeploymentAddresses
  rswETH: RswETH
  multicall: Multicall3
}

async function fetchRswEXITClaims(
  backendUrl: string,
  multicall: Multicall3,
  exitToWithdrawAsset: ExitToWithdrawAsset,
  account: string
): Promise<ExitClaimMap> {
  const cf = new RswEXITClaimFetcher(backendUrl)
  const exitMC = new ExitMulticaller(multicall)
  const exitAddresses = Array.from(exitToWithdrawAsset.keys())

  const balances = await exitMC.fetchExitBalances(account, exitAddresses)
  const ownedTokenIdList = await exitMC.fetchOwnedTokenIds(account, balances)

  const res: ExitClaimMap = {}
  for (const { tokenIds, exitAddress } of ownedTokenIdList) {
    const withdrawAsset = exitToWithdrawAsset.get(exitAddress)
    if (!withdrawAsset) {
      throw new Error('no asset address')
    }

    const claims = await fetchClaimsForTokens(
      cf,
      tokenIds,
      withdrawAsset.address
    )
    res[exitAddress] = claims
  }

  return res
}

async function fetchRswExitAllowances(
  multicall: Multicall3,
  rswETHAddress: string,
  exitAddresses: string[],
  account: string
): Promise<ExitAllowanceMap> {
  const tmc = new TokenMulticalls(multicall)

  const tokenSpenders = exitAddresses.map((exitAddress) => ({
    token: rswETHAddress,
    spender: exitAddress,
  }))
  const allowances = await tmc.fetchAllowances(tokenSpenders, account)

  const res: ExitAllowanceMap = {}
  allowances.forEach(({ spender, allowance }) => {
    res[spender] = allowance
  })

  return res
}

async function fetchEigenStakedrop({
  account,
  multicall,
  eigenCheckerMode,
  eigenStakedropMerkleDrop,
  walletClient,
}: {
  multicall: Multicall3
  account: string
  eigenCheckerMode: boolean
  walletClient: WalletClient
  eigenStakedropMerkleDrop: MerkleDrop
}): Promise<EigenLayerStakedropResult> {
  const airdropDataP = walletClient.eigenlayerAirdrop({
    walletAddress: account,
  })

  let currentMerkleRoot: string
  let cumulativeClaimed: BigNumber
  if (!eigenCheckerMode) {
    const calls: Multicall3.Call3Struct[] = []
    calls.push({
      target: eigenStakedropMerkleDrop.address,
      allowFailure: false,
      callData:
        CumulativeMerkleDrop__factory.createInterface().encodeFunctionData(
          'merkleRoot'
        ),
    })
    calls.push({
      target: eigenStakedropMerkleDrop.address,
      allowFailure: false,
      callData:
        CumulativeMerkleDrop__factory.createInterface().encodeFunctionData(
          'cumulativeClaimed',
          [account]
        ),
    })

    const [merkleRootResult, cumulativeClaimedResult] =
      await multicall.callStatic.tryAggregate(true, calls)

    currentMerkleRoot =
      CumulativeMerkleDrop__factory.createInterface().decodeFunctionResult(
        'merkleRoot',
        merkleRootResult.returnData
      )[0]
    cumulativeClaimed =
      CumulativeMerkleDrop__factory.createInterface().decodeFunctionResult(
        'cumulativeClaimed',
        cumulativeClaimedResult.returnData
      )[0]
  } else {
    currentMerkleRoot = formatBytes32String('0')
    cumulativeClaimed = BigNumber.from(0)
  }

  const airdropData = await airdropDataP
  if (!eigenCheckerMode) {
    if (prefix0x(airdropData.latestMerkleRootHex) !== currentMerkleRoot) {
      throw new Error('Merkle root mismatch')
    }
  }

  if (
    airdropData.cumulativeAmount === '' ||
    airdropData.cumulativeAmount === '0'
  ) {
    return {
      exists: false,
    }
  }

  return {
    exists: true,
    data: {
      address: account,
      cumulativeAmount: BigNumber.from(airdropData.cumulativeAmount),
      merkleRoot: prefix0x(airdropData.latestMerkleRootHex),
      merkleProof: airdropData.proofsHex.map((p) => prefix0x(p)),
      totalAmount: BigNumber.from(airdropData.cumulativeAmount),
    },
    cumulativeClaimed,
  }
}

async function fetchMerkleContractsState({
  multicall,
  eigenCheckerMode,
  merkleDropAddress,
}: {
  multicall: Multicall3
  merkleDropAddress: string
  eigenCheckerMode: boolean
}): Promise<MerkleContractsState> {
  const svc = new MerkleDropService({
    merkleDropAddress,
    multicall,
    stakingAddress: undefined,
  })

  const state = await svc.fetchContractsState()

  if (eigenCheckerMode) {
    state.merkleDrop.claimIsOpen = false
    state.merkleDrop.merkleRoot = formatBytes32String('0')
  }

  return state
}

export function useRswETHApiReadContractImpl({
  rswETHToken,
  exitToWithdrawAsset,
  exitAssets,
  eigenStakedropMerkleDrop,
  eigenCheckerMode,
}: {
  rswETHToken: Token
  exitAssets: ExitAssets
  exitToWithdrawAsset: ExitToWithdrawAsset
  eigenStakedropMerkleDrop: MerkleDrop
  eigenCheckerMode: boolean
}): IRswETHApiRead {
  const { v3BackendLrtUrl } = useDeploymentSetConfig()
  const { account: maybeAccount } = useSwellWeb3()
  const rswETH = useRswETHContract()!
  const multicall = useMulticallContract()

  const account = maybeAccount!
  const exitAddresses = exitAssets.map((asset) => asset.exitAddress)
  const merkleDropEigenStakedropContract = useMerkleDropContract(
    eigenStakedropMerkleDrop.address
  )!

  const walletClient =
    useV3BackendClient(v3BackendLrtUrl).v3BackendClient.wallet

  return {
    rswETHBalance: async () => {
      return rswETH.balanceOf(account)
    },
    rswEXITAllowances: async () => {
      return fetchRswExitAllowances(
        multicall,
        rswETHToken.address,
        exitAddresses,
        account
      )
    },
    rswEXITClaims: async () => {
      const backendUrl = v3BackendLrtUrl
      return fetchRswEXITClaims(
        backendUrl,
        multicall,
        exitToWithdrawAsset,
        account
      )
    },
    eigenStakedrop: async () => {
      return fetchEigenStakedrop({
        multicall,
        account,
        eigenCheckerMode,
        eigenStakedropMerkleDrop,
        walletClient,
      })
    },
    eigenStakedropContractsState: async () => {
      return fetchMerkleContractsState({
        multicall,
        merkleDropAddress: eigenStakedropMerkleDrop.address,
        eigenCheckerMode,
      })
    },
    checkClaimEigenStakedrop: async ({ merkleProof, cumulativeAmount }) => {
      return merkleDropEigenStakedropContract.verifyProof(
        merkleProof,
        cumulativeAmount,
        account
      )
    },
  }
}
export function useRswETHApiWriteContractImpl({
  withdrawAssetToExit,
  eigenStakedropMerkleDrop,
}: {
  withdrawAssetToExit: Map<string, string>
  eigenStakedropMerkleDrop: MerkleDrop
}): IRswETHApiWrite {
  const rswETH = useRswETHContract()!
  const merkleDropEigenStakedropContract = useMerkleDropContract(
    eigenStakedropMerkleDrop.address
  )!

  return {
    approveRswETHForWithdrawal: async ({ assetAddress, amount }, opts) => {
      const spender = withdrawAssetToExit.get(assetAddress)
      if (!spender) {
        throw new Error('no spender')
      }
      return rswETH.approve(spender, amount, opts)
    },
    approveRswETHForWithdrawalEstimateGas: async ({ assetAddress, amount }) => {
      const spender = withdrawAssetToExit.get(assetAddress)
      if (!spender) {
        throw new Error('no spender')
      }
      return rswETH.estimateGas.approve(spender, amount)
    },
    createWithdrawRequest: async ({ assetAddress, rswETHAmount }, opts) => {
      const exitAddress = withdrawAssetToExit.get(assetAddress)
      if (!exitAddress) {
        throw new Error('no exit')
      }
      const rswExit = SWExit__factory.connect(exitAddress, rswETH.signer)
      return rswExit.createWithdrawRequest(rswETHAmount, opts)
    },
    createWithdrawRequestEstimateGas: async ({
      assetAddress,
      rswETHAmount,
    }) => {
      const exitAddress = withdrawAssetToExit.get(assetAddress)
      if (!exitAddress) {
        throw new Error('no exit')
      }
      const exit = SWExit__factory.connect(exitAddress, rswETH.signer)
      return exit.estimateGas.createWithdrawRequest(rswETHAmount)
    },
    finalizeWithdrawal: async ({ requestId, assetAddress }, opts) => {
      const exitAddress = withdrawAssetToExit.get(assetAddress)
      if (!exitAddress) {
        throw new Error('no exit')
      }
      const exit = SWExit__factory.connect(exitAddress, rswETH.signer)
      return exit.finalizeWithdrawal(requestId, opts)
    },
    finalizeWithdrawalEstimateGas: async ({ requestId, assetAddress }) => {
      const exitAddress = withdrawAssetToExit.get(assetAddress)
      if (!exitAddress) {
        throw new Error('no exit')
      }
      const exit = SWExit__factory.connect(exitAddress, rswETH.signer)
      return exit.estimateGas.finalizeWithdrawal(requestId)
    },
    claimEigenStakedrop: async (
      { amountToLock, cumulativeAmount, merkleProof },
      opts
    ) => {
      return merkleDropEigenStakedropContract.claimAndLock(
        cumulativeAmount,
        amountToLock,
        merkleProof,
        opts
      )
    },
    claimEigenStakedropEstimateGas: async ({
      amountToLock,
      cumulativeAmount,
      merkleProof,
    }) => {
      return merkleDropEigenStakedropContract.estimateGas.claimAndLock(
        cumulativeAmount,
        amountToLock,
        merkleProof
      )
    },
  }
}
