/**
 * Copied: https://github.com/Uniswap/interface/blob/ad2472eac638b389316ba1f3c3f1ed08fbbb12cd/src/lib/hooks/useBlockNumber.tsx
 * Changed:
 *  - Added useEveryNthBlock hook
 */
import { useSwellWeb3 } from '@swell-web3/core'
import { useIsWindowVisible } from 'hooks/useIsWindowVisible'
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import noop from 'lodash/noop'

const MISSING_PROVIDER = Symbol()
const BlockNumberContext = createContext<
  | {
      value?: number
      timestamp?: number
      numListeners: number
      subscribe: () => { unsubscribe: () => void }
    }
  | typeof MISSING_PROVIDER
>(MISSING_PROVIDER)

/* should only be imported for test/debug purposes */
export { BlockNumberContext as DebugBlockNumberContext }

function useBlockNumberContext() {
  const blockNumber = useContext(BlockNumberContext)
  if (blockNumber === MISSING_PROVIDER) {
    throw new Error(
      'BlockNumber hooks must be wrapped in a <BlockNumberProvider>'
    )
  }

  return blockNumber
}

export default function useBlockNumber({ skip }: { skip?: boolean } = {}):
  | number
  | undefined {
  const { subscribe, value } = useBlockNumberContext()

  useEffect(() => {
    if (skip) return noop

    const { unsubscribe } = subscribe()
    return unsubscribe
  }, [skip, subscribe])

  return value
}

export function useEveryNthBlock({
  blocksPerFetch,
  fetch,
  skip = false,
}: {
  blocksPerFetch: number
  fetch: () => void
  skip?: boolean
}) {
  const lastFetchBlock = useRef<number>()
  const latestBlockNo = useBlockNumber({ skip })

  useEffect(() => {
    if (latestBlockNo == null || skip) return

    const stale =
      lastFetchBlock.current == null ||
      latestBlockNo - lastFetchBlock.current >= blocksPerFetch

    if (stale) {
      fetch()
      lastFetchBlock.current = latestBlockNo
    }
  }, [fetch, blocksPerFetch, latestBlockNo, skip])
}

export function BlockNumberProvider({ children }: { children: ReactNode }) {
  const [numListeners, setNumListeners] = useState<number>(0)
  const { chainId: activeChainId, provider } = useSwellWeb3()
  const [{ chainId, block, timestamp }, setChainBlock] = useState<{
    chainId?: number
    block?: number
    timestamp?: number
  }>({ chainId: activeChainId })

  const onBlock = useCallback(
    (block: number) => {
      setChainBlock((chainBlock) => {
        if (chainBlock.chainId === activeChainId) {
          if (!chainBlock.block || chainBlock.block < block) {
            return { chainId: activeChainId, block, timestamp: Date.now() }
          }
        }
        return chainBlock
      })
    },
    [activeChainId, setChainBlock]
  )

  const windowVisible = useIsWindowVisible()
  useEffect(() => {
    let stale = false

    if (provider && activeChainId && windowVisible && numListeners > 0) {
      // If chainId hasn't changed, don't clear the block. This prevents re-fetching still valid data.
      setChainBlock((chainBlock) =>
        chainBlock.chainId === activeChainId
          ? chainBlock
          : { chainId: activeChainId }
      )

      provider
        .getBlockNumber()
        .then((block) => {
          if (!stale) onBlock(block)
        })
        .catch((error) => {
          console.error(
            `Failed to get block number for chainId ${activeChainId}`,
            error
          )
        })

      provider.on('block', onBlock)
      return () => {
        stale = true
        provider.removeListener('block', onBlock)
      }
    }

    return void 0
  }, [
    activeChainId,
    provider,
    onBlock,
    setChainBlock,
    windowVisible,
    numListeners,
  ])

  const blockValue = useMemo(
    () => (chainId === activeChainId ? block : undefined),
    [activeChainId, block, chainId]
  )
  const timestampValue = useMemo(
    () => (chainId === activeChainId ? timestamp : undefined),
    [activeChainId, timestamp, chainId]
  )
  const subscribe = useCallback(() => {
    setNumListeners((v) => v + 1)

    return {
      unsubscribe: () => {
        setNumListeners((v) => v - 1)
      },
    }
  }, [])

  const value = useMemo(
    () => ({
      value: blockValue,
      timestamp: timestampValue,
      numListeners,
      subscribe,
    }),
    [blockValue, numListeners, subscribe, timestampValue]
  )

  return (
    <BlockNumberContext.Provider value={value}>
      {children}
    </BlockNumberContext.Provider>
  )
}
