import { useSwellWeb3 } from '@/swell-web3/core'
import { useEffect } from 'react'

// loads the MetaCRM widget script
export function MetaCRMWidgetScript({ production }: { production: boolean }) {
  useEffect(() => {
    const loadMetaCRMScript = (window as any).loadMetaCRMScript

    // temporarily use staging
    let url = 'https://static-staging.svc.swellnetwork.io/widget-2-5-2.js'
    if (!production) {
      url = 'https://static-staging.svc.swellnetwork.io/widget-2-5-2.js'
    }

    ;(async function () {
      try {
        await loadMetaCRMScript(url)
        const MetaCRMWidget: any = (window as any).MetaCRMWidget
        MetaCRMWidget.init({ apiKey: 'kgz1yn9dwmf' })
      } catch (error) {
        console.error('Failed to load widget.js', error)
      }
    })()
  }, [production])

  return null
}

export function MetaCRMAccount() {
  const { account } = useSwellWeb3()

  useEffect(() => {
    const MetaCRMWidget: any = (window as any).MetaCRMWidget
    if (MetaCRMWidget?.manualConnectWallet) {
      MetaCRMWidget.manualConnectWallet(account)
    }
    const handleConnectWidget = () => {
      if (MetaCRMWidget?.manualConnectWallet) {
        MetaCRMWidget.manualConnectWallet(account)
      }
    }
    document.addEventListener('MetaCRMLoaded', handleConnectWidget)
    return () => {
      document.removeEventListener('MetaCRMLoaded', handleConnectWidget)
    }
  }, [account])
  return null
}

// loads the MetaCRM tracking script
export function MetaCRMTrackingScript({ production }: { production: boolean }) {
  useEffect(() => {
    const loadMetaCRMTrackingScript = (window as any).loadMetaCRMTrackingScript

    // temporarily use staging
    let url = 'https://static-staging.svc.swellnetwork.io/tracking-1-2-0.js'
    if (!production) {
      url = 'https://static-staging.svc.swellnetwork.io/tracking-1-2-0.js'
    }

    ;(async function () {
      try {
        await loadMetaCRMTrackingScript(url, 'gvlcj5h9oa')
      } catch (error) {
        console.error('Failed to load tracking.js', error)
      }
    })()
  }, [production])

  return null
}

// sends the wallet address to MetaCRM on change
export function MetaCRMTracking() {
  const { account } = useSwellWeb3()

  useEffect(() => {
    if ((window as any)?.MetaCRMTracking?.manualConnectWallet) {
      ;(window as any).MetaCRMTracking.manualConnectWallet(account)
    }
  }, [account])

  return null
}
