import { AvailableChipV2 } from '@/components/StakingWidget/AvailableChipV2'
import styled from 'styled-components'
import {
  PredepositInputs,
  PredepositTokensSummary,
  PredepositWithdrawalSummary,
  PredepositWithdrawController,
} from '../../types'
import { FlexRow } from '@/swell-ui/FlexRow'
import { WithdrawFromPredepositInput } from '../../SwellchainInputs'
import { useEffect, useState } from 'react'
import { useSwellWeb3 } from '@/swell-web3/core'
import { PredepositUser } from '@/state/predeposit/types'
import { PreparedWithdrawFromPredeposit } from '../../swellchainCalls'
import {
  MintEarnETHMultiStepButton,
  WithdrawFromPredepositButton,
} from '../../SwellchainButtons'
import { PredepositCalls, PredepositTokens } from '@/state/predeposit/hooks'
import { ConsiderBridgingEarnETHModal } from '../../components/ConsiderBridgingEarnETHModal'
import { useNavigate } from 'react-router'
import { ROUTES } from '@/constants/routes'
import { PredepositTokenSelect } from '../../components/PredepositTokenSelect'
import {
  PreparedNucleusApproveAssetForDeposit,
  PreparedNucleusDeposit,
} from '@/components/Nucleus/nucleusCalls'
import { NucleusBalances } from '@/state/nucleusVault/types'

const EARN_ETH_LEARN_MORE_HREF = 'https://www.swellnetwork.io/post/wave-1'

export function PredepositWithdrawView({
  predepositWithdrawalSummary,
  predepositUser,
  preparedWithdrawFromPredeposit,
  predepositCalls,
  predepositInputs,
  predepositTokens,
  predepositTokensSummary,
  preparedApproveAssetForDeposit,
  preparedDeposit,
  controller,
  balances,
}: {
  predepositWithdrawalSummary: PredepositWithdrawalSummary
  predepositUser: PredepositUser | undefined
  predepositInputs: PredepositInputs
  predepositTokens: PredepositTokens
  predepositTokensSummary: PredepositTokensSummary

  predepositCalls: PredepositCalls

  preparedWithdrawFromPredeposit: PreparedWithdrawFromPredeposit
  preparedApproveAssetForDeposit: PreparedNucleusApproveAssetForDeposit
  preparedDeposit: PreparedNucleusDeposit
  controller: PredepositWithdrawController
  balances: NucleusBalances | undefined
}) {
  const [touched, setTouched] = useState(false)
  const { account } = useSwellWeb3()

  let errorMsg = preparedWithdrawFromPredeposit.error ?? null
  if (!touched) {
    errorMsg = null
  }

  const token = predepositTokensSummary.find(
    (token) => token.address === predepositInputs.withdrawToken?.address
  )
  const isEarnEthAsset = token?.isEarnEthAsset

  const [isModalOpen, setIsModalOpen] = useState(false)
  const isTxComplete =
    predepositCalls.withdrawFromPredeposit.status ===
    predepositCalls.withdrawFromPredeposit.STATUS.FULFILLED
  useEffect(() => {
    if (isTxComplete && isEarnEthAsset) {
      if (controller.initiator === 'withdraw') {
        setIsModalOpen(true)
      }
    }
  }, [isTxComplete, isEarnEthAsset, controller.initiator])

  const navigate = useNavigate()
  const onConfirm = () => {
    setIsModalOpen(false)
    navigate(ROUTES.EarnETH)
  }
  const onClose = () => {
    setIsModalOpen(false)
  }

  let actions = (
    <>
      <div style={{ height: '24px', marginTop: 'auto' }} />
      <WithdrawFromPredepositButton
        withdrawFromPredeposit={
          controller.predepositCalls.withdrawFromPredeposit
        }
        prepared={preparedWithdrawFromPredeposit}
        controller={controller}
      />
    </>
  )
  if (isEarnEthAsset) {
    actions = (
      <>
        <div style={{ height: '12px', marginTop: 'auto' }} />
        <WithdrawFromPredepositButton
          withdrawFromPredeposit={
            controller.predepositCalls.withdrawFromPredeposit
          }
          prepared={preparedWithdrawFromPredeposit}
          controller={controller}
        />
        <div style={{ height: '24px', marginTop: '12px' }} />
        <p style={{ textAlign: 'center' }}>OR</p>
        <div style={{ marginTop: 'auto' }} />
        <p style={{ textAlign: 'center' }}>
          Mint earnETH with your asset to get Swellchain exposure.{' '}
          <a
            href={EARN_ETH_LEARN_MORE_HREF}
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn more
          </a>
          .
        </p>
        <MintEarnETHMultiStepButton
          withdrawFromPredeposit={
            controller.predepositCallsWithNucleus.withdrawFromPredeposit
          }
          preparedWithdrawFromPredeposit={preparedWithdrawFromPredeposit}
          approveAssetForDeposit={
            controller.nucleusCalls.approveAssetForDeposit
          }
          preparedApproveAssetForDeposit={preparedApproveAssetForDeposit}
          deposit={controller.nucleusCalls.deposit}
          preparedDeposit={preparedDeposit}
          controller={controller}
        />
      </>
    )
  }

  return (
    <Layout>
      {isModalOpen && (
        <ConsiderBridgingEarnETHModal onConfirm={onConfirm} onClose={onClose} />
      )}
      <AvailableChipV2 available={predepositWithdrawalSummary.available} />
      <div style={{ height: '24px' }} />
      <FlexRow gap="8px" justify="space-between">
        <span className="body-xlarge-bold">Withdraw</span>
        <PredepositTokenSelect
          predepositInputs={predepositInputs}
          predepositTokens={predepositTokens}
          predepositTokensSummary={predepositTokensSummary}
        />
      </FlexRow>
      <div style={{ height: '24px' }} />
      <WithdrawFromPredepositInput
        predepositInputs={predepositInputs}
        predepositWithdrawalSummary={predepositWithdrawalSummary}
        errorMessage={errorMsg}
        setTouched={setTouched}
        account={account}
        predepositUser={predepositUser}
        controller={controller}
        balances={balances}
      />
      {actions}
    </Layout>
  )
}

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`
