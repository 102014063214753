import { DefaultTheme, css } from 'styled-components/macro'
import { createTheme } from '@mui/material/styles'
import { colors } from './colors'
import { typography } from './typography'
import { ThemeData } from './branding'
import { transparentize, linearGradient, mix } from 'polished'
import cloneDeep from 'lodash/cloneDeep'

// navigation is universal, with less page-specific branding. Only light/dark.
export const navigationLight: DefaultTheme['navigation'] = {
  dialog: {
    bgColor: colors.white['50'],
    borderRadius: '16px',
    backdrop: {
      backdropFilter: 'blur(5px)',
      bgColor: 'rgba(255, 255, 255, 0.50)',
    },
    borderColor: colors.eigenLayer['federalBlue'],
  },
  newIndicator: {
    bgColor: ThemeData.Symbiotic.symbioticBtcOrange,
    color: '#000',
    border: 'none',
    borderRadius: '4px',
  },
  dividerColor: '#ECF2FE',
  pagesTypography: css`
    color: rgb(24, 13, 104);
    /* Body/medium */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%; /* 25.6px */
    letter-spacing: normal;
  `,
  pagesUnselectedColor: 'rgba(24, 13, 104, 0.5)',
  pagesOpenColor: mix(0.5, 'rgb(24, 13, 104)', 'rgba(24, 13, 104, 0.5)'),
  columnHeadingTypography: css`
    color: var(--Eigen-Layer-Federal-Blue, #180d68);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 19.2px */
    letter-spacing: 0.9px;
    text-transform: uppercase;
  `,
  drawerChevronColor: '#180d68',
  drawerHeadingTypography: css`
    color: var(--Eigen-Layer-Federal-Blue, #180d68);
    /* Headlines/H6 */
    font-family: Inter;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 33.8px */
    letter-spacing: -0.78px;
  `,
  linkTypography: css`
    color: var(--Eigen-Layer-Federal-Blue, #180d68);
    /* Body/medium */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 25.6px */
    letter-spacing: -0.48px;
  `,
  linkHoverBgColor: '#CEDFFC',
  linkHoverBorderRadius: '4px',
}

export const navigationDark: DefaultTheme['navigation'] = {
  dialog: {
    bgColor: '#000',
    borderRadius: '16px',
    backdrop: {
      backdropFilter: 'blur(5px)',
      bgColor: 'rgba(0, 0, 0, 0.50)',
    },
    borderColor: '#1D1D1D',
  },
  newIndicator: {
    bgColor: ThemeData.Symbiotic.symbioticBtcOrange,
    border: 'none',
    borderRadius: '4px',
    color: '#000',
  },
  dividerColor: 'rgba(255, 255, 255, 0.10)',
  pagesTypography: css`
    color: var(--white-50, #fff);
    /* Body/medium */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%; /* 25.6px */
    letter-spacing: normal;
  `,
  pagesUnselectedColor: 'rgb(176, 176, 176)',
  pagesOpenColor: mix(0.5, 'rgb(176, 176, 176)', '#fff'),
  columnHeadingTypography: css`
    color: var(--white-125, #b0b0b0);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 19.2px */
    letter-spacing: 0.9px;
    text-transform: uppercase;
  `,

  drawerChevronColor: '#fff',
  drawerHeadingTypography: css`
    color: var(--white-50, #fff);
    /* Headlines/H6 */
    font-family: Inter;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 33.8px */
    letter-spacing: -0.78px;
  `,
  linkTypography: css`
    color: var(--white-50, #fff);
    /* Body/medium */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 25.6px */
    letter-spacing: -0.48px;
  `,
  linkHoverBgColor: '#181818',
  linkHoverBorderRadius: '4px',
}

const toastDark: DefaultTheme['toast'] = {
  bgColor: colors.oceanBlue['125'],
  altBgColor: `linear-gradient(180deg, #3068EF 0.29%, #1322AC 167.95%)`,
  messageColor: colors.white['125'],
  border: '0.8px solid #424557',
  borderRadius: '8px',
  progressCompleteBgColor: '#009e49',
  a: {
    hoverColor: colors.white['50'],
  },
}

const toastLight: DefaultTheme['toast'] = {
  bgColor: colors.white['50'],
  altBgColor: colors.eigenLayer['yellow'],
  messageColor: colors.eigenLayer['federalBlue'],
  border: `0.8px solid #CEDFFC`,
  borderRadius: '8px',
  progressCompleteBgColor: '#009e49',
  a: {
    hoverColor: colors.eigenLayer['federalBlue'],
  },
}
export const darkTheme: DefaultTheme = createTheme({
  mainBgColor: colors.oceanBlue['100'],
  mainColor: colors.white['50'],
  secondaryBgColor: 'rgb(14, 20, 26)',
  accentBgColor: 'rgb(41, 50, 73)',
  accentColor: 'rgb(200, 200, 200)',
  fiatColor: colors.lightBlue['50'],

  a: {
    color: colors.white['125'],
    hoverColor: colors.white['50'],
  },

  accordionTable: {
    summaryBgColor: colors.oceanBlue['100'],
    expandedBgColor:
      'inear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05) ), #051a2b',
  },

  alert: {
    success: {
      bgColor: colors.lightBlue['125'],
      linkHoverColor: colors.white['100'],
    },
    error: {
      bgColor: colors.red['100'],
    },
  },

  badge: {
    error: {
      borderColor: colors.red['100'],
      color: colors.red['100'],
    },
    info: {
      borderColor: 'transparent',
      color: '#fff',
      bgColor: ThemeData.Swell.SwellLightBlue75,
    },
    success: {
      borderColor: '#4CAF50',
      color: '#4CAF50',
    },
    warning: {
      borderColor: '#FFC107',
      color: '#FFC107',
    },
    button: {
      borderColor: 'transparent',
      color: '#fff',
    },
    borderRadius: '50%',
    height: '20px',
    typography: css`
      /* Body/xsmall */
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 160%; /* 19.2px */
      letter-spacing: -0.36px;
    `,
  } as DefaultTheme['badge'],

  box: {
    bgColor: colors.oceanBlue['125'],
    borderColor: `transparent`,
    color: colors.white['125'],
    label: {
      color: colors.lightBlue['50'],
    },
    borderRadius: '16px',
  },

  bottomNav: {
    bgColor: colors.oceanBlue['125'],
    color: colors.white['50'],
    selectedColor: colors.white['50'],
  },

  pill: {
    error: {
      borderColor: colors.red['100'],
      color: colors.red['100'],
    },
    info: {
      borderColor: colors.lightBlue['100'],
      color: colors.lightBlue['100'],
    },
    success: {
      borderColor: '#4CAF50',
      color: '#4CAF50',
    },
    warning: {
      borderColor: '#FFC107',
      color: '#FFC107',
    },
    button: {
      borderColor: 'transparent',
      color: '#fff',
      bgColor: colors.lightBlue['100'],
    },
    typography: css`
      /* Body/small */
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      letter-spacing: -0.42px;
    `,
  } as DefaultTheme['pill'],

  progressBar: {
    barColor: colors.lightBlue['100'],
    bgColor: colors.oceanBlue['125'],
    borderRadius: '11px',
    barHeight: '22px',
    edgeLabelColor: colors.white['50'],
    barLabelGap: '6px',
    timeLabelColor: colors.white['50'],
    barBorderRadius: '11px',
    borderColor: colors.lightBlue['50'],
  } as DefaultTheme['progressBar'],
  button: {
    accent: {
      bgColor: colors.oceanBlue['125'],
      color: colors.white['50'],
    },
    small: {
      fontSize: typography.body.medium.fontSize,
      lineHeight: typography.body.medium.lineHeight,
    },
    medium: {
      fontSize: typography.body.medium.fontSize,
      lineHeight: typography.body.medium.lineHeight,
    },
    large: {
      fontSize: typography.body.large.fontSize,
      lineHeight: typography.body.large.lineHeight,
    },
    primary: {
      bgColor: colors.lightBlue['100'],
      color: colors.white['50'],
      hoverOpacity: 0.7,
      activeOpacity: 0.5,
      disabledBgColor: '#131B5E',
      disabledColor: colors.white['150'],
      disabledOpacity: 0.5,
    },
    secondary: {
      bgColor: 'transparent',
      color: colors.white['50'],
      disabledColor: colors.white['50'],
      hoverOpacity: 0.7,
      activeOpacity: 0.5,
      disabledOpacity: 0.2,
    },
    tertiary: {
      bgColor: 'transparent',
      color: colors.white['50'],
      hoverOpacity: 0.7,
      activeOpacity: 0.5,
      disabledOpacity: 0.2,
    },
  },

  chip: {
    color: colors.white['125'],
    filledBgColor: 'rgba(241,241,241,0.1)',
    filledColor: colors.white['50'],
  },

  colors,

  dataBoxGrid: {
    dataBox: {
      color: colors.white['50'],
      bgColor: 'rgba(255, 255, 255, 0.05)',
      dataColor: colors.white['125'],
    },
  },

  dialog: {
    bgColor: colors.oceanBlue['125'],
    borderColor: colors.lightBlue['50'],
    borderRadius: '16px',
    backdrop: {
      bgColor: 'rgba(11, 20, 27, 0.7)',
      backdropFilter: 'blur(10.5px)',
    },
    title: {
      color: colors.white['125'],
    },
  },

  divider: {
    darkColor: colors.auxGrey,
    lightColor: colors.auxGrey,
  },

  drawer: {
    bgColor: colors.oceanBlue['125'],
    backdrop: {
      bgColor: 'rgba(11, 20, 27, 0.7)',
      backdropFilter: 'blur(10.5px)',
    },
  },

  footer: {
    link: {
      color: colors.lightBlue['50'],
    },
  },

  input: {
    underlineColor: colors.white['50'],
    underlineFocusColor: colors.lightBlue['100'],
    outlined: {
      border: '1px solid rgba(255, 255, 255, 0.05)',
      borderRadius: '8px',
    },
  },

  menu: {
    bgColor: colors.oceanBlue['125'],
    color: colors.white['50'],
    borderColor: '#424557',
    borderRadius: '16px',
  },

  navigation: navigationDark,

  pagination: {
    item: {
      color: colors.white['50'],
      bgColor: colors.lightBlue['100'],
    },
  },

  select: {
    input: {
      bgColor: '',
      color: '',
      borderRadius: '',
      disabledBgColor: '',
      disabledColor: '',
      disabledBorder: '',
      focusedBorder: '',
      hoverBorder: '',
    },
    menu: {
      bgColor: '',
      border: '',
      borderGradientDirection: '',
      borderIsGradient: false,
      borderRadius: '',
      gapPx: 0,
    },
    menuItem: {
      typography: css``,
    },
  } as DefaultTheme['select'],
  scrollbar: {
    trackBg: 'rgba(255, 255, 255, 0.05)',
    thumbBg: 'rgba(255, 255, 255, 0.1)',
    width: '8px',
    borderRadius: '6px',
  },

  toast: toastDark,

  tabs: {
    color: colors.white['125'],
    hoverColor: colors.white['50'],
  },

  table: {
    head: {
      color: colors.lightBlue['50'],
      secondaryColor: colors.white['125'],
    },
    cell: {
      bgColor: colors.oceanBlue['100'],
    },
    row: {},
  },

  tokenList: {
    rowHoverColor: colors.oceanBlue['100'],
  },

  typography,

  typographyCSS: {
    coloredHeading: css`
      color: var(--lightblue-50, #a4abf1);
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 160%; /* 22.4px */
      letter-spacing: 1.05px;
      text-transform: uppercase;
    `,
  } as DefaultTheme['typographyCSS'],

  yearnAeraVault: {
    rewardsGlowCSS: css``,
  } as DefaultTheme['yearnAeraVault'],

  searchInput: {
    bgColor: 'transparent',
    magnifyingGlassColor: '#F1F1F1',
    borderRadius: '100px',
    magnifyingGlassStyle: 1,
    color: '#fff',
    height: '42px',
    border: '1px solid rgba(129, 129, 129, 0.3)',
  } as DefaultTheme['searchInput'],

  stakingBackground: {
    leftBlobScale: 1,
    leftBlobY: 280,
    rightBlobY: -90,
    rightBlobScale: 1,
    leftBlobX: 0,
    rightBlobX: 0,
  } as DefaultTheme['stakingBackground'],
})

export const restakeTheme: DefaultTheme = createTheme({
  mainBgColor: colors.eigenLayer['aliceBlue'],
  mainColor: colors.eigenLayer['federalBlue'],
  secondaryBgColor: 'rgb(14, 20, 26)',
  accentBgColor: 'rgb(41, 50, 73)',
  accentColor: 'rgb(200, 200, 200)',
  fiatColor: colors.lightBlue['50'],

  a: {
    color: '#180d6880',
    hoverColor: colors.eigenLayer['federalBlue'],
  },

  accordionTable: {
    summaryBgColor: colors.eigenLayer['aliceBlue'],
    expandedBgColor: colors.eigenLayer['aliceBlue'],
  },

  alert: {
    success: {
      bgColor: colors.lightBlue['125'],
      linkHoverColor: colors.white['100'],
    },
    error: {
      bgColor: colors.red['100'],
    },
  },

  badge: {
    error: {
      borderColor: colors.red['100'],
      color: colors.red['100'],
    },
    info: {
      borderColor: colors.lightBlue['100'],
      color: colors.lightBlue['100'],
    },
    success: {
      borderColor: '#4CAF50',
      color: '#4CAF50',
    },
    warning: {
      borderColor: '#FFC107',
      color: '#FFC107',
    },
    button: {
      borderColor: 'transparent',
      color: '#fff',
    },
    borderRadius: '50%',
    height: '20px',
    typography: css`
      /* Body/xsmall */
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 160%; /* 19.2px */
      letter-spacing: -0.36px;
    `,
  } as DefaultTheme['badge'],

  box: {
    bgColor: colors.white['50'],
    borderRadius: '16px',
    border: 'none',
    color: colors.eigenLayer['federalBlue'],
    label: {
      color: colors.eigenLayer['federalBlue'],
    },
  },

  bottomNav: {
    bgColor: colors.white['50'],
    color: colors.white['50'],
    selectedColor: colors.eigenLayer['federalBlue'],
  },

  button: {
    accent: {
      bgColor: 'transparent',
      border: `1px solid ${colors.eigenLayer['federalBlue']}`,
      color: colors.eigenLayer['federalBlue'],
    },
    small: {
      fontSize: typography.body.medium.fontSize,
      lineHeight: typography.body.medium.lineHeight,
    },
    medium: {
      fontSize: typography.body.medium.fontSize,
      lineHeight: typography.body.medium.lineHeight,
    },
    large: {
      fontSize: typography.body.large.fontSize,
      lineHeight: typography.body.large.lineHeight,
    },
    primary: {
      bgColor: colors.eigenLayer['yellow'],
      color: colors.eigenLayer['federalBlue'],
      hoverOpacity: 0.7,
      activeOpacity: 0.5,
      disabledBgColor: 'rgba(255, 188, 1, 0.5)',
      disabledColor: 'rgba(24, 13, 104, 0.5)',
      disabledOpacity: 0.5,
    },
    secondary: {
      bgColor: 'transparent',
      color: colors.eigenLayer['federalBlue'],
      disabledColor: colors.eigenLayer['federalBlue'],
      hoverOpacity: 0.7,
      activeOpacity: 0.5,
      disabledOpacity: 0.2,
    },
    tertiary: {
      bgColor: 'transparent',
      color: colors.eigenLayer['federalBlue'],
      hoverOpacity: 0.7,
      activeOpacity: 0.5,
      disabledOpacity: 0.2,
    },
  },

  chip: {
    color: colors.eigenLayer['federalBlue'],
    filledBgColor: colors.eigenLayer['aliceBlue'],
    filledColor: colors.eigenLayer['federalBlue'],
  },

  colors,

  dataBoxGrid: {
    dataBox: {
      color: colors.eigenLayer['federalBlue'],
      bgColor: 'rgba(255, 255, 255, 0.05)',
      dataColor: colors.lightBlue['100'],
    },
  },

  dialog: {
    bgColor: colors.white['50'],
    borderColor: colors.eigenLayer['federalBlue'],
    borderRadius: '16px',
    backdrop: {
      bgColor: 'rgba(11, 20, 27, 0.7)',
      backdropFilter: 'blur(10.5px)',
    },
    title: {
      color: colors.eigenLayer['federalBlue'],
    },
  },

  divider: {
    darkColor: colors.eigenLayer['federalBlue'],
    lightColor: colors.eigenLayer['platinum'],
  },

  drawer: {
    bgColor: colors.eigenLayer['aliceBlue'],
    backdrop: {
      bgColor: 'rgba(11, 20, 27, 0.7)',
      backdropFilter: 'blur(10.5px)',
    },
  },

  footer: {
    link: {
      color: colors.eigenLayer['federalBlue'],
    },
  },

  input: {
    underlineColor: colors.eigenLayer['federalBlue'],
    underlineFocusColor: colors.lightBlue['100'],
    outlined: {
      border: `1px solid ${colors.lightBlue['50']}`,
    },
  },

  menu: {
    bgColor: colors.white['50'],
    color: colors.eigenLayer['federalBlue'],
    borderColor: '#424557',
    borderRadius: '16px',
  },

  navigation: navigationLight,

  pagination: {
    item: {
      color: colors.eigenLayer['federalBlue'],
      bgColor: colors.eigenLayer['yellow'],
    },
  },

  pill: {
    error: {
      borderColor: colors.red['100'],
      color: colors.red['100'],
    },
    info: {
      borderColor: colors.lightBlue['100'],
      color: colors.lightBlue['100'],
    },
    success: {
      borderColor: '#4CAF50',
      color: '#4CAF50',
    },
    warning: {
      borderColor: '#FFC107',
      color: '#FFC107',
    },
    button: {
      borderColor: 'transparent',
      color: '#fff',
    },
    bgColor: colors.lightBlue['100'],
  } as DefaultTheme['pill'],
  select: {
    input: {
      bgColor: '',
      color: '',
      borderRadius: '',
      disabledBgColor: '',
      disabledColor: '',
      disabledBorder: '',
      focusedBorder: '',
      hoverBorder: '',
      border: '',
      height: '',
      padding: '',
    },
    menu: {
      bgColor: '',
      border: '',
      borderGradientDirection: '',
      borderIsGradient: false,
      borderRadius: '',
      gapPx: 0,
    },
    menuItem: {
      typography: css``,
    },
  } as DefaultTheme['select'],

  progressBar: {
    barColor: colors.lightBlue['100'],
    bgColor: colors.oceanBlue['125'],
    borderRadius: '11px',
    barHeight: '22px',
    edgeLabelColor: colors.white['50'],
    barLabelGap: '6px',
    timeLabelColor: colors.white['50'],
    barBorderRadius: '11px',
    borderColor: colors.lightBlue['50'],
  } as DefaultTheme['progressBar'],

  tabs: {
    color: '#180d6880',
    hoverColor: colors.eigenLayer['federalBlue'],
  },

  table: {
    head: {
      color: colors.lightBlue['100'],
      secondaryColor: colors.lightBlue['100'],
    },
    cell: {
      bgColor: colors.white['50'],
    },
    row: {},
  },

  toast: toastLight,

  tokenList: {
    rowHoverColor: colors.eigenLayer['aliceBlue'],
  },

  typography,

  typographyCSS: {
    coloredHeading: css`
      color: var(--Swell-Lightblue-75, #6e79e9);
      /* Body/uppercase medium */
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 160%; /* 22.4px */
      letter-spacing: 1.05px;
      text-transform: uppercase;
    `,
  } as DefaultTheme['typographyCSS'],
  yearnAeraVault: {
    rewardsGlowCSS: css``,
  } as DefaultTheme['yearnAeraVault'],
})

export const btcLrtTheme: DefaultTheme = cloneDeep(darkTheme)
btcLrtTheme.typographyCSS.coloredHeading = css`
  color: ${ThemeData.Swell.SwellLightBlue75};
  /* Body/uppercase medium */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 22.4px */
  letter-spacing: 1.05px;
  text-transform: uppercase;
`
btcLrtTheme.fiatColor = ThemeData.Swell.SwellLightBlue75

btcLrtTheme.a.hoverColor = ThemeData.Swell.SwellLightBlue75
btcLrtTheme.tabs.hoverColor = ThemeData.Swell.SwellLightBlue75

btcLrtTheme.button.primary.bgColor = ThemeData.Symbiotic.symbioticBtcOrange
btcLrtTheme.button.primary.disabledBgColor = transparentize(
  0.3,
  ThemeData.Symbiotic.symbioticBtcOrange
)
btcLrtTheme.button.primary.disabledColor = transparentize(0.3, '#000')
btcLrtTheme.button.primary.color =
  ThemeData.Symbiotic.symbioticButtonPrimaryTextColor

btcLrtTheme.button.accent.bgColor = 'transparent'
btcLrtTheme.button.accent.color = '#fff'
btcLrtTheme.button.accent.border = `1px solid rgba(255, 255, 255, 0.30)`

btcLrtTheme.box.borderColor = 'transparent'
btcLrtTheme.box.borderRadius = ThemeData.Symbiotic.symbioticBorderRadius
btcLrtTheme.box.bgColor = ThemeData.Symbiotic.symbioticBitcoinDark

btcLrtTheme.drawer.backdrop.backdropFilter =
  ThemeData.Symbiotic.SymbioticBackdropFilter
btcLrtTheme.drawer.backdrop.bgColor = ThemeData.Symbiotic.symbioticBackdropColor
btcLrtTheme.drawer.bgColor = ThemeData.Symbiotic.symbioticBlack

btcLrtTheme.menu.bgColor = ThemeData.Symbiotic.symbioticBlack
btcLrtTheme.menu.borderColor = 'rgba(129, 129, 129, 0.30)'
btcLrtTheme.menu.borderRadius = ThemeData.Symbiotic.symbioticBorderRadius

btcLrtTheme.input.outlined.borderRadius =
  ThemeData.Symbiotic.symbioticBorderRadius
btcLrtTheme.input.underlineFocusColor = ThemeData.Symbiotic.symbioticBtcOrange

btcLrtTheme.dialog.borderRadius = ThemeData.Symbiotic.symbioticBorderRadius
btcLrtTheme.dialog.bgColor = ThemeData.Symbiotic.symbioticBlack
btcLrtTheme.dialog.borderColor = ThemeData.Symbiotic.symbioticBtcOrange

btcLrtTheme.scrollbar = {
  trackBg: ThemeData.Symbiotic.symbioticBlack,
  thumbBg: ThemeData.Symbiotic.symbioticBtcOrange,
  width: '8px',
  borderRadius: '0',
}

btcLrtTheme.navigation = navigationDark
btcLrtTheme.divider.lightColor = 'rgba(255, 255, 255, 0.20)'
btcLrtTheme.divider.darkColor = ThemeData.Swell.SwellLightBlue

btcLrtTheme.yearnAeraVault.rewardsGlowCSS = css`
  border: 1px solid ${ThemeData.Symbiotic.symbioticBtcOrange};
  background: ${ThemeData.Symbiotic.symbioticBitcoinDark};
`
btcLrtTheme.progressBar.barColor = ThemeData.Symbiotic.symbioticBtcOrange
btcLrtTheme.progressBar.borderColor = ThemeData.Symbiotic.symbioticDarkGrey
btcLrtTheme.progressBar.barHeight = '18px'

export const rswellTheme: DefaultTheme = cloneDeep(btcLrtTheme)
rswellTheme.button.primary.bgColor = '#fff'
rswellTheme.button.primary.disabledBgColor = transparentize(0.3, '#fff')
rswellTheme.button.primary.disabledColor = transparentize(0.3, '#000')
rswellTheme.button.primary.color = ThemeData.Swell.SwellOceanBlue

rswellTheme.button.accent.bgColor = 'transparent'
rswellTheme.button.accent.color = '#fff'
rswellTheme.button.accent.border = `1px solid rgba(255, 255, 255, 0.30)`

rswellTheme.fiatColor = ThemeData.Swell.SwellLightBlue75
rswellTheme.typographyCSS.coloredHeading = css`
  color: ${ThemeData.Swell.SwellLightBlue75};
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 22.4px */
  letter-spacing: 1.05px;
  text-transform: uppercase;
`
rswellTheme.tabs.hoverColor = '#fff'
rswellTheme.a.hoverColor = '#fff'
rswellTheme.box.borderColor = ThemeData.Swell.SwellLightBlue75
rswellTheme.box.bgColor = '#0a1319'
rswellTheme.yearnAeraVault.rewardsGlowCSS = css`
  border: 1px solid #fff;
  background: #0a1319;
`
rswellTheme.divider.darkColor = ThemeData.Swell.SwellLightBlue100
rswellTheme.scrollbar = {
  trackBg: ThemeData.Swell.SwellOceanBlue150,
  thumbBg: ThemeData.Swell.SwellLightBlue100,
  width: '8px',
  borderRadius: '0',
}
rswellTheme.input.underlineFocusColor = darkTheme.input.underlineFocusColor
rswellTheme.progressBar.barColor = darkTheme.progressBar.barColor

export const portfolioTheme = cloneDeep(darkTheme)
portfolioTheme.box.bgColor = ThemeData.Swell.SwellOceanBlue150
portfolioTheme.box.borderColor = 'rgba(164, 171, 241, 0.20)'
portfolioTheme.table.head.bgColor = portfolioTheme.box.bgColor
portfolioTheme.table.head.borderColor = 'transparent'
portfolioTheme.table.head.padding = '0'
portfolioTheme.table.head.typography = css`
  color: var(--Swell-Lightblue-75, #6e79e9);
  /* Body/uppercase medium */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 22.4px */
  letter-spacing: 1.05px;
  text-transform: uppercase;
`

portfolioTheme.table.cell.bgColor = portfolioTheme.box.bgColor
portfolioTheme.table.cell.borderColor = 'rgba(255, 255, 255, 0.10)'
portfolioTheme.table.cell.padding = '12px 0 12px'
portfolioTheme.table.row.rowGradientStops = [
  {
    color: '#3068EF',
    offset: '0%',
  },
  {
    color: '#1322AC',
    offset: '100%',
  },
]

portfolioTheme.select = {
  input: {
    color: '#fff',
    height: '42px',
    bgColor: ThemeData.Swell.SwellOceanblue125,
    border: '1px solid #000',
    borderRadius: '100px',
    disabledBgColor: '',
    disabledColor: '',
    disabledBorder: '',
    focusedBorder: '',
    hoverBorder: '',
    padding: '16px',
  },
  menu: {
    bgColor: ThemeData.Swell.SwellOceanblue125,
    borderRadius: '16px',
    borderIsGradient: true,
    border: ['#3068EF', '#1322AC'],
    borderGradientDirection: 'to bottom',
    gapPx: 12,
  },
  menuItem: {
    typography: css`
      color: var(--Swell-White-50, #fff);
      /* Body/small */
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 160%; /* 22.4px */
      letter-spacing: -0.42px;
    `,
  },
}

portfolioTheme.pill.button.bgColor = ThemeData.Swell.SwellOceanblue125
portfolioTheme.pill.button.borderColor = 'transparent'
portfolioTheme.pill.borderRadius = '100px'
portfolioTheme.pill.height = '42px'
portfolioTheme.scrollbar = {
  trackBg: ThemeData.Swell.SwellOceanBlue150,
  thumbBg: ThemeData.Swell.SwellLightBlue100,
  width: '8px',
  borderRadius: '8px',
}

export const earnTheme: DefaultTheme = cloneDeep(darkTheme)
earnTheme.fiatColor = ThemeData.Swell.SwellVaultTurquoise

earnTheme.divider.lightColor = 'rgba(255, 255, 255, 0.20)'
earnTheme.divider.darkColor = transparentize(
  0.5,
  ThemeData.Swell.SwellVaultTurquoise
)

// earnTheme.box.bgColor = ThemeData.Swell.SwellVaultDarkCyan
earnTheme.box.bgColor = '#001214'
// earnTheme.box.bgColor = 'pink'
earnTheme.typographyCSS.coloredHeading = css`
  color: var(--Swell-Vault-Turquoise, #0ff);
  /* Body/uppercase medium */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 22.4px */
  letter-spacing: 1.05px;
  text-transform: uppercase;
`
earnTheme.scrollbar = {
  trackBg: '#002225',
  thumbBg: linearGradient({
    colorStops: ['#00FFFF', '#AEFFFF'],
    toDirection: 'to bottom',
  }) as any,
  width: '8px',
  borderRadius: '4px',
}
earnTheme.button.primary.bgColor = ThemeData.Swell.SwellVaultTurquoise
earnTheme.button.primary.disabledBgColor = transparentize(
  0.3,
  ThemeData.Swell.SwellVaultTurquoise
)
earnTheme.button.primary.disabledColor = transparentize(0.3, '#000')
earnTheme.button.primary.color = ThemeData.Swell.SwellOceanBlue

earnTheme.button.accent.bgColor = 'transparent'
earnTheme.button.accent.color = '#fff'
earnTheme.button.accent.border = `1px solid rgba(255, 255, 255, 0.30)`

earnTheme.table.head.color = ThemeData.Swell.SwellVaultTurquoise
earnTheme.table.head.bgColor = earnTheme.box.bgColor
earnTheme.table.head.borderColor = 'transparent'
earnTheme.table.head.padding = '0'
earnTheme.table.cell.bgColor = earnTheme.box.bgColor
earnTheme.table.cell.borderColor = 'rgba(255, 255, 255, 0.10)'
earnTheme.table.cell.padding = '12px 0'

earnTheme.menu.borderColor = ThemeData.Swell.SwellVaultTurquoise
earnTheme.menu.bgColor = ThemeData.Swell.SwellOceanBlue

earnTheme.progressBar.barColor = ThemeData.Swell.SwellVaultTurquoise
earnTheme.progressBar.borderColor = ThemeData.Swell.SwellVaultTurquoise
earnTheme.progressBar.edgeLabelColor = '#B0B0B0'
earnTheme.progressBar.timeLabelColor = '#fff'
earnTheme.progressBar.borderRadius = '11px'
earnTheme.progressBar.barBorderRadius = '11px'

earnTheme.pill.error.color = '#FF9D9D'
earnTheme.pill.error.borderColor = '#F2BAC9'
earnTheme.pill.success.color = ThemeData.Swell.SwellVaultTurquoise
earnTheme.pill.success.borderColor = ThemeData.Swell.SwellVaultTurquoise
earnTheme.pill.warning.color = '#F2E2BA'
earnTheme.pill.warning.borderColor = '#F2E2BA'
earnTheme.pill.info.color = '#fff'
earnTheme.pill.info.borderColor = '#fff'

export const rswethTheme = cloneDeep(darkTheme)
rswethTheme.button.primary.bgColor = ThemeData.Eigen.EigenLayerYellow
rswethTheme.button.primary.disabledBgColor = transparentize(
  0.3,
  ThemeData.Eigen.EigenLayerYellow
)
rswethTheme.button.primary.disabledColor = transparentize(0.3, '#000')
rswethTheme.button.primary.color = '#000'

rswethTheme.input.underlineFocusColor = ThemeData.Eigen.EigenLayerYellow
rswethTheme.divider.lightColor = 'rgba(255, 255, 255, 0.20)'
rswethTheme.divider.darkColor = ThemeData.Swell.SwellLightBlue

export const swethTheme = cloneDeep(darkTheme)
swethTheme.divider.darkColor = ThemeData.Swell.SwellLightBlue
swethTheme.scrollbar = {
  trackBg: '#16113F',
  thumbBg: ThemeData.Swell.SwellLightBlue100,
  width: '8px',
  borderRadius: '8px',
}

export const l2Theme = cloneDeep(darkTheme)
l2Theme.scrollbar = {
  trackBg: '#16113F',
  thumbBg: ThemeData.Swell.SwellLightBlue100,
  width: '8px',
  borderRadius: '8px',
}
l2Theme.pill.info.color = '#fff'
l2Theme.pill.info.borderColor = '#fff'
l2Theme.pill.padding = '8px 12px'
l2Theme.box.bgColor = 'rgba(12, 8, 39, 0.90)'
l2Theme.box.borderRadius = '12px'
l2Theme.divider.darkColor = ThemeData.Swell.SwellLightBlue100

l2Theme.searchInput = {
  bgColor: 'rgba(241, 241, 241, 0.05)',
  magnifyingGlassColor: '#F1F1F1',
  borderRadius: '12px',
  magnifyingGlassStyle: 2,
  color: '#fff',
  height: '56px',
  border: 'none',
} as DefaultTheme['searchInput']

export const l2ThemeEco = cloneDeep(l2Theme)
l2ThemeEco.stakingBackground = {
  leftBlobScale: 0.8,
  leftBlobY: 240,
  rightBlobY: -90,
  rightBlobScale: 1,
  leftBlobX: 0,
  rightBlobX: 0,
}
export const l2ThemeLaunch = cloneDeep(l2Theme)
l2ThemeLaunch.stakingBackground = {
  leftBlobX: -150,
  leftBlobY: 90,
  leftBlobScale: 0.9,

  rightBlobX: 200,
  rightBlobY: -190,
  rightBlobScale: 0.7,
}
export const wswellTheme = cloneDeep(l2ThemeLaunch)
wswellTheme.stakingBackground = {
  ...wswellTheme.stakingBackground,
  bottomBlobVisible: true,
}
