import { CountdownBoxClockV2 } from '@/swell-ui/CountdownClock/CountdownBoxClockV2'
import styled from 'styled-components'
import { SwellchainIncentivesSummary } from '../../types'
import { useNowMs } from '@/hooks/useTimeCountdown'
import { FlexRow } from '@/swell-ui/FlexRow'
import { TOKEN_LIST_SWELL } from '@/constants/tokens'

export function SwellchainIncentives({
  incentivesSummary,
}: {
  incentivesSummary: SwellchainIncentivesSummary
}) {
  const nowMs = useNowMs()
  return (
    <Layout>
      <FlexRow direction="column" justify="start" align="start" gap="24">
        <span className="body-small colored uppercased">
          Time until next distribution
        </span>
        <CountdownBoxClockV2
          toMs={incentivesSummary?.targetDateMs ?? 0}
          nowMs={nowMs}
          onExpiry="scramble"
          loading={!incentivesSummary?.targetDateMs}
        />
      </FlexRow>
      <FlexRow direction="column" justify="start" align="start" gap="24">
        <span className="body-small colored uppercased">
          Current wave distribution
        </span>
        <FlexRow
          style={{ flex: '1 0 auto' }}
          direction="column"
          justify="center"
        >
          <FlexRow gap="10" align="center" margin="0 0 16px 0">
            <img src={TOKEN_LIST_SWELL.logoURI} width="42" height="42" />
            <FlexRow gap="6" align="baseline">
              <span className="body-xlarge-bold">
                {incentivesSummary.amount}
              </span>
              <span className="body-medium-bold" style={{ display: 'none' }}>
                SWELL
              </span>
            </FlexRow>
          </FlexRow>
        </FlexRow>
      </FlexRow>
    </Layout>
  )
}

const Layout = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`
