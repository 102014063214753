import { Button, ButtonProps } from '@/swell-ui/Button'
import { CircularProgress } from '@/swell-ui/CircularProgress'
import styled from 'styled-components'
import { ConnectWalletButton } from '@/components/ConnectWalletButton'
import { SwitchChainButton } from '@/components/SwitchChainButton'
import { useWeb3Button } from '@/hooks/useWeb3Button'
import {
  NucleusApproveAssetForDeposit,
  NucleusApproveVaultTokenForAtomicQueue,
  NucleusCancelWithdraw,
  NucleusDeposit,
  NucleusRequestWithdraw,
} from '@/state/nucleusVault/hooks'
import {
  NucleusErrors,
  PreparedNucleusApproveAssetForDeposit,
  PreparedNucleusApproveVaultTokenForAtomicQueue,
  PreparedNucleusCancelWithdraw,
  PreparedNucleusDeposit,
  PreparedNucleusRequestWithdraw,
} from './nucleusCalls'
import { INucleusActiveWithdrawal } from './nucleusHooks'
import { PredepositCalls } from '@/state/predeposit/hooks'
import { PreparedWithdrawFromPredeposit } from '@/pages/Swellchain/swellchainCalls'
import useChainDetection from '@/hooks/useChainDetection'
import { NucleusVault } from '@/types/nucleus'
import { TokenKey } from '@/types/tokens'
import { useSwellWeb3 } from '@/swell-web3/core'

const StyledButtonThin = styled(Button)`
  width: 100%;
  height: unset;
  /* Body/medium bold */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%; /* 25.6px */
  padding: 8px 16px;
  height: 42px;

  &:disabled {
    opacity: 0.2;
  }
`

const StyledConnectButton = styled(ConnectWalletButton)`
  width: 100%;
`
const StyledSwitchChainButton = styled(SwitchChainButton)`
  width: 100%;
`

export function NucleusButton(props: ButtonProps) {
  return (
    <StyledButtonThin
      variant="primary"
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.children}
    </StyledButtonThin>
  )
}

export function NucleusConnectButton(props: ButtonProps) {
  return <StyledConnectButton {...props} variant="primary" />
}
export function NucleusSwitchChainButton() {
  const { switchToDeploymentChain } = useChainDetection()
  return (
    <StyledButtonThin variant="primary" onClick={switchToDeploymentChain}>
      Switch Chain
    </StyledButtonThin>
  )
}
export function NucleusSwitchChainButtonL2() {
  const { switchToL2DeploymentChain } = useChainDetection()
  return (
    <StyledButtonThin onClick={switchToL2DeploymentChain} variant="primary">
      Switch Chain
    </StyledButtonThin>
  )
}
const ButtonCircularProgress = styled(CircularProgress)`
  &.MuiCircularProgress-colorPrimary {
    color: currentColor;
  }
`
const ButtonInner = styled.span`
  position: relative;
  > div {
    position: absolute;
    top: -7px;
    left: -32px;
  }
`
export function NucleusApproveAssetForDepositButton({
  approveAssetForDeposit,
  prepared,
  preventInteraction,
  idleLabel = 'Approve',
}: {
  approveAssetForDeposit: NucleusApproveAssetForDeposit
  prepared: PreparedNucleusApproveAssetForDeposit
  preventInteraction?: boolean
  idleLabel?: string
}) {
  const btn = useWeb3Button(
    approveAssetForDeposit,
    approveAssetForDeposit.call,
    prepared.args,
    {
      idleLabel: 'Approve',
      pendingLabel: (
        <ButtonInner>
          <div>
            <ButtonCircularProgress size={24} />
          </div>
          <span>Confirming...</span>
        </ButtonInner>
      ),
      fulfilledLabel: 'Approve',
    }
  )
  return (
    <NucleusButton
      disabled={preventInteraction || btn.disabled}
      onClick={btn.onClick}
    >
      {btn.label}
    </NucleusButton>
  )
}
export function NucleusApproveVaultTokenForAtomicQueueButton({
  approveVaultTokenForAtomicQueue,
  prepared,
  preventInteraction,
  selectedToken,
  vault,
}: {
  approveVaultTokenForAtomicQueue: NucleusApproveVaultTokenForAtomicQueue
  prepared: PreparedNucleusApproveVaultTokenForAtomicQueue
  preventInteraction: boolean
  vault: NucleusVault
  selectedToken: TokenKey
}) {
  const { account } = useSwellWeb3()

  const btn = useWeb3Button(
    approveVaultTokenForAtomicQueue,
    approveVaultTokenForAtomicQueue.call,
    prepared.args,
    {
      idleLabel: 'Approve',
      pendingLabel: (
        <ButtonInner>
          <div>
            <ButtonCircularProgress size={24} />
          </div>
          <span>Confirming...</span>
        </ButtonInner>
      ),
      fulfilledLabel: 'Approve',
    }
  )

  if (account) {
    if (prepared.error === NucleusErrors.UnsupportedChain) {
      if (selectedToken.chainId === vault.vaultTokenL2.chainId) {
        return <NucleusSwitchChainButtonL2 />
      }
      if (selectedToken.chainId === vault.vaultToken.chainId) {
        return <NucleusSwitchChainButton />
      }
    }
  }

  return (
    <NucleusButton
      disabled={preventInteraction || btn.disabled}
      onClick={btn.onClick}
    >
      {btn.label}
    </NucleusButton>
  )
}

export function NucleusDepositButton({
  deposit,
  prepared,
  preventInteraction,
  idleLabel = 'Deposit',
  selectedToken,
  vault,
}: {
  deposit: NucleusDeposit
  prepared: PreparedNucleusDeposit
  preventInteraction: boolean
  idleLabel?: string
  vault: NucleusVault
  selectedToken: TokenKey | undefined
}) {
  const { account } = useSwellWeb3()

  const btn = useWeb3Button(deposit, deposit.call, prepared.args, {
    idleLabel: idleLabel,
    pendingLabel: (
      <ButtonInner>
        <div>
          <ButtonCircularProgress size={24} />
        </div>
        <span>Confirming...</span>
      </ButtonInner>
    ),
    fulfilledLabel: idleLabel,
  })

  if (account) {
    if (prepared.error === NucleusErrors.UnsupportedChain) {
      if (selectedToken?.chainId === vault.vaultTokenL2.chainId) {
        return <NucleusSwitchChainButtonL2 />
      }
      if (selectedToken?.chainId === vault.vaultToken.chainId) {
        return <NucleusSwitchChainButton />
      }
    }
  }

  return (
    <NucleusButton
      disabled={preventInteraction || btn.disabled}
      onClick={btn.onClick}
    >
      {btn.label}
    </NucleusButton>
  )
}
export function NucleusRequestWithdrawButton({
  requestWithdraw,
  prepared,
  preventInteraction,
  selectedToken,
  vault,
}: {
  requestWithdraw: NucleusRequestWithdraw
  prepared: PreparedNucleusRequestWithdraw
  preventInteraction: boolean
  vault: NucleusVault
  selectedToken: TokenKey
}) {
  const { account } = useSwellWeb3()

  const btn = useWeb3Button(
    requestWithdraw,
    requestWithdraw.call,
    prepared.args,
    {
      idleLabel: 'Request withdrawal',
      pendingLabel: (
        <ButtonInner>
          <div>
            <ButtonCircularProgress size={24} />
          </div>
          <span>Confirming...</span>
        </ButtonInner>
      ),
      fulfilledLabel: 'Requested withdrawal',
    }
  )

  if (account) {
    if (prepared.error === NucleusErrors.UnsupportedChain) {
      if (selectedToken.chainId === vault.vaultTokenL2.chainId) {
        return <NucleusSwitchChainButtonL2 />
      }
      if (selectedToken.chainId === vault.vaultToken.chainId) {
        return <NucleusSwitchChainButton />
      }
    }
  }

  return (
    <NucleusButton
      disabled={preventInteraction || btn.disabled}
      onClick={btn.onClick}
    >
      {btn.label}
    </NucleusButton>
  )
}

export function NucleusCancelWithdrawButton({
  cancelWithdraw,
  prepared,
  preventInteraction,
  selectedToken,
  vault,
}: {
  cancelWithdraw: NucleusCancelWithdraw
  prepared: PreparedNucleusCancelWithdraw
  preventInteraction: boolean
  vault: NucleusVault
  selectedToken: TokenKey
}) {
  const { account } = useSwellWeb3()

  const btn = useWeb3Button(
    cancelWithdraw,
    cancelWithdraw.call,
    prepared.args,
    {
      idleLabel: 'Cancel withdrawal',
      pendingLabel: (
        <ButtonInner>
          <div>
            <ButtonCircularProgress size={24} />
          </div>
          <span>Confirming...</span>
        </ButtonInner>
      ),
      fulfilledLabel: 'Canceled',
    }
  )

  if (account) {
    if (prepared.error === NucleusErrors.UnsupportedChain) {
      if (selectedToken.chainId === vault.vaultTokenL2.chainId) {
        return <NucleusSwitchChainButtonL2 />
      }
      if (selectedToken.chainId === vault.vaultToken.chainId) {
        return <NucleusSwitchChainButton />
      }
    }
  }

  return (
    <NucleusButton
      disabled={preventInteraction || btn.disabled}
      onClick={btn.onClick}
    >
      {btn.label}
    </NucleusButton>
  )
}

export function NucleusWithdrawFulfilledAckButton({
  activeWithdrawal,
}: {
  activeWithdrawal: INucleusActiveWithdrawal
}) {
  return (
    <NucleusButton onClick={activeWithdrawal.ackCompleted}>OK</NucleusButton>
  )
}

export function NucleusWithdrawFailedAckButton({
  activeWithdrawal,
}: {
  activeWithdrawal: INucleusActiveWithdrawal
}) {
  return <NucleusButton onClick={activeWithdrawal.ackFailed}>OK</NucleusButton>
}

export function NucleusSwellchainWithdrawPredepositButton({
  withdrawFromPredeposit,
  prepared,
  preventInteraction,
}: {
  withdrawFromPredeposit: PredepositCalls['withdrawFromPredeposit']
  prepared: PreparedWithdrawFromPredeposit
  preventInteraction?: boolean
}) {
  const btn = useWeb3Button(
    withdrawFromPredeposit,
    withdrawFromPredeposit.call,
    prepared.args,
    {
      idleLabel: 'Withdraw',
      pendingLabel: (
        <ButtonInner>
          <div>
            <ButtonCircularProgress size={24} />
          </div>
          <span>Confirming...</span>
        </ButtonInner>
      ),
      fulfilledLabel: 'Withdraw',
    }
  )

  return (
    <NucleusButton
      disabled={preventInteraction || btn.disabled}
      onClick={btn.onClick}
    >
      {btn.label}
    </NucleusButton>
  )
}
