// @generated by protoc-gen-connect-es v1.1.2 with parameter "target=ts"
// @generated from file swell/v3/stats.proto (package swell.v3, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { BridgeEventsRequest, BridgeEventsResponse, EarnAPYsRequest, EarnAPYsResponse, EarnStatsRequest, EarnStatsResponse, EarnVaultStatsRequest, EarnVaultStatsResponse, L2StatsRequest, L2StatsResponse, PollingBridgeEventsRequest, PollingBridgeEventsResponse, PriceRequest, PriceResponse, RemainingEigenlayerSwethRequest, RemainingEigenlayerSwethResponse, StatsServiceAllRequest, StatsServiceAllResponse, StatsServiceBtcLrtRequest, StatsServiceBtcLrtResponse, StatsServiceRswellRequest, StatsServiceRswellResponse, SuggestedGasPriceRequest, SuggestedGasPriceResponse, WavedropStatsRequest, WavedropStatsResponse } from "./stats_pb.js";
import { MethodIdempotency, MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service swell.v3.StatsService
 */
export const StatsService = {
  typeName: "swell.v3.StatsService",
  methods: {
    /**
     * @generated from rpc swell.v3.StatsService.PollingBridgeEvents
     */
    pollingBridgeEvents: {
      name: "PollingBridgeEvents",
      I: PollingBridgeEventsRequest,
      O: PollingBridgeEventsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc swell.v3.StatsService.All
     */
    all: {
      name: "All",
      I: StatsServiceAllRequest,
      O: StatsServiceAllResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc swell.v3.StatsService.EarnAPYs
     */
    earnAPYs: {
      name: "EarnAPYs",
      I: EarnAPYsRequest,
      O: EarnAPYsResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc swell.v3.StatsService.RemainingEigenlayerSweth
     */
    remainingEigenlayerSweth: {
      name: "RemainingEigenlayerSweth",
      I: RemainingEigenlayerSwethRequest,
      O: RemainingEigenlayerSwethResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc swell.v3.StatsService.BtcLrt
     */
    btcLrt: {
      name: "BtcLrt",
      I: StatsServiceBtcLrtRequest,
      O: StatsServiceBtcLrtResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc swell.v3.StatsService.Rswell
     */
    rswell: {
      name: "Rswell",
      I: StatsServiceRswellRequest,
      O: StatsServiceRswellResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc swell.v3.StatsService.WavedropStats
     */
    wavedropStats: {
      name: "WavedropStats",
      I: WavedropStatsRequest,
      O: WavedropStatsResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc swell.v3.StatsService.EarnStats
     */
    earnStats: {
      name: "EarnStats",
      I: EarnStatsRequest,
      O: EarnStatsResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc swell.v3.StatsService.EarnVaultEarnETHStats
     */
    earnVaultEarnETHStats: {
      name: "EarnVaultEarnETHStats",
      I: EarnVaultStatsRequest,
      O: EarnVaultStatsResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc swell.v3.StatsService.EarnVaultEarnBTCStats
     */
    earnVaultEarnBTCStats: {
      name: "EarnVaultEarnBTCStats",
      I: EarnVaultStatsRequest,
      O: EarnVaultStatsResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc swell.v3.StatsService.SuggestedGasPrice
     */
    suggestedGasPrice: {
      name: "SuggestedGasPrice",
      I: SuggestedGasPriceRequest,
      O: SuggestedGasPriceResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc swell.v3.StatsService.Prices
     */
    prices: {
      name: "Prices",
      I: PriceRequest,
      O: PriceResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc swell.v3.StatsService.BridgeEvents
     */
    bridgeEvents: {
      name: "BridgeEvents",
      I: BridgeEventsRequest,
      O: BridgeEventsResponse,
      kind: MethodKind.ServerStreaming,
    },
    /**
     * @generated from rpc swell.v3.StatsService.L2Stats
     */
    l2Stats: {
      name: "L2Stats",
      I: L2StatsRequest,
      O: L2StatsResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
  }
} as const;

