import React, { useEffect, useMemo, useState } from 'react'
import styled, { css } from 'styled-components'
import { EthIcon } from '@swell-ui/icons/EthIcon'
import { isAddress } from '@/util/hexStrings'
import { getAddress } from 'ethers/lib/utils'
import { Token } from '@/types/tokens'
import { getChainIcon } from '@/constants/chainInfo'
import { ChainId } from '@/constants/chains'

const MIN_SIZE = 24
const MIN_CHAIN_SIZE = 12
const DEFAULT_SIZE = 24

export function TokenLogoV2({
  token,
  size: sizeProp,
  className,
  ...props
}: {
  token?: Pick<Token, 'address' | 'logoURI' | 'chainId' | 'symbol'>
  size?: number | `${number}px`
  className?: string
}) {
  let sizePx: number | undefined
  if (typeof sizeProp === 'string') {
    try {
      sizePx = parseInt(sizeProp.replace('px', ''))
    } catch (e) {
      console.error('Failed to parse size prop', sizeProp)
    }
  }
  if (typeof sizeProp === 'number') {
    sizePx = sizePx ?? sizeProp
  }
  if (!sizePx) {
    sizePx = DEFAULT_SIZE
  }
  if (sizePx < MIN_SIZE) {
    sizePx = MIN_SIZE
  }

  let chainSizePx = sizePx / 3
  if (chainSizePx < MIN_CHAIN_SIZE) {
    chainSizePx = MIN_CHAIN_SIZE
  }

  const tokenAddress = token?.address
  const logoURI = token?.logoURI
  const chainId = token?.chainId

  const urlOptions: string[] = []
  if (logoURI) {
    urlOptions.push(logoURI)
  }
  if (isAddress(tokenAddress) && !logoURI && chainId === ChainId.MAINNET) {
    urlOptions.push(
      `https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/ethereum/assets/${getAddress(
        tokenAddress!
      )}/logo.png`
    )
  }

  const [fallbackIndex, setFallbackIndex] = useState<number>(0)
  const chainIcon = useMemo(() => {
    let i: ReturnType<typeof getChainIcon> | undefined
    if (chainId) {
      i = getChainIcon(chainId)
    }
    return i ?? { shape: 'circle', uri: '' }
  }, [chainId])
  useEffect(() => {
    setFallbackIndex(0)
  }, [tokenAddress])

  const src =
    fallbackIndex < urlOptions.length ? urlOptions[fallbackIndex] : undefined

  const onImgError = () => {
    setFallbackIndex(fallbackIndex + 1)
  }

  if (token?.symbol === 'ETH') {
    return (
      <LogoImgContainer
        sizePx={sizePx}
        data-qa="token-img"
        data-index={fallbackIndex}
      >
        <StyledEthIcon size={sizePx} {...props} />
      </LogoImgContainer>
    )
  }

  return (
    <LogoImgContainer
      sizePx={sizePx}
      data-qa="token-img"
      data-index={fallbackIndex}
      className={className}
    >
      {src && (
        <LogoImg src={src} onError={onImgError} sizePx={sizePx} {...props} />
      )}
      {!src && (
        <MissingLogoImg size={sizePx}>
          {token?.symbol.slice(0, 3) ?? ''}
        </MissingLogoImg>
      )}
      {chainId !== ChainId.MAINNET && chainIcon.uri && (
        <img
          className="btl-chain"
          width={chainSizePx}
          height={chainSizePx}
          src={chainIcon.uri}
          style={{ borderRadius: chainIcon.shape === 'square' ? 0 : '50%' }}
        />
      )}
      {chainId !== ChainId.MAINNET && !chainIcon.uri && (
        <div
          className="btl-chain unknown"
          style={{
            width: chainSizePx,
            height: chainSizePx,
            borderRadius: '50%',
          }}
        />
      )}
    </LogoImgContainer>
  )
}

const LogoImgContainer = styled.div<{ sizePx: number }>`
  ${({ sizePx }) => css`
    height: ${sizePx}px;
    width: ${sizePx}px;
  `};

  position: relative;
  .btl-chain {
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translate(25%, 25%);

    &.unknown {
      background: ${({ theme }) => theme.colors.black['50']};
    }
  }
`

const LogoImg = styled.img<{ sizePx: number }>`
  border-radius: 50%;
  ${({ sizePx }) => css`
    height: ${sizePx}px;
    width: ${sizePx}px;
  `}
`

const MissingLogoImg = styled.div<{ size: number }>`
  border-radius: 100px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.black['50']};

  ${({ size }) => css`
    height: ${size}px;
    width: ${size}px;
  `}
`

const StyledEthIcon = styled(EthIcon)<{ sizePx: number }>`
  ${({ size }) => css`
    height: ${size}px;
    width: ${size}px;
  `}
`
