import { useDeploymentSetConfig } from './useDeploymentSetConfig'

export function usePredepositSettings() {
  const {
    v3BackendLstUrl,
    v3BackendLrtUrl,
    addresses: { preDepositStaking },
  } = useDeploymentSetConfig()
  return {
    predepositBackendURL: v3BackendLstUrl,
    pointsURL: v3BackendLrtUrl,
    preDepositStaking,
  }
}
