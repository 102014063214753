import { WSWELLLockSummary, WSWELLUserSummary } from './types'
import { EVKUser } from '@/types/eulerEVK'
import { MerklUser } from '@/types/merkl'

export function makeWSWELLUserSummary({
  merklUser,
  evkUser,
  account,
  lockDurationMs,
  nowMs,
}: {
  merklUser: Pick<MerklUser, 'unclaimed'> | undefined
  evkUser: EVKUser | undefined
  account: string | undefined
  lockDurationMs: number
  nowMs: number
}): WSWELLUserSummary {
  const s: WSWELLUserSummary = {
    unclaimedWSwell: '-',
    locks: [],
    locksLoaded: true,
    totalWSwellToUnlock: '-',
  }

  if (!account) {
    return s
  }

  if (merklUser) {
    s.unclaimedWSwell = merklUser.unclaimed
  } else {
    s.unclaimedWSwell = ''
  }
  if (evkUser) {
    s.locksLoaded = true
    s.totalWSwellToUnlock = evkUser.totalToUnlock
    const locks = [...evkUser.locks]

    for (const lock of locks) {
      const {
        lockTimestampUnix,
        lockedAmount,
        accountAmount,
        formattedMaturityDate,
        unlockablePercent,
        forfeitPercent,
        remainderAmount,
      } = lock

      const unlockDateMs = lockTimestampUnix * 1000 + lockDurationMs

      const l: WSWELLLockSummary = {
        lockTimestampUnix,
        amountToMature: lockedAmount,
        unlockDateMs,
        currentUnlockAmount: accountAmount,
        percentUnlocked: unlockablePercent,
        unlockDateFormatted: formattedMaturityDate,
        isMatured: nowMs >= unlockDateMs,
        percentForfeit: forfeitPercent,
        currentForfeitAmount: remainderAmount,
      }
      s.locks.push(l)
    }
  } else {
    s.totalWSwellToUnlock = ''
    s.locksLoaded = false
  }

  // sort locks by unlock date
  s.locks.sort((a, b) => a.unlockDateMs - b.unlockDateMs)

  return s
}
