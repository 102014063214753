import {
  AccountantWithRateProviders__factory,
  AtomicQueue__factory,
  Multicall3,
  TellerWithMultiAssetSupport__factory,
} from '@/abis/types'
import { BigNumber } from 'ethers'
import {
  NucleusRates,
  NucleusSupportedTokenMap,
  NucleusTokens,
  NucleusVault,
  NucleusWithdrawRequestResultMap,
} from '@/types/nucleus'
import { Token, TokenKey } from '@/types/tokens'

export class NucleusVaultService {
  private multicall: Multicall3
  private multicallL2: Multicall3
  private atomicQueueAddress: string
  private vault: NucleusVault
  private l1ChainId: number
  private l2ChainId: number

  constructor({
    atomicQueueAddress,
    multicall,
    vault,
    l1ChainId,
    l2ChainId,
    multicallL2,
  }: {
    multicall: Multicall3
    atomicQueueAddress: string
    vault: NucleusVault
    l1ChainId: number
    l2ChainId: number
    multicallL2: Multicall3
  }) {
    this.multicall = multicall
    this.atomicQueueAddress = atomicQueueAddress
    this.vault = vault
    this.l1ChainId = l1ChainId
    this.l2ChainId = l2ChainId
    this.multicallL2 = multicallL2
  }

  private supportedTokensOnChain = async ({
    chainId,
  }: {
    chainId: number
  }): Promise<NucleusSupportedTokenMap[number]> => {
    let multicall: Multicall3
    let tokens: Token[]
    let tellerAddress: string
    if (chainId === this.l1ChainId) {
      multicall = this.multicall
      tokens = this.vault.depositAssets.filter(
        (t) => t.chainId === this.l1ChainId
      )
      tellerAddress = this.vault.tellerAddress
    } else if (chainId === this.l2ChainId) {
      multicall = this.multicallL2
      tokens = this.vault.depositAssets.filter(
        (t) => t.chainId === this.l2ChainId
      )
      tellerAddress = this.vault.tellerAddressL2
    } else {
      throw new Error(`unsupported chainId ${chainId}`)
    }

    const indexToTokenAddress = new Map<number, string>()
    const calls: Multicall3.Call3Struct[] = []

    const tellerIface = TellerWithMultiAssetSupport__factory.createInterface()

    tokens.forEach((t, index) => {
      const addr = t.address
      indexToTokenAddress.set(index, addr)
      calls.push({
        target: tellerAddress,
        callData: tellerIface.encodeFunctionData('isSupported', [addr]),
        allowFailure: false,
      })
    })

    const results = await multicall.callStatic.tryAggregate(false, calls)

    const supported: NucleusSupportedTokenMap[number] = {}

    for (let i = 0; i < results.length; i++) {
      const address = indexToTokenAddress.get(i)
      if (!address) throw new Error(`missing address for index ${i}`)
      const result = results[i]
      supported[address] = {
        ...tokens[i],
        isSupported: tellerIface.decodeFunctionResult(
          'isSupported',
          result.returnData
        )[0],
      }
    }

    return supported
  }

  supportedTokens = async (): Promise<NucleusTokens> => {
    const supportedL1P = this.supportedTokensOnChain({
      chainId: this.l1ChainId,
    })
    const supportedL2P = this.supportedTokensOnChain({
      chainId: this.l2ChainId,
    })
    const supportedL1 = await supportedL1P
    const supportedL2 = await supportedL2P

    const nc: NucleusTokens = {
      deposit: {
        [this.l1ChainId]: supportedL1,
        [this.l2ChainId]: supportedL2,
      },
      withdraw: {
        [this.l1ChainId]: {},
        [this.l2ChainId]: {},
      },
    }
    for (const token of this.vault.withdrawAssets) {
      if (
        token.chainId !== this.l1ChainId &&
        token.chainId !== this.l2ChainId
      ) {
        console.warn(
          `unsupported chainId ${token.chainId} for token ${token.address}`
        )
        continue
      }

      if (token.chainId === this.l1ChainId) {
        if (!nc.withdraw[token.chainId]) {
          nc.withdraw[token.chainId] = {}
        }
        nc.withdraw[token.chainId][token.address] = {
          ...token,
          isSupported: true,
        }
        continue
      }
      if (!nc.withdraw[token.chainId]) {
        nc.withdraw[token.chainId] = {}
      }

      nc.withdraw[token.chainId][token.address] = {
        ...token,
        isSupported: true,
      }
    }
    return nc
  }

  private withdrawalRequestsByWantTokenOnChain = async ({
    wantTokens,
    chainId,
    user,
  }: {
    wantTokens: string[]
    chainId: number
    user: string
  }): Promise<NucleusWithdrawRequestResultMap[number]> => {
    let multicall: Multicall3
    let tokens: Token[]
    let atomicQueueAddress: string
    let vaultTokenAddress: string
    if (chainId === this.l1ChainId) {
      multicall = this.multicall
      tokens = this.vault.depositAssets.filter(
        (t) => t.chainId === this.l1ChainId
      )
      atomicQueueAddress = this.atomicQueueAddress
      vaultTokenAddress = this.vault.vaultToken.address
    } else if (chainId === this.l2ChainId) {
      multicall = this.multicallL2
      tokens = this.vault.depositAssets.filter(
        (t) => t.chainId === this.l2ChainId
      )
      atomicQueueAddress = this.atomicQueueAddress
      vaultTokenAddress = this.vault.vaultTokenL2.address
    } else {
      throw new Error(`unsupported chainId ${chainId}`)
    }

    tokens = tokens.filter(
      (t) => wantTokens.includes(t.address) && t.chainId === chainId
    )
    if (tokens.length === 0) {
      return {}
    }

    const resultMap: NucleusWithdrawRequestResultMap[number] = {}
    for (const asset of tokens) {
      resultMap[asset.address] = { exists: false }
    }

    const aqIface = AtomicQueue__factory.createInterface()

    const calls: Multicall3.Call3Struct[] = []
    for (const wantToken of tokens) {
      const _user = user
      const _offer = vaultTokenAddress
      const _want = wantToken.address

      const target = atomicQueueAddress

      calls.push({
        target,
        callData: aqIface.encodeFunctionData('getUserAtomicRequest', [
          _user,
          _offer,
          _want,
        ]),
        allowFailure: false,
      })
    }

    const results = await multicall.callStatic.tryAggregate(true, calls)

    for (let i = 0; i < results.length; i++) {
      const wantToken = wantTokens[i]
      const result = results[i]
      const atomicRequest = aqIface.decodeFunctionResult(
        'getUserAtomicRequest',
        result.returnData
      )[0]

      if (atomicRequest.inSolve) {
        resultMap[wantToken] = { exists: false }
        continue
      }

      resultMap[wantToken] = {
        exists: true,
        request: {
          deadlineUnix: atomicRequest.deadline.toNumber(),
          atomicPrice: atomicRequest.atomicPrice,
          offerAmount: atomicRequest.offerAmount,
        },
      }
    }

    return resultMap
  }

  withdrawalRequestsByWantToken = async ({
    wantTokenKeys,
    user,
  }: {
    wantTokenKeys: TokenKey[]
    user: string
  }): Promise<NucleusWithdrawRequestResultMap> => {
    const wantTokensL1 = wantTokenKeys
      .filter((k) => k.chainId === this.l1ChainId)
      .map((k) => k.address)
    const wantTokensL2 = wantTokenKeys
      .filter((k) => k.chainId === this.l2ChainId)
      .map((k) => k.address)

    const l1ResultP = this.withdrawalRequestsByWantTokenOnChain({
      wantTokens: wantTokensL1,
      chainId: this.l1ChainId,
      user,
    })
    const l2ResultP = this.withdrawalRequestsByWantTokenOnChain({
      wantTokens: wantTokensL2,
      chainId: this.l2ChainId,
      user,
    })
    const l1Result = await l1ResultP
    const l2Result = await l2ResultP

    return {
      [this.l1ChainId]: l1Result,
      [this.l2ChainId]: l2Result,
    }
  }

  private ratesOnChain = async ({
    assetAddresses,
    chainId,
  }: {
    assetAddresses: string[]
    chainId: number
  }): Promise<NucleusRates['vaultTokenQuoteRates'][number]> => {
    let multicall: Multicall3
    let tokens: Token[]
    let accountantAddress: string
    if (chainId === this.l1ChainId) {
      multicall = this.multicall
      tokens = this.vault.depositAssets.filter(
        (t) => t.chainId === this.l1ChainId
      )
      accountantAddress = this.vault.accountantAddress
    } else if (chainId === this.l2ChainId) {
      multicall = this.multicallL2
      tokens = this.vault.depositAssets.filter(
        (t) => t.chainId === this.l2ChainId
      )
      accountantAddress = this.vault.accountantAddressL2
    } else {
      throw new Error(`unsupported chainId ${chainId}`)
    }
    tokens = tokens.filter((t) => assetAddresses.includes(t.address))

    const indexToTokenAddress = new Map<number, string>()
    const calls: Multicall3.Call3Struct[] = []

    const accountantIface =
      AccountantWithRateProviders__factory.createInterface()

    tokens.forEach((t, index) => {
      const addr = t.address
      indexToTokenAddress.set(index, addr)
      calls.push({
        target: accountantAddress,
        callData: accountantIface.encodeFunctionData('getRateInQuoteSafe', [
          addr,
        ]),
        allowFailure: true,
      })
    })

    const results = await multicall.callStatic.tryAggregate(false, calls)

    const quotes: Record<string, BigNumber> = {}
    for (let i = 0; i < results.length; i++) {
      const address = indexToTokenAddress.get(i)
      if (!address) throw new Error(`missing address for index ${i}`)
      const result = results[i]
      if (!result.success) continue
      quotes[address] = accountantIface.decodeFunctionResult(
        'getRateInQuoteSafe',
        result.returnData
      )[0]
    }

    return quotes
  }

  rates = async ({
    assetAddresses,
  }: {
    assetAddresses: string[]
  }): Promise<NucleusRates> => {
    const vaultTokenPrimaryRateP = AccountantWithRateProviders__factory.connect(
      this.vault.accountantAddress,
      this.multicall.provider
    ).getRate()
    const vaultTokenPrimaryRateL2P =
      AccountantWithRateProviders__factory.connect(
        this.vault.accountantAddressL2,
        this.multicallL2.provider
      ).getRate()

    const l1QuotesP = this.ratesOnChain({
      assetAddresses,
      chainId: this.l1ChainId,
    })
    const l2QuotesP = this.ratesOnChain({
      assetAddresses,
      chainId: this.l2ChainId,
    })

    const [vaultTokenPrimaryRate, vaultTokenPrimaryRateL2, l1Quotes, l2Quotes] =
      await Promise.all([
        vaultTokenPrimaryRateP,
        vaultTokenPrimaryRateL2P,
        l1QuotesP,
        l2QuotesP,
      ])

    return {
      vaultTokenPrimaryRate: {
        [this.l1ChainId]: vaultTokenPrimaryRate,
        [this.l2ChainId]: vaultTokenPrimaryRateL2,
      },
      vaultTokenQuoteRates: {
        [this.l1ChainId]: l1Quotes,
        [this.l2ChainId]: l2Quotes,
      },
    }
  }
}
