import {
  ApproveSwETHForWithdrawal,
  CreateWithdrawRequestSwETH,
  FinalizeWithdrawalSwETH,
  SwETHDeposit,
} from '@/state/sweth/hooks'
import { BigNumber } from 'ethers'
import {
  SwETHClaimInputs,
  SwETHDepositInputs,
  SwETHWithdrawInputs,
} from './types'
import {
  SwETHState,
  SwETHUser,
  SwETHWithdrawalsUser,
} from '@/state/sweth/types'

export const SwETHErrors = {
  AmountMustBeGreaterThanZero: 'Amount must be greater than 0',
  DepositIsPaused: 'Deposits are paused',
  WithdrawIsPaused: 'Withdrawals are paused',
  NotInWhitelist: 'Not in whitelist',
  InsufficientBalance: 'Insufficient balance',
  InsufficientAllowance: 'Insufficient allowance',
  UnstakeAmountTooLow: 'Unstake amount too low',
  UnstakeAmountTooHigh: 'Unstake amount too high',
  WithdrawRequestNotClaimable: 'Withdraw request is not claimable',
  InvalidTokenId: 'Invalid token id',
}

type ValidatedArgs<T> =
  | {
      args?: undefined
      error: string | null
    }
  | {
      args: T
      error?: undefined
    }

export function prepareSwETHDeposit({
  ethBalance,
  depositInputs,
  state,
  user,
}: {
  ethBalance: BigNumber | undefined
  depositInputs: Pick<SwETHDepositInputs, 'nativeCurrencyAmount'>
  state: Pick<SwETHState, 'whitelistEnabled' | 'coreMethodsPaused'> | undefined
  user: Pick<SwETHUser, 'whitelisted'> | undefined
}): ValidatedArgs<Parameters<SwETHDeposit['call']>> {
  if (!ethBalance || !state || !depositInputs.nativeCurrencyAmount || !user) {
    return {
      error: null,
    }
  }

  if (state.coreMethodsPaused) {
    return {
      error: SwETHErrors.DepositIsPaused,
    }
  }

  if (state.whitelistEnabled && !user.whitelisted) {
    return {
      error: SwETHErrors.NotInWhitelist,
    }
  }

  if (depositInputs.nativeCurrencyAmount.lte(0)) {
    return {
      error: SwETHErrors.AmountMustBeGreaterThanZero,
    }
  }

  if (depositInputs.nativeCurrencyAmount.gt(ethBalance)) {
    return {
      error: SwETHErrors.InsufficientBalance,
    }
  }

  return {
    args: [{ nativeCurrencyAmount: depositInputs.nativeCurrencyAmount }],
  }
}
export type PreparedSwETHDeposit = ReturnType<typeof prepareSwETHDeposit>

export function prepareApproveSwETHForWithdrawal({
  withdrawInputs,
  user,
}: {
  withdrawInputs: Pick<SwETHWithdrawInputs, 'swETHAmount'>
  user: Pick<SwETHUser, 'swETHBalance'> | undefined
}): ValidatedArgs<Parameters<ApproveSwETHForWithdrawal['call']>> {
  if (!withdrawInputs.swETHAmount || !user) {
    return {
      error: null,
    }
  }

  const { swETHAmount } = withdrawInputs
  const { swETHBalance } = user
  if (swETHAmount.lte(0)) {
    return {
      error: SwETHErrors.AmountMustBeGreaterThanZero,
    }
  }
  if (swETHAmount.gt(swETHBalance)) {
    return {
      error: SwETHErrors.InsufficientBalance,
    }
  }

  return {
    args: [{ amount: swETHAmount }],
  }
}
export type PreparedApproveSwETHForWithdrawal = ReturnType<
  typeof prepareApproveSwETHForWithdrawal
>

export function prepareSwETHCreateWithdrawRequest({
  state,
  withdrawInputs,
  user,
  minUnstakeAmount,
  maxUnstakeAmount,
}: {
  state: Pick<SwETHState, 'withdrawalsPaused'> | undefined
  withdrawInputs: Pick<SwETHWithdrawInputs, 'swETHAmount'>
  minUnstakeAmount: BigNumber
  maxUnstakeAmount: BigNumber
  user: Pick<SwETHUser, 'swETHAllowanceForExit' | 'swETHBalance'> | undefined
}): ValidatedArgs<Parameters<CreateWithdrawRequestSwETH['call']>> {
  if (!state || !withdrawInputs.swETHAmount) {
    return {
      error: null,
    }
  }

  if (state.withdrawalsPaused) {
    return {
      error: SwETHErrors.WithdrawIsPaused,
    }
  }
  const { swETHAmount } = withdrawInputs
  if (swETHAmount.lte(0)) {
    return {
      error: SwETHErrors.AmountMustBeGreaterThanZero,
    }
  }
  if (swETHAmount.lt(minUnstakeAmount)) {
    return {
      error: SwETHErrors.UnstakeAmountTooLow,
    }
  }
  if (swETHAmount.gt(maxUnstakeAmount)) {
    return {
      error: SwETHErrors.UnstakeAmountTooHigh,
    }
  }

  if (!user) {
    return {
      error: null,
    }
  }

  const { swETHBalance, swETHAllowanceForExit } = user
  if (swETHAmount.gt(swETHBalance)) {
    return {
      error: SwETHErrors.InsufficientBalance,
    }
  }
  if (swETHAmount.gt(swETHAllowanceForExit)) {
    return {
      error: SwETHErrors.InsufficientAllowance,
    }
  }

  return {
    args: [{ swETHAmount }],
  }
}
export type PreparedSwETHCreateWithdrawRequest = ReturnType<
  typeof prepareSwETHCreateWithdrawRequest
>

export function prepareSwETHFinalizeWithdrawal({
  claimInputs,
  withdrawUser,
}: {
  claimInputs: Pick<SwETHClaimInputs, 'selectedTokenId'>
  withdrawUser:
    | {
        exitClaims: Pick<
          SwETHWithdrawalsUser['exitClaims'][number],
          'requestId'
        >[]
      }
    | undefined
}): ValidatedArgs<Parameters<FinalizeWithdrawalSwETH['call']>> {
  if (!withdrawUser) {
    return {
      error: null,
    }
  }

  const { selectedTokenId } = claimInputs
  const { exitClaims } = withdrawUser

  let found = false
  for (const c of exitClaims) {
    if (BigNumber.from(c.requestId).toNumber() === selectedTokenId) {
      found = true
      break
    }
  }
  if (!found) {
    return {
      error: SwETHErrors.InvalidTokenId,
    }
  }

  return {
    args: [{ requestId: BigNumber.from(selectedTokenId) }],
  }
}
export type PreparedFinalizeWithdrawalSwETH = ReturnType<
  typeof prepareSwETHFinalizeWithdrawal
>
