import { Wavedrop, WavedropPhase } from './types'
import { reconstructWavedropsFromStaticDataV2 } from './util'
import { BigNumber } from 'ethers'
import { StaticWavedropServiceV2 } from '@/services/StaticWavedropV2'

export async function fetchWavedrops({
  wavedropSvc,
  account,
}: {
  wavedropSvc: StaticWavedropServiceV2
  account: string
}) {
  const staticWds = await wavedropSvc.wavedrops(account)

  let wd1: Wavedrop | undefined
  const _wd1 = staticWds.wavedrops.find((wd) => wd.wavedropNumber === 1)
  if (_wd1) {
    wd1 = reconstructWavedropsFromStaticDataV2(_wd1)
  }
  if (!wd1) {
    console.error('wd1 not found')
    wd1 = {
      waveNumber: 1,
      phase: WavedropPhase.claims,
      daoTokenAmount: BigNumber.from(0),
      blackPearls: 0,
      multiplier: 0,
      cumulativeAmount: BigNumber.from(0),
      merkleProof: [],
      merkleRoot: '',
      swellAmount: BigNumber.from(0),
    }
  }

  let wd2: Wavedrop | undefined
  const _wd2 = staticWds.wavedrops.find((wd) => wd.wavedropNumber === 2)
  if (_wd2) {
    wd2 = reconstructWavedropsFromStaticDataV2(_wd2)
  }
  if (!wd2) {
    console.error('wd2 not found')
    wd2 = {
      waveNumber: 2,
      phase: WavedropPhase.claims,
      blackPearls: 0,
      multiplier: 1,
      cumulativeAmount: BigNumber.from(0),
      daoTokenAmount: BigNumber.from(0),
      merkleProof: [],
      merkleRoot: '',
      swellAmount: BigNumber.from(0),
      ecosystemAmount: BigNumber.from(0),
      primaryAmount: BigNumber.from(0),
    }
  }

  return {
    wavedrops: [wd1, wd2],
  }
}
