import { EarnAPY } from '@/submodules/v3-shared/ts/connect/swell/v3/stats_pb'
import { UserPortfolioHoldingsResponse } from '@/submodules/v3-shared/ts/connect/swell/v3/wallet_pb'
import {
  EarnApyComponent,
  EarnAsset,
  EarnHoldings,
  UserEarnPosition,
  GlobalEarnPortfolioPosition,
  EarnProtocol,
  GlobalEarnCampaign,
  GlobalEarnPositionsMap,
  EarnPositionBalanceMap,
  UserEarnCampaign,
  EarnRewardAsset,
} from '@/types/earn'
import { SortingDirection } from '@/types/sorting'
import { MaybeRangeAPR } from '@/types/apr'
import {
  EarnCampaignsFilters,
  EarnCampaignsOrderBy,
  EarnPositionFilters,
  EarnPositionsOrderBy,
} from '@/types/portfolioFilters'
import { LegacyPointsResults } from '@/state/earn/types'
import { TOKEN_LIST_SWELL } from '@/constants/tokens'
import { Result } from '@/types/result'
import { EigenLayerStakedropResult } from '@/state/rsweth/types'
import { merkleClaimable } from './merkledrop'
import { formatUnits } from 'ethers/lib/utils'
import { EARN_CAMPAIGN_EIGEN } from '@/constants/earn'

export function parseEarnHoldings(
  data: UserPortfolioHoldingsResponse
): EarnHoldings {
  return {
    numAssetsHeld: data.numAssetsHeld,
    totalAssetBalanceUsd: data.totalAssetBalanceCents / 100,
    numParticipatingCampaigns: data.numParticipatingCampaigns,
  }
}

export function parseEarnPortfolioPosition(
  data: EarnAPY
): GlobalEarnPortfolioPosition {
  let apr: MaybeRangeAPR
  if (data.apy.length === 0) {
    apr = { type: 'scalar', aprPercent: 0 }
  } else if (data.apy.length === 1) {
    apr = { type: 'scalar', aprPercent: data.apy[0] }
  } else {
    apr = {
      type: 'range',
      basePercent: data.apy[0],
      maxPercent: data.apy[1],
    }
  }

  let swellApr: MaybeRangeAPR | undefined
  if (data.swellAprPercent.length && data.swellAprPercent.some((a) => a > 0)) {
    if (data.swellAprPercent.length === 1) {
      swellApr = { type: 'scalar', aprPercent: data.swellAprPercent[0] }
    } else {
      swellApr = {
        type: 'range',
        basePercent: data.swellAprPercent[0],
        maxPercent: data.swellAprPercent[1],
      }
    }
  }

  let totalApr: MaybeRangeAPR = apr
  if (swellApr) {
    if (apr.type === 'scalar') {
      if (swellApr.type === 'scalar') {
        totalApr = {
          type: 'scalar',
          aprPercent: apr.aprPercent + swellApr.aprPercent,
        }
      } else {
        totalApr = {
          type: 'range',
          basePercent: apr.aprPercent + swellApr.basePercent,
          maxPercent: apr.aprPercent + swellApr.maxPercent,
        }
      }
    } else {
      if (swellApr.type === 'scalar') {
        totalApr = {
          type: 'range',
          basePercent: apr.basePercent + swellApr.aprPercent,
          maxPercent: apr.maxPercent + swellApr.aprPercent,
        }
      } else {
        totalApr = {
          type: 'range',
          basePercent: apr.basePercent + swellApr.basePercent,
          maxPercent: apr.maxPercent + swellApr.maxPercent,
        }
      }
    }
  }
  const assets: EarnAsset[] = []

  for (const symbol of data.positionTokens) {
    assets.push({ symbol, logoURI: '' })
  }
  const numIcons = Math.min(
    data.tokenIconList.length,
    data.positionTokens.length
  )
  for (let i = 0; i < numIcons; i++) {
    assets[i].logoURI = data.tokenIconList[i]
  }

  let category = ''
  if (data.category) {
    category = data.category
  }

  const protocol: EarnProtocol = { logoURI: '', name: '' }
  if (data.logo) {
    protocol.logoURI = data.logo
  }
  if (data.protocol) {
    protocol.name = data.protocol
  }

  let tvlUsd = 0
  if (data.tvl) {
    tvlUsd = data.tvl
  }

  const aprComponents: EarnApyComponent[] = []
  if (swellApr) {
    aprComponents.push({
      name: 'Base APR',
      apr: apr,
      logoURI: protocol.logoURI,
    })
    aprComponents.push({
      name: 'SWELL',
      apr: swellApr,
      logoURI: TOKEN_LIST_SWELL.logoURI,
    })
  }

  return {
    positionId: `${data.id}`,
    chainId: data.chainId,
    apr: totalApr,
    assets,
    category,
    protocol,
    tvlUsd,
    link: data.link,
    positionName: data.positionName,
    aprComponents,
    rewardAssetIds: data.rewardAssetIds,
  }
}

export function buildEarnPositions({
  positionBalanceMap,
  globalPositionMap,
  filters,
  orderBy,
  orderDirection,
  availableTokenSymbolsForFilter,
}: {
  globalPositionMap: Partial<GlobalEarnPositionsMap>
  positionBalanceMap: Partial<EarnPositionBalanceMap>
  filters: EarnPositionFilters
  orderBy: EarnPositionsOrderBy
  orderDirection: SortingDirection
  availableTokenSymbolsForFilter: string[]
}) {
  let positions: UserEarnPosition[] = []
  for (const [positionId, positionWithoutBalance] of Object.entries(
    globalPositionMap
  )) {
    if (!positionWithoutBalance) {
      continue
    }

    const rewardAssetIds = positionWithoutBalance.rewardAssetIds
    const hasEcosystemTokenRewards = rewardAssetIds.length > 0

    let balanceUsd = positionBalanceMap[positionId]?.balanceUsd
    if (balanceUsd === undefined) {
      balanceUsd = { exists: false }
    }
    let swell = positionBalanceMap[positionId]?.swell
    if (swell === undefined) {
      swell = { exists: false }
    }
    let swellCumulative = positionBalanceMap[positionId]?.swellCumulative
    if (swellCumulative === undefined) {
      swellCumulative = { exists: false }
    }
    let ecosystemTokens: Result<{ amounts: number[] }> | undefined
    let ecosystemTokensCumulative: Result<{ amounts: number[] }> | undefined
    if (hasEcosystemTokenRewards) {
      ecosystemTokens = positionBalanceMap[positionId]?.ecosystemTokens
      ecosystemTokensCumulative =
        positionBalanceMap[positionId]?.ecosystemTokensCumulative
    }
    if (ecosystemTokens === undefined) {
      ecosystemTokens = { exists: false }
    }
    if (ecosystemTokensCumulative === undefined) {
      ecosystemTokensCumulative = { exists: false }
    }

    positions.push({
      ...positionWithoutBalance,
      balanceUsd,
      swell,
      swellCumulative,
      ecosystemTokens,
      ecosystemTokensCumulative,
    })
  }

  if (filters.hideZeroBalances) {
    positions = positions.filter(
      (position) =>
        position.balanceUsd.exists &&
        position.balanceUsd.amounts.some((a) => a > 0)
    )
  }

  if (filters.chainIds) {
    positions = positions.filter((position) =>
      filters.chainIds!.includes(position.chainId)
    )
  }

  if (filters.includeTokenSymbols && filters.includeTokenSymbols.length) {
    let allSelected = false
    if (
      filters.includeTokenSymbols.length ===
      availableTokenSymbolsForFilter.length
    ) {
      allSelected = true
      for (const symbol of filters.includeTokenSymbols) {
        if (!availableTokenSymbolsForFilter.includes(symbol)) {
          allSelected = false
          break
        }
      }
    }

    const lowercaseSymbols = filters.includeTokenSymbols.map((s) =>
      s.toLowerCase()
    )

    if (!allSelected) {
      positions = positions.filter((position) => {
        for (const asset of position.assets) {
          if (lowercaseSymbols.includes(asset.symbol.toLowerCase())) {
            return true
          }
        }
        return false
      })
    }
  }

  if (filters.searchTerm) {
    positions = positions.filter((position) => {
      const matchesCategory = position.category
        .toLowerCase()
        .includes(filters.searchTerm!.toLowerCase())
      const matchesProtocol = position.protocol.name
        .toLowerCase()
        .includes(filters.searchTerm!.toLowerCase())

      let matchesAsset = false
      for (const asset of position.assets) {
        if (
          asset.symbol.toLowerCase().includes(filters.searchTerm!.toLowerCase())
        ) {
          matchesAsset = true
          break
        }
      }

      return matchesCategory || matchesProtocol || matchesAsset
    })
  }

  const compareFnProtocol = (a: UserEarnPosition, b: UserEarnPosition) =>
    a.protocol.name.localeCompare(b.protocol.name)
  const _apyCompare = (aprA: MaybeRangeAPR, aprB: MaybeRangeAPR) => {
    const aApr = aprA.type === 'scalar' ? aprA.aprPercent : aprA.basePercent
    const bApr = aprB.type === 'scalar' ? aprB.aprPercent : aprB.basePercent
    return aApr - bApr
  }

  const compareFnApr = (a: UserEarnPosition, b: UserEarnPosition) => {
    return _apyCompare(a.apr, b.apr)
  }

  const compareFnSwellYield = (a: UserEarnPosition, b: UserEarnPosition) => {
    let aSwellApyComponent = -1
    if (a.aprComponents) {
      for (const component of a.aprComponents) {
        if (component.name === 'SWELL') {
          aSwellApyComponent =
            component.apr.type === 'scalar'
              ? component.apr.aprPercent
              : component.apr.basePercent
        }
      }
    }

    let bSwellApyComponent = -1
    if (b.aprComponents) {
      for (const component of b.aprComponents) {
        if (component.name === 'SWELL') {
          bSwellApyComponent =
            component.apr.type === 'scalar'
              ? component.apr.aprPercent
              : component.apr.basePercent
        }
      }
    }

    return aSwellApyComponent - bSwellApyComponent
  }

  const defaultSortFn = compareFnProtocol

  let defaultDirection: SortingDirection = 'asc'
  let sortFn: (a: UserEarnPosition, b: UserEarnPosition) => number
  if (orderBy === 'protocol') {
    sortFn = compareFnProtocol
  } else if (orderBy === 'asset') {
    sortFn = (a, b) => {
      let aSymbol = ''
      if (a.assets.length > 0) {
        aSymbol = a.assets[0].symbol
      }
      let bSymbol = ''
      if (b.assets.length > 0) {
        bSymbol = b.assets[0].symbol
      }
      return aSymbol.localeCompare(bSymbol)
    }
  } else if (orderBy === 'category') {
    sortFn = (a, b) => a.category.localeCompare(b.category)
  } else if (orderBy === 'tvl') {
    sortFn = (a, b) => a.tvlUsd - b.tvlUsd
    defaultDirection = 'desc'
  } else if (orderBy === 'apr') {
    sortFn = (a, b) => {
      return _apyCompare(a.apr, b.apr)
    }
    defaultDirection = 'desc'
  } else if (orderBy === 'rewards') {
    sortFn = (a, b) => {
      let aAmount = -1
      if (a.swell.exists) {
        for (const amount of a.swell.amounts) {
          if (amount > aAmount) {
            aAmount = amount
          }
        }
      }
      if (a.ecosystemTokens.exists) {
        for (const amount of a.ecosystemTokens.amounts) {
          if (amount > aAmount) {
            aAmount = amount
          }
        }
      }
      let bAmount = -1
      if (b.swell.exists) {
        for (const amount of b.swell.amounts) {
          if (amount > bAmount) {
            bAmount = amount
          }
        }
      }
      if (b.ecosystemTokens.exists) {
        for (const amount of b.ecosystemTokens.amounts) {
          if (amount > bAmount) {
            bAmount = amount
          }
        }
      }
      return aAmount - bAmount
    }
    defaultDirection = 'desc'
  } else if (orderBy === 'balance') {
    sortFn = (a, b) => {
      let aAmount = -1
      if (a.balanceUsd.exists) {
        for (const amount of a.balanceUsd.amounts) {
          if (amount > aAmount) {
            aAmount = amount
          }
        }
      }
      let bAmount = -1
      if (b.balanceUsd.exists) {
        for (const amount of b.balanceUsd.amounts) {
          if (amount > bAmount) {
            bAmount = amount
          }
        }
      }
      return aAmount - bAmount
    }
    defaultDirection = 'desc'
  } else {
    sortFn = defaultSortFn
  }

  if (orderDirection === 'unspecified') {
    orderDirection = defaultDirection
  }

  let sortWithDirection = sortFn
  if (orderDirection === 'desc') {
    sortWithDirection = (a, b) => sortFn(b, a)
  }

  const finalSortFn: typeof sortFn = (a, b) => {
    let result = sortWithDirection(a, b)
    if (result === 0) {
      result = compareFnSwellYield(b, a)
    }
    if (result === 0) {
      result = compareFnApr(b, a)
    }
    return result
  }

  positions.sort(finalSortFn)

  return positions
}

export function buildSortedFilteredCampaigns({
  orderBy,
  orderDirection,
  preconfiguredCampaigns,
  positions,
  legacyPointsResults,
  rewardAssets,
  latestSwellCampaign,
  eigenStakedropResult,
  eigenToken,
}: {
  positions: UserEarnPosition[] | undefined
  filters: EarnCampaignsFilters
  orderBy: EarnCampaignsOrderBy
  orderDirection: SortingDirection
  preconfiguredCampaigns: GlobalEarnCampaign[]
  legacyPointsResults: LegacyPointsResults | undefined
  rewardAssets: EarnRewardAsset[]
  latestSwellCampaign: GlobalEarnCampaign | undefined
  eigenStakedropResult: EigenLayerStakedropResult | undefined
  eigenToken: { decimals: number }
}) {
  const legacyClaimable = (legacyPointsResults?.claimable ?? {}) as Partial<
    Record<string, number>
  >
  const legacyPoints = (legacyPointsResults?.points ?? {}) as Partial<
    Record<string, number>
  >

  const campaigns: UserEarnCampaign[] = []
  for (const campaign of preconfiguredCampaigns) {
    const { campaignId } = campaign
    const legacyClaimableAmountResult = legacyClaimable[campaignId]
    const legacyPointsAmountResult = legacyPoints[campaignId]

    const c: UserEarnCampaign = {
      ...campaign,
      userClaimableAssets: { exists: false },
      userPoints: { exists: false },
      userClaimableAssetsCumulative: { exists: false },
    }
    if (typeof legacyClaimableAmountResult === 'number') {
      c.userClaimableAssets = {
        exists: true,
        amount: legacyClaimableAmountResult,
      }
      c.userClaimableAssetsCumulative = {
        exists: true,
        amount: legacyClaimableAmountResult,
      }
    }
    if (typeof legacyPointsAmountResult === 'number') {
      c.userPoints = {
        exists: true,
        amount: legacyPointsAmountResult,
      }
    }
    campaigns.push(c)
  }

  // synthetic Eigen campaign
  const eigenCampaign: UserEarnCampaign = {
    campaignId: EARN_CAMPAIGN_EIGEN.campaignId,
    campaignName: EARN_CAMPAIGN_EIGEN.campaignName,
    claimableAssetId: EARN_CAMPAIGN_EIGEN.claimableAssetId,
    claimSpec: EARN_CAMPAIGN_EIGEN.claimSpec,
    logoURI: EARN_CAMPAIGN_EIGEN.logoURI,
    squareLogo: EARN_CAMPAIGN_EIGEN.squareLogo,
    pointsId: EARN_CAMPAIGN_EIGEN.pointsId,
    userClaimableAssets: { exists: false },
    userClaimableAssetsCumulative: { exists: false },
    userPoints: { exists: false },
  }

  if (eigenStakedropResult) {
    if (eigenStakedropResult.exists) {
      const {
        data: { cumulativeAmount, totalAmount },
        cumulativeClaimed,
      } = eigenStakedropResult

      const { claimableAmount } = merkleClaimable({
        cumulativeAmount,
        cumulativeClaimed,
        totalAmount,
      })

      const claimableAmountNum = Number(
        formatUnits(claimableAmount, eigenToken.decimals)
      )

      eigenCampaign.userClaimableAssets = {
        exists: true,
        amount: claimableAmountNum,
      }
      eigenCampaign.userClaimableAssetsCumulative = {
        exists: true,
        amount: claimableAmountNum,
      }
      eigenCampaign.userPoints = { exists: false }
    } else {
      eigenCampaign.userClaimableAssets = {
        exists: true,
        amount: 0,
      }
      eigenCampaign.userClaimableAssetsCumulative = {
        exists: true,
        amount: 0,
      }
    }
  }
  campaigns.push(eigenCampaign)

  if (latestSwellCampaign) {
    const c: UserEarnCampaign = {
      ...latestSwellCampaign,
      userClaimableAssets: { exists: false },
      userPoints: { exists: false },
      userClaimableAssetsCumulative: { exists: false },
    }
    let totalSwell = 0
    let totalSwellCumulative = 0
    let someLoading = true
    if (positions) {
      someLoading = false
      for (const position of positions) {
        if (position.swell.exists) {
          const amount = position.swell.amounts.reduce((a, b) => a + b, 0)
          totalSwell += amount
        } else {
          someLoading = true
          break
        }
        if (position.swellCumulative.exists) {
          const amount = position.swellCumulative.amounts.reduce(
            (a, b) => a + b,
            0
          )
          totalSwellCumulative += amount
        } else {
          someLoading = true
          break
        }
      }
    }
    if (!someLoading) {
      c.userClaimableAssets = { exists: true, amount: totalSwell }
      c.userClaimableAssetsCumulative = {
        exists: true,
        amount: totalSwellCumulative,
      }
    }
    campaigns.push(c)
  }

  const exoticSet = new Set<string>()

  // iterate over positions, identify exotic campaigns by unrecognized assets
  if (positions) {
    for (const position of positions) {
      for (const assetId of position.rewardAssetIds) {
        const hasMeta = rewardAssets.find((ra) => ra.assetId === assetId)
        const isUsed = campaigns.find((c) => c.claimableAssetId === assetId)

        const seen = exoticSet.has(assetId)
        if (seen) continue

        if (hasMeta && !isUsed) {
          const campaignId = `exotic-${assetId}`
          campaigns.push({
            campaignId,
            campaignName: hasMeta.assetName,
            logoURI: hasMeta.logoURI,
            claimableAssetId: assetId,
            claimSpec: { type: 'url', url: '' },
            pointsId: '',
            userPoints: { exists: false },
            squareLogo: hasMeta.squareLogo,
            userClaimableAssets: { exists: false },
            userClaimableAssetsCumulative: { exists: false },
          })
          exoticSet.add(assetId)
        }
      }
    }
  }

  // group and sum balances by exotic campaigns
  for (const c of campaigns) {
    const isExotic = exoticSet.has(c.claimableAssetId)
    if (!isExotic) continue
    const assetId = c.claimableAssetId

    let totalEcosystemTokens = 0
    let totalCumulativeEcosystemTokens = 0
    let someLoading = false
    for (const position of positions ?? []) {
      const indexOfAsset = position.rewardAssetIds.indexOf(assetId)
      if (indexOfAsset === -1) continue // nothing for this asset

      if (position.ecosystemTokens.exists) {
        if (position.ecosystemTokens.amounts.length > indexOfAsset) {
          const amount = position.ecosystemTokens.amounts[indexOfAsset]
          totalEcosystemTokens += amount
        }
      } else {
        someLoading = true
        break
      }
      if (position.ecosystemTokensCumulative.exists) {
        if (position.ecosystemTokensCumulative.amounts.length > indexOfAsset) {
          const amount =
            position.ecosystemTokensCumulative.amounts[indexOfAsset]
          totalCumulativeEcosystemTokens += amount
        }
      } else {
        someLoading = true
        break
      }
    }

    if (someLoading) continue
    c.userClaimableAssets = {
      exists: true,
      amount: totalEcosystemTokens,
    }
    c.userClaimableAssetsCumulative = {
      exists: true,
      amount: totalCumulativeEcosystemTokens,
    }
  }

  const compareFnCampaignName = (a: UserEarnCampaign, b: UserEarnCampaign) =>
    a.campaignName.localeCompare(b.campaignName)
  const defaultSortFn = compareFnCampaignName
  const defaultDirection: SortingDirection = 'asc'
  let sortFn: (a: UserEarnCampaign, b: UserEarnCampaign) => number

  if (orderBy === 'campaignName') {
    sortFn = compareFnCampaignName
  } else if (orderBy === 'points') {
    sortFn = (a, b) => {
      let aAmount = -1
      if (a.userPoints.exists) {
        aAmount = a.userPoints.amount
      }
      let bAmount = -1
      if (b.userPoints.exists) {
        bAmount = b.userPoints.amount
      }
      return aAmount - bAmount
    }
  } else if (orderBy === 'claimable') {
    sortFn = (a, b) => {
      let aAmount = -1
      if (a.userClaimableAssets.exists) {
        aAmount = a.userClaimableAssets.amount
      }
      let bAmount = -1
      if (b.userClaimableAssets.exists) {
        bAmount = b.userClaimableAssets.amount
      }
      return aAmount - bAmount
    }
  } else {
    sortFn = defaultSortFn
  }

  let orderDirectionFinal: SortingDirection = orderDirection
  if (orderDirection === 'unspecified') {
    orderDirectionFinal = defaultDirection
  }

  if (orderDirectionFinal === 'asc') {
    campaigns.sort(sortFn)
  }
  if (orderDirectionFinal === 'desc') {
    campaigns.sort((a, b) => sortFn(b, a))
  }

  return campaigns
}
