import { ChainId, SupportedChainId } from './chains'
import swbtcLogo from '@/assets/images/swbtc-logo-64x64.png'
import swellLogo from 'assets/images/swell-logo.png'
import { Token } from '@/types/tokens'
import swellEarnBtcLogo from '@/assets/images/swell-earn-btc-120x120.png'
import ethLogo from '@/assets/images/ethereum-logo.png'
import eigenlayerLogo from 'assets/images/predeposit/eigenlayer.png'
import rswellLogo from '@/assets/images/rswell-icon-120x120.png'
import swellEarnEthLogo from '@/assets/images/swell-earn-eth-120x120.png'
import swethLogo from '@/assets/images/sweth_120x120.png'
import rswethLogo from '@/assets/images/rsweth_120x120.png'
import tbtcLogo from '@/assets/images/tbtc_64x64.png'
import pumpbtcLogo from '@/assets/images/pumpbtc_64x64.png'
import fbtcLogo from '@/assets/images/fbtc_64x64.png'
import cbtcLogo from '@/assets/images/coinbase_btc-250x250.png'
import lorenzoStbtcLogo from '@/assets/images/lorenzo_stbtc_120x120.png'
import solvbtcLogo from '@/assets/images/solvbtc-250x250.png'
import lombardbtcLogo from '@/assets/images/lombard_btc-250x250.png'
import kelpRsethLogo from '@/assets/images/kelp_rseth_160x160.png'
import tetherUsdtLogo from '@/assets/images/tether_usdt_160x160.png'
import binanceBtcbLogo from '@/assets/images/binance_btcb-logo.png'
import pendleEzethLogoUrl from '@/assets/wave9/pendle_ezeth.png'
import pendleRswethLogoUrl from '@/assets/wave9/pendle_rsweth.png'
import pendleWeethLogoUrl from '@/assets/wave9/pendle_weeth.png'
import renzoPzethLogoUrl from '@/assets/images/renzo_pzeth_160x160.png'
import apxethLogoUrl from '@/assets/images/predeposit_assets/apxeth_80x80.png'
import pxethLogoUrl from '@/assets/images/predeposit_assets/pxeth_80x80.png'
import ezethLogoUrl from '@/assets/images/predeposit_assets/ezeth_80x80.png'
import usdeLogoUrl from '@/assets/images/predeposit_assets/usde_80x80.png'
import symbioticLogoUrl from '@/assets/images/predeposit_assets/symbiotic_80x80.png'
import eigenpieLogoUrl from '@/assets/images/predeposit_assets/eigenpie_80x80.png'
import mellowAmphrethLogoUrl from '@/assets/images/predeposit_assets/mellow_amphreth_80x80.png'
import amphorEthLogoUrl from '@/assets/wave9/amphor_eth.svg'
import wethLogo from '@/assets/images/weth_160x160.png'
import ubtcLogo from '@/assets/images/ubtc_160x160.png'
import etherfiPtLogoUrl from '@/assets/images/pt-_eeth.png'
import {
  URI_CBETH_LOGO,
  URI_EETH_LOGO,
  URI_ETHX_LOGO,
  URI_METH_LOGO,
  URI_RETH_LOGO,
  URI_SFRX_LOGO,
  URI_USD_LOGO,
  URI_WBETH_LOGO,
  URI_WBTC_LOGO,
  URI_WEETH_LOGO,
  URI_WSTETH_LOGO,
} from './logo'

export const TOKEN_LIST_ETH = {
  chainId: 1,
  address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
  name: 'Ethereum',
  symbol: 'ETH',
  decimals: 18,
  logoURI: ethLogo,
}
export const TOKEN_LIST_ETH_SWELL_L2 = {
  ...TOKEN_LIST_ETH,
  chainId: ChainId.SWELLCHAIN,
}
export const TOKEN_LIST_ETH_SWELL_L2_TESTNET = {
  ...TOKEN_LIST_ETH,
  chainId: ChainId.SWELL_TESTNET,
}
export const TOKEN_LIST_ETH_SEPOLIA = {
  ...TOKEN_LIST_ETH,
  chainId: SupportedChainId.SEPOLIA,
}
export const TOKEN_LIST_ETH_OPTIMISM = {
  ...TOKEN_LIST_ETH,
  chainId: SupportedChainId.OPTIMISM,
}
export const TOKEN_LIST_ETH_ARBITRUM = {
  ...TOKEN_LIST_ETH,
  chainId: SupportedChainId.ARBITRUM,
}

export const TOKEN_LIST_SWETH = {
  chainId: 1,
  address: '0xf951E335afb289353dc249e82926178EaC7DEd78',
  name: 'Swell staked ETH',
  symbol: 'swETH',
  decimals: 18,
  logoURI: swethLogo,
}
export const TOKEN_LIST_SWETH_SWELL_L2 = {
  ...TOKEN_LIST_SWETH,
  chainId: ChainId.SWELLCHAIN,
  address: '', // todo
}
export const TOKEN_LIST_SWETH_SWELL_L2_TESTNET = {
  ...TOKEN_LIST_SWETH,
  chainId: ChainId.SWELL_TESTNET,
  address: '', // todo
}

export const TOKEN_LIST_RSWETH = {
  chainId: 1,
  address: '0xFAe103DC9cf190eD75350761e95403b7b8aFa6c0',
  name: 'Swell restaked ETH',
  symbol: 'rswETH',
  decimals: 18,
  logoURI: rswethLogo,
}
export const TOKEN_LIST_RSWETH_SWELL_L2 = {
  ...TOKEN_LIST_RSWETH,
  chainId: ChainId.SWELLCHAIN,
  address: '', // todo
}
export const TOKEN_LIST_RSWETH_SWELL_L2_TESTNET = {
  ...TOKEN_LIST_RSWETH,
  chainId: ChainId.SWELL_TESTNET,
  address: '', // todo
}

export const TOKEN_LIST_WBTC = {
  chainId: 1,
  address: '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599',
  name: 'Wrapped Bitcoin',
  symbol: 'WBTC',
  decimals: 8, // 8 decimals for WBTC
  logoURI: URI_WBTC_LOGO,
}

export const TOKEN_LIST_WBTC_SEPOLIA = {
  ...TOKEN_LIST_WBTC,
  decimals: 8,
  chainId: SupportedChainId.SEPOLIA,
  address: '0xfBefEB5dAaCD725CD8943c82B13C4B4499Dc87f5',
}

export const TOKEN_LIST_WBTC_HOLESKY = {
  ...TOKEN_LIST_WBTC,
  decimals: 8,
  chainId: SupportedChainId.HOLESKY,
  address: '0x640577074A5d054b011E0f05114078777646ca8e',
}

export const TOKEN_LIST_SWBTC = {
  chainId: 1,
  address: '0x8DB2350D78aBc13f5673A411D4700BCF87864dDE',
  name: 'swBTC',
  symbol: 'swBTC',
  decimals: 8,
  logoURI: swbtcLogo,
}
export const TOKEN_LIST_SWBTC_HOLESKY = {
  ...TOKEN_LIST_SWBTC,
  chainId: SupportedChainId.HOLESKY,
  address: '0xC42aEa23C2E77a0831d7eefc07BB4ec9052503FF',
}
export const TOKEN_LIST_SWBTC_SWELL_L2 = {
  ...TOKEN_LIST_SWBTC,
  chainId: ChainId.SWELLCHAIN,
  address: '', // todo
}
export const TOKEN_LIST_SWBTC_SWELL_L2_TESTNET = {
  ...TOKEN_LIST_SWBTC,
  chainId: ChainId.SWELL_TESTNET,
  address: '', // todo
}
export const TOKEN_LIST_SWBTC_SEPOLIA = {
  ...TOKEN_LIST_SWBTC,
  chainId: SupportedChainId.SEPOLIA,
  address: '0xeFc03C91C5F11E6B86645337D11f76BbbDBA151F',
}
export const TOKEN_LIST_SWBTC_OPTIMISM = {
  ...TOKEN_LIST_SWBTC,
  chainId: SupportedChainId.OPTIMISM,
  address: '0x0b2C639c533813f4Aa9D7837CAf62653d097Ff85',
}
export const TOKEN_LIST_SWBTC_ARBITRUM = {
  ...TOKEN_LIST_SWBTC,
  chainId: SupportedChainId.ARBITRUM,
  address: '0xaf88d065e77c8cC2239327C5EDb3A432268e5831',
}

export const TOKEN_LIST_SWELL = {
  chainId: 1,
  address: '0x0a6E7Ba5042B38349e437ec6Db6214AEC7B35676',
  name: 'SWELL',
  symbol: 'SWELL',
  decimals: 18,
  logoURI: swellLogo,
}
export const TOKEN_LIST_SWELL_SWELL_L2 = {
  ...TOKEN_LIST_SWELL,
  chainId: ChainId.SWELLCHAIN,
  address: '', // todo
}
export const TOKEN_LIST_SWELL_SWELL_L2_TESTNET = {
  ...TOKEN_LIST_SWELL,
  chainId: ChainId.SWELL_TESTNET,
  address: '', // todo
}
export const TOKEN_LIST_SWELL_SEPOLIA = {
  ...TOKEN_LIST_SWELL,
  chainId: SupportedChainId.SEPOLIA,
  address: '', // todo
}
export const TOKEN_LIST_SWELL_OPTIMISM = {
  ...TOKEN_LIST_SWELL,
  chainId: SupportedChainId.OPTIMISM,
  address: '', // todo
}
export const TOKEN_LIST_SWELL_ARBITRUM = {
  ...TOKEN_LIST_SWELL,
  chainId: SupportedChainId.ARBITRUM,
  address: '', // todo
}

export const TOKEN_LIST_RSWELL = {
  chainId: 1,
  address: '0x358d94b5b2F147D741088803d932Acb566acB7B6',
  name: 'Restaked SWELL',
  symbol: 'rSWELL',
  decimals: 18,
  logoURI: rswellLogo,
}
export const TOKEN_LIST_SWELL_HOLESKY = {
  ...TOKEN_LIST_SWELL,
  chainId: SupportedChainId.HOLESKY,
  address: '0xeAffBEF6e95e3e180BE06622436F83B966A21053',
}

export const TOKEN_LIST_RSWELL_HOLESKY = {
  ...TOKEN_LIST_RSWELL,
  chainId: SupportedChainId.HOLESKY,
  address: '0x20Ee39Fbd6809FB88cC8f2fB2312Eb910a2487b9',
}
export const TOKEN_LIST_RSWELL_SWELL_L2: Token = {
  ...TOKEN_LIST_RSWELL,
  address: '', // todo
  chainId: ChainId.SWELLCHAIN,
}
export const TOKEN_LIST_RSWELL_SWELL_L2_TESTNET: Token = {
  ...TOKEN_LIST_RSWELL,
  address: '', // todo
  chainId: ChainId.SWELL_TESTNET,
}
export const TOKEN_LIST_RSWELL_SEPOLIA: Token = {
  ...TOKEN_LIST_RSWELL,
  address: '', // todo
  chainId: SupportedChainId.SEPOLIA,
}
export const TOKEN_LIST_RSWELL_OPTIMISM: Token = {
  ...TOKEN_LIST_RSWELL,
  address: '', // todo
  chainId: SupportedChainId.OPTIMISM,
}
export const TOKEN_LIST_RSWELL_ARBITRUM: Token = {
  ...TOKEN_LIST_RSWELL,
  address: '', // todo
  chainId: SupportedChainId.ARBITRUM,
}

export const TOKEN_LIST_EARN_ETH = {
  chainId: 1,
  address: '0x9Ed15383940CC380fAEF0a75edacE507cC775f22',
  name: 'Swell Earn ETH',
  symbol: 'earnETH',
  decimals: 18,
  logoURI: swellEarnEthLogo,
}
export const TOKEN_LIST_EARN_ETH_SWELLCHAIN = {
  ...TOKEN_LIST_EARN_ETH,
  chainId: ChainId.SWELLCHAIN,
  address: '0x9Ed15383940CC380fAEF0a75edacE507cC775f22', // SAME
}

export const TOKEN_LIST_EARN_BTC = {
  chainId: 1,
  address: '0x66E47E6957B85Cf62564610B76dD206BB04d831a',
  name: 'Swell Earn BTC',
  symbol: 'earnBTC',
  decimals: 8,
  logoURI: swellEarnBtcLogo,
}
export const TOKEN_LIST_EARN_BTC_SWELLCHAIN = {
  ...TOKEN_LIST_EARN_BTC,
  chainId: ChainId.SWELLCHAIN,
  address: '0x66E47E6957B85Cf62564610B76dD206BB04d831a', // SAME
}

export const TOKEN_LIST_STETH = {
  address: '0xae7ab96520DE3A18E5e111B5EaAb095312D7fE84',
  chainId: 1,
  decimals: 18,
  logoURI: 'https://static.alchemyapi.io/images/assets/8085.png',
  name: 'Lido staked ETH',
  symbol: 'stETH',
}

export const TOKEN_LIST_WSTETH = {
  address: '0x7f39C581F595B53c5cb19bD0b3f8dA6c935E2Ca0',
  chainId: 1,
  decimals: 18,
  logoURI: URI_WSTETH_LOGO,
  name: 'Lido wrapped staked ETH',
  symbol: 'wstETH',
}
export const TOKEN_LIST_WSTETH_SWELL_L2 = {
  ...TOKEN_LIST_WSTETH,
  address: '', // todo
  chainId: ChainId.SWELLCHAIN,
}
export const TOKEN_LIST_WSTETH_SWELL_L2_TESTNET = {
  ...TOKEN_LIST_WSTETH,
  address: '', // todo
  chainId: ChainId.SWELL_TESTNET,
}
export const TOKEN_LIST_WSTETH_SEPOLIA = {
  ...TOKEN_LIST_WSTETH,
  address: '', // todo
  chainId: SupportedChainId.SEPOLIA,
}
export const TOKEN_LIST_WSTETH_OPTIMISM = {
  ...TOKEN_LIST_WSTETH,
  address: '', // todo
  chainId: SupportedChainId.OPTIMISM,
}
export const TOKEN_LIST_WSTETH_ARBITRUM = {
  ...TOKEN_LIST_WSTETH,
  address: '', // todo
  chainId: SupportedChainId.ARBITRUM,
}

export const TOKEN_LIST_RETH = {
  address: '0xae78736Cd615f374D3085123A210448E74Fc6393',
  chainId: 1,
  decimals: 18,
  logoURI: URI_RETH_LOGO,
  name: 'Rocket Pool ETH',
  symbol: 'rETH',
}

export const TOKEN_LIST_WETH = {
  chainId: 1,
  address: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
  symbol: 'WETH',
  name: 'Wrapped ETH',
  decimals: 18,
  logoURI: wethLogo,
  tags: ['ETH'],
}

export const TOKEN_LIST_EETH: Token = {
  name: 'ether.fi ETH',
  symbol: 'eETH',
  address: '0x35fA164735182de50811E8e2E824cFb9B6118ac2',
  chainId: 1,
  decimals: 18,
  logoURI: URI_EETH_LOGO,
}
export const TOKEN_LIST_WEETH: Token = {
  symbol: 'weETH',
  address: '0xCd5fE23C85820F7B72D0926FC9b05b43E359b7ee',
  chainId: 1,
  decimals: 18,
  logoURI: URI_WEETH_LOGO,
  name: 'Wrapped eETH',
}
export const TOKEN_LIST_WEETH_SWELL_L2: Token = {
  ...TOKEN_LIST_WEETH,
  address: '', // todo
  chainId: ChainId.SWELLCHAIN,
}
export const TOKEN_LIST_WEETH_SWELL_L2_TESTNET: Token = {
  ...TOKEN_LIST_WEETH,
  address: '', // todo
  chainId: ChainId.SWELL_TESTNET,
}
export const TOKEN_LIST_WEETH_SEPOLIA: Token = {
  ...TOKEN_LIST_WEETH,
  address: '', // todo
  chainId: SupportedChainId.SEPOLIA,
}
export const TOKEN_LIST_WEETH_OPTIMISM: Token = {
  ...TOKEN_LIST_WEETH,
  address: '', // todo
  chainId: SupportedChainId.OPTIMISM,
}
export const TOKEN_LIST_WEETH_ARBITRUM: Token = {
  ...TOKEN_LIST_WEETH,
  address: '', // todo
  chainId: SupportedChainId.ARBITRUM,
}

export const TOKEN_LIST_CBETH: Token = {
  symbol: 'cbETH',
  address: '0xBe9895146f7AF43049ca1c1AE358B0541Ea49704',
  chainId: 1,
  decimals: 18,
  logoURI: URI_CBETH_LOGO,
  name: 'Coinbase Wrapped Staked ETH',
}
export const TOKEN_LIST_WBETH: Token = {
  symbol: 'wbETH',
  address: '0xa2E3356610840701BDf5611a53974510Ae27E2e1',
  chainId: 1,
  decimals: 18,
  logoURI: URI_WBETH_LOGO,
  name: 'Wrapped Binance Beacon ETH',
}
export const TOKEN_LIST_METH: Token = {
  symbol: 'mETH',
  address: '0xd5F7838F5C461fefF7FE49ea5ebaF7728bB0ADfa',
  chainId: 1,
  decimals: 18,
  logoURI: URI_METH_LOGO,
  name: 'mETH',
}
export const TOKEN_LIST_SFRXETH: Token = {
  symbol: 'sfrxETH',
  address: '0xac3E018457B222d93114458476f3E3416Abbe38F',
  chainId: 1,
  decimals: 18,
  logoURI: URI_SFRX_LOGO,
  name: 'FRAX staked ETH',
}
export const TOKEN_LIST_ETHX: Token = {
  symbol: 'ETHx',
  address: '0xA35b1B31Ce002FBF2058D22F30f95D405200A15b',
  chainId: 1,
  decimals: 18,
  logoURI: URI_ETHX_LOGO,
  name: 'ETHx',
}
export const TOKEN_LIST_USDC: Token = {
  symbol: 'USDC',
  address: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
  chainId: 1,
  decimals: 6,
  logoURI: URI_USD_LOGO,
  name: 'USD Coin',
}
export const TOKEN_LIST_USDC_SWELL_L2: Token = {
  ...TOKEN_LIST_USDC,
  address: '', // todo
  chainId: ChainId.SWELLCHAIN,
}
export const TOKEN_LIST_USDC_SWELL_L2_TESTNET: Token = {
  ...TOKEN_LIST_USDC,
  address: '', // todo
  chainId: ChainId.SWELL_TESTNET,
}
export const TOKEN_LIST_USDC_SEPOLIA: Token = {
  ...TOKEN_LIST_USDC,
  address: '', // todo
  chainId: SupportedChainId.SEPOLIA,
}
export const TOKEN_LIST_USDC_OPTIMISM: Token = {
  ...TOKEN_LIST_USDC,
  address: '0x0b2C639c533813f4Aa9D7837CAf62653d097Ff85',
  chainId: SupportedChainId.OPTIMISM,
}
export const TOKEN_LIST_USDC_ARBITRUM: Token = {
  ...TOKEN_LIST_USDC,
  address: '0xaf88d065e77c8cC2239327C5EDb3A432268e5831',
  chainId: SupportedChainId.ARBITRUM,
}

export const TOKEN_LIST_APXETH: Token = {
  chainId: 1,
  address: '0x9Ba021B0a9b958B5E75cE9f6dff97C7eE52cb3E6',
  symbol: 'apxETH',
  name: 'Dinero auto-compounding pxETH',
  decimals: 18,
  logoURI: apxethLogoUrl,
}

export const TOKEN_LIST_PXETH: Token = {
  chainId: 1,
  address: '0x04C154b66CB340F3Ae24111CC767e0184Ed00Cc6',
  symbol: 'pxETH',
  name: 'Dinero staked ETH',
  decimals: 18,
  logoURI: pxethLogoUrl,
}

export const TOKEN_LIST_EIGEN = {
  chainId: 1,
  address: '0xec53bF9167f50cDEB3Ae105f56099aaaB9061F83',
  name: 'Eigen',
  symbol: 'EIGEN',
  decimals: 18,
  logoURI: eigenlayerLogo,
}

export const TOKEN_LIST_CBBTC: Token = {
  symbol: 'cbBTC',
  address: '0xcbB7C0000aB88B473b1f5aFd9ef808440eed33Bf',
  chainId: 1,
  decimals: 8,
  logoURI: cbtcLogo,
  name: 'Coinbase Wrapped BTC',
}

export const TOKEN_LIST_FBTC: Token = {
  symbol: 'FBTC',
  address: '0xC96dE26018A54D51c097160568752c4E3BD6C364',
  chainId: 1,
  decimals: 8,
  logoURI: fbtcLogo,
  name: 'Fire Bitcoin',
}

export const TOKEN_LIST_PUMPBTC: Token = {
  symbol: 'pumpBTC',
  address: '0xF469fBD2abcd6B9de8E169d128226C0Fc90a012e',
  chainId: 1,
  decimals: 8,
  logoURI: pumpbtcLogo,
  name: 'pumpBTC',
}

export const TOKEN_LIST_TBTC: Token = {
  symbol: 'tBTC',
  address: '0x18084fbA666a33d37592fA2633fD49a74DD93a88',
  chainId: 1,
  decimals: 18,
  logoURI: tbtcLogo,
  name: 'tBTC',
}

export const TOKEN_LIST_LORENZO_STBTC: Token = {
  symbol: 'stBTC',
  address: '0xf6718b2701D4a6498eF77D7c152b2137Ab28b8A3',
  chainId: 1,
  decimals: 18,
  logoURI: lorenzoStbtcLogo,
  name: 'Lorenzo stBTC',
}
export const TOKEN_LIST_LORENZO_STBTC_SWELL_L2: Token = {
  ...TOKEN_LIST_LORENZO_STBTC,
  address: '0xf6718b2701D4a6498eF77D7c152b2137Ab28b8A3', // config same ???
  chainId: ChainId.SWELLCHAIN,
}
export const TOKEN_LIST_LORENZO_STBTC_SWELL_L2_TESTNET: Token = {
  ...TOKEN_LIST_LORENZO_STBTC,
  address: '', // todo
  chainId: ChainId.SWELL_TESTNET,
}

export const TOKEN_LIST_SOLV_BTC: Token = {
  symbol: 'solvBTC',
  address: '0x7A56E1C57C7475CCf742a1832B028F0456652F97',
  chainId: 1,
  decimals: 18,
  logoURI: solvbtcLogo,
  name: 'Solv BTC',
}

export const TOKEN_LIST_LBTC: Token = {
  name: 'Lombard staked BTC',
  symbol: 'LBTC',
  address: '0x8236a87084f8B84306f72007F36F2618A5634494',
  chainId: 1,
  decimals: 8,
  logoURI: lombardbtcLogo,
}

export const TOKEN_LIST_BINANCE_BTCB: Token = {
  name: 'Binance BTC',
  symbol: 'BTCB',
  address: '0x9BE89D2a4cd102D8Fecc6BF9dA793be995C22541',
  chainId: 1,
  decimals: 8,
  logoURI: binanceBtcbLogo,
}

export const TOKEN_LIST_KELP_RSETH: Token = {
  name: 'Kelp restaked ETH',
  symbol: 'rsETH',
  address: '0xA1290d69c65A6Fe4DF752f95823fae25cB99e5A7',
  chainId: 1,
  decimals: 18,
  logoURI: kelpRsethLogo,
}

export const TOKEN_LIST_KELP_RSETH_SWELL_L2: Token = {
  ...TOKEN_LIST_KELP_RSETH,
  address: '', // todo
  chainId: ChainId.SWELLCHAIN,
}
export const TOKEN_LIST_KELP_RSETH_SWELL_L2_TESTNET: Token = {
  ...TOKEN_LIST_KELP_RSETH,
  address: '', // todo
  chainId: ChainId.SWELL_TESTNET,
}
export const TOKEN_LIST_KELP_RSETH_SEPOLIA: Token = {
  ...TOKEN_LIST_KELP_RSETH,
  address: '', // todo
  chainId: SupportedChainId.SEPOLIA,
}
export const TOKEN_LIST_KELP_RSETH_OPTIMISM: Token = {
  ...TOKEN_LIST_KELP_RSETH,
  address: '', // todo
  chainId: SupportedChainId.OPTIMISM,
}
export const TOKEN_LIST_KELP_RSETH_ARBITRUM: Token = {
  ...TOKEN_LIST_KELP_RSETH,
  address: '', // todo
  chainId: SupportedChainId.ARBITRUM,
}

export const TOKEN_LIST_RENZO_EZETH: Token = {
  chainId: 1,
  address: '0xbf5495Efe5DB9ce00f80364C8B423567e58d2110',
  symbol: 'ezETH',
  name: 'Renzo restaked ETH',
  decimals: 18,
  logoURI: ezethLogoUrl,
  tags: ['LRT'],
}

export const TOKEN_LIST_RENZO_EZETH_SWELL_L2: Token = {
  ...TOKEN_LIST_RENZO_EZETH,
  chainId: ChainId.SWELLCHAIN,
  address: '', // todo
}
export const TOKEN_LIST_RENZO_EZETH_SWELL_L2_TESTNET: Token = {
  ...TOKEN_LIST_RENZO_EZETH,
  address: '', // todo
  chainId: ChainId.SWELL_TESTNET,
}
export const TOKEN_LIST_RENZO_EZETH_SEPOLIA: Token = {
  ...TOKEN_LIST_RENZO_EZETH,
  chainId: SupportedChainId.SEPOLIA,
  address: '', // todo
}
export const TOKEN_LIST_RENZO_EZETH_OPTIMISM: Token = {
  ...TOKEN_LIST_RENZO_EZETH,
  chainId: SupportedChainId.OPTIMISM,
  address: '', // todo
}
export const TOKEN_LIST_RENZO_EZETH_ARBITRUM: Token = {
  ...TOKEN_LIST_RENZO_EZETH,
  chainId: SupportedChainId.ARBITRUM,
  address: '', // todo
}

export const TOKEN_LIST_RENZO_PZETH: Token = {
  chainId: 1,
  address: '0x8c9532a60E0E7C6BbD2B2c1303F63aCE1c3E9811',
  symbol: 'pzETH',
  decimals: 18,
  logoURI: renzoPzethLogoUrl,
  name: 'Renzo restaked LST',
}
export const TOKEN_LIST_RENZO_PZETH_SWELL_L2: Token = {
  ...TOKEN_LIST_RENZO_PZETH,
  chainId: ChainId.SWELLCHAIN,
  address: '', // todo
}
export const TOKEN_LIST_RENZO_PZETH_SWELL_L2_TESTNET: Token = {
  ...TOKEN_LIST_RENZO_PZETH,
  chainId: ChainId.SWELL_TESTNET,
  address: '', // todo
}

export const TOKEN_LIST_ETHENA_USDE: Token = {
  chainId: 1,
  address: '0x4c9EDD5852cd905f086C759E8383e09bff1E68B3',
  decimals: 18,
  logoURI: usdeLogoUrl,
  name: 'Ethena USDe',
  symbol: 'USDe',
  tags: ['Stables'],
}
export const TOKEN_LIST_ETHENA_USDE_SWELL_L2: Token = {
  ...TOKEN_LIST_ETHENA_USDE,
  chainId: ChainId.SWELLCHAIN,
  address: '', // todo
}
export const TOKEN_LIST_ETHENA_USDE_SWELL_L2_TESTNET: Token = {
  ...TOKEN_LIST_ETHENA_USDE,
  chainId: ChainId.SWELL_TESTNET,
  address: '', // todo
}
export const TOKEN_LIST_ETHENA_USDE_SEPOLIA: Token = {
  ...TOKEN_LIST_ETHENA_USDE,
  chainId: SupportedChainId.SEPOLIA,
  address: '', // todo
}
export const TOKEN_LIST_ETHENA_USDE_OPTIMISM: Token = {
  ...TOKEN_LIST_ETHENA_USDE,
  chainId: SupportedChainId.OPTIMISM,
  address: '', // todo
}
export const TOKEN_LIST_ETHENA_USDE_ARBITRUM: Token = {
  ...TOKEN_LIST_ETHENA_USDE,
  chainId: SupportedChainId.ARBITRUM,
  address: '', // todo
}

const susdeLogoUrl = '' // todo
export const TOKEN_LIST_ETHENA_SUSDE: Token = {
  chainId: 1,
  address: '0x9D39A5DE30e57443BfF2A8307A4256c8797A3497',
  decimals: 18,
  logoURI: susdeLogoUrl,
  name: 'Ethena sUSDe',
  symbol: 'sUSDe',
  tags: ['Stables'],
}
export const TOKEN_LIST_ETHENA_SUSDE_SWELL_L2: Token = {
  ...TOKEN_LIST_ETHENA_SUSDE,
  chainId: ChainId.SWELLCHAIN,
  address: '', // todo
}
export const TOKEN_LIST_ETHENA_SUSDE_SWELL_L2_TESTNET: Token = {
  ...TOKEN_LIST_ETHENA_SUSDE,
  chainId: ChainId.SWELL_TESTNET,
  address: '', // todo
}
export const TOKEN_LIST_ETHENA_SUSDE_SEPOLIA: Token = {
  ...TOKEN_LIST_ETHENA_SUSDE,
  chainId: SupportedChainId.SEPOLIA,
  address: '', // todo
}
export const TOKEN_LIST_ETHENA_SUSDE_OPTIMISM: Token = {
  ...TOKEN_LIST_ETHENA_SUSDE,
  chainId: SupportedChainId.OPTIMISM,
  address: '', // todo
}
export const TOKEN_LIST_ETHENA_SUSDE_ARBITRUM: Token = {
  ...TOKEN_LIST_ETHENA_SUSDE,
  chainId: SupportedChainId.ARBITRUM,
  address: '', // todo
}

const ethenaENALogoUrl = '' // todo
export const TOKEN_LIST_ETHENA_ENA: Token = {
  chainId: 1,
  address: '0x57e114B691Db790C35207b2e685D4A43181e6061',
  decimals: 18,
  logoURI: ethenaENALogoUrl,
  name: 'Ethena',
  symbol: 'ENA',
}
export const TOKEN_LIST_ETHENA_ENA_SWELL_L2: Token = {
  ...TOKEN_LIST_ETHENA_ENA,
  chainId: ChainId.SWELLCHAIN,
  address: '', // todo
}
export const TOKEN_LIST_ETHENA_ENA_SWELL_L2_TESTNET: Token = {
  ...TOKEN_LIST_ETHENA_ENA,
  chainId: ChainId.SWELL_TESTNET,
  address: '', // todo
}
export const TOKEN_LIST_ETHENA_ENA_SEPOLIA: Token = {
  ...TOKEN_LIST_ETHENA_ENA,
  chainId: SupportedChainId.SEPOLIA,
  address: '', // todo
}
export const TOKEN_LIST_ETHENA_ENA_OPTIMISM: Token = {
  ...TOKEN_LIST_ETHENA_ENA,
  chainId: SupportedChainId.OPTIMISM,
  address: '', // todo
}
export const TOKEN_LIST_ETHENA_ENA_ARBITRUM: Token = {
  ...TOKEN_LIST_ETHENA_ENA,
  chainId: SupportedChainId.ARBITRUM,
  address: '', // todo
}

export const TOKEN_LIST_USDT: Token = {
  chainId: 1,
  address: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
  decimals: 6,
  logoURI: tetherUsdtLogo,
  name: 'Tether USD',
  symbol: 'USDT',
  tags: ['Stables'],
}
export const TOKEN_LIST_USDT_SWELL_L2: Token = {
  ...TOKEN_LIST_USDT,
  chainId: ChainId.SWELLCHAIN,
  address: '', // todo
}
export const TOKEN_LIST_USDT_SWELL_L2_TESTNET: Token = {
  ...TOKEN_LIST_USDT,
  chainId: ChainId.SWELL_TESTNET,
  address: '0x41a0bD84E65e75Bc30AFBbe6ea142eBBcc347542',
}
export const TOKEN_LIST_USDT_SEPOLIA: Token = {
  ...TOKEN_LIST_USDT,
  chainId: SupportedChainId.SEPOLIA,
  address: '0xfd665f836095Ed02fDBF3C4F24174D70DFD6b69c',
}
export const TOKEN_LIST_USDT_OPTIMISM: Token = {
  ...TOKEN_LIST_USDT,
  chainId: SupportedChainId.OPTIMISM,
  address: '0x94b008aA00579c1307B0EF2c499aD98a8ce58e58',
}
export const TOKEN_LIST_USDT_ARBITRUM: Token = {
  ...TOKEN_LIST_USDT,
  chainId: SupportedChainId.ARBITRUM,
  address: '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
}

export const TOKEN_LIST_PT_WEETH_26DEC2024: Token = {
  name: 'Pendle PT weETH 26 Dec 2024',
  symbol: 'PT-weETH-26DEC2024',
  address: '0x1c085195437738d73d75DC64bC5A3E098b7f93b1',
  chainId: 1,
  decimals: 18,
  logoURI: pendleWeethLogoUrl,
  tags: ['Pendle'],
}
export const TOKEN_LIST_PT_EZETH_26DEC2024: Token = {
  chainId: 1,
  address: '0xf7906F274c174A52d444175729E3fa98f9bde285',
  decimals: 18,
  logoURI: pendleEzethLogoUrl,
  name: 'Pendle PT ezETH 26 Dec 2024',
  symbol: 'PT-ezETH-26DEC2024',
  tags: ['Pendle'],
}
export const TOKEN_LIST_PT_RSWETH_27JUN2024: Token = {
  chainId: 1,
  address: '0x5cb12D56F5346a016DBBA8CA90635d82e6D1bcEa',
  symbol: 'Pendle PT rswETH',
  name: 'Swell 27 Jun 2024',
  decimals: 18,
  logoURI: pendleRswethLogoUrl,
  tags: ['Pendle'],
}
export const TOKEN_LIST_PT_WEETH_27JUN2024: Token = {
  chainId: 1,
  address: '0xc69Ad9baB1dEE23F4605a82b3354F8E40d1E5966',
  symbol: 'Pendle PT eETH',
  name: 'EtherFi 27 Jun 2024',
  decimals: 18,
  logoURI: etherfiPtLogoUrl,
  tags: ['Pendle'],
}
export const TOKEN_LIST_DC_COLLATERAL_SWETH: Token = {
  name: 'Symbiotic swETH',
  symbol: 'DC_swETH',
  address: '0x38B86004842D3FA4596f0b7A0b53DE90745Ab654',
  chainId: 1,
  decimals: 18,
  logoURI: symbioticLogoUrl,
  tags: ['Symbiotic'],
}
export const TOKEN_LIST_DC_COLLATERAL_WSTETH: Token = {
  name: 'Symbiotic wrapped stETH',
  symbol: 'DC_wstETH',
  address: '0xC329400492c6ff2438472D4651Ad17389fCb843a',
  chainId: 1,
  decimals: 18,
  logoURI: symbioticLogoUrl,
  tags: ['Symbiotic'],
}
export const TOKEN_LIST_DC_COLLATERAL_CBETH: Token = {
  name: 'Symbiotic Coinbase wrapped staked ETH',
  symbol: 'DC_cbETH',
  address: '0xB26ff591F44b04E78de18f43B46f8b70C6676984',
  chainId: 1,
  decimals: 18,
  logoURI: symbioticLogoUrl,
  tags: ['Symbiotic'],
}
export const TOKEN_LIST_EIGENPIE_MMETH: Token = {
  chainId: 1,
  address: '0x8a053350ca5F9352a16deD26ab333e2D251DAd7c',
  symbol: 'mmETH',
  name: 'Eigenpie Mantle ETH',
  decimals: 18,
  logoURI: eigenpieLogoUrl,
  tags: ['Eigenpie'],
}
export const TOKEN_LIST_EIGENPIE_MSWETH: Token = {
  chainId: 1,
  address: '0x32bd822d615A3658A68b6fDD30c2fcb2C996D678',
  symbol: 'mswETH',
  name: 'Eigenpie Swell swETH',
  decimals: 18,
  logoURI: eigenpieLogoUrl,
  tags: ['Eigenpie'],
}
export const TOKEN_LIST_EIGENPIE_MSTETH: Token = {
  chainId: 1,
  address: '0x49446A0874197839D15395B908328a74ccc96Bc0',
  symbol: 'mstETH',
  name: 'Eigenpie Lido stETH',
  decimals: 18,
  logoURI: eigenpieLogoUrl,
  tags: ['Eigenpie'],
}
export const TOKEN_LIST_MELLOW_FINANCE_AMPHRETH: Token = {
  name: 'Mellow MEV Capital 25 Jul 2024',
  symbol: 'Pendle PT amphrETH',
  address: '0x4CA48e7Dd3970F96EE74D6A573Ca5678EA1EB31b',
  chainId: 1,
  decimals: 18,
  logoURI: mellowAmphrethLogoUrl,
  tags: ['Pendle'],
}
export const TOKEN_LIST_AMPHOR_AMPHRETH: Token = {
  name: 'Amphor restaked ETH',
  symbol: 'amphrETH',
  address: '0x5fD13359Ba15A84B76f7F87568309040176167cd',
  chainId: 1,
  decimals: 18,
  logoURI: amphorEthLogoUrl,
}

export const TOKEN_LIST_UBTC_SWELLCHAIN: Token = {
  name: 'UBTC',
  symbol: 'UBTC',
  address: '0xFA3198ecF05303a6d96E57a45E6c815055D255b1',
  chainId: ChainId.SWELLCHAIN,
  decimals: 18,
  logoURI: ubtcLogo,
}
export const TOKEN_LIST_WSWELL_EULER_EVK: Token = {
  // https://explorer.swellnetwork.io/address/0xda1F8EA667dc5600F5f654DF44b47F1639a83DD1
  name: 'wSWELL',
  symbol: 'wSWELL',
  address: '0xda1F8EA667dc5600F5f654DF44b47F1639a83DD1',
  chainId: ChainId.SWELLCHAIN,
  decimals: 18,
  logoURI: swellLogo, // meh..
}
