import {
  ApproveSwETHForWithdrawal,
  CreateWithdrawRequestSwETH,
  FinalizeWithdrawalSwETH,
  SwETHDeposit,
} from '@/state/sweth/hooks'
import {
  PreparedApproveSwETHForWithdrawal,
  PreparedFinalizeWithdrawalSwETH,
  PreparedSwETHCreateWithdrawRequest,
  PreparedSwETHDeposit,
} from './swETHCalls'
import { useWeb3Button } from '@/hooks/useWeb3Button'
import styled from 'styled-components'
import { CircularProgress } from '@/swell-ui/CircularProgress'
import { Button, ButtonProps } from '@/swell-ui/Button'

const StyledButton = styled(Button)`
  position: relative;
  width: 100%;
`

const ButtonCircularProgress = styled(CircularProgress)`
  &.MuiCircularProgress-colorPrimary {
    color: currentColor;
  }
`

const ButtonInner = styled.span`
  position: relative;
  > div {
    position: absolute;
    top: -7px;
    left: -32px;
  }
`

function SwethButton(props: ButtonProps) {
  return (
    <StyledButton
      variant="primary"
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.children}
    </StyledButton>
  )
}

export function SwETHDepositButton({
  deposit,
  prepared,
  preventInteraction,
}: {
  deposit: SwETHDeposit
  prepared: PreparedSwETHDeposit
  preventInteraction: boolean
}) {
  const btn = useWeb3Button(deposit, deposit.call, prepared.args, {
    idleLabel: 'Deposit',
    pendingLabel: (
      <ButtonInner>
        <div>
          <ButtonCircularProgress size={24} />
        </div>
        <span>Confirming...</span>
      </ButtonInner>
    ),
    fulfilledLabel: 'Deposit',
  })

  return (
    <SwethButton
      onClick={btn.onClick}
      disabled={preventInteraction || btn.disabled}
    >
      {btn.label}
    </SwethButton>
  )
}
export function ApproveSwETHForWithdrawalButton({
  approveSwETHForWithdrawal,
  prepared,
  preventInteraction,
}: {
  approveSwETHForWithdrawal: ApproveSwETHForWithdrawal
  prepared: PreparedApproveSwETHForWithdrawal
  preventInteraction: boolean
}) {
  const btn = useWeb3Button(
    approveSwETHForWithdrawal,
    approveSwETHForWithdrawal.call,
    prepared.args,
    {
      idleLabel: 'Approve',
      pendingLabel: (
        <ButtonInner>
          <div>
            <ButtonCircularProgress size={24} />
          </div>
          <span>Confirming...</span>
        </ButtonInner>
      ),
      fulfilledLabel: 'Approve',
    }
  )

  return (
    <SwethButton
      onClick={btn.onClick}
      disabled={preventInteraction || btn.disabled}
    >
      {btn.label}
    </SwethButton>
  )
}
export function SwETHCreateWithdrawRequestButton({
  preventInteraction,
  createWithdrawRequest,
  prepared,
}: {
  preventInteraction: boolean
  createWithdrawRequest: CreateWithdrawRequestSwETH
  prepared: PreparedSwETHCreateWithdrawRequest
}) {
  const btn = useWeb3Button(
    createWithdrawRequest,
    createWithdrawRequest.call,
    prepared.args,
    {
      idleLabel: 'Withdraw',
      pendingLabel: (
        <ButtonInner>
          <div>
            <ButtonCircularProgress size={24} />
          </div>
          <span>Confirming...</span>
        </ButtonInner>
      ),
      fulfilledLabel: 'Withdraw',
    }
  )

  return (
    <SwethButton
      onClick={btn.onClick}
      disabled={preventInteraction || btn.disabled}
    >
      {btn.label}
    </SwethButton>
  )
}
export function FinalizeWithdrawalButton({
  finalizeWithdrawal,
  prepared,
  preventInteraction,
}: {
  finalizeWithdrawal: FinalizeWithdrawalSwETH
  prepared: PreparedFinalizeWithdrawalSwETH
  preventInteraction: boolean
}) {
  const btn = useWeb3Button(
    finalizeWithdrawal,
    finalizeWithdrawal.call,
    prepared.args,
    {
      idleLabel: 'Claim',
      pendingLabel: (
        <ButtonInner>
          <div>
            <ButtonCircularProgress size={24} />
          </div>
          <span>Confirming...</span>
        </ButtonInner>
      ),
      fulfilledLabel: 'Claim',
    }
  )

  return (
    <SwethButton
      onClick={btn.onClick}
      disabled={preventInteraction || btn.disabled}
    >
      {btn.label}
    </SwethButton>
  )
}
