import React from 'react'
import styled, { css } from 'styled-components/macro'
import { Chip } from '@swell-ui/Chip'
import { FlexRow } from '@swell-ui/FlexRow'
import { orderBy } from 'lodash'
import { L2EcosystemProject } from '@/state/l2ecosystem/types'

interface TagsFilterProps {
  projects: Pick<L2EcosystemProject, 'tags'>[] | undefined
  onSelect: (tag: string) => void
  onClear: () => void
  selected?: string
}

const Layout = styled(FlexRow)`
  flex-wrap: wrap;
  .MuiChip-root {
    border-radius: 100px;
    color: ${({ theme }) => theme.colors.white['50']};
    border-color: 1px solid rgba(255, 255, 255, 0.5);
    font-weight: 600;
    line-height: 24px;

    > span {
      padding: 4px 8px;
    }
  }
`

const StyledChip = styled(Chip)<any>`
  ${({ selected }) =>
    selected &&
    css`
      background: linear-gradient(90deg, #845aff 0%, #4943e0 100%);
      border-color: transparent;
    `}
`

export function TagsFilter({
  onClear,
  onSelect,
  selected,
  projects = [],
}: TagsFilterProps) {
  const counts: Record<string, number> = {}

  projects.forEach((token) => {
    token.tags?.forEach((tag) => {
      if (counts[tag]) {
        counts[tag]++
      } else {
        counts[tag] = 1
      }
    })
  })

  const sortedTags = orderBy(Object.keys(counts), (key) => counts[key], [
    'desc',
  ])

  return (
    <Layout justify="center" gap="6">
      {projects && (
        <>
          <StyledChip
            label={`All (${projects.length})`}
            variant="outlined"
            onClick={() => onClear()}
            selected={!selected}
          />
          {sortedTags.map((key: string) => {
            return (
              <StyledChip
                key={key}
                label={`${key} (${counts[key]})`}
                variant="outlined"
                onClick={() => onSelect(key)}
                selected={selected === key}
              />
            )
          })}
        </>
      )}
    </Layout>
  )
}
