import { EarnHoldings, UserEarnCampaign, UserEarnPosition } from '@/types/earn'

type Resultify<T> = T extends Record<infer Keys, any>
  ? {
      [key in Keys]:
        | { loaded: true; result: T[key] }
        | { loaded: false; result?: undefined }
    }
  : never

export type EarnHoldingsResults = Resultify<EarnHoldings>

export function makeEarnHoldings({
  campaigns,
  positions,
}: {
  campaigns: UserEarnCampaign[] | undefined
  positions: UserEarnPosition[] | undefined
}): EarnHoldingsResults {
  const res: EarnHoldingsResults = {
    numAssetsHeld: { loaded: false },
    numParticipatingCampaigns: { loaded: false },
    totalAssetBalanceUsd: { loaded: false },
  }

  if (positions) {
    const allBalancesLoaded = positions.every((position) => {
      return position.balanceUsd.exists
    })
    if (allBalancesLoaded) {
      let totalAssetBalance = 0
      let numAssetsHeld = 0
      for (const position of positions) {
        for (const amount of position.balanceUsd.amounts!) {
          if (amount > 0) {
            totalAssetBalance += amount
            numAssetsHeld++
          }
        }
      }
      res.numAssetsHeld = { loaded: true, result: numAssetsHeld }
      res.totalAssetBalanceUsd = { loaded: true, result: totalAssetBalance }
    }
  }

  if (campaigns) {
    const allCampaignsWithPoints = campaigns.filter((c) => Boolean(c.pointsId))
    const allCampaignsWithRewards = campaigns.filter((c) =>
      Boolean(c.claimableAssetId)
    )

    const allCampaignsWithPointsLoaded = allCampaignsWithPoints.every(
      (campaign) => campaign.userPoints.exists
    )
    const allCampaignsWithRewardsLoaded = allCampaignsWithRewards.every(
      (campaign) => campaign.userClaimableAssets.exists
    )

    if (allCampaignsWithPointsLoaded && allCampaignsWithRewardsLoaded) {
      const participatingCampaigns = new Set<string>()
      for (const campaign of allCampaignsWithPoints) {
        if (campaign.userPoints.exists && campaign.userPoints.amount > 0) {
          participatingCampaigns.add(campaign.campaignId)
        }
      }
      for (const campaign of allCampaignsWithRewards) {
        if (
          campaign.userClaimableAssets.exists &&
          campaign.userClaimableAssets.amount > 0
        ) {
          participatingCampaigns.add(campaign.campaignId)
        }
      }

      const numParticipatingCampaigns = participatingCampaigns.size

      res.numParticipatingCampaigns = {
        loaded: true,
        result: numParticipatingCampaigns,
      }
    }
  }

  return res
}
