import { createContext, useContext } from 'react'
import { YearnVaultApi } from './types'
import { YearnAeraVault } from '@/types/yearnAera'

const MISSING_PROVIDER = Symbol()

export type YearnVaultContext = YearnVaultApi & YearnAeraVault

function makeYearnVaultContext() {
  const YearnVaultContext = createContext<
    YearnVaultContext | typeof MISSING_PROVIDER
  >(MISSING_PROVIDER)

  function useYearnVaultApi() {
    const ctx = useContext(YearnVaultContext)
    if (ctx === MISSING_PROVIDER) {
      throw new Error(
        'YearnVaultContext hooks must be wrapped in a <YearnVaultContext.Provider>'
      )
    }
    return ctx
  }

  return { YearnVaultContext, useYearnVaultApi }
}

export const {
  YearnVaultContext: BtcLrtVaultContext,
  useYearnVaultApi: useBtcLrtVaultApi,
} = makeYearnVaultContext()
export const {
  YearnVaultContext: BtcLrtVaultLegacyContext,
  useYearnVaultApi: useBtcLrtVaultLegacyApi,
} = makeYearnVaultContext()
export const {
  YearnVaultContext: RswellVaultContext,
  useYearnVaultApi: useRswellVaultApi,
} = makeYearnVaultContext()
