// @generated by protoc-gen-es v1.3.3 with parameter "target=ts"
// @generated from file swell/v3/wallet.proto (package swell.v3, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";

/**
 * @generated from enum swell.v3.EarnPositionOrderBy
 */
export enum EarnPositionOrderBy {
  /**
   * @generated from enum value: EARN_POSITION_ORDER_BY_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: EARN_POSITION_ORDER_BY_PROTOCOL = 1;
   */
  PROTOCOL = 1,

  /**
   * @generated from enum value: EARN_POSITION_ORDER_BY_ASSET = 2;
   */
  ASSET = 2,

  /**
   * @generated from enum value: EARN_POSITION_ORDER_BY_CATEGORY = 3;
   */
  CATEGORY = 3,

  /**
   * @generated from enum value: EARN_POSITION_ORDER_BY_TVL = 4;
   */
  TVL = 4,

  /**
   * @generated from enum value: EARN_POSITION_ORDER_BY_APR = 5;
   */
  APR = 5,

  /**
   * @generated from enum value: EARN_POSITION_ORDER_BY_MULTIPLIER = 6;
   */
  MULTIPLIER = 6,

  /**
   * @generated from enum value: EARN_POSITION_ORDER_BY_PEARLS = 7;
   */
  PEARLS = 7,

  /**
   * @generated from enum value: EARN_POSITION_ORDER_BY_BALANCE = 8;
   */
  BALANCE = 8,
}
// Retrieve enum metadata with: proto3.getEnumType(EarnPositionOrderBy)
proto3.util.setEnumType(EarnPositionOrderBy, "swell.v3.EarnPositionOrderBy", [
  { no: 0, name: "EARN_POSITION_ORDER_BY_UNSPECIFIED" },
  { no: 1, name: "EARN_POSITION_ORDER_BY_PROTOCOL" },
  { no: 2, name: "EARN_POSITION_ORDER_BY_ASSET" },
  { no: 3, name: "EARN_POSITION_ORDER_BY_CATEGORY" },
  { no: 4, name: "EARN_POSITION_ORDER_BY_TVL" },
  { no: 5, name: "EARN_POSITION_ORDER_BY_APR" },
  { no: 6, name: "EARN_POSITION_ORDER_BY_MULTIPLIER" },
  { no: 7, name: "EARN_POSITION_ORDER_BY_PEARLS" },
  { no: 8, name: "EARN_POSITION_ORDER_BY_BALANCE" },
]);

/**
 * @generated from enum swell.v3.EarnPositionOrderDirection
 */
export enum EarnPositionOrderDirection {
  /**
   * @generated from enum value: EARN_POSITION_ORDER_DIRECTION_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: EARN_POSITION_ORDER_DIRECTION_ASC = 1;
   */
  ASC = 1,

  /**
   * @generated from enum value: EARN_POSITION_ORDER_DIRECTION_DESC = 2;
   */
  DESC = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(EarnPositionOrderDirection)
proto3.util.setEnumType(EarnPositionOrderDirection, "swell.v3.EarnPositionOrderDirection", [
  { no: 0, name: "EARN_POSITION_ORDER_DIRECTION_UNSPECIFIED" },
  { no: 1, name: "EARN_POSITION_ORDER_DIRECTION_ASC" },
  { no: 2, name: "EARN_POSITION_ORDER_DIRECTION_DESC" },
]);

/**
 * @generated from enum swell.v3.EarnBalanceType
 */
export enum EarnBalanceType {
  /**
   * @generated from enum value: EARN_BALANCE_TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: EARN_BALANCE_TYPE_ECOSYSTEM_TOKENS = 1;
   */
  ECOSYSTEM_TOKENS = 1,

  /**
   * @generated from enum value: EARN_BALANCE_TYPE_USER_TVL = 2;
   */
  USER_TVL = 2,

  /**
   * @generated from enum value: EARN_BALANCE_TYPE_SWELL = 3;
   */
  SWELL = 3,

  /**
   * @generated from enum value: EARN_BALANCE_TYPE_ECOSYSTEM_TOKENS_CUMULATIVE = 4;
   */
  ECOSYSTEM_TOKENS_CUMULATIVE = 4,

  /**
   * @generated from enum value: EARN_BALANCE_TYPE_SWELL_CUMULATIVE = 5;
   */
  SWELL_CUMULATIVE = 5,
}
// Retrieve enum metadata with: proto3.getEnumType(EarnBalanceType)
proto3.util.setEnumType(EarnBalanceType, "swell.v3.EarnBalanceType", [
  { no: 0, name: "EARN_BALANCE_TYPE_UNSPECIFIED" },
  { no: 1, name: "EARN_BALANCE_TYPE_ECOSYSTEM_TOKENS" },
  { no: 2, name: "EARN_BALANCE_TYPE_USER_TVL" },
  { no: 3, name: "EARN_BALANCE_TYPE_SWELL" },
  { no: 4, name: "EARN_BALANCE_TYPE_ECOSYSTEM_TOKENS_CUMULATIVE" },
  { no: 5, name: "EARN_BALANCE_TYPE_SWELL_CUMULATIVE" },
]);

/**
 * @generated from message swell.v3.TokenBalancesRequest
 */
export class TokenBalancesRequest extends Message<TokenBalancesRequest> {
  /**
   * @generated from field: string wallet_address = 1;
   */
  walletAddress = "";

  constructor(data?: PartialMessage<TokenBalancesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.TokenBalancesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "wallet_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TokenBalancesRequest {
    return new TokenBalancesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TokenBalancesRequest {
    return new TokenBalancesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TokenBalancesRequest {
    return new TokenBalancesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: TokenBalancesRequest | PlainMessage<TokenBalancesRequest> | undefined, b: TokenBalancesRequest | PlainMessage<TokenBalancesRequest> | undefined): boolean {
    return proto3.util.equals(TokenBalancesRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.TokenBalance
 */
export class TokenBalance extends Message<TokenBalance> {
  /**
   * @generated from field: string contract_address = 1;
   */
  contractAddress = "";

  /**
   * @generated from field: string token_balance = 2;
   */
  tokenBalance = "";

  constructor(data?: PartialMessage<TokenBalance>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.TokenBalance";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "contract_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "token_balance", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TokenBalance {
    return new TokenBalance().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TokenBalance {
    return new TokenBalance().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TokenBalance {
    return new TokenBalance().fromJsonString(jsonString, options);
  }

  static equals(a: TokenBalance | PlainMessage<TokenBalance> | undefined, b: TokenBalance | PlainMessage<TokenBalance> | undefined): boolean {
    return proto3.util.equals(TokenBalance, a, b);
  }
}

/**
 * @generated from message swell.v3.TokenBalancesResponse
 */
export class TokenBalancesResponse extends Message<TokenBalancesResponse> {
  /**
   * @generated from field: repeated swell.v3.TokenBalance token_balances = 1;
   */
  tokenBalances: TokenBalance[] = [];

  constructor(data?: PartialMessage<TokenBalancesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.TokenBalancesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "token_balances", kind: "message", T: TokenBalance, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TokenBalancesResponse {
    return new TokenBalancesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TokenBalancesResponse {
    return new TokenBalancesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TokenBalancesResponse {
    return new TokenBalancesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: TokenBalancesResponse | PlainMessage<TokenBalancesResponse> | undefined, b: TokenBalancesResponse | PlainMessage<TokenBalancesResponse> | undefined): boolean {
    return proto3.util.equals(TokenBalancesResponse, a, b);
  }
}

/**
 * @generated from message swell.v3.TokenMetadataRequest
 */
export class TokenMetadataRequest extends Message<TokenMetadataRequest> {
  /**
   * @generated from field: string token_address = 1;
   */
  tokenAddress = "";

  constructor(data?: PartialMessage<TokenMetadataRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.TokenMetadataRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "token_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TokenMetadataRequest {
    return new TokenMetadataRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TokenMetadataRequest {
    return new TokenMetadataRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TokenMetadataRequest {
    return new TokenMetadataRequest().fromJsonString(jsonString, options);
  }

  static equals(a: TokenMetadataRequest | PlainMessage<TokenMetadataRequest> | undefined, b: TokenMetadataRequest | PlainMessage<TokenMetadataRequest> | undefined): boolean {
    return proto3.util.equals(TokenMetadataRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.TokenMetadataResponse
 */
export class TokenMetadataResponse extends Message<TokenMetadataResponse> {
  /**
   * @generated from field: int32 chain_id = 1;
   */
  chainId = 0;

  /**
   * @generated from field: string address = 2;
   */
  address = "";

  /**
   * @generated from field: string name = 3;
   */
  name = "";

  /**
   * @generated from field: string symbol = 4;
   */
  symbol = "";

  /**
   * @generated from field: int32 decimals = 5;
   */
  decimals = 0;

  /**
   * @generated from field: string logo_uri = 6;
   */
  logoUri = "";

  constructor(data?: PartialMessage<TokenMetadataResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.TokenMetadataResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "chain_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "symbol", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "decimals", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "logo_uri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TokenMetadataResponse {
    return new TokenMetadataResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TokenMetadataResponse {
    return new TokenMetadataResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TokenMetadataResponse {
    return new TokenMetadataResponse().fromJsonString(jsonString, options);
  }

  static equals(a: TokenMetadataResponse | PlainMessage<TokenMetadataResponse> | undefined, b: TokenMetadataResponse | PlainMessage<TokenMetadataResponse> | undefined): boolean {
    return proto3.util.equals(TokenMetadataResponse, a, b);
  }
}

/**
 * @generated from message swell.v3.IsGeoRestrictedRequest
 */
export class IsGeoRestrictedRequest extends Message<IsGeoRestrictedRequest> {
  /**
   * @generated from field: string wallet_address = 1;
   */
  walletAddress = "";

  /**
   * @generated from field: string country_code = 2;
   */
  countryCode = "";

  constructor(data?: PartialMessage<IsGeoRestrictedRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.IsGeoRestrictedRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "wallet_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "country_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): IsGeoRestrictedRequest {
    return new IsGeoRestrictedRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): IsGeoRestrictedRequest {
    return new IsGeoRestrictedRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): IsGeoRestrictedRequest {
    return new IsGeoRestrictedRequest().fromJsonString(jsonString, options);
  }

  static equals(a: IsGeoRestrictedRequest | PlainMessage<IsGeoRestrictedRequest> | undefined, b: IsGeoRestrictedRequest | PlainMessage<IsGeoRestrictedRequest> | undefined): boolean {
    return proto3.util.equals(IsGeoRestrictedRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.IsGeoRestrictedResponse
 */
export class IsGeoRestrictedResponse extends Message<IsGeoRestrictedResponse> {
  /**
   * @generated from field: bool is_restricted = 1;
   */
  isRestricted = false;

  constructor(data?: PartialMessage<IsGeoRestrictedResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.IsGeoRestrictedResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "is_restricted", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): IsGeoRestrictedResponse {
    return new IsGeoRestrictedResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): IsGeoRestrictedResponse {
    return new IsGeoRestrictedResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): IsGeoRestrictedResponse {
    return new IsGeoRestrictedResponse().fromJsonString(jsonString, options);
  }

  static equals(a: IsGeoRestrictedResponse | PlainMessage<IsGeoRestrictedResponse> | undefined, b: IsGeoRestrictedResponse | PlainMessage<IsGeoRestrictedResponse> | undefined): boolean {
    return proto3.util.equals(IsGeoRestrictedResponse, a, b);
  }
}

/**
 * @generated from message swell.v3.CheckIPRequest
 */
export class CheckIPRequest extends Message<CheckIPRequest> {
  /**
   * @generated from field: string ip = 1;
   */
  ip = "";

  constructor(data?: PartialMessage<CheckIPRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.CheckIPRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ip", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CheckIPRequest {
    return new CheckIPRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CheckIPRequest {
    return new CheckIPRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CheckIPRequest {
    return new CheckIPRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CheckIPRequest | PlainMessage<CheckIPRequest> | undefined, b: CheckIPRequest | PlainMessage<CheckIPRequest> | undefined): boolean {
    return proto3.util.equals(CheckIPRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.CheckIPResponse
 */
export class CheckIPResponse extends Message<CheckIPResponse> {
  /**
   * @generated from field: bool is_restricted = 1;
   */
  isRestricted = false;

  /**
   * @generated from field: string country_code = 2;
   */
  countryCode = "";

  /**
   * @generated from field: string region = 3;
   */
  region = "";

  /**
   * @generated from field: string ip = 4;
   */
  ip = "";

  /**
   * @generated from field: string timestamp = 5;
   */
  timestamp = "";

  constructor(data?: PartialMessage<CheckIPResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.CheckIPResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "is_restricted", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "country_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "region", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "ip", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "timestamp", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CheckIPResponse {
    return new CheckIPResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CheckIPResponse {
    return new CheckIPResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CheckIPResponse {
    return new CheckIPResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CheckIPResponse | PlainMessage<CheckIPResponse> | undefined, b: CheckIPResponse | PlainMessage<CheckIPResponse> | undefined): boolean {
    return proto3.util.equals(CheckIPResponse, a, b);
  }
}

/**
 * @generated from message swell.v3.EigenLayerAirdropRequest
 */
export class EigenLayerAirdropRequest extends Message<EigenLayerAirdropRequest> {
  /**
   * @generated from field: string wallet_address = 1;
   */
  walletAddress = "";

  constructor(data?: PartialMessage<EigenLayerAirdropRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.EigenLayerAirdropRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "wallet_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EigenLayerAirdropRequest {
    return new EigenLayerAirdropRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EigenLayerAirdropRequest {
    return new EigenLayerAirdropRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EigenLayerAirdropRequest {
    return new EigenLayerAirdropRequest().fromJsonString(jsonString, options);
  }

  static equals(a: EigenLayerAirdropRequest | PlainMessage<EigenLayerAirdropRequest> | undefined, b: EigenLayerAirdropRequest | PlainMessage<EigenLayerAirdropRequest> | undefined): boolean {
    return proto3.util.equals(EigenLayerAirdropRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.EigenLayerAirdropResponse
 */
export class EigenLayerAirdropResponse extends Message<EigenLayerAirdropResponse> {
  /**
   * @generated from field: string wallet_address = 1;
   */
  walletAddress = "";

  /**
   * @generated from field: string cumulative_amount = 2;
   */
  cumulativeAmount = "";

  /**
   * @generated from field: repeated string proofs_hex = 3;
   */
  proofsHex: string[] = [];

  /**
   * @generated from field: string latest_merkle_root_hex = 4;
   */
  latestMerkleRootHex = "";

  constructor(data?: PartialMessage<EigenLayerAirdropResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.EigenLayerAirdropResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "wallet_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "cumulative_amount", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "proofs_hex", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "latest_merkle_root_hex", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EigenLayerAirdropResponse {
    return new EigenLayerAirdropResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EigenLayerAirdropResponse {
    return new EigenLayerAirdropResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EigenLayerAirdropResponse {
    return new EigenLayerAirdropResponse().fromJsonString(jsonString, options);
  }

  static equals(a: EigenLayerAirdropResponse | PlainMessage<EigenLayerAirdropResponse> | undefined, b: EigenLayerAirdropResponse | PlainMessage<EigenLayerAirdropResponse> | undefined): boolean {
    return proto3.util.equals(EigenLayerAirdropResponse, a, b);
  }
}

/**
 * @generated from message swell.v3.AirdropRequest
 */
export class AirdropRequest extends Message<AirdropRequest> {
  /**
   * @generated from field: string wallet_address = 1;
   */
  walletAddress = "";

  constructor(data?: PartialMessage<AirdropRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.AirdropRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "wallet_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AirdropRequest {
    return new AirdropRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AirdropRequest {
    return new AirdropRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AirdropRequest {
    return new AirdropRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AirdropRequest | PlainMessage<AirdropRequest> | undefined, b: AirdropRequest | PlainMessage<AirdropRequest> | undefined): boolean {
    return proto3.util.equals(AirdropRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.ClaimedEvent
 */
export class ClaimedEvent extends Message<ClaimedEvent> {
  /**
   * @generated from field: string merkledrop_address = 1;
   */
  merkledropAddress = "";

  /**
   * @generated from field: string amount_claimed_wei = 2;
   */
  amountClaimedWei = "";

  /**
   * @generated from field: string amount_locked_wei = 3;
   */
  amountLockedWei = "";

  /**
   * @generated from field: int64 timestamp_unix = 4;
   */
  timestampUnix = protoInt64.zero;

  constructor(data?: PartialMessage<ClaimedEvent>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.ClaimedEvent";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "merkledrop_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "amount_claimed_wei", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "amount_locked_wei", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "timestamp_unix", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ClaimedEvent {
    return new ClaimedEvent().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ClaimedEvent {
    return new ClaimedEvent().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ClaimedEvent {
    return new ClaimedEvent().fromJsonString(jsonString, options);
  }

  static equals(a: ClaimedEvent | PlainMessage<ClaimedEvent> | undefined, b: ClaimedEvent | PlainMessage<ClaimedEvent> | undefined): boolean {
    return proto3.util.equals(ClaimedEvent, a, b);
  }
}

/**
 * @generated from message swell.v3.AirdropResponse
 */
export class AirdropResponse extends Message<AirdropResponse> {
  /**
   * @generated from field: string wallet_address = 1;
   */
  walletAddress = "";

  /**
   * @generated from field: string cumulative_amount = 2;
   */
  cumulativeAmount = "";

  /**
   * @generated from field: string total_amount = 3;
   */
  totalAmount = "";

  /**
   * @generated from field: repeated string proofs_hex = 4;
   */
  proofsHex: string[] = [];

  /**
   * @generated from field: string latest_merkle_root_hex = 5;
   */
  latestMerkleRootHex = "";

  /**
   * @generated from field: string loyalty_amount = 6;
   */
  loyaltyAmount = "";

  /**
   * @generated from field: bool is_sybil = 7;
   */
  isSybil = false;

  /**
   * @generated from field: float pearl_amount = 8;
   */
  pearlAmount = 0;

  /**
   * @generated from field: int32 vesting_tier = 9;
   */
  vestingTier = 0;

  /**
   * @generated from field: bool selected_option_2 = 10;
   */
  selectedOption2 = false;

  /**
   * @generated from field: repeated swell.v3.ClaimedEvent claimed_events = 11;
   */
  claimedEvents: ClaimedEvent[] = [];

  constructor(data?: PartialMessage<AirdropResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.AirdropResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "wallet_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "cumulative_amount", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "total_amount", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "proofs_hex", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 5, name: "latest_merkle_root_hex", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "loyalty_amount", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "is_sybil", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "pearl_amount", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 9, name: "vesting_tier", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 10, name: "selected_option_2", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 11, name: "claimed_events", kind: "message", T: ClaimedEvent, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AirdropResponse {
    return new AirdropResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AirdropResponse {
    return new AirdropResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AirdropResponse {
    return new AirdropResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AirdropResponse | PlainMessage<AirdropResponse> | undefined, b: AirdropResponse | PlainMessage<AirdropResponse> | undefined): boolean {
    return proto3.util.equals(AirdropResponse, a, b);
  }
}

/**
 * @generated from message swell.v3.AirdropCheckerRequest
 */
export class AirdropCheckerRequest extends Message<AirdropCheckerRequest> {
  /**
   * @generated from field: string wallet_address = 1;
   */
  walletAddress = "";

  constructor(data?: PartialMessage<AirdropCheckerRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.AirdropCheckerRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "wallet_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AirdropCheckerRequest {
    return new AirdropCheckerRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AirdropCheckerRequest {
    return new AirdropCheckerRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AirdropCheckerRequest {
    return new AirdropCheckerRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AirdropCheckerRequest | PlainMessage<AirdropCheckerRequest> | undefined, b: AirdropCheckerRequest | PlainMessage<AirdropCheckerRequest> | undefined): boolean {
    return proto3.util.equals(AirdropCheckerRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.AirdropCheckerResponse
 */
export class AirdropCheckerResponse extends Message<AirdropCheckerResponse> {
  /**
   * @generated from field: string wallet_address = 1;
   */
  walletAddress = "";

  /**
   * @generated from field: string total_amount = 2;
   */
  totalAmount = "";

  /**
   * @generated from field: string loyalty_amount = 3;
   */
  loyaltyAmount = "";

  /**
   * @generated from field: bool is_sybil = 4;
   */
  isSybil = false;

  /**
   * @generated from field: bool is_whale = 5;
   */
  isWhale = false;

  /**
   * @generated from field: float pearl_amount = 6;
   */
  pearlAmount = 0;

  /**
   * @generated from field: int32 vesting_tier = 7;
   */
  vestingTier = 0;

  constructor(data?: PartialMessage<AirdropCheckerResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.AirdropCheckerResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "wallet_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "total_amount", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "loyalty_amount", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "is_sybil", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "is_whale", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "pearl_amount", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 7, name: "vesting_tier", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AirdropCheckerResponse {
    return new AirdropCheckerResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AirdropCheckerResponse {
    return new AirdropCheckerResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AirdropCheckerResponse {
    return new AirdropCheckerResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AirdropCheckerResponse | PlainMessage<AirdropCheckerResponse> | undefined, b: AirdropCheckerResponse | PlainMessage<AirdropCheckerResponse> | undefined): boolean {
    return proto3.util.equals(AirdropCheckerResponse, a, b);
  }
}

/**
 * @generated from message swell.v3.ClaimedEventsRequest
 */
export class ClaimedEventsRequest extends Message<ClaimedEventsRequest> {
  /**
   * @generated from field: string wallet_address = 1;
   */
  walletAddress = "";

  /**
   * @generated from field: string merkledrop_address = 2;
   */
  merkledropAddress = "";

  constructor(data?: PartialMessage<ClaimedEventsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.ClaimedEventsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "wallet_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "merkledrop_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ClaimedEventsRequest {
    return new ClaimedEventsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ClaimedEventsRequest {
    return new ClaimedEventsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ClaimedEventsRequest {
    return new ClaimedEventsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ClaimedEventsRequest | PlainMessage<ClaimedEventsRequest> | undefined, b: ClaimedEventsRequest | PlainMessage<ClaimedEventsRequest> | undefined): boolean {
    return proto3.util.equals(ClaimedEventsRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.ClaimedEventsResponse
 */
export class ClaimedEventsResponse extends Message<ClaimedEventsResponse> {
  /**
   * @generated from field: repeated swell.v3.ClaimedEvent events = 1;
   */
  events: ClaimedEvent[] = [];

  constructor(data?: PartialMessage<ClaimedEventsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.ClaimedEventsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "events", kind: "message", T: ClaimedEvent, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ClaimedEventsResponse {
    return new ClaimedEventsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ClaimedEventsResponse {
    return new ClaimedEventsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ClaimedEventsResponse {
    return new ClaimedEventsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ClaimedEventsResponse | PlainMessage<ClaimedEventsResponse> | undefined, b: ClaimedEventsResponse | PlainMessage<ClaimedEventsResponse> | undefined): boolean {
    return proto3.util.equals(ClaimedEventsResponse, a, b);
  }
}

/**
 * @generated from message swell.v3.SwellL2EcosystemUserRequest
 */
export class SwellL2EcosystemUserRequest extends Message<SwellL2EcosystemUserRequest> {
  /**
   * @generated from field: string wallet_address = 1;
   */
  walletAddress = "";

  constructor(data?: PartialMessage<SwellL2EcosystemUserRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.SwellL2EcosystemUserRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "wallet_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SwellL2EcosystemUserRequest {
    return new SwellL2EcosystemUserRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SwellL2EcosystemUserRequest {
    return new SwellL2EcosystemUserRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SwellL2EcosystemUserRequest {
    return new SwellL2EcosystemUserRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SwellL2EcosystemUserRequest | PlainMessage<SwellL2EcosystemUserRequest> | undefined, b: SwellL2EcosystemUserRequest | PlainMessage<SwellL2EcosystemUserRequest> | undefined): boolean {
    return proto3.util.equals(SwellL2EcosystemUserRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.SwellL2EcosystemUserResponse
 */
export class SwellL2EcosystemUserResponse extends Message<SwellL2EcosystemUserResponse> {
  /**
   * @generated from field: float points = 1;
   */
  points = 0;

  constructor(data?: PartialMessage<SwellL2EcosystemUserResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.SwellL2EcosystemUserResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "points", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SwellL2EcosystemUserResponse {
    return new SwellL2EcosystemUserResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SwellL2EcosystemUserResponse {
    return new SwellL2EcosystemUserResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SwellL2EcosystemUserResponse {
    return new SwellL2EcosystemUserResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SwellL2EcosystemUserResponse | PlainMessage<SwellL2EcosystemUserResponse> | undefined, b: SwellL2EcosystemUserResponse | PlainMessage<SwellL2EcosystemUserResponse> | undefined): boolean {
    return proto3.util.equals(SwellL2EcosystemUserResponse, a, b);
  }
}

/**
 * @generated from message swell.v3.WavedropCheckerRequest
 */
export class WavedropCheckerRequest extends Message<WavedropCheckerRequest> {
  /**
   * @generated from field: string wallet_address = 1;
   */
  walletAddress = "";

  /**
   * @generated from field: int32 wave_number = 2;
   */
  waveNumber = 0;

  constructor(data?: PartialMessage<WavedropCheckerRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.WavedropCheckerRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "wallet_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "wave_number", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): WavedropCheckerRequest {
    return new WavedropCheckerRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): WavedropCheckerRequest {
    return new WavedropCheckerRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): WavedropCheckerRequest {
    return new WavedropCheckerRequest().fromJsonString(jsonString, options);
  }

  static equals(a: WavedropCheckerRequest | PlainMessage<WavedropCheckerRequest> | undefined, b: WavedropCheckerRequest | PlainMessage<WavedropCheckerRequest> | undefined): boolean {
    return proto3.util.equals(WavedropCheckerRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.WavedropCheckerResponse
 */
export class WavedropCheckerResponse extends Message<WavedropCheckerResponse> {
  /**
   * @generated from field: float black_pearls = 1;
   */
  blackPearls = 0;

  /**
   * @generated from field: float multiplier = 2;
   */
  multiplier = 0;

  /**
   * @generated from field: string swell_tokens = 3;
   */
  swellTokens = "";

  constructor(data?: PartialMessage<WavedropCheckerResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.WavedropCheckerResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "black_pearls", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 2, name: "multiplier", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 3, name: "swell_tokens", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): WavedropCheckerResponse {
    return new WavedropCheckerResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): WavedropCheckerResponse {
    return new WavedropCheckerResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): WavedropCheckerResponse {
    return new WavedropCheckerResponse().fromJsonString(jsonString, options);
  }

  static equals(a: WavedropCheckerResponse | PlainMessage<WavedropCheckerResponse> | undefined, b: WavedropCheckerResponse | PlainMessage<WavedropCheckerResponse> | undefined): boolean {
    return proto3.util.equals(WavedropCheckerResponse, a, b);
  }
}

/**
 * @generated from message swell.v3.WavedropUserRequest
 */
export class WavedropUserRequest extends Message<WavedropUserRequest> {
  /**
   * @generated from field: string wallet_address = 1;
   */
  walletAddress = "";

  constructor(data?: PartialMessage<WavedropUserRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.WavedropUserRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "wallet_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): WavedropUserRequest {
    return new WavedropUserRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): WavedropUserRequest {
    return new WavedropUserRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): WavedropUserRequest {
    return new WavedropUserRequest().fromJsonString(jsonString, options);
  }

  static equals(a: WavedropUserRequest | PlainMessage<WavedropUserRequest> | undefined, b: WavedropUserRequest | PlainMessage<WavedropUserRequest> | undefined): boolean {
    return proto3.util.equals(WavedropUserRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.WavedropUserResponse
 */
export class WavedropUserResponse extends Message<WavedropUserResponse> {
  /**
   * @generated from field: float black_pearls = 1;
   */
  blackPearls = 0;

  /**
   * @generated from field: float multiplier = 2;
   */
  multiplier = 0;

  constructor(data?: PartialMessage<WavedropUserResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.WavedropUserResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "black_pearls", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 2, name: "multiplier", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): WavedropUserResponse {
    return new WavedropUserResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): WavedropUserResponse {
    return new WavedropUserResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): WavedropUserResponse {
    return new WavedropUserResponse().fromJsonString(jsonString, options);
  }

  static equals(a: WavedropUserResponse | PlainMessage<WavedropUserResponse> | undefined, b: WavedropUserResponse | PlainMessage<WavedropUserResponse> | undefined): boolean {
    return proto3.util.equals(WavedropUserResponse, a, b);
  }
}

/**
 * @generated from message swell.v3.Wavedrop2UserRequest
 */
export class Wavedrop2UserRequest extends Message<Wavedrop2UserRequest> {
  /**
   * @generated from field: string wallet_address = 1;
   */
  walletAddress = "";

  constructor(data?: PartialMessage<Wavedrop2UserRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.Wavedrop2UserRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "wallet_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Wavedrop2UserRequest {
    return new Wavedrop2UserRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Wavedrop2UserRequest {
    return new Wavedrop2UserRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Wavedrop2UserRequest {
    return new Wavedrop2UserRequest().fromJsonString(jsonString, options);
  }

  static equals(a: Wavedrop2UserRequest | PlainMessage<Wavedrop2UserRequest> | undefined, b: Wavedrop2UserRequest | PlainMessage<Wavedrop2UserRequest> | undefined): boolean {
    return proto3.util.equals(Wavedrop2UserRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.Wavedrop2UserResponse
 */
export class Wavedrop2UserResponse extends Message<Wavedrop2UserResponse> {
  /**
   * @generated from field: float black_pearls = 1;
   */
  blackPearls = 0;

  /**
   * @generated from field: float multiplier = 2;
   */
  multiplier = 0;

  constructor(data?: PartialMessage<Wavedrop2UserResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.Wavedrop2UserResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "black_pearls", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 2, name: "multiplier", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Wavedrop2UserResponse {
    return new Wavedrop2UserResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Wavedrop2UserResponse {
    return new Wavedrop2UserResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Wavedrop2UserResponse {
    return new Wavedrop2UserResponse().fromJsonString(jsonString, options);
  }

  static equals(a: Wavedrop2UserResponse | PlainMessage<Wavedrop2UserResponse> | undefined, b: Wavedrop2UserResponse | PlainMessage<Wavedrop2UserResponse> | undefined): boolean {
    return proto3.util.equals(Wavedrop2UserResponse, a, b);
  }
}

/**
 * @generated from message swell.v3.BtcLrtUserRequest
 */
export class BtcLrtUserRequest extends Message<BtcLrtUserRequest> {
  /**
   * @generated from field: string wallet_address = 1;
   */
  walletAddress = "";

  constructor(data?: PartialMessage<BtcLrtUserRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.BtcLrtUserRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "wallet_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BtcLrtUserRequest {
    return new BtcLrtUserRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BtcLrtUserRequest {
    return new BtcLrtUserRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BtcLrtUserRequest {
    return new BtcLrtUserRequest().fromJsonString(jsonString, options);
  }

  static equals(a: BtcLrtUserRequest | PlainMessage<BtcLrtUserRequest> | undefined, b: BtcLrtUserRequest | PlainMessage<BtcLrtUserRequest> | undefined): boolean {
    return proto3.util.equals(BtcLrtUserRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.BtcLrtUserResponse
 */
export class BtcLrtUserResponse extends Message<BtcLrtUserResponse> {
  /**
   * @generated from field: float black_pearls = 1;
   */
  blackPearls = 0;

  /**
   * @generated from field: float multiplier = 2;
   */
  multiplier = 0;

  constructor(data?: PartialMessage<BtcLrtUserResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.BtcLrtUserResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "black_pearls", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 2, name: "multiplier", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BtcLrtUserResponse {
    return new BtcLrtUserResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BtcLrtUserResponse {
    return new BtcLrtUserResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BtcLrtUserResponse {
    return new BtcLrtUserResponse().fromJsonString(jsonString, options);
  }

  static equals(a: BtcLrtUserResponse | PlainMessage<BtcLrtUserResponse> | undefined, b: BtcLrtUserResponse | PlainMessage<BtcLrtUserResponse> | undefined): boolean {
    return proto3.util.equals(BtcLrtUserResponse, a, b);
  }
}

/**
 * @generated from message swell.v3.RswellUserRequest
 */
export class RswellUserRequest extends Message<RswellUserRequest> {
  /**
   * @generated from field: string wallet_address = 1;
   */
  walletAddress = "";

  constructor(data?: PartialMessage<RswellUserRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.RswellUserRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "wallet_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RswellUserRequest {
    return new RswellUserRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RswellUserRequest {
    return new RswellUserRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RswellUserRequest {
    return new RswellUserRequest().fromJsonString(jsonString, options);
  }

  static equals(a: RswellUserRequest | PlainMessage<RswellUserRequest> | undefined, b: RswellUserRequest | PlainMessage<RswellUserRequest> | undefined): boolean {
    return proto3.util.equals(RswellUserRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.RswellUserResponse
 */
export class RswellUserResponse extends Message<RswellUserResponse> {
  /**
   * @generated from field: float black_pearls = 1;
   */
  blackPearls = 0;

  /**
   * @generated from field: float multiplier = 2;
   */
  multiplier = 0;

  constructor(data?: PartialMessage<RswellUserResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.RswellUserResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "black_pearls", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 2, name: "multiplier", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RswellUserResponse {
    return new RswellUserResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RswellUserResponse {
    return new RswellUserResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RswellUserResponse {
    return new RswellUserResponse().fromJsonString(jsonString, options);
  }

  static equals(a: RswellUserResponse | PlainMessage<RswellUserResponse> | undefined, b: RswellUserResponse | PlainMessage<RswellUserResponse> | undefined): boolean {
    return proto3.util.equals(RswellUserResponse, a, b);
  }
}

/**
 * @generated from message swell.v3.UserPortfolioHoldingsRequest
 */
export class UserPortfolioHoldingsRequest extends Message<UserPortfolioHoldingsRequest> {
  /**
   * @generated from field: string wallet_address = 1;
   */
  walletAddress = "";

  constructor(data?: PartialMessage<UserPortfolioHoldingsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.UserPortfolioHoldingsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "wallet_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserPortfolioHoldingsRequest {
    return new UserPortfolioHoldingsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserPortfolioHoldingsRequest {
    return new UserPortfolioHoldingsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserPortfolioHoldingsRequest {
    return new UserPortfolioHoldingsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UserPortfolioHoldingsRequest | PlainMessage<UserPortfolioHoldingsRequest> | undefined, b: UserPortfolioHoldingsRequest | PlainMessage<UserPortfolioHoldingsRequest> | undefined): boolean {
    return proto3.util.equals(UserPortfolioHoldingsRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.UserPortfolioHoldingsResponse
 */
export class UserPortfolioHoldingsResponse extends Message<UserPortfolioHoldingsResponse> {
  /**
   * @generated from field: int32 num_assets_held = 1;
   */
  numAssetsHeld = 0;

  /**
   * @generated from field: int32 num_participating_campaigns = 2;
   */
  numParticipatingCampaigns = 0;

  /**
   * @generated from field: int32 total_asset_balance_cents = 3;
   */
  totalAssetBalanceCents = 0;

  constructor(data?: PartialMessage<UserPortfolioHoldingsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.UserPortfolioHoldingsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "num_assets_held", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "num_participating_campaigns", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "total_asset_balance_cents", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserPortfolioHoldingsResponse {
    return new UserPortfolioHoldingsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserPortfolioHoldingsResponse {
    return new UserPortfolioHoldingsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserPortfolioHoldingsResponse {
    return new UserPortfolioHoldingsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UserPortfolioHoldingsResponse | PlainMessage<UserPortfolioHoldingsResponse> | undefined, b: UserPortfolioHoldingsResponse | PlainMessage<UserPortfolioHoldingsResponse> | undefined): boolean {
    return proto3.util.equals(UserPortfolioHoldingsResponse, a, b);
  }
}

/**
 * @generated from message swell.v3.UserEarnCampaign
 */
export class UserEarnCampaign extends Message<UserEarnCampaign> {
  /**
   * @generated from field: float points = 1;
   */
  points = 0;

  /**
   * @generated from field: bool is_claimable = 2;
   */
  isClaimable = false;

  /**
   * @generated from field: float claimable_assets = 3;
   */
  claimableAssets = 0;

  /**
   * @generated from field: string claimable_asset_symbol = 4;
   */
  claimableAssetSymbol = "";

  constructor(data?: PartialMessage<UserEarnCampaign>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.UserEarnCampaign";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "points", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 2, name: "is_claimable", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "claimable_assets", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 4, name: "claimable_asset_symbol", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserEarnCampaign {
    return new UserEarnCampaign().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserEarnCampaign {
    return new UserEarnCampaign().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserEarnCampaign {
    return new UserEarnCampaign().fromJsonString(jsonString, options);
  }

  static equals(a: UserEarnCampaign | PlainMessage<UserEarnCampaign> | undefined, b: UserEarnCampaign | PlainMessage<UserEarnCampaign> | undefined): boolean {
    return proto3.util.equals(UserEarnCampaign, a, b);
  }
}

/**
 * @generated from message swell.v3.BlackPearlsWavedrop
 */
export class BlackPearlsWavedrop extends Message<BlackPearlsWavedrop> {
  /**
   * @generated from field: int32 wave_number = 1;
   */
  waveNumber = 0;

  /**
   * @generated from field: int32 end_unix = 2;
   */
  endUnix = 0;

  /**
   * @generated from field: swell.v3.UserEarnCampaign user_campaign = 3;
   */
  userCampaign?: UserEarnCampaign;

  constructor(data?: PartialMessage<BlackPearlsWavedrop>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.BlackPearlsWavedrop";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "wave_number", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "end_unix", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "user_campaign", kind: "message", T: UserEarnCampaign },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BlackPearlsWavedrop {
    return new BlackPearlsWavedrop().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BlackPearlsWavedrop {
    return new BlackPearlsWavedrop().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BlackPearlsWavedrop {
    return new BlackPearlsWavedrop().fromJsonString(jsonString, options);
  }

  static equals(a: BlackPearlsWavedrop | PlainMessage<BlackPearlsWavedrop> | undefined, b: BlackPearlsWavedrop | PlainMessage<BlackPearlsWavedrop> | undefined): boolean {
    return proto3.util.equals(BlackPearlsWavedrop, a, b);
  }
}

/**
 * @generated from message swell.v3.EarnCampaignsRequest
 */
export class EarnCampaignsRequest extends Message<EarnCampaignsRequest> {
  /**
   * @generated from field: string wallet_address = 1;
   */
  walletAddress = "";

  constructor(data?: PartialMessage<EarnCampaignsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.EarnCampaignsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "wallet_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EarnCampaignsRequest {
    return new EarnCampaignsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EarnCampaignsRequest {
    return new EarnCampaignsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EarnCampaignsRequest {
    return new EarnCampaignsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: EarnCampaignsRequest | PlainMessage<EarnCampaignsRequest> | undefined, b: EarnCampaignsRequest | PlainMessage<EarnCampaignsRequest> | undefined): boolean {
    return proto3.util.equals(EarnCampaignsRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.EarnCampaignsResponse
 */
export class EarnCampaignsResponse extends Message<EarnCampaignsResponse> {
  /**
   * @generated from field: swell.v3.UserEarnCampaign voyage = 1;
   */
  voyage?: UserEarnCampaign;

  /**
   * @generated from field: swell.v3.UserEarnCampaign swell_l2_prelaunch = 2;
   */
  swellL2Prelaunch?: UserEarnCampaign;

  /**
   * @generated from field: repeated swell.v3.BlackPearlsWavedrop black_pearl_wavedrops = 3;
   */
  blackPearlWavedrops: BlackPearlsWavedrop[] = [];

  /**
   * @generated from field: swell.v3.UserEarnCampaign symbiotic = 4;
   */
  symbiotic?: UserEarnCampaign;

  /**
   * @generated from field: swell.v3.UserEarnCampaign eigenlayer_season_2 = 5;
   */
  eigenlayerSeason2?: UserEarnCampaign;

  constructor(data?: PartialMessage<EarnCampaignsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.EarnCampaignsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "voyage", kind: "message", T: UserEarnCampaign },
    { no: 2, name: "swell_l2_prelaunch", kind: "message", T: UserEarnCampaign },
    { no: 3, name: "black_pearl_wavedrops", kind: "message", T: BlackPearlsWavedrop, repeated: true },
    { no: 4, name: "symbiotic", kind: "message", T: UserEarnCampaign },
    { no: 5, name: "eigenlayer_season_2", kind: "message", T: UserEarnCampaign },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EarnCampaignsResponse {
    return new EarnCampaignsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EarnCampaignsResponse {
    return new EarnCampaignsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EarnCampaignsResponse {
    return new EarnCampaignsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: EarnCampaignsResponse | PlainMessage<EarnCampaignsResponse> | undefined, b: EarnCampaignsResponse | PlainMessage<EarnCampaignsResponse> | undefined): boolean {
    return proto3.util.equals(EarnCampaignsResponse, a, b);
  }
}

/**
 * @generated from message swell.v3.EarnClaimableAssetAmount
 */
export class EarnClaimableAssetAmount extends Message<EarnClaimableAssetAmount> {
  /**
   * @generated from field: string asset_id = 1;
   */
  assetId = "";

  /**
   * @generated from field: float amount = 2;
   */
  amount = 0;

  constructor(data?: PartialMessage<EarnClaimableAssetAmount>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.EarnClaimableAssetAmount";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "asset_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "amount", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EarnClaimableAssetAmount {
    return new EarnClaimableAssetAmount().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EarnClaimableAssetAmount {
    return new EarnClaimableAssetAmount().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EarnClaimableAssetAmount {
    return new EarnClaimableAssetAmount().fromJsonString(jsonString, options);
  }

  static equals(a: EarnClaimableAssetAmount | PlainMessage<EarnClaimableAssetAmount> | undefined, b: EarnClaimableAssetAmount | PlainMessage<EarnClaimableAssetAmount> | undefined): boolean {
    return proto3.util.equals(EarnClaimableAssetAmount, a, b);
  }
}

/**
 * @generated from message swell.v3.EarnPointsAmount
 */
export class EarnPointsAmount extends Message<EarnPointsAmount> {
  /**
   * @generated from field: string points_id = 1;
   */
  pointsId = "";

  /**
   * @generated from field: float amount = 2;
   */
  amount = 0;

  constructor(data?: PartialMessage<EarnPointsAmount>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.EarnPointsAmount";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "points_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "amount", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EarnPointsAmount {
    return new EarnPointsAmount().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EarnPointsAmount {
    return new EarnPointsAmount().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EarnPointsAmount {
    return new EarnPointsAmount().fromJsonString(jsonString, options);
  }

  static equals(a: EarnPointsAmount | PlainMessage<EarnPointsAmount> | undefined, b: EarnPointsAmount | PlainMessage<EarnPointsAmount> | undefined): boolean {
    return proto3.util.equals(EarnPointsAmount, a, b);
  }
}

/**
 * @generated from message swell.v3.MaybeEarnPositionBalances
 */
export class MaybeEarnPositionBalances extends Message<MaybeEarnPositionBalances> {
  /**
   * @generated from field: bool is_present = 1;
   */
  isPresent = false;

  /**
   * @generated from field: int32 pearls = 2;
   */
  pearls = 0;

  /**
   * @generated from field: int32 balance_cents = 3;
   */
  balanceCents = 0;

  constructor(data?: PartialMessage<MaybeEarnPositionBalances>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.MaybeEarnPositionBalances";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "is_present", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "pearls", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "balance_cents", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MaybeEarnPositionBalances {
    return new MaybeEarnPositionBalances().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MaybeEarnPositionBalances {
    return new MaybeEarnPositionBalances().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MaybeEarnPositionBalances {
    return new MaybeEarnPositionBalances().fromJsonString(jsonString, options);
  }

  static equals(a: MaybeEarnPositionBalances | PlainMessage<MaybeEarnPositionBalances> | undefined, b: MaybeEarnPositionBalances | PlainMessage<MaybeEarnPositionBalances> | undefined): boolean {
    return proto3.util.equals(MaybeEarnPositionBalances, a, b);
  }
}

/**
 * @generated from message swell.v3.EarnPositionsRequest
 */
export class EarnPositionsRequest extends Message<EarnPositionsRequest> {
  /**
   * @generated from field: string wallet_address = 1;
   */
  walletAddress = "";

  constructor(data?: PartialMessage<EarnPositionsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.EarnPositionsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "wallet_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EarnPositionsRequest {
    return new EarnPositionsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EarnPositionsRequest {
    return new EarnPositionsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EarnPositionsRequest {
    return new EarnPositionsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: EarnPositionsRequest | PlainMessage<EarnPositionsRequest> | undefined, b: EarnPositionsRequest | PlainMessage<EarnPositionsRequest> | undefined): boolean {
    return proto3.util.equals(EarnPositionsRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.EarnPositionsResponse
 */
export class EarnPositionsResponse extends Message<EarnPositionsResponse> {
  /**
   * @generated from field: string position_id = 1;
   */
  positionId = "";

  /**
   * deprecated
   *
   * @generated from field: float balance = 2;
   */
  balance = 0;

  /**
   * @generated from field: swell.v3.EarnBalanceType balance_type = 3;
   */
  balanceType = EarnBalanceType.UNSPECIFIED;

  /**
   * @generated from field: repeated float balances = 4;
   */
  balances: number[] = [];

  constructor(data?: PartialMessage<EarnPositionsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.EarnPositionsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "position_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "balance", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 3, name: "balance_type", kind: "enum", T: proto3.getEnumType(EarnBalanceType) },
    { no: 4, name: "balances", kind: "scalar", T: 2 /* ScalarType.FLOAT */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EarnPositionsResponse {
    return new EarnPositionsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EarnPositionsResponse {
    return new EarnPositionsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EarnPositionsResponse {
    return new EarnPositionsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: EarnPositionsResponse | PlainMessage<EarnPositionsResponse> | undefined, b: EarnPositionsResponse | PlainMessage<EarnPositionsResponse> | undefined): boolean {
    return proto3.util.equals(EarnPositionsResponse, a, b);
  }
}

/**
 * @generated from message swell.v3.MultichainToken
 */
export class MultichainToken extends Message<MultichainToken> {
  /**
   * @generated from field: int32 chain_id = 1;
   */
  chainId = 0;

  /**
   * @generated from field: string token_address = 2;
   */
  tokenAddress = "";

  constructor(data?: PartialMessage<MultichainToken>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.MultichainToken";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "chain_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "token_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MultichainToken {
    return new MultichainToken().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MultichainToken {
    return new MultichainToken().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MultichainToken {
    return new MultichainToken().fromJsonString(jsonString, options);
  }

  static equals(a: MultichainToken | PlainMessage<MultichainToken> | undefined, b: MultichainToken | PlainMessage<MultichainToken> | undefined): boolean {
    return proto3.util.equals(MultichainToken, a, b);
  }
}

/**
 * @generated from message swell.v3.MultichainBalancesRequest
 */
export class MultichainBalancesRequest extends Message<MultichainBalancesRequest> {
  /**
   * @generated from field: string wallet_address = 1;
   */
  walletAddress = "";

  /**
   * @generated from field: repeated int32 chain_ids = 2;
   */
  chainIds: number[] = [];

  /**
   * @generated from field: repeated swell.v3.MultichainToken tokens = 3;
   */
  tokens: MultichainToken[] = [];

  /**
   * @generated from field: bool exact_tokens = 4;
   */
  exactTokens = false;

  constructor(data?: PartialMessage<MultichainBalancesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.MultichainBalancesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "wallet_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "chain_ids", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 3, name: "tokens", kind: "message", T: MultichainToken, repeated: true },
    { no: 4, name: "exact_tokens", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MultichainBalancesRequest {
    return new MultichainBalancesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MultichainBalancesRequest {
    return new MultichainBalancesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MultichainBalancesRequest {
    return new MultichainBalancesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: MultichainBalancesRequest | PlainMessage<MultichainBalancesRequest> | undefined, b: MultichainBalancesRequest | PlainMessage<MultichainBalancesRequest> | undefined): boolean {
    return proto3.util.equals(MultichainBalancesRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.MultichainBalancesResponse
 */
export class MultichainBalancesResponse extends Message<MultichainBalancesResponse> {
  /**
   * @generated from field: int32 chain_id = 1;
   */
  chainId = 0;

  /**
   * @generated from field: string token_address = 2;
   */
  tokenAddress = "";

  /**
   * @generated from field: string balance = 3;
   */
  balance = "";

  /**
   * @generated from field: string token_symbol = 4;
   */
  tokenSymbol = "";

  constructor(data?: PartialMessage<MultichainBalancesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.MultichainBalancesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "chain_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "token_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "balance", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "token_symbol", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MultichainBalancesResponse {
    return new MultichainBalancesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MultichainBalancesResponse {
    return new MultichainBalancesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MultichainBalancesResponse {
    return new MultichainBalancesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: MultichainBalancesResponse | PlainMessage<MultichainBalancesResponse> | undefined, b: MultichainBalancesResponse | PlainMessage<MultichainBalancesResponse> | undefined): boolean {
    return proto3.util.equals(MultichainBalancesResponse, a, b);
  }
}

