// @generated by protoc-gen-connect-es v1.1.2 with parameter "target=ts"
// @generated from file swell/v3/layerzerobridge.proto (package swell.v3, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { LayerZeroQuoteRequest, LayerZeroQuoteResponse, LayerZeroTxStatusRequest, LayerZeroTxStatusResponse } from "./layerzerobridge_pb.js";
import { MethodIdempotency, MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service swell.v3.LayerZeroService
 */
export const LayerZeroService = {
  typeName: "swell.v3.LayerZeroService",
  methods: {
    /**
     * @generated from rpc swell.v3.LayerZeroService.Quote
     */
    quote: {
      name: "Quote",
      I: LayerZeroQuoteRequest,
      O: LayerZeroQuoteResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc swell.v3.LayerZeroService.TxStatus
     */
    txStatus: {
      name: "TxStatus",
      I: LayerZeroTxStatusRequest,
      O: LayerZeroTxStatusResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.Idempotent,
    },
  }
} as const;

