export function readFileAsText(
  file: File,
  onLoad: (text: string) => void,
  onError: (error: DOMException | null) => void
) {
  const fileReader = new FileReader()
  fileReader.readAsText(file)

  fileReader.onload = function () {
    onLoad(fileReader.result as string)
  }

  fileReader.onerror = function () {
    onError(fileReader.error)
  }
}
