import { displayCryptoLocale } from '@/util/displayCrypto'
import { displayFiat } from '@/util/displayFiat'
import { formatWithConfig, formatWithDynamicPrecision } from '@/util/number'
import { BigNumber } from 'ethers'
import {
  GlobalBridgeEventsSummary,
  GlobalBridgeEventSummary,
  PredepositInputs,
  PredepositTokensSummary,
  PredepositWithdrawalSummary,
  SwellchainIncentivesSummary,
  PredepositWithdrawController,
  SwellchainStatsSummary,
} from './types'
import { PredepositTokens } from '@/state/predeposit/hooks'
import { PredepositStats, PredepositUser } from '@/state/predeposit/types'
import { L2BridgeTxEvent } from '@/state/l2promotions/types'
import { TransactionFeeResult } from '@/hooks/useTransactionFee'
import { applyRateBigNumberToFloat } from '@/util/big'
import { formatUnits } from 'ethers/lib/utils'
import { formatTimeAgoAbbrev } from '@/util/displayTime'
import { Token } from '@/types/tokens'
import { shortenAddress } from '@/util/hexStrings'
import { SwellchainIncentive } from '@/types/incentives'
import { NucleusBalances } from '@/state/nucleusVault/types'
import { determineIsEarnEthAsset } from './util'
import { L2EcosystemStats } from '@/state/l2ecosystem/types'

export function makeGlobalBridgeEventsSummary({
  globalBridgeEvents,
  nowMs,
  tokens,
  maxSize,
}: {
  globalBridgeEvents: L2BridgeTxEvent[] | undefined
  nowMs: number
  tokens: Token[]
  maxSize: number
}): GlobalBridgeEventsSummary {
  const events: GlobalBridgeEventSummary[] = []

  const globalSortedByTimeDesc = (globalBridgeEvents ?? []).sort(
    (a, b) => b.timestampUnix - a.timestampUnix
  )
  for (let i = 0; i < globalSortedByTimeDesc.length; i++) {
    if (i >= maxSize) {
      break
    }
    const e = globalSortedByTimeDesc[i]
    const timestampMs = e.timestampUnix * 1000

    if (timestampMs > nowMs) {
      continue // discard future events
    }

    let decimals = -1
    for (const token of tokens) {
      if (token.symbol === e.symbol) {
        decimals = token.decimals
        break
      }
    }
    if (decimals === -1) {
      console.error(`Token ${e.symbol} not found`)
      continue
    }

    let amount = ''
    try {
      if (e.amount < 0.01) {
        amount = '<0.01'
      } else {
        amount = formatWithDynamicPrecision(e.amount, { localize: true })
      }
    } catch (e) {
      console.error(e)
      continue
    }
    events.push({
      timeAgo: formatTimeAgoAbbrev({ nowMs, timestampMs }),
      timestampMs,
      url: e.url,
      account: shortenAddress(e.account),
      amount,
      symbol: e.symbol,
    })
  }

  return { events }
}

export function makePredepositTokensSummary({
  predepositTokens,
  predepositUser,
  account,
  earnEthTokens,
  l1Tokens,
  doNotVamp,
}: {
  predepositTokens: PredepositTokens
  predepositUser: PredepositUser | undefined
  account: string | undefined
  earnEthTokens: Token[]
  l1Tokens: Token[]
  doNotVamp: Token[]
}): PredepositTokensSummary {
  let s: PredepositTokensSummary = []

  const tokenToStakedNum: Partial<Record<string, number>> = {}
  if (predepositUser) {
    for (const token of predepositTokens.tokenList.tokens) {
      const balance = predepositUser.stakes[token.address]
      if (balance) {
        tokenToStakedNum[token.address] = Number(
          formatUnits(balance, token.decimals)
        )
      }
    }
  }

  for (const token of predepositTokens.tokenList.tokens) {
    const withdrawalSupported =
      predepositTokens.tokenSupportedMap[token.address]?.withdrawSupported ??
      false

    if (!withdrawalSupported) continue

    let balance: BigNumber | undefined = undefined
    if (predepositUser) {
      balance = predepositUser.stakes[token.address]
    }

    let staked: string | undefined
    if (balance) {
      staked = displayCryptoLocale(balance, token.decimals)
    }

    if (!account) {
      staked = '0'
    }

    const isEarnEthAsset = determineIsEarnEthAsset({
      tokenAddress: token.address,
      earnEthTokens,
      l1Tokens,
      doNotVamp,
    })

    s.push({
      logoURI: token.logoURI,
      symbol: token.symbol,
      address: token.address,
      chainId: token.chainId,
      name: token.name,
      staked: staked,
      isEarnEthAsset,
    })
  }

  // sort by staked
  s = s.sort((a, b) => {
    const aStaked = tokenToStakedNum[a.address] ?? 0
    const bStaked = tokenToStakedNum[b.address] ?? 0
    return bStaked - aStaked
  })

  return s
}

export function makePredepositWithdrawalSummary({
  predepositStats,
  predepositInputs,
  predepositUser,
  withdrawalTxFeeResult,
  ethUsdRate,
  account,
  controller,
  earnEthBalances,
  doNotVamp,
  earnEthTokens,
  l1Tokens,
}: {
  predepositStats: PredepositStats | undefined
  predepositInputs: Pick<PredepositInputs, 'withdrawAmount' | 'withdrawToken'>
  predepositUser: PredepositUser | undefined
  withdrawalTxFeeResult: TransactionFeeResult | undefined
  ethUsdRate: number | undefined
  account: string | undefined
  controller: Pick<PredepositWithdrawController, 'phase'>
  earnEthBalances: NucleusBalances | undefined
  doNotVamp: Token[]
  earnEthTokens: Token[]
  l1Tokens: Token[]
}): PredepositWithdrawalSummary {
  const s: PredepositWithdrawalSummary = {
    available: '',
    gasCost: '',
    valueUsd: '',
  }

  if (predepositInputs.withdrawToken) {
    const { withdrawToken } = predepositInputs
    const isEarnEthAsset = determineIsEarnEthAsset({
      tokenAddress: withdrawToken.address,
      doNotVamp,
      earnEthTokens,
      l1Tokens,
    })

    if (predepositUser) {
      if (
        !isEarnEthAsset ||
        controller.phase === 'withdraw' ||
        controller.phase === 'unknown'
      ) {
        const b = predepositUser.stakes[withdrawToken.address]
        if (b !== undefined) {
          s.available = `${displayCryptoLocale(b, withdrawToken.decimals)} ${
            withdrawToken.symbol
          }`
        }
      } else if (earnEthBalances) {
        const b =
          earnEthBalances.assets?.[withdrawToken.chainId]?.[
            withdrawToken.address
          ]
        if (b !== undefined) {
          s.available = `0 ${withdrawToken.symbol}`
        }
      }
    }
  }

  if (withdrawalTxFeeResult && ethUsdRate) {
    s.gasCost = displayFiat(
      applyRateBigNumberToFloat(withdrawalTxFeeResult.feeWei, ethUsdRate, {
        base: { decimals: 18 },
      })
    )
  }

  if (predepositStats) {
    const { ratesUsd } = predepositStats
    if (predepositInputs.withdrawToken) {
      const { withdrawAmount, withdrawToken } = predepositInputs
      const rate = ratesUsd[withdrawToken.address]
      if (withdrawAmount && rate) {
        const withdrawAmountUsd = applyRateBigNumberToFloat(
          withdrawAmount,
          rate,
          { base: { decimals: withdrawToken.decimals } }
        )
        s.valueUsd = displayFiat(withdrawAmountUsd)
      }
    }
  }

  if (!account && !s.available && predepositInputs.withdrawToken?.symbol) {
    s.available = `- ${predepositInputs.withdrawToken.symbol}`
  }

  return s
}

function formatEstimatedDuration(tMs: number) {
  const ONE_HOUR = 60 * 60 * 1000
  const ONE_MINUTE = 60 * 1000
  const ONE_DAY = 24 * ONE_HOUR

  if (tMs < ONE_MINUTE) {
    return `${formatWithConfig(tMs / 1000, { localize: false, precision: 0 })}s`
  }
  if (tMs < ONE_HOUR) {
    return `${formatWithConfig(tMs / ONE_MINUTE, {
      localize: false,
      precision: 0,
    })}m`
  }
  if (tMs < ONE_DAY) {
    return `${formatWithConfig(tMs / ONE_HOUR, {
      localize: false,
      precision: 0,
    })}h`
  }
  return `${formatWithConfig(tMs / ONE_DAY, {
    localize: false,
    precision: 0,
  })}d`
}

function formatRouteRate({
  fromTokenSymbol,
  rate,
  toTokenSymbol,
  rateDecimals,
}: {
  rate: BigNumber
  rateDecimals: number
  toTokenSymbol: string
  fromTokenSymbol: string
}) {
  const toStr = `1 ${toTokenSymbol}`
  const requiredFromStr = `${displayCryptoLocale(rate, rateDecimals, {
    precision: 4,
    roundLarge: true,
  })} ${fromTokenSymbol}`
  return `${toStr} = ${requiredFromStr}`
}

type CurrentIncentive =
  | Pick<SwellchainIncentive, 'swellToDistribute' | 'targetDateMs' | 'noReveal'>
  | undefined

export function makeIncentivesSummary({
  daoToken,
  currentIncentive,
  fallbackIntervalMs,
  nowMs,
}: {
  daoToken: Token
  fallbackIntervalMs: number
  currentIncentive: CurrentIncentive
  nowMs: number
}): SwellchainIncentivesSummary {
  let amount = ''
  if (currentIncentive) {
    amount = displayCryptoLocale(
      currentIncentive.swellToDistribute,
      daoToken.decimals
    )
  }
  let targetDateMs = 0
  let scrambleClock = false
  if (currentIncentive && currentIncentive.noReveal) {
    scrambleClock = true
  } else if (currentIncentive) {
    targetDateMs = currentIncentive.targetDateMs
  }

  let i = 0
  while (targetDateMs > 0 && nowMs > targetDateMs) {
    targetDateMs = targetDateMs + fallbackIntervalMs

    i++
    if (i > 19) {
      console.warn('incentive target date fallback infinite loop')
      break
    }
  }

  return {
    amount,
    targetDateMs,
    scrambleClock,
  }
}

export function makeSwellchainStatsSummary({
  l2Stats,
}: {
  l2Stats: L2EcosystemStats | undefined
}): SwellchainStatsSummary {
  const s: SwellchainStatsSummary = {
    tvl: '',
  }
  if (l2Stats) {
    s.tvl = displayFiat(l2Stats.tvlUsd, true)
  }
  return s
}
