// @generated by protoc-gen-es v1.3.3 with parameter "target=ts"
// @generated from file swell/v3/lifibridge.proto (package swell.v3, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum swell.v3.LifiStatus
 */
export enum LifiStatus {
  /**
   * @generated from enum value: LIFI_STATUS_UNKNOWN = 0;
   */
  UNKNOWN = 0,

  /**
   * @generated from enum value: LIFI_STATUS_PENDING = 1;
   */
  PENDING = 1,

  /**
   * @generated from enum value: LIFI_STATUS_CONFIRMED = 2;
   */
  CONFIRMED = 2,

  /**
   * @generated from enum value: LIFI_STATUS_ERROR = 3;
   */
  ERROR = 3,

  /**
   * @generated from enum value: LIFI_STATUS_REFUND = 4;
   */
  REFUND = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(LifiStatus)
proto3.util.setEnumType(LifiStatus, "swell.v3.LifiStatus", [
  { no: 0, name: "LIFI_STATUS_UNKNOWN" },
  { no: 1, name: "LIFI_STATUS_PENDING" },
  { no: 2, name: "LIFI_STATUS_CONFIRMED" },
  { no: 3, name: "LIFI_STATUS_ERROR" },
  { no: 4, name: "LIFI_STATUS_REFUND" },
]);

/**
 * @generated from message swell.v3.LifiBridgeQuoteRequest
 */
export class LifiBridgeQuoteRequest extends Message<LifiBridgeQuoteRequest> {
  /**
   * @generated from field: int32 from_chain_id = 1;
   */
  fromChainId = 0;

  /**
   * @generated from field: int32 to_chain_id = 2;
   */
  toChainId = 0;

  /**
   * @generated from field: string from_token_address = 3;
   */
  fromTokenAddress = "";

  /**
   * @generated from field: string from_amount_wei = 4;
   */
  fromAmountWei = "";

  /**
   * @generated from field: int32 from_decimals = 5;
   */
  fromDecimals = 0;

  /**
   * @generated from field: string to_token_address = 6;
   */
  toTokenAddress = "";

  /**
   * @generated from field: int32 to_decimals = 7;
   */
  toDecimals = 0;

  /**
   * @generated from field: string from_account = 8;
   */
  fromAccount = "";

  /**
   * @generated from field: string to_account = 9;
   */
  toAccount = "";

  constructor(data?: PartialMessage<LifiBridgeQuoteRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.LifiBridgeQuoteRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "from_chain_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "to_chain_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "from_token_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "from_amount_wei", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "from_decimals", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "to_token_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "to_decimals", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 8, name: "from_account", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "to_account", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LifiBridgeQuoteRequest {
    return new LifiBridgeQuoteRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LifiBridgeQuoteRequest {
    return new LifiBridgeQuoteRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LifiBridgeQuoteRequest {
    return new LifiBridgeQuoteRequest().fromJsonString(jsonString, options);
  }

  static equals(a: LifiBridgeQuoteRequest | PlainMessage<LifiBridgeQuoteRequest> | undefined, b: LifiBridgeQuoteRequest | PlainMessage<LifiBridgeQuoteRequest> | undefined): boolean {
    return proto3.util.equals(LifiBridgeQuoteRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.LifiBridgeQuote
 */
export class LifiBridgeQuote extends Message<LifiBridgeQuote> {
  /**
   * @generated from field: int32 estTimeSeconds = 1;
   */
  estTimeSeconds = 0;

  /**
   * @generated from field: int32 fromChainId = 2;
   */
  fromChainId = 0;

  /**
   * @generated from field: string fromAmountWei = 3;
   */
  fromAmountWei = "";

  /**
   * @generated from field: int32 fromDecimals = 4;
   */
  fromDecimals = 0;

  /**
   * @generated from field: string fromTokenSymbol = 5;
   */
  fromTokenSymbol = "";

  /**
   * @generated from field: float fromAmountUsd = 6;
   */
  fromAmountUsd = 0;

  /**
   * @generated from field: float gasCostUsd = 7;
   */
  gasCostUsd = 0;

  /**
   * @generated from field: string rateWei = 8;
   */
  rateWei = "";

  /**
   * @generated from field: string toAmountWei = 9;
   */
  toAmountWei = "";

  /**
   * @generated from field: float toAmountUsd = 10;
   */
  toAmountUsd = 0;

  /**
   * @generated from field: int32 toChainId = 11;
   */
  toChainId = 0;

  /**
   * @generated from field: int32 toDecimals = 12;
   */
  toDecimals = 0;

  /**
   * @generated from field: string toTokenSymbol = 13;
   */
  toTokenSymbol = "";

  /**
   * @generated from field: string approveAddress = 14;
   */
  approveAddress = "";

  constructor(data?: PartialMessage<LifiBridgeQuote>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.LifiBridgeQuote";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "estTimeSeconds", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "fromChainId", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "fromAmountWei", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "fromDecimals", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "fromTokenSymbol", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "fromAmountUsd", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 7, name: "gasCostUsd", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 8, name: "rateWei", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "toAmountWei", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "toAmountUsd", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 11, name: "toChainId", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 12, name: "toDecimals", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 13, name: "toTokenSymbol", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "approveAddress", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LifiBridgeQuote {
    return new LifiBridgeQuote().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LifiBridgeQuote {
    return new LifiBridgeQuote().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LifiBridgeQuote {
    return new LifiBridgeQuote().fromJsonString(jsonString, options);
  }

  static equals(a: LifiBridgeQuote | PlainMessage<LifiBridgeQuote> | undefined, b: LifiBridgeQuote | PlainMessage<LifiBridgeQuote> | undefined): boolean {
    return proto3.util.equals(LifiBridgeQuote, a, b);
  }
}

/**
 * @generated from message swell.v3.LifiBridgePreparedTransaction
 */
export class LifiBridgePreparedTransaction extends Message<LifiBridgePreparedTransaction> {
  /**
   * @generated from field: uint32 chainId = 1;
   */
  chainId = 0;

  /**
   * @generated from field: string to = 2;
   */
  to = "";

  /**
   * @generated from field: string from = 3;
   */
  from = "";

  /**
   * @generated from field: string gasLimitWei = 4;
   */
  gasLimitWei = "";

  /**
   * @generated from field: string data = 5;
   */
  data = "";

  /**
   * @generated from field: string value = 6;
   */
  value = "";

  constructor(data?: PartialMessage<LifiBridgePreparedTransaction>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.LifiBridgePreparedTransaction";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "chainId", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "to", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "gasLimitWei", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "data", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LifiBridgePreparedTransaction {
    return new LifiBridgePreparedTransaction().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LifiBridgePreparedTransaction {
    return new LifiBridgePreparedTransaction().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LifiBridgePreparedTransaction {
    return new LifiBridgePreparedTransaction().fromJsonString(jsonString, options);
  }

  static equals(a: LifiBridgePreparedTransaction | PlainMessage<LifiBridgePreparedTransaction> | undefined, b: LifiBridgePreparedTransaction | PlainMessage<LifiBridgePreparedTransaction> | undefined): boolean {
    return proto3.util.equals(LifiBridgePreparedTransaction, a, b);
  }
}

/**
 * @generated from message swell.v3.LifiBridgeQuoteResponse
 */
export class LifiBridgeQuoteResponse extends Message<LifiBridgeQuoteResponse> {
  /**
   * @generated from field: bool found = 1;
   */
  found = false;

  /**
   * @generated from field: swell.v3.LifiBridgeQuote quote = 2;
   */
  quote?: LifiBridgeQuote;

  /**
   * @generated from field: swell.v3.LifiBridgePreparedTransaction preparedTransaction = 3;
   */
  preparedTransaction?: LifiBridgePreparedTransaction;

  constructor(data?: PartialMessage<LifiBridgeQuoteResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.LifiBridgeQuoteResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "found", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "quote", kind: "message", T: LifiBridgeQuote },
    { no: 3, name: "preparedTransaction", kind: "message", T: LifiBridgePreparedTransaction },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LifiBridgeQuoteResponse {
    return new LifiBridgeQuoteResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LifiBridgeQuoteResponse {
    return new LifiBridgeQuoteResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LifiBridgeQuoteResponse {
    return new LifiBridgeQuoteResponse().fromJsonString(jsonString, options);
  }

  static equals(a: LifiBridgeQuoteResponse | PlainMessage<LifiBridgeQuoteResponse> | undefined, b: LifiBridgeQuoteResponse | PlainMessage<LifiBridgeQuoteResponse> | undefined): boolean {
    return proto3.util.equals(LifiBridgeQuoteResponse, a, b);
  }
}

/**
 * @generated from message swell.v3.LifiBridgeTxState
 */
export class LifiBridgeTxState extends Message<LifiBridgeTxState> {
  /**
   * @generated from field: string tx_hash = 1;
   */
  txHash = "";

  /**
   * @generated from field: string account = 2;
   */
  account = "";

  /**
   * @generated from field: int32 timestampUnix = 3;
   */
  timestampUnix = 0;

  /**
   * @generated from field: int32 fromChainId = 4;
   */
  fromChainId = 0;

  /**
   * @generated from field: int32 toChainId = 5;
   */
  toChainId = 0;

  /**
   * @generated from field: string fromTokenSymbol = 6;
   */
  fromTokenSymbol = "";

  /**
   * @generated from field: string toTokenSymbol = 7;
   */
  toTokenSymbol = "";

  /**
   * @generated from field: string fromAmountWei = 8;
   */
  fromAmountWei = "";

  /**
   * @generated from field: string toAmountWei = 9;
   */
  toAmountWei = "";

  /**
   * @generated from field: int32 fromDecimals = 10;
   */
  fromDecimals = 0;

  /**
   * @generated from field: int32 toDecimals = 11;
   */
  toDecimals = 0;

  /**
   * @generated from field: string error = 12;
   */
  error = "";

  /**
   * @generated from field: swell.v3.LifiStatus status = 13;
   */
  status = LifiStatus.UNKNOWN;

  /**
   * @generated from field: string url = 14;
   */
  url = "";

  constructor(data?: PartialMessage<LifiBridgeTxState>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.LifiBridgeTxState";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "tx_hash", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "account", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "timestampUnix", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "fromChainId", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "toChainId", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "fromTokenSymbol", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "toTokenSymbol", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "fromAmountWei", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "toAmountWei", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "fromDecimals", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 11, name: "toDecimals", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 12, name: "error", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "status", kind: "enum", T: proto3.getEnumType(LifiStatus) },
    { no: 14, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LifiBridgeTxState {
    return new LifiBridgeTxState().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LifiBridgeTxState {
    return new LifiBridgeTxState().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LifiBridgeTxState {
    return new LifiBridgeTxState().fromJsonString(jsonString, options);
  }

  static equals(a: LifiBridgeTxState | PlainMessage<LifiBridgeTxState> | undefined, b: LifiBridgeTxState | PlainMessage<LifiBridgeTxState> | undefined): boolean {
    return proto3.util.equals(LifiBridgeTxState, a, b);
  }
}

/**
 * @generated from message swell.v3.LifiBridgeTxStatusRequest
 */
export class LifiBridgeTxStatusRequest extends Message<LifiBridgeTxStatusRequest> {
  /**
   * @generated from field: repeated string tx_hashes = 1;
   */
  txHashes: string[] = [];

  constructor(data?: PartialMessage<LifiBridgeTxStatusRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.LifiBridgeTxStatusRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "tx_hashes", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LifiBridgeTxStatusRequest {
    return new LifiBridgeTxStatusRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LifiBridgeTxStatusRequest {
    return new LifiBridgeTxStatusRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LifiBridgeTxStatusRequest {
    return new LifiBridgeTxStatusRequest().fromJsonString(jsonString, options);
  }

  static equals(a: LifiBridgeTxStatusRequest | PlainMessage<LifiBridgeTxStatusRequest> | undefined, b: LifiBridgeTxStatusRequest | PlainMessage<LifiBridgeTxStatusRequest> | undefined): boolean {
    return proto3.util.equals(LifiBridgeTxStatusRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.LifiBridgeTxStatusResponse
 */
export class LifiBridgeTxStatusResponse extends Message<LifiBridgeTxStatusResponse> {
  /**
   * @generated from field: repeated swell.v3.LifiBridgeTxState states = 1;
   */
  states: LifiBridgeTxState[] = [];

  constructor(data?: PartialMessage<LifiBridgeTxStatusResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.LifiBridgeTxStatusResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "states", kind: "message", T: LifiBridgeTxState, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LifiBridgeTxStatusResponse {
    return new LifiBridgeTxStatusResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LifiBridgeTxStatusResponse {
    return new LifiBridgeTxStatusResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LifiBridgeTxStatusResponse {
    return new LifiBridgeTxStatusResponse().fromJsonString(jsonString, options);
  }

  static equals(a: LifiBridgeTxStatusResponse | PlainMessage<LifiBridgeTxStatusResponse> | undefined, b: LifiBridgeTxStatusResponse | PlainMessage<LifiBridgeTxStatusResponse> | undefined): boolean {
    return proto3.util.equals(LifiBridgeTxStatusResponse, a, b);
  }
}

/**
 * @generated from message swell.v3.KnownTokensRequest
 */
export class KnownTokensRequest extends Message<KnownTokensRequest> {
  /**
   * @generated from field: repeated int32 chain_ids = 1;
   */
  chainIds: number[] = [];

  constructor(data?: PartialMessage<KnownTokensRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.KnownTokensRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "chain_ids", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): KnownTokensRequest {
    return new KnownTokensRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): KnownTokensRequest {
    return new KnownTokensRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): KnownTokensRequest {
    return new KnownTokensRequest().fromJsonString(jsonString, options);
  }

  static equals(a: KnownTokensRequest | PlainMessage<KnownTokensRequest> | undefined, b: KnownTokensRequest | PlainMessage<KnownTokensRequest> | undefined): boolean {
    return proto3.util.equals(KnownTokensRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.LifiKnownToken
 */
export class LifiKnownToken extends Message<LifiKnownToken> {
  /**
   * @generated from field: int32 chainId = 1;
   */
  chainId = 0;

  /**
   * @generated from field: string address = 2;
   */
  address = "";

  /**
   * @generated from field: string name = 3;
   */
  name = "";

  /**
   * @generated from field: string symbol = 4;
   */
  symbol = "";

  /**
   * @generated from field: int32 decimals = 5;
   */
  decimals = 0;

  /**
   * @generated from field: string logoURI = 6;
   */
  logoURI = "";

  /**
   * @generated from field: string coinKey = 7;
   */
  coinKey = "";

  constructor(data?: PartialMessage<LifiKnownToken>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.LifiKnownToken";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "chainId", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "symbol", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "decimals", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "logoURI", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "coinKey", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LifiKnownToken {
    return new LifiKnownToken().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LifiKnownToken {
    return new LifiKnownToken().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LifiKnownToken {
    return new LifiKnownToken().fromJsonString(jsonString, options);
  }

  static equals(a: LifiKnownToken | PlainMessage<LifiKnownToken> | undefined, b: LifiKnownToken | PlainMessage<LifiKnownToken> | undefined): boolean {
    return proto3.util.equals(LifiKnownToken, a, b);
  }
}

/**
 * @generated from message swell.v3.KnownTokensResponse
 */
export class KnownTokensResponse extends Message<KnownTokensResponse> {
  /**
   * @generated from field: repeated swell.v3.LifiKnownToken tokens = 1;
   */
  tokens: LifiKnownToken[] = [];

  constructor(data?: PartialMessage<KnownTokensResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.KnownTokensResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "tokens", kind: "message", T: LifiKnownToken, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): KnownTokensResponse {
    return new KnownTokensResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): KnownTokensResponse {
    return new KnownTokensResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): KnownTokensResponse {
    return new KnownTokensResponse().fromJsonString(jsonString, options);
  }

  static equals(a: KnownTokensResponse | PlainMessage<KnownTokensResponse> | undefined, b: KnownTokensResponse | PlainMessage<KnownTokensResponse> | undefined): boolean {
    return proto3.util.equals(KnownTokensResponse, a, b);
  }
}

