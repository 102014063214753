import { SelectTokenFn, Token } from '@/types/tokens'
import { useEffect, useState } from 'react'
import { useTransactionContext } from '@/state/transactions/context'
import { ScrollableYArea } from '@/swell-ui/ScrollableArea'
import { FlexRow } from '@/swell-ui/FlexRow'
import { Button } from '@/swell-ui/Button'
import { displayCryptoLocale } from '@/util/displayCrypto'
import styled, { createGlobalStyle, css } from 'styled-components'
import { AsyncDiv } from '@/swell-ui/AsyncDiv'
import { useSwellWeb3 } from '@/swell-web3/core'
import { getChainInfo } from '@/constants/chainInfo'
import useChainDetection from '@/hooks/useChainDetection'
import { TokenLogoV2 } from '@/components/TokenLogoV2'
import { TokenTrigger } from '@/components/TokenTrigger'

const GlobalStyle = createGlobalStyle`
  body {
    overflow: hidden;
  }
`

export function NucleusTokenModal({
  tokensWithBalances,
  selectedToken,
  selectToken,
}: {
  tokensWithBalances: Token[]
  selectedToken: Token | undefined
  selectToken: SelectTokenFn
}) {
  const { account } = useSwellWeb3()
  const { anyTransactionInProgress } = useTransactionContext()
  const [tokenSelectOpen, setTokenSelectOpen] = useState<boolean>(false)
  const [jumpUpProgress, setJumpUpProgress] = useState<number>(0)
  useEffect(() => {
    let done = false

    function animate() {
      if (done) return

      setJumpUpProgress((p) => {
        if (tokenSelectOpen && p < 1) {
          return Math.min(p + 0.2, 1)
        }

        if (!tokenSelectOpen && p > 0) {
          return Math.max(p - 0.2, 0)
        }

        return p
      })
      return requestAnimationFrame(animate)
    }

    requestAnimationFrame(animate)
    return () => {
      done = true
    }
  }, [tokenSelectOpen])

  return (
    <>
      <TokenTrigger
        onClick={() => {
          if (anyTransactionInProgress) return
          return setTokenSelectOpen(true)
        }}
        selectedToken={selectedToken}
        preventInteraction={anyTransactionInProgress}
      />
      {tokenSelectOpen && <GlobalStyle />}
      <Backdrop
        style={{
          opacity: jumpUpProgress,
          pointerEvents: tokenSelectOpen ? 'auto' : 'none',
        }}
        onClick={() => {
          setTokenSelectOpen(false)
        }}
      />
      <TokenPaper
        style={{
          opacity: jumpUpProgress,
          pointerEvents: tokenSelectOpen ? 'auto' : 'none',
        }}
      >
        <FlexRow justify="space-between">
          <span className="body-medium-bold">Deposit Token</span>
          <Button
            onClick={() => setTokenSelectOpen(false)}
            variant="secondary"
            size="small"
            style={{
              padding: '0',
              width: '29px',
              height: '29px',
              borderRadius: '6px',
              border: '1px solid rgba(164, 171, 241, 0.30)',
            }}
          >
            <CloseButton />
          </Button>
        </FlexRow>
        <TsTrackLayout>
          <ScrollableYArea>
            {tokensWithBalances.map((t) => {
              let amount = ''
              if (t.balance) {
                amount = displayCryptoLocale(t.balance, t.decimals)
              }
              if (!account) {
                amount = '0'
              }

              return (
                <TokenSelectRow
                  key={`${t.address}-${t.chainId}`}
                  token={t}
                  onClick={() => {
                    selectToken({ address: t.address, chainId: t.chainId })
                    setTokenSelectOpen(false)
                  }}
                  amount={amount}
                />
              )
            })}
          </ScrollableYArea>
        </TsTrackLayout>
      </TokenPaper>
    </>
  )
}

const TsPill = styled.div`
  display: flex;
  height: 32px;
  padding: 6px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  color: var(--white-50, #fff);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  letter-spacing: -0.48px;
  cursor: pointer;
`
const TsTrackLayout = styled.div`
  display: flex;
  flex-flow: column;
  flex-grow: 1;

  .scroll-area-y {
    ${({ theme }) =>
      theme.scrollbar &&
      css`
        &::-webkit-scrollbar {
          width: ${theme.scrollbar.width};
        }

        &::-webkit-scrollbar-track {
          background: ${theme.scrollbar.trackBg};
          border-radius: ${theme.scrollbar.borderRadius};
        }

        &::-webkit-scrollbar-thumb {
          background: ${theme.scrollbar.thumbBg};
          border-radius: ${theme.scrollbar.borderRadius};
        }
      `}

    > div {
      padding-right: 9px;
    }
  }
`

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  border-radius: 16px;
  backdrop-filter: blur(4px);
`

const bodyMediumBold = css`
  color: var(--Swell-White-100, #e7e7e7);
  /* Body/medium bold */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%; /* 25.6px */
`

const bodyMedium = css`
  color: var(--Swell-White-125, #b0b0b0);
  /* Body/medium */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 25.6px */
  letter-spacing: -0.48px;
`

const bodyXSmall = css`
  color: var(--Swell-White-125, #b0b0b0);
  /* Body/xsmall */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 19.2px */
  letter-spacing: -0.36px;
`

const bodyLargeBold = css`
  color: var(--Swell-White-50, #fff);

  /* Body/large bold */
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 28.8px */
`

const TokenPaper = styled.div`
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 420px;
  max-height: 525px;
  width: 100%;

  z-index: 1001;
  border-radius: 16px;
  height: 80%;
  padding: 24px 32px;

  background: var(--Swell-Oceanblue-150, #010508);
  border: 0.8px solid rgba(164, 171, 241, 0.2);

  display: flex;
  flex-direction: column;
  gap: 24px;

  .body-medium-bold {
    ${bodyMediumBold};
  }
  .body-medium {
    ${bodyMedium};
    color: white !important;
  }
  .body-xsmall {
    ${bodyXSmall};
  }
  .body-large-bold {
    ${bodyLargeBold};
  }
`
function CaretDown() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="5"
      viewBox="0 0 8 5"
      fill="none"
    >
      <path d="M0 0.5L4 4.5L8 0.5H0Z" fill="#fff" />
    </svg>
  )
}

function TokenSelectRow({
  token,
  amount,
  onClick,
}: {
  token: Token
  amount: string | undefined
  onClick?: () => void
}) {
  const { l2DeploymentChainId } = useChainDetection()
  const l2ChainName = getChainInfo(l2DeploymentChainId).label

  return (
    <TsRowLayout onClick={onClick}>
      <div className="ts-logo">
        <TokenLogoV2 token={token} />
      </div>
      <div className="ts-name">
        <FlexRow direction="column" gap="10" align="start" justify="center">
          <span className="body-medium">{token.name}</span>
          <span className="body-xsmall">
            {token.symbol}{' '}
            {token.chainId === l2DeploymentChainId ? `• ${l2ChainName}` : ''}
          </span>
        </FlexRow>
      </div>
      <div className="ts-amount">
        <div>
          <AsyncDiv loading={!amount}>
            {() => <span className="body-large-bold">{amount}</span>}
          </AsyncDiv>
        </div>
      </div>
    </TsRowLayout>
  )
}

const TsRowLayout = styled.div`
  padding: 12px;
  height: 69px;

  &:first-of-type {
    border-radius: 12px 12px 0 0;
  }
  &:last-of-type {
    border-radius: 0 0 12px 12px;
  }

  [aria-busy='false'] {
    min-width: 0;
  }

  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-areas: 'logo name amount';

  .ts-logo {
    grid-area: logo;
    justify-self: center;
    align-self: center;
    margin-right: 12px;
  }
  .ts-name {
    grid-area: name;
    align-self: stretch;
    display: grid;
    position: relative;
    > div {
      width: 195px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      span {
        line-height: 100%;
      }
    }
  }
  .ts-amount {
    grid-area: amount;
    justify-self: end;
    align-self: center;
    /* text-align: right; */

    position: relative;
    > div {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      background-color: #011416;
      transition: background-color 0.2s;

      max-width: 120px;
      overflow: hidden;
    }
  }

  &:not(:last-of-type) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }

  background-color: #011416;
  &:hover {
    background-color: #0e3f44;

    .ts-amount {
      > div {
        background-color: #0e3f44;
      }
    }
  }

  transition: background-color 0.2s;
  cursor: pointer;
`

export function CloseButton() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <g clip-path="url(#clip0_12174_7761)">
        <path
          d="M17.1875 4.8125L4.8125 17.1875"
          stroke="white"
          stroke-width="1.375"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M17.1875 17.1875L4.8125 4.8125"
          stroke="white"
          stroke-width="1.375"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_12174_7761">
          <rect width="22" height="22" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
