import { Token } from '@/types/tokens'
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { SortingDirection } from '@/types/sorting'
import { useSwellWeb3 } from '@/swell-web3/core'
import {
  EarnCampaignsOrderBy,
  EarnPositionFilters,
  EarnPositionsOrderBy,
} from '@/types/portfolioFilters'

export function useDefiCampaignsFilters({
  filterTokenOptions,
  filterChainOptions,
}: {
  filterTokenOptions: Token[]
  filterChainOptions: number[]
}) {
  const supportedTokenSymbolsSet = new Set(
    filterTokenOptions.map((token) => token.symbol)
  )
  const supportedChainIdsSet = new Set(filterChainOptions)

  const [selectedTokenSymbols, setSelectedTokenSymbols] = useState<string[]>(
    Array.from(supportedTokenSymbolsSet)
  )
  const [selectedChainIds, setSelectedChainIds] = useState<number[]>(
    Array.from(supportedChainIdsSet)
  )
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [hideZeroBalances, setHideZeroBalances] = useState<boolean>(false)

  const filters: EarnPositionFilters = {
    chainIds: selectedChainIds,
    hideZeroBalances,
    includeTokenSymbols: selectedTokenSymbols,
    searchTerm,
  }

  const setChainFilter = setSelectedChainIds
  const setSelectedTokens = setSelectedTokenSymbols

  const setSearchTermFilter = useCallback((term: string) => {
    setSearchTerm(term)
  }, [])

  const clearSearchTerm = useCallback(() => {
    setSearchTerm('')
  }, [])

  const supportedChainIdsArray = Array.from(supportedChainIdsSet)
  const supportedTokenSymbolsArray = Array.from(supportedTokenSymbolsSet)

  const clearFilters = useCallback(() => {
    setSelectedChainIds(supportedChainIdsArray)
    setSelectedTokenSymbols(supportedTokenSymbolsArray)
    setSearchTerm('')
  }, [supportedChainIdsArray, supportedTokenSymbolsArray])

  let activeFilters = 0
  if (selectedTokenSymbols.length < supportedTokenSymbolsSet.size) {
    activeFilters++
  }
  if (selectedChainIds.length < supportedChainIdsSet.size) {
    activeFilters++
  }
  if (searchTerm) {
    activeFilters++
  }

  return {
    filters,
    setSelectedTokens,
    setChainFilter,
    setSearchTermFilter,
    clearSearchTerm,
    clearFilters,
    setHideZeroBalances,
    activeFilters,
  }
}

export function useDefiPositionsFilters({
  filterTokenOptions,
  filterChainOptions,
}: {
  filterTokenOptions: Token[]
  filterChainOptions: number[]
}) {
  const supportedTokenSymbolsSet = new Set(
    filterTokenOptions.map((token) => token.symbol)
  )
  const supportedChainIdsSet = new Set(filterChainOptions)

  const [selectedTokenSymbols, setSelectedTokenSymbols] = useState<string[]>(
    Array.from(supportedTokenSymbolsSet)
  )
  const [selectedChainIds, setSelectedChainIds] = useState<number[]>(
    Array.from(supportedChainIdsSet)
  )
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [hideZeroBalances, setHideZeroBalances] = useState<boolean>(false)

  const filters: EarnPositionFilters = {
    chainIds: selectedChainIds,
    hideZeroBalances,
    includeTokenSymbols: selectedTokenSymbols,
    searchTerm,
  }

  const setChainFilter = setSelectedChainIds
  const setSelectedTokens = setSelectedTokenSymbols

  const setSearchTermFilter = useCallback((term: string) => {
    setSearchTerm(term)
  }, [])

  const clearSearchTerm = useCallback(() => {
    setSearchTerm('')
  }, [])

  const supportedChainIdsArray = Array.from(supportedChainIdsSet)
  const supportedTokenSymbolsArray = Array.from(supportedTokenSymbolsSet)

  const clearFilters = useCallback(() => {
    setSelectedChainIds(supportedChainIdsArray)
    setSelectedTokenSymbols(supportedTokenSymbolsArray)
    setSearchTerm('')
  }, [supportedChainIdsArray, supportedTokenSymbolsArray])

  let activeFilters = 0
  if (selectedTokenSymbols.length < supportedTokenSymbolsSet.size) {
    activeFilters++
  }
  if (selectedChainIds.length < supportedChainIdsSet.size) {
    activeFilters++
  }
  if (searchTerm) {
    activeFilters++
  }

  return {
    filters,
    setSelectedTokens,
    setChainFilter,
    setSearchTermFilter,
    clearSearchTerm,
    clearFilters,
    setHideZeroBalances,
    activeFilters,
  }
}

export function usePortfolioCampaignsOrderBy(): {
  orderBy: EarnCampaignsOrderBy
  direction: SortingDirection
  setOrderBy: Dispatch<SetStateAction<EarnCampaignsOrderBy>>
  setDirection: Dispatch<SetStateAction<SortingDirection>>
} {
  const { account } = useSwellWeb3()

  const [orderBy, setOrderBy] = useState<EarnCampaignsOrderBy>(
    account ? EarnCampaignsOrderBy.claimable : EarnCampaignsOrderBy.unspecified
  )
  const [direction, setDirection] = useState<SortingDirection>(
    SortingDirection.desc
  )

  const accRef = useRef<string | undefined>(account)
  useEffect(() => {
    const justSignedIn = accRef.current === undefined && account !== undefined
    const justSignedOut = accRef.current !== undefined && account === undefined

    if (justSignedIn) {
      setOrderBy(EarnCampaignsOrderBy.claimable)
    } else if (justSignedOut) {
      setOrderBy(EarnCampaignsOrderBy.unspecified)
    }

    accRef.current = account
  }, [account])

  return {
    orderBy,
    direction,
    setOrderBy,
    setDirection,
  }
}

export function usePortfolioPositionsOrderBy(): {
  orderBy: EarnPositionsOrderBy
  direction: SortingDirection
  setOrderBy: Dispatch<SetStateAction<EarnPositionsOrderBy>>
  setDirection: Dispatch<SetStateAction<SortingDirection>>
} {
  const { account } = useSwellWeb3()

  const [orderBy, setOrderBy] = useState<EarnPositionsOrderBy>(
    account ? EarnPositionsOrderBy.balance : EarnPositionsOrderBy.apr
  )
  const [direction, setDirection] = useState<SortingDirection>(
    SortingDirection.desc
  )

  const accRef = useRef<string | undefined>(account)
  useEffect(() => {
    const justSignedIn = accRef.current === undefined && account !== undefined
    const justSignedOut = accRef.current !== undefined && account === undefined

    if (justSignedIn) {
      setOrderBy(EarnPositionsOrderBy.balance)
    } else if (justSignedOut) {
      setOrderBy(EarnPositionsOrderBy.apr)
    }

    accRef.current = account
  }, [account])

  return {
    orderBy,
    direction,
    setOrderBy,
    setDirection,
  }
}
