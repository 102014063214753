// @generated by protoc-gen-es v1.3.3 with parameter "target=ts"
// @generated from file swell/v3/merklwallet.proto (package swell.v3, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message swell.v3.MerklWalletWSwellAccumulatedRequest
 */
export class MerklWalletWSwellAccumulatedRequest extends Message<MerklWalletWSwellAccumulatedRequest> {
  /**
   * @generated from field: string wallet_address = 1;
   */
  walletAddress = "";

  constructor(data?: PartialMessage<MerklWalletWSwellAccumulatedRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.MerklWalletWSwellAccumulatedRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "wallet_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MerklWalletWSwellAccumulatedRequest {
    return new MerklWalletWSwellAccumulatedRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MerklWalletWSwellAccumulatedRequest {
    return new MerklWalletWSwellAccumulatedRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MerklWalletWSwellAccumulatedRequest {
    return new MerklWalletWSwellAccumulatedRequest().fromJsonString(jsonString, options);
  }

  static equals(a: MerklWalletWSwellAccumulatedRequest | PlainMessage<MerklWalletWSwellAccumulatedRequest> | undefined, b: MerklWalletWSwellAccumulatedRequest | PlainMessage<MerklWalletWSwellAccumulatedRequest> | undefined): boolean {
    return proto3.util.equals(MerklWalletWSwellAccumulatedRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.MerklWalletWSwellAccumulatedResponse
 */
export class MerklWalletWSwellAccumulatedResponse extends Message<MerklWalletWSwellAccumulatedResponse> {
  /**
   * @generated from field: string accumulated_wei = 1;
   */
  accumulatedWei = "";

  /**
   * @generated from field: string unclaimed_wei = 2;
   */
  unclaimedWei = "";

  /**
   * @generated from field: string pending_wei = 3;
   */
  pendingWei = "";

  /**
   * @generated from field: repeated string proofs_hex = 4;
   */
  proofsHex: string[] = [];

  constructor(data?: PartialMessage<MerklWalletWSwellAccumulatedResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.MerklWalletWSwellAccumulatedResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "accumulated_wei", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "unclaimed_wei", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "pending_wei", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "proofs_hex", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MerklWalletWSwellAccumulatedResponse {
    return new MerklWalletWSwellAccumulatedResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MerklWalletWSwellAccumulatedResponse {
    return new MerklWalletWSwellAccumulatedResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MerklWalletWSwellAccumulatedResponse {
    return new MerklWalletWSwellAccumulatedResponse().fromJsonString(jsonString, options);
  }

  static equals(a: MerklWalletWSwellAccumulatedResponse | PlainMessage<MerklWalletWSwellAccumulatedResponse> | undefined, b: MerklWalletWSwellAccumulatedResponse | PlainMessage<MerklWalletWSwellAccumulatedResponse> | undefined): boolean {
    return proto3.util.equals(MerklWalletWSwellAccumulatedResponse, a, b);
  }
}

