import { useWavedropsSettings } from '../deployments/hooks/useWavedropsSettings'
import { WavedropsContext } from './context'
import { useSwellWeb3 } from '@/swell-web3/core'
import {
  useMerkleDropContract,
  useMerkleDropContractL2,
  useReadonlyMulticallL2,
} from '@/hooks/useContract'
import { useMemo } from 'react'
import { CumulativeMerkleDrop__factory, Multicall3 } from '@/abis/types'
import { fetchWavedrops } from './fetcher'
import { StaticWavedropServiceV2 } from '@/services/StaticWavedropV2'
import {
  MERKLE_DROP_SWELL_WAVEDROPS_MAINNET,
  MERKLE_DROP_SWELL_WAVEDROPS_SWELLCHAIN,
} from '@/constants/merkleDrops'

const OLD_L1_MERKLE_DROP = MERKLE_DROP_SWELL_WAVEDROPS_MAINNET
const MERKLE_DROP = MERKLE_DROP_SWELL_WAVEDROPS_SWELLCHAIN

export function useWavedropsApiImpl(): WavedropsContext {
  const { account: maybeAccount } = useSwellWeb3()
  const { wavedropServiceURL } = useWavedropsSettings()
  const merkleDrop = useMerkleDropContractL2(MERKLE_DROP.address)!
  const oldMerkleDrop = useMerkleDropContract(OLD_L1_MERKLE_DROP.address)!

  const account = maybeAccount!

  const wavedropSvc = useMemo(
    () => new StaticWavedropServiceV2({ baseURL: wavedropServiceURL }),
    [wavedropServiceURL]
  )
  const multicall = useReadonlyMulticallL2()!

  return {
    currentWaveNumber: 2,
    read: {
      wavedropContractState: async () => {
        const calls: Multicall3.Call3Struct[] = []
        calls.push({
          target: MERKLE_DROP.address,
          allowFailure: false,
          callData:
            CumulativeMerkleDrop__factory.createInterface().encodeFunctionData(
              'merkleRoot'
            ),
        })
        calls.push({
          target: MERKLE_DROP.address,
          allowFailure: false,
          callData:
            CumulativeMerkleDrop__factory.createInterface().encodeFunctionData(
              'claimIsOpen'
            ),
        })

        const [merkleRootAirdropResult, claimIsOpenAirdropResult] =
          await multicall.callStatic.tryAggregate(true, calls)

        const merkleRoot =
          CumulativeMerkleDrop__factory.createInterface().decodeFunctionResult(
            'merkleRoot',
            merkleRootAirdropResult.returnData
          )[0]
        const claimIsOpen =
          CumulativeMerkleDrop__factory.createInterface().decodeFunctionResult(
            'claimIsOpen',
            claimIsOpenAirdropResult.returnData
          )[0]

        return {
          merkleDrop: {
            claimIsOpen: claimIsOpen,
            merkleRoot: merkleRoot,
          },
          staking: { exists: false },
        }
      },
      wavedropUser: async () => {
        const cumulativeClaimedW1P = oldMerkleDrop.cumulativeClaimed(account)
        const cumulativeClaimedW2P = merkleDrop.cumulativeClaimed(account)
        const [cumulativeClaimedW1, cumulativeClaimedW2] = await Promise.all([
          cumulativeClaimedW1P,
          cumulativeClaimedW2P,
        ])
        return { cumulativeClaimedW1, cumulativeClaimed: cumulativeClaimedW2 }
      },
      wavedrops: async () => {
        return fetchWavedrops({ wavedropSvc, account })
      },
      checkClaimWavedrop: async ({ cumulativeAmount, merkleProof }) => {
        return merkleDrop.verifyProof(merkleProof, cumulativeAmount, account)
      },
    },
    write: {
      claimWavedrop: async (
        { amountToLock, cumulativeAmount, merkleProof },
        opts
      ) => {
        return merkleDrop.claimAndLock(
          cumulativeAmount,
          amountToLock,
          merkleProof,
          opts
        )
      },
      claimWavedropEstimateGas: async ({
        amountToLock,
        cumulativeAmount,
        merkleProof,
      }) => {
        return merkleDrop.estimateGas.claimAndLock(
          cumulativeAmount,
          amountToLock,
          merkleProof
        )
      },
    },
  }
}
