import { useCallback, useMemo } from 'react'
import { useSwellWeb3 } from '@swell-web3/core'
import { useDeploymentSetConfig } from '@/state/deployments/hooks'
import useDebounce from './useDebounce'
import { HOST_ENV, HostEnv } from '@/configuration/hostEnv'
import { SupportedChainId } from '@/constants/chains'
import { validateCurrentChainId } from '@/util/chains'
import { useLocation } from 'react-router'
import { ethers } from 'ethers'
import { useAddSwellchainToWallet } from './useAddChainToWallet'

const useSwitchChain = () => {
  return useCallback(async (connector: any, targetChainId: number) => {
    await connector.switchChain(targetChainId)
  }, [])
}

function useSwitchToDeploymentChain() {
  const { chainId: targetChainId } = useDeploymentSetConfig()
  const { connector } = useSwellWeb3()

  const switchChain = useSwitchChain()

  return useCallback(async () => {
    if (!connector) return
    try {
      await switchChain(connector, targetChainId)
    } catch (error: any) {
      console.error('Failed to switch networks', error)
    }
  }, [connector, switchChain, targetChainId])
}

function useSwitchToL2DeploymentChain() {
  const { l2ChainId: targetChainId } = useDeploymentSetConfig()
  const { connector } = useSwellWeb3()

  const switchChain = useSwitchChain()
  const addSwellchain = useAddSwellchainToWallet()

  return useCallback(async () => {
    if (!connector) return
    try {
      await switchChain(connector, targetChainId)
    } catch (error: any) {
      console.error('Failed to switch networks', error)
      try {
        addSwellchain()
      } catch (error: any) {
        console.error('Failed to add Swellchain to wallet', error)
      }
    }
  }, [addSwellchain, connector, switchChain, targetChainId])
}

function useSwitchToTestnetChain() {
  const { testnets } = useDeploymentSetConfig()
  const { connector } = useSwellWeb3()
  const testnetChains = useMemo(
    () => new Set(testnets.map(({ chainId }) => chainId)),
    [testnets]
  )

  const switchChain = useSwitchChain()

  return useCallback(
    async (chainId: SupportedChainId) => {
      if (!connector) return
      if (!testnetChains.has(chainId)) {
        throw new Error('Invalid testnet chainId')
      }
      try {
        await switchChain(connector, chainId)
      } catch (error: any) {
        console.error('Failed to switch networks', error)
      }
    },
    [connector, switchChain, testnetChains]
  )
}

function useSwitchToTestnetL2Chain() {
  const { testnets } = useDeploymentSetConfig()
  const { connector } = useSwellWeb3()
  const testnetChains = useMemo(
    () => new Set(testnets.map(({ l2ChainId }) => l2ChainId)),
    [testnets]
  )

  const switchChain = useSwitchChain()

  return useCallback(
    async (chainId: SupportedChainId) => {
      if (!connector) return
      if (!testnetChains.has(chainId)) {
        throw new Error('Invalid testnet chainId')
      }
      try {
        await switchChain(connector, chainId)
      } catch (error: any) {
        console.error('Failed to switch networks', error)
      }
    },
    [connector, switchChain, testnetChains]
  )
}

const allowTestnet = HOST_ENV !== HostEnv.PRODUCTION
/**
 * Compares the current chainId to the deployment chainId
 * Exposes a callback to switch chains to the deployment chainId (switchToDeploymentChain)
 */
export const useChainDetection = () => {
  const { pathname } = useLocation()

  const { chainId } = useSwellWeb3()
  const {
    chainId: deploymentChainId,
    l2ChainId: l2DeploymentChainId,
    testnets,
  } = useDeploymentSetConfig()

  const testnetChainIds = testnets.map(
    ({ chainId }) => chainId as SupportedChainId
  )
  const testnetL2ChainIds = testnets.map(
    ({ l2ChainId }) => l2ChainId as SupportedChainId
  )
  const debouncedChainId = useDebounce(chainId, 400)

  const {
    ok: chainOk,
    isTestnetChain,
    isDeploymentChain,
    allowL2,
    isL2DeploymentChain,
    isTestnetL2Chain,
  } = validateCurrentChainId({
    allowTestnet,
    chainId,
    deploymentChainId,
    testnetChainIds,
    l2DeploymentChainId,
    testnetL2ChainIds,
    pathname,
  })
  const { ok: debouncedChainOk } = validateCurrentChainId({
    allowTestnet,
    chainId: debouncedChainId,
    deploymentChainId,
    testnetChainIds,
    l2DeploymentChainId,
    testnetL2ChainIds,
    pathname,
  })
  const isWrongChainId = !chainOk
  const isWrongChainIdDebounced = !debouncedChainOk

  const switchToDeploymentChain = useSwitchToDeploymentChain()
  const switchToL2DeploymentChain = useSwitchToL2DeploymentChain()
  let switchToTestnetChain = useSwitchToTestnetChain()
  let switchToTestnetL2Chain = useSwitchToTestnetL2Chain()
  if (!allowTestnet) {
    switchToTestnetChain = async () => {
      throw new Error('testnet not allowed')
    }
    switchToTestnetL2Chain = async () => {
      throw new Error('testnet not allowed')
    }
  }

  const { readOnlyProvider, readOnlyProviderL2 } = useSwellWeb3()

  let userProviderIfAvailable: ethers.providers.JsonRpcProvider =
    readOnlyProvider
  let userProviderIfAvailableL2: ethers.providers.JsonRpcProvider =
    readOnlyProviderL2

  if (chainId === deploymentChainId) {
    userProviderIfAvailable = useSwellWeb3().provider
  }
  if (chainId === l2DeploymentChainId) {
    userProviderIfAvailableL2 = useSwellWeb3().provider
  }

  return {
    l2DeploymentChainId,
    chainId,
    switchToDeploymentChain,
    switchToTestnetChain,
    deploymentChainId,
    testnetChainIds,
    isWrongChainId,
    /**
     * chainId might change rapidly in some circumstances, depending on the wallet provider
     *
     * A debounced chainId is provided to use with side effects which depend on the chainId
     *  being wrong.
     */
    isWrongChainIdDebounced,
    allowTestnet,
    isTestnetChain,
    isDeploymentChain,
    isL2DeploymentChain,
    isTestnetL2Chain,
    allowL2,
    switchToL2DeploymentChain,
    switchToTestnetL2Chain,
    userProviderIfAvailable,
    userProviderIfAvailableL2,
  }
}

export default useChainDetection
