import { Box } from '@/swell-ui/Box'
import styled from 'styled-components'
import { ColoredHeadingTypography } from '@/swell-ui/Typography/typographyPresets'
import { FlexRow } from '@/swell-ui/FlexRow'
import { AsyncDiv } from '@/swell-ui/AsyncDiv'
import { Token } from '@/types/tokens'
import { useMediaQuery } from '@mui/material'
import { Divider } from '@/swell-ui/Divider'
import { NucleusHoldingsSummary } from './types'
import { Tooltip } from '@/swell-ui/Tooltip'
import { NucleusTokens, NucleusVault } from '@/types/nucleus'
import { css } from 'styled-components'
import {
  TOKEN_LIST_FBTC,
  TOKEN_LIST_LORENZO_STBTC,
  TOKEN_LIST_PUMPBTC,
  TOKEN_LIST_SOLV_BTC,
} from '@/constants/tokens'
import babylonLogo from '@/assets/images/babylon_logo-160x160.png'
import { TokenLogoV2 } from '../TokenLogoV2'
import useChainDetection from '@/hooks/useChainDetection'

const ICON_SIZE = 32
const TOKEN_ICON_SIZE = 24

export function NucleusHoldings({
  vault,
  supportedTokens,
  holdingsSummary,
  showBtcPartnerPoints,
}: {
  vault: NucleusVault
  supportedTokens: NucleusTokens | undefined
  holdingsSummary: NucleusHoldingsSummary
  showBtcPartnerPoints?: boolean
}) {
  const { vaultToken: vaultTokenMainnet, vaultTokenL2, depositAssets } = vault
  const { isL2DeploymentChain } = useChainDetection()
  let vaultToken = vaultTokenMainnet
  if (isL2DeploymentChain) {
    vaultToken = vaultTokenL2
  }

  const isMobile = useMediaQuery('(max-width: 942px)')

  const vaultTokenSection = (
    <FlexRow
      direction="column"
      gap="12"
      justify="start"
      align={isMobile ? 'start' : 'center'}
      style={{ flexGrow: 0, flexShrink: 0, width: '220px' }}
    >
      <ColoredHeadingTypography nobreak>
        Current Holdings
      </ColoredHeadingTypography>
      <FlexRow gap="12" justify={isMobile ? 'start' : 'center'}>
        <AsyncDiv loading={!holdingsSummary.vaultToken}>
          {() => (
            <FlexRow gap="12">
              <TokenLogoV2 token={vaultToken} size={ICON_SIZE} />
              <span className="value">{holdingsSummary.vaultToken}</span>
            </FlexRow>
          )}
        </AsyncDiv>
      </FlexRow>
    </FlexRow>
  )

  const assetsSection = (
    <AssetsSection
      direction="column"
      gap="12"
      justify="start"
      align="start"
      width="100%"
      height="100%"
    >
      <ColoredHeadingTypography nobreak>
        Deposit assets
      </ColoredHeadingTypography>
      <div className="nh-deposit-assets">
        <FlexRow gap="6">
          {depositAssets.map((token, i) => {
            let supported = true
            if (supportedTokens) {
              supported = !!(
                supportedTokens.deposit?.[token.chainId]?.[token.address]
                  ?.isSupported ?? false
              )
            }

            const tooltipTitle = supported
              ? token.name
              : `${token.name} (coming soon)`

            return (
              <div
                style={{
                  height: '24px',
                  width: '24px',
                  overflow: 'hidden',
                  flex: '0 0 auto',
                }}
              >
                <Tooltip key={i} title={tooltipTitle}>
                  <DepositToken token={token} supported={supported} />
                </Tooltip>
              </div>
            )
          })}
        </FlexRow>
      </div>
    </AssetsSection>
  )

  const btcPartnerPointsSection = (
    <FlexRow gap="12" justify="start" direction="column" align="start">
      <ColoredHeadingTypography nobreak>
        BTC Partner Points (1x)
      </ColoredHeadingTypography>
      <BtcPartnerPointsLogos />
    </FlexRow>
  )

  const Layout = showBtcPartnerPoints ? StyledBoxBtc : BaseLayout

  return (
    <Layout padding="24px">
      <div className="nh-vault">{vaultTokenSection}</div>
      <div className="nh-div h">
        <HDivider />
      </div>
      <div className="nh-div v">
        <VDivider />
      </div>
      <div className="nh-assets">{assetsSection}</div>
      {showBtcPartnerPoints && (
        <>
          <div className="nh-div2 h">
            <HDivider />
          </div>
          <div className="nh-div2 v">
            <VDivider />
          </div>
          <div className="nh-btc-partner-pts">{btcPartnerPointsSection}</div>
        </>
      )}
    </Layout>
  )
}

const HDivider = styled(Divider)`
  background-color: ${({ theme }) => theme.divider.lightColor};
`

const VDivider = styled.div`
  flex: 1 1 auto;
  width: 100%;

  position: relative;
  :before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 1px;
    height: 100%;
    transform: translateX(-50%);
    background-color: ${({ theme }) => theme.divider.lightColor};
  }
`

const AssetsSection = styled(FlexRow)`
  .nh-deposit-assets {
    align-self: stretch;

    position: relative;
    > div {
      position: absolute;
      top: 50%;
      left: 0;
      height: ${TOKEN_ICON_SIZE}px;
      > * {
        height: ${TOKEN_ICON_SIZE}px;
        img {
          border-radius: 50%;
        }
      }
      transform: translateY(-50%);
    }
    overflow: auto;
    flex: 1 1 auto;

    ${({ theme }) =>
      theme.scrollbar &&
      css`
        padding-right: 24px;

        &::-webkit-scrollbar {
          height: 2px;
          width: 0px;
        }

        &::-webkit-scrollbar-track {
          background: ${theme.scrollbar.trackBg};
          border-radius: ${theme.scrollbar.borderRadius};
        }

        &::-webkit-scrollbar-thumb {
          background: ${theme.scrollbar.thumbBg};
          border-radius: ${theme.scrollbar.borderRadius};
        }
      `}
  }
`

const mobileLayout = css`
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto;
  grid-template-areas: 'vault' 'divider' 'assets';

  hr {
    margin: 0;
  }

  .nh-div.v {
    display: none;
    align-self: stretch;
    width: 1px;
    margin-left: 24px;
    margin-right: 24px;
  }
  .nh-div.h {
    display: block;
    height: 1px;
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .nh-assets {
    .nh-deposit-assets {
      min-height: 32px;
    }
  }
`
const tabletLayout = css`
  grid-template-columns: auto auto 1fr;
  grid-template-rows: 68.18px;
  grid-template-areas: 'vault divider assets';

  .nh-div.v {
    display: flex;
  }
  .nh-div.h {
    display: none;
  }
`
const BaseLayout = styled(Box)`
  display: grid;
  ${mobileLayout}
  @media (min-width: 942px) {
    ${tabletLayout}
  }
  .nh-vault {
    grid-area: vault;
  }
  .nh-div {
    grid-area: divider;
  }
  .nh-assets {
    grid-area: assets;
  }

  .center-become-start-mob {
    align-self: start;
    @media (min-width: 942px) {
      align-self: center;
    }
  }

  ${ColoredHeadingTypography} {
    svg {
      position: relative;
      top: 1px;

      path {
        stroke: currentColor;
      }
    }
  }

  span.value {
    color: var(--Swell-White-50, #fff);
    /* Headlines/H6 */
    font-family: Inter;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 33.8px */
    letter-spacing: -0.78px;
  }

  [aria-busy='false'] {
    min-width: unset;
    width: min-content;
  }

  [aria-busy='true'] {
    height: 33.8px;
  }

  .nh-deposit-token {
    width: ${TOKEN_ICON_SIZE}px;
    height: ${TOKEN_ICON_SIZE}px;
    border-radius: 50%;
    &.unsupported {
      opacity: 0.35;
    }
  }
`

const mobileLayoutBtc = css`
  grid-template-rows: auto auto auto auto auto;
  grid-template-areas: 'vault' 'divider' 'assets' 'divider2' 'btcPartnerPoints';

  .nh-div2.v {
    display: none;
    align-self: stretch;
    width: 1px;
    margin-left: 12px;
    margin-right: 24px;
  }
  .nh-div2.h {
    display: block;
    height: 1px;
    margin-top: 24px;
    margin-bottom: 24px;
  }
`
const tabletLayoutBtc = css`
  grid-template-columns: auto auto 1fr auto auto;
  grid-template-rows: 68.18px;
  grid-template-areas: 'vault divider assets divider2 btcPartnerPoints';

  .nh-div2.v {
    display: flex;
  }
  .nh-div2.h {
    display: none;
  }
`

const StyledBoxBtc = styled(BaseLayout)`
  .nh-div2 {
    grid-area: divider2;
  }
  .nh-btc-partner-pts {
    grid-area: btcPartnerPoints;
  }
  ${mobileLayoutBtc}
  @media (min-width: 942px) {
    ${tabletLayoutBtc}
  }
`

function DepositToken({
  token,
  supported,
}: {
  token: Token
  supported: boolean
}) {
  let className = 'nh-deposit-token'
  if (!supported) {
    className += ' unsupported'
  }
  return (
    <TokenLogoV2 token={token} size={TOKEN_ICON_SIZE} className={className} />
  )
}

function BtcPartnerPointsLogos() {
  const size = 22

  return (
    <FlexRow height="33.8px" gap="12">
      <img src={TOKEN_LIST_PUMPBTC.logoURI} width={size} height={size} />
      <img src={TOKEN_LIST_LORENZO_STBTC.logoURI} width={size} height={size} />
      <img src={TOKEN_LIST_FBTC.logoURI} width={size} height={size} />
      <img src={TOKEN_LIST_SOLV_BTC.logoURI} width={size} height={size} />
      <img src={babylonLogo} width={size} height={size} />
    </FlexRow>
  )
}
