export type DisplayTimeOpts = {
  preset: 'time-with-seconds'
}

/**
 * Displays time in a some readable format
 *
 * @param millis Time in milliseconds
 * @param param1 Options @see DisplayTimeOpts
 * @returns A formatted string displaying the provided time
 */
export const displayTime = (
  millis: number,
  { preset }: DisplayTimeOpts = { preset: 'time-with-seconds' }
): string => {
  switch (preset) {
    case 'time-with-seconds': {
      const formatter = new Intl.DateTimeFormat(navigator.language, {
        dateStyle: 'short',
        timeStyle: 'medium',
      })
      return formatter
        .format(new Date(millis))
        .replaceAll('/', '-')
        .replaceAll(',', '')
    }
    default:
      throw new Error(`invalid preset: ${preset}`)
  }
}

export function formatDelay(delayMs: number) {
  const delayUnix = Math.floor(delayMs / 1000)
  if (delayUnix === 0) {
    return 'None'
  }

  const oneHour = 60 * 60
  const oneDay = oneHour * 24
  const hoursThreshold = oneHour
  const daysThreshold = oneDay

  if (delayUnix >= daysThreshold) {
    const days = Math.round(delayUnix / oneDay)
    if (days === 1) {
      return `${days} day`
    }
    return `${days} days`
  }

  if (delayUnix >= hoursThreshold) {
    const hours = Math.round(delayUnix / oneHour)
    if (hours === 1) {
      return `${hours} hour`
    }
    if (hours === 24) {
      return '1 day'
    }

    return `${hours} hours`
  }

  const seconds = delayUnix
  if (seconds === 1) {
    return `${seconds} second`
  }
  return `${seconds} seconds`
}

export function formatTimeAgoAbbrev({
  nowMs,
  timestampMs,
}: {
  nowMs: number
  timestampMs: number
}) {
  const timeAgoMs = nowMs - timestampMs
  if (timeAgoMs < 1000) {
    return 'just now'
  }

  const seconds = Math.floor(timeAgoMs / 1000)
  if (seconds < 60) {
    if (seconds === 1) {
      return '1 sec ago'
    }
    return `${seconds} secs ago`
  }

  const minutes = Math.floor(seconds / 60)
  if (minutes < 60) {
    if (minutes === 1) {
      return '1 min ago'
    }
    return `${minutes} mins ago`
  }

  const hours = Math.floor(minutes / 60)
  if (hours < 24) {
    if (hours === 1) {
      return '1 hr ago'
    }
    return `${hours} hrs ago`
  }

  const days = Math.floor(hours / 24)
  if (days === 1) {
    return '1 day ago'
  }
  return `${days} days ago`
}
