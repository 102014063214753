import React, { useState } from 'react'
import styled from 'styled-components'
import { Accordion } from '@swell-ui/Accordion'
import { FlexRow } from '@swell-ui/FlexRow'
import { MinusIcon } from '@swell-ui/icons/MinusIcon'
import { PlusIcon } from '@swell-ui/icons/PlusIcon'
import { Typography } from '@swell-ui/Typography'
import { Link } from 'react-router-dom'
import { ROUTES } from '@/constants/routes'

const Heading = styled(Typography)`
  margin-top: 0;
`

const SectionBody = styled.div`
  color: #b0b0b0;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 25.6px */
  letter-spacing: -0.48px;
`

interface Question {
  question: string
  answer: string | React.ReactNode
}

const FaqOverflowContainer = styled.div`
  position: relative;
  width: 100%;
  overflow: visible;
`

const FaqContainerFlex = styled(FlexRow)`
  li {
    margin: 4px 0;
  }
`

const StyledAccordion = styled(Accordion)`
  width: 100%;
  min-height: 52px;

  .MuiAccordionSummary-content.Mui-expanded {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 21px;
  }

  .MuiAccordionDetails-root {
    margin-bottom: 0;
  }
`

const QUESTIONS: Question[] = [
  {
    question: '1. What did I earn in the Pre-Launch?',
    answer: (
      <>
        <p>
          Rewards stop flowing to the Pre-Launch on December 19th 2024 at
          11:59pm UTC.
        </p>
        <p>
          During the Pre-Launch, the following rewards were earned by
          depositors:
        </p>
        <h4>Ecosystem Points</h4>
        <p>
          The following tokens, representing 25% of the total Ecosystem Rewards,
          were earned by Ecosystem Point holders during the Pre-Launch:
        </p>
        <ul>
          <li>5,000,000 ION (~0.5%)*</li>
          <li>7,500,000 AMBIENT (~0.75%)*</li>
          <li>5,000,000 BUBBLY (~0.5%)*</li>
          <li>7,500,000 JIGSAW (~0.75%)*</li>
          <li>5,000,000 TEMPEST( ~0.5%)*</li>
          <li>5,000,000 NEPTUNE (~0.5%)*</li>
          <li>
            10,000,000 SWELL (0.1%) (to be claimed by Ecosystem Points holders
            with Wavedrop 2).
          </li>
        </ul>
        <p>*Where total supply is unknown it is assumed to be 1B.</p>
        <p>
          Pre-Launch depositors also earned token allocations from Brahma,
          Versatus, Ditto, Drosera, and other yet-to-be-announced protocols.
          These remaining token allocations will be revealed before the end of
          Wavedrop 3. Tokens from ecosystem dApps will be claimable at the
          airdrops of the respective protocols.
        </p>
        <p>
          The remaining 75% of Ecosystem Rewards will be distributed through
          Wavedrops in proportion with $SWELL for using the respective dApps on
          Swellchain.
        </p>
        <h4>Eigenlayer Points </h4>
        <p>
          1M Points were promised to depositors in the first 4 weeks of the
          Swell L2 Pre-Launch who stayed until the L2 launch.
        </p>
        <p>
          If you're eligible, the amount of EigenLayer Tokens you receive will
          be derived from the amount that you deposited in the pre-launch
          deposit contract, and the duration of your deposit, as calculated
          based on a time-weighted average.
        </p>
        <p>
          The Eigenlayer Points were purchased by Swell during Eigenlayer Season
          1, in which points were converted to tokens at a ratio of ~42.8 points
          per $EIGEN.
        </p>
        <p>
          Your EIGEN will be claimable via{' '}
          <Link to={ROUTES.EarnPortfolio}>the Portfolio</Link> on the date of
          the Wavedrop 2 distribution, currently expected around January 10th.
        </p>
      </>
    ),
  },
  {
    question: '2. Are mainnet positions still rewarded?',
    answer: (
      <>
        <p>
          In addition to rewarding activity on Swellchain, Swell assets used in
          selected protocols on mainnet will still be rewarded with SWELL. These
          include:
        </p>
        <ul>
          <li>
            <b>PancakeSwap</b>: rswETH/ETH, swETH/ETH
          </li>
          <li>
            <b>Ambient/Tempest</b>: rswETH/ETH, rswETH/SWELL
          </li>
          <li>
            <b>Morpho</b>: rswETH deposit, ETH borrow
          </li>
          <li>
            <b>Euler</b>: rswETH deposit, ETH borrow
          </li>
          <li>
            <b>Pendle</b>: rswETH Dec 26, swETH Dec 26, rswETH June 26 (Swell
            will continue to vote with its EQB to optimize returns for Pendle
            LPs).
          </li>
          <li>
            <b>Derive</b>: rswETHC
          </li>
          <li>
            <b>Karak</b>: rswETH
          </li>
          <li>
            <b>earnETH</b>
          </li>
          <li>
            <b>earnBTC</b>
          </li>
          <li>
            <b>Eigenlayer</b>: swETH
          </li>
        </ul>
      </>
    ),
  },
  {
    question: '3. Can I claim SWELL earned in Wavedrops early?',
    answer: (
      <>
        <p>
          SWELL earned in Ecosystem Wavedrops is distributed daily through{' '}
          <a
            href="https://merkl.angle.money/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Merkl
          </a>{' '}
          in the form of locked wrapper wSWELL, which can be unlocked whenever
          you choose.*
        </p>
        <p>
          You can either wait until the end of the 10 week Wavedrop to get 100%
          of your SWELL, or claim early for a discounted amount.
        </p>
        <p>
          35% is unlocked immediately when you first claim wSWELL, and the
          remaining 65% unlocks linearly over the rest of the 10 weeks, reaching
          full unlock at maturity. You are free to redeem SWELL at any point
          during the unlock period, but any locked SWELL remaining will be
          forfeited and returned to the Ecosystem Allocation.
        </p>
        <p>
          For example, Alice provides 10,000 liquidity to Neptune on Swellchain
          on December 17th and earns 100 wSWELL per week as rewards, with the
          APY displaying on the Swell app. After 5 weeks, she decides to
          transfer 500 wSWELL to her wallet. At this point the clock starts
          ticking to unlock 500 SWELL. She can immediately redeem 175 SWELL if
          she wishes (35% of her expected 500 SWELL at maturity). If she does
          this, however, all remaining 325 SWELL will be returned to the Swell
          DAO. If she decides to wait the full 10 weeks she will be free to
          redeem a full 500 SWELL at maturity. She can also redeem anytime in
          between to get a proportional share of the unlocked SWELL.
        </p>
        <p>
          She can also keep withdrawing wSWELL to her wallet at any time she
          likes. Each transfer starts a separate unlock schedule. Note that
          wSWELL is therefore a non-fungible asset, even though it has an
          ERC20-like interface.
        </p>
        <p>*The unlocking of wSWELL will be enabled in early January 2025.</p>
      </>
    ),
  },
  {
    question: '4. How do I add Swellchain to my wallet?',
    answer: (
      <>
        <p>Swellchain is supported natively on Rabby and Zerion.</p>

        <p>
          For other wallets, you can add Swellchain address as a{' '}
          <a
            href="https://support.metamask.io/networks-and-sidechains/managing-networks/how-to-add-a-custom-network-rpc/"
            target="_blank"
            rel="noopener noreferrer"
          >
            custom network
          </a>{' '}
          using the following details:
        </p>

        <p>
          Chain Name: Swellchain
          <br />
          Chain ID: 1923
          <br />
          RPC URL: https://swell-mainnet.alt.technology
          <br />
          Block Explorer: https://explorer.swellnetwork.io
        </p>
      </>
    ),
  },
  {
    question: '5. How can I claim Wavedrop 1 on Swellchain?',
    answer: (
      <>
        <p>
          All unclaimed SWELL from Wavedrop 1 and the Voyage will be migrated to
          be claimed on Swellchain on the date of Wavedrop 2 distribution,
          currently expected before January 10th.
        </p>
        <p>
          Claims will temporarily close for around 48 hours before this as the
          migration takes place.
        </p>
      </>
    ),
  },
  {
    question:
      '6. Will the loyalty multipliers continue from the previous Wavedrops?',
    answer: (
      <>
        <p>
          Yes, however the multipliers will not be restored in Wavedrop 3
          (starting December 17th), and only from Wavedrop 4.{' '}
        </p>
      </>
    ),
  },
  {
    question: '7. How long do I have to move assets out of the Pre-Launch?',
    answer: (
      <>
        <p>There is no time limit.</p>
      </>
    ),
  },
]

function QuestionAccordion({ question }: { question: Question }) {
  const [expanded, setExpanded] = useState<boolean>(false)

  const handleChange = () => {
    setExpanded(!expanded)
  }

  const accordionConfig = {
    summary: (
      <FlexRow justify="space-between">
        <Typography variant="headline" size="h6" letterSpacing="small">
          {question.question}
        </Typography>
        {expanded && <MinusIcon />}
        {!expanded && <PlusIcon />}
      </FlexRow>
    ),
    details: <SectionBody>{question.answer}</SectionBody>,
  }

  return <StyledAccordion config={accordionConfig} onChange={handleChange} />
}

export function BridgeFaqSection() {
  return (
    <FaqOverflowContainer>
      <FaqContainerFlex direction="column" align="flex-start" gap="12">
        <span className="headlines-h5">FAQ</span>
        {QUESTIONS.map((question: Question) => {
          return (
            <QuestionAccordion key={question.question} question={question} />
          )
        })}
      </FaqContainerFlex>
    </FaqOverflowContainer>
  )
}
