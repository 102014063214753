import useSWR from 'swr'
import { useSwellWeb3 } from '@swell-web3/core'
import { useWeb3Call } from '@/hooks/useWeb3Call'
import {
  getApproveGasEstimate,
  getStakingUpperGasEstimate,
} from '@/constants/gasEstimates'
import { useSwETHApi } from './context'

export function useSwETHTokens() {
  const { nativeCurrency, swETHToken, exitSwETHETH } = useSwETHApi()
  return { nativeCurrency, swETHToken, exitSwETHETH }
}

export function useSwETHUser() {
  const api = useSwETHApi()
  const { account } = useSwellWeb3()
  return useSWR(account ? ['swETHUser', account] : undefined, api.read.user)
}
export function useSwETHState() {
  const api = useSwETHApi()
  return useSWR('swETHState', api.read.state)
}
export function useSwETHStats() {
  const api = useSwETHApi()
  return useSWR('swETHStats', api.read.stats)
}
export function useSwETHWithdrawalsUser() {
  const api = useSwETHApi()
  const { account } = useSwellWeb3()
  return useSWR(
    account ? ['swETHWithdrawalUser', account] : undefined,
    api.read.withdrawalsUser
  )
}
export function useSwETHStakingPoolActivityCount() {
  const api = useSwETHApi()
  return useSWR(['sweth', 'staking-activity', 'count'], () =>
    api.read.stakingPoolCount()
  )
}
export function useSwETHStakingPoolActivityPage({ page }: { page: number }) {
  const api = useSwETHApi()
  return useSWR(['sweth', 'staking-activity', page], () =>
    api.read.stakingPoolActivity(page)
  )
}

// --
// mutations

export function useSwETHDeposit() {
  const api = useSwETHApi()

  return useWeb3Call({
    estimateGas: api.write.depositEstimateGas,
    fn: api.write.deposit,
    staticGasEstimate: () => getStakingUpperGasEstimate(),
    validate: async ({ nativeCurrencyAmount }) => {
      if (nativeCurrencyAmount.lte(0)) {
        return 'Amount must be greater than 0'
      }

      return null
    },
  })
}
export type SwETHDeposit = ReturnType<typeof useSwETHDeposit>

export function useApproveSwETHForWithdrawal() {
  const api = useSwETHApi()

  return useWeb3Call({
    estimateGas: api.write.approveSwETHForWithdrawalEstimateGas,
    fn: api.write.approveSwETHForWithdrawal,
    staticGasEstimate: () => getApproveGasEstimate(),
    validate: async ({ amount }) => {
      if (amount.lte(0)) {
        return 'Amount must be greater than 0'
      }
      return null
    },
  })
}
export type ApproveSwETHForWithdrawal = ReturnType<
  typeof useApproveSwETHForWithdrawal
>

export function useCreateWithdrawRequestSwETH() {
  const api = useSwETHApi()

  return useWeb3Call({
    estimateGas: api.write.createWithdrawRequestEstimateGas,
    fn: api.write.createWithdrawRequest,
    staticGasEstimate: () => getApproveGasEstimate(),
    validate: async ({ swETHAmount }) => {
      if (swETHAmount.lte(0)) {
        return 'Amount must be greater than 0'
      }

      return null
    },
  })
}
export type CreateWithdrawRequestSwETH = ReturnType<
  typeof useCreateWithdrawRequestSwETH
>

export function useFinalizeWithdrawalSwETH() {
  const api = useSwETHApi()

  return useWeb3Call({
    estimateGas: api.write.finalizeWithdrawalEstimateGas,
    fn: api.write.finalizeWithdrawal,
    staticGasEstimate: () => getApproveGasEstimate(),
    validate: async ({ requestId }) => {
      if (!requestId) {
        return 'No request id'
      }

      return null
    },
  })
}
export type FinalizeWithdrawalSwETH = ReturnType<
  typeof useFinalizeWithdrawalSwETH
>

export function useSwETHCalls() {
  return {
    deposit: useSwETHDeposit(),
    approveSwETHForWithdrawal: useApproveSwETHForWithdrawal(),
    createWithdrawRequest: useCreateWithdrawRequestSwETH(),
    finalizeWithdrawal: useFinalizeWithdrawalSwETH(),
  }
}
export type SwETHCalls = ReturnType<typeof useSwETHCalls>
