import { ChainId } from '@/constants/chains'
import { DeploymentSet } from '@/types/deployments'
import { BigNumber } from 'ethers'

function buildChains(rpcUrls: DeploymentSet['rpcUrl']) {
  const ethMainnet = {
    id: '0x1',
    token: 'ETH',
    label: 'Ethereum',
    rpcUrl: rpcUrls.mainnet,
  }

  const ethGoerli = {
    id: '0x5',
    token: 'GoerliETH',
    label: 'Goerli',
    rpcUrl: rpcUrls.goerli,
  }

  const swell = {
    id: BigNumber.from(ChainId.SWELLCHAIN).toHexString(),
    token: 'ETH',
    label: 'Swell',
    rpcUrl: rpcUrls.swell,
  }
  const swellTestnet = {
    id: BigNumber.from(ChainId.SWELL_TESTNET).toHexString(),
    token: 'ETH',
    label: 'Swell Testnet',
    rpcUrl: rpcUrls.swellTestnet,
  }
  const optimism = {
    id: BigNumber.from(ChainId.OPTIMISM).toHexString(),
    token: 'ETH',
    label: 'Optimism',
    rpcUrl: rpcUrls.optimism,
  }
  const sepolia = {
    id: BigNumber.from(ChainId.SEPOLIA).toHexString(),
    token: 'ETH',
    label: 'Sepolia',
    rpcUrl: rpcUrls.sepolia,
  }
  const arbitrum = {
    id: BigNumber.from(ChainId.ARBITRUM).toHexString(),
    token: 'ETH',
    label: 'Arbitrum',
    rpcUrl: rpcUrls.arbitrum,
  }

  return [
    ethMainnet,
    ethGoerli,
    swell,
    swellTestnet,
    optimism,
    sepolia,
    arbitrum,
  ]
}

export { buildChains }
