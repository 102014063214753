// @generated by protoc-gen-es v1.3.3 with parameter "target=ts"
// @generated from file swell/v3/nucleus.proto (package swell.v3, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";

/**
 * @generated from message swell.v3.NucleusServiceVaultStatsRequest
 */
export class NucleusServiceVaultStatsRequest extends Message<NucleusServiceVaultStatsRequest> {
  /**
   * @generated from field: string vault_token = 1;
   */
  vaultToken = "";

  constructor(data?: PartialMessage<NucleusServiceVaultStatsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.NucleusServiceVaultStatsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "vault_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NucleusServiceVaultStatsRequest {
    return new NucleusServiceVaultStatsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NucleusServiceVaultStatsRequest {
    return new NucleusServiceVaultStatsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NucleusServiceVaultStatsRequest {
    return new NucleusServiceVaultStatsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: NucleusServiceVaultStatsRequest | PlainMessage<NucleusServiceVaultStatsRequest> | undefined, b: NucleusServiceVaultStatsRequest | PlainMessage<NucleusServiceVaultStatsRequest> | undefined): boolean {
    return proto3.util.equals(NucleusServiceVaultStatsRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.NucleusPosition
 */
export class NucleusPosition extends Message<NucleusPosition> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: float allocation_percent = 2;
   */
  allocationPercent = 0;

  /**
   * @generated from field: repeated float apy_percent = 3;
   */
  apyPercent: number[] = [];

  /**
   * @generated from field: repeated string asset_logo_uris = 4;
   */
  assetLogoUris: string[] = [];

  /**
   * @generated from field: repeated string protocol_logo_uris = 5;
   */
  protocolLogoUris: string[] = [];

  /**
   * @generated from field: string protocol_name = 6;
   */
  protocolName = "";

  /**
   * @generated from field: uint32 chain_id = 7;
   */
  chainId = 0;

  /**
   * @generated from field: repeated float swell_apy_percent = 8;
   */
  swellApyPercent: number[] = [];

  constructor(data?: PartialMessage<NucleusPosition>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.NucleusPosition";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "allocation_percent", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 3, name: "apy_percent", kind: "scalar", T: 2 /* ScalarType.FLOAT */, repeated: true },
    { no: 4, name: "asset_logo_uris", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 5, name: "protocol_logo_uris", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 6, name: "protocol_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "chain_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 8, name: "swell_apy_percent", kind: "scalar", T: 2 /* ScalarType.FLOAT */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NucleusPosition {
    return new NucleusPosition().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NucleusPosition {
    return new NucleusPosition().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NucleusPosition {
    return new NucleusPosition().fromJsonString(jsonString, options);
  }

  static equals(a: NucleusPosition | PlainMessage<NucleusPosition> | undefined, b: NucleusPosition | PlainMessage<NucleusPosition> | undefined): boolean {
    return proto3.util.equals(NucleusPosition, a, b);
  }
}

/**
 * @generated from message swell.v3.NucleusPerformance
 */
export class NucleusPerformance extends Message<NucleusPerformance> {
  /**
   * @generated from field: int32 timestamp = 1;
   */
  timestamp = 0;

  /**
   * @generated from field: float apy_percent = 2;
   */
  apyPercent = 0;

  constructor(data?: PartialMessage<NucleusPerformance>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.NucleusPerformance";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "timestamp", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "apy_percent", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NucleusPerformance {
    return new NucleusPerformance().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NucleusPerformance {
    return new NucleusPerformance().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NucleusPerformance {
    return new NucleusPerformance().fromJsonString(jsonString, options);
  }

  static equals(a: NucleusPerformance | PlainMessage<NucleusPerformance> | undefined, b: NucleusPerformance | PlainMessage<NucleusPerformance> | undefined): boolean {
    return proto3.util.equals(NucleusPerformance, a, b);
  }
}

/**
 * @generated from message swell.v3.NucleusServiceVaultStatsResponse
 */
export class NucleusServiceVaultStatsResponse extends Message<NucleusServiceVaultStatsResponse> {
  /**
   * @generated from field: repeated float apy_percent = 1;
   */
  apyPercent: number[] = [];

  /**
   * @generated from field: int64 tvl_cents = 2;
   */
  tvlCents = protoInt64.zero;

  /**
   * @generated from field: repeated swell.v3.NucleusPosition positions = 3;
   */
  positions: NucleusPosition[] = [];

  /**
   * @generated from field: repeated swell.v3.NucleusPerformance performance = 4;
   */
  performance: NucleusPerformance[] = [];

  constructor(data?: PartialMessage<NucleusServiceVaultStatsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.NucleusServiceVaultStatsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "apy_percent", kind: "scalar", T: 2 /* ScalarType.FLOAT */, repeated: true },
    { no: 2, name: "tvl_cents", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "positions", kind: "message", T: NucleusPosition, repeated: true },
    { no: 4, name: "performance", kind: "message", T: NucleusPerformance, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NucleusServiceVaultStatsResponse {
    return new NucleusServiceVaultStatsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NucleusServiceVaultStatsResponse {
    return new NucleusServiceVaultStatsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NucleusServiceVaultStatsResponse {
    return new NucleusServiceVaultStatsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: NucleusServiceVaultStatsResponse | PlainMessage<NucleusServiceVaultStatsResponse> | undefined, b: NucleusServiceVaultStatsResponse | PlainMessage<NucleusServiceVaultStatsResponse> | undefined): boolean {
    return proto3.util.equals(NucleusServiceVaultStatsResponse, a, b);
  }
}

/**
 * @generated from message swell.v3.NucleusServiceGlobalStatsRequest
 */
export class NucleusServiceGlobalStatsRequest extends Message<NucleusServiceGlobalStatsRequest> {
  constructor(data?: PartialMessage<NucleusServiceGlobalStatsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.NucleusServiceGlobalStatsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NucleusServiceGlobalStatsRequest {
    return new NucleusServiceGlobalStatsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NucleusServiceGlobalStatsRequest {
    return new NucleusServiceGlobalStatsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NucleusServiceGlobalStatsRequest {
    return new NucleusServiceGlobalStatsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: NucleusServiceGlobalStatsRequest | PlainMessage<NucleusServiceGlobalStatsRequest> | undefined, b: NucleusServiceGlobalStatsRequest | PlainMessage<NucleusServiceGlobalStatsRequest> | undefined): boolean {
    return proto3.util.equals(NucleusServiceGlobalStatsRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.NucleusServiceGlobalStatsResponse
 */
export class NucleusServiceGlobalStatsResponse extends Message<NucleusServiceGlobalStatsResponse> {
  /**
   * @generated from field: int64 total_tvl_cents = 1;
   */
  totalTvlCents = protoInt64.zero;

  constructor(data?: PartialMessage<NucleusServiceGlobalStatsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.NucleusServiceGlobalStatsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "total_tvl_cents", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NucleusServiceGlobalStatsResponse {
    return new NucleusServiceGlobalStatsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NucleusServiceGlobalStatsResponse {
    return new NucleusServiceGlobalStatsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NucleusServiceGlobalStatsResponse {
    return new NucleusServiceGlobalStatsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: NucleusServiceGlobalStatsResponse | PlainMessage<NucleusServiceGlobalStatsResponse> | undefined, b: NucleusServiceGlobalStatsResponse | PlainMessage<NucleusServiceGlobalStatsResponse> | undefined): boolean {
    return proto3.util.equals(NucleusServiceGlobalStatsResponse, a, b);
  }
}

/**
 * @generated from message swell.v3.NucleusServiceVaultSolverFeeRecord
 */
export class NucleusServiceVaultSolverFeeRecord extends Message<NucleusServiceVaultSolverFeeRecord> {
  /**
   * @generated from field: string want_token_address = 1;
   */
  wantTokenAddress = "";

  /**
   * @generated from field: string low_fee_threshold_wei = 2;
   */
  lowFeeThresholdWei = "";

  /**
   * @generated from field: float low_fee_percent = 3;
   */
  lowFeePercent = 0;

  /**
   * @generated from field: float high_fee_percent = 4;
   */
  highFeePercent = 0;

  /**
   * @generated from field: uint32 want_token_chain_id = 5;
   */
  wantTokenChainId = 0;

  constructor(data?: PartialMessage<NucleusServiceVaultSolverFeeRecord>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.NucleusServiceVaultSolverFeeRecord";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "want_token_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "low_fee_threshold_wei", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "low_fee_percent", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 4, name: "high_fee_percent", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 5, name: "want_token_chain_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NucleusServiceVaultSolverFeeRecord {
    return new NucleusServiceVaultSolverFeeRecord().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NucleusServiceVaultSolverFeeRecord {
    return new NucleusServiceVaultSolverFeeRecord().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NucleusServiceVaultSolverFeeRecord {
    return new NucleusServiceVaultSolverFeeRecord().fromJsonString(jsonString, options);
  }

  static equals(a: NucleusServiceVaultSolverFeeRecord | PlainMessage<NucleusServiceVaultSolverFeeRecord> | undefined, b: NucleusServiceVaultSolverFeeRecord | PlainMessage<NucleusServiceVaultSolverFeeRecord> | undefined): boolean {
    return proto3.util.equals(NucleusServiceVaultSolverFeeRecord, a, b);
  }
}

/**
 * @generated from message swell.v3.NucleusServiceVaultStateRequest
 */
export class NucleusServiceVaultStateRequest extends Message<NucleusServiceVaultStateRequest> {
  /**
   * @generated from field: string vault_token = 1;
   */
  vaultToken = "";

  constructor(data?: PartialMessage<NucleusServiceVaultStateRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.NucleusServiceVaultStateRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "vault_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NucleusServiceVaultStateRequest {
    return new NucleusServiceVaultStateRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NucleusServiceVaultStateRequest {
    return new NucleusServiceVaultStateRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NucleusServiceVaultStateRequest {
    return new NucleusServiceVaultStateRequest().fromJsonString(jsonString, options);
  }

  static equals(a: NucleusServiceVaultStateRequest | PlainMessage<NucleusServiceVaultStateRequest> | undefined, b: NucleusServiceVaultStateRequest | PlainMessage<NucleusServiceVaultStateRequest> | undefined): boolean {
    return proto3.util.equals(NucleusServiceVaultStateRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.NucleusServiceVaultStateResponse
 */
export class NucleusServiceVaultStateResponse extends Message<NucleusServiceVaultStateResponse> {
  /**
   * @generated from field: float performance_fee_percent = 1;
   */
  performanceFeePercent = 0;

  /**
   * @generated from field: float deprecated_solver_fee_percent = 2;
   */
  deprecatedSolverFeePercent = 0;

  /**
   * @generated from field: float platform_fee_percent = 3;
   */
  platformFeePercent = 0;

  /**
   * @generated from field: int32 withdrawal_processing_duration_unix = 4;
   */
  withdrawalProcessingDurationUnix = 0;

  /**
   * @generated from field: repeated swell.v3.NucleusServiceVaultSolverFeeRecord solver_fees = 5;
   */
  solverFees: NucleusServiceVaultSolverFeeRecord[] = [];

  constructor(data?: PartialMessage<NucleusServiceVaultStateResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.NucleusServiceVaultStateResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "performance_fee_percent", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 2, name: "deprecated_solver_fee_percent", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 3, name: "platform_fee_percent", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 4, name: "withdrawal_processing_duration_unix", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "solver_fees", kind: "message", T: NucleusServiceVaultSolverFeeRecord, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NucleusServiceVaultStateResponse {
    return new NucleusServiceVaultStateResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NucleusServiceVaultStateResponse {
    return new NucleusServiceVaultStateResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NucleusServiceVaultStateResponse {
    return new NucleusServiceVaultStateResponse().fromJsonString(jsonString, options);
  }

  static equals(a: NucleusServiceVaultStateResponse | PlainMessage<NucleusServiceVaultStateResponse> | undefined, b: NucleusServiceVaultStateResponse | PlainMessage<NucleusServiceVaultStateResponse> | undefined): boolean {
    return proto3.util.equals(NucleusServiceVaultStateResponse, a, b);
  }
}

/**
 * @generated from message swell.v3.NucleusWantTokenKey
 */
export class NucleusWantTokenKey extends Message<NucleusWantTokenKey> {
  /**
   * @generated from field: string address = 1;
   */
  address = "";

  /**
   * @generated from field: uint32 chain_id = 2;
   */
  chainId = 0;

  constructor(data?: PartialMessage<NucleusWantTokenKey>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.NucleusWantTokenKey";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "chain_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NucleusWantTokenKey {
    return new NucleusWantTokenKey().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NucleusWantTokenKey {
    return new NucleusWantTokenKey().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NucleusWantTokenKey {
    return new NucleusWantTokenKey().fromJsonString(jsonString, options);
  }

  static equals(a: NucleusWantTokenKey | PlainMessage<NucleusWantTokenKey> | undefined, b: NucleusWantTokenKey | PlainMessage<NucleusWantTokenKey> | undefined): boolean {
    return proto3.util.equals(NucleusWantTokenKey, a, b);
  }
}

/**
 * @generated from message swell.v3.NucleusEventFulfilledData
 */
export class NucleusEventFulfilledData extends Message<NucleusEventFulfilledData> {
  /**
   * @generated from field: string user = 1;
   */
  user = "";

  /**
   * @generated from field: string offer_token = 2;
   */
  offerToken = "";

  /**
   * @generated from field: string want_token = 3;
   */
  wantToken = "";

  /**
   * @generated from field: string offer_amount_spent_wei = 4;
   */
  offerAmountSpentWei = "";

  /**
   * @generated from field: string want_amount_received_wei = 5;
   */
  wantAmountReceivedWei = "";

  /**
   * @generated from field: int64 timestamp_unix = 6;
   */
  timestampUnix = protoInt64.zero;

  /**
   * @generated from field: uint32 chain_id = 7;
   */
  chainId = 0;

  constructor(data?: PartialMessage<NucleusEventFulfilledData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.NucleusEventFulfilledData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "offer_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "want_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "offer_amount_spent_wei", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "want_amount_received_wei", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "timestamp_unix", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "chain_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NucleusEventFulfilledData {
    return new NucleusEventFulfilledData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NucleusEventFulfilledData {
    return new NucleusEventFulfilledData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NucleusEventFulfilledData {
    return new NucleusEventFulfilledData().fromJsonString(jsonString, options);
  }

  static equals(a: NucleusEventFulfilledData | PlainMessage<NucleusEventFulfilledData> | undefined, b: NucleusEventFulfilledData | PlainMessage<NucleusEventFulfilledData> | undefined): boolean {
    return proto3.util.equals(NucleusEventFulfilledData, a, b);
  }
}

/**
 * @generated from message swell.v3.NucleusServiceFulfilledEventsRequest
 */
export class NucleusServiceFulfilledEventsRequest extends Message<NucleusServiceFulfilledEventsRequest> {
  /**
   * @generated from field: string user = 1;
   */
  user = "";

  /**
   * deprecated
   *
   * @generated from field: repeated string want_tokens = 2;
   */
  wantTokens: string[] = [];

  /**
   * @generated from field: string vault_token = 3;
   */
  vaultToken = "";

  /**
   * @generated from field: repeated swell.v3.NucleusWantTokenKey want_token_keys = 4;
   */
  wantTokenKeys: NucleusWantTokenKey[] = [];

  constructor(data?: PartialMessage<NucleusServiceFulfilledEventsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.NucleusServiceFulfilledEventsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "want_tokens", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "vault_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "want_token_keys", kind: "message", T: NucleusWantTokenKey, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NucleusServiceFulfilledEventsRequest {
    return new NucleusServiceFulfilledEventsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NucleusServiceFulfilledEventsRequest {
    return new NucleusServiceFulfilledEventsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NucleusServiceFulfilledEventsRequest {
    return new NucleusServiceFulfilledEventsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: NucleusServiceFulfilledEventsRequest | PlainMessage<NucleusServiceFulfilledEventsRequest> | undefined, b: NucleusServiceFulfilledEventsRequest | PlainMessage<NucleusServiceFulfilledEventsRequest> | undefined): boolean {
    return proto3.util.equals(NucleusServiceFulfilledEventsRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.NucleusServiceFulfilledEventsResponse
 */
export class NucleusServiceFulfilledEventsResponse extends Message<NucleusServiceFulfilledEventsResponse> {
  /**
   * @generated from field: repeated swell.v3.NucleusEventFulfilledData events = 1;
   */
  events: NucleusEventFulfilledData[] = [];

  constructor(data?: PartialMessage<NucleusServiceFulfilledEventsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.NucleusServiceFulfilledEventsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "events", kind: "message", T: NucleusEventFulfilledData, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NucleusServiceFulfilledEventsResponse {
    return new NucleusServiceFulfilledEventsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NucleusServiceFulfilledEventsResponse {
    return new NucleusServiceFulfilledEventsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NucleusServiceFulfilledEventsResponse {
    return new NucleusServiceFulfilledEventsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: NucleusServiceFulfilledEventsResponse | PlainMessage<NucleusServiceFulfilledEventsResponse> | undefined, b: NucleusServiceFulfilledEventsResponse | PlainMessage<NucleusServiceFulfilledEventsResponse> | undefined): boolean {
    return proto3.util.equals(NucleusServiceFulfilledEventsResponse, a, b);
  }
}

/**
 * @generated from message swell.v3.NucleusServicePointsResult
 */
export class NucleusServicePointsResult extends Message<NucleusServicePointsResult> {
  /**
   * @generated from field: float pts = 1;
   */
  pts = 0;

  /**
   * @generated from field: float multiplier = 2;
   */
  multiplier = 0;

  constructor(data?: PartialMessage<NucleusServicePointsResult>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.NucleusServicePointsResult";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "pts", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 2, name: "multiplier", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NucleusServicePointsResult {
    return new NucleusServicePointsResult().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NucleusServicePointsResult {
    return new NucleusServicePointsResult().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NucleusServicePointsResult {
    return new NucleusServicePointsResult().fromJsonString(jsonString, options);
  }

  static equals(a: NucleusServicePointsResult | PlainMessage<NucleusServicePointsResult> | undefined, b: NucleusServicePointsResult | PlainMessage<NucleusServicePointsResult> | undefined): boolean {
    return proto3.util.equals(NucleusServicePointsResult, a, b);
  }
}

/**
 * @generated from message swell.v3.NucleusServicePoints
 */
export class NucleusServicePoints extends Message<NucleusServicePoints> {
  /**
   * @generated from field: swell.v3.NucleusServicePointsResult ecosystem_pts = 1;
   */
  ecosystemPts?: NucleusServicePointsResult;

  /**
   * @generated from field: swell.v3.NucleusServicePointsResult black_pearls = 2;
   */
  blackPearls?: NucleusServicePointsResult;

  /**
   * @generated from field: swell.v3.NucleusServicePointsResult nucleus_pts = 3;
   */
  nucleusPts?: NucleusServicePointsResult;

  constructor(data?: PartialMessage<NucleusServicePoints>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.NucleusServicePoints";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ecosystem_pts", kind: "message", T: NucleusServicePointsResult },
    { no: 2, name: "black_pearls", kind: "message", T: NucleusServicePointsResult },
    { no: 3, name: "nucleus_pts", kind: "message", T: NucleusServicePointsResult },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NucleusServicePoints {
    return new NucleusServicePoints().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NucleusServicePoints {
    return new NucleusServicePoints().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NucleusServicePoints {
    return new NucleusServicePoints().fromJsonString(jsonString, options);
  }

  static equals(a: NucleusServicePoints | PlainMessage<NucleusServicePoints> | undefined, b: NucleusServicePoints | PlainMessage<NucleusServicePoints> | undefined): boolean {
    return proto3.util.equals(NucleusServicePoints, a, b);
  }
}

/**
 * @generated from message swell.v3.NucleusServicePointsRequest
 */
export class NucleusServicePointsRequest extends Message<NucleusServicePointsRequest> {
  /**
   * @generated from field: string wallet_address = 1;
   */
  walletAddress = "";

  constructor(data?: PartialMessage<NucleusServicePointsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.NucleusServicePointsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "wallet_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NucleusServicePointsRequest {
    return new NucleusServicePointsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NucleusServicePointsRequest {
    return new NucleusServicePointsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NucleusServicePointsRequest {
    return new NucleusServicePointsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: NucleusServicePointsRequest | PlainMessage<NucleusServicePointsRequest> | undefined, b: NucleusServicePointsRequest | PlainMessage<NucleusServicePointsRequest> | undefined): boolean {
    return proto3.util.equals(NucleusServicePointsRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.NucleusServicePointsResponse
 */
export class NucleusServicePointsResponse extends Message<NucleusServicePointsResponse> {
  /**
   * @generated from field: swell.v3.NucleusServicePoints points = 1;
   */
  points?: NucleusServicePoints;

  constructor(data?: PartialMessage<NucleusServicePointsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.NucleusServicePointsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "points", kind: "message", T: NucleusServicePoints },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NucleusServicePointsResponse {
    return new NucleusServicePointsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NucleusServicePointsResponse {
    return new NucleusServicePointsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NucleusServicePointsResponse {
    return new NucleusServicePointsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: NucleusServicePointsResponse | PlainMessage<NucleusServicePointsResponse> | undefined, b: NucleusServicePointsResponse | PlainMessage<NucleusServicePointsResponse> | undefined): boolean {
    return proto3.util.equals(NucleusServicePointsResponse, a, b);
  }
}

/**
 * @generated from message swell.v3.NucleusVerifyWithdrawalRequest
 */
export class NucleusVerifyWithdrawalRequest extends Message<NucleusVerifyWithdrawalRequest> {
  /**
   * @generated from field: string offer_token = 1;
   */
  offerToken = "";

  /**
   * @generated from field: string offer_token_amount = 2;
   */
  offerTokenAmount = "";

  /**
   * @generated from field: string atomic_price_wei = 3;
   */
  atomicPriceWei = "";

  /**
   * @generated from field: string want_token = 4;
   */
  wantToken = "";

  /**
   * @generated from field: string user = 5;
   */
  user = "";

  /**
   * @generated from field: string atomic_queue_address = 6;
   */
  atomicQueueAddress = "";

  /**
   * @generated from field: uint32 chain_id = 7;
   */
  chainId = 0;

  constructor(data?: PartialMessage<NucleusVerifyWithdrawalRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.NucleusVerifyWithdrawalRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "offer_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "offer_token_amount", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "atomic_price_wei", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "want_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "user", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "atomic_queue_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "chain_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NucleusVerifyWithdrawalRequest {
    return new NucleusVerifyWithdrawalRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NucleusVerifyWithdrawalRequest {
    return new NucleusVerifyWithdrawalRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NucleusVerifyWithdrawalRequest {
    return new NucleusVerifyWithdrawalRequest().fromJsonString(jsonString, options);
  }

  static equals(a: NucleusVerifyWithdrawalRequest | PlainMessage<NucleusVerifyWithdrawalRequest> | undefined, b: NucleusVerifyWithdrawalRequest | PlainMessage<NucleusVerifyWithdrawalRequest> | undefined): boolean {
    return proto3.util.equals(NucleusVerifyWithdrawalRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.NucleusVerifyWithdrawalResponse
 */
export class NucleusVerifyWithdrawalResponse extends Message<NucleusVerifyWithdrawalResponse> {
  /**
   * @generated from field: bool is_valid = 1;
   */
  isValid = false;

  /**
   * @generated from field: string fail_message = 2;
   */
  failMessage = "";

  constructor(data?: PartialMessage<NucleusVerifyWithdrawalResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.NucleusVerifyWithdrawalResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "is_valid", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "fail_message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NucleusVerifyWithdrawalResponse {
    return new NucleusVerifyWithdrawalResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NucleusVerifyWithdrawalResponse {
    return new NucleusVerifyWithdrawalResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NucleusVerifyWithdrawalResponse {
    return new NucleusVerifyWithdrawalResponse().fromJsonString(jsonString, options);
  }

  static equals(a: NucleusVerifyWithdrawalResponse | PlainMessage<NucleusVerifyWithdrawalResponse> | undefined, b: NucleusVerifyWithdrawalResponse | PlainMessage<NucleusVerifyWithdrawalResponse> | undefined): boolean {
    return proto3.util.equals(NucleusVerifyWithdrawalResponse, a, b);
  }
}

