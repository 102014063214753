import styled from 'styled-components'
import { useTimeCountdown } from '@/hooks/useTimeCountdown'
import { useSwellUiTheme } from '@/swell-ui/theme'
import { Tooltip } from '@/swell-ui/Tooltip'
import { useSwellWeb3 } from '@/swell-web3/core'
import { formatWithConfig } from '@/util/number'
import { useMediaQuery } from '@mui/material'
import { InfoIcon } from '@/swell-ui/icons/InfoIcon'
import { DurationBreakdown } from '@/util/datetime'
import { DecoBox } from '../DecoBox'
import { FlexRow } from '@/swell-ui/FlexRow'
import { CountdownBoxClock } from '../CountdownFlipClock/CountdownBoxClock'
import blackPearlUrl from '@/assets/images/black-pearls-128x128.png'
import { AsyncDiv } from '@/swell-ui/AsyncDiv'
import { TOKEN_LIST_SWELL } from '@/constants/tokens'
import { Divider } from '@/swell-ui/Divider'
import { Wavedrop } from '@/state/wavedrops/types'
import { displayCryptoLocale } from '@/util/displayCrypto'
import { useSwellDaoToken } from '@/state/dao/hooks'
import { PreparedWavedropClaim } from './wavedropCalls'
import { ReactNode } from 'react'

const BLACK_PEARLS_WIP_INFO_URL =
  'https://docs.swellnetwork.io/swell-dao/faqs/swell-city-black-pearl-integration'

export function WavedropCard({
  endTimeUnix,
  wavedrop,
  ended,
  showMultiplier,
  endTimeFormatted,
  waveNumber,
  claimTooltip,
  isWip,
  preparedWavedropClaim,
  button,
  isEcosystemDrop,
}: {
  endTimeUnix: number
  wavedrop: Wavedrop | undefined
  ended: boolean
  showMultiplier?: boolean
  endTimeFormatted: string
  waveNumber: number
  claimTooltip?: string
  isWip?: boolean
  preparedWavedropClaim: PreparedWavedropClaim
  button: ReactNode
  isEcosystemDrop: boolean
}) {
  const daoToken = useSwellDaoToken()
  const { theme } = useSwellUiTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))
  const { account } = useSwellWeb3()

  const countdown = useTimeCountdown(endTimeUnix * 1000)
  const showCountdown = countdown !== null

  let currentWaveTitle = `Wave ${waveNumber}: Ends ${endTimeFormatted}`
  if (ended) {
    currentWaveTitle = `Wave ${waveNumber}: Ended ${endTimeFormatted}`
  }

  // user values
  let blackPearlsStr = '-'
  let multiplierStr = ''
  let swellBalanceStr = '-' // N/A for now
  if (account) {
    // trigger loading appearance with falsy values
    blackPearlsStr = ''

    if (wavedrop) {
      blackPearlsStr = formatWithConfig(wavedrop.blackPearls, {
        localize: true,
        precision: 0,
      })
      if (showMultiplier && wavedrop.multiplier) {
        const multiplierNumStr = formatWithConfig(wavedrop.multiplier, {
          localize: true,
          precision: 1,
        })
        multiplierStr = `${multiplierNumStr}x`
      }

      if (wavedrop.daoTokenAmount) {
        swellBalanceStr = displayCryptoLocale(
          wavedrop.daoTokenAmount,
          daoToken.decimals,
          { precision: 0 }
        )
      }
    }
  }

  // Black Pearls (1x boost)
  let blackPearlsLabelStr = 'Black Pearls'
  if (multiplierStr) {
    blackPearlsLabelStr += ` (${multiplierStr} boost)`
  }

  let ecosystemDropStr = '-'
  let basePearlDropStr = '-'
  if (isEcosystemDrop) {
    if (wavedrop) {
      if (wavedrop.phase !== 'claims') {
        return null //  bug
      }

      if (wavedrop.ecosystemAmount) {
        ecosystemDropStr = displayCryptoLocale(
          wavedrop.ecosystemAmount,
          daoToken.decimals,
          { precision: 0 }
        )
      }
      if (wavedrop.primaryAmount) {
        basePearlDropStr = displayCryptoLocale(
          wavedrop.primaryAmount,
          daoToken.decimals,
          { precision: 0 }
        )
      }
    } else if (account) {
      ecosystemDropStr = ''
      basePearlDropStr = ''
    }
  }

  let widget = (
    <WidgetMobile
      waveNumber={waveNumber}
      showCountdown={showCountdown}
      countdown={countdown}
      blackPearlsStr={blackPearlsStr}
      swellBalanceStr={swellBalanceStr}
      account={account}
      blackPearlsLabelStr={blackPearlsLabelStr}
      currentWaveTitle={currentWaveTitle}
      claimTooltip={claimTooltip}
      isWip={isWip}
      button={button}
      isEcosystemDrop={isEcosystemDrop}
      ecosystemDropStr={ecosystemDropStr}
      basePearlDropStr={basePearlDropStr}
    />
  )
  if (isMdUp) {
    widget = (
      <WidgetDesktop
        claimTooltip={claimTooltip}
        waveNumber={waveNumber}
        showCountdown={showCountdown}
        countdown={countdown}
        blackPearlsStr={blackPearlsStr}
        swellBalanceStr={swellBalanceStr}
        account={account}
        blackPearlsLabelStr={blackPearlsLabelStr}
        currentWaveTitle={currentWaveTitle}
        isWip={isWip}
        button={button}
        isEcosystemDrop={isEcosystemDrop}
        ecosystemDropStr={ecosystemDropStr}
        basePearlDropStr={basePearlDropStr}
      />
    )
  }

  return widget
}

function BlackPearlsWIPTooltip() {
  return null
}
function PreLaunchTooltip() {
  return (
    <Tooltip
      title={<>SWELL earned from Swellchain Pre-Launch deposits</>}
      placement="top"
      offset={[0, -10]}
      arrow
    >
      <InfoIcon />
    </Tooltip>
  )
}
function Wavedrop2Tooltip() {
  return (
    <Tooltip
      title={<>SWELL earned from Wavedrop 2</>}
      placement="top"
      offset={[0, -10]}
      arrow
    >
      <InfoIcon />
    </Tooltip>
  )
}

function WidgetDesktop({
  account,
  blackPearlsLabelStr,
  blackPearlsStr,
  claimTooltip,
  countdown,
  showCountdown,
  swellBalanceStr,
  currentWaveTitle,
  waveNumber,
  isWip,
  button,
  isEcosystemDrop,
  ecosystemDropStr,
  basePearlDropStr,
}: {
  showCountdown: boolean
  countdown: DurationBreakdown | undefined
  blackPearlsStr: string
  isEcosystemDrop: boolean
  claimTooltip: string | undefined
  swellBalanceStr: string
  account: string | undefined
  blackPearlsLabelStr: string
  currentWaveTitle: string
  waveNumber: number
  button: ReactNode
  isWip?: boolean

  ecosystemDropStr: string | undefined
  basePearlDropStr: string | undefined
}) {
  function renderSwellBalances() {
    const defaultNode = (
      <>
        <span className="swc-label-colored">Wavedrop {waveNumber}</span>
        <FlexRow gap="12" width="auto">
          <img src={TOKEN_LIST_SWELL.logoURI} width={32} height={32} />
          <AsyncDiv loading={!swellBalanceStr}>
            {() => <span className="swc-med-value">{swellBalanceStr}</span>}
          </AsyncDiv>
        </FlexRow>
      </>
    )

    if (!isEcosystemDrop) {
      return defaultNode
    }

    return (
      <FlexRow>
        <FlexRow direction="column" gap="12">
          <span className="swc-label-colored info-icon">
            Wavedrop {waveNumber} <Wavedrop2Tooltip />
          </span>
          <FlexRow gap="12" width="auto">
            <img src={TOKEN_LIST_SWELL.logoURI} width={32} height={32} />
            <AsyncDiv loading={!basePearlDropStr}>
              {() => <span className="swc-med-value">{basePearlDropStr}</span>}
            </AsyncDiv>
          </FlexRow>
        </FlexRow>
        <FlexRow direction="column" gap="12">
          <span className="swc-label-colored info-icon">
            Pre-launch <PreLaunchTooltip />
          </span>
          <FlexRow gap="12" width="auto">
            <img src={TOKEN_LIST_SWELL.logoURI} width={32} height={32} />
            <AsyncDiv loading={!ecosystemDropStr}>
              {() => <span className="swc-med-value">{ecosystemDropStr}</span>}
            </AsyncDiv>
          </FlexRow>
        </FlexRow>
      </FlexRow>
    )
  }

  return (
    <DecoBox padding="42px !important" direction="row" gap="48">
      <FlexRow
        direction="column"
        gap="24"
        justify="center"
        align="start"
        width="433px"
        style={{
          alignSelf: 'stretch',
          visibility: showCountdown ? 'visible' : 'visible',
          flexShrink: 0,
        }}
      >
        <span className="swc-label-1">{currentWaveTitle}</span>
        {countdown ? (
          <CountdownBoxClock countdown={countdown} />
        ) : (
          <CountdownBoxClock
            countdown={{
              days: 0,
              hours: 0,
              minutes: 0,
              seconds: 0,
            }}
          />
        )}
      </FlexRow>
      <VDivider />
      <FlexRow
        direction="column"
        justify="space-between"
        align="center"
        gap="12"
      >
        <span className="swc-label-colored info-icon">
          {blackPearlsLabelStr} {isWip && <BlackPearlsWIPTooltip />}
        </span>
        <FlexRow gap="12" width="auto">
          <img src={blackPearlUrl} width={32} height={32} />
          <AsyncDiv loading={!blackPearlsStr}>
            {() => <span className="swc-med-value">{blackPearlsStr}</span>}
          </AsyncDiv>
        </FlexRow>
        {renderSwellBalances()}
        <FlexRow width="100%" padding="12px 0 0">
          {button}
        </FlexRow>
      </FlexRow>
    </DecoBox>
  )
}

function WidgetMobile({
  account,
  blackPearlsLabelStr,
  blackPearlsStr,
  claimTooltip,
  countdown,
  showCountdown,
  swellBalanceStr,
  currentWaveTitle,
  isEcosystemDrop,
  waveNumber,
  isWip,
  button,
  basePearlDropStr,
  ecosystemDropStr,
}: {
  showCountdown: boolean
  countdown: DurationBreakdown | undefined
  blackPearlsStr: string
  swellBalanceStr: string

  ecosystemDropStr: string | undefined
  basePearlDropStr: string | undefined

  account: string | undefined
  blackPearlsLabelStr: string
  currentWaveTitle: string
  waveNumber: number
  claimTooltip?: string
  button: ReactNode
  isEcosystemDrop: boolean
  isWip?: boolean
}) {
  function renderSwellBalances() {
    const defaultNode = (
      <>
        <span className="swc-label-colored">Wavedrop {waveNumber}</span>
        <FlexRow gap="12" width="auto">
          <img src={TOKEN_LIST_SWELL.logoURI} width={32} height={32} />
          <AsyncDiv loading={!swellBalanceStr}>
            {() => <span className="swc-med-value">{swellBalanceStr}</span>}
          </AsyncDiv>
        </FlexRow>
      </>
    )

    if (!isEcosystemDrop) {
      return defaultNode
    }

    return (
      <FlexRow>
        <FlexRow direction="column" gap="12">
          <span className="swc-label-colored info-icon">
            Wavedrop {waveNumber} <Wavedrop2Tooltip />
          </span>
          <FlexRow gap="12" width="auto">
            <img src={TOKEN_LIST_SWELL.logoURI} width={32} height={32} />
            <AsyncDiv loading={!basePearlDropStr}>
              {() => <span className="swc-med-value">{basePearlDropStr}</span>}
            </AsyncDiv>
          </FlexRow>
        </FlexRow>
        <FlexRow direction="column" gap="12">
          <span className="swc-label-colored info-icon">
            Pre-launch <PreLaunchTooltip />
          </span>
          <FlexRow gap="12" width="auto">
            <img src={TOKEN_LIST_SWELL.logoURI} width={32} height={32} />
            <AsyncDiv loading={!ecosystemDropStr}>
              {() => <span className="swc-med-value">{ecosystemDropStr}</span>}
            </AsyncDiv>
          </FlexRow>
        </FlexRow>
      </FlexRow>
    )
  }

  return (
    <DecoBox gap="24">
      <FlexRow
        direction="column"
        style={{
          visibility: showCountdown ? 'visible' : 'visible',
        }}
        gap="24"
      >
        <span className="swc-label-1">{currentWaveTitle}</span>
        {countdown ? (
          <CountdownBoxClock countdown={countdown} compact />
        ) : (
          <CountdownBoxClock
            countdown={{
              days: 0,
              hours: 0,
              minutes: 0,
              seconds: 0,
            }}
            compact
          />
        )}
      </FlexRow>
      <Divider />
      <FlexRow
        direction="column"
        justify="space-between"
        align="center"
        gap="12"
      >
        <span className="swc-label-colored info-icon">
          {blackPearlsLabelStr} {isWip && <BlackPearlsWIPTooltip />}
        </span>
        <FlexRow gap="12" width="auto">
          <img src={blackPearlUrl} width={32} height={32} />
          <AsyncDiv loading={!blackPearlsStr}>
            {() => <span className="swc-med-value">{blackPearlsStr}</span>}
          </AsyncDiv>
        </FlexRow>
        {renderSwellBalances()}
        <FlexRow width="100%" padding="12px 0 0">
          {button}
        </FlexRow>
      </FlexRow>
    </DecoBox>
  )
}

const VDivider = styled.div`
  width: 1px;
  align-self: stretch;
  background: rgba(255, 255, 255, 0.2);
`
