import { UnlockWSwell } from '@/state/wswell/hooks'
import { WSWELLLockSummary, WSWELLUserSummary } from '../../types'
import { PreparedUnlockWSwell } from '../../wswellCalls'
import { Token } from '@/types/tokens'
import { useSwellWeb3 } from '@/swell-web3/core'
import useChainDetection from '@/hooks/useChainDetection'
import styled from 'styled-components'
import { Box } from '@/swell-ui/Box'
import { ReactNode } from 'react'
import {
  DisabledWSWELLUnlockButton,
  WSwellButton,
  WSwellConnectWallet,
  WSwellSwitchChain,
  WSWELLUnlockButton,
} from '../../WswellButtons'
import { AccordionV2 } from '@/swell-ui/AccordionV2'
import { FlexRow } from '@/swell-ui/FlexRow'
import { CurrentUnlockAmountCell, TimeTilMaturityCell } from '../common'
import { EagerEVKLock } from '@/state/wswell/types'

export function MerklClaimAccordion({
  userSummary,
  allPreparedUnlockWSwell,
  preventInteraction,
  unlockWSwell,
  eagerLock,
  WSwellToken,
}: {
  WSwellToken: Token
  userSummary: WSWELLUserSummary
  allPreparedUnlockWSwell: Partial<Record<number, PreparedUnlockWSwell>>
  unlockWSwell: UnlockWSwell
  preventInteraction: boolean
  eagerLock: EagerEVKLock | undefined
}) {
  const { account } = useSwellWeb3()
  const { isL2DeploymentChain } = useChainDetection()

  let cards: ReactNode[] = []
  if (userSummary.locksLoaded) {
    for (const lock of userSummary.locks ?? []) {
      const preparedUnlockWSwell =
        allPreparedUnlockWSwell[lock.lockTimestampUnix]

      if (!preparedUnlockWSwell) {
        console.warn('No prepared unlock for lock', lock)
        continue
      }
      cards.push(
        <MobileAccordion
          key={lock.lockTimestampUnix}
          eagerLock={eagerLock}
          evkToken={WSwellToken}
          lock={lock}
          preparedUnlockWSwell={preparedUnlockWSwell}
          preventInteraction={preventInteraction}
          unlockWSwell={unlockWSwell}
        />
      )
    }
  } else {
    cards = [] // todo loading cards?
  }

  return (
    <SBox>
      {cards}
      {!account && (
        <div className="cw-positioner">
          <WSwellConnectWallet />
        </div>
      )}
      {account && !isL2DeploymentChain && (
        <div className="cw-positioner">
          <WSwellSwitchChain />
        </div>
      )}
    </SBox>
  )
}

const SBox = styled(Box)`
  margin-bottom: 140px;

  border-radius: 16px;
  border: 1px solid rgba(164, 171, 241, 0.2);

  gap: 24px;
  padding: 24px 12px !important;

  background: var(--Swell-Oceanblue-150, #010508);

  display: flex;
  padding: 32px 42px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;

  .MuiCollapse-root:not(.MuiCollapse-hidden) {
    margin-top: 12px;
    padding: 16px;
  }

  .MuiAccordionSummary-root {
    padding: 16px;
  }

  .MuiAccordionSummary-root,
  .MuiCollapse-root {
    background: var(--Swell-Oceanblue-125, #0b141b);
    border-radius: 12px;
  }

  min-height: 270px;

  position: relative;
  .cw-positioner {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    display: flex;

    justify-content: center;
    align-items: center;

    button {
      position: relative;
      min-width: 182px;
      max-width: 182px;
      transform: translateY(24px);
    }
  }

  .MuiAccordion-root {
    background: transparent;
    padding: 0;
  }
`

function MobileAccordion({
  lock,
  preparedUnlockWSwell,
  evkToken,
  preventInteraction,
  unlockWSwell,
  eagerLock,
}: {
  lock: WSWELLLockSummary
  preparedUnlockWSwell: PreparedUnlockWSwell
  evkToken: Token
  preventInteraction: boolean
  unlockWSwell: UnlockWSwell
  eagerLock: EagerEVKLock | undefined
}) {
  const { isL2DeploymentChain, switchToL2DeploymentChain } = useChainDetection()

  let unlockButton = null as any

  if (!preparedUnlockWSwell) {
    unlockButton = <DisabledWSWELLUnlockButton />
  } else {
    const isEagerLock = eagerLock?.lockTimestampUnix === lock.lockTimestampUnix
    unlockButton = (
      <WSWELLUnlockButton
        unlockWSwell={unlockWSwell}
        preventInteraction={preventInteraction}
        prepared={preparedUnlockWSwell}
        isMatured={lock.isMatured}
        isEagerLock={isEagerLock}
      />
    )
  }

  if (!isL2DeploymentChain) {
    unlockButton = (
      <WSwellButton onClick={switchToL2DeploymentChain}>
        Switch to Swellchain
      </WSwellButton>
    )
  }

  return (
    <StyledAccordionV2
      summary={<span>{lock.amountToMature} wSWELL</span>}
      details={
        <AccordionContent>
          <div className="p-amount-to-mature">
            <FlexRow direction="column" gap="6" align="start">
              <span className="body-uppercase-small">Amount to Mature</span>
              <FlexRow className="mct-cell body-small">
                <span>{lock.amountToMature}</span>
              </FlexRow>
            </FlexRow>
          </div>
          <div className="p-time-til-maturity">
            <FlexRow direction="column" gap="6" align="start">
              <span className="body-uppercase-small">Time Until Maturity</span>
              <FlexRow className="mct-cell body-small">
                <TimeTilMaturityCell lock={lock} />
              </FlexRow>
            </FlexRow>
          </div>
          <div className="p-unlock-amount">
            <FlexRow direction="column" gap="6" align="start">
              <span className="body-uppercase-small">Unlock Amount</span>
              <FlexRow className="mct-cell body-small">
                <CurrentUnlockAmountCell lock={lock} evkToken={evkToken} />
              </FlexRow>
            </FlexRow>
          </div>
          <div className="p-actions">{unlockButton}</div>
        </AccordionContent>
      }
    />
  )
}

const StyledAccordionV2 = styled(AccordionV2)``

const AccordionContent = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(4, auto);
  grid-template-areas:
    'amount-to-mature'
    'time-til-maturity'
    'unlock-amount'
    'button';
  grid-column-gap: 12px;
  grid-row-gap: 24px;

  .p-amount-to-mature {
    grid-area: amount-to-mature;
  }
  .p-time-til-maturity {
    grid-area: time-til-maturity;
  }
  .p-unlock-amount {
    grid-area: unlock-amount;
  }
  .p-actions {
    grid-area: button;
    max-width: 180px;
  }
`
