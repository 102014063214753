import { useNucleusEthVault } from '@/state/nucleusVault/hooks'
import { ScrollableYArea } from '@/swell-ui/ScrollableArea'
import styled from 'styled-components'

export function NucleusSwellchainInfoView() {
  return (
    <Layout>
      <ScrollableYArea containerType="flex">
        <div className="custom">
          <h3>Swellchain pre-launch assets</h3>
          <p>
            To help facilitate users who want to bridge the more exotic assets
            in the Swellchain Pre-launch deposit contract without the cost and
            effort of manually unwinding positions on mainnet to bridge to
            Swellchain, depositing those assets into the earnETH vault is an
            easy way for the underlying assets to be converted to earnETH
            strategies that will be eventually bridged to Swellchain.
          </p>
          <p>
            Step 1. Withdraw from pre-launch deposit
            <br />
            Step 2. Deposit into earnETH vault, receive earnETH vault token
            <br />
            Step 3. Hodl on mainnet while you receive Swellchain exposure
          </p>
        </div>
      </ScrollableYArea>
      <ScrollableYArea containerType="flex">
        <div className="custom">
          <h3>Supported assets</h3>
          <p>
            PT-weETH-26DEC2024
            <br />
            PT-ezETH-26DEC2024
            <br />
            PT-rswETH-27JUN2024
            <br />
            PT-weETH-27JUN2024
            <br />
            Symbiotic swETH
            <br />
            Symbiotic wstETH
            <br />
            Symbiotic cbETH
            <br />
            Eigenpie mmETH
            <br />
            Eigenpie mswETH
            <br />
            Eigenpie mstETH
            <br />
            Mellow Finance amphrETH
            <br />
            apxETH
            <br />
          </p>
        </div>
      </ScrollableYArea>
    </Layout>
  )
}

const Layout = styled.div`
  display: flex;
  flex-flow: column-reverse nowrap;
  justify-content: stretch;
  align-items: stretch;
  gap: 24px;
  font-size: 14px;

  @media (min-width: 900px) {
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: unset;
    column-gap: 62px;
    justify-content: unset;
    align-items: unset;
  }

  height: 100%;

  > div {
    flex: 1;
  }

  .scroll-area-y {
    background-color: rgba(0, 34, 37, 0.5);
    border-radius: 8px;

    > div {
      right: 2px;
    }
  }

  .custom {
    padding: 0 12px 12px 12px;
    h3 {
      margin-top: 0;
      padding-top: 12px;

      color: var(--Swell-White-50, #fff);
      /* Body/medium bold */
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 160%; /* 25.6px */
    }
  }
`
