import EventEmitter from 'events'
import GUI from 'lil-gui'

export const guiSetupFunctions = new Set<(gui: GUI) => void>()
export const updateEmitter = new EventEmitter()

export function registerGui(setup: (gui: GUI) => void) {
  guiSetupFunctions.add(setup)
  updateEmitter.emit('update')
  return () => {
    guiSetupFunctions.delete(setup)
    updateEmitter.emit('update')
  }
}
