import { useSwellWeb3 } from '@/swell-web3/core'
import { EarnContext } from './context'
import { useEarnSettings } from '../deployments/hooks/useEarnSettings'
import { useV3BackendClient } from '@/services/V3BackendService/hooks'
import { parseEarnPortfolioPosition } from '../../util/earn'
import {
  GlobalEarnPortfolioPosition,
  EarnPositionBalanceMap,
} from '@/types/earn'
import { EarnBalanceType } from '@/submodules/v3-shared/ts/connect/swell/v3/wallet_pb'
import { useMemo } from 'react'
import { StaticWavedropService } from '@/services/StaticWavedrop'
import { useWavedropsSettings } from '../deployments/hooks/useWavedropsSettings'
import { fetchWavedrops } from '../wavedrops/fetcher'
import { formatUnits } from 'ethers/lib/utils'
import { TOKEN_LIST_SWELL } from '@/constants/tokens'
import { StaticWavedropServiceV2 } from '@/services/StaticWavedropV2'

const daoToken = TOKEN_LIST_SWELL

export function useEarnApiImpl(): EarnContext {
  const {
    backendURL,
    filterChainOptions,
    filterTokenOptions,
    rewardAssets,
    pointsCampaigns,
    preconfiguredCampaigns,
    latestSwellCampaign,
    merklBaseUrl,
  } = useEarnSettings()
  const { account } = useSwellWeb3()
  const walletClient = useV3BackendClient(backendURL).v3BackendClient.wallet
  const statsClient = useV3BackendClient(backendURL).v3BackendClient.stats

  const { wavedropServiceURL } = useWavedropsSettings()
  const wavedropSvc = useMemo(
    () => new StaticWavedropServiceV2({ baseURL: wavedropServiceURL }),
    [wavedropServiceURL]
  )

  return {
    latestSwellCampaign,
    rewardAssets,
    pointsCampaigns,
    preconfiguredCampaigns,
    filterChainOptions,
    filterTokenOptions,
    merklBaseUrl,
    legacyPointsResults: async () => {
      if (!account) {
        throw new Error('no account')
      }
      const respP = walletClient.earnCampaigns({
        walletAddress: account,
      })

      const wavedrops = await fetchWavedrops({ wavedropSvc, account })

      let pointsWavedrop_1 = 0
      let claimWavedrop_1 = 0
      let pointsWavedrop_2 = 0
      let claimWavedrop_2 = 0
      let claimEcosystem = 0
      for (const campaign of wavedrops.wavedrops) {
        if (campaign.waveNumber === 1) {
          pointsWavedrop_1 += campaign.blackPearls ?? 0
          claimWavedrop_1 += campaign.daoTokenAmount
            ? Number(formatUnits(campaign.daoTokenAmount, daoToken.decimals))
            : 0
        }
        if (campaign.waveNumber === 2) {
          if (campaign.phase !== 'claims') continue
          pointsWavedrop_2 += campaign.blackPearls ?? 0
          claimWavedrop_2 += campaign.primaryAmount
            ? Number(formatUnits(campaign.primaryAmount, daoToken.decimals))
            : 0
          claimEcosystem += campaign.ecosystemAmount
            ? Number(formatUnits(campaign.ecosystemAmount, daoToken.decimals))
            : 0
        }
      }

      const resp = await respP

      return {
        claimable: {
          voyage: resp.voyage?.claimableAssets ?? 0,
          wavedrop_1: claimWavedrop_1,
          wavedrop_2: claimWavedrop_2,
          l2_launch: claimEcosystem,
        },
        points: {
          l2_launch: resp.swellL2Prelaunch?.points ?? 0,
          symbiotic: resp.symbiotic?.points ?? 0,
          voyage: resp.voyage?.points ?? 0,
          wavedrop_1: pointsWavedrop_1,
          wavedrop_2: pointsWavedrop_2,
        },
      }
    },
    positions: async () => {
      const resp = await statsClient.earnAPYs({})
      const mapping: Record<string, GlobalEarnPortfolioPosition> = {}
      for (const position of resp.available) {
        const p = parseEarnPortfolioPosition(position)
        mapping[p.positionId] = p
      }
      return mapping
    },
    positionBalances: (signal) => {
      const stream = walletClient.earnPositions(
        { walletAddress: account },
        { signal }
      )

      const m: EarnPositionBalanceMap = {}
      return (async function* () {
        for await (const resp of stream) {
          if (!m[resp.positionId]) {
            m[resp.positionId] = {
              balanceUsd: { exists: false },
              ecosystemTokens: { exists: false },
              ecosystemTokensCumulative: { exists: false },
              swell: { exists: false },
              swellCumulative: { exists: false },
            }
          }

          const isBalanceUsd = resp.balanceType === EarnBalanceType.USER_TVL
          const isEcosystemTokens =
            resp.balanceType === EarnBalanceType.ECOSYSTEM_TOKENS
          const isEcosystemTokensCumulative =
            resp.balanceType === EarnBalanceType.ECOSYSTEM_TOKENS_CUMULATIVE
          const isSwell = resp.balanceType === EarnBalanceType.SWELL
          const isSwellCumulative =
            resp.balanceType === EarnBalanceType.SWELL_CUMULATIVE

          if (isBalanceUsd) {
            m[resp.positionId].balanceUsd = {
              exists: true,
              amounts: resp.balances ?? [],
            }
          }
          if (isSwell) {
            m[resp.positionId].swell = {
              exists: true,
              amounts: resp.balances ?? [],
            }
          }
          if (isEcosystemTokens) {
            m[resp.positionId].ecosystemTokens = {
              exists: true,
              amounts: resp.balances ?? [],
            }
          }
          if (isEcosystemTokensCumulative) {
            m[resp.positionId].ecosystemTokensCumulative = {
              exists: true,
              amounts: resp.balances ?? [],
            }
          }
          if (isSwellCumulative) {
            m[resp.positionId].swellCumulative = {
              exists: true,
              amounts: resp.balances ?? [],
            }
          }

          yield m
        }
      })()
    },
  }
}
