export const ROUTES = {
  StakeSwETH: '/stake/sweth',
  StakeRswETH: '/stake/rsweth',
  StakeSwBTC: '/stake/swbtc',
  StakeSwell: '/stake/rswell',
  SwellchainLaunch: '/swellchain/launch',
  SwellchainEcosystem: '/swellchain/ecosystem',
  DaoVoyage: '/dao/voyage',
  DaoSwellCity: '/dao/swell-city',
  EarnPortfolio: '/earn/portfolio',
  EarnETH: '/earn/earneth',
  EarnBTC: '/earn/earnbtc',
  EarnWSwell: '/earn/wswell',
}
