import { useEffect } from 'react'
import { YearnAeraPageTemplate } from '@/components/YearnAeraPage/YearnAeraPageTemplate'
import { YearnAeraPointsCampaigns } from '@/components/YearnAeraPage/types'
import eigenLayerGreyLogo from '@/assets/images/eigen-grey-80x80.png'
import { VaultYieldSource } from '@/types/yearnAera'
import {
  useRswellVault,
  useRswellVaultStats,
  useRswellVaultState,
  useRswellAuth,
  useRswellBalances,
  useRswellAllowances,
  useRswellWithdrawRequest,
  useRswellVaultPoints,
  useRswellVaultCalls,
} from '@/state/yearnVault/hooks'
import { TOKEN_LIST_RSWELL } from '@/constants/tokens'
import { FlexRow } from '@/swell-ui/FlexRow'
import { usePrices } from '@/state/prices/hooks'
import { useRestakedSwellDaoToken } from '@/state/dao/hooks'
import { useConsiderDepositInL2Modal } from './hooks'
import { useNavigate } from 'react-router'
import { ROUTES } from '@/constants/routes'
import { ConsiderDepositInL2Modal } from '@/components/Modals/ConsiderDepositRswellInL2Modal'
import { URI_SYMBIOTIC_LOGO } from '@/constants/logo'

// https://www.figma.com/design/1trLPK52KCe6Nko8e2NCbU/Swell-Network-Design?node-id=10342-20967&node-type=canvas&m=dev

const title = (
  <FlexRow align="center" gap="12">
    <img src={TOKEN_LIST_RSWELL.logoURI} width={48} height={48} /> Restaked
    SWELL LRT
  </FlexRow>
)
const pageDescription = (
  <p>
    An automated vault that restakes SWELL into restaking protocol for rewards
    and to secure AVSs for Swell L2.
  </p>
)

const TERMS_OF_SERVICE_HREF =
  'https://www.swellnetwork.io/legal/terms-of-service'
const VAULT_AGREEMENT_HREF =
  'https://www.swellnetwork.io/legal/gauntlet-vault-agreement-and-disclaimer'

const vaultDescription = (
  <>
    <p>
      This is a multi-strategy vault that optimally restakes SWELL into a mix of
      restaking protocols for maximum risk adjusted yields.
    </p>
    <br />
    <p>
      Disclaimer:
      <br />
      rSWELL vault is not open to persons or citizens of the United States and
      other restricted countries - for more details please refer to the{' '}
      <a href={TERMS_OF_SERVICE_HREF} rel="noopener noreferrer" target="_blank">
        terms of service
      </a>{' '}
      and{' '}
      <a href={VAULT_AGREEMENT_HREF} rel="noopener noreferrer" target="_blank">
        vault agreement
      </a>
      .
    </p>
  </>
)
const pointsCampaigns: YearnAeraPointsCampaigns = {
  symbiotic: {
    logoURI: URI_SYMBIOTIC_LOGO,
    name: 'Symbiotic Points',
    multiplier: 1,
    comingSoon: false,
  },
}

const yieldSources: VaultYieldSource[] = [
  {
    name: 'Symbiotic',
    logoURI: URI_SYMBIOTIC_LOGO,
    comingSoon: false,
  },
  {
    name: 'Eigenlayer',
    logoURI: eigenLayerGreyLogo,
    comingSoon: true,
  },
]

export function RSWELLVaultPage() {
  const navigate = useNavigate()

  const restakedDaoToken = useRestakedSwellDaoToken()

  const vault = useRswellVault()

  const vaultStatsQuery = useRswellVaultStats()
  const vaultStateQuery = useRswellVaultState()
  const authQuery = useRswellAuth()
  const balancesQuery = useRswellBalances()
  const allowancesQuery = useRswellAllowances()
  const withdrawRequestQuery = useRswellWithdrawRequest()
  const vaultPointsQuery = useRswellVaultPoints()
  const pricesQuery = usePrices()

  const calls = useRswellVaultCalls()
  const {
    approveAssetForDeposit,
    approveVaultTokenForWithdraw,
    cancelWithdraw,
    deposit,
    requestWithdraw,
    completeWithdraw,
  } = calls

  useEffect(() => {
    if (
      approveAssetForDeposit.status === approveAssetForDeposit.STATUS.FULFILLED
    ) {
      allowancesQuery.mutate()
    }
  }, [
    allowancesQuery,
    approveAssetForDeposit.STATUS.FULFILLED,
    approveAssetForDeposit.status,
  ])
  useEffect(() => {
    if (
      approveVaultTokenForWithdraw.status ===
      approveVaultTokenForWithdraw.STATUS.FULFILLED
    ) {
      allowancesQuery.mutate()
    }
  }, [
    allowancesQuery,
    approveVaultTokenForWithdraw.STATUS.FULFILLED,
    approveVaultTokenForWithdraw.status,
  ])
  useEffect(() => {
    if (deposit.status === deposit.STATUS.FULFILLED) {
      balancesQuery.mutate()
    }
  }, [balancesQuery, deposit.STATUS.FULFILLED, deposit.status])
  useEffect(() => {
    if (requestWithdraw.status === requestWithdraw.STATUS.FULFILLED) {
      withdrawRequestQuery.mutate()
      balancesQuery.mutate()
    }
  }, [balancesQuery, requestWithdraw, withdrawRequestQuery])
  useEffect(() => {
    if (cancelWithdraw.status === cancelWithdraw.STATUS.FULFILLED) {
      withdrawRequestQuery.mutate()
      balancesQuery.mutate()
    }
  }, [balancesQuery, cancelWithdraw, withdrawRequestQuery])
  useEffect(() => {
    if (completeWithdraw.status === completeWithdraw.STATUS.FULFILLED) {
      balancesQuery.mutate()
      withdrawRequestQuery.mutate()
    }
  }, [
    balancesQuery,
    completeWithdraw,
    completeWithdraw.status,
    withdrawRequestQuery,
  ])

  const allowances = allowancesQuery.data
  const balances = balancesQuery.data
  const depositAssetUsdRate = pricesQuery.data?.swellUsd
  const withdrawRequest = withdrawRequestQuery.data
  const vaultStats = vaultStatsQuery.data
  const auth = authQuery.data
  const vaultPoints = vaultPointsQuery.data
  const vaultState = vaultStateQuery.data

  const considerDepositInL2Modal = useConsiderDepositInL2Modal({ deposit })
  considerDepositInL2Modal.open = false

  return (
    <>
      {considerDepositInL2Modal.open && considerDepositInL2Modal.summary && (
        <ConsiderDepositInL2Modal
          onConfirm={() => {
            navigate(ROUTES.SwellchainLaunch)
          }}
          onClose={considerDepositInL2Modal.onClose}
          restakedDaoToken={restakedDaoToken}
          summary={considerDepositInL2Modal.summary}
          entrypoint="restake"
        />
      )}
      <YearnAeraPageTemplate
        legacyWithdrawAsset={undefined}
        legacyCalls={undefined}
        legacyWithdrawRequest={undefined}
        vault={vault}
        pageDescription={pageDescription}
        title={title}
        vaultDescription={vaultDescription}
        depositAssetUsdRate={depositAssetUsdRate}
        vaultStats={vaultStats}
        yieldSources={yieldSources}
        pointsCampaigns={pointsCampaigns}
        vaultPoints={vaultPoints}
        vaultState={vaultState}
        auth={auth}
        balances={balances}
        allowances={allowances}
        withdrawRequest={withdrawRequest}
        calls={calls}
      />
    </>
  )
}
