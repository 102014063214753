import React from 'react'
import styled from 'styled-components/macro'
import { Box } from '@swell-ui/Box'

import { OperatorsTable } from '@/components/OperatorsTable'
import { PageTitle } from '@/components/PageTitle'
import { useProtocolAllOperators } from '@/state/restakingProtocolOperators/hooks'

const TitlePositioner = styled.div`
  position: relative;
`

const ContainerBox = styled(Box)`
  padding: 24px 42px;
  max-width: 100%;
  overflow: hidden;
`

function AllOperators() {
  const allOperatorsQuery = useProtocolAllOperators()

  return (
    <>
      <TitlePositioner>
        <PageTitle text="All restaking operators" />
      </TitlePositioner>
      <ContainerBox>
        <OperatorsTable allOperators={allOperatorsQuery.data?.allOperators} />
      </ContainerBox>
    </>
  )
}

export { AllOperators }
