import { Accordion } from '@/swell-ui/Accordion'
import { DownCaratIcon } from '@/swell-ui/icons/DownCaratIcon'
import { UpCaratIcon } from '@/swell-ui/icons/UpCaratIcon'
import { Typography } from '@/swell-ui/Typography'
import { useState } from 'react'
import styled from 'styled-components'
import { StakingPoolActivityInputs, SwETHStatsSummary } from '../../types'
import { AsyncDiv } from '@/swell-ui/AsyncDiv'
import { KeyValueList } from '@/swell-ui/KeyValueList'
import { StakingPoolActivityV2 } from '@/components/StakingPoolActivityV2'
import { ActivitySummaryCounts, ActivitySummaryItems } from '@/types/activity'

type RelevantSummary = Pick<
  SwETHStatsSummary,
  'commissionRate' | 'totalStaked' | 'numStakers' | 'marketCap'
>

const ActivityContainer = styled.div`
  margin-top: 24px;
`

const SummaryContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: -0.03em;

  svg {
    margin-left: 5px;
  }
`

function SwellStatisticAccordionSummary({ open }: { open: boolean }) {
  return (
    <SummaryContainer>
      <Typography variant="body" size="small">
        Swell statistics
      </Typography>
      {!open && <DownCaratIcon />}
      {open && <UpCaratIcon />}
    </SummaryContainer>
  )
}

function SwellStatisticAccordionDetails({
  summary,
  activityCounts,
  activityItems,
  activityInputs,
  lstToken,
}: {
  summary: RelevantSummary
  activityItems: ActivitySummaryItems | undefined
  activityCounts: ActivitySummaryCounts | undefined
  activityInputs: StakingPoolActivityInputs
  lstToken: { logoURI: string }
}) {
  const commissionRateConfig = {
    label: 'Commission rate',
    value: (
      <AsyncDiv loading={!summary.commissionRate}>
        {() => summary.commissionRate}
      </AsyncDiv>
    ),
  }

  const totalEthStakedConfig = {
    label: 'Total ETH staked',
    value: (
      <AsyncDiv loading={!summary.totalStaked}>
        {() => summary.totalStaked}
      </AsyncDiv>
    ),
  }

  const stakersConfig = {
    label: 'Stakers',
    value: (
      <AsyncDiv loading={!summary.numStakers}>
        {() => summary.numStakers}
      </AsyncDiv>
    ),
  }

  const swEthMarketCapConfig = {
    label: 'swETH market cap',
    value: (
      <AsyncDiv loading={!summary.marketCap}>
        {() => `${summary.marketCap} USD`}
      </AsyncDiv>
    ),
  }

  const keyValueListConfig = {
    list: [
      commissionRateConfig,
      totalEthStakedConfig,
      stakersConfig,
      swEthMarketCapConfig,
    ],
  }

  return (
    <>
      <KeyValueList config={keyValueListConfig} />
      <ActivityContainer>
        <StakingPoolActivityV2
          title={'Staking pool activity'}
          activityCounts={activityCounts}
          activityItems={activityItems}
          activityInputs={activityInputs}
          token={lstToken}
        />
      </ActivityContainer>
    </>
  )
}

export function SwellStatisticsV2({
  summary,
  activityCounts,
  activityItems,
  activityInputs,
  lstToken,
}: {
  summary: RelevantSummary
  activityItems: ActivitySummaryItems | undefined
  activityCounts: ActivitySummaryCounts | undefined
  activityInputs: StakingPoolActivityInputs
  lstToken: { logoURI: string }
}) {
  const [open, setOpen] = useState<boolean>(false)

  const accordionConfig = {
    summary: <SwellStatisticAccordionSummary open={open} />,
    details: (
      <SwellStatisticAccordionDetails
        summary={summary}
        activityCounts={activityCounts}
        activityItems={activityItems}
        activityInputs={activityInputs}
        lstToken={lstToken}
      />
    ),
  }

  function handleChange() {
    if (!open) {
      setOpen(true)
    } else {
      setOpen(false)
    }
  }

  return <Accordion config={accordionConfig} onChange={handleChange} />
}
