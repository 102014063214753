import { BigNumber, ethers } from 'ethers'

export const CrossChainBridgeProvider = {
  unspecified: 'unspecified' as const,
  lifi: 'lifi' as const,
  bungee: 'bungee' as const,
  native: 'native' as const,
  layerZero: 'layerZero' as const,
}
export type CrossChainBridgeProvider =
  (typeof CrossChainBridgeProvider)[keyof typeof CrossChainBridgeProvider]

export type BridgeProvider = {
  id: CrossChainBridgeProvider
  name: string
  logoURI: string
  logoShape: 'circle' | 'square'
}

export type CrossChainQuote = {
  estTimeMs: number
  gasCostUsd: number
  rate: BigNumber

  fromChainId: number
  toChainId: number

  fromAmount: BigNumber
  fromDecimals: number
  fromAmountUsd: number
  fromTokenSymbol: string

  toAmount: BigNumber
  toDecimals: number
  toAmountUsd: number
  toTokenSymbol: string

  approveAddress: string
}

export type CrossChainQuoteWithTx = CrossChainQuote & {
  transactionRequest: ethers.providers.TransactionRequest
}

type CrossChainTxStateBase = {
  account: string
  provider: string
  timestampMs: number

  fromChainId: number
  toChainId: number

  fromTokenSymbol: string
  toTokenSymbol: string
  fromAmount: BigNumber
  toAmount: BigNumber
  fromDecimals: number
  toDecimals: number

  url: string
}
export type CrossChainTxState = CrossChainTxStateBase &
  (
    | {
        status: 'confirmed' | 'pending'
        error?: undefined
      }
    | {
        status: 'error' | 'refund' | 'unknown'
        error?: string
      }
  )

export type BalanceRecord = {
  address: string
  chainId: number
  balance: BigNumber
}
export type TokenBalanceMap = {
  [address: string]: BigNumber
}

type Resultify<T> = T extends Record<infer Keys, any>
  ? {
      [key in Keys]:
        | { loaded: true; result: T[key] }
        | { loaded: false; result?: undefined }
    }
  : never
export type TokenBalanceResultMap = Resultify<TokenBalanceMap>
export type CrossChainTokenBalances = {
  [chainId: number]: Partial<TokenBalanceResultMap>
}
