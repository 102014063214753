import { useWeb3Button } from '@/hooks/useWeb3Button'
import { ClaimMerkl, UnlockWSwell } from '@/state/wswell/hooks'
import { Button, ButtonProps } from '@/swell-ui/Button'
import { CircularProgress } from '@/swell-ui/CircularProgress'
import React from 'react'
import styled from 'styled-components'
import { PreparedClaimWSwell, PreparedUnlockWSwell } from './wswellCalls'
import { useSwellWeb3 } from '@/swell-web3/core'
import useChainDetection from '@/hooks/useChainDetection'
import { css } from 'styled-components'

const StyledButton = styled(Button)`
  border-radius: 100px;

  ${(props) => {
    if (props.variant === 'primary') return
    return css`
      border: 1px solid var(--Swell-White-50, #fff);
    `
  }}

  /* width: 163px; */
  height: 34px;
  white-space: nowrap;

  display: flex;
  padding: 6px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  color: var(--Swell-White-50, #fff);
  text-align: center;
  /* Body/small */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 22.4px */
  letter-spacing: -0.42px;
`

export function WSwellButton(
  props: Omit<ButtonProps, 'variant'> & {
    minWidth?: string
  }
) {
  return (
    <StyledButton
      variant="secondary"
      onClick={props.onClick}
      disabled={props.disabled}
      style={{
        minWidth: props.minWidth ?? '163px',
      }}
    >
      {props.children}
    </StyledButton>
  )
}

const StyledButtonPrimary = styled(Button)`
  border-radius: 100px;
  height: 42px;

  white-space: nowrap;
  color: var(--Swell-White-50, #fff);
  text-align: center;
  /* Body/medium */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 25.6px */
  letter-spacing: -0.48px;

  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
`

export function WSwellRoundPrimary(
  props: Omit<ButtonProps, 'variant'> & {
    width?: string
  }
) {
  return (
    <StyledButtonPrimary
      variant="primary"
      onClick={props.onClick}
      disabled={props.disabled}
      style={props.style}
    >
      {props.children}
    </StyledButtonPrimary>
  )
}

export function WSwellSmallRoundPrimary(
  props: Omit<ButtonProps, 'variant'> & {
    width?: string
  }
) {
  return (
    <StyledButton
      variant="primary"
      onClick={props.onClick}
      disabled={props.disabled}
      style={props.style}
    >
      {props.children}
    </StyledButton>
  )
}

export function WSwellConnectWallet() {
  const { connect } = useSwellWeb3()
  return (
    <WSwellRoundPrimary
      onClick={() => {
        connect()
      }}
      width="182px"
    >
      Connect Wallet
    </WSwellRoundPrimary>
  )
}

export function WSwellSwitchChain() {
  const { switchToL2DeploymentChain } = useChainDetection()
  return (
    <WSwellRoundPrimary
      onClick={() => {
        switchToL2DeploymentChain()
      }}
      width="182px"
    >
      Switch to Swellchain
    </WSwellRoundPrimary>
  )
}

const ButtonCircularProgress = styled(CircularProgress)`
  &.MuiCircularProgress-colorPrimary {
    transform: scale(0.75);
    color: currentColor;
  }
`
const ButtonInner = styled.span`
  margin: 0 24px;
  position: relative;
  > div {
    position: absolute;
    top: -7px;
    left: -32px;
  }
`

export function WSWELLClaimButton({
  claimWSwell,
  prepared,
  preventInteraction,
}: {
  claimWSwell: ClaimMerkl
  prepared: PreparedClaimWSwell
  preventInteraction: boolean
}) {
  const btn = useWeb3Button(claimWSwell, claimWSwell.call, prepared.args, {
    fulfilledLabel: 'Claimed',
    pendingLabel: (
      <ButtonInner>
        <div>
          <ButtonCircularProgress size={24} />
        </div>
        <span>Confirming...</span>
      </ButtonInner>
    ),
    idleLabel: 'Claim',
  })

  return (
    <WSwellSmallRoundPrimary
      onClick={btn.onClick}
      disabled={btn.disabled || preventInteraction}
      style={{ flex: '0 0 auto' }}
    >
      {btn.label}
    </WSwellSmallRoundPrimary>
  )
}

export function WSWELLUnlockButton({
  unlockWSwell,
  prepared,
  preventInteraction,
  isMatured,
  isEagerLock,
}: {
  prepared: PreparedUnlockWSwell
  unlockWSwell: UnlockWSwell
  preventInteraction: boolean
  isMatured: boolean
  isEagerLock: boolean
}) {
  const btn = useWeb3Button(unlockWSwell, unlockWSwell.call, prepared.args, {
    fulfilledLabel: 'Unlocked',
    pendingLabel: (
      <ButtonInner>
        <div>
          <ButtonCircularProgress size={24} />
        </div>
        <span>Confirming...</span>
      </ButtonInner>
    ),
    idleLabel: 'Unlock',
  })

  const disabled = btn.disabled || preventInteraction || isEagerLock
  if (!isMatured) {
    return (
      <WSwellButton onClick={btn.onClick} disabled={disabled}>
        {btn.label}
      </WSwellButton>
    )
  }

  return (
    <WSwellSmallRoundPrimary
      onClick={btn.onClick}
      disabled={disabled}
      style={{
        height: '34px',
        width: '100%',
      }}
    >
      {btn.label}
    </WSwellSmallRoundPrimary>
  )
}

export function DisabledWSWELLUnlockButton() {
  return <WSwellSmallRoundPrimary disabled>Unlock</WSwellSmallRoundPrimary>
}
