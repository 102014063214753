import { BigNumber } from 'ethers'

export const SwellchainIncentiveId = {
  SwellchainIncentive2024Launch: 'swellchain-incentive-2024-launch' as const,
  Wavedrop3Part2: 'wavedrop-3-part-2' as const,
  Wavedrop3Part3: 'wavedrop-3-part-3' as const,
}

export type SwellchainIncentiveId =
  (typeof SwellchainIncentiveId)[keyof typeof SwellchainIncentiveId]

export type SwellchainIncentive = {
  id: SwellchainIncentiveId
  name: string
  targetDateMs: number
  swellToDistribute: BigNumber
  noReveal?: boolean
}
