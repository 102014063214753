/**
 * Copied: https://github.com/Uniswap/interface/blob/ad2472eac638b389316ba1f3c3f1ed08fbbb12cd/src/state/application/reducer.ts
 * Changed:
 * - Remove concepts related to popups
 * - Uniswap had more items in ApplicationModal enum; kept only wallet related
 */

import { createSlice } from '@reduxjs/toolkit'

export enum ApplicationModal {
  WALLET,
  WALLET_DROPDOWN,
}

interface ApplicationState {
  readonly chainId: number | null
  readonly openModal: ApplicationModal | null
  readonly bridgeProvider: string
}

const initialState: ApplicationState = {
  chainId: null,
  openModal: null,
  bridgeProvider: '',
}

const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    updateChainId(state, action) {
      const { chainId } = action.payload
      state.chainId = chainId
    },
    setOpenModal(state, action) {
      state.openModal = action.payload
    },
    setBridgeProvider(state, action) {
      state.bridgeProvider = action.payload
    },
  },
})

export const { updateChainId, setOpenModal, setBridgeProvider } =
  applicationSlice.actions
export default applicationSlice.reducer
