import { FlexRow } from '@/swell-ui/FlexRow'
import styled from 'styled-components'
import { NavigateBuildButton } from '../../SwellchainButtons'
import { L2PromoBuiltWith } from '@/state/l2promotions/types'
import { ThemeData } from '@/swell-ui/theme/branding'
import { L2BridgeBreakpoints } from '../../styles'

export function BuiltWithSection({
  l2BuiltWith: items,
}: {
  l2BuiltWith: L2PromoBuiltWith[] | undefined
}) {
  return (
    <Layout>
      <FlexRow justify="space-between">
        <span className="headlines-h7">Built with</span>
        <NavigateBuildButton />
      </FlexRow>

      <div id={'lpsbox'}>
        <div className={'lpsbox'}>
          <span
            className="uppercase-medium-white"
            style={{ textAlign: 'center' }}
          >
            Network infrastructure
          </span>
          <div>
            {items?.map((item) => (
              <BuiltWith key={item.name} {...item} />
            ))}
          </div>
        </div>
      </div>
    </Layout>
  )
}

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  #lpsbox {
    border-radius: 16px;
    border: 1px solid rgba(164, 171, 241, 0.2);
    background: ${ThemeData.Swell.SwellOceanBlue150};
    backdrop-filter: blur(2px);
    padding: 42px;

    display: flex;
    flex-direction: column;
    gap: 42px;

    .lpsbox {
      max-width: 848px;
      margin: 0 auto;

      display: flex;
      flex-direction: column;
      gap: 24px;
      justify-content: center;
      align-items: stretch;

      > span {
        align-self: center;
      }
      > div {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        gap: 32px 16px;
        @media (max-width: 768px) {
          gap: 32px 8px;
        }
      }
    }
  }
`

function BuiltWith({
  logoURI,
  logoSquare,
  name,
}: {
  logoURI: string
  logoSquare?: boolean
  name: string
}) {
  return (
    <BWLayout>
      <img
        src={logoURI}
        width="32"
        height="32"
        style={{ borderRadius: logoSquare ? '0' : '50%' }}
      />
      <span>{name}</span>
    </BWLayout>
  )
}
const BWLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 12px;
  padding: 8px;

  width: 120px;

  img {
    width: 60px;
    height: 60px;
    border-radius: 100px;
  }

  span:first-of-type {
    color: var(--Swell-White-150, #818181);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 19.2px */
    letter-spacing: 0.9px;
    text-transform: uppercase;
  }

  span:nth-of-type(2) {
    color: #e6e6e6;
    text-align: center;
    /* Body/small */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 22.4px */
    letter-spacing: -0.42px;
  }
`
