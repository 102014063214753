import { useSwellWeb3 } from '@/swell-web3/core'
import { Multicall3, SwETH__factory, SWExit__factory } from '@/abis/types'
import { ExitAsset, ExitClaim } from '@/types/claims'
import {
  ISwETHApiRead,
  ISwETHApiWrite,
  SwETHState,
  SwETHStats,
  SwETHUser,
  SwETHWithdrawalsUser,
} from './types'
import { useMulticallContract, useSwETHContract } from '@/hooks/useContract'
import { SwETHContext } from './context'
import { useDeploymentSetConfig } from '../deployments/hooks'
import { useV3BackendClient } from '@/services/V3BackendService/hooks'
import { TOKEN_LIST_ETH, TOKEN_LIST_SWETH } from '@/constants/tokens'
import { EXIT_ASSET_SWETH_ETH } from '@/constants/exits'
import { StatsClient } from '@/services/V3BackendService/types'
import { stakeAdapter, SwEXITClaimFetcher } from './util'
import { JsonRpcProvider } from '@ethersproject/providers'
import { parseEther } from 'ethers/lib/utils'
import {
  OrderBy,
  OrderDirection,
  StakeTransactionType,
} from '@/submodules/v3-shared/ts/connect/swell/v3/staker_pb'
import { Token } from '@/types/tokens'
import { getChainInfo } from '@/constants/chainInfo'
import { BigNumber } from 'ethers'
import axios from 'axios'

const STAKES_PER_PAGE = 9

export interface IExitClaimFetcher {
  fetchClaim: (requestId: number) => Promise<ExitClaim>
}

export function useSwETHApiImpl(): SwETHContext {
  const nativeCurrency = TOKEN_LIST_ETH
  const swETHToken = TOKEN_LIST_SWETH
  const exitSwETHETH = EXIT_ASSET_SWETH_ETH

  return {
    nativeCurrency,
    swETHToken,
    exitSwETHETH,
    read: useSwETHApiReadContractImpl({
      exitSwETHETH,
      nativeCurrency,
      swETHToken,
    }),
    write: useSwETHApiWriteContractImpl({ exitSwETHETH }),
  }
}

async function fetchState({
  multicall,
  swETHAddress,
}: {
  swETHAddress: string
  multicall: Multicall3
}): Promise<SwETHState> {
  const calls: Multicall3.Call3Struct[] = [
    {
      target: swETHAddress,
      allowFailure: false,
      callData: SwETH__factory.createInterface().encodeFunctionData(
        'nodeOperatorRewardPercentage'
      ),
    },
    {
      target: swETHAddress,
      allowFailure: false,
      callData: SwETH__factory.createInterface().encodeFunctionData(
        'swellTreasuryRewardPercentage'
      ),
    },
    {
      target: swETHAddress,
      allowFailure: false,
      callData:
        SwETH__factory.createInterface().encodeFunctionData('whitelistEnabled'),
    },
  ]

  const [
    nodeOperatorRewardPercentageResult,
    swellTreasuryRewardPercentageResult,
    whitelistEnabledResult,
  ] = await multicall.callStatic.tryAggregate(true, calls)

  const nodeOperatorRewardPercentage =
    SwETH__factory.createInterface().decodeFunctionResult(
      'nodeOperatorRewardPercentage',
      nodeOperatorRewardPercentageResult.returnData
    )[0]
  const swellTreasuryRewardPercentage =
    SwETH__factory.createInterface().decodeFunctionResult(
      'swellTreasuryRewardPercentage',
      swellTreasuryRewardPercentageResult.returnData
    )[0]
  const whitelistEnabled =
    SwETH__factory.createInterface().decodeFunctionResult(
      'whitelistEnabled',
      whitelistEnabledResult.returnData
    )[0]

  return {
    coreMethodsPaused: false, // todo
    withdrawalsPaused: false, // todo
    nodeOperatorRewardPercentage,
    swellTreasuryRewardPercentage,
    whitelistEnabled,
  }
}

async function fetchApr({ v3BackendLstUrl }: { v3BackendLstUrl: string }) {
  const url = new URL(v3BackendLstUrl)
  url.pathname = '/api/tokens/sweth/apr'
  return axios.get<number>(url.toString()).then((res) => res.data)
}

async function fetchStats({
  statsClient,
  v3BackendLstUrl,
}: {
  statsClient: StatsClient
  v3BackendLstUrl: string
}): Promise<SwETHStats> {
  const aprP = fetchApr({ v3BackendLstUrl })
  const { swEthMarketCapCents, stakerCountUsers, totalEthStakedWei } =
    await statsClient.all({})
  const stakingAprPercent = await aprP
  return {
    aprPercent: stakingAprPercent,
    marketCapUsd: parseFloat(swEthMarketCapCents) / 100,
    numStakers: parseInt(stakerCountUsers),
    totalEthStaked: BigNumber.from(totalEthStakedWei),
  }
}

async function fetchUser({
  account,
  multicall,
  swETHAddress,
  swEXITAddress,
}: {
  account: string
  multicall: Multicall3
  swETHAddress: string
  swEXITAddress: string
}): Promise<SwETHUser> {
  const calls: Multicall3.Call3Struct[] = [
    {
      target: swETHAddress,
      allowFailure: false,
      callData: SwETH__factory.createInterface().encodeFunctionData(
        'balanceOf',
        [account]
      ),
    },
    {
      target: swETHAddress,
      allowFailure: false,
      callData: SwETH__factory.createInterface().encodeFunctionData(
        'allowance',
        [account, swEXITAddress]
      ),
    },
    {
      target: swETHAddress,
      allowFailure: false,
      callData: SwETH__factory.createInterface().encodeFunctionData(
        'whitelistedAddresses',
        [account]
      ),
    },
  ]

  const [swETHBalanceResult, swETHAllowanceForExitResult, whitelistedResult] =
    await multicall.callStatic.tryAggregate(true, calls)

  const swETHBalance = SwETH__factory.createInterface().decodeFunctionResult(
    'balanceOf',
    swETHBalanceResult.returnData
  )[0]
  const swETHAllowanceForExit =
    SwETH__factory.createInterface().decodeFunctionResult(
      'allowance',
      swETHAllowanceForExitResult.returnData
    )[0]
  const whitelisted = SwETH__factory.createInterface().decodeFunctionResult(
    'whitelistedAddresses',
    whitelistedResult.returnData
  )[0]

  return {
    swETHAllowanceForExit,
    swETHBalance,
    whitelisted,
  }
}

async function fetchClaim({
  tokenId,
  v3BackendLstUrl,
}: {
  v3BackendLstUrl: string
  tokenId: number
}) {
  const fetcher = new SwEXITClaimFetcher(v3BackendLstUrl)
  return fetcher.fetchClaim(tokenId)
}

async function fetchWithdrawalsUser({
  account,
  swEXITAddress,
  provider,
  multicall,
  v3BackendLstUrl,
}: {
  account: string
  swEXITAddress: string
  provider: JsonRpcProvider
  multicall: Multicall3
  v3BackendLstUrl: string
}): Promise<SwETHWithdrawalsUser> {
  const exitBalanceB = await SWExit__factory.connect(
    swEXITAddress,
    provider
  ).balanceOf(account)
  const exitBalance = exitBalanceB.toNumber()

  const calls: Multicall3.Call3Struct[] = []
  for (let i = 0; i < exitBalance; i++) {
    calls.push({
      target: swEXITAddress,
      allowFailure: false,
      callData: SWExit__factory.createInterface().encodeFunctionData(
        'tokenOfOwnerByIndex',
        [account, i]
      ),
    })
  }

  const results = await multicall.callStatic.tryAggregate(true, calls)

  const tokenIds = results.map((result) => {
    return SWExit__factory.createInterface()
      .decodeFunctionResult('tokenOfOwnerByIndex', result.returnData)[0]
      .toNumber()
  })

  const exitClaims: ExitClaim[] = []
  for (const tokenId of tokenIds) {
    const claim = await fetchClaim({ tokenId, v3BackendLstUrl })
    exitClaims.push(claim)
  }

  return {
    exitClaims,
  }
}

export function useSwETHApiReadContractImpl({
  exitSwETHETH,
  nativeCurrency,
  swETHToken,
}: {
  exitSwETHETH: ExitAsset
  nativeCurrency: Token
  swETHToken: Token
}): ISwETHApiRead {
  const { v3BackendLstUrl } = useDeploymentSetConfig()
  const { account: maybeAccount, provider } = useSwellWeb3()
  const swETH = useSwETHContract()!
  const multicall = useMulticallContract()

  const account = maybeAccount!

  const statsClient = useV3BackendClient(v3BackendLstUrl).v3BackendClient.stats
  const stakerClient =
    useV3BackendClient(v3BackendLstUrl).v3BackendClient.staker

  const primaryChainInfo = getChainInfo(swETHToken.chainId)

  return {
    state: async () => {
      return {
        nodeOperatorRewardPercentage: parseEther('0.05'),
        swellTreasuryRewardPercentage: parseEther('0.05'),
        whitelistEnabled: false,
        coreMethodsPaused: false,
        withdrawalsPaused: false,
      }
    },

    stats: async () => {
      return fetchStats({ statsClient, v3BackendLstUrl })
    },
    user: async () => {
      return fetchUser({
        account,
        multicall,
        swETHAddress: swETH.address,
        swEXITAddress: exitSwETHETH.exitAddress,
      })
    },
    withdrawalsUser: async () => {
      return fetchWithdrawalsUser({
        account,
        swEXITAddress: exitSwETHETH.exitAddress,
        provider,
        multicall,
        v3BackendLstUrl,
      })
    },
    stakingPoolCount: async () => {
      const { count } = await stakerClient.stakes({
        first: 1,
        skip: 0,
        orderBy: OrderBy.TIMESTAMP,
        orderDirection: OrderDirection.DESC,
        stakeTransactionTypes: [StakeTransactionType.STAKE],
      })
      return {
        numResults: count,
        numPages: Math.ceil(count / STAKES_PER_PAGE),
      }
    },
    stakingPoolActivity: async (page) => {
      const { stakes } = await stakerClient.stakes({
        first: STAKES_PER_PAGE,
        skip: STAKES_PER_PAGE * (page - 1),
        orderBy: OrderBy.TIMESTAMP,
        orderDirection: OrderDirection.DESC,
        stakeTransactionTypes: [StakeTransactionType.STAKE],
      })

      return {
        items: stakes.map((stake) => {
          return stakeAdapter(stake, {
            nativeCurrency,
            explorerBaseUrl: primaryChainInfo.explorer,
            swETHToken,
          })
        }),
      }
    },
  }
}
export function useSwETHApiWriteContractImpl({
  exitSwETHETH,
}: {
  exitSwETHETH: ExitAsset
}): ISwETHApiWrite {
  const swEXITAddress = exitSwETHETH.exitAddress
  const swETH = useSwETHContract()!

  return {
    deposit: async ({ nativeCurrencyAmount }, opts) => {
      return swETH.deposit({ value: nativeCurrencyAmount, ...opts })
    },
    depositEstimateGas: async ({ nativeCurrencyAmount }) => {
      return swETH.estimateGas.deposit({ value: nativeCurrencyAmount })
    },
    approveSwETHForWithdrawal: async ({ amount }, opts) => {
      return swETH.approve(swEXITAddress, amount, opts)
    },
    approveSwETHForWithdrawalEstimateGas: async ({ amount }) => {
      return swETH.estimateGas.approve(swEXITAddress, amount)
    },
    createWithdrawRequest: async ({ swETHAmount }, opts) => {
      const rswExit = SWExit__factory.connect(swEXITAddress, swETH.signer)
      return rswExit.createWithdrawRequest(swETHAmount, opts)
    },
    createWithdrawRequestEstimateGas: async ({ swETHAmount }) => {
      const exit = SWExit__factory.connect(swEXITAddress, swETH.signer)
      return exit.estimateGas.createWithdrawRequest(swETHAmount)
    },
    finalizeWithdrawal: async ({ requestId }, opts) => {
      const exit = SWExit__factory.connect(swEXITAddress, swETH.signer)
      return exit.finalizeWithdrawal(requestId, opts)
    },
    finalizeWithdrawalEstimateGas: async ({ requestId }) => {
      const exit = SWExit__factory.connect(swEXITAddress, swETH.signer)
      return exit.estimateGas.finalizeWithdrawal(requestId)
    },
  }
}
