import {
  DEPLOYMENT_NAMES,
  DEPLOYMENT_SETS,
  INITIAL_DEPLOYMENT_SET_NAME,
} from '@/configuration/deploymentSets'
import { DeploymentSet } from '@/types/deployments'

const empty = {
  chainId: 0,
  l2ChainId: 0,
  v3BackendLrtUrl: '',
  v3BackendLstUrl: '',
  v3BackendPublicUrl: '',
  addresses: {
    swETH: '',
    swExit: '',
    depositManager: '',
    nodeOperatorRegistry: '',
    rswETH: '',
    restakingDepositManager: '',
    restakingNodeOperatorRegistry: '',
    zap: '',
    lifi: '',
    multicall: '',
    preDepositStaking: '',
    preDepositZap: '',
    eETH: '',
    weETH: '',
    stETH: '',
    wstETH: '',
    WETH: '',
    paraswapTokenTransferProxy: '',
    liquidUSDVaultTeller: '',
  },
  addressesL2: {
    multicall: '',
  },
  testnets: [],
  // node operators:
  // fork version is derived from chain ID
  nodeOperators: {
    // LST withdrawalAddress.
    withdrawalAddress: '', // only one withdrawal address (DepositManager)
    // LRT operators are assigned one of many withdrawal addresses, validated on the backend
  },
  rpcUrl: {
    mainnet: '',
    goerli: '',
    swell: '',
    swellTestnet: '',
    optimism: '',
    sepolia: '',
    arbitrum: '',
    optimismSepolia: '',
  },
  walletConnectV2: {
    projectId: '',
  },
  staticAirdropUrl: '',
  bridge: {
    superbridgeWidgetUrl: '',
    assets: [],
  },
} as DeploymentSet

export function loadPrimaryDeploymentSet(): DeploymentSet {
  let ds: DeploymentSet
  {
    let idx = -1
    for (let i = 0; i < DEPLOYMENT_NAMES.length; i++) {
      if (DEPLOYMENT_NAMES[i] === INITIAL_DEPLOYMENT_SET_NAME) {
        idx = i
        break
      }
    }

    if (idx === -1) {
      return empty
    }

    ds = DEPLOYMENT_SETS[idx]

    if (!ds) return empty
  }
  return ds
}
