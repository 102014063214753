import {
  NucleusApproveAssetForDeposit,
  NucleusApproveVaultTokenForAtomicQueue,
  NucleusCancelWithdraw,
  NucleusDeposit,
  NucleusRequestWithdraw,
} from '@/state/nucleusVault/hooks'
import {
  NucleusSharesState,
  NucleusVaultAuth,
  NucleusVaultStateDeposit,
  NucleusVaultStateWithdraw,
} from '@/state/nucleusVault/types'
import { Token, TokenKey } from '@/types/tokens'
import { BigNumber } from 'ethers'
import {
  depositAssetMinimumMint,
  makeNucleusWithdrawalRequest,
} from './nucleusConversions'
import { NucleusActiveWithdrawalResult } from './nucleusWithdrawals'
import { NucleusSupportedTokenMap, NucleusVault } from '@/types/nucleus'
import { calculateSolverFee } from '@/util/nucleus'

export const NucleusErrors = {
  UnsupportedChain: 'Unsupported chain',
  AmountMustBeGreaterThanZero: 'Amount must be greater than 0',
  InsufficientBalance: 'Insufficient balance',
  InsufficientAllowance: 'Insufficient allowance',
  InvalidSlippage: 'Invalid slippage',
  UnstakeAmountTooLow: 'Unstake amount too low',
  UnstakeAmountTooHigh: 'Unstake amount too high',
  SharesAreLocked: 'Shares are locked',
  UnsupportedDepositAsset: 'Unsupported deposit asset',
  UnsupportedWithdrawalAsset: 'Unsupported withdrawal asset',
  AlreadyRequestingWithdrawal: 'Already requesting withdrawal',
  NoSolverFeeForAsset: 'No solver fee for asset',
  DepositIsPaused: 'Deposits are paused',
  NotAuthorizedToDeposit: 'Not authorized to deposit',
  InvalidSolverDiscount: 'Invalid solver discount',
  MustWithdrawFromPredeposit: 'Must withdraw from predeposit',
  NotInWithdrawWhitelist: 'Not in withdrawal whitelist',
}

type ValidatedArgs<T> =
  | {
      args?: never
      error: string | null
    }
  | {
      args: T
      error?: never
    }

export function prepareNucleusApproveAssetForDeposit({
  assetAmount,
  assetAddress,
  assetBalance,
}: {
  assetAmount: BigNumber | undefined
  assetAddress?: string
  assetBalance: BigNumber | undefined
}): ValidatedArgs<Parameters<NucleusApproveAssetForDeposit['call']>> {
  if (
    assetAmount === undefined ||
    assetBalance === undefined ||
    !assetAddress
  ) {
    return { error: null }
  }

  if (assetAmount.lte(0)) {
    return { error: NucleusErrors.AmountMustBeGreaterThanZero }
  }
  if (assetAmount.gt(assetBalance)) {
    return { error: NucleusErrors.InsufficientBalance }
  }

  return {
    args: [{ amount: assetAmount, assetAddress }],
  }
}
export type PreparedNucleusApproveAssetForDeposit = ReturnType<
  typeof prepareNucleusApproveAssetForDeposit
>

export function prepareNucleusApproveVaultTokenForAtomicQueue({
  vaultTokenAmount,
  vaultTokenBalance,
}: {
  vaultTokenAmount: BigNumber | undefined
  vaultTokenBalance: BigNumber | undefined
}): ValidatedArgs<Parameters<NucleusApproveVaultTokenForAtomicQueue['call']>> {
  if (vaultTokenAmount === undefined || vaultTokenBalance === undefined) {
    return { error: null }
  }

  if (vaultTokenAmount.lte(0)) {
    return { error: NucleusErrors.AmountMustBeGreaterThanZero }
  }
  if (vaultTokenAmount.gt(vaultTokenBalance)) {
    return { error: NucleusErrors.InsufficientBalance }
  }

  return {
    args: [{ amount: vaultTokenAmount }],
  }
}
export type PreparedNucleusApproveVaultTokenForAtomicQueue = ReturnType<
  typeof prepareNucleusApproveVaultTokenForAtomicQueue
>

export function prepareNucleusDeposit({
  depositAmount,
  depositToken,
  depositAssetBalance,
  depositAssetAllowanceForVault,
  slippage,
  vaultTokenRateInQuote,
  supportedAssets,
  requiresApproval,
  vaultState,
  auth,
  baseAsset,
  chainId: currentChainId,
}: {
  requiresApproval: boolean
  depositToken: Token | undefined
  slippage: number
  depositAmount: BigNumber | undefined
  depositAssetBalance: BigNumber | undefined
  depositAssetAllowanceForVault: BigNumber | undefined
  vaultTokenRateInQuote: BigNumber | undefined
  supportedAssets: NucleusSupportedTokenMap | undefined
  vaultState: NucleusVaultStateDeposit | undefined
  auth: NucleusVaultAuth | undefined
  baseAsset: Token
  chainId: number
}): ValidatedArgs<Parameters<NucleusDeposit['call']>> {
  if (depositToken === undefined) {
    return { error: null }
  }
  if (currentChainId !== depositToken.chainId) {
    return { error: NucleusErrors.UnsupportedChain }
  }

  if (
    depositAmount === undefined ||
    depositAssetBalance === undefined ||
    vaultTokenRateInQuote === undefined ||
    supportedAssets === undefined ||
    vaultState === undefined ||
    auth === undefined
  ) {
    return { error: null }
  }

  if (requiresApproval) {
    if (depositAssetAllowanceForVault === undefined) {
      return { error: null }
    }
  }

  const supportedForChain = supportedAssets[currentChainId]
  if (!supportedForChain) {
    return { error: 'Internal error' }
  }

  if (vaultState.isDepositPaused) {
    return { error: NucleusErrors.DepositIsPaused }
  }
  if (!auth.isAuthorizedToDeposit) {
    return { error: NucleusErrors.NotAuthorizedToDeposit }
  }
  if (!supportedForChain[depositToken.address]?.isSupported) {
    return { error: NucleusErrors.UnsupportedDepositAsset }
  }

  if (slippage < 0 || slippage > 1) {
    return { error: NucleusErrors.InvalidSlippage }
  }
  if (depositAmount.lte(0)) {
    return { error: NucleusErrors.AmountMustBeGreaterThanZero }
  }
  if (depositAmount.gt(depositAssetBalance)) {
    return { error: NucleusErrors.InsufficientBalance }
  }

  if (requiresApproval) {
    if (depositAmount.gt(depositAssetAllowanceForVault!)) {
      return { error: NucleusErrors.InsufficientAllowance }
    }
  }

  const minimumMint = depositAssetMinimumMint({
    slippage,
    toSendAsset: depositAmount,
    vaultTokenRateInQuote,
    base: baseAsset,
  })

  const depositAsset = depositToken.address

  return {
    args: [{ depositAmount, depositAsset, minimumMint }],
  }
}
export type PreparedNucleusDeposit = ReturnType<typeof prepareNucleusDeposit>

export function prepareNucleusRequestWithdraw({
  offerAmount,
  wantAsset,
  vaultTokenBalance,
  sharesState,
  vaultAllowanceForAtomicQueue,
  vaultTokenRateInQuote,
  nowMs,
  activeWithdrawal,
  vaultState,
  baseAsset,
  chainId,
  supportedAssets,
  withdrawWhitelist,
  account,
}: {
  account: string | undefined
  wantAsset: TokenKey
  supportedAssets: NucleusSupportedTokenMap | undefined
  withdrawWhitelist: NucleusVault['withdrawWhitelist'] | undefined
  chainId: number
  baseAsset: Token
  nowMs: number
  offerAmount: BigNumber | undefined
  vaultTokenBalance: BigNumber | undefined
  vaultAllowanceForAtomicQueue: BigNumber | undefined
  sharesState: NucleusSharesState | undefined
  vaultTokenRateInQuote: BigNumber | undefined
  vaultState: NucleusVaultStateWithdraw | undefined
  activeWithdrawal: NucleusActiveWithdrawalResult | undefined
}): ValidatedArgs<Parameters<NucleusRequestWithdraw['call']>> {
  if (chainId !== wantAsset.chainId) {
    return { error: NucleusErrors.UnsupportedChain }
  }
  if (
    offerAmount === undefined ||
    vaultTokenBalance === undefined ||
    vaultAllowanceForAtomicQueue === undefined ||
    sharesState === undefined ||
    vaultTokenRateInQuote === undefined ||
    activeWithdrawal === undefined ||
    vaultState === undefined ||
    supportedAssets === undefined ||
    !account
  ) {
    return { error: null }
  }

  if (!supportedAssets[wantAsset.chainId]?.[wantAsset.address]?.isSupported) {
    return { error: NucleusErrors.UnsupportedWithdrawalAsset }
  }

  const whitelist =
    withdrawWhitelist?.[wantAsset.chainId]?.[wantAsset.address] ?? []
  if (whitelist.length > 0) {
    let found = false
    for (const w of whitelist) {
      if (w.toLowerCase() === account.toLowerCase()) {
        found = true
        break
      }
    }
    if (!found) {
      return { error: NucleusErrors.NotInWithdrawWhitelist }
    }
  }

  const solverFee =
    vaultState.solverFees[wantAsset.chainId]?.[wantAsset.address]
  if (!solverFee) {
    return { error: NucleusErrors.NoSolverFeeForAsset }
  }

  if (
    solverFee.feeLowPercent < 0 ||
    solverFee.feeLowPercent > 100 ||
    solverFee.feeHighPercent < 0 ||
    solverFee.feeHighPercent > 100 ||
    solverFee.feeLowPercent > solverFee.feeHighPercent
  ) {
    return { error: NucleusErrors.InvalidSolverDiscount }
  }

  const sf = calculateSolverFee({
    offerTokenAmount: offerAmount,
    solverFee,
    base: baseAsset,
    vaultTokenRateInQuote: vaultTokenRateInQuote,
  })

  const solverDiscount = sf.feePercent / 100
  const deadlineDurationMs = vaultState.withdrawalProcessingDurationUnix * 1000

  if (activeWithdrawal.status === 'Requesting') {
    return { error: NucleusErrors.AlreadyRequestingWithdrawal }
  }

  if (offerAmount.lte(0)) {
    return { error: NucleusErrors.AmountMustBeGreaterThanZero }
  }
  if (offerAmount.gt(vaultTokenBalance)) {
    return { error: NucleusErrors.InsufficientBalance }
  }
  if (offerAmount.gt(vaultAllowanceForAtomicQueue)) {
    return { error: NucleusErrors.InsufficientAllowance }
  }

  const deadlineUnix = (nowMs + deadlineDurationMs) / 1000
  if (sharesState.shareUnlock.isLocked) {
    if (deadlineUnix < sharesState.shareUnlock.unlockTimeUnix) {
      return { error: NucleusErrors.SharesAreLocked }
    }
  }

  const wantAssetAddress = wantAsset.address
  const withdrawRequest = makeNucleusWithdrawalRequest({
    deadlineUnix,
    solverDiscount,
    toSendVaultToken: offerAmount,
    vaultTokenRateInQuote,
    base: baseAsset,
  })

  return {
    args: [{ withdrawRequest, wantAssetAddress }],
  }
}
export type PreparedNucleusRequestWithdraw = ReturnType<
  typeof prepareNucleusRequestWithdraw
>

export function prepareNucleusCancelWithdraw({
  cancelAsset,
  supportedAssets,
  chainId,
}: {
  cancelAsset: TokenKey | undefined
  supportedAssets: NucleusSupportedTokenMap | undefined
  chainId: number
}): ValidatedArgs<Parameters<NucleusCancelWithdraw['call']>> {
  if (cancelAsset === undefined || supportedAssets === undefined) {
    return { error: null }
  }
  if (chainId !== cancelAsset.chainId) {
    return { error: NucleusErrors.UnsupportedChain }
  }
  if (
    !supportedAssets[cancelAsset.chainId]?.[cancelAsset.address]?.isSupported
  ) {
    return { error: NucleusErrors.UnsupportedWithdrawalAsset }
  }

  return {
    args: [{ wantAssetAddress: cancelAsset.address }],
  }
}
export type PreparedNucleusCancelWithdraw = ReturnType<
  typeof prepareNucleusCancelWithdraw
>
