/* eslint-disable no-empty */
import { PredepositCalls, PredepositTokens } from '@/state/predeposit/hooks'
import { Token } from '@/types/tokens'
import { BigNumber } from 'ethers'
import { parseUnits } from 'ethers/lib/utils'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  CustomBridgeSubView,
  EarnEthWithdrawAndDepositProgress,
  L2PageView,
  PredepositInputs,
  PredepositWithdrawController,
  PredepositWithdrawInitiator,
} from './types'
import useTransactionFee, {
  TransactionFeeResult,
} from '@/hooks/useTransactionFee'
import { getPreDepositWithdrawGasEstimate } from '@/constants/gasEstimates'
import { compareHex } from '@/util/hexStrings'
import { NucleusVaultCalls } from '@/state/nucleusVault/hooks'
import { determineIsEarnEthAsset, earnEthWithdrawAndDepositPhase } from './util'
import { PreparedNucleusDeposit } from '@/components/Nucleus/nucleusCalls'
import { PredepositUser } from '@/state/predeposit/types'
import { depositAssetMinimumMint } from '@/components/Nucleus/nucleusConversions'
import { TOKEN_LIST_ETH } from '@/constants/tokens'
import { NucleusRates, NucleusVault } from '@/types/nucleus'

export function useL2PageView({ defaultView }: { defaultView: L2PageView }) {
  const [selectedView, setSelectedView] = useState<L2PageView>(defaultView)
  const isSuperbridgeView = selectedView === L2PageView.superbridge
  const isCustomBridgeView = selectedView === L2PageView.custombridge
  const isWithdrawView = selectedView === L2PageView.withdraw
  const switchToSuperbridge = () => setSelectedView(L2PageView.superbridge)
  const switchToCustomBridge = () => setSelectedView(L2PageView.custombridge)
  const switchToWithdraw = () => setSelectedView(L2PageView.withdraw)

  return {
    isSuperbridgeView,
    isCustomBridgeView,
    isWithdrawView,
    switchToSuperbridge,
    switchToCustomBridge,
    switchToWithdraw,
  }
}
export type L2PageViewInputs = ReturnType<typeof useL2PageView>

export function useCustomBridgeSubView({
  defaultView,
}: {
  defaultView: CustomBridgeSubView
}) {
  const [selectedView, setSelectedView] =
    useState<CustomBridgeSubView>(defaultView)
  const isBridgeView = selectedView === CustomBridgeSubView.bridge
  const isGasView = selectedView === CustomBridgeSubView.gas
  const isHistoryView = selectedView === CustomBridgeSubView.history
  const switchToBridge = () => setSelectedView(CustomBridgeSubView.bridge)
  const switchToGas = () => setSelectedView(CustomBridgeSubView.gas)
  const switchToHistory = () => setSelectedView(CustomBridgeSubView.history)

  return {
    isBridgeView,
    isGasView,
    isHistoryView,
    switchToBridge,
    switchToGas,
    switchToHistory,
  }
}
export type CustomBridgeSubViewInputs = ReturnType<
  typeof useCustomBridgeSubView
>

export function usePredepositInputs({
  predepositTokens,
}: {
  predepositTokens: PredepositTokens
}): PredepositInputs {
  const [withdrawTokenAddress, setWithdrawTokenAddress] = useState(
    predepositTokens.tokenList.tokens?.[0]?.address
  )
  const selectWithdrawToken = useCallback((address: string) => {
    setWithdrawTokenAddress(address)
  }, [])
  const withdrawToken = predepositTokens.tokenList.tokens.find((t) =>
    compareHex(t.address, withdrawTokenAddress)
  )

  const [withdrawAmountInput, setWithdrawAmountInput] = useState('')
  let withdrawAmount: BigNumber | undefined
  if (withdrawToken && withdrawAmountInput) {
    try {
      withdrawAmount = parseUnits(withdrawAmountInput, withdrawToken.decimals)
    } catch (e) {}
  }

  return {
    selectWithdrawToken,
    setWithdrawAmountInput,
    withdrawAmount,
    withdrawAmountInput,
    withdrawTokenAddress,
    withdrawToken,
  }
}

export function usePredepositWithdrawController({
  nucleusCalls: ogNucleusCalls,
  predepositCalls: ogPredepositCalls,
  preparedDeposit,
  slippage,
  predepositUser,
  predepositInputs,
  doNotVamp,
  earnEthTokens,
  l1Tokens,
  rates,
  vault,
}: {
  preparedDeposit: PreparedNucleusDeposit
  predepositCalls: PredepositCalls
  nucleusCalls: Pick<NucleusVaultCalls, 'approveAssetForDeposit' | 'deposit'>
  slippage: number
  predepositUser: PredepositUser | undefined
  predepositInputs: PredepositInputs
  doNotVamp: Token[]
  earnEthTokens: Token[]
  l1Tokens: Token[]
  rates: NucleusRates | undefined
  vault: NucleusVault
}): PredepositWithdrawController {
  let isEarnEthAsset = false
  if (predepositInputs.withdrawToken) {
    isEarnEthAsset = determineIsEarnEthAsset({
      doNotVamp,
      earnEthTokens,
      l1Tokens,
      tokenAddress: predepositInputs.withdrawToken?.address,
    })
  }

  const phase = earnEthWithdrawAndDepositPhase({
    preparedDeposit,
    predepositUser,
    predepositInputs,
  })
  const [initiator, setInitiator] = useState<PredepositWithdrawInitiator>()
  const [progress, setProgress] = useState<EarnEthWithdrawAndDepositProgress>()
  useEffect(() => {
    setInitiator(undefined)
    setProgress(undefined)
  }, [predepositInputs.withdrawTokenAddress])

  const withdraw = useMemo(
    () => ogPredepositCalls.withdrawFromPredeposit.call,
    [ogPredepositCalls.withdrawFromPredeposit.call]
  )

  const predepositCalls = {
    ...ogPredepositCalls,
    withdrawFromPredeposit: {
      ...ogPredepositCalls.withdrawFromPredeposit,
    },
  }
  predepositCalls.withdrawFromPredeposit.call = (args) => {
    setInitiator('withdraw')
    return withdraw(args)
  }

  const predepositCallsWithNucleus = {
    ...ogPredepositCalls,
    withdrawFromPredeposit: { ...ogPredepositCalls.withdrawFromPredeposit },
  }
  predepositCallsWithNucleus.withdrawFromPredeposit.call = (args) => {
    setInitiator('withdraw-and-deposit')
    setProgress({ numSteps: 3, currentStepIndex: 0 })
    return withdraw(args)
  }

  const approve = useMemo(
    () => ogNucleusCalls.approveAssetForDeposit.call,
    [ogNucleusCalls.approveAssetForDeposit.call]
  )
  const deposit = useMemo(
    () => ogNucleusCalls.deposit.call,
    [ogNucleusCalls.deposit.call]
  )

  const nucleusCalls = {
    ...ogNucleusCalls,
    approveAssetForDeposit: {
      ...ogNucleusCalls.approveAssetForDeposit,
    },
    deposit: {
      ...ogNucleusCalls.deposit,
    },
  }

  nucleusCalls.approveAssetForDeposit.call = (args) => {
    setInitiator('withdraw-and-deposit')
    setProgress({ numSteps: 3, currentStepIndex: 1 })
    return approve(args)
  }
  nucleusCalls.deposit.call = (args) => {
    setInitiator('withdraw-and-deposit')
    setProgress({ numSteps: 3, currentStepIndex: 2 })
    return deposit(args)
  }

  let error = predepositCalls.withdrawFromPredeposit.error
  if (!error) {
    error = nucleusCalls.approveAssetForDeposit.error
  }

  const lastWithdrawFromPredepositStatus = useRef(
    predepositCalls.withdrawFromPredeposit.status
  )

  useEffect(() => {
    const prevWithdrawFromPredepositStatus =
      lastWithdrawFromPredepositStatus.current
    const withdrawFromPredepositStatus =
      ogPredepositCalls.withdrawFromPredeposit.status

    lastWithdrawFromPredepositStatus.current = withdrawFromPredepositStatus

    const justSucceededWithdraw =
      prevWithdrawFromPredepositStatus !== 'fulfilled' &&
      withdrawFromPredepositStatus === 'fulfilled'

    if (initiator !== 'withdraw-and-deposit') {
      return
    }

    if (!justSucceededWithdraw) {
      return
    }
    const args = ogPredepositCalls.withdrawFromPredeposit.args
    if (!args) {
      console.error('missing args withdrawFromPredeposit', { args })
      return
    }
    ogNucleusCalls.approveAssetForDeposit.call({
      amount: args[0].amount,
      assetAddress: args[0].assetAddress,
    })
    ogPredepositCalls.withdrawFromPredeposit.clear()
  }, [
    initiator,
    ogNucleusCalls.approveAssetForDeposit,
    ogPredepositCalls.withdrawFromPredeposit,
  ])

  const lastApproveAssetForDepositStatus = useRef(
    nucleusCalls.approveAssetForDeposit.status
  )

  const vaultChainId = vault.vaultToken.chainId

  useEffect(() => {
    if (!rates) return

    const prevApproveAssetForDepositStatus =
      lastApproveAssetForDepositStatus.current
    const approveAssetForDepositStatus =
      ogNucleusCalls.approveAssetForDeposit.status

    lastApproveAssetForDepositStatus.current = approveAssetForDepositStatus

    const justSucceededApprove =
      prevApproveAssetForDepositStatus !== 'fulfilled' &&
      approveAssetForDepositStatus === 'fulfilled'

    if (initiator !== 'withdraw-and-deposit') {
      return
    }

    if (!justSucceededApprove) {
      return
    }
    const args = ogNucleusCalls.approveAssetForDeposit.args
    if (!args) {
      return
    }

    const vaultTokenRateInQuote =
      rates.vaultTokenQuoteRates?.[vaultChainId]?.[args[0].assetAddress]
    if (!vaultTokenRateInQuote) {
      return
    }

    const minimumMint = depositAssetMinimumMint({
      slippage,
      toSendAsset: args[0].amount,
      vaultTokenRateInQuote,
      base: TOKEN_LIST_ETH,
    })

    ogNucleusCalls.deposit.call({
      depositAmount: args[0].amount,
      depositAsset: args[0].assetAddress,
      minimumMint: minimumMint,
    })
    ogNucleusCalls.approveAssetForDeposit.clear()
  }, [
    initiator,
    ogNucleusCalls.approveAssetForDeposit,
    ogNucleusCalls.deposit,
    rates,
    slippage,
    vaultChainId,
  ])

  return {
    initiator,
    progress,
    nucleusCalls,
    phase,
    predepositCalls,
    predepositCallsWithNucleus,
    isEarnEthAsset,
  }
}

export function useResetPredepositInputsOnTokenChange({
  predepositInputs,
}: {
  predepositInputs: PredepositInputs
}) {
  const { withdrawTokenAddress, setWithdrawAmountInput } = predepositInputs

  useEffect(() => {
    setWithdrawAmountInput('')
  }, [setWithdrawAmountInput, withdrawTokenAddress])
}

export function useSwellchainLiveness({
  mutatePredepositUser,
  predepositCalls,
  nucleusCalls,
  mutateNucleus,
}: {
  mutatePredepositUser: () => void
  mutateNucleus: () => void
  predepositCalls: PredepositCalls
  nucleusCalls: NucleusVaultCalls
}) {
  const lastMutatedPredeposit = useRef(0)
  useEffect(() => {
    if (
      predepositCalls.withdrawFromPredeposit.status ===
      predepositCalls.withdrawFromPredeposit.STATUS.FULFILLED
    ) {
      if (Date.now() - lastMutatedPredeposit.current > 12_000) {
        lastMutatedPredeposit.current = Date.now()
        mutatePredepositUser()
        mutateNucleus()
      }
    }
  }, [
    mutateNucleus,
    mutatePredepositUser,
    predepositCalls.withdrawFromPredeposit.STATUS.FULFILLED,
    predepositCalls.withdrawFromPredeposit.status,
  ])

  const lastMutatedNucleus = useRef(0)
  useEffect(() => {
    if (
      nucleusCalls.approveAssetForDeposit.status ===
      nucleusCalls.approveAssetForDeposit.STATUS.FULFILLED
    ) {
      if (Date.now() - lastMutatedNucleus.current > 12_000) {
        lastMutatedNucleus.current = Date.now()
        mutateNucleus()
      }
    }
  }, [
    mutateNucleus,
    nucleusCalls.approveAssetForDeposit.STATUS.FULFILLED,
    nucleusCalls.approveAssetForDeposit.status,
  ])
  useEffect(() => {
    if (nucleusCalls.deposit.status === nucleusCalls.deposit.STATUS.FULFILLED) {
      if (Date.now() - lastMutatedNucleus.current > 12_000) {
        lastMutatedNucleus.current = Date.now()
        mutateNucleus()
      }
    }
  }, [
    mutateNucleus,
    nucleusCalls.deposit.STATUS.FULFILLED,
    nucleusCalls.deposit.status,
  ])
}

export function usePredepositTransactionFees():
  | TransactionFeeResult
  | undefined {
  const depositTransactionFee = useTransactionFee({
    gasAmount: getPreDepositWithdrawGasEstimate().toNumber(),
  })

  return depositTransactionFee.data
}
