// @generated by protoc-gen-connect-es v1.1.2 with parameter "target=ts"
// @generated from file swell/v3/bungeebridge.proto (package swell.v3, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { BungeeBridgeQuoteRequest, BungeeBridgeQuoteResponse, BungeeBridgeTxStatusRequest, BungeeBridgeTxStatusResponse } from "./bungeebridge_pb.js";
import { MethodIdempotency, MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service swell.v3.BungeeBridgeService
 */
export const BungeeBridgeService = {
  typeName: "swell.v3.BungeeBridgeService",
  methods: {
    /**
     * @generated from rpc swell.v3.BungeeBridgeService.Quote
     */
    quote: {
      name: "Quote",
      I: BungeeBridgeQuoteRequest,
      O: BungeeBridgeQuoteResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc swell.v3.BungeeBridgeService.TxStatus
     */
    txStatus: {
      name: "TxStatus",
      I: BungeeBridgeTxStatusRequest,
      O: BungeeBridgeTxStatusResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.Idempotent,
    },
  }
} as const;

