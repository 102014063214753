import useSWRImmutable from 'swr/immutable'
import useSWRSubscription from 'swr/subscription'
import { useEarnApi } from './context'
import { useSwellWeb3 } from '@/swell-web3/core'
import { SortingDirection } from '@/types/sorting'
import {
  UserEarnPosition,
  EarnPositionBalanceMap,
  UserEarnCampaign,
} from '@/types/earn'
import {
  buildSortedFilteredCampaigns,
  buildEarnPositions,
} from '../../util/earn'
import { useMemo } from 'react'
import {
  EarnCampaignsFilters,
  EarnCampaignsOrderBy,
  EarnPositionFilters,
  EarnPositionsOrderBy,
} from '@/types/portfolioFilters'
import { solidityKeccak256 } from 'ethers/lib/utils'
import { useRefreshRate } from '@/hooks/useMath'
import ms from 'ms.macro'
import cloneDeep from 'lodash/cloneDeep'
import { Token } from '@/types/tokens'
import { EigenLayerStakedropResult } from '../rsweth/types'

export function usePortfolioSettings() {
  const api = useEarnApi()
  const {
    filterChainOptions,
    filterTokenOptions,
    pointsCampaigns,
    rewardAssets,
    merklBaseUrl,
  } = api

  return {
    filterChainOptions,
    filterTokenOptions,
    pointsCampaigns,
    rewardAssets,
    merklBaseUrl,
  }
}
export function useLegacyPointsResults() {
  const api = useEarnApi()
  const { account } = useSwellWeb3()

  return useSWRImmutable(
    account ? ['portfoliov2', 'legacyPointsResults', account] : null,
    api.legacyPointsResults
  )
}

export function usePortfolioPositions({
  filters,
  orderBy,
  orderDirection,
}: {
  filters: EarnPositionFilters
  orderBy: EarnPositionsOrderBy
  orderDirection: SortingDirection
}) {
  const { account } = useSwellWeb3()
  const api = useEarnApi()
  const r = useRefreshRate({ rate: ms`4000ms` }) // dont trust swr

  const globalPositionsQuery = useSWRImmutable(
    ['portfoliov2', 'positions'],
    api.positions
  )

  const sub = useSWRSubscription(
    account ? ['portfoliov2', 'positionBalances', account] : null,
    (__, { next }) => {
      const ac = new AbortController()
      const stream = api.positionBalances(ac.signal)
      ;(async () => {
        try {
          for await (const data of stream) {
            next(null, data)
          }
        } catch (e) {
          console.error('position balances', e)
          next(e, null)
        }
      })()
      return () => ac.abort()
    }
  )

  const globalPositionMap = globalPositionsQuery.data
  const positionBalanceMap: EarnPositionBalanceMap = cloneDeep(sub.data ?? {})

  const availableTokenSymbolsForFilter = api.filterTokenOptions.map(
    (t) => t.symbol
  )

  // react doesn't work with deep objects, so we need to hash it manually
  const hash = solidityKeccak256(
    ['string'],
    [
      JSON.stringify({
        positionBalanceMap,
        filters,
        globalPositionMap,
        orderBy,
        orderDirection,
        availableTokenSymbolsForFilter,
        r,
      }),
    ]
  )

  const data = useMemo<UserEarnPosition[] | undefined>(() => {
    if (!globalPositionMap) {
      return undefined
    }

    return buildEarnPositions({
      positionBalanceMap,
      filters,
      globalPositionMap,
      orderBy,
      orderDirection,
      availableTokenSymbolsForFilter,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps -- manually computed
  }, [hash])

  const dataUnfiltered = useMemo<UserEarnPosition[] | undefined>(() => {
    if (!globalPositionMap) {
      return undefined
    }

    return buildEarnPositions({
      positionBalanceMap,
      filters: {
        chainIds: undefined,
        hideZeroBalances: false,
        includeTokenSymbols: undefined,
        searchTerm: undefined,
      },
      globalPositionMap,
      orderBy,
      orderDirection,
      availableTokenSymbolsForFilter,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps -- manually computed
  }, [hash])

  return {
    error: globalPositionsQuery.error || sub.error,
    isLoading: globalPositionsQuery.isLoading,
    data,
    isValidating: globalPositionsQuery.isValidating,
    dataUnfiltered,
  }
}

export function usePortfolioCampaigns({
  filters,
  orderBy,
  orderDirection,
  positions,
  eigenToken,
  eigenStakedropResult,
}: {
  filters: EarnCampaignsFilters
  orderBy: EarnCampaignsOrderBy
  orderDirection: SortingDirection
  positions: UserEarnPosition[] | undefined
  eigenToken: Token
  eigenStakedropResult: EigenLayerStakedropResult | undefined
}) {
  const api = useEarnApi()
  const { preconfiguredCampaigns, rewardAssets, latestSwellCampaign } = api
  const r = useRefreshRate({ rate: ms`4000ms` }) // dont trust swr

  const legacyPointsResultsQuery = useLegacyPointsResults()
  const legacyPointsResults = legacyPointsResultsQuery.data

  // react doesn't work with deep objects, so we need to hash it manually
  const hash = solidityKeccak256(
    ['string'],
    [
      JSON.stringify({
        filters,
        orderBy,
        orderDirection,
        positions,
        legacyPointsResults,
        preconfiguredCampaigns,
        rewardAssets,
        latestSwellCampaign,
        eigenStakedropResult,
        r,
      }),
    ]
  )
  const data = useMemo<UserEarnCampaign[] | undefined>(() => {
    return buildSortedFilteredCampaigns({
      positions,
      filters,
      orderDirection,
      orderBy,
      preconfiguredCampaigns,
      legacyPointsResults,
      rewardAssets,
      latestSwellCampaign,
      eigenStakedropResult,
      eigenToken,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps -- manually computed
  }, [hash])

  return {
    error: legacyPointsResultsQuery.error,
    isLoading: legacyPointsResultsQuery.isLoading,
    data,
    isValidating: legacyPointsResultsQuery.isValidating,
  }
}
