import {
  UserEarnPosition,
  UserEarnCampaign,
  EarnRewardAsset,
  EarnPointsCampaign,
} from '@/types/earn'
import {
  PortfolioDefiPositionSummary,
  PortfolioDefiPositionSummary_Reward,
  UserCampaignsSummary,
  UserCampaignSummary,
  UserPortfolioSummary,
} from './types'
import { displayFiat } from '@/util/displayFiat'
import { formatWithConfig, formatWithDynamicPrecision } from '@/util/number'
import { getChainIcon, getChainName } from '@/constants/chainInfo'
import { EarnHoldingsResults } from './util'
import { displayRangeAPR } from '@/util/apr'
import { TOKEN_LIST_SWELL } from '@/constants/tokens'
import { displayPearls } from '@/util/displayNumbers'

export function makeUserPortfolioSummary({
  holdings,
  account,
}: {
  holdings: EarnHoldingsResults
  account?: string
}): UserPortfolioSummary {
  if (!account) {
    return {
      campaignParticipation: '-',
      numberOfAssetsHeld: '-',
      totalAssetBalance: '-',
    }
  }
  const resp: UserPortfolioSummary = {
    campaignParticipation: '',
    numberOfAssetsHeld: '',
    totalAssetBalance: '',
  }

  const { numAssetsHeld, numParticipatingCampaigns, totalAssetBalanceUsd } =
    holdings

  if (numAssetsHeld.loaded) {
    resp.numberOfAssetsHeld = formatWithConfig(numAssetsHeld.result, {
      localize: true,
      precision: 0,
    })
  }
  if (numParticipatingCampaigns.loaded) {
    resp.campaignParticipation = formatWithConfig(
      numParticipatingCampaigns.result,
      {
        localize: true,
        precision: 0,
      }
    )
  }
  if (totalAssetBalanceUsd.loaded) {
    resp.totalAssetBalance = displayFiat(totalAssetBalanceUsd.result, false)
  }

  return resp
}

export function makeUserCampaignsSummary({
  account,
  campaigns,
  rewardAssets,
  pointsCampaigns,
  merklBaseUrl,
}: {
  account: string | undefined
  campaigns: UserEarnCampaign[] | undefined
  rewardAssets: EarnRewardAsset[]
  pointsCampaigns: EarnPointsCampaign[]
  merklBaseUrl: string
}): UserCampaignsSummary {
  const res: UserCampaignsSummary = {
    campaigns: [],
  }
  for (const c of campaigns ?? []) {
    const cSummary: UserCampaignSummary = {
      _campaignId: c.campaignId,
      campaignName: c.campaignName,
      campaignLogoURI: c.logoURI,
      campaignSquareLogo: c.squareLogo,
      claimURL: '',
      showDisabledClaimLink: false,
      externalClaimLink: false,

      claimable: '-',
      claimableAssetName: '',
      claimableAssetLogoURI: '',
      claimableNum: 0,
      claimableAssetSquareLogo: false,

      points: '-',
      pointsNum: 0,
      pointsName: '',
      pointsLogoURI: '',
      pointsSquareLogo: false,
    }
    if (c.claimSpec.type === 'url') {
      cSummary.claimURL = c.claimSpec.url
    }
    if (c.claimSpec.type === 'notAvailable') {
      cSummary.showDisabledClaimLink = true
    }
    if (c.claimSpec.type === 'merkl') {
      cSummary.showDisabledClaimLink = true
      cSummary.externalClaimLink = true
      if (account) {
        const u = new URL(merklBaseUrl)
        u.pathname = `/user/${account}`
        cSummary.claimURL = u.toString()

        if (cSummary.claimableNum > 0) {
          cSummary.showDisabledClaimLink = false
        }
      }
    }

    if (!c.claimableAssetId) {
      cSummary.claimable = 'N/A'
      cSummary.claimableNum = -1
    } else {
      const asset = rewardAssets.find((a) => a.assetId === c.claimableAssetId)
      if (asset) {
        cSummary.claimableAssetName = asset.assetName
        cSummary.claimableAssetLogoURI = asset.logoURI
        cSummary.claimableAssetSquareLogo = asset.squareLogo
      }
      if (c.userClaimableAssetsCumulative.exists) {
        cSummary.claimable = displayPearls(
          c.userClaimableAssetsCumulative.amount
        )
        cSummary.claimableNum = c.userClaimableAssetsCumulative.amount
      } else {
        if (account) {
          cSummary.claimable = '' // loading
        }
      }
    }

    if (!c.pointsId) {
      cSummary.points = 'N/A'
      cSummary.pointsNum = -1
    } else {
      const pc = pointsCampaigns.find((p) => p.pointsId === c.pointsId)
      if (pc) {
        cSummary.pointsName = pc.pointsName
        cSummary.pointsLogoURI = pc.logoURI
        cSummary.pointsSquareLogo = pc.squareLogo
      }
      if (c.userPoints.exists) {
        cSummary.points = displayPearls(c.userPoints.amount)
        cSummary.pointsNum = c.userPoints.amount
      } else {
        if (account) {
          cSummary.points = '' // loading
        }
      }
    }

    res.campaigns.push(cSummary)
  }

  if (!campaigns) {
    if (!account) {
      return res
    }

    for (const c of res.campaigns) {
      c.claimable = ''
      c.points = ''
    }
  }

  return res
}

export function makePortfolioDefiPosition({
  position,
  account,
  rewardAssets,
}: {
  position: UserEarnPosition
  account: string | undefined
  rewardAssets: EarnRewardAsset[]
}): PortfolioDefiPositionSummary {
  type UserComponent = Pick<
    PortfolioDefiPositionSummary,
    'rewardResult' | 'rewardsNum' | 'balance' | 'balanceNum'
  >
  type StaticComponent = Omit<PortfolioDefiPositionSummary, keyof UserComponent>

  const chainName = getChainName(position.chainId)
  const chainIcon = getChainIcon(position.chainId)

  const isPositionWithRewards = position.rewardAssetIds.length > 0

  const apr = displayRangeAPR(position.apr)
  const aprNum =
    position.apr.type === 'range'
      ? position.apr.basePercent
      : position.apr.aprPercent

  let assetSymbol = ''
  let assetLogoURI = ''
  if (position.assets.length > 0) {
    assetSymbol = position.assets[0].symbol
    assetLogoURI = position.assets[0].logoURI
  }

  const staticComponent: StaticComponent = {
    key: position.positionId,
    protocolName: position.protocol.name,
    protocolLogoURI: position.protocol.logoURI,
    chainId: position.chainId,
    chainName: chainName,
    chainLogoURI: chainIcon.uri,
    positionName: position.positionName,
    assetSymbol: assetSymbol,
    assetLogoURI: assetLogoURI,
    category: position.category,
    apr: apr,
    aprNum: aprNum,
    tvl: displayFiat(position.tvlUsd, true),
    tvlNum: position.tvlUsd,
    link: position.link,
    apyComponents: { exists: false },
    rewardsNA: !isPositionWithRewards,
  }
  if (position.aprComponents && position.aprComponents.length > 1) {
    staticComponent.apyComponents = {
      exists: true,
      components: position.aprComponents.map((c) => ({
        name: c.name,
        apy: displayRangeAPR(c.apr),
        logoURI: c.logoURI,
      })),
    }
  }
  const userComponent: UserComponent = {
    rewardResult: { exists: true, rewards: [] },
    rewardsNum: -1,
    balance: '-',
    balanceNum: -1,
  }

  for (const rewardAssetId of position.rewardAssetIds) {
    const asset = rewardAssets.find((a) => a.assetId === rewardAssetId)
    if (!asset) {
      continue
    }
    userComponent.rewardResult = {
      exists: true,
      rewards: [
        {
          claimable: '-',
          claimableNum: -1,
          claimableAssetName: asset.assetName,
          claimableAssetLogoURI: asset.logoURI,
          claimableAssetSquareLogo: asset.squareLogo,
        },
      ],
    }
  }

  if (account) {
    if (position.balanceUsd.exists && position.balanceUsd.amounts.length > 0) {
      userComponent.balance = displayFiat(position.balanceUsd.amounts[0], false)
      userComponent.balanceNum = position.balanceUsd.amounts[0]
    } else {
      userComponent.balance = '' // loading
    }
  }

  if (isPositionWithRewards) {
    userComponent.rewardResult = { exists: true, rewards: [] }

    for (const asset of rewardAssets) {
      const assetIndex = position.rewardAssetIds.indexOf(asset.assetId)
      if (assetIndex < 0) {
        continue
      }
      const res: PortfolioDefiPositionSummary_Reward = {
        claimable: '-',
        claimableNum: -1,
        claimableAssetName: asset.assetName,
        claimableAssetLogoURI: asset.logoURI,
        claimableAssetSquareLogo: asset.squareLogo,
      }
      if (account) {
        if (
          position.ecosystemTokens.exists &&
          assetIndex < position.ecosystemTokens.amounts.length
        ) {
          res.claimable = formatWithDynamicPrecision(
            position.ecosystemTokens.amounts[assetIndex],
            { localize: true }
          )
          res.claimableNum = position.ecosystemTokens.amounts[assetIndex]
        } else {
          res.claimable = '' // loading
        }
      } else {
        res.claimable = '-'
      }

      userComponent.rewardResult.rewards.push(res)
    }
  }

  const hasSwellRewards = position.aprComponents.some(
    (c) => c.name === TOKEN_LIST_SWELL.symbol
  )
  if (hasSwellRewards) {
    staticComponent.rewardsNA = false
    if (!userComponent.rewardResult.exists) {
      userComponent.rewardResult = { exists: true, rewards: [] }
    }
    userComponent.rewardResult.rewards!.unshift({
      claimable: '',
      claimableNum: -1,
      claimableAssetName: TOKEN_LIST_SWELL.name,
      claimableAssetLogoURI: TOKEN_LIST_SWELL.logoURI,
      claimableAssetSquareLogo: false,
    })
    if (account) {
      if (position.swell.exists && position.swell.amounts.length > 0) {
        userComponent.rewardResult.rewards[0].claimable =
          formatWithDynamicPrecision(position.swell.amounts[0], {
            localize: true,
          })
        userComponent.rewardResult.rewards[0].claimableNum =
          position.swell.amounts[0]
      } else {
        userComponent.rewardResult.rewards[0].claimable = '' // loading
      }
    } else {
      userComponent.rewardResult.rewards[0].claimable = '-'
    }
  }

  if (userComponent.rewardResult.exists) {
    for (const reward of userComponent.rewardResult.rewards) {
      if (reward.claimableNum > userComponent.rewardsNum) {
        userComponent.rewardsNum = reward.claimableNum
      }
    }
  }

  return { ...staticComponent, ...userComponent }
}
