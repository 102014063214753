import { createContext, useContext } from 'react'
import { IL2EcosystemApi } from './types'

const MISSING_PROVIDER = Symbol()

export type L2EcosystemContext = IL2EcosystemApi
export const L2EcosystemContext = createContext<
  L2EcosystemContext | typeof MISSING_PROVIDER
>(MISSING_PROVIDER)

export function useL2EcosystemApi() {
  const connectors = useContext(L2EcosystemContext)
  if (connectors === MISSING_PROVIDER) {
    throw new Error(
      'L2EcosystemContext hooks must be wrapped in a <L2EcosystemProvider>'
    )
  }
  return connectors
}
