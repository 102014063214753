import { ChainId } from '@/constants/chains'
import { useDeploymentSetConfig } from '@/state/deployments/hooks'

// w30 todo: put chain id constants in var, move this to helpers/network.ts
function makeRpcUrls() {
  const { rpcUrl } = useDeploymentSetConfig()

  return {
    [ChainId.MAINNET]: rpcUrl.mainnet,
    [ChainId.GOERLI]: rpcUrl.goerli,
    [ChainId.HARDHAT]: 'http://localhost:8545',
    [ChainId.SWELLCHAIN]: rpcUrl.swell,
    [ChainId.SWELL_TESTNET]: rpcUrl.swellTestnet,
    [ChainId.OPTIMISM]: rpcUrl.optimism,
    [ChainId.SEPOLIA]: rpcUrl.sepolia,
    [ChainId.ARBITRUM]: rpcUrl.arbitrum,
    [ChainId.OPTIMISM_SEPOLIA]: rpcUrl.optimismSepolia,
  } as any
}

export function useRpcUrl() {
  const { chainId } = useDeploymentSetConfig()
  return makeRpcUrls()[chainId as any]
}
export function useRpcUrlL2() {
  const { l2ChainId } = useDeploymentSetConfig()
  return makeRpcUrls()[l2ChainId as any]
}

export function useWalletConnectV2ProjectId() {
  const { walletConnectV2 } = useDeploymentSetConfig()
  return walletConnectV2.projectId
}
