import React from 'react'
import styled from 'styled-components/macro'
import { CloseIcon } from '@swell-ui/icons/CloseIcon'
import { Dialog, DialogContent, DialogTitle } from '@swell-ui/Dialog'
import { FlexRow } from '@/swell-ui/FlexRow'
import { TableConfig } from '@swell-ui/Table'
import { Typography } from '@swell-ui/Typography'
import { StakingPoolActivityTable } from './StakingPoolActivityTable'
import { ActivitySummary_Item } from '@/types/activity'
import { css } from '@mui/material'

interface StakingPoolActivityModalProps {
  open: boolean
  onClose: (value: boolean) => any
  items?: ActivitySummary_Item[]
  pagination?: TableConfig['pagination']
  isLoading: boolean
  title: string
  token: { logoURI: string }
}

const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    max-width: 1152px;
  }

  .MuiDialogTitle-root {
    padding: 24px 32px 12px;
  }

  .MuiDialogContent-root {
    margin-right: 6px;
    ${({ theme }) =>
      theme.scrollbar &&
      css`
        &::-webkit-scrollbar {
          width: ${theme.scrollbar.width};
        }
        &::-webkit-scrollbar-track {
          background: ${theme.scrollbar.trackBg};
          border-radius: ${theme.scrollbar.borderRadius};
        }
        &::-webkit-scrollbar-thumb {
          background: ${theme.scrollbar.thumbBg};
          border-radius: ${theme.scrollbar.borderRadius};
        }
      `}
  }
`

const StyledCloseIcon = styled(CloseIcon)`
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }

  path {
    stroke-width: 0.666667px;
    stroke: ${({ theme }) => theme.mainColor};
  }
`

function StakingPoolActivityModal({
  open,
  onClose,
  items,
  pagination,
  title,
  isLoading,
  token,
}: StakingPoolActivityModalProps) {
  return (
    <StyledDialog open={open} fullWidth onClose={onClose}>
      <DialogTitle>
        <FlexRow justify="space-between">
          <Typography variant="body" size="large">
            {title}
          </Typography>
          <StyledCloseIcon aria-label="Close" onClick={onClose} />
        </FlexRow>
      </DialogTitle>
      <DialogContent>
        <StakingPoolActivityTable
          isLoading={isLoading}
          items={items}
          pagination={pagination}
          token={token}
        />
      </DialogContent>
    </StyledDialog>
  )
}

export { StakingPoolActivityModal }
