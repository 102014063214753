import styled from 'styled-components'
import {
  StakingPoolActivityInputs,
  SwETHDepositInputs,
  SwETHDepositSummary,
  SwETHStatsSummary,
  SwETHWithdrawInputs,
  SwETHWithdrawSummary,
} from '../types'
import { AvailableChipV2 } from '@/components/StakingWidget/AvailableChipV2'
import { FlexRow } from '@/swell-ui/FlexRow'
import { Transak } from '../components/Transak'
import { ThemeData } from '@/swell-ui/theme/branding'
import { ReactNode, useState } from 'react'
import {
  SwETHBottomInputDepositForSwETH,
  SwETHBottomInputWithdrawForNativeCurrency,
  SwETHTopInputDepositNativeCurrency,
  SwETHTopInputWithdrawSwETH,
} from '../SwETHInputs'
import { SwETHCalls } from '@/state/sweth/hooks'
import {
  PreparedApproveSwETHForWithdrawal,
  PreparedSwETHCreateWithdrawRequest,
  PreparedSwETHDeposit,
  SwETHErrors,
} from '../swETHCalls'
import {
  ApproveSwETHForWithdrawalButton,
  SwETHCreateWithdrawRequestButton,
  SwETHDepositButton,
} from '../SwETHButtons'
import { LstExchangeInfo } from '../components/LstStats'
import { SwellStatisticsV2 } from '../components/SwellStatisticsV2'
import { ActivitySummaryCounts, ActivitySummaryItems } from '@/types/activity'
import { Typography } from '@/swell-ui/Typography'
import { EthIcon } from '@/swell-ui/icons/EthIcon'
import { SwethIcon } from '@/swell-ui/icons/SwethIcon'
import { DividerDark } from '@/swell-ui/Divider'

export function SwETHDepositView({
  depositInputs,
  preparedApproveSwETHForWithdrawal,
  preparedCreateWithdrawRequest,
  preparedDeposit,
  depositSummary,
  withdrawInputs,
  withdrawSummary,
  statsSummary,
  nativeCurrency,
  calls,
  activityCounts,
  activityItems,
  activityInputs,
  swETHToken,
}: {
  calls: Pick<
    SwETHCalls,
    'deposit' | 'approveSwETHForWithdrawal' | 'createWithdrawRequest'
  >
  preparedDeposit: PreparedSwETHDeposit
  preparedApproveSwETHForWithdrawal: PreparedApproveSwETHForWithdrawal
  preparedCreateWithdrawRequest: PreparedSwETHCreateWithdrawRequest
  depositSummary: SwETHDepositSummary
  depositInputs: SwETHDepositInputs
  withdrawSummary: SwETHWithdrawSummary
  withdrawInputs: SwETHWithdrawInputs
  statsSummary: SwETHStatsSummary
  activityItems: ActivitySummaryItems | undefined
  activityCounts: ActivitySummaryCounts | undefined
  activityInputs: StakingPoolActivityInputs
  nativeCurrency: { symbol: string }
  swETHToken: { symbol: string; logoURI: string }
}) {
  const [touched, setTouched] = useState(false)
  const [isWithdraw, setIsWithdraw] = useState(false)

  const clearInputs = () => {
    depositInputs.onEditNativeCurrency('')
    withdrawInputs.onEditSwETH('')
  }

  const handleUnstakeClick = () => {
    setIsWithdraw((prev) => !prev)
    setTouched(false)
    clearInputs()
  }

  let preventInteraction = false
  if (!isWithdraw) {
    if (depositSummary.availableETH === '') {
      preventInteraction = true
    }
  } else {
    if (withdrawSummary.availableSwETH === '') {
      preventInteraction = true
    }
  }

  let errorMessage: string
  if (!isWithdraw) {
    errorMessage = preparedDeposit.error ?? ''
  } else {
    errorMessage = preparedCreateWithdrawRequest.error ?? ''
    if (errorMessage === SwETHErrors.InsufficientAllowance) {
      errorMessage = preparedApproveSwETHForWithdrawal.error ?? ''
    }
    if (errorMessage === SwETHErrors.UnstakeAmountTooLow) {
      const minAmountStr = withdrawInputs.minUnstakeAmountSwETH
      errorMessage = `Cannot unstake less than ${minAmountStr} ${swETHToken.symbol}`
    }
    if (errorMessage === SwETHErrors.UnstakeAmountTooHigh) {
      const maxAmountStr = withdrawInputs.maxUnstakeAmountSwETH
      errorMessage = `Cannot unstake more than ${maxAmountStr} ${swETHToken.symbol}`
    }
  }
  if (!touched) {
    errorMessage = ''
  }

  let topInput: ReactNode
  let bottomInput: ReactNode
  if (!isWithdraw) {
    topInput = (
      <SwETHTopInputDepositNativeCurrency
        depositInputs={depositInputs}
        depositSummary={depositSummary}
        errorMessage={errorMessage}
        preventInteraction={preventInteraction}
        setTouched={setTouched}
      />
    )

    bottomInput = (
      <SwETHBottomInputDepositForSwETH
        depositInputs={depositInputs}
        depositSummary={depositSummary}
        preventInteraction={preventInteraction}
        setTouched={setTouched}
      />
    )
  } else {
    topInput = (
      <SwETHTopInputWithdrawSwETH
        withdrawInputs={withdrawInputs}
        errorMessage={errorMessage}
        preventInteraction={preventInteraction}
        setTouched={setTouched}
        withdrawSummary={withdrawSummary}
      />
    )

    bottomInput = (
      <SwETHBottomInputWithdrawForNativeCurrency
        withdrawSummary={withdrawSummary}
        preventInteraction={preventInteraction}
        withdrawInputs={withdrawInputs}
        setTouched={setTouched}
      />
    )
  }

  let topHeading: ReactNode
  let bottomHeading: ReactNode
  if (!isWithdraw) {
    topHeading = <StakeETHHeading />
    bottomHeading = <ReceiveSwETHHeading />
  } else {
    topHeading = <UnstakeSwETHHeading />
    bottomHeading = <ReceiveETHHeading />
  }

  let button: ReactNode
  if (!isWithdraw) {
    button = (
      <SwETHDepositButton
        deposit={calls.deposit}
        prepared={preparedDeposit}
        preventInteraction={preventInteraction}
      />
    )
  } else {
    if (
      preparedCreateWithdrawRequest.error === SwETHErrors.InsufficientAllowance
    ) {
      button = (
        <ApproveSwETHForWithdrawalButton
          approveSwETHForWithdrawal={calls.approveSwETHForWithdrawal}
          prepared={preparedApproveSwETHForWithdrawal}
          preventInteraction={preventInteraction}
        />
      )
    } else {
      button = (
        <SwETHCreateWithdrawRequestButton
          createWithdrawRequest={calls.createWithdrawRequest}
          prepared={preparedCreateWithdrawRequest}
          preventInteraction={preventInteraction}
        />
      )
    }
  }

  let transactionFee: string
  if (!isWithdraw) {
    transactionFee = depositSummary.transactionFee
  } else {
    transactionFee = withdrawSummary.transactionFee
  }

  let availableChip = (
    <AvailableChipV2
      available={depositSummary.availableETH}
      symbol={nativeCurrency.symbol}
    />
  )
  if (isWithdraw) {
    availableChip = (
      <AvailableChipV2
        available={withdrawSummary.availableSwETH}
        symbol={swETHToken.symbol}
      />
    )
  }
  return (
    <Layout>
      <div style={{ height: '12px' }} />
      <FlexRow justify="space-between">
        {availableChip}
        <Transak />
      </FlexRow>
      <div style={{ height: '12px' }} />
      {topHeading}
      {topInput}
      <div style={{ height: '12px' }} />
      <FlexRow justify="center">
        <UnstakeButton onClick={handleUnstakeClick}>
          <ArrowDown />
        </UnstakeButton>
      </FlexRow>
      {bottomHeading}
      {bottomInput}
      <div style={{ height: '12px' }} />
      <DividerDark />
      <LstExchangeInfo
        apr={statsSummary.apr}
        exchangeRate={statsSummary.exchangeRate}
        transactionFee={transactionFee}
        unstake={isWithdraw}
        processingTime="~12 days"
      />
      <div style={{ height: '24px' }} />
      {button}
      <div style={{ margin: '12px 0 0' }}>
        <SwellStatisticsV2
          activityCounts={activityCounts}
          activityItems={activityItems}
          activityInputs={activityInputs}
          lstToken={swETHToken}
          summary={statsSummary}
        />
      </div>
    </Layout>
  )
}

const Layout = styled.div`
  display: flex;
  flex-flow: column nowrap;
`

function ArrowDown() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none"
    >
      <g clip-path="url(#clip0_11574_5371)">
        <path
          d="M11.5004 3.4375V18.5625"
          stroke={ThemeData.Swell.SwellLightBlue}
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M5.31287 12.375L11.5004 18.5625L17.6879 12.375"
          stroke={ThemeData.Swell.SwellLightBlue}
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_11574_5371">
          <rect
            width="22"
            height="22"
            fill="white"
            transform="translate(0.49939)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

const UnstakeButton = styled.div<any>`
  display: flex;
  padding: 6px;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  border-radius: 6px;
  border: 2px solid ${ThemeData.Swell.SwellLightBlue};
  background: transparent;

  svg {
    transition: rotate 300ms;
    color: ${ThemeData.Swell.SwellLightBlue};
  }

  &:hover {
    opacity: 0.7;
    cursor: pointer;

    svg {
      rotate: 180deg;
    }
  }

  &:active {
    opacity: 0.5;
  }
`

function StakeETHHeading() {
  return (
    <FlexRow justify="space-between" align="center">
      <Typography variant="body" size="xlarge" fstyle="bold">
        Stake
      </Typography>
      <SymbolWrapper>
        <StyledEthIcon />
        <Typography variant="body" size="large" fstyle="bold">
          ETH
        </Typography>
      </SymbolWrapper>
    </FlexRow>
  )
}

const SymbolWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.white['50']};
`
const StyledEthIcon = styled(EthIcon)`
  width: 35px;
  height: 35px;
  margin-right: 8px;
`
const StyledSwethIcon = styled(SwethIcon)`
  width: 35px;
  height: 35px;
  margin-right: 8px;
`

function ReceiveSwETHHeading() {
  return (
    <FlexRow justify="space-between" align="center">
      <Typography variant="body" size="xlarge" fstyle="bold">
        Receive
      </Typography>
      <SymbolWrapper>
        <StyledSwethIcon />
        <Typography variant="body" size="large" fstyle="bold">
          swETH
        </Typography>
      </SymbolWrapper>
    </FlexRow>
  )
}

function UnstakeSwETHHeading() {
  return (
    <FlexRow justify="space-between" align="center">
      <Typography variant="body" size="xlarge" fstyle="bold">
        Unstake
      </Typography>
      <SymbolWrapper>
        <StyledSwethIcon />
        <Typography variant="body" size="large" fstyle="bold">
          swETH
        </Typography>
      </SymbolWrapper>
    </FlexRow>
  )
}
function ReceiveETHHeading() {
  return (
    <FlexRow justify="space-between" align="center">
      <Typography variant="body" size="xlarge" fstyle="bold">
        Receive
      </Typography>
      <SymbolWrapper>
        <StyledEthIcon />
        <Typography variant="body" size="large" fstyle="bold">
          ETH
        </Typography>
      </SymbolWrapper>
    </FlexRow>
  )
}
