import { parseEther } from 'ethers/lib/utils'
import { Wavedrop, WavedropPhase } from './types'
import { StaticWavedrop } from '@/services/StaticWavedrop/types'
import { StaticWavedropV2 } from '@/services/StaticWavedropV2/types'

export function reconstructWavedropsFromStaticData(
  staticWd: StaticWavedrop
): Wavedrop {
  const wd: Wavedrop = {
    phase: WavedropPhase.claims,
    waveNumber: staticWd.wavedropNumber,
    blackPearls: staticWd.weightedPearls,
    multiplier: staticWd.bonus,
    daoTokenAmount: staticWd.swellAmount,
    cumulativeAmount: staticWd.cumulativeAmount,
    merkleProof: staticWd.proofsHex,
    merkleRoot: staticWd.merkleRoot,
    swellAmount: staticWd.swellAmount,
    ecosystemAmount: staticWd.ecosystemAmount ?? parseEther('0'),
    primaryAmount: staticWd.primaryAmount ?? parseEther('0'),
  }

  return wd
}

export function reconstructWavedropsFromStaticDataV2(
  staticWd: StaticWavedropV2
): Wavedrop {
  const wd: Wavedrop = {
    phase: WavedropPhase.claims,
    waveNumber: staticWd.wavedropNumber,
    blackPearls: staticWd.meta.weightedPearls,
    multiplier: staticWd.meta.bonus,
    daoTokenAmount: staticWd.meta.swellAmount,
    cumulativeAmount: staticWd.cumulativeAmount,
    merkleProof: staticWd.proofsHex,
    merkleRoot: staticWd.merkleRoot,
    swellAmount: staticWd.meta.swellAmount,
    ecosystemAmount: staticWd.meta.ecosystemAmount ?? parseEther('0'),
    primaryAmount: staticWd.meta.primaryAmount ?? parseEther('0'),
  }

  return wd
}
