import {
  NucleusAllowances,
  NucleusBalances,
  NucleusSharesState,
  NucleusVaultAuth,
  NucleusVaultPoints,
  NucleusVaultState,
  NucleusVaultStats,
} from '@/state/nucleusVault/types'
import { Box } from '@/swell-ui/Box'
import { FlexRow } from '@/swell-ui/FlexRow'
import { TabConfig, Tabs } from '@/swell-ui/Tabs'
import { ReactNode, useState } from 'react'
import styled from 'styled-components'
import { NucleusHoldings } from './NucleusHoldings'
import { NucleusVaultCalls } from '@/state/nucleusVault/hooks'
import { NucleusWithdrawView } from './views/NucleusWithdrawView'
import { NucleusPerformanceView } from './views/NucleusPerformanceView'
import { NucleusVaultStatsRow } from './NucleusVaultStats'
import { useMediaQuery } from '@mui/material'
import { NucleusDepositView } from './views/NucleusDepositView'
import {
  useNucleusActiveWithdrawal,
  useNucleusDepositInputs,
} from './nucleusHooks'
import { NucleusClaimView } from './views/NucleusClaimView'
import { NucleusDetailsView } from './views/NucleusDetailsView'
import {
  NucleusApproveAssetForDepositToast,
  NucleusApproveVaultTokenForAtomicQueueToast,
  NucleusCancelWithdrawToast,
  NucleusDepositToast,
  NucleusRequestWithdrawToast,
} from './NucleusToasts'
import { useTransactionContext } from '@/state/transactions/context'
import { NucleusPointsCampaigns } from './types'
import {
  NucleusFulfilledRequestEvent,
  NucleusRates,
  NucleusTokens,
  NucleusVault,
  NucleusWithdrawRequestResult,
} from '@/types/nucleus'
import { makeNucleusHoldingsSummary } from './nucleusFormatting'
import { useSwellWeb3 } from '@/swell-web3/core'

const Tab = {
  Deposit: 'Deposit' as const,
  Withdraw: 'Withdraw' as const,
  Claim: 'Claim' as const,
  Details: 'Details' as const,
  Performance: 'Performance' as const,
}
type Tab = (typeof Tab)[keyof typeof Tab]
const DEFAULT_TAB = Tab.Deposit

export function NucleusVaultWidget({
  baseAssetRateUsd,
  stats,
  allowances,
  auth,
  balances,
  rates,
  sharesState,
  supportedTokens,
  vaultState,
  currentWithdrawRequest,
  points,
  descriptionNode,
  performanceNode,
  calls,
  fulfilledRequests,
  pointsCampaigns,
  vault,
  noPerformanceData,
}: {
  vault: NucleusVault
  calls: NucleusVaultCalls

  pointsCampaigns: NucleusPointsCampaigns

  // user
  balances: NucleusBalances | undefined
  allowances: NucleusAllowances | undefined
  supportedTokens: NucleusTokens | undefined
  points: NucleusVaultPoints | undefined
  currentWithdrawRequest: NucleusWithdrawRequestResult | undefined
  fulfilledRequests: NucleusFulfilledRequestEvent[] | undefined
  sharesState: NucleusSharesState | undefined

  // vault
  stats: NucleusVaultStats | undefined
  vaultState: NucleusVaultState | undefined
  auth: NucleusVaultAuth | undefined
  rates: NucleusRates | undefined
  baseAssetRateUsd: number | undefined

  // content
  descriptionNode: ReactNode
  performanceNode: ReactNode

  noPerformanceData: boolean
}) {
  const { account, chainId } = useSwellWeb3()

  const { anyTransactionInProgress } = useTransactionContext()
  const is900Up = useMediaQuery('(min-width:900px)')
  const [tab, setTab] = useState<Tab>(DEFAULT_TAB)

  const depositInputs = useNucleusDepositInputs({
    depositAssets: vault.depositAssets,
  })
  const activeWithdrawal = useNucleusActiveWithdrawal({
    fulfilledRequests,
    vaultToken: vault.vaultToken,
    currentWithdrawRequest,
  })
  const returnToWithdraw = () => setTab(Tab.Withdraw)
  const goToClaim = () => setTab(Tab.Claim)

  const holdingsSummary = makeNucleusHoldingsSummary({
    balances,
    vaultToken: vault.vaultToken,
    vaultTokenL2: vault.vaultTokenL2,

    account,
    points: points,
    chainId,
  })

  const supportedAssetsDeposit = supportedTokens?.deposit
  const supportedAssetsWithdraw = supportedTokens?.withdraw

  let content: ReactNode = null
  if (tab === Tab.Deposit) {
    content = (
      <NucleusDepositView
        depositInputs={depositInputs}
        vault={vault}
        balances={balances}
        allowances={allowances}
        positions={stats?.positions}
        approveAssetForDeposit={calls.approveAssetForDeposit}
        auth={auth}
        deposit={calls.deposit}
        rates={rates}
        supportedAssets={supportedAssetsDeposit}
        vaultState={vaultState}
        baseAssetRateUsd={baseAssetRateUsd}
      />
    )
  } else if (tab === Tab.Withdraw) {
    content = (
      <NucleusWithdrawView
        vault={vault}
        requestWithdraw={calls.requestWithdraw}
        approveVaultTokenForAtomicQueue={calls.approveVaultTokenForAtomicQueue}
        allowances={allowances}
        balances={balances}
        activeWithdrawal={activeWithdrawal}
        sharesState={sharesState}
        rates={rates}
        vaultState={vaultState}
        baseAssetRateUsd={baseAssetRateUsd}
        goToClaim={goToClaim}
        supportedAssets={supportedAssetsWithdraw}
      />
    )
  } else if (tab === Tab.Claim) {
    content = (
      <NucleusClaimView
        vaultState={vaultState}
        activeWithdrawal={activeWithdrawal}
        returnToWithdraw={returnToWithdraw}
        cancelWithdraw={calls.cancelWithdraw}
        vault={vault}
        rates={rates}
        supportedAssets={supportedAssetsWithdraw}
      />
    )
  } else if (tab === Tab.Details) {
    content = (
      <NucleusDetailsView
        description={descriptionNode}
        vaultState={vaultState}
        vaultToken={vault.vaultToken}
      />
    )
  } else if (tab === Tab.Performance) {
    content = (
      <NucleusPerformanceView
        performance={performanceNode}
        performanceData={stats?.performance}
        apr={stats?.apr}
      />
    )
  }

  const statsNode = (
    <NucleusVaultStatsRow
      vaultStats={stats}
      pointsCampaigns={pointsCampaigns}
      holdingsSummary={holdingsSummary}
    />
  )

  let aboveBoxesArea = (
    <FlexRow
      direction="column"
      gap={is900Up ? '24' : '12'}
      justify="flex-start"
    >
      {statsNode}
    </FlexRow>
  )
  if (is900Up) {
    aboveBoxesArea = (
      <FlexRow justify="space-between" align="center">
        {statsNode}
      </FlexRow>
    )
  }

  let tabs: TabConfig[] = [
    {
      id: Tab.Deposit,
      label: 'Deposit',
    },
    {
      id: Tab.Withdraw,
      label: 'Withdraw',
    },
    {
      id: Tab.Details,
      label: 'Details',
    },
    {
      id: Tab.Performance,
      label: 'Performance',
    },
  ]
  if (activeWithdrawal && activeWithdrawal.status !== 'NoActiveWithdrawal') {
    tabs = [
      {
        id: Tab.Deposit,
        label: 'Deposit',
      },
      {
        id: Tab.Withdraw,
        label: 'Withdraw',
      },
      {
        id: Tab.Claim,
        label: 'Claim',
      },
      {
        id: Tab.Details,
        label: 'Details',
      },
      {
        id: Tab.Performance,
        label: 'Performance',
      },
    ]
  }
  if (noPerformanceData) {
    tabs = tabs.filter((t) => t.id !== Tab.Performance)
  }

  return (
    <Layout>
      {aboveBoxesArea}
      <NucleusHoldings
        vault={vault}
        holdingsSummary={holdingsSummary}
        supportedTokens={supportedTokens}
        showBtcPartnerPoints
      />
      <TabsBox>
        <Tabs selectedTab={tab} setTab={(v) => setTab(v as Tab)} tabs={tabs} />
        <TabContent>{content}</TabContent>
      </TabsBox>
      <NucleusCancelWithdrawToast
        cancelWithdraw={calls.cancelWithdraw}
        anyTransactionInProgress={anyTransactionInProgress}
        withdrawAssets={vault.withdrawAssets}
        chainId={chainId}
      />
      <NucleusApproveAssetForDepositToast
        approveAssetForDeposit={calls.approveAssetForDeposit}
        anyTransactionInProgress={anyTransactionInProgress}
        depositAssets={vault.depositAssets}
      />
      <NucleusDepositToast
        baseAsset={vault.baseAsset}
        deposit={calls.deposit}
        anyTransactionInProgress={anyTransactionInProgress}
        depositAssets={vault.depositAssets}
        rates={rates}
        vaultToken={vault.vaultToken}
        chainId={chainId}
      />
      <NucleusApproveVaultTokenForAtomicQueueToast
        approveVaultTokenForAtomicQueue={calls.approveVaultTokenForAtomicQueue}
        anyTransactionInProgress={anyTransactionInProgress}
        vaultToken={vault.vaultToken}
      />
      <NucleusRequestWithdrawToast
        baseAsset={vault.baseAsset}
        requestWithdraw={calls.requestWithdraw}
        anyTransactionInProgress={anyTransactionInProgress}
        withdrawAssets={vault.withdrawAssets}
        vaultToken={vault.vaultToken}
        chainId={chainId}
      />
    </Layout>
  )
}

const TabContent = styled.div`
  align-self: stretch;

  display: flex;
  flex-direction: column;
  align-items: stretch;

  margin-top: 42px;
  height: 464px;

  @media (min-width: 900px) {
    height: 210px;
  }
`

const TabsBox = styled(Box)`
  padding: 24px;
`

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
