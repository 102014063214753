import { SupportedChainId } from './chains'

export type AddressMap = Record<SupportedChainId, string>

export const CHAINLINK_ETH_USD_FEED_ADDRESSES: AddressMap = {
  [SupportedChainId.MAINNET]: '0x5f4eC3Df9cbd43714FE2740f5E3616155c5b8419',
  [SupportedChainId.GOERLI]: '0xD4a33860578De61DBAbDc8BFdb98FD742fA7028e',
  [SupportedChainId.HARDHAT]: '0x5f4eC3Df9cbd43714FE2740f5E3616155c5b8419',
  [SupportedChainId.SEPOLIA]: '',
  [SupportedChainId.HOLESKY]: '',
  [SupportedChainId.SWELL]: '',
  [SupportedChainId.SWELL_TESTNET]: '',
  [SupportedChainId.OPTIMISM]: '',
  [SupportedChainId.ARBITRUM]: '',
  [SupportedChainId.OPTIMISM_SEPOLIA]: '',
}
export const CHAINLINK_WBTC_ETH_ADDRESSES: AddressMap = {
  [SupportedChainId.MAINNET]: '0xdeb288F737066589598e9214E782fa5A8eD689e8',
  [SupportedChainId.GOERLI]: '',
  [SupportedChainId.HARDHAT]: '',
  [SupportedChainId.SEPOLIA]: '',
  [SupportedChainId.HOLESKY]: '',
  [SupportedChainId.SWELL]: '',
  [SupportedChainId.SWELL_TESTNET]: '',
  [SupportedChainId.OPTIMISM]: '',
  [SupportedChainId.ARBITRUM]: '',
  [SupportedChainId.OPTIMISM_SEPOLIA]: '',
}
