import styled, { css } from 'styled-components'
import { title1CSS } from '@/components/AirdropCarousel/styles/common'
import { DecoBox } from '@/components/DecoBox'
import { SwellDaoClaimAirdrop } from '@/state/dao/hooks'
import { Token } from '@/types/tokens'
import { AirdropResult } from '@/state/dao/types'
import { FlexRow } from '@/swell-ui/FlexRow'
import { ColoredHeadingTypography } from '@/swell-ui/Typography/typographyPresets'
import { makeCompletionSummary } from '../../airdropFormatting'
import { keyframes } from 'styled-components'
import { useMediaQuery } from '@mui/material'
import { ROUTES } from '@/constants/routes'
import { ReactNode } from 'react'
import { ButtonLink } from '../../AirdropButtons'
import { BannerTGE } from '@/components/AppBanner/BannerTGE'

const bodyMediumCSS = css`
  color: var(--Swell-White-50, #fff);
  text-align: right;
  /* Body/medium */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 25.6px */
  letter-spacing: -0.48px;
`
const bodyLargeBoldCSS = css`
  color: var(--Swell-White-50, #fff);
  /* Body/large bold */
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 28.8px */
`

const tokenSymbolTextCSS = css`
  color: var(--Swell-White-50, #fff);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
`

// Renders the completion screen of the airdrop carousel, showing a summary of the user's claimed/restaked tokens
export function Completion({
  daoToken,
  restakedDaoToken,
  airdropResult,
  cachedClaimAirdropArguments,
}: {
  daoToken: Token
  restakedDaoToken: Token
  airdropResult: AirdropResult

  // data that the user submitted successfully when claiming the airdrop. Not available if they're revisiting the page.
  cachedClaimAirdropArguments?: Parameters<SwellDaoClaimAirdrop['call']>[0]
}) {
  const isXS = useMediaQuery('(max-width: 370px)')
  const summary = makeCompletionSummary({
    daoToken,
    restakedDaoToken,
    airdropResult,
    cachedClaimAirdropArguments,
  })

  const claimedTokens: Token[] = []
  if (summary.totalClaimedTokenSymbols.includes(daoToken.symbol)) {
    claimedTokens.push(daoToken)
  }
  if (summary.totalClaimedTokenSymbols.includes(restakedDaoToken.symbol)) {
    claimedTokens.push(restakedDaoToken)
  }

  const totalClaimedRow = (
    <DataRow value={summary.totalClaimedAmount} tokens={claimedTokens} />
  )

  const depositNowLink =
    summary.callToAction === 'l2' ? ROUTES.SwellchainLaunch : ROUTES.StakeSwell

  let content: ReactNode = null
  if (summary.callToAction === 'l2') {
    content = (
      <>
        <p style={{ textAlign: 'left' }}>Bridge to Swellchain to earn:</p>
        <ul style={{ textAlign: 'left' }}>
          <li>1x Symbiotic Points</li>
          <li>Ongoing SWELL rewards in Wavedrops</li>
          <li>Future restaking yield for securing Swellchain</li>
          <li>Yield-boosting DeFi opportunities on Swellchain</li>
        </ul>
      </>
    )
  } else {
    content = (
      <>
        <p style={{ textAlign: 'left' }}>
          Restake your SWELL for rSWELL and bridge to Swellchain to earn:
        </p>
        <ul style={{ textAlign: 'left' }}>
          <li>1x Symbiotic Points</li>
          <li>Ongoing SWELL rewards in Wavedrops</li>
          <li>Future restaking yield for securing Swellchain</li>
          <li>Yield-boosting DeFi opportunities on Swellchain</li>
        </ul>
      </>
    )
  }

  let title = 'Claimed'
  if (
    summary.totalClaimedAmount &&
    summary.totalClaimedAmount !== '0' &&
    summary.totalRestakedAmount &&
    summary.totalRestakedAmount !== '0'
  ) {
    title = 'Claimed'
  } else if (
    !summary.totalClaimedAmount ||
    summary.totalClaimedAmount === '0'
  ) {
    title = 'Restaked Swell'
  }

  return (
    <Layout
      direction="column"
      margin="0 auto"
      justify="center"
      align={isXS ? 'center' : 'stretch'}
      gap="16"
      textAlign="center"
    >
      <h1>Congratulations!</h1>
      <p>You have claimed your airdrop!</p>
      <div className="spacer no-mob" />
      <ClaimSummaryBox
        align="center"
        justify="center"
        gap="12"
        textAlign="center"
        width="auto"
      >
        <ColoredHeadingTypography
          style={{
            fontSize: '14px',
            textAlign: 'center',
            alignSelf: 'center',
          }}
        >
          {title}
        </ColoredHeadingTypography>
        {totalClaimedRow}
        {content}
      </ClaimSummaryBox>
      <div className="btn-wide">
        <ButtonLink to={depositNowLink}>Deposit now</ButtonLink>
      </div>
      <div className="spacer only-mob" />
      <div className="spacer only-mob" />
    </Layout>
  )
}
const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`

const fadeInCSS = css`
  opacity: 0;
  animation: ${fadeIn} 0.65s ease-out forwards;
  animation-delay: 0;
`

const ClaimSummaryBox = styled(DecoBox)`
  ${ColoredHeadingTypography} {
    font-size: 14px;
    align-self: flex-start;
  }

  width: 100%;
  padding: 16px 12px !important;

  @media (min-width: 460px) {
    gap: 24px;
    max-width: 431px;
    padding: 16px !important;
  }
`

const Layout = styled(FlexRow)`
  margin-top: 24px;
  #banner-positioner {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    text-align: left;

    width: calc(100vw - 24px);

    p {
      margin: unset;
      padding: unset;
    }
    ul {
      margin-top: unset;
    }

    @media (max-height: 1000px) {
      display: none;
    }
  }

  ${fadeInCSS}
  h1 {
    margin: 0;
    ${title1CSS}
    @media (max-width: 1089px) {
      margin-top: 124px;
    }
  }
  .body-medium {
    ${bodyMediumCSS}
  }
  .large-bold {
    ${bodyLargeBoldCSS}
  }
  .token-symbol {
    ${tokenSymbolTextCSS}
  }
  .btn-wide {
    max-width: 431px;
    width: 100%;
    > a,
    button {
      width: 100%;
    }
  }
  .left {
    flex: 1;
    text-align: left;
  }
  .spacer {
    height: 16px;
  }
  .no-mob {
    display: none;
  }
  .only-mob {
    display: inherit;
  }
  @media (min-width: 460px) {
    .no-mob {
      display: inherit;
    }
    .only-mob {
      display: none;
    }
  }
  @media (max-width: 370px) {
    width: 100%;
    padding: 0;
  }
  p {
    margin: 0;
    padding: 0;
  }
  ul {
    margin-top: 0;
  }
`

function DataRow({ tokens, value }: { value: string; tokens: Token[] }) {
  const tokenSymbolStr = tokens.map((token) => token.symbol).join(' & ')

  return (
    <>
      <FlexRow
        gap="6"
        className="info"
        direction={'row'}
        align={'center'}
        justify={'center'}
        margin={'0 auto'}
        width="max-content"
      >
        <TokenGroup justify={'center'}>
          {tokens.map((token) => (
            <img
              key={token.address}
              src={token.logoURI}
              className="token-logo"
              width="32"
              height="32"
            />
          ))}
        </TokenGroup>

        <span className="large-bold left">
          {value}
          <span className="token-symbol"> {tokenSymbolStr}</span>
        </span>
      </FlexRow>
    </>
  )
}

const TokenGroup = styled(FlexRow)`
  flex: 0 1 max-content;

  img.token-logo {
    width: 32px;
    height: 32px;
    border-radius: 50%;

    &:not(:last-child) {
      margin-right: -10px;
      z-index: 1;
    }
  }
`
