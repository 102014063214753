import { useL2EcosystemProjects } from '@/state/l2ecosystem/hooks'
import { L2EcosystemProject } from '@/state/l2ecosystem/types'
import styled, { css } from 'styled-components'
import { FeatureCard } from './FeatureCard'
import { FlexRow } from '@/swell-ui/FlexRow'
import { L2Breakpoints } from './consts'
import { ProjectsList } from './ProjectsList'
import { useMediaQuery } from '@mui/material'
import swellL2Large from '@/assets/images/swell_l2_large.png'

export function L2EcosystemPage() {
  const isMobile = useMediaQuery(`(max-width: ${L2Breakpoints.mobile}px)`)

  const projectsQuery = useL2EcosystemProjects()
  const projects = projectsQuery.data
  const allFeaturedProjects = projects?.filter((p) => p.isFeatured)

  let featuredProject1: L2EcosystemProject | undefined
  let featuredProject2: L2EcosystemProject | undefined
  if (allFeaturedProjects && allFeaturedProjects.length > 0) {
    featuredProject1 = allFeaturedProjects[0]
    if (allFeaturedProjects.length > 1) {
      featuredProject2 = allFeaturedProjects[1]
    }
  }

  const featuredProjects = [featuredProject1, featuredProject2]
  const featureCards = featuredProjects.map((project, idx) => {
    return <FeatureCard key={idx} project={project!} />
  })

  const renderTitle = () => {
    return (
      <FlexRow gap={'16'}>
        {!isMobile && <img src={swellL2Large} width={42} height={42} />}
        <span
          className="headlines-h3 headlines-h5-mob"
          style={{ wordBreak: 'break-word', textAlign: 'left' }}
        >
          Swellchain Ecosystem
        </span>
        {isMobile && <img src={swellL2Large} width={32} height={32} />}
      </FlexRow>
    )
  }

  const renderDescription = () => {
    return (
      <span className="body-medium">
        Discover projects that have built or are integrated into Swellchain
      </span>
    )
  }

  const renderFeatured = () => {
    return (
      <FlexRow gap="24" direction="column" align="start">
        <span className="headlines-h6 headlines-h7-mob">Featured</span>
        <div id="feature-track">{featureCards}</div>
      </FlexRow>
    )
  }

  const renderProjects = () => {
    return <ProjectsList projects={projects} />
  }

  return (
    <PageStyles>
      <div className="a-title">{renderTitle()}</div>
      <div className="a-description">{renderDescription()}</div>
      <div className="a-featured">{renderFeatured()}</div>
      <div className="a-projects">{renderProjects()}</div>
    </PageStyles>
  )
}

const headlinesH3 = css`
  color: var(--Swell-White-50, #fff);
  /* Headlines/H3 */
  font-family: Inter;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 57.6px */
  letter-spacing: -1.44px;
`
const headlinesH5 = css`
  color: var(--Swell-White-50, #fff);
  text-align: center;
  /* Headlines/H5 */
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 38.4px */
  letter-spacing: -0.96px;
`
const headlinesH6 = css`
  color: var(--Swell-White-50, #fff);
  /* Headlines/H6 */
  font-family: Inter;
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 33.8px */
  letter-spacing: -0.78px;
`
const headlinesH7 = css`
  color: var(--Swell-White-50, #fff);
  /* Headlines/H7 */
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 26px */
  letter-spacing: -0.6px;
`

const bodyMedium = css`
  color: var(--Swell-White-100, #e7e7e7);
  /* Body/medium */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 25.6px */
  letter-spacing: -0.48px;
`
const bodySmall = css`
  color: var(--Swell-White-100, #e7e7e7);
  /* Body/small */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 22.4px */
  letter-spacing: -0.42px;
`
const pageStyles = css`
  .headlines-h3 {
    ${headlinesH3};
  }
  .headlines-h5-mob {
    ${headlinesH5};
  }
  .headlines-h6 {
    ${headlinesH6};
  }
  .headlines-h7-mob {
    ${headlinesH7};
  }
  .headlines-h7 {
    ${headlinesH7};
  }
  .body-medium {
    ${bodyMedium};
  }
  .body-small {
    ${bodySmall};
  }
`

const layoutMobile = css`
  grid-template-areas:
    'title'
    'description'
    'featured'
    'projects';
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto auto;

  .a-title {
    margin-bottom: 16px;
  }
  .a-description {
    margin-bottom: 40px;
  }
  .a-featured {
    margin-bottom: calc(48px + 24px);
  }
  .a-projects {
    min-height: calc(100vh - 92px);
  }

  #feature-track {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;
  }
`
const layoutDesktop = css`
  .a-featured {
    margin-bottom: calc(86px + 24px);
  }

  #feature-track {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
  }
`

const PageStyles = styled.div`
  padding: 24px 12px 0;
  ${pageStyles};

  display: grid;
  ${layoutMobile};
  @media (min-width: ${L2Breakpoints.mobile}px) {
    ${layoutDesktop};
  }
  max-width: 1112px;
  margin: 0 auto;

  .a-title {
    grid-area: title;
  }
  .a-description {
    grid-area: description;
  }
  .a-featured {
    grid-area: featured;
  }
  .a-projects {
    grid-area: projects;
  }
  margin-bottom: 48px;
`
