import {
  PreDepositRatesResponse,
  PreDepositStatsResponse,
} from '@/submodules/v3-shared/ts/connect/swell/v3/predeposit_pb'
import { BigNumber } from 'ethers'
import { formatUnits } from 'ethers/lib/utils'

export function calcPredepositTotalDepositedUsd({
  rates,
  stats,
  tokens,
}: {
  stats: PreDepositStatsResponse
  rates: PreDepositRatesResponse
  tokens: { address: string; decimals: number }[]
}) {
  const tokenStats: Record<
    string,
    {
      totalStaked: BigNumber
    }
  > = {}
  for (const token of stats.tokenStats) {
    tokenStats[token.tokenAddress] = {
      totalStaked: BigNumber.from(token.totalStaked),
    }
  }

  const addrToDecimalMap = new Map<string, number>()
  for (const token of tokens) {
    addrToDecimalMap.set(token.address, token.decimals)
  }
  const addrToRateMap = new Map<string, number>()
  for (const rate of rates.ratesUsd) {
    addrToRateMap.set(rate.tokenAddress, rate.rateCents / 100)
  }

  const addrToTotalStakedMap = new Map<string, BigNumber>()
  for (const [tokenAddress, tokenStat] of Object.entries(tokenStats)) {
    addrToTotalStakedMap.set(tokenAddress, tokenStat.totalStaked)
  }

  const depositedAddrs = Array.from(addrToTotalStakedMap.keys())

  let total = 0
  for (const addr of depositedAddrs) {
    const decimals = addrToDecimalMap.get(addr)
    if (!decimals) {
      console.warn('missing decimals for token', addr, addrToDecimalMap)
      continue
    }

    const deposited = addrToTotalStakedMap.get(addr)
    if (!deposited) {
      console.warn('missing deposited for token', addr)
      continue
    }

    const rate = addrToRateMap.get(addr)
    if (!rate) {
      console.warn('missing rate for token', addr)
      continue
    }

    const depositedFloat = parseFloat(formatUnits(deposited, decimals))
    const depositedUsd = depositedFloat * rate
    total += depositedUsd
  }

  return total
}
