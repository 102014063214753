import { Web3Toast } from '../../components/StakingConfirmationProgressWidget/Web3Toast'
import { useEffect } from 'react'
import { displayCryptoLocale } from '@/util/displayCrypto'
import { useTransactionContext } from '@/state/transactions/context'
import {
  PredepositTokens,
  WithdrawFromPredeposit,
} from '@/state/predeposit/hooks'

export const TRANSACTION_TOAST_TITLE = {
  COMPLETED: 'Transaction completed!',
  APPROVE_PROMPTING: 'Approval pending',
  APPROVE_PENDING: 'Approval confirming',
  APPROVE_COMPLETED: 'Approval completed',

  BRIDGE_PROMPTING: 'Bridge pending',
  BRIDGE_PENDING: 'Bridge confirming',
  BRIDGE_COMPLETED: 'Bridge started',
  BRIDGE_BRIDGING: 'Bridging',
  BRIDGE_BRIDGED: 'Bridged',

  WITHDRAW_PROMPTING: 'Withdraw pending',
  WITHDRAW_PENDING: 'Withdraw confirming',
  WITHDRAW_COMPLETED: 'Withdraw started',
}

export function WithdrawFromPredepositToast({
  withdrawFromPredeposit,
  predepositTokens,
}: {
  withdrawFromPredeposit: WithdrawFromPredeposit
  predepositTokens: Pick<PredepositTokens, 'tokenList'>
}) {
  const { anyTransactionInProgress } = useTransactionContext()
  const onClose = withdrawFromPredeposit.clear

  const complete =
    withdrawFromPredeposit.status === withdrawFromPredeposit.STATUS.FULFILLED
  const confirming =
    withdrawFromPredeposit.status === withdrawFromPredeposit.STATUS.PROMPTING
  const pending =
    withdrawFromPredeposit.status === withdrawFromPredeposit.STATUS.PENDING

  useEffect(() => {
    if (complete && anyTransactionInProgress) {
      onClose()
    }
  }, [complete, anyTransactionInProgress, onClose])

  let title = ''
  if (complete) {
    title = TRANSACTION_TOAST_TITLE.COMPLETED
  } else if (confirming) {
    title = TRANSACTION_TOAST_TITLE.WITHDRAW_PROMPTING
  } else if (pending) {
    title = TRANSACTION_TOAST_TITLE.WITHDRAW_PENDING
  }

  let message = ''
  if (withdrawFromPredeposit.args) {
    const [{ amount, assetAddress }] = withdrawFromPredeposit.args

    const t = predepositTokens.tokenList.tokens.find(
      (token) => token.address === assetAddress
    )
    if (t) {
      const amountStr = displayCryptoLocale(amount, t.decimals)
      message = `Withdraw ${amountStr} ${t.symbol}`
    }
  }

  return (
    <Web3Toast
      call={withdrawFromPredeposit}
      title={title}
      message={message}
      anyTransactionInProgress={anyTransactionInProgress}
    />
  )
}
