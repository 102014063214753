import { Typography } from '@/swell-ui/Typography'
import {
  VaultYieldSource,
  YearnAeraVault,
  YearnAeraVaultPoints,
  YearnWithdrawAssetV1,
} from '@/types/yearnAera'
import React, { ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { YearnAeraRewards } from './YearnAeraRewards'
import { ColoredHeadingTypography } from '@/swell-ui/Typography/typographyPresets'
import { YearnAeraDetails } from './YearnAeraDetails'
import { PointsCampaign } from '@/types/points'
import {
  YearnAllowances,
  YearnAuthResult,
  YearnBalances,
  YearnVaultState,
  YearnVaultStats,
  YearnWithdrawRequestResult,
} from '@/state/yearnVault/types'
import { YearnVaultWidget } from '../YearnAeraVaultWidget/YearnVaultWidget'
import { YearnVaultCalls } from '@/state/yearnVault/hooks'
import { YearnAeraPointsCampaigns } from './types'
import { ThemeData } from '@/swell-ui/theme/branding'
import {
  useYearnVaultCallsNotify,
  useYearnVaultLegacyCallsNotify,
} from './hooks'
import { PriceReading } from '@/state/prices/types'

export function YearnAeraPageTemplate({
  vault,
  title: customTitle,
  pageDescription,
  yieldSources,
  depositAssetUsdRate,
  vaultStats,
  allowances,
  auth,
  balances,
  calls,
  withdrawRequest,
  vaultState,
  pointsCampaigns,
  vaultDescription,
  vaultPoints,
  legacyCalls,
  legacyWithdrawRequest,
  legacyWithdrawAsset,
}: {
  vault: YearnAeraVault
  title: ReactNode
  pageDescription: ReactNode
  vaultDescription: ReactNode

  yieldSources: VaultYieldSource[]
  pointsCampaigns: YearnAeraPointsCampaigns

  depositAssetUsdRate: PriceReading | undefined

  // vault data
  vaultStats: YearnVaultStats | undefined
  vaultState: YearnVaultState | undefined
  vaultPoints: YearnAeraVaultPoints | undefined

  // user data
  auth: YearnAuthResult | undefined
  balances: YearnBalances | undefined
  allowances: YearnAllowances | undefined
  withdrawRequest: YearnWithdrawRequestResult | undefined
  legacyWithdrawRequest: YearnWithdrawRequestResult | undefined
  legacyWithdrawAsset: YearnWithdrawAssetV1 | undefined

  calls: YearnVaultCalls
  legacyCalls: Pick<YearnVaultCalls, 'cancelWithdraw'> | undefined
}) {
  useYearnVaultCallsNotify(calls)
  if (legacyCalls) {
    // warning: must not change otherwise hook order will be violated
    useYearnVaultLegacyCallsNotify(legacyCalls)
  }

  const title = (
    <Typography
      variant="headline"
      xs="h3"
      md="h2"
      size="h2"
      fstyle="bold"
      letterSpacing="small"
    >
      {customTitle}
    </Typography>
  )

  const description = (
    <Typography
      variant="body"
      xs="medium"
      md="medium"
      size="medium"
      letterSpacing="small"
    >
      {pageDescription}
    </Typography>
  )

  const pointsCampaignsList: PointsCampaign[] = [pointsCampaigns.symbiotic]
  if (pointsCampaigns.eigen) {
    pointsCampaignsList.push(pointsCampaigns.eigen)
  }
  if (pointsCampaigns.karak) {
    pointsCampaignsList.push(pointsCampaigns.karak)
  }

  return (
    <Layout className="v-page-layout">
      <div className="v-title">{title}</div>
      <div className="v-description">{description}</div>
      <div className="v-rewards">
        <ColoredHeadingTypography>
          Earn yield and rewards
        </ColoredHeadingTypography>
        <YearnAeraRewards
          pointsCampaignsList={pointsCampaignsList}
          yieldSources={yieldSources}
        />
      </div>
      <div className="v-details">
        <ColoredHeadingTypography>Vault details</ColoredHeadingTypography>
        <YearnAeraDetails
          vault={vault}
          vaultStats={vaultStats}
          vaultPoints={vaultPoints}
          balances={balances}
          depositAssetUsdRate={depositAssetUsdRate}
          vaultDescription={vaultDescription}
          pointsCampaigns={pointsCampaigns}
        />
      </div>
      <div className="v-widget">
        <YearnVaultWidget
          vault={vault}
          allowances={allowances}
          balances={balances}
          auth={auth}
          depositAssetUsdRate={depositAssetUsdRate}
          withdrawRequest={withdrawRequest}
          vaultStats={vaultStats}
          calls={calls}
          vaultState={vaultState}
          legacyCalls={legacyCalls}
          legacyWithdrawRequest={legacyWithdrawRequest}
          legacyWithdrawAsset={legacyWithdrawAsset}
        />
      </div>
    </Layout>
  )
}

const mobileLayout = css`
  padding-top: 24px;

  grid-template-columns: 1fr;
  grid-auto-rows: auto;
  align-items: center;
  justify-items: center;
  grid-template-areas:
    'title'
    'description'
    'rewards'
    'widget'
    'details';
  gap: 24px;

  .v-title,
  .v-description {
    text-align: left;
  }

  .v-title,
  .v-description {
    justify-self: start;
  }

  .v-widget {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    gap: 24px;
  }
`
const tabletLayout = css`
  max-width: 985px;
  margin: 0 auto;

  padding: 24px 12px 0;

  grid-template-columns: 1fr auto;
  grid-auto-rows: auto;
  grid-template-areas:
    'title'
    'description'
    'rewards'
    'widget'
    'details';
  gap: unset;
  row-gap: 24px;

  .v-title,
  .v-description,
  .v-rewards {
    justify-self: start;
  }

  .v-description {
    max-width: unset;
    text-align: left;
    margin-bottom: 12px;
  }

  .v-widget {
    margin-left: 35px;
  }
`
const desktopLayout = css`
  max-width: unset;
  margin: unset;

  grid-template-columns: minmax(auto, 858px) auto;
  grid-auto-rows: auto;
  grid-template-areas:
    'title widget'
    'description widget'
    'rewards widget'
    'details widget';
  gap: unset;

  .v-title {
    margin-bottom: 16px;
  }

  .v-description {
    margin-bottom: 35px;
  }

  .v-rewards {
    margin-bottom: 24px;
    justify-self: stretch;
  }

  .v-widget {
    margin-left: 35px;
  }
`
const Layout = styled.div`
  width: 100%;

  display: grid;

  .v-title {
    grid-area: title;
  }
  .v-description {
    grid-area: description;
    color: ${ThemeData.Symbiotic.symbioticLightGrey};
  }
  .v-rewards {
    grid-area: rewards;
    display: flex;
    flex-flow: column nowrap;
    gap: 12px;
    justify-self: stretch;
  }
  .v-widget {
    grid-area: widget;
    align-self: start;
  }
  .v-details {
    grid-area: details;
    display: flex;
    flex-flow: column nowrap;
    gap: 12px;
    justify-self: stretch;
  }

  ${mobileLayout}
  @media (min-width: 900px) {
    ${tabletLayout}
  }
  @media (min-width: 1391px) {
    ${desktopLayout}
  }
`
