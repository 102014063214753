import { createContext, useContext } from 'react'
import { WSwellMerklApi } from './types'
import { Token } from '@/types/tokens'

const MISSING_PROVIDER = Symbol()

export type WSwellMerklContext = WSwellMerklApi & {
  WSwellToken: Token
  maturityDurationUnix: number
}

export const WSwellMerklContext = createContext<
  WSwellMerklContext | typeof MISSING_PROVIDER
>(MISSING_PROVIDER)

export function useWSwellMerklApi() {
  const ctx = useContext(WSwellMerklContext)
  if (ctx === MISSING_PROVIDER) {
    throw new Error(
      'WSwellMerklContext hooks must be wrapped in a <WSwellMerklContext.Provider>'
    )
  }
  return ctx
}
