import styled, { keyframes } from 'styled-components'
import {
  GlobalBridgeEventsSummary,
  GlobalBridgeEventSummary,
} from '../../types'
import { ReactNode, RefObject, useEffect, useRef, useState } from 'react'
import { useNowMsAnimation } from '@/hooks/useTimeCountdown'

export function NewTxDrop({
  globalBridgeEventsSummary,
}: {
  globalBridgeEventsSummary: GlobalBridgeEventsSummary
}) {
  const nowMs = useNowMsAnimation()
  const [firstDataTimestamp, setFirstDataTimestamp] = useState<number | null>(
    null
  )
  useEffect(() => {
    if (globalBridgeEventsSummary.events.length > 0) {
      setFirstDataTimestamp((v) => {
        if (v) return v
        return nowMs
      })
    }
  }, [globalBridgeEventsSummary.events, nowMs])

  let justLoaded = false
  if (firstDataTimestamp) {
    const diff = nowMs - firstDataTimestamp
    if (diff < 400) {
      justLoaded = true
    }
  }

  const ref1 = useRef<HTMLSpanElement>(null)
  const ref2 = useRef<HTMLSpanElement>(null)
  const ref3 = useRef<HTMLSpanElement>(null)
  const ref4 = useRef<HTMLSpanElement>(null)
  const ref5 = useRef<HTMLSpanElement>(null)
  const ref6 = useRef<HTMLSpanElement>(null)
  const ref7 = useRef<HTMLSpanElement>(null)

  const nodes = globalBridgeEventsSummary.events.map((event, idx) => {
    let ref: RefObject<HTMLSpanElement> | null = null
    if (idx === 0) {
      ref = ref1
    } else if (idx === 1) {
      ref = ref2
    } else if (idx === 2) {
      ref = ref3
    } else if (idx === 3) {
      ref = ref4
    } else if (idx === 4) {
      ref = ref5
    } else if (idx === 5) {
      ref = ref6
    } else if (idx === 6) {
      ref = ref7
    }

    let y = 0
    let hide = false
    if (ref && ref.current) {
      const rect = ref.current.getBoundingClientRect()
      const parentRect = ref.current.parentElement?.getBoundingClientRect()
      if (parentRect) {
        y = rect.top - parentRect.top
      }
    } else {
      y = 0
      hide = true
    }

    if (hide) {
      return null
    }

    return (
      <BridgeEvent
        key={event.url}
        e={event}
        y={y}
        noAnimation={justLoaded || !firstDataTimestamp}
      />
    )
  })

  const tracks: ReactNode[] = []
  for (let i = 0; i < 7; i++) {
    let ref: RefObject<HTMLSpanElement> | null = null
    if (i === 0) {
      ref = ref1
    } else if (i === 1) {
      ref = ref2
    } else if (i === 2) {
      ref = ref3
    } else if (i === 3) {
      ref = ref4
    } else if (i === 4) {
      ref = ref5
    } else if (i === 5) {
      ref = ref6
    } else if (i === 6) {
      ref = ref7
    }

    tracks.push(
      <span
        key={i}
        style={{ visibility: 'hidden' }}
        className="body-small"
        ref={ref as any}
      >
        tx
      </span>
    )
  }

  let boxHeight: number | undefined
  if (ref1.current) {
    boxHeight = ref1.current.getBoundingClientRect().height * 6
  }

  return (
    <Layout style={{ height: `${boxHeight}px` }}>
      {nodes}
      {tracks}
    </Layout>
  )
}

const slideInFromRight = keyframes`
  from {
    transform: translateX(14%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  overflow: hidden;

  a {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
    color: var(--Swell-White-100, #e7e7e7);
    text-decoration: none;
    :hover {
      text-decoration: none;
      color: var(--Swell-White-100, #e7e7e7);
    }

    transition: opacity 0.35s;
    animation: ${slideInFromRight} 0.35s;
    &.no-animation {
      opacity: 0 !important;
      transform: unset !important;
    }

    &:nth-child(1) {
      opacity: 1;
    }
    &:nth-child(2) {
      opacity: 0.8;
    }
    &:nth-child(3) {
      opacity: 0.6;
    }
    &:nth-child(4) {
      opacity: 0.4;
    }
    &:nth-child(5) {
      opacity: 0.2;
    }
    &:nth-child(6) {
      opacity: 0.1;
    }
    &:nth-child(7) {
      opacity: 0;
    }
  }

  position: relative;
  .tx-event {
    position: absolute;
    top: 0;
    left: 0;
  }
`

function BridgeEvent({
  e,
  y: trueY,
  noAnimation,
}: {
  e: GlobalBridgeEventSummary
  y: number
  noAnimation?: boolean
}) {
  const [yContinuous, setYContinuous] = useState(trueY)
  useEffect(() => {
    let done = false
    function animate() {
      if (done) {
        return
      }
      setYContinuous((y) => {
        if (noAnimation) {
          return trueY
        }

        const diff = y - trueY
        if (Math.abs(diff) < 0.1) {
          return y
        }
        return y - diff * 0.1
      })
      requestAnimationFrame(animate)
    }
    requestAnimationFrame(animate)
    return () => {
      done = true
    }
  }, [trueY, noAnimation])

  const { account, amount, symbol, timeAgo, url } = e

  let className = 'body-small tx-event'
  if (noAnimation) {
    className += ' no-animation'
  }
  return (
    <a
      href={url}
      target="_blank"
      rel="noreferrer"
      className={className}
      style={{ top: `${yContinuous}px` }}
    >
      {timeAgo}: {account} bridged {amount} {symbol}
    </a>
  )
}
