import { useV3BackendClient } from '@/services/V3BackendService/hooks'
import { useL2PromotionsSettings } from '../deployments/hooks/useL2PromotionsSettings'
import { L2PromotionsContext } from './context'
import altlayerLogo from '@/assets/images/predeposit/altlayer.png'
import ambientLogo from '@/assets/images/predeposit/ambient.png'
import chainlinkLogo from '@/assets/images/predeposit/chainlink.png'
import eigenlayerLogo from '@/assets/images/predeposit/eigenlayer.png'
import etherfiLogo from '@/assets/images/predeposit/etherfi.png'
import ionLogo from '@/assets/images/predeposit/ion.png'
import redstoneLogo from '@/assets/images/predeposit/redstone.png'
import renzoLogo from '@/assets/images/predeposit/renzo.png'
import bubblyFinanceLogo from '@/assets/images/bubbly_finance_80x80.png'
import optimismLogo from '@/assets/images/optimism_160x160.png'
import eulerLogo from '@/assets/images/euler_160x160.png'
import hyperlaneLogo from '@/assets/images/hyperlane_150x150.png'
import neptuneLogo from '@/assets/images/neptune_finance_160x160.png'
import { L2PromoLaunchPartner } from './types'
import kelpDaoLogo from '@/assets/images/kelp_dao_120x120.png'
import metronomeLogo from '@/assets/images/metronome_logo_120x120.png'
import axisLogo from '@/assets/images/axis_logo_120x120.png'
import tempestLogo from '@/assets/images/tempest_logo_120x120.png'
import merklLogo from '@/assets/images/merkl_logo_120x120.png'
import {
  SWELLCHAIN_INCENTIVE_2024_LAUNCH,
  SWELLCHAIN_INCENTIVE_WAVEDROP3_PART2,
} from '@/constants/incentives'
import { parseL2BridgeTxEvent } from './util'
import { useCrossChainBridgeSettings } from '../deployments/hooks/crossChainBridgeSettings'
import { useChainInfo } from '../deployments/hooks'
import blockscoutLogo from '@/assets/images/blockscout_60x60.png'
import layerzeroLogo from '@/assets/images/layerzero_logo-round.png'
import ankrLogo from '@/assets/images/ankr_60x60.png'
import ethenaLogo from '@/assets/images/ethena_80x80.png'
import symbioticLogo from '@/assets/images/predeposit_assets/symbiotic_80x80.png'
import venusLogo from '@/assets/images/venus_160x160.png'
import dittoLogo from '@/assets/images/ditto-logo.png'

export function useL2PromotionApiImpl(): L2PromotionsContext {
  const { backendURL } = useL2PromotionsSettings()
  const statsSvc = useV3BackendClient(backendURL).v3BackendClient.stats
  const { l1Tokens } = useCrossChainBridgeSettings()
  const chainInfo = useChainInfo()

  return {
    recentGlobalEvents: (signal) => {
      return (async function* () {
        const seenEventIds = new Set<string>()

        while (true) {
          const resp = await statsSvc.pollingBridgeEvents(
            { numEvents: 6 },
            { signal }
          )
          for (const event of resp.events) {
            const eventId = event.id
            if (seenEventIds.has(eventId)) {
              continue
            }
            seenEventIds.add(eventId)

            try {
              yield parseL2BridgeTxEvent(event, chainInfo, l1Tokens)
            } catch (e) {
              console.error('parsing bridge event', e)
              continue
            }
          }
          await new Promise((resolve) => setTimeout(resolve, 12000))
        }
      })()
    },

    // recentGlobalEvents: (signal) => {
    //   const stream = statsSvc.bridgeEvents({}, { signal })
    //   return (async function* () {
    //     for await (const event of stream) {
    //       try {
    //         yield parseL2BridgeTxEvent(event, chainInfo, l1Tokens)
    //       } catch (e) {
    //         console.error('parsing bridge event', e)
    //         continue
    //       }
    //     }
    //   })()
    // },
    // recentGlobalEvents: () => {
    //   return (async function* () {
    //     let txId = 0
    //     while (true) {
    //       yield {
    //         timestampUnix: Date.now() / 1000,
    //         account: getAddress(hexZeroPad('0x69', 20)),
    //         amount: 420,
    //         symbol: 'USDT',
    //         url: 'https://bridge.com/tx/' + txId++,
    //       } as L2BridgeTxEvent
    //       await new Promise((resolve) =>
    //         setTimeout(resolve, Math.random() * 10000 + 60)
    //       )
    //     }
    //   })()
    // },
    builtWith: async () => {
      return [
        {
          name: 'Optimism',
          logoURI: optimismLogo,
          logoShape: 'circle',
        },
        {
          name: 'AltLayer',
          logoURI: altlayerLogo,
          logoShape: 'circle',
        },
        {
          name: 'Chainlink',
          logoURI: chainlinkLogo,
          logoShape: 'circle',
        },
        {
          name: 'Redstone',
          logoShape: 'circle',
          logoURI: redstoneLogo,
        },
        {
          name: 'Blockscout',
          logoURI: blockscoutLogo,
          logoShape: 'circle',
        },
        {
          name: 'LayerZero',
          logoURI: layerzeroLogo,
          logoShape: 'circle',
        },
        {
          name: 'Ankr',
          logoURI: ankrLogo,
          logoShape: 'circle',
        },
        {
          name: 'Hyperlane',
          logoURI: hyperlaneLogo,
          logoShape: 'circle',
        },
      ]
    },
    launchPartners: async () => {
      const lps: L2PromoLaunchPartner[] = [
        {
          name: 'EigenLayer',
          description: '',
          kind: 'major',
          logoShape: 'circle',
          logoURI: eigenlayerLogo,
        },
        {
          name: 'Symbiotic',
          description: '',
          kind: 'major',
          logoShape: 'circle',
          logoURI: symbioticLogo,
        },
        {
          name: 'Ethena',
          description: '',
          kind: 'major',
          logoShape: 'circle',
          logoURI: ethenaLogo,
        },
        {
          name: 'ether.fi',
          description: '',
          kind: 'major',
          logoShape: 'circle',
          logoURI: etherfiLogo,
        },
        {
          name: 'Renzo',
          description: '',
          kind: 'major',
          logoShape: 'circle',
          logoURI: renzoLogo,
        },
        {
          name: 'Kelp DAO',
          description: '',
          kind: 'major',
          logoShape: 'circle',
          logoURI: kelpDaoLogo,
        },
        {
          name: 'Euler Finance',
          description: 'Lend, borrow and build without limits',
          kind: 'dapps',
          logoShape: 'circle',
          logoURI: eulerLogo,
        },
        {
          name: 'Ion Protocol',
          description: 'The lending protocol for staked and restaked assets',
          kind: 'dapps',
          logoShape: 'circle',
          logoURI: ionLogo,
        },
        {
          name: 'Ambient',
          description: 'Zero-to-One Decentralized Trading Protocol',
          kind: 'dapps',
          logoShape: 'circle',
          logoURI: ambientLogo,
        },
        {
          name: 'Bubbly',
          description:
            'The first AMM-based pre-market DEX, trading like Uniswap',
          kind: 'dapps',
          logoShape: 'circle',
          logoURI: bubblyFinanceLogo,
        },
        {
          name: 'Neptune Finance',
          description: 'The first all-in-one DEX built on Swell Chain',
          kind: 'dapps',
          logoShape: 'circle',
          logoURI: neptuneLogo,
        },
        {
          name: 'Metronome',
          description: '',
          kind: 'dapps',
          logoShape: 'circle',
          logoURI: metronomeLogo,
        },
        {
          name: 'Axis',
          description: '',
          kind: 'dapps',
          logoShape: 'circle',
          logoURI: axisLogo,
        },
        {
          name: 'Tempest',
          description: '',
          kind: 'dapps',
          logoShape: 'circle',
          logoURI: tempestLogo,
        },
        {
          name: 'Merkl',
          description: '',
          kind: 'dapps',
          logoShape: 'circle',
          logoURI: merklLogo,
        },
        {
          name: 'Venus',
          description: '',
          kind: 'dapps',
          logoShape: 'circle',
          logoURI: venusLogo,
        },
        {
          name: 'Ditto',
          description: '',
          kind: 'avs',
          logoShape: 'circle',
          logoURI: dittoLogo,
        },
        {
          name: 'Hyperlane',
          description: '',
          kind: 'avs',
          logoShape: 'circle',
          logoURI: hyperlaneLogo,
        },
        {
          name: 'AltLayer',
          description: '',
          kind: 'avs',
          logoShape: 'circle',
          logoURI: altlayerLogo,
        },
      ]
      return lps
    },
    stats: async () => {
      return {
        currentIncentive: SWELLCHAIN_INCENTIVE_WAVEDROP3_PART2,
      }
    },
  }
}
