// @generated by protoc-gen-connect-es v1.1.2 with parameter "target=ts"
// @generated from file swell/v3/monitoring.proto (package swell.v3, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { RpcMonitorRequest, RpcMonitorResponse } from "./monitoring_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service swell.v3.MonitoringService
 */
export const MonitoringService = {
  typeName: "swell.v3.MonitoringService",
  methods: {
    /**
     * @generated from rpc swell.v3.MonitoringService.RpcMonitor
     */
    rpcMonitor: {
      name: "RpcMonitor",
      I: RpcMonitorRequest,
      O: RpcMonitorResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

