import { useSwellWeb3 } from '@/swell-web3/core'
import { useCallback } from 'react'

export function useAddSwellchainToWallet() {
  const { provider } = useSwellWeb3()

  return useCallback(() => {
    if (!provider) {
      return
    }

    const chainId = '0x783'
    const chainName = 'Swellchain'
    const rpcUrls = ['https://swell-mainnet.alt.technology']
    const iconUrls = [
      'https://ipfs.io/ipfs/QmZcZreqE6P7JQCduEkXtni8UTJVjtX1PEiPFm7WpSEPc3',
    ]
    const nativeCurrency = {
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18,
    }
    const blockExplorerUrls = ['https://explorer.swellnetwork.io/']

    provider
      .send('wallet_addEthereumChain', [
        {
          chainId,
          chainName,
          rpcUrls,
          iconUrls,
          nativeCurrency,
          blockExplorerUrls,
        },
      ])
      .catch((error: any) => {
        console.error('Error adding chain to wallet', error)
      })
  }, [provider])
}
