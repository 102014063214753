import {
  EarnPointsCampaign,
  EarnRewardAsset,
  GlobalEarnCampaign,
} from '@/types/earn'
import { TOKEN_LIST_SWELL } from './tokens'
import { ROUTES } from '@/constants/routes'
import {
  URI_AMBIENT_LOGO,
  URI_BLACK_PEARL,
  URI_EIGEN_LOGO,
  URI_EULER_LOGO,
  URI_ION_LOGO,
  URI_NEPTUNE_LOGO,
  URI_SWELL_L2_POINTS_LOGO,
  URI_SYMBIOTIC_LOGO,
  URI_TEMPEST_LOGO,
  URI_WHITE_PEARL,
} from './logo'

// REWARD ASSETS
export const KnownEarnClaimableAssetIds = {
  SWELL: 'SWELL',
  ION: 'ION',
  NEPTUNE: 'NEP',
  AMBIENT: 'AMB',
  EULER: 'EUL',
  TEMPEST: 'TEMPEST',
  EIGEN: 'EIGEN',
}

export const REWARD_ASSET_SWELL: EarnRewardAsset = {
  assetId: TOKEN_LIST_SWELL.symbol,
  assetName: 'SWELL',
  logoURI: TOKEN_LIST_SWELL.logoURI,
  squareLogo: false,
}
export const REWARD_ASSET_ION: EarnRewardAsset = {
  assetId: 'ION',
  assetName: 'Ion Protocol',
  squareLogo: false,
  logoURI: URI_ION_LOGO,
}

export const REWARD_ASSET_NEPTUNE: EarnRewardAsset = {
  assetId: 'NEP',
  assetName: 'Neptune',
  squareLogo: false,
  logoURI: URI_NEPTUNE_LOGO,
}
export const REWARD_ASSET_AMBIENT: EarnRewardAsset = {
  assetId: 'AMB',
  assetName: 'Ambient Finance',
  squareLogo: false,
  logoURI: URI_AMBIENT_LOGO,
}
export const REWARD_ASSET_EULER: EarnRewardAsset = {
  assetId: 'EUL',
  assetName: 'Euler',
  squareLogo: false,
  logoURI: URI_EULER_LOGO,
}
export const REWARD_ASSET_TEMPEST: EarnRewardAsset = {
  assetId: 'TEMP',
  assetName: 'Tempest',
  squareLogo: false,
  logoURI: URI_TEMPEST_LOGO,
}

// POINTS
export const KnownEarnPointsCampaignIds = {
  WHITE_PEARLS: 'WHITE_PEARLS',
  BLACK_PEARLS: 'BLACK_PEARLS',
  ECOSYSTEM_POINTS: 'ECOSYSTEM_POINTS',
  SYMBIOTIC: 'SYMBIOTIC',
  EIGEN: 'EIGEN',
}

export const POINTS_CAMPAIGN_WHITE_PEARLS: EarnPointsCampaign = {
  pointsId: KnownEarnPointsCampaignIds.WHITE_PEARLS,
  pointsName: 'White Pearls',
  logoURI: URI_WHITE_PEARL,
  squareLogo: false,
}
export const POINTS_CAMPAIGN_BLACK_PEARLS: EarnPointsCampaign = {
  pointsId: KnownEarnPointsCampaignIds.BLACK_PEARLS,
  pointsName: 'Black Pearls',
  logoURI: URI_BLACK_PEARL,
  squareLogo: false,
}
export const POINTS_CAMPAIGN_ECOSYSTEM_POINTS: EarnPointsCampaign = {
  pointsId: KnownEarnPointsCampaignIds.ECOSYSTEM_POINTS,
  pointsName: 'Ecosystem Points',
  logoURI: URI_SWELL_L2_POINTS_LOGO,
  squareLogo: true,
}
export const POINTS_CAMPAIGN_SYMBIOTIC: EarnPointsCampaign = {
  pointsId: KnownEarnPointsCampaignIds.SYMBIOTIC,
  pointsName: 'Symbiotic',
  logoURI: URI_SYMBIOTIC_LOGO,
  squareLogo: false,
}

// GLOBAL CAMPAIGNS
export const KnownEarnCampaignIds = {
  EIGEN: 'eigen' as const,
  VOYAGE: 'voyage' as const,
  WAVEDROP_1: 'wavedrop_1' as const,
  WAVEDROP_2: 'wavedrop_2' as const,
  WAVEDROP_3: 'wavedrop_3' as const,
  L2_LAUNCH: 'l2_launch' as const,
  SYMBIOTIC: 'symbiotic' as const,
}

export const EARN_CAMPAIGN_VOYAGE: GlobalEarnCampaign = {
  campaignName: 'The Voyage',
  campaignId: KnownEarnCampaignIds.VOYAGE,
  claimSpec: {
    type: 'url',
    url: ROUTES.DaoVoyage,
  },
  logoURI: URI_WHITE_PEARL,
  squareLogo: false,
  pointsId: POINTS_CAMPAIGN_BLACK_PEARLS.pointsId,
  claimableAssetId: KnownEarnClaimableAssetIds.SWELL,
}
export const EARN_CAMPAIGN_WAVEDROP_1: GlobalEarnCampaign = {
  campaignName: 'Wavedrop 1',
  campaignId: KnownEarnCampaignIds.WAVEDROP_1,
  claimSpec: {
    type: 'url',
    url: ROUTES.DaoSwellCity,
  },
  logoURI: URI_BLACK_PEARL,
  squareLogo: false,
  pointsId: POINTS_CAMPAIGN_BLACK_PEARLS.pointsId,
  claimableAssetId: KnownEarnClaimableAssetIds.SWELL,
}
export const EARN_CAMPAIGN_WAVEDROP_2: GlobalEarnCampaign = {
  campaignName: 'Wavedrop 2',
  campaignId: KnownEarnCampaignIds.WAVEDROP_2,
  claimSpec: {
    type: 'url',
    url: ROUTES.DaoSwellCity,
  },
  logoURI: URI_BLACK_PEARL,
  squareLogo: false,
  pointsId: POINTS_CAMPAIGN_BLACK_PEARLS.pointsId,
  claimableAssetId: KnownEarnClaimableAssetIds.SWELL,
}
export const EARN_CAMPAIGN_WAVEDROP_3: GlobalEarnCampaign = {
  campaignName: 'Wavedrop 3',
  campaignId: KnownEarnCampaignIds.WAVEDROP_3,
  claimSpec: { type: 'url', url: ROUTES.EarnWSwell },
  logoURI: TOKEN_LIST_SWELL.logoURI,
  squareLogo: false,
  pointsId: '',
  claimableAssetId: KnownEarnClaimableAssetIds.SWELL,
}
export const EARN_CAMPAIGN_L2_LAUNCH: GlobalEarnCampaign = {
  campaignName: 'Swell L2 - Pre launch',
  campaignId: KnownEarnCampaignIds.L2_LAUNCH,
  claimSpec: { type: 'url', url: ROUTES.DaoSwellCity },
  logoURI: URI_SWELL_L2_POINTS_LOGO,
  squareLogo: true,
  pointsId: KnownEarnPointsCampaignIds.ECOSYSTEM_POINTS,
  claimableAssetId: KnownEarnClaimableAssetIds.SWELL,
}
export const EARN_CAMPAIGN_SYMBIOTIC: GlobalEarnCampaign = {
  campaignId: KnownEarnCampaignIds.SYMBIOTIC,
  campaignName: 'Symbiotic',
  claimSpec: { type: 'notAvailable' },
  logoURI: URI_SYMBIOTIC_LOGO,
  squareLogo: false,
  pointsId: KnownEarnPointsCampaignIds.SYMBIOTIC,
  claimableAssetId: '',
}
export const EARN_CAMPAIGN_EIGEN: GlobalEarnCampaign = {
  campaignId: KnownEarnCampaignIds.EIGEN,
  campaignName: 'EIGEN',
  claimSpec: { type: 'eigenStakeDrop' },
  logoURI: URI_EIGEN_LOGO,
  squareLogo: false,
  pointsId: '',
  claimableAssetId: KnownEarnClaimableAssetIds.EIGEN,
}
