import { FlexRow } from '@/swell-ui/FlexRow'
import { ThemeData } from '@/swell-ui/theme/branding'
import { Typography } from '@/swell-ui/Typography'
import { Token } from '@/types/tokens'
import { trimDecimalPlaces } from '@/util/number'
import { formatUnits } from 'ethers/lib/utils'
import { ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { AsyncDiv } from '@/swell-ui/AsyncDiv'
import { CrossChainTokenBalances } from '@/types/crossChainBridge'
import { EthInput } from '@/swell-ui/inputs'
import {
  BridgeInputs,
  PredepositInputs,
  PredepositWithdrawalSummary,
  PredepositWithdrawController,
  QuoteSummaryResult,
} from '@/pages/Swellchain/types'
import { PredepositUser } from '@/state/predeposit/types'
import { useTransactionContext } from '@/state/transactions/context'
import { NucleusBalances } from '@/state/nucleusVault/types'

const StyledEthInput = styled(EthInput)`
  ${({ theme }) => css`
    .MuiFormHelperText-root {
      margin-top: 2px;
      margin-bottom: 16px;
    }

    ${theme.breakpoints.up('sm')} {
      max-width: unset;
    }
  `}
  .MuiInputBase-root:after {
    border-color: ${ThemeData.Swell.SwellVaultTurquoise};
  }
  .Mui-disabled.MuiInputBase-root:before {
    border-bottom-style: solid;
    opacity: 0.6;
  }
`
const EthInputWrapper = styled.div`
  position: relative;
  display: flex;
  gap: 8px;
  justify-content: space-between;
  align-items: center;

  .MuiFormControl-root {
    width: 100%;
  }

  input,
  div > div > p {
    font-size: ${({ theme }) => theme.typography.body.large.fontSize};
    font-weight: 600;
  }
`

type BridgeInputsFrom = Pick<
  BridgeInputs,
  'fromAmount' | 'setFromAmountInput' | 'fromTokenSymbol' | 'fromAmountInput'
>
export function BridgeFromInput({
  errorMessage,
  fromToken,
  setTouched,
  usdLabel,
  bridgeInputs,
  account,
  quoteResult,
  fromBalances,
}: {
  bridgeInputs: BridgeInputsFrom
  errorMessage: string | null
  fromToken: Token | undefined
  usdLabel: string | undefined
  setTouched: (touched: boolean) => void
  account: string | undefined
  fromBalances: CrossChainTokenBalances[number] | undefined
  quoteResult?: QuoteSummaryResult
}) {
  let disabled = false
  if (account) {
    if (!fromBalances) {
      disabled = true
    }
  }
  if (!fromToken) {
    disabled = true
  }

  const onChange = (event: any) => {
    const value = event.target.value
    if (value === '') {
      bridgeInputs.setFromAmountInput('')
      return
    }

    setTouched(true)

    const decimalPlaces = fromToken!.decimals
    const valueClean = trimDecimalPlaces(value, decimalPlaces)
    bridgeInputs.setFromAmountInput(valueClean)
  }

  const handleMaxClick = () => {
    if (disabled) return

    const v = fromBalances?.[fromToken!.address]?.result

    if (!v) return

    onChange({
      target: {
        value: formatUnits(v, fromToken!.decimals),
      },
    })
  }

  const valueStr = bridgeInputs.fromAmountInput

  const gasLabel = quoteResult?.summary?.gasFee

  return (
    <InputLayout
      hasError={!!errorMessage}
      usdLabel={usdLabel}
      gasLabel={gasLabel}
      showGas
      showUsd
    >
      <EthInputWrapper>
        <StyledEthInput
          variant="standard"
          value={valueStr}
          onChange={onChange}
          error={!!errorMessage}
          helperText={errorMessage}
          disabled={disabled}
          onMaxClick={handleMaxClick}
        />
      </EthInputWrapper>
    </InputLayout>
  )
}

export function WithdrawFromPredepositInput({
  predepositInputs,
  predepositWithdrawalSummary,
  errorMessage,
  setTouched,
  predepositUser,
  account,
  controller,
  balances,
}: {
  predepositInputs: Pick<
    PredepositInputs,
    'setWithdrawAmountInput' | 'withdrawAmountInput' | 'withdrawToken'
  >
  predepositWithdrawalSummary: Pick<
    PredepositWithdrawalSummary,
    'gasCost' | 'valueUsd'
  >
  errorMessage: string | null
  setTouched: (touched: boolean) => void
  account: string | undefined
  predepositUser: PredepositUser | undefined
  controller: PredepositWithdrawController
  balances: NucleusBalances | undefined
}) {
  const { anyTransactionInProgress } = useTransactionContext()
  const { gasCost, valueUsd } = predepositWithdrawalSummary
  const { withdrawAmountInput, setWithdrawAmountInput, withdrawToken } =
    predepositInputs

  let disabled = anyTransactionInProgress || !withdrawToken
  if (account && !predepositUser) {
    disabled = true
  }

  const onChange = (event: any) => {
    const value = event.target.value
    if (value === '') {
      setWithdrawAmountInput('')
      return
    }

    setTouched(true)

    const decimalPlaces = withdrawToken!.decimals
    const valueClean = trimDecimalPlaces(value, decimalPlaces)
    setWithdrawAmountInput(valueClean)
  }

  const handleMaxClick = () => {
    if (disabled) return
    if (!predepositUser) return
    if (!withdrawToken) return

    const amt = predepositUser.stakes[withdrawToken.address]
    if (!amt) return

    onChange({
      target: {
        value: formatUnits(amt, withdrawToken.decimals),
      },
    })
  }

  let error = false
  let helperText: string | undefined = undefined
  if (errorMessage) {
    error = true
    helperText = errorMessage
  }
  if (anyTransactionInProgress) {
    error = false
    helperText = undefined
  }

  return (
    <InputLayout
      usdLabel={valueUsd}
      hasError={!!errorMessage}
      gasLabel={gasCost}
      showGas
      showUsd
    >
      <EthInputWrapper>
        <StyledEthInput
          variant="standard"
          value={withdrawAmountInput}
          onChange={onChange}
          error={error}
          helperText={helperText}
          disabled={disabled}
          onMaxClick={handleMaxClick}
        />
      </EthInputWrapper>
    </InputLayout>
  )
}

const InfoTypography = styled(Typography)`
  height: 19.2px;
  color: ${({ theme }) => theme.fiatColor};
  opacity: 0.6;
  letter-spacing: -0.03em;
`
const HiddenInfoTypography = styled(InfoTypography)`
  visibility: hidden;
`

function InputLayout({
  children,
  usdLabel,
  hasError,
  gasLabel,
  showGas,
  showTime,
  showUsd,
  timeLabel,
}: {
  children: React.ReactNode

  hasError: boolean

  usdLabel?: string | undefined
  gasLabel?: string | undefined
  timeLabel?: string | undefined

  showTime?: boolean
  showGas?: boolean
  showUsd?: boolean
}) {
  let usdNode: ReactNode = <div style={{ height: '0px' }} />
  let gasNode: ReactNode = <div style={{ height: '0px' }} />
  let timeNode: ReactNode = <div style={{ height: '0px' }} />

  if (!hasError) {
    usdNode = usdLabel ? (
      <InfoTypography variant="body" size="xsmall" className="il-usd-node">
        {usdLabel}
      </InfoTypography>
    ) : (
      <HiddenInfoTypography
        variant="body"
        size="xsmall"
        className="il-usd-node"
      >
        $0.00
      </HiddenInfoTypography>
    )

    gasNode = (
      <FlexRow className="il-gas-node">
        <GasIcon />
        <AsyncDiv loading={!gasLabel}>
          {() => (
            <InfoTypography variant="body" size="xsmall">
              {gasLabel}
            </InfoTypography>
          )}
        </AsyncDiv>
      </FlexRow>
    )

    if (usdLabel === '$0.00') {
      usdNode = (
        <HiddenInfoTypography variant="body" size="xsmall">
          $0.00
        </HiddenInfoTypography>
      )
    }

    timeNode = (
      <FlexRow className="il-time-node">
        <TimeIcon />
        <AsyncDiv loading={!timeLabel}>
          {() => (
            <InfoTypography variant="body" size="xsmall">
              {timeLabel}
            </InfoTypography>
          )}
        </AsyncDiv>
      </FlexRow>
    )
  }

  return (
    <SInputLayout direction="column" gap="8" align="start">
      {children}
      {!hasError && (
        <FlexRow justify="space-between" gap="8" height="19.2px">
          {showUsd && <div style={{ height: '19.2px' }}>{usdNode}</div>}
          {showTime && <div style={{ height: '19.2px' }}>{timeNode}</div>}
          {showGas && <div style={{ height: '19.2px' }}>{gasNode}</div>}
        </FlexRow>
      )}
    </SInputLayout>
  )
}

const SInputLayout = styled(FlexRow)`
  position: relative;

  [aria-busy='false'] {
    min-width: unset;
    height: unset;
  }
  [aria-busy='true'] {
    min-width: 32px;
  }
  .il-usd-node {
    color: ${ThemeData.Swell.SwellLightBlue75} !important;
    margin-right: auto;
  }
  .il-gas-node {
    color: ${ThemeData.Swell.SwellLightBlue75} !important;
    margin-left: auto;
    display: flex;
    height: 19.2px;
    gap: 4px;
  }
  .il-time-node {
    color: ${ThemeData.Swell.SwellLightBlue75} !important;
    margin-left: 6px;
  }

  .MuiFormHelperText-root {
    height: 27.2px;
    margin: 0;
    padding-top: 2px;
  }
`

function GasIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
    >
      <g clip-path="url(#clip0_12148_2312)">
        <path
          d="M3.5 14V4C3.5 3.73478 3.60536 3.48043 3.79289 3.29289C3.98043 3.10536 4.23478 3 4.5 3H9.5C9.76522 3 10.0196 3.10536 10.2071 3.29289C10.3946 3.48043 10.5 3.73478 10.5 4V14"
          stroke={ThemeData.Swell.SwellLightBlue75}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M2 14H12"
          stroke={ThemeData.Swell.SwellLightBlue75}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M10.5 7.5H12C12.2652 7.5 12.5196 7.60536 12.7071 7.79289C12.8946 7.98043 13 8.23478 13 8.5V11C13 11.2652 13.1054 11.5196 13.2929 11.7071C13.4804 11.8946 13.7348 12 14 12C14.2652 12 14.5196 11.8946 14.7071 11.7071C14.8946 11.5196 15 11.2652 15 11V5.91437C15 5.78297 14.9741 5.65285 14.9238 5.53145C14.8736 5.41006 14.7998 5.29976 14.7069 5.20687L13.5 4"
          stroke={ThemeData.Swell.SwellLightBlue75}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8.5 7.5H5.5"
          stroke={ThemeData.Swell.SwellLightBlue75}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_12148_2312">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
function TimeIcon() {
  return <div>t</div>
}
