// @generated by protoc-gen-es v1.3.3 with parameter "target=ts"
// @generated from file swell/v3/nativebridge.proto (package swell.v3, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum swell.v3.NativeStatus
 */
export enum NativeStatus {
  /**
   * @generated from enum value: NATIVE_STATUS_UNKNOWN = 0;
   */
  UNKNOWN = 0,

  /**
   * @generated from enum value: NATIVE_STATUS_PENDING = 1;
   */
  PENDING = 1,

  /**
   * @generated from enum value: NATIVE_STATUS_CONFIRMED = 2;
   */
  CONFIRMED = 2,

  /**
   * @generated from enum value: NATIVE_STATUS_ERROR = 3;
   */
  ERROR = 3,

  /**
   * @generated from enum value: NATIVE_STATUS_REFUND = 4;
   */
  REFUND = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(NativeStatus)
proto3.util.setEnumType(NativeStatus, "swell.v3.NativeStatus", [
  { no: 0, name: "NATIVE_STATUS_UNKNOWN" },
  { no: 1, name: "NATIVE_STATUS_PENDING" },
  { no: 2, name: "NATIVE_STATUS_CONFIRMED" },
  { no: 3, name: "NATIVE_STATUS_ERROR" },
  { no: 4, name: "NATIVE_STATUS_REFUND" },
]);

/**
 * @generated from message swell.v3.NativeBridgeQuoteRequest
 */
export class NativeBridgeQuoteRequest extends Message<NativeBridgeQuoteRequest> {
  /**
   * @generated from field: int32 from_chain_id = 1;
   */
  fromChainId = 0;

  /**
   * @generated from field: int32 to_chain_id = 2;
   */
  toChainId = 0;

  /**
   * @generated from field: string from_token_address = 3;
   */
  fromTokenAddress = "";

  /**
   * @generated from field: string from_amount_wei = 4;
   */
  fromAmountWei = "";

  /**
   * @generated from field: int32 from_decimals = 5;
   */
  fromDecimals = 0;

  /**
   * @generated from field: string to_token_address = 6;
   */
  toTokenAddress = "";

  /**
   * @generated from field: int32 to_decimals = 7;
   */
  toDecimals = 0;

  /**
   * @generated from field: float slippage_percent = 8;
   */
  slippagePercent = 0;

  /**
   * @generated from field: string gas_on_destination_chain_wei = 9;
   */
  gasOnDestinationChainWei = "";

  /**
   * @generated from field: string from_account = 10;
   */
  fromAccount = "";

  /**
   * @generated from field: string to_account = 11;
   */
  toAccount = "";

  constructor(data?: PartialMessage<NativeBridgeQuoteRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.NativeBridgeQuoteRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "from_chain_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "to_chain_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "from_token_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "from_amount_wei", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "from_decimals", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "to_token_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "to_decimals", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 8, name: "slippage_percent", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 9, name: "gas_on_destination_chain_wei", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "from_account", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "to_account", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NativeBridgeQuoteRequest {
    return new NativeBridgeQuoteRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NativeBridgeQuoteRequest {
    return new NativeBridgeQuoteRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NativeBridgeQuoteRequest {
    return new NativeBridgeQuoteRequest().fromJsonString(jsonString, options);
  }

  static equals(a: NativeBridgeQuoteRequest | PlainMessage<NativeBridgeQuoteRequest> | undefined, b: NativeBridgeQuoteRequest | PlainMessage<NativeBridgeQuoteRequest> | undefined): boolean {
    return proto3.util.equals(NativeBridgeQuoteRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.NativeBridgeQuote
 */
export class NativeBridgeQuote extends Message<NativeBridgeQuote> {
  /**
   * @generated from field: int32 estTimeSeconds = 1;
   */
  estTimeSeconds = 0;

  /**
   * @generated from field: int32 fromChainId = 2;
   */
  fromChainId = 0;

  /**
   * @generated from field: string fromAmountWei = 3;
   */
  fromAmountWei = "";

  /**
   * @generated from field: int32 fromDecimals = 4;
   */
  fromDecimals = 0;

  /**
   * @generated from field: string fromTokenSymbol = 5;
   */
  fromTokenSymbol = "";

  /**
   * @generated from field: float fromAmountUsd = 6;
   */
  fromAmountUsd = 0;

  /**
   * @generated from field: float gasCostUsd = 7;
   */
  gasCostUsd = 0;

  /**
   * @generated from field: string rateWei = 8;
   */
  rateWei = "";

  /**
   * @generated from field: string toAmountWei = 9;
   */
  toAmountWei = "";

  /**
   * @generated from field: float toAmountUsd = 10;
   */
  toAmountUsd = 0;

  /**
   * @generated from field: int32 toChainId = 11;
   */
  toChainId = 0;

  /**
   * @generated from field: int32 toDecimals = 12;
   */
  toDecimals = 0;

  /**
   * @generated from field: string toTokenSymbol = 13;
   */
  toTokenSymbol = "";

  /**
   * @generated from field: string approveAddress = 14;
   */
  approveAddress = "";

  constructor(data?: PartialMessage<NativeBridgeQuote>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.NativeBridgeQuote";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "estTimeSeconds", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "fromChainId", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "fromAmountWei", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "fromDecimals", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "fromTokenSymbol", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "fromAmountUsd", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 7, name: "gasCostUsd", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 8, name: "rateWei", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "toAmountWei", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "toAmountUsd", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 11, name: "toChainId", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 12, name: "toDecimals", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 13, name: "toTokenSymbol", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "approveAddress", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NativeBridgeQuote {
    return new NativeBridgeQuote().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NativeBridgeQuote {
    return new NativeBridgeQuote().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NativeBridgeQuote {
    return new NativeBridgeQuote().fromJsonString(jsonString, options);
  }

  static equals(a: NativeBridgeQuote | PlainMessage<NativeBridgeQuote> | undefined, b: NativeBridgeQuote | PlainMessage<NativeBridgeQuote> | undefined): boolean {
    return proto3.util.equals(NativeBridgeQuote, a, b);
  }
}

/**
 * @generated from message swell.v3.NativeBridgePreparedTransaction
 */
export class NativeBridgePreparedTransaction extends Message<NativeBridgePreparedTransaction> {
  /**
   * @generated from field: uint32 chainId = 1;
   */
  chainId = 0;

  /**
   * @generated from field: string to = 2;
   */
  to = "";

  /**
   * @generated from field: string from = 3;
   */
  from = "";

  /**
   * @generated from field: string gasLimitWei = 4;
   */
  gasLimitWei = "";

  /**
   * @generated from field: string data = 5;
   */
  data = "";

  /**
   * @generated from field: string value = 6;
   */
  value = "";

  constructor(data?: PartialMessage<NativeBridgePreparedTransaction>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.NativeBridgePreparedTransaction";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "chainId", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "to", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "gasLimitWei", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "data", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NativeBridgePreparedTransaction {
    return new NativeBridgePreparedTransaction().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NativeBridgePreparedTransaction {
    return new NativeBridgePreparedTransaction().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NativeBridgePreparedTransaction {
    return new NativeBridgePreparedTransaction().fromJsonString(jsonString, options);
  }

  static equals(a: NativeBridgePreparedTransaction | PlainMessage<NativeBridgePreparedTransaction> | undefined, b: NativeBridgePreparedTransaction | PlainMessage<NativeBridgePreparedTransaction> | undefined): boolean {
    return proto3.util.equals(NativeBridgePreparedTransaction, a, b);
  }
}

/**
 * @generated from message swell.v3.NativeBridgeQuoteResponse
 */
export class NativeBridgeQuoteResponse extends Message<NativeBridgeQuoteResponse> {
  /**
   * @generated from field: bool found = 1;
   */
  found = false;

  /**
   * @generated from field: swell.v3.NativeBridgeQuote quote = 2;
   */
  quote?: NativeBridgeQuote;

  /**
   * @generated from field: swell.v3.NativeBridgePreparedTransaction preparedTransaction = 3;
   */
  preparedTransaction?: NativeBridgePreparedTransaction;

  constructor(data?: PartialMessage<NativeBridgeQuoteResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.NativeBridgeQuoteResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "found", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "quote", kind: "message", T: NativeBridgeQuote },
    { no: 3, name: "preparedTransaction", kind: "message", T: NativeBridgePreparedTransaction },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NativeBridgeQuoteResponse {
    return new NativeBridgeQuoteResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NativeBridgeQuoteResponse {
    return new NativeBridgeQuoteResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NativeBridgeQuoteResponse {
    return new NativeBridgeQuoteResponse().fromJsonString(jsonString, options);
  }

  static equals(a: NativeBridgeQuoteResponse | PlainMessage<NativeBridgeQuoteResponse> | undefined, b: NativeBridgeQuoteResponse | PlainMessage<NativeBridgeQuoteResponse> | undefined): boolean {
    return proto3.util.equals(NativeBridgeQuoteResponse, a, b);
  }
}

/**
 * @generated from message swell.v3.NativeBridgeTxState
 */
export class NativeBridgeTxState extends Message<NativeBridgeTxState> {
  /**
   * @generated from field: string tx_hash = 1;
   */
  txHash = "";

  /**
   * @generated from field: string account = 2;
   */
  account = "";

  /**
   * @generated from field: int32 timestampUnix = 3;
   */
  timestampUnix = 0;

  /**
   * @generated from field: int32 fromChainId = 4;
   */
  fromChainId = 0;

  /**
   * @generated from field: int32 toChainId = 5;
   */
  toChainId = 0;

  /**
   * @generated from field: string fromTokenSymbol = 6;
   */
  fromTokenSymbol = "";

  /**
   * @generated from field: string toTokenSymbol = 7;
   */
  toTokenSymbol = "";

  /**
   * @generated from field: string fromAmountWei = 8;
   */
  fromAmountWei = "";

  /**
   * @generated from field: string toAmountWei = 9;
   */
  toAmountWei = "";

  /**
   * @generated from field: int32 fromDecimals = 10;
   */
  fromDecimals = 0;

  /**
   * @generated from field: int32 toDecimals = 11;
   */
  toDecimals = 0;

  /**
   * @generated from field: string error = 12;
   */
  error = "";

  /**
   * @generated from field: swell.v3.NativeStatus status = 13;
   */
  status = NativeStatus.UNKNOWN;

  /**
   * @generated from field: string url = 14;
   */
  url = "";

  constructor(data?: PartialMessage<NativeBridgeTxState>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.NativeBridgeTxState";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "tx_hash", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "account", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "timestampUnix", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "fromChainId", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "toChainId", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "fromTokenSymbol", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "toTokenSymbol", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "fromAmountWei", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "toAmountWei", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "fromDecimals", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 11, name: "toDecimals", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 12, name: "error", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "status", kind: "enum", T: proto3.getEnumType(NativeStatus) },
    { no: 14, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NativeBridgeTxState {
    return new NativeBridgeTxState().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NativeBridgeTxState {
    return new NativeBridgeTxState().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NativeBridgeTxState {
    return new NativeBridgeTxState().fromJsonString(jsonString, options);
  }

  static equals(a: NativeBridgeTxState | PlainMessage<NativeBridgeTxState> | undefined, b: NativeBridgeTxState | PlainMessage<NativeBridgeTxState> | undefined): boolean {
    return proto3.util.equals(NativeBridgeTxState, a, b);
  }
}

/**
 * @generated from message swell.v3.NativeBridgeTxStatusRequest
 */
export class NativeBridgeTxStatusRequest extends Message<NativeBridgeTxStatusRequest> {
  /**
   * @generated from field: repeated string tx_hashes = 1;
   */
  txHashes: string[] = [];

  constructor(data?: PartialMessage<NativeBridgeTxStatusRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.NativeBridgeTxStatusRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "tx_hashes", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NativeBridgeTxStatusRequest {
    return new NativeBridgeTxStatusRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NativeBridgeTxStatusRequest {
    return new NativeBridgeTxStatusRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NativeBridgeTxStatusRequest {
    return new NativeBridgeTxStatusRequest().fromJsonString(jsonString, options);
  }

  static equals(a: NativeBridgeTxStatusRequest | PlainMessage<NativeBridgeTxStatusRequest> | undefined, b: NativeBridgeTxStatusRequest | PlainMessage<NativeBridgeTxStatusRequest> | undefined): boolean {
    return proto3.util.equals(NativeBridgeTxStatusRequest, a, b);
  }
}

/**
 * @generated from message swell.v3.NativeBridgeTxStatusResponse
 */
export class NativeBridgeTxStatusResponse extends Message<NativeBridgeTxStatusResponse> {
  /**
   * @generated from field: repeated swell.v3.NativeBridgeTxState states = 1;
   */
  states: NativeBridgeTxState[] = [];

  constructor(data?: PartialMessage<NativeBridgeTxStatusResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "swell.v3.NativeBridgeTxStatusResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "states", kind: "message", T: NativeBridgeTxState, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NativeBridgeTxStatusResponse {
    return new NativeBridgeTxStatusResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NativeBridgeTxStatusResponse {
    return new NativeBridgeTxStatusResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NativeBridgeTxStatusResponse {
    return new NativeBridgeTxStatusResponse().fromJsonString(jsonString, options);
  }

  static equals(a: NativeBridgeTxStatusResponse | PlainMessage<NativeBridgeTxStatusResponse> | undefined, b: NativeBridgeTxStatusResponse | PlainMessage<NativeBridgeTxStatusResponse> | undefined): boolean {
    return proto3.util.equals(NativeBridgeTxStatusResponse, a, b);
  }
}

