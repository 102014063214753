import { createContext, useContext } from 'react'
import { Token } from '@/types/tokens'
import { SwETHApi } from './types'
import { ExitAsset } from '@/types/claims'

const MISSING_PROVIDER = Symbol()

export type ExitToWithdrawAsset = Map<string, Token>
export type WithdrawAssetToExit = Map<string, string>

export type SwETHContext = SwETHApi & {
  swETHToken: Token
  nativeCurrency: Token
  exitSwETHETH: ExitAsset
}

export const SwETHContext = createContext<
  SwETHContext | typeof MISSING_PROVIDER
>(MISSING_PROVIDER)

export function useSwETHApi() {
  const ctx = useContext(SwETHContext)
  if (ctx === MISSING_PROVIDER) {
    throw new Error(
      'SwETHContext hooks must be wrapped in a <SwETHContext.Provider>'
    )
  }
  return ctx
}
