import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { Accordion } from '@swell-ui/Accordion'
import { CaretDownIcon } from '@swell-ui/icons/CaretDownIcon'
import { CaretRightIcon } from '@swell-ui/icons/CaretRightIcon'
import { CaretUpIcon } from '@swell-ui/icons/CaretUpIcon'
import { FlexRow } from '@swell-ui/FlexRow'
import { Pagination } from '@swell-ui/Pagination'
import { Table, TableConfig } from '@swell-ui/Table'
import { Typography } from '@swell-ui/Typography'
import { Skeleton } from '@swell-ui/Skeleton'
import { ActivitySummary_Item } from '@/types/activity'
import { useNowMs } from '@/hooks/useTimeCountdown'
import { formatTimeAgoAbbrev } from '@/util/displayTime'

interface StakingPoolActivityTableProps {
  abbreviated?: boolean
  items?: ActivitySummary_Item[]
  pagination?: TableConfig['pagination']
  isLoading: boolean
  token: { logoURI: string }
}

type RowType = {
  wallet: string
  age: string
  action: string
  deposit: string
  receive: React.ReactNode
  link: React.ReactNode
  linkUrl: string
}

const COLUMNS: TableConfig['columns'] = [
  {
    key: 'wallet',
    label: 'Wallet',
  },
  {
    key: 'age',
    label: 'Age',
  },
  {
    key: 'action',
    label: 'Action',
  },
  {
    key: 'deposit',
    label: 'Deposit',
  },
  {
    key: 'receive',
    label: 'Receive',
  },
  {
    key: 'link',
    label: '',
  },
]

const LOADING_LENGTH = 9

const StyledTable = styled(Table)`
  .MuiTable-root {
    border-collapse: initial;
    border-spacing: 0 12px;
  }

  .MuiTableRow-root {
    height: 70px;
    margin-bottom: 6px;
  }

  .MuiTableRow-head {
    height: 22px;
    .MuiTableCell-root {
      // padding: 12px 16px 24px;
      leading-trim: both;
      text-edge: cap;
      font-size: 14px;
      line-height: 160%; /* 22.4px */
      letter-spacing: 2.52px;
      text-transform: uppercase;
    }
  }

  .MuiTableBody-root {
    .MuiTableCell-root {
      border-top: 1px solid #3068ef;
      border-bottom: 1px solid #3068ef;

      &:first-child {
        border-left: 1px solid #3068ef;
        border-top-left-radius: 16px;
        border-bottom-left-radius: 16px;
      }

      &:last-child {
        border-right: 1px solid #3068ef;
        border-top-right-radius: 16px;
        border-bottom-right-radius: 16px;
      }

      .MuiTableRow-root:hover {
        .MuiTableCell-root {
          background-color: ${({ theme }) => theme.colors.oceanBlue['125']};
        }
      }
    }
  }

  .wallet-cell,
  .age-cell,
  .action-cell,
  .deposit-cell,
  .receive-cell {
    min-width: 208px;
  }
`

const CenteredTypography = styled(Typography)`
  text-align: center;
`

function StakingPoolActivityTableFull({
  config,
  loading,
}: {
  config: any
  loading: boolean
}) {
  return <StyledTable loading={loading} config={config} />
}

const StyledAccordion = styled(Accordion)`
  .MuiAccordionSummary-content {
    background: ${({ theme }) => theme.accordionTable.summaryBgColor};
    margin: 0;
    margin-bottom: 8px;
    border-radius: 12px;

    &.Mui-expanded {
      background: ${({ theme }) => theme.accordionTable.expandedBgColor};
      margin: 0 0 8px 0;
    }
  }

  .MuiAccordionDetails-root {
    margin-bottom: 8px;
  }

  svg {
    path {
      fill: ${({ theme }) => theme.mainColor};
    }
  }

  font-size: 14px;
  line-height: 22.4px;
  letter-spacing: -0.03em;
`

const AccordionSummary = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 54px;
  padding: 0 16px;
`

const AccordionDetails = styled.div`
  background: ${({ theme }) => theme.accordionTable.expandedBgColor};
  padding: 16px;
  border-radius: 12px;
`

const LoaderWrapper = styled.div`
  flex: 1 1 auto;
  padding-right: 16px;
`

const DetailsItem = styled.div`
  display: flex;
  justify-content: space-between;

  svg {
    display: none;
  }
`

const Key = styled.div`
  width: 50%;
`

const Value = styled.div`
  flex: 1 1 auto;
`

const StyledPagination = styled(Pagination)`
  .MuiPagination-ul {
    justify-content: space-around;
  }
`

const ResultsTypography = styled(Typography)`
  color: ${({ theme }) => theme.colors.white['125']};
`

function ReceiveDisplay({
  item,
  token,
}: {
  item: ActivitySummary_Item
  token: { logoURI: string }
}) {
  return (
    <FlexRow gap="12">
      <img src={token.logoURI} width="28" height="28" />
      <span>{item.receive}</span>
    </FlexRow>
  )
}

function StakingPoolActivityTableMobile({
  config,
  loading,
}: {
  config: any
  loading: boolean
}) {
  const [whichOpen, setWhichOpen] = useState<number>(-1)

  const handleAccordionChange = (index: number) => {
    if (whichOpen === index) {
      return setWhichOpen(-1)
    }

    setWhichOpen(index)
  }

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    if (!config.pagination) {
      return
    }
    config.pagination.onChange(page)
  }

  const loadingArray = []
  for (let i = 0; i < config.loadingLength; i++) {
    loadingArray.push(i)
  }

  if (config.rows.length === 0 && !loading) {
    return (
      <CenteredTypography variant="body" size="medium">
        No transactions available.
      </CenteredTypography>
    )
  }

  return (
    <div>
      {loading &&
        loadingArray.map((index: number) => {
          const accordionConfig = {
            summary: (
              <AccordionSummary>
                <LoaderWrapper>
                  <Skeleton />
                </LoaderWrapper>
                <LoaderWrapper>
                  <Skeleton />
                </LoaderWrapper>
                <div>
                  {whichOpen !== index && <CaretDownIcon />}
                  {whichOpen === index && <CaretUpIcon />}
                </div>
              </AccordionSummary>
            ),
            details: <div></div>,
          }

          return (
            <StyledAccordion
              key={index}
              expanded={whichOpen === index}
              config={accordionConfig}
              onChange={() => handleAccordionChange(index)}
            />
          )
        })}
      {!loading &&
        config.rows.map((row: RowType, index: number) => {
          const accordionConfig = {
            summary: (
              <AccordionSummary>
                <Key>{row.age}</Key>
                <Value>{row.deposit}</Value>
                <div>
                  {whichOpen !== index && <CaretDownIcon />}
                  {whichOpen === index && <CaretUpIcon />}
                </div>
              </AccordionSummary>
            ),
            details: (
              <AccordionDetails>
                <DetailsItem>
                  <Key>Age</Key>
                  <Value>{row.age}</Value>
                  <div>
                    <CaretDownIcon />
                  </div>
                </DetailsItem>
                <DetailsItem>
                  <Key>Action</Key>
                  <Value>{row.action}</Value>
                  <div>
                    <CaretDownIcon />
                  </div>
                </DetailsItem>
                <DetailsItem>
                  <Key>Deposit</Key>
                  <Value>{row.deposit}</Value>
                  <div>
                    <CaretDownIcon />
                  </div>
                </DetailsItem>
                <DetailsItem>
                  <Key>Receive</Key>
                  <Value>{row.receive}</Value>
                  <div>
                    <CaretDownIcon />
                  </div>
                </DetailsItem>
                <DetailsItem>
                  <Key>Wallet</Key>
                  <Value>{row.wallet}</Value>
                  <div>
                    <CaretDownIcon />
                  </div>
                </DetailsItem>
              </AccordionDetails>
            ),
          }

          return (
            <StyledAccordion
              key={index}
              expanded={whichOpen === index}
              config={accordionConfig}
              onChange={() => handleAccordionChange(index)}
            />
          )
        })}
      <StyledPagination
        count={config.pagination.count}
        onChange={handlePageChange}
        siblingCount={0}
        boundaryCount={1}
      />
      {config.pagination.totalResults && (
        <FlexRow justify="center">
          <ResultsTypography variant="body" size="xsmall">
            {config.pagination.totalResults} results
          </ResultsTypography>
        </FlexRow>
      )}
    </div>
  )
}

function StakingPoolActivityTable({
  abbreviated,
  isLoading,
  pagination,
  items = [],
  token,
}: StakingPoolActivityTableProps) {
  const nowMs = useNowMs()

  const tableConfig = ((): TableConfig => {
    if (!items) {
      return {
        columns: COLUMNS,
        rows: [],
        loadingLength: LOADING_LENGTH,
        pagination,
      }
    }

    // TODO: need ability to tell if is a item or restake
    const rows = items.map((item: ActivitySummary_Item): RowType => {
      const timestampMs = item.timestampMs
      const age = formatTimeAgoAbbrev({ nowMs, timestampMs })

      return {
        wallet: item.wallet,
        age,
        action: item.action,
        deposit: item.deposit,
        receive: abbreviated ? (
          item.receive
        ) : (
          <ReceiveDisplay item={item} token={token} />
        ),
        link: <CaretRightIcon />,
        linkUrl: item.link,
      }
    })

    return {
      columns: COLUMNS,
      rows: rows,
      loadingLength: LOADING_LENGTH,
      pagination,
      onRowClick: abbreviated
        ? undefined
        : (row: any) => {
            window.open(row.linkUrl, '_blank')
          },
    }
  })()

  if (abbreviated) {
    return (
      <StakingPoolActivityTableMobile
        config={tableConfig}
        loading={isLoading}
      />
    )
  }

  const showTable = () => {
    return isLoading || items.length > 0
  }

  return (
    <>
      {!showTable() && (
        <CenteredTypography variant="body" size="medium">
          No transactions available.
        </CenteredTypography>
      )}
      {showTable() && (
        <StakingPoolActivityTableFull
          config={tableConfig}
          loading={isLoading}
        />
      )}
    </>
  )
}

export { StakingPoolActivityTable }
