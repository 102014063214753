// @generated by protoc-gen-connect-es v1.1.2 with parameter "target=ts"
// @generated from file swell/v3/lifibridge.proto (package swell.v3, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { KnownTokensRequest, KnownTokensResponse, LifiBridgeQuoteRequest, LifiBridgeQuoteResponse, LifiBridgeTxStatusRequest, LifiBridgeTxStatusResponse } from "./lifibridge_pb.js";
import { MethodIdempotency, MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service swell.v3.LifiBridgeService
 */
export const LifiBridgeService = {
  typeName: "swell.v3.LifiBridgeService",
  methods: {
    /**
     * @generated from rpc swell.v3.LifiBridgeService.Quote
     */
    quote: {
      name: "Quote",
      I: LifiBridgeQuoteRequest,
      O: LifiBridgeQuoteResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc swell.v3.LifiBridgeService.TxStatus
     */
    txStatus: {
      name: "TxStatus",
      I: LifiBridgeTxStatusRequest,
      O: LifiBridgeTxStatusResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.Idempotent,
    },
    /**
     * @generated from rpc swell.v3.LifiBridgeService.KnownTokens
     */
    knownTokens: {
      name: "KnownTokens",
      I: KnownTokensRequest,
      O: KnownTokensResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.Idempotent,
    },
  }
} as const;

