import { ROUTES } from '@/constants/routes'
import { Button } from '@/swell-ui/Button'
import { FlexRow } from '@/swell-ui/FlexRow'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import swellL2Logo from '@/assets/images/swell_l2_large.png'
import { CloseIcon } from '@/swell-ui/icons/CloseIcon'

const LEARN_MORE_LINK = 'https://www.swellnetwork.io/post/swellchain'

const headlinesH7 = css`
  color: var(--Swell-White-50, #fff);
  /* Headlines/H7 */
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 26px */
  letter-spacing: -0.6px;
`
const bodySmall = css`
  color: var(--Swell-White-50, #fff);
  /* Body/small */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  letter-spacing: -0.42px;
`

const linkCSS = css`
  color: var(--Swell-White-50, #fff);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  letter-spacing: -0.42px;
  text-decoration: underline !important;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: none;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;

  &:hover {
    color: var(--Swell-White-50, #fff);
  }
`

export function BannerL2Launch() {
  const [open, setOpen] = useState<boolean>(true)

  const handleClose = () => {
    setOpen(false)
  }

  if (!open) {
    return null
  }

  return (
    <Banner>
      <StyledCloseIcon onClick={handleClose} />
      <div className="b-logo">
        <img src={swellL2Logo} width={62} height={62} className="desktop" />
        <img src={swellL2Logo} width={28} height={28} className="mobile" />
      </div>
      <div className="b-title">
        <span className="headlines-h7">Swellchain has launched!</span>
      </div>
      <div className="b-content">
        <p className="body-small">
          Swellchain is the restaking chain, powered by Proof of Restake. Built
          on OP and part of the Superchain family. Join the early adopters and
          bridge your assets.{' '}
          <a href={LEARN_MORE_LINK} target="_blank" rel="noopener noreferrer">
            Learn more here
          </a>
          .
        </p>
      </div>
      <div className="b-button">
        <Link to={ROUTES.SwellchainLaunch}>
          <LaunchButton>Launch</LaunchButton>
        </Link>
      </div>
    </Banner>
  )
}

const StyledCloseIcon = styled(CloseIcon)`
  position: absolute;
  top: 4px;
  right: 10px;

  path {
    stroke: #fff;
  }

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`

const MOBILE_PX = 740

const layoutMobile = css`
  grid-template-columns: auto 1fr;
  grid-template-rows: auto auto auto;
  grid-template-areas:
    'logo     title'
    'content  content'
    'button   button';
  padding: 16px;

  .desktop {
    display: none;
  }

  .b-logo {
    justify-self: center;
    align-self: center;
    margin-right: 4px;
    display: flex;
  }

  @media (max-width: ${MOBILE_PX}px) {
    .b-title {
      align-self: center;
    }

    .b-button {
      margin-top: 12px;
      justify-self: center;
    }
  }
`
const layoutDesktop = css`
  max-width: 946px;
  min-height: 114px;

  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto auto;
  grid-template-areas:
    'logo title   button'
    'logo content button';

  .mobile {
    display: none;
  }
  .desktop {
    display: block;
  }

  .b-logo {
    justify-self: center;
    align-self: center;
    margin-right: 12px;
  }

  .b-button {
    justify-self: center;
    align-self: center;
    margin-left: 12px;
  }
`

const Banner = styled.div`
  position: relative;
  margin: 0 auto;

  display: grid;
  ${layoutMobile};
  @media (min-width: ${MOBILE_PX}px) {
    ${layoutDesktop};
  }

  border-radius: 16px;
  border: 1px solid var(--Gradient-Blue, #3068ef);

  background: var(
    --Gradient-Blue,
    linear-gradient(180deg, #3068ef 0.29%, #1322ac 167.95%)
  );

  .headlines-h7 {
    ${headlinesH7};
  }
  .body-small {
    ${bodySmall};
  }
  a {
    ${linkCSS};
  }
  p {
    margin: 0;
  }

  .b-logo {
    grid-area: logo;
  }
  .b-title {
    grid-area: title;
  }
  .b-content {
    grid-area: content;
  }
  .b-button {
    grid-area: button;
  }
`

const LaunchButton = styled(Button)`
  width: 162px;
  height: 46px;
  border-radius: 100px;
  border: 1px solid var(--Swell-White-50, #fff);
  background: #fff;
  color: var(--Swell-Lightblue-150, #252c73);
  text-align: center;
  /* Body/medium bold */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%; /* 25.6px */
`
