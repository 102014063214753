import React, { ReactNode, useState } from 'react'
import { Box } from '@/swell-ui/Box'
import styled from 'styled-components'
import { UserCampaignsSummary } from './types'
import { TableV2 } from '@/swell-ui/TableV2/TableV2'
import { ClaimLinkButton, EigenStakeDropClaimButton } from './PortfolioButtons'
import { FlexRow } from '@/swell-ui/FlexRow'
import { EARN_CAMPAIGN_EIGEN } from '@/constants/earn'
import { PreparedClaimEigenStakedrop } from '@/components/ClaimEigenWidget/eigenStakedropCalls'
import { ClaimEigenStakedrop } from '@/state/rsweth/hooks'

type Col = 'campaignName' | 'points' | 'claimable' | 'link'
type Row = { [key in Col]: ReactNode }
type TableProps = React.ComponentProps<typeof TableV2<Col, Row>>

export function PortfolioCampaignsTableBox({
  campaignsSummary,
  claimEigenStakedrop,
  preparedClaimEigenStakedrop,
}: {
  campaignsSummary: UserCampaignsSummary
  preparedClaimEigenStakedrop: PreparedClaimEigenStakedrop
  claimEigenStakedrop: ClaimEigenStakedrop
}) {
  const [sortBy, setSortBy] = useState<keyof Row | undefined>()
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc')

  type Intermediate = {
    campaignName: string
    logoURI: string
    squareLogo?: boolean
    points: string | null
    pointsNum: number | null
    pointsName: string
    pointsLogoURI?: string
    pointsSquareLogo?: boolean
    claimable: string | null
    claimableNum: number | null
    claimableAssetLogoURI?: string
    claimableAssetSquareLogo?: boolean
    link: string
    linkDisabled: boolean
    linkExternal: boolean
  }
  let partialRows: Intermediate[] = []
  for (const campaign of campaignsSummary.campaigns) {
    let claimable = campaign.claimable
    if (claimable === 'N/A') {
      claimable = '-'
    }
    let points = campaign.points
    if (points === 'N/A') {
      points = '-'
    }

    partialRows.push({
      campaignName: campaign.campaignName,
      logoURI: campaign.campaignLogoURI,
      squareLogo: campaign.campaignSquareLogo,
      points,
      claimable,
      link: campaign.claimURL,
      linkDisabled: campaign.showDisabledClaimLink,
      linkExternal: campaign.externalClaimLink,
      pointsNum: campaign.pointsNum,
      claimableNum: campaign.claimableNum,
      pointsName: campaign.pointsName,
      claimableAssetLogoURI: campaign.claimableAssetLogoURI,
      claimableAssetSquareLogo: campaign.claimableAssetSquareLogo,
      pointsLogoURI: campaign.pointsLogoURI,
      pointsSquareLogo: campaign.pointsSquareLogo,
    })
  }

  if (sortBy) {
    if (sortBy === 'campaignName') {
      partialRows = partialRows.sort((a, b) => {
        const v = a.campaignName.localeCompare(b.campaignName)
        return sortDirection === 'asc' ? v : -v
      })
    } else if (sortBy === 'points') {
      partialRows = partialRows.sort((a, b) => {
        let v = 0
        if (a.pointsNum === null) v = -1
        else if (b.pointsNum === null) v = 1
        else v = a.pointsNum - b.pointsNum

        return sortDirection === 'asc' ? v : -v
      })
    } else if (sortBy === 'claimable') {
      partialRows = partialRows.sort((a, b) => {
        let v = 0
        if (a.claimableNum === null) v = -1
        else if (b.claimableNum === null) v = 1
        else v = a.claimableNum - b.claimableNum

        return sortDirection === 'asc' ? v : -v
      })
    }
  }

  const rows: TableProps['rows'] = []
  for (const row of partialRows) {
    let claim = <ClaimLinkButton internal to={'#'} disabled label="N/A" />
    if (row.link) {
      if (row.linkExternal) {
        claim = (
          <ClaimLinkButton
            href={row.link}
            disabled={row.linkDisabled}
            internal={false}
          />
        )
      } else {
        claim = (
          <ClaimLinkButton to={row.link} disabled={row.linkDisabled} internal />
        )
      }
    } else {
      if (row.linkDisabled && row.linkExternal) {
        claim = <ClaimLinkButton internal={false} href={''} disabled />
      }
    }
    if (row.campaignName === EARN_CAMPAIGN_EIGEN.campaignName) {
      claim = (
        <EigenStakeDropClaimButton
          prepared={preparedClaimEigenStakedrop}
          claimEigenStakedrop={claimEigenStakedrop}
        />
      )
    }

    let pointsStr = row.points
    if (pointsStr && pointsStr !== '-') {
      pointsStr = `${pointsStr} ${row.pointsName}`
    }

    rows.push({
      _key: row.campaignName,
      campaignName: {
        loaded: true,
        result: (
          <CampaignTitle
            title={row.campaignName}
            logoURI={row.logoURI}
            sq={row.squareLogo}
          />
        ),
      },
      points: pointsStr
        ? {
            loaded: true,
            result: pointsStr,
          }
        : {
            loaded: false,
          },
      claimable: row.claimable
        ? {
            loaded: true,
            result: row.claimable,
          }
        : {
            loaded: false,
          },
      link: {
        loaded: true,
        result: claim,
      },
    })
  }

  const tableSortingBy: TableProps['sortingBy'] = sortBy
    ? {
        [sortBy]: sortDirection,
      }
    : undefined

  const onClickSortCampaignName = () => {
    const wasSortingByCampaignName = sortBy === 'campaignName'

    setSortBy('campaignName')

    let nextSortDirection: typeof sortDirection =
      sortDirection === 'asc' ? 'desc' : 'asc'
    if (!wasSortingByCampaignName) nextSortDirection = 'asc'

    setSortDirection(nextSortDirection)
  }
  const onClickSortPoints = () => {
    const wasSortingByPoints = sortBy === 'points'

    setSortBy('points')

    let nextSortDirection: typeof sortDirection =
      sortDirection === 'asc' ? 'desc' : 'asc'
    if (!wasSortingByPoints) nextSortDirection = 'desc'

    setSortDirection(nextSortDirection)
  }
  const onClickSortClaimable = () => {
    const wasSortingByClaimable = sortBy === 'claimable'

    setSortBy('claimable')

    let nextSortDirection: typeof sortDirection =
      sortDirection === 'asc' ? 'desc' : 'asc'
    if (!wasSortingByClaimable) nextSortDirection = 'desc'

    setSortDirection(nextSortDirection)
  }

  return (
    <SBox padding="24px">
      <TableV2
        rows={rows}
        loading={false}
        header={{
          campaignName: {
            label: 'Campaign',
            onClickSort: onClickSortCampaignName,
          },
          points: { label: 'Points', onClickSort: onClickSortPoints },
          claimable: { label: 'Claimable', onClickSort: onClickSortClaimable },
          link: { label: 'Claim Link', width: '100px', align: 'center' },
        }}
        sortingBy={tableSortingBy}
      />
    </SBox>
  )
}

const SBox = styled(Box)`
  flex-grow: 1;

  .MuiTableContainer-root {
    overscroll-behavior: unset;
  }
`

function CampaignTitle({
  title,
  logoURI,
  sq,
}: {
  title: string
  logoURI: string
  sq?: boolean
}) {
  return (
    <FlexRow gap="12">
      <img
        src={logoURI}
        alt={title}
        width="32"
        height="32"
        style={{ borderRadius: sq ? '0' : '50%' }}
      />
      <span>{title}</span>
    </FlexRow>
  )
}
