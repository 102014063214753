import { useDeploymentSetConfig } from '@/state/deployments/hooks'
import { useRef, useState } from 'react'
import styled from 'styled-components'

export function SuperbridgeView() {
  const {
    bridge: { superbridgeWidgetUrl },
  } = useDeploymentSetConfig()

  const [loaded, setLoaded] = useState(false)
  const iframeRef = useRef<HTMLIFrameElement>(null)

  // loaded -> todo make it look good

  return (
    <Layout>
      <iframe
        ref={iframeRef}
        src={superbridgeWidgetUrl}
        // @ts-ignore
        allowtransparency="true"
        onLoad={() => setLoaded(true)}
      />
    </Layout>
  )
}

const Layout = styled.div`
  height: 100%;
  display: flex;

  iframe {
    max-width: 100%;
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 20px;
  }
`
