// @generated by protoc-gen-connect-es v1.1.2 with parameter "target=ts"
// @generated from file swell/v3/bridge.proto (package swell.v3, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { BridgeTxStatusRequest, BridgeTxStatusResponse, CrossChainRoutesRequest, CrossChainRoutesResponse, PopulateCrossChainTransactionRequest, PopulateCrossChainTransactionResponse } from "./bridge_pb.js";
import { MethodIdempotency, MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service swell.v3.BridgeService
 */
export const BridgeService = {
  typeName: "swell.v3.BridgeService",
  methods: {
    /**
     * @generated from rpc swell.v3.BridgeService.Routes
     */
    routes: {
      name: "Routes",
      I: CrossChainRoutesRequest,
      O: CrossChainRoutesResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc swell.v3.BridgeService.PopulateTransaction
     */
    populateTransaction: {
      name: "PopulateTransaction",
      I: PopulateCrossChainTransactionRequest,
      O: PopulateCrossChainTransactionResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc swell.v3.BridgeService.TxStatus
     */
    txStatus: {
      name: "TxStatus",
      I: BridgeTxStatusRequest,
      O: BridgeTxStatusResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.Idempotent,
    },
  }
} as const;

