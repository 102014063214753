import { ChainInfo, getChainInfo } from '@/constants/chainInfo'
import useChainDetection from '@/hooks/useChainDetection'

export const useChainInfo = (): ChainInfo => {
  const {
    isTestnetChain,
    deploymentChainId,
    allowTestnet,
    chainId: currentChainId,
    isL2DeploymentChain,
    l2DeploymentChainId,
  } = useChainDetection()

  let chainId = deploymentChainId
  if (isTestnetChain && allowTestnet) {
    chainId = currentChainId
  }

  if (isL2DeploymentChain) {
    chainId = l2DeploymentChainId
  }

  return getChainInfo(chainId)
}

export default useChainInfo
