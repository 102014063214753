import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import { ModalOpenIcon } from '@swell-ui/icons/ModalOpenIcon'
import { TableConfig } from '@swell-ui/Table'
import { Typography } from '@swell-ui/Typography'
import { useMediaQuery } from '@swell-ui/theme/useMediaQuery'
import { useSwellUiTheme } from '@swell-ui/theme'
import {
  StakingPoolActivityAccordion,
  StakingPoolActivityAccordionV2Props,
} from './StakingPoolActivityAccordion'
import { StakingPoolActivityModal } from './StakingPoolActivityModal'
import { ActivitySummaryCounts, ActivitySummaryItems } from '@/types/activity'
import { StakingPoolActivityInputs } from '@/pages/StakeSwETH/types'

const StakingPoolActivitySection = styled.div`
  display: block;
  text-align: center;

  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`

const StyledModalOpenIcon = styled(ModalOpenIcon)`
  position: relative;
  top: 1.5px;
  margin-left: 4px;
`

export function StakingPoolActivityV2({
  title,
  activityCounts,
  activityItems,
  activityInputs,
  token,
}: {
  title: string
  activityItems: ActivitySummaryItems | undefined
  activityCounts: ActivitySummaryCounts | undefined
  activityInputs: StakingPoolActivityInputs
  token: { logoURI: string }
}) {
  const { theme } = useSwellUiTheme()
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'))

  const [modalOpen, setModalOpen] = useState<boolean>(false)

  const { selectedPage, setSelectedPage } = activityInputs

  const pagination = useMemo<TableConfig['pagination']>(() => {
    if (!activityCounts)
      return { count: 0, page: 1, totalResults: 0, onChange: () => {} }

    const { numPages, numResults } = activityCounts

    return {
      boundaryCount: 1,
      siblingCount: 1,
      count: numPages,
      page: selectedPage,
      totalResults: numResults,
      onChange: (page: number) => {
        setSelectedPage(page)
      },
    }
  }, [activityCounts, selectedPage, setSelectedPage])

  type SharedProps = Pick<
    StakingPoolActivityAccordionV2Props,
    'isLoading' | 'pagination' | 'items'
  >
  const items = activityItems?.items
  const sharedProps = useMemo<SharedProps>(() => {
    if (!items) {
      return {
        isLoading: true,
        stakes: [],
        pagination,
      }
    }

    return {
      isLoading: false,
      pagination,
      items,
    }
  }, [pagination, items])

  if (isSmUp) {
    return (
      <StakingPoolActivitySection>
        <Typography variant="body" size="small">
          <span onClick={() => setModalOpen(true)}>
            {title}
            <StyledModalOpenIcon />
          </span>
        </Typography>
        <StakingPoolActivityModal
          token={token}
          {...sharedProps}
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          title={title}
        />
      </StakingPoolActivitySection>
    )
  }

  return (
    <StakingPoolActivityAccordion
      token={token}
      title={title}
      {...sharedProps}
    />
  )
}
