import { Box } from '@/swell-ui/Box'
import styled from 'styled-components'
import {
  SwETHClaimSummary,
  SwETHDepositInputs,
  SwETHDepositSummary,
  SwETHStatsSummary,
  SwETHWithdrawInputs,
  SwETHWithdrawSummary,
  SwETHClaimInputs,
  StakingPoolActivityInputs,
} from './types'
import { ReactNode, useState } from 'react'
import { TabConfig, Tabs } from '@/swell-ui/Tabs'
import { SwETHClaimView } from './views/SwETHClaimView'
import { SwETHDepositView } from './views/SwETHDepositView'
import { SwETHCalls } from '@/state/sweth/hooks'
import {
  PreparedApproveSwETHForWithdrawal,
  PreparedFinalizeWithdrawalSwETH,
  PreparedSwETHCreateWithdrawRequest,
  PreparedSwETHDeposit,
} from './swETHCalls'
import { ActivitySummaryCounts, ActivitySummaryItems } from '@/types/activity'
import { Token } from '@/types/tokens'
import { css } from 'styled-components'
import {
  SwETHDepositToast,
  SwETHUnstakeToast,
  SwETHFinalizeWithdrawalToast,
  SwETHApproveUnstakeToast,
} from './SwETHToasts'
import { PrimaryRates } from '@/state/prices/types'
import { SwETHWithdrawalsUser } from '@/state/sweth/types'
import { useTransactionContext } from '@/state/transactions/context'

const Tab = {
  Deposit: 'Deposit' as const,
  Claim: 'Claim' as const,
}
type Tab = (typeof Tab)[keyof typeof Tab]
const DEFAULT_TAB = Tab.Deposit

export function SwETHWidget({
  claimSummary,
  depositSummary,
  statsSummary,
  withdrawSummary,
  claimInputs,
  depositInputs,
  withdrawInputs,
  activityCounts,
  activityItems,
  activityInputs,
  calls,
  nativeCurrency,
  preparedApproveSwETHForWithdrawal,
  preparedCreateWithdrawRequest,
  preparedDeposit,
  swETHToken,
  preparedFinalizeWithdrawRequest,
  primaryRates,
  withdrawUser,
}: {
  claimSummary: SwETHClaimSummary
  statsSummary: SwETHStatsSummary
  depositSummary: SwETHDepositSummary
  withdrawSummary: SwETHWithdrawSummary
  depositInputs: SwETHDepositInputs
  withdrawInputs: SwETHWithdrawInputs
  claimInputs: SwETHClaimInputs
  calls: SwETHCalls
  preparedDeposit: PreparedSwETHDeposit
  preparedApproveSwETHForWithdrawal: PreparedApproveSwETHForWithdrawal
  preparedCreateWithdrawRequest: PreparedSwETHCreateWithdrawRequest
  preparedFinalizeWithdrawRequest: PreparedFinalizeWithdrawalSwETH
  activityItems: ActivitySummaryItems | undefined
  activityCounts: ActivitySummaryCounts | undefined
  activityInputs: StakingPoolActivityInputs
  nativeCurrency: Token
  swETHToken: Token
  primaryRates: Pick<PrimaryRates, 'swETHPrimaryRate'> | undefined
  withdrawUser: SwETHWithdrawalsUser | undefined
}) {
  const { anyTransactionInProgress } = useTransactionContext()

  const [tab, setTab] = useState<Tab>(DEFAULT_TAB)
  const tabs: TabConfig[] = [{ id: Tab.Deposit, label: 'Stake' }]
  if (!claimSummary.isLoading) {
    const anyClaims = claimSummary.numTotalNum > 0
    if (anyClaims) {
      tabs.push({ id: Tab.Claim, label: 'Claim' })
    }
  }

  let content: ReactNode = null
  if (tab === Tab.Deposit) {
    content = (
      <SwETHDepositView
        depositSummary={depositSummary}
        depositInputs={depositInputs}
        withdrawInputs={withdrawInputs}
        withdrawSummary={withdrawSummary}
        statsSummary={statsSummary}
        calls={calls}
        preparedDeposit={preparedDeposit}
        preparedApproveSwETHForWithdrawal={preparedApproveSwETHForWithdrawal}
        preparedCreateWithdrawRequest={preparedCreateWithdrawRequest}
        activityItems={activityItems}
        activityCounts={activityCounts}
        activityInputs={activityInputs}
        nativeCurrency={nativeCurrency}
        swETHToken={swETHToken}
      />
    )
  }
  if (tab === Tab.Claim) {
    content = (
      <SwETHClaimView
        claimSummary={claimSummary}
        claimInputs={claimInputs}
        preparedFinalizeWithdrawRequest={preparedFinalizeWithdrawRequest}
        finalizeWithdrawal={calls.finalizeWithdrawal}
      />
    )
  }

  let switchToClaim: (() => void) | undefined
  if (tabs.some((t) => t.id === Tab.Claim)) {
    switchToClaim = () => setTab(Tab.Claim)
  }

  return (
    <>
      <StakingWidgetBox>
        <Tabs selectedTab={tab} setTab={(v) => setTab(v as Tab)} tabs={tabs} />
        {content}
      </StakingWidgetBox>
      <SwETHDepositToast
        swETHDeposit={calls.deposit}
        primaryRates={primaryRates}
        nativeCurrency={nativeCurrency}
        swETHToken={swETHToken}
        anyTransactionInProgress={anyTransactionInProgress}
      />
      <SwETHApproveUnstakeToast
        swETHToken={swETHToken}
        approveSwETHForWithdrawal={calls.approveSwETHForWithdrawal}
        anyTransactionInProgress={anyTransactionInProgress}
      />
      <SwETHUnstakeToast
        createWithdrawRequest={calls.createWithdrawRequest}
        primaryRates={primaryRates}
        anyTransactionInProgress={anyTransactionInProgress}
        switchToClaim={switchToClaim}
        swETHToken={swETHToken}
        nativeCurrency={nativeCurrency}
      />
      <SwETHFinalizeWithdrawalToast
        finalizeWithdrawal={calls.finalizeWithdrawal}
        withdrawUser={withdrawUser}
        claimInputs={claimInputs}
        anyTransactionInProgress={anyTransactionInProgress}
      />
    </>
  )
}

const StakingWidgetBox = styled(Box)`
  ${({ theme }) => css`
    position: relative;
    width: 340px;
    margin: 0 auto;
    padding: 24px 24px 12px;
    margin-top: 18px;
    color: ${theme.colors.white['50']};
    background: rgba(11, 20, 27, 0.8);
    border: none;

    > div {
      margin-bottom: 12px;
    }

    ${theme.breakpoints.up('sm')} {
      width: 420px;
      padding: 24px 32px 12px;


      > div {
        margin-bottom: 24px;

        &:last-child {
          margin-bottom: 12px;
        }
      }
    }}
  `};

  .Mui-disabled.MuiInputBase-root:before {
    border-bottom-style: solid;
    opacity: 0.6;
  }
`
