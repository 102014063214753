import useChainDetection from '@/hooks/useChainDetection'
import { NucleusBalances } from '@/state/nucleusVault/types'
import { FlexRow } from '@/swell-ui/FlexRow'
import { EthInput } from '@/swell-ui/inputs'
import { ThemeData } from '@/swell-ui/theme/branding'
import { Typography } from '@/swell-ui/Typography'
import { Token } from '@/types/tokens'
import { trimDecimalPlaces } from '@/util/number'
import { formatUnits } from 'ethers/lib/utils'
import { ReactNode } from 'react'
import styled, { css } from 'styled-components'

const StyledEthInput = styled(EthInput)`
  ${({ theme }) => css`
    .MuiFormHelperText-root {
      margin-top: 2px;
      margin-bottom: 16px;
    }

    ${theme.breakpoints.up('sm')} {
      max-width: unset;
    }
  `}
  .MuiInputBase-root:after {
    border-color: ${ThemeData.Swell.SwellVaultTurquoise};
  }
  .Mui-disabled.MuiInputBase-root:before {
    border-bottom-style: solid;
    opacity: 0.6;
  }
`
const EthInputWrapper = styled.div`
  position: relative;
  display: flex;
  gap: 8px;
  justify-content: space-between;
  align-items: center;

  .MuiFormControl-root {
    width: 100%;
  }

  input,
  div > div > p {
    font-size: ${({ theme }) => theme.typography.body.large.fontSize};
    font-weight: 600;
  }
`

export function DepositAssetInput({
  errorMessage,
  balances,
  disabled,
  depositAsset,
  setTouched,
  inputValue,
  setInputValue,
  usdLabel,
}: {
  setTouched: (touched: boolean) => void
  inputValue: string
  setInputValue: (value: string) => void
  errorMessage: string | null
  balances: NucleusBalances | undefined
  disabled: boolean
  depositAsset: Token | undefined
  usdLabel: string | undefined
}) {
  const onChange = (event: any) => {
    if (!depositAsset) return

    const value = event.target.value
    if (value === '') {
      setInputValue('')
      return
    }

    setTouched(true)

    const decimalPlaces = depositAsset.decimals
    const valueClean = trimDecimalPlaces(value, decimalPlaces)
    setInputValue(valueClean)
  }

  const handleMaxClick = () => {
    if (!depositAsset) return
    if (!balances) return
    const v = balances.assets[depositAsset.chainId]?.[depositAsset.address]
    if (!v) return

    onChange({
      target: {
        value: formatUnits(v, depositAsset.decimals),
      },
    })
  }

  return (
    <Layout usdLabel={usdLabel} hasError={!!errorMessage}>
      <EthInputWrapper>
        <StyledEthInput
          variant="standard"
          value={inputValue}
          onChange={onChange}
          error={!!errorMessage}
          helperText={errorMessage}
          disabled={disabled}
          onMaxClick={handleMaxClick}
        />
      </EthInputWrapper>
    </Layout>
  )
}

export function WithdrawAssetInput({
  errorMessage,
  balances,
  disabled,
  setTouched,
  inputValue,
  setInputValue,
  vaultToken,
  usdLabel,
}: {
  setTouched: (touched: boolean) => void
  inputValue: string
  setInputValue: (value: string) => void
  errorMessage: string | null
  balances: NucleusBalances | undefined
  disabled: boolean
  vaultToken: Token
  usdLabel: string | undefined
}) {
  const { isL2DeploymentChain, l2DeploymentChainId, deploymentChainId } =
    useChainDetection()

  const onChange = (event: any) => {
    const value = event.target.value
    if (value === '') {
      setInputValue('')
      return
    }

    setTouched(true)

    const decimalPlaces = vaultToken.decimals
    const valueClean = trimDecimalPlaces(value, decimalPlaces)
    setInputValue(valueClean)
  }

  const handleMaxClick = () => {
    if (!balances) return

    let chainId = deploymentChainId
    if (isL2DeploymentChain) {
      chainId = l2DeploymentChainId
    }

    const v = balances.assets[chainId]?.[vaultToken.address]
    if (!v) return

    onChange({
      target: {
        value: formatUnits(v, vaultToken.decimals),
      },
    })
  }

  return (
    <Layout usdLabel={usdLabel} hasError={!!errorMessage}>
      <EthInputWrapper>
        <StyledEthInput
          variant="standard"
          value={inputValue}
          onChange={onChange}
          error={!!errorMessage}
          helperText={errorMessage}
          disabled={disabled}
          onMaxClick={handleMaxClick}
        />
      </EthInputWrapper>
    </Layout>
  )
}

const UsdTypography = styled(Typography)`
  height: 19.2px;
  color: ${({ theme }) => theme.fiatColor};
  opacity: 0.6;
  margin-bottom: 16px;
  margin-top: 2px;
  letter-spacing: -0.03em;
`
const HiddenUsdTypography = styled(UsdTypography)`
  /* visibility: hidden; */
`

function Layout({
  children,
  usdLabel,
  hasError,
}: {
  children: React.ReactNode
  usdLabel: string | undefined
  hasError: boolean
}) {
  let usdNode: ReactNode = <div style={{ height: '0px' }} />
  if (!hasError) {
    usdNode = usdLabel ? (
      <UsdTypography variant="body" size="xsmall">
        {usdLabel}
      </UsdTypography>
    ) : (
      <HiddenUsdTypography variant="body" size="xsmall">
        $0.00
      </HiddenUsdTypography>
    )
  }

  return (
    <SInputLayout direction="column" gap="1" align="start">
      {children}
      {usdNode}
    </SInputLayout>
  )
}

const SInputLayout = styled(FlexRow)``
