import {
  NucleusAllowances,
  NucleusBalances,
  NucleusVaultAuth,
  NucleusVaultState,
} from '@/state/nucleusVault/types'
import { FlexRow } from '@/swell-ui/FlexRow'
import { useSwellWeb3 } from '@/swell-web3/core'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { DepositAssetInput } from '../NucleusInputs'
import {
  NucleusApproveAssetForDepositButton,
  NucleusDepositButton,
} from '../NucleusButtons'
import {
  NucleusErrors,
  prepareNucleusApproveAssetForDeposit,
  prepareNucleusDeposit,
} from '../nucleusCalls'
import {
  NucleusApproveAssetForDeposit,
  NucleusDeposit,
} from '@/state/nucleusVault/hooks'
import { BigNumber } from 'ethers'
import { TOKEN_LIST_ETH } from '@/constants/tokens'
import { useTransactionContext } from '@/state/transactions/context'
import { useMediaQuery } from '@mui/material'
import { NucleusPositionsTable } from '../NucleusPositionsTable'
import {
  NucleusPosition,
  NucleusRates,
  NucleusSupportedTokenMap,
  NucleusVault,
} from '@/types/nucleus'
import { makeNucleusDepositSummary } from '../nucleusFormatting'
import { AvailableChipV2 } from '@/components/StakingWidget/AvailableChipV2'
import { NucleusDepositInputs } from '../nucleusHooks'
import { NucleusDepositTokenSelect } from '../components/NucleusDepositTokenSelect'

const DEPOSIT_SLIPPAGE = 0.01 // implies minMint=0
const SELECT_ONLY_SUPPORTED_TOKENS = true

export function NucleusDepositView({
  balances,
  allowances,
  positions,
  deposit,
  approveAssetForDeposit,
  auth,
  supportedAssets,
  rates,
  vaultState,
  baseAssetRateUsd,
  depositInputs,
  vault,
}: {
  vault: NucleusVault
  deposit: NucleusDeposit
  approveAssetForDeposit: NucleusApproveAssetForDeposit
  balances: NucleusBalances | undefined
  allowances: NucleusAllowances | undefined
  positions: NucleusPosition[] | undefined
  auth: NucleusVaultAuth | undefined
  supportedAssets: NucleusSupportedTokenMap | undefined
  vaultState: NucleusVaultState | undefined
  rates: NucleusRates | undefined
  baseAssetRateUsd: number | undefined
  depositInputs: NucleusDepositInputs
}) {
  const is900Up = useMediaQuery('(min-width:900px)')
  const { anyTransactionInProgress } = useTransactionContext()
  const { account, chainId } = useSwellWeb3()
  const [touched, setTouched] = useState(false)
  const { amount: assetAmount, selectedToken, setAmountInput } = depositInputs

  // clear input when tx is fulfilled
  useEffect(() => {
    if (deposit.status === deposit.STATUS.FULFILLED) {
      setAmountInput('')
    }
  }, [deposit.status, deposit.STATUS.FULFILLED, setAmountInput])

  let assetBalance: BigNumber | undefined
  if (balances && selectedToken) {
    assetBalance =
      balances.assets?.[selectedToken.chainId]?.[selectedToken.address]
  }

  const requiresApproval = selectedToken?.symbol !== TOKEN_LIST_ETH.symbol

  let depositAssetAllowanceForVault: BigNumber | undefined
  if (allowances && selectedToken) {
    depositAssetAllowanceForVault =
      allowances.assetsForDeposit?.[selectedToken.chainId]?.[
        selectedToken.address
      ]
  }

  let vaultTokenRateInQuote: BigNumber | undefined
  if (rates && selectedToken) {
    vaultTokenRateInQuote =
      rates.vaultTokenQuoteRates?.[selectedToken.chainId]?.[
        selectedToken.address
      ]
  }

  const preparedApprove = prepareNucleusApproveAssetForDeposit({
    assetAmount,
    assetAddress: selectedToken?.address,
    assetBalance,
  })
  const preparedDeposit = prepareNucleusDeposit({
    auth,
    depositAmount: assetAmount,
    depositAssetAllowanceForVault,
    depositAssetBalance: assetBalance,
    depositToken: selectedToken,
    requiresApproval,
    slippage: DEPOSIT_SLIPPAGE,
    supportedAssets,
    vaultState,
    vaultTokenRateInQuote,
    baseAsset: vault.baseAsset,
    chainId,
  })

  const accountAndNoBalances = Boolean(account && !balances)
  const noRates = !rates
  const preventInteraction =
    anyTransactionInProgress || accountAndNoBalances || noRates

  const mustApproveDeposit =
    preparedDeposit.error === NucleusErrors.InsufficientAllowance

  let buttonNode: React.ReactNode
  if (mustApproveDeposit) {
    buttonNode = (
      <NucleusApproveAssetForDepositButton
        approveAssetForDeposit={approveAssetForDeposit}
        prepared={preparedApprove}
        preventInteraction={preventInteraction}
      />
    )
  } else {
    buttonNode = (
      <NucleusDepositButton
        deposit={deposit}
        prepared={preparedDeposit}
        preventInteraction={preventInteraction}
        vault={vault}
        selectedToken={selectedToken}
      />
    )
  }

  let errorMessage: string | null = null
  if (touched) {
    if (mustApproveDeposit) {
      if (preparedApprove.error) {
        errorMessage = preparedApprove.error
      }
    } else if (preparedDeposit.error) {
      errorMessage = preparedDeposit.error
    }
  }

  const depositSummary = makeNucleusDepositSummary({
    assetAmount,
    balances,
    baseAssetRateUsd,
    rates,
    selectedToken,
    vaultToken: vault.vaultToken,
    vaultTokenL2: vault.vaultTokenL2,
    base: vault.baseAsset,
  })

  let depositTokens = [...vault.depositAssets]
  if (SELECT_ONLY_SUPPORTED_TOKENS) {
    depositTokens = depositTokens.filter(
      (t) => supportedAssets?.[t.chainId]?.[t.address]?.isSupported
    )
  }

  const depositWidget = (
    <FlexRow direction="column" maxWidth="353px" height="188px">
      <FlexRow justify="space-between">
        <AvailableChipV2 available={depositSummary.availableBalance} />
        <NucleusDepositTokenSelect
          nucleusBalances={balances}
          depositInputs={depositInputs}
        />
      </FlexRow>
      <div style={{ height: '32.5px' }} />
      <DepositAssetInput
        setTouched={setTouched}
        inputValue={depositInputs.amountInput}
        setInputValue={depositInputs.setAmountInput}
        errorMessage={errorMessage}
        balances={balances}
        disabled={preventInteraction}
        depositAsset={depositInputs.selectedToken}
        usdLabel={depositSummary.depositUsd}
      />
      {buttonNode}
    </FlexRow>
  )

  const positionsTable = <NucleusPositionsTable positions={positions} />

  if (is900Up) {
    return (
      <Layout gap="62" height="208px">
        {depositWidget}
        {positionsTable}
      </Layout>
    )
  }

  return (
    <Layout gap="48" direction="column">
      {depositWidget}
      {positionsTable}
    </Layout>
  )
}

const Layout = styled(FlexRow)`
  align-items: stretch;
  > div:first-child {
    flex-shrink: 0;
  }
`
