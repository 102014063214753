import {
  NucleusWithdrawRequest,
  NucleusFulfilledRequestEvent,
  NucleusWithdrawRequestResult,
} from '@/types/nucleus'
import { TokenKey } from '@/types/tokens'

export const NucleusWithdrawalStatus = {
  Requesting: 'Requesting' as const,
  UnackedFulfilled: 'UnackedFulfilled' as const,
  UnackedFailed: 'UnackedFailed' as const,
  NoActiveWithdrawal: 'NoActiveWithdrawal' as const,
}
export type NucleusWithdrawalStatus =
  (typeof NucleusWithdrawalStatus)[keyof typeof NucleusWithdrawalStatus]

// Nucleus withdrawals can be in 4 states:
// - requesting / unsolved / waiting
// - recently solved, unacknowledged
// - recently failed, unacknowledged
// - no active withdrawal
export type NucleusActiveWithdrawalResult =
  | {
      // requesting / unsolved
      status: (typeof NucleusWithdrawalStatus)['Requesting']
      request: NucleusWithdrawRequest
      wantTokenKey: TokenKey
      fulfilledEvent?: undefined
      failedRequest?: undefined
    }
  | {
      // recently solved, unacknowledged
      status: (typeof NucleusWithdrawalStatus)['UnackedFulfilled']
      request?: undefined
      wantTokenKey: TokenKey
      fulfilledEvent: NucleusFulfilledRequestEvent
      failedRequest?: undefined
    }
  | {
      // recently failed, unacknowledged
      status: (typeof NucleusWithdrawalStatus)['UnackedFailed']
      request?: undefined
      wantTokenKey?: undefined
      fulfilledEvent?: undefined
      failedRequest: NucleusWithdrawRequest
    }
  | {
      // no active withdrawal
      status: (typeof NucleusWithdrawalStatus)['NoActiveWithdrawal']
      request?: undefined
      wantTokenKey?: undefined
      fulfilledEvent?: undefined
      failedRequest?: undefined
    }

// exported for hooks, not intended to be consumed directly in components
// calculates withdrawal UI state given info about user withdrawals & user acknowledgements
export function nucleusActiveWithdrawalMemo({
  fulfilledRequests,
  ackCompletedUnix,
  ackFailedUnix,
  nowUnix,
  currentWithdrawRequest,
}: {
  currentWithdrawRequest: NucleusWithdrawRequestResult
  fulfilledRequests: NucleusFulfilledRequestEvent[]
  ackCompletedUnix: number | null
  ackFailedUnix: number | null
  nowUnix: number
}): NucleusActiveWithdrawalResult {
  if (!currentWithdrawRequest?.exists) {
    // no requests ever
    return {
      status: NucleusWithdrawalStatus.NoActiveWithdrawal,
    }
  }
  const { request, wantTokenKey } = currentWithdrawRequest

  let latestFulfilledEvent: NucleusFulfilledRequestEvent | null = null
  for (const req of fulfilledRequests) {
    const timestamp = req.timestampUnix
    if (!latestFulfilledEvent) {
      latestFulfilledEvent = req
      continue
    }
    if (timestamp > latestFulfilledEvent.timestampUnix) {
      latestFulfilledEvent = req
    }
  }

  const latestRequestDeadlineInFuture = request.deadlineUnix > nowUnix
  const latestRequestSolved = request.offerAmount.eq(0)
  const latestRequestFailed =
    !latestRequestDeadlineInFuture && !latestRequestSolved

  const isRequesting = latestRequestDeadlineInFuture && !latestRequestSolved

  let isUnackedFulfilled = false
  if (latestFulfilledEvent) {
    if (
      !ackCompletedUnix ||
      latestFulfilledEvent.timestampUnix > ackCompletedUnix
    ) {
      isUnackedFulfilled = true
    }
  }

  let isUnackedFailed = false
  if (latestRequestFailed) {
    if (!ackFailedUnix || request.deadlineUnix > ackFailedUnix) {
      isUnackedFailed = true
    }
  }

  let result: NucleusActiveWithdrawalResult | undefined
  if (isUnackedFailed) {
    result = {
      status: NucleusWithdrawalStatus.UnackedFailed,
      failedRequest: request,
    }
  }

  if (isUnackedFulfilled) {
    result = {
      status: NucleusWithdrawalStatus.UnackedFulfilled,
      fulfilledEvent: latestFulfilledEvent!,
      wantTokenKey,
    }
  }

  if (isRequesting) {
    result = {
      status: NucleusWithdrawalStatus.Requesting,
      request,
      wantTokenKey,
    }
  }

  if (!result) {
    result = {
      status: NucleusWithdrawalStatus.NoActiveWithdrawal,
    }
  }

  return result
}
